export class FeePrompt {
  id!: number;
  feePromptOptions: FeePromptOption[];
}

export class FeePromptOption {
  feeId!: number;
  name: string;
  selected: boolean;
  required: boolean;
}

import { Company } from '@app/core/model/company.model'
import { Privileged } from '@app/core/class/privileged.class'
import { Type } from 'class-transformer'
import { User } from '@app/core/model/user.model'

export class LibraryManagementFolder extends Privileged {
  id: number
  name: string
  displayName?: string
  itemType: string
  createdTs: string
  updatedTs: string
  @Type(() => User) createdBy: User
  @Type(() => User) updatedBy: User
  company: Company
  archived: boolean
  deleted: boolean
  language: string
  privilegeCategories: string[]
  items: any
  sharedCompaniesNames: any
  companyGroupSharedFolders: any
  isSharedByCompanies: boolean
  parent: number | string
  path: string
  libraryItemType: string
  folder: boolean
  libraryFolder?: { id: number; name: string }
}

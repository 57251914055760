import {
  OvertimeUnitType,
  ScopeThirdPartyCostUnitTypeConverter,
  ThirdPartyCostFormulaDefinitionDetail,
  ThirdPartyCostUnitType,
} from '@app/core/model/third-party-cost.model'

import { Type } from 'class-transformer'

export class ThirdPartyCostFormula {
  id!: number
  private name!: string
  private subjectName: string
  quantity: number
  unitType: ThirdPartyCostUnitType
  unitTypes?: ThirdPartyCostUnitType[]
  unitTypeQuantity: number
  overtimeRate: number
  overtimeUnitType: OvertimeUnitType
  overtimeUnitQuantity: number
  @Type(() => ThirdPartyCostFormulaDefinitionDetail) thirdPartyCostFormulaDefinitionSnapshot: ThirdPartyCostFormulaDefinitionDetail
  @Type(() => ScopeThirdPartyCostUnitTypeConverter) private unitTypeConvertors: ScopeThirdPartyCostUnitTypeConverter[]

  get getUnitTypeConvertors() {
    return this.unitTypeConvertors
  }

  get getName() {
    return this.name
  }

  get getSubjectName() {
    return this.subjectName
  }
}

import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SharedModule } from '@app/shared/shared.module';
import {
  ScopeUiDatepickerComponent,
} from '@app/shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component';
import {
  ScopeUiAutocompleteComponent,
} from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxMaskDirective } from 'ngx-mask';
import {
  ScopeUiDropdownComponent,
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { FormControl } from '@angular/forms';
import { HyperFormula } from 'hyperformula';
import { FormulaBuilderComponent } from '@shared/components/formula-builder/formula-builder.component';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { ScopeUiChipsComponent } from '@shared/components/ui-components/scope-ui-chips/scope-ui-chips.component';
import {
  ScopeUiCheckboxComponent
} from '@shared/components/ui-components/scope-ui-checkbox/scope-ui-checkbox.component';
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model';
import { Observable } from 'rxjs';
import {
  CompanyManagementSelectors
} from '@app/features/company-management/store/selectors/company-management.selectors';
import { Store } from '@ngrx/store';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { FormulaService } from '@shared/services/formula.service';
import { ComponentModifier } from '@app/features/company-management/models/component-modifier.model';

export interface AddEditCategoryModalConfig {
  scenario: Scenario,
  category?: ScenarioCategory,
  modifiers: ComponentModifier[],
  confirmCallback: (category: any) => void
}

@Component({
  selector: 'add-edit-scenario-category-modal',
  standalone: true,
  imports: [CommonModule, ScopeUiDatepickerComponent, ScopeUiAutocompleteComponent, SharedModule, MatDatepickerModule, CKEditorModule, ScopeUiInputComponent, NgxSliderModule, NgxMaskDirective, ScopeUiDropdownComponent, FormulaBuilderComponent, ScopeUiChipsComponent, ScopeUiCheckboxComponent],
  templateUrl: './add-edit-scenario-category-modal.component.html',
  styleUrls: ['./add-edit-scenario-category-modal.component.scss'],
})
export class AddEditScenarioCategoryModalComponent {
  name: string
  showAlways: boolean = true
  showOnCondition: boolean = false
  condition: string
  conditionControl = new FormControl()
  scenario: Scenario
  hf: HyperFormula
  updateLoading$: Observable<boolean>
  private showDependencyWarning: boolean = true

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddEditCategoryModalConfig,
    public dialog: MatDialog,
    private store: Store,
    public cdr: ChangeDetectorRef,
    private formulaService: FormulaService
  ) {
    // Create a HyperFormula instance for field ID validation
    this.hf = HyperFormula.buildEmpty({ licenseKey: 'gpl-v3' })
    this.updateLoading$ = this.store.select(CompanyManagementSelectors.selectLoadingAddUpdateScenarioCategory)
    this.scenario = data.scenario

    if (data.category) {
      let categories = data.scenario.categories
        .filter((c) => c.orderIndex < data.category.orderIndex)
      this.scenario = { ...data.scenario, categories }

      this.name = data.category.name
      this.showAlways = !data.category.displayCondition
      this.showOnCondition = !!data.category.displayCondition
      this.condition = data.category.displayCondition
    }
  }

  disableSubmit(): boolean {
    return !this.name || (this.showOnCondition && (!this.condition || this.conditionControl.hasError('invalid')))
  }

  submit() {
    let condition = this.showOnCondition ? this.condition : null
    this.data.confirmCallback({ id: this.data.category?.id, name: this.name, displayCondition: condition })
  }

  checkDependencies() {
    if (this.showDependencyWarning && this.data.category) {
      this.showDependencyWarning = false
      let {questionDependencies, modifierDependencies} =
        this.formulaService.getReferencedFieldsForCategory(this.data.category, this.data.scenario, this.data.modifiers)

      if (questionDependencies.length || modifierDependencies.length) {
        let modalConfig = {
          title: `Category Has Dependencies`,
          body: `By changing the condition you can potentially break the rules in the following calculations:\n\n` +
            (questionDependencies.length ?
              'Questions:\n' + questionDependencies.map((d) => `\u2022 ${d.categoryName} > ${d.displayText}\n`).join('') : '') +
            (modifierDependencies.length ?
              'Modifiers:\n' + modifierDependencies.map((d) => `\u2022 ${d.name}\n`).join('') : ''),
          confirmText: 'Ok',
          confirmCallback: () => {
            dialog.close();
          },
          cancelCallback: undefined,
          inputs: [],
          isMemberModal: undefined,
          limitBodyWidth: true
        };
        let dialog = this.dialog.open(ScopeUiModalComponent, { data: modalConfig });
      }
    }
  }
}

import { AsyncPipe, CommonModule, CurrencyPipe, DecimalPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { Router } from "@angular/router";
import { Brand } from "@app/features/scope-overview/model/brand.model";
import { RatecardIdentity } from "@app/features/scope-overview/model/ratecard-identity.model";
import { RatecardVersion } from "@app/features/scope-overview/model/ratecard-version.model";
import { ScopeOverviewService } from "@app/features/scope-overview/service/scope-overview.service";
import {
  DuplicateDifferentRatecardVersionComponent
} from "@app/features/scoping/components/duplicate-different-ratecard-version/duplicate-different-ratecard-version.component";
import { MapScopeRolesComponent } from '@app/features/scoping/components/map-scope-roles/map-scope-roles.component';
import { Deliverable } from "@app/features/scoping/models/deliverable.model";
import { MyScopesService } from "@app/features/scoping/service/scoping.service";
import { ScopeActions } from "@app/features/scoping/store/actions/scoping.actions";
import { ScopingSelectors } from "@app/features/scoping/store/selectors/scoping.selector";
import { OfficeLocation } from "@core/model/office-location";
import { DefaultView, ScopeVersion } from "@core/model/scope-version";
import { SecondParty } from "@core/model/second-party.model";
import { AuthService } from '@core/service/auth.service';
import { CountryCodeService } from "@core/service/country-code.service";
import { LanguageService } from "@core/service/language.service";
import { ScopeMetadataService } from "@core/service/scope-metadata.service";
import { Store } from "@ngrx/store";
import { LoaderModalModule } from '@shared/components/loader-modal/loader-modal.module';
import { ScopeUiOptionsMenuComponent } from "@shared/components/scope-ui-options-menu/scope-ui-options-menu.component";
import {
  ScopeUiAutocompleteComponent
} from "@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component";
import {
  ScopeUiDatepickerComponent
} from "@shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component";
import {
  ScopeUiDropdownComponent
} from "@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component";
import { ScopeUiDuration } from '@shared/components/ui-components/scope-ui-duration/scope-ui-duration.component';
import { ScopeUiInputComponent } from "@shared/components/ui-components/scope-ui-input/scope-ui-input.component";
import { ScopeUiRadioComponent } from '@shared/components/ui-components/scope-ui-radio/scope-ui-radio.component';
import { DirectivesModule } from "@shared/directives/index.module";
import { DateFormatPipe } from "@shared/pipe/dateFormat.pipe";
import { PipesModule } from "@shared/pipe/index.module";
import { DEFAULT_POSITION, HeaderStyleService, INHERIT_POSITION } from '@shared/services/header-style.service';
import { RouteUtils } from "@shared/utils/route-util.service";
import {
  SNACKBAR_LENGTH_LONG,
  SNACKBAR_LENGTH_SHORT,
  SnackbarEventType,
  SnackbarService,
} from '@shared/utils/snackbar.service';
import { untilDestroyed } from "@shared/utils/utils";
import { instanceToInstance } from "class-transformer";
import _, { toPairs } from "lodash";
import { Observable, combineLatest, from, map, finalize } from 'rxjs';
import {
  DynamicFieldType,
  ScopeDynamicFieldSettingModel,
} from '@app/features/scoping/models/scope-dynamic-field-setting.model';
import { User } from '@core/model/user.model';
import { ScopeDynamicFieldSettingService } from '@app/features/scoping/service/scope-dynamic-field-setting.service';
import { catchError, concatMap } from 'rxjs/operators';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import {
  CompanyManagementSelectors
} from '@app/features/company-management/store/selectors/company-management.selectors';
import { CompanyManagementActions } from '@app/features/company-management/store/actions/company-management.actions';
import { TableLoaderComponent } from "@app/shared/components/ui-components/table-loader/table-loader.component";

@Component({
  selector: 'app-create-scope',
  templateUrl: './create-scope.component.html',
  styleUrls: ['./create-scope.component.scss'],
  imports: [
    CommonModule,
    ScopeUiOptionsMenuComponent,
    MatDialogModule,
    AsyncPipe,
    PipesModule,
    NgIf,
    MatDividerModule,
    ScopeUiInputComponent,
    ScopeUiAutocompleteComponent,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    NgForOf,
    DirectivesModule,
    ScopeUiDatepickerComponent,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    DecimalPipe,
    NgClass,
    ScopeUiDropdownComponent,
    CurrencyPipe,
    LoaderModalModule,
    ScopeUiRadioComponent,
    ScopeUiDuration,
    TableLoaderComponent
  ],
  providers: [ DateFormatPipe ],
  standalone: true
})
export class CreateScopeComponent {
  @Input() id?: number;
  private readonly destroy$;

  officeLocations$!: Observable<OfficeLocation[]>;
  secondParties$!: Observable<SecondParty[]>;
  scopeTemplates$: Observable<any[]>;
  wordTemplates$: Observable<any[]>;
  xlsxTemplates$: Observable<any[]>;
  scopeTypes$: Observable<any[]>;
  scenarios$!: Observable<Scenario[]>;
  isDynamicFieldsLoading$!: Observable<boolean>;
  secondParties!: SecondParty[];
  scopeTypes!: any[];
  ratecards: RatecardVersion[] = [];
  companyOwnRateCards!: RatecardVersion[];
  benchmarkRateCards!: RatecardVersion[];
  defaultRateCard!: RatecardVersion;
  loggedInUser: User;
  scopeDynamicFieldSettings: ScopeDynamicFieldSettingModel[];
  scopeDynamicFieldTextSettings: ScopeDynamicFieldSettingModel[];
  scopeDynamicFieldDropdownSettings: ScopeDynamicFieldSettingModel[];
  scopeDynamicFieldDropdownExternalSettings: ScopeDynamicFieldSettingModel[];
  scopeDynamicFieldDropdownSettingsWithOptions: { setting: ScopeDynamicFieldSettingModel, options: { name: string } [] }[] = [];
  scopeDynamicFieldDropdownExternalSettingsWithOptions: { setting: ScopeDynamicFieldSettingModel, options: { name: string } [] }[] = [];

  selectedSecondParty!: SecondParty | null;
  myNewScope: any = {};
  trafficMetaDataFieldTemp: any = {};
  trafficSystemMetadataSearch$: any = {};
  currencyCode?: string;
  contacts: any[] = [];
  locations: OfficeLocation[] = [];
  selectedOfficeLocation?: OfficeLocation | null;
  step: number = 1;
  scopeViews: any = [
    { name: 'Table', value: DefaultView.table },
    { name: 'Timeline', value: DefaultView.timeline },
  ];

  languages = [
    { value: "EN", name: "English" },
    { value: "ES", name: "Spanish" },
    { value: "DE", name: "German" },
    { value: "PR", name: "Portuguese" },
    { value: "FR", name: "French" }
  ];
  selectedPeriod: { value: string, name: string } = { name: 'Custom', value: 'custom' };
  selectedLanguage: any;
  countDays: any;
  private usingTemplate: boolean = false;
  createNewScope: boolean;
  private isTemplate: boolean = false;
  isScopeByRole: boolean = false;
  isScopeByScenario: boolean = false;
  scopeToDuplicate?: ScopeVersion;
  private isDifferentRatecard: boolean = false;
  private isDifferentRatecardVersion: boolean = false;
  locationsCount: number
  loading: boolean = false
  protected isExternalDataLoading = false;
  protected isDynamicFieldsLoading = false;

  get secondPartyOptions() {
    return (this.selectedOfficeLocation ? this.selectedOfficeLocation.restrictedSecondParties : this.secondParties) || [];
  }

  constructor(private countryCodeService: CountryCodeService,
              private scopeMetadataService: ScopeMetadataService,
              private scopeOverviewService: ScopeOverviewService,
              private myScopeService: MyScopesService,
              private lang: LanguageService,
              private dialog: MatDialog,
              private store: Store,
              private router: Router,
              private routeUtils: RouteUtils,
              private cdr: ChangeDetectorRef,
              private headerStyleService: HeaderStyleService,
              private snackbarService: SnackbarService,
              private authService: AuthService,
              private scopeDynamicFieldSettingService: ScopeDynamicFieldSettingService) {
    this.destroy$ = untilDestroyed();
    this.countryCodeService = countryCodeService;
    this.scopeMetadataService = scopeMetadataService;
    this.officeLocations$ = this.store.select(ScopingSelectors.selectOfficeLocations)
    this.secondParties$ = this.store.select(ScopingSelectors.selectSecondParties)
    this.scopeTemplates$ = this.store.select(ScopingSelectors.selectScopeTemplates)
    this.wordTemplates$ = this.store.select(ScopingSelectors.selectWordOutputTemplates)
    this.xlsxTemplates$ = this.store.select(ScopingSelectors.selectXlsxOutputTemplates)
    this.scopeTypes$ = this.store.select(ScopingSelectors.selectScopeTypes)
    this.isDynamicFieldsLoading$ = this.store.select(ScopingSelectors.selectDynamicFieldSettingsLoading);
    this.scenarios$ = this.store.select(CompanyManagementSelectors.selectScenarios);
    this.loggedInUser = this.authService.loggedInUser;
  }

  ngOnDestroy() {
    this.headerStyleService.setMainSectionClass(DEFAULT_POSITION)
  }

  onViewRadioChange(newValue: string) {
    this.myNewScope.defaultView = newValue;
  }

  ngOnInit() {
    this.headerStyleService.setMainSectionClass(INHERIT_POSITION)
    this.usingTemplate = this.router.url.includes('use-template')
    this.isScopeByRole = this.router.url.includes('create-scope-by-role')
    this.isScopeByScenario = this.router.url.includes('create-scope-by-scenario')
    this.createNewScope = (this.id == null) || this.usingTemplate

    this.fetchRateCards()

    this.secondParties$.pipe(this.destroy$()).subscribe((secondParties: SecondParty[]) => this.secondParties = secondParties)
    this.scopeTypes$.pipe(this.destroy$()).subscribe((scopeTypes: any[]) => this.scopeTypes = scopeTypes)
    this.isDynamicFieldsLoading$.pipe(this.destroy$()).subscribe(
      (isDynamicFieldsLoading: boolean) => this.isDynamicFieldsLoading = isDynamicFieldsLoading)

    if (this.usingTemplate) {
      this.scopeTemplates$.pipe(this.destroy$()).subscribe((scopeTemplates: any[]) => {
        if (scopeTemplates) {
          let templateId = this.id;
          this.myNewScope.scopeTemplate = scopeTemplates.find(t => t.identity.id == templateId);
        }
      })
    }

    this.store.dispatch(ScopeActions.getOfficeLocations())
    this.store.dispatch(ScopeActions.getScopeTemplates())
    this.store.dispatch(ScopeActions.getWordOutputTemplates())
    this.store.dispatch(ScopeActions.getXlsxOutputTemplates())
    this.store.dispatch(ScopeActions.getScopeTypes())
    this.store.dispatch(ScopeActions.getSecondParties())
    if (this.isScopeByScenario) this.store.dispatch(CompanyManagementActions.getScenarios())

    if (this.createNewScope) {
      this.myNewScope = {
        name: '',
        secondParty: null,
        contact: null,
        brand: null,
        location: null,
        scopeTemplate: null,
        startDate: new Date(),
        budget: null,
        endDate: new Date(),
        outputTemplate: null,
        xlsxTemplate: null,
        benchmarkRateCard: null,
        language: this.authService.loggedInUser!.company.language,
        costPlusId: null,
        defaultView: 'table',
        dynamicFields: new Map()
      };
      this.myNewScope.endDate.setMonth(this.myNewScope.startDate.getMonth() + 3)
      this.setSelectedLanguage()
      this.initiateScopeVersionCustomFields();
      if (this.authService.loggedInUser?.company.hasApplicationSetting('SCOPE_DYNAMIC_FIELDS')) {
        this.initialScopeDynamicFieldSettings();
      }
    } else {
      this.myNewScope.sourceScopeId = this.id;
      this.getExistingScope();
    }
    this.locationsCount = this.getLocations().length
  }

  setSelectedLanguage() {
    this.selectedLanguage = this.languages.find(lang => lang.value === this.authService.loggedInUser!.company.language)
  }

  fetchRateCards() {
    combineLatest([this.scopeOverviewService.getAllRateCardsForScopeCreate(), this.scopeOverviewService.getDefaultRatecard()])
      .pipe(this.destroy$())
      .subscribe(([rateCards, rateCard]) => {
        this.companyOwnRateCards = rateCards;
        this.benchmarkRateCards = rateCards;
        this.defaultRateCard = rateCard;
        if (this.benchmarkRateCards.length == 0){
          this.benchmarkRateCards.push(this.defaultRateCard);
        }
      })
  }

  getTrafficFields() {
    if (this.authService.loggedInUser?.company.trafficSystemSettings){
      const trafficSystem = this.authService.loggedInUser?.company.trafficSystemSettings.trafficSystem;
      if (trafficSystem && trafficSystem.externalTrafficSystemConfig.scopeMetadataLookupConfig){
        return trafficSystem.externalTrafficSystemConfig.scopeMetadataLookupConfig.definitions
      }
    }
    return [];
  }

  getSecondParty = (id: number): void => {
    this.scopeOverviewService
      .getSecondPartyByIdForScope(id)
      .pipe(this.destroy$())
      .subscribe((secondParty: SecondParty) => {
        this.selectedSecondParty = secondParty;
        this.contacts = secondParty?.contacts || [];
        this.locations = secondParty?.locations || [];
        this.locationsCount = this.getLocations().length
        this.getRateCards()
        this.cdr.detectChanges()
      })
  }

  getOfficeLocation = (id: number): void => {
    this.scopeOverviewService
      .getOfficeLocation(id)
      .pipe(this.destroy$())
      .subscribe((location: OfficeLocation) => {
        this.selectedOfficeLocation = location;
      })
  }

  getSecondPartyById(secondPartyId: number) {
    this.myNewScope.contact = '';
    this.myNewScope.location = '';
    this.myNewScope.brand = null;
    this.selectedSecondParty = null;
    this.myNewScope.ratecard = null;
    if(secondPartyId) {
      this.getSecondParty(secondPartyId);
    } else {
      this.contacts = [];
      this.locations = [];
      this.locationsCount = this.getLocations().length
    }
  };

  getRateCards() {
    let listOfRateCards: RatecardVersion[] = []
    if (this.selectedSecondParty) {
      let isSharedClient = this.myNewScope.secondParty.groupShared
      if (this.myNewScope.brand != null) {
        listOfRateCards = isSharedClient ? this.companyOwnRateCards : this.getRateCardsOfBrand()
      } else {
        listOfRateCards = isSharedClient ? this.companyOwnRateCards : this.getRateCardsOfSecondParty()
      }
      if (listOfRateCards.length == 0) {
        listOfRateCards.push(this.defaultRateCard)
      }
    }
    this.ratecards = this.isScopeByRole ? listOfRateCards.filter(rc => !rc.rateCardIdentity.costPlus) : listOfRateCards;
    return listOfRateCards
  }

  getRateCardsOfBrand() {
    return this.companyOwnRateCards.filter(rc => {
      let match = this.myNewScope.brand.rateCardIdentities.find((brandRc: RatecardIdentity) => {
        return brandRc.id == rc.rateCardIdentity.id
      })
      return match != null
    });
  }

  getRateCardsOfSecondParty() {
    return this.companyOwnRateCards.filter(rc => {
      let match = this.selectedSecondParty!.rateCardIdentities?.find(brandRc => {
        return brandRc.id == rc.rateCardIdentity.id
      })
      return match != null
    });
  }

  initiateScopeVersionCustomFields() {
    this.store.select(ScopingSelectors.selectCustomFields).pipe(this.destroy$())
      .subscribe((initialScopeVersionCustomFields: any) => {
        if (initialScopeVersionCustomFields) {
          this.myNewScope.companyScopeVersionCustomFieldsDefinition = instanceToInstance(initialScopeVersionCustomFields.companyScopeVersionCustomFieldsDefinition);
          this.myNewScope.scopeVersionCustomFieldValueStructure = instanceToInstance(initialScopeVersionCustomFields.scopeVersionCustomFieldValueStructure);

          if (this.myNewScope.scopeVersionCustomFieldValueStructure != null && this.myNewScope.scopeVersionCustomFieldValueStructure.fieldValues?.length > 0) {
            let _this = this
            this.myNewScope.scopeVersionCustomFieldValueStructure.fieldValues.forEach(function (customField: any) {
              _this.myNewScope.companyScopeVersionCustomFieldsDefinition.customFields.forEach(function (metaField: any) {
                if (metaField.id == customField.id) {
                  customField.name = metaField.name;
                }
              });
            });
            if (!this.createNewScope) {
              this.myNewScope.scopeVersionCustomFieldValueStructure.fieldValues.forEach(function(customField: any) {
                var id = customField.id;
                var customFieldValue = _this.scopeToDuplicate!.scopeVersionCustomFieldValueStructure?.fieldValues.find(it => it.id == id);
                if (customFieldValue != null) {
                  customField.value = customFieldValue.value;
                }
              });
            }
          }
        }
      }
    );

    this.store.dispatch(ScopeActions.getCustomFields())
  }

  getTextDynamicFieldValue = (setting: ScopeDynamicFieldSettingModel) =>
    this.myNewScope.dynamicFields?.get?.(setting.name)

  getDropdownDynamicFieldValue = (setting: ScopeDynamicFieldSettingModel) =>
    ({ name: this.myNewScope.dynamicFields?.get?.(setting?.name) })

  getExternalDropdownDynamicFieldValue = (payload: { setting: ScopeDynamicFieldSettingModel; options: any[] }) =>
    payload.options?.find(option => {
      let settingName = payload.setting?.name
      let dynamicField = this.myNewScope.dynamicFields?.get?.(settingName)
      return option.item.id === dynamicField?.item?.id
    })
  
  getExternalDropdownDynamicFieldDisplayValue = (setting: ScopeDynamicFieldSettingModel) => {
    let dynamicField = this.myNewScope.dynamicFields?.get?.(setting?.name)
    return this.getFieldValueAsOption(dynamicField?.item, setting?.jsonValueName)?.name
  }

  initialScopeDynamicFieldSettings(): void {
    this.store.select(ScopingSelectors.selectDynamicFieldSettings).pipe(this.destroy$())
      .subscribe((scopeDynamicFieldSettings: ScopeDynamicFieldSettingModel[]) => {
        this.scopeDynamicFieldSettings = scopeDynamicFieldSettings;
        if (this.scopeDynamicFieldSettings && this.scopeDynamicFieldSettings.length > 0) {
          this.filterScopeDynamicFieldSettings();
        }
      });
    this.store.dispatch(ScopeActions.getDynamicFieldSettings());
  }

  filterScopeDynamicFieldSettings(): void {
    this.scopeDynamicFieldTextSettings = this.scopeDynamicFieldSettings.filter(setting => setting.type === DynamicFieldType.TEXT);
    this.scopeDynamicFieldDropdownSettings = this.scopeDynamicFieldSettings.filter(setting => setting.type === DynamicFieldType.DROPDOWN);
    this.scopeDynamicFieldDropdownExternalSettings = this.scopeDynamicFieldSettings.filter(setting => setting.type === DynamicFieldType.DROPDOWN_EXTERNAL);
    this.initialTextDynamicFields();
    this.buildScopeDynamicFieldDropdownOptions(this.scopeDynamicFieldDropdownSettings);
    this.buildScopeDynamicFieldDropdownExternalOptions(this.scopeDynamicFieldDropdownExternalSettings);
  }

  initialTextDynamicFields(): void {
    let settingsWithValue = this.scopeDynamicFieldTextSettings.filter(setting => setting.value);
    settingsWithValue.forEach(settingWithValue => {
      if (!this.myNewScope.dynamicFields?.get(settingWithValue.name))
        this.myNewScope.dynamicFields?.set(settingWithValue.name, settingWithValue.value)
    });
  }

  buildScopeDynamicFieldDropdownOptions(scopeDynamicFieldDropdownSettings: ScopeDynamicFieldSettingModel[]): void {
    this.scopeDynamicFieldDropdownSettingsWithOptions = [];
    scopeDynamicFieldDropdownSettings.forEach(dropdownSetting => {
      let options: { name: string }[] = dropdownSetting.value?.split('\n')
        .filter(option=> option.trim())
        .map(option => ({ name: option })) || [];
      this.scopeDynamicFieldDropdownSettingsWithOptions.push({ setting: dropdownSetting, options });
    });
  }

  buildScopeDynamicFieldDropdownExternalOptions(scopeDynamicFieldDropdownExternalSettings: ScopeDynamicFieldSettingModel[]): void {
    if (this.isExternalDataLoading) return

    this.isExternalDataLoading = true;
    this.scopeDynamicFieldDropdownExternalSettingsWithOptions = [];

    from(scopeDynamicFieldDropdownExternalSettings).pipe(
      concatMap(dropdownExternalSetting =>
        this.scopeDynamicFieldSettingService.getExternalSystemResource({
          integrationName: dropdownExternalSetting.apiIntegrationName,
          resourceUri: dropdownExternalSetting.externalSystemResourceUri,
        }).pipe(
          map(resp => ({ resp, dropdownExternalSetting })),
          catchError(error => [{ resp: [], dropdownExternalSetting }])
        )
      ),
      finalize(() => {
        this.isExternalDataLoading = false;
        this.cdr.detectChanges();
      })
    ).subscribe({
      next: ({ resp, dropdownExternalSetting }) => {
        const options = this.getFieldValuesAsOptions(resp, dropdownExternalSetting.jsonValueName);
        const existingIndex = this.scopeDynamicFieldDropdownExternalSettingsWithOptions
          .findIndex(de => de.setting.name === dropdownExternalSetting.name);

        if (existingIndex === -1) {
          this.scopeDynamicFieldDropdownExternalSettingsWithOptions.push({ setting: dropdownExternalSetting, options });
        } else {
          this.scopeDynamicFieldDropdownExternalSettingsWithOptions[existingIndex].options = options;
        }
        this.cdr.detectChanges();
      }
    });
  }

  getFieldValueAsOption(item: any, fieldPath: string): { name: string, item: any } {
    const pathParts = fieldPath.substring(2).split('.')
    let value = item

    for (const part of pathParts)
      if (value && part in value) value = value[part]
      else return null

    return { name: value, item };
  }

  getFieldValuesAsOptions(jsonArray: any[], fieldPath: string): { name: string }[] {
    return jsonArray.map((item) => this.getFieldValueAsOption(item, fieldPath)).filter(item => item);
  }

  onInputDynamicFieldTextSettingChange(setting: ScopeDynamicFieldSettingModel, newValue: any) {
    if (!newValue?.trim()) {
      this.myNewScope.dynamicFields?.delete(setting.name);
    } else {
      this.myNewScope.dynamicFields?.set(setting.name, newValue);
    }
  }

  onSelectDropdownDynamicField($event: any, name: string) {
    this.myNewScope.dynamicFields?.set(name, $event.name);
  }

  onSelectDropdownExternalDynamicField($event: { event: any }, item: any) {
    this.myNewScope.dynamicFields?.set(item.setting.name, $event.event);
  }

  onSelectSecondParty($event: {event: SecondParty; componentId?: any}) {
    this.myNewScope.secondParty = $event.event;
    this.myNewScope.secondPartyId = $event.event?.id;
    this.getSecondPartyById($event.event?.id);
    this.myNewScope.ratecard = null;
  }

  onSelectBrand($event: { event: Brand; componentId?: any }) {
    this.myNewScope.brand = $event.event;
    if ($event.event) {
      this.myNewScope.contact = $event.event.keyContact;
      this.myNewScope.contactId = $event.event.keyContact?.id;
      this.myNewScope.location = $event.event.location;
      this.myNewScope.locationId = $event.event.location?.id;
      this.myNewScope.ratecard = null;
    }
    this.getRateCards()
  }

  selectScenario($event: { event: any; componentId?: any }) {
    this.myNewScope.scenario = $event.event;
    this.myNewScope.scenarioId = $event.event?.id;
  }

  onSelectKeyContact($event: { event: any; componentId?: any }) {
    this.myNewScope.contact = $event.event;
    this.myNewScope.contactId = $event.event?.id;
  }

  onSelectLocation($event: { event: any; componentId?: any }) {
    this.myNewScope.location = $event.event;
    this.myNewScope.locationId = $event.event?.id;
  }

  onSelectScopeTemplate($event: { event: any; componentId?: any }) {
    this.myNewScope.scopeTemplate = $event.event;
  }

  onSelectWordTemplate($event: { event: any; componentId?: any }) {
    this.myNewScope.outputTemplate = $event.event;
    this.myNewScope.outputTemplateId = $event.event?.id;
  }

  onSelectXlsxTemplate($event: { event: any; componentId?: any }) {
    this.myNewScope.xlsxTemplate = $event.event;
    this.myNewScope.xlsxTemplateId = $event.event?.id;
  }

  onSelectRateCard($event: { event: any; componentId?: any }) {
    this.myNewScope.ratecard = $event.event;
    if (this.myNewScope.ratecard) {
      let ratecardAssociations = [];
      if (!this.myNewScope.brand && this.selectedSecondParty.secondPartyRatecards.length > 0) {
        ratecardAssociations = this.selectedSecondParty.secondPartyRatecards;
      } else if (this.myNewScope.brand.ratecardAssociations.length > 0) {
        ratecardAssociations = this.myNewScope.brand.ratecardAssociations;
      }
      if (this.authService.loggedInUser?.company.hasApplicationSetting('COST_PLUS')) {
        this.initialCostPlusForRatecard(ratecardAssociations);
      }
    }
    if (this.id != null) {
      this.isDifferentRatecard = this.myNewScope.ratecard == null ||
        this.scopeToDuplicate.identity.rateCard.rateCardIdentity.id != this.myNewScope.ratecard.rateCardIdentity.id;
      if (!this.isDifferentRatecard) {
        this.isDifferentRatecardVersion =
          this.scopeToDuplicate.identity.rateCard.version != this.myNewScope.ratecard.version;
      }
    }
  };

  initialCostPlusForRatecard(ratecardAssociations: any) {
    ratecardAssociations.forEach(ratecardAssociation => {
      if (ratecardAssociation.ratecardIdentity.id === this.myNewScope.ratecard.rateCardIdentity.id) {
        if (ratecardAssociation.costPlus) {
          this.myNewScope.costPlusId = ratecardAssociation.costPlus.id;
        } else {
          this.myNewScope.costPlusId = null;
        }
      }
    });
  }

  findLocationByISO(iso2: any) {
    return this.countryCodeService.getCountryByISO2(iso2)
  }

  updateTrafficSystemMetadataField(uuid: any) {
    let val = this.trafficMetaDataFieldTemp[uuid];
    if( this.myNewScope.trafficSystemMetadata == undefined) {
      this.myNewScope.trafficSystemMetadata = {}
    }
    this.myNewScope.trafficSystemMetadata[uuid] = {
      name: val, value: val
    }
  }

  onSearchTrafficField(event: { searchString: string }, secondPartyId: number, rateCardId: number, uuid: string) {
    this.trafficSystemMetadataSearch$[uuid] = this.scopeMetadataService.callScopeMetadataLookupUrl(uuid, secondPartyId, rateCardId, event.searchString);
  }

  onSelectBenchmarkRateCard($event: { event: any; componentId?: any }) {
    this.myNewScope.benchmarkRateCard = $event.event;
  }

  getSelectedLanguageName() {
    return this.languages.find(p => p.value === this.myNewScope.language)?.name
  }

  validateDate() {
    if (!this.myNewScope.startDate) {
      this.myNewScope.startDate = new Date();
    }
    if (!this.myNewScope.endDate) {
      this.myNewScope.endDate = new Date();
      this.myNewScope.endDate.setMonth(this.myNewScope.startDate.getMonth() + 3);
    }
  }

  getContacts() {
    let contacts: any[];
    if (this.myNewScope.brand) {
      let _this = this;
      contacts = this.contacts.filter(function(contact){
        return contact.location?.id === _this.myNewScope.brand!.location?.id
      })
    } else {
      contacts = this.contacts || []
    }
    return contacts
  }

  getLocations() {
    let locations: any[];
    if (this.myNewScope.brand) {
      let _this = this;
      locations = this.selectedSecondParty?.locations?.filter(function(location: any){
        return location.id === _this.myNewScope.brand!.location?.id
      }) || []
    } else {
      locations = this.locations || []
    }
    return locations
  }

  getBudgetLabel() {
    return 'Budget' + (this.myNewScope.ratecard?.currencyCode ? ` (${this.myNewScope.ratecard?.currencyCode})` : '');
  }

  getTPCBudgetLabel() {
    return 'Third Party Cost Budget' + (this.myNewScope.ratecard?.currencyCode ? ` (${this.myNewScope.ratecard?.currencyCode})` : '');
  }

  saveDetails() {
    if(this.isFormValid() && this.isDynamicFieldsValid()) {
      this.validateDate();
      if (this.myNewScope.dynamicFields) {
        this.myNewScope.dynamicFields = Object.fromEntries(this.myNewScope.dynamicFields);
      }
      if (this.myNewScope.brand != null) {
        this.myNewScope.brandId = this.myNewScope.brand.id;
      }
      if (this.myNewScope.budget == null) {
        this.myNewScope.budget = 0;
      }
      if (this.myNewScope.thirdPartyCostBudget == null) {
        this.myNewScope.thirdPartyCostBudget = 0;
      }
      if (this.createNewScope) {
        let withScopeTemplate = (this.myNewScope.scopeTemplate != null);
        if (withScopeTemplate) {
          this.createNewScopeFromTemplate();
        } else {
          this.myNewScope.identificationType = this.isScopeByRole ? 'SCOPE_BY_ROLE' : this.isScopeByScenario ? 'SCOPE_BY_SCENARIO' : 'SCOPE_BY_WORK';
          this.postNewScope()
        }
      } else {
        this.duplicateExistingScope()
      }
    }
  }

  postNewScope() {
    this.loading = true
    this.myScopeService
      .createScope(this.myNewScope)
      .pipe(this.destroy$())
      .subscribe({
        next: (scope: ScopeVersion) => {
          setTimeout(() => {
            this.router.navigateByUrl(this.routeUtils.urlScopeOverview(scope.identity.id))
            this.loading = false
          }, 500)
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred while creating ${this.lang.get('scope|l')}`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.ERROR)
          this.loading = false
        }
      });
  };

  createNewScopeFromTemplate() {
    this.myNewScope.scopeTemplateId = this.myNewScope.scopeTemplate.identity.id
    this.myNewScope.identificationType = 'SCOPE_BY_WORK';
    let selectedRateCard = this.myNewScope.ratecard;
    let templateRateCard = this.myNewScope.scopeTemplate.identity.rateCard;
    let sameRateCardIdentity = (selectedRateCard.rateCardIdentity.id == templateRateCard.rateCardIdentity.id);
    let sameRateCardVersion = sameRateCardIdentity && (selectedRateCard.id == templateRateCard.id);

    if (sameRateCardVersion) {
      this.postNewScope();
    } else {
      let dialog = this.dialog.open(MapScopeRolesComponent)
      dialog.componentInstance.isTemplate = true
      dialog.componentInstance.myNewScope = this.myNewScope
      this.store.select(ScopingSelectors.selectDeliverablesByScope(this.myNewScope.scopeTemplate.identity.id)).pipe(this.destroy$())
        .subscribe((deliverables: Deliverable[]) => {
          dialog.componentInstance.deliverables = deliverables
        })
      this.store.select(ScopingSelectors.selectRateCard).pipe(this.destroy$())
        .subscribe((rateCard: RatecardVersion) => {
          dialog.componentInstance.ratecard = rateCard
        })

      this.store.dispatch(ScopeActions.getDeliverables({ scopeId: this.myNewScope.scopeTemplate.identity.id }))
      this.store.dispatch(ScopeActions.getRateCard({ rateCardId: this.myNewScope.ratecard.rateCardIdentity.id }))

      dialog.componentInstance.onConfirm.subscribe((scope: any) => {
        dialog.close()
        this.myNewScope = scope
        // TODO new template path
        //this.isTemplate ? this.postNewTemplate() : this.postNewScope()
        this.postNewScope()
      });
    }
  }

  duplicateExistingScope() {
    this.myNewScope.identificationType = this.scopeToDuplicate.identity.identificationType;
    if (this.isDifferentRatecard) {
      let dialog = this.dialog.open(MapScopeRolesComponent)
      dialog.componentInstance.myNewScope = this.myNewScope
      dialog.componentInstance.scopeToDuplicate = this.scopeToDuplicate
      this.store.select(ScopingSelectors.selectDeliverablesByScope(this.scopeToDuplicate.identity.id)).pipe(this.destroy$())
        .subscribe((deliverables: Deliverable[]) => {
          dialog.componentInstance.deliverables = deliverables
        })
      this.store.select(ScopingSelectors.selectRateCard).pipe(this.destroy$())
        .subscribe((rateCard: RatecardVersion) => {
          dialog.componentInstance.ratecard = rateCard
        })

      this.store.dispatch(ScopeActions.getDeliverables({ scopeId: this.scopeToDuplicate.identity.id }))
      this.store.dispatch(ScopeActions.getRateCard({ rateCardId: this.myNewScope.ratecard.rateCardIdentity.id }))

      dialog.componentInstance.onConfirm.subscribe((scope: any) => {
        dialog.close()
        this.myNewScope = scope
        // TODO new template path
        //this.isTemplate ? this.postNewTemplate() : this.postNewScope()
        this.postNewScope()
      });
    } else if (this.isDifferentRatecardVersion) {
      let dialog = this.dialog.open(DuplicateDifferentRatecardVersionComponent, {
        width: '575px'
      })
      dialog.componentInstance.scopeToDuplicate = this.scopeToDuplicate

      dialog.componentInstance.onUseLatest.subscribe(() => {
        this.myNewScope.ratecard = this.companyOwnRateCards.find((r) => r.rateCardIdentity.id === this.myNewScope.ratecard.rateCardIdentity.id);
        dialog.close()
        this.postNewScope();
      });
      dialog.componentInstance.onUseOld.subscribe(() => {
        dialog.close()
        this.myNewScope.ratecard = this.scopeToDuplicate.identity.rateCard
        // TODO new template path
        //this.isTemplate ? this.postNewTemplate() : this.postNewScope()
        this.postNewScope();
      });
    } else {
      // TODO new template path
      //this.isTemplate ? this.postNewTemplate() : this.postNewScope()
      this.postNewScope()
    }
  };

  isFormValid() {
    return this.myNewScope && this.myNewScope.secondPartyId && this.myNewScope.ratecard &&
      this.myNewScope.startDate !== null && this.myNewScope.endDate !== null && !_.isEmpty(this.myNewScope.name) &&
      (!this.isScopeByScenario || this.myNewScope.scenario)
  }

  isDynamicFieldsValid() {
    if (this.isExternalDataLoading || this.isDynamicFieldsLoading) return false
    if (this.scopeDynamicFieldSettings)
      return this.scopeDynamicFieldSettings
        .filter(s => s.mandatory)
        .every(s => this.myNewScope.dynamicFields?.get(s.name) != null);

    return true;
  }

  officeLocationDisplayName(item: any) {
    return item ? `${item.name} (${item.countryName}, ${item.region.charAt(0).toUpperCase() + item.region.slice(1).toLowerCase()})` : '';
  }

  locationDisplayName = (item?: OfficeLocation) => {
    if (item) {
      let country = this.findLocationByISO(item.country);
      return `${item.location} (${item.getAddress()} ${country.Country_Name}, ${country.Continent})`;
    }
    return '';
  }

  contactDisplayName(item: any): string {
    return item?.contactName || '';
  }

  locationSimpleName(item?: OfficeLocation) {
    return item?.location || '';
  }

  trafficFieldOption(item: any) {
    return item ? `${item.name} (${item.description})` : '';
  }

  selectOfficeLocation($event: { event: any; componentId?: any }) {
    if ($event.event) {
      this.myNewScope.officeLocation = $event.event;
      this.myNewScope.officeLocationId = $event.event.id;
      this.getOfficeLocation($event.event.id);
    } else {
      this.selectedOfficeLocation = null;
      this.selectedSecondParty = null;
      this.myNewScope.secondParty = null;
      this.myNewScope.brand = null;
      this.myNewScope.contact = null;
      this.myNewScope.location = null;
      this.myNewScope.outputTemplate = null;
    }
    this.locationsCount = this.getLocations().length
  }

  selectTrafficField($event: { event: any; componentId?: any }, uuid: string) {
    if(this.myNewScope.trafficSystemMetadata == undefined) {
      this.myNewScope.trafficSystemMetadata = {}
    }
    this.myNewScope.trafficSystemMetadata[uuid] = $event.event
  }

  backToScopes() {
    this.router.navigateByUrl('/scopes')
  }

  scopeByRoleEnabled() {
    return this.authService.loggedInUser?.company.hasApplicationSetting('SCOPE_BY_ROLE__ENABLE')
  }

  scopeByScenarioEnabled() {
    return this.authService.loggedInUser?.company.hasApplicationSetting('SCOPE_BY_SCENARIO')
  }

  onSelectLanguage($event: any) {
    this.myNewScope.language = $event.value
  }

  getExistingScope() {
    this.loading = true
    this.scopeOverviewService.getScope(this.id).pipe(this.destroy$())
      .subscribe({
        next: (scopeResponse: ScopeVersion) => {
          this.loading = false
          this.scopeToDuplicate = scopeResponse;
          if (this.scopeToDuplicate.identity.costPlus) {
            this.myNewScope.costPlusId = this.scopeToDuplicate.identity.costPlus.id;
          }
          this.myNewScope.name = this.scopeToDuplicate.name;
          this.myNewScope.language = this.scopeToDuplicate.identity.language
          this.setSelectedLanguage()
          this.myNewScope.startDate = this.scopeToDuplicate.startDate;
          this.myNewScope.endDate = this.scopeToDuplicate.endDate;
          if (this.scopeToDuplicate.identity.scopeType != null) {
            this.myNewScope.scopeType = this.scopeToDuplicate.identity.scopeType;
            this.myNewScope.scopeTypeId = this.scopeToDuplicate.identity.scopeType.id;
          }
          this.myNewScope.secondPartyId = this.scopeToDuplicate.identity.secondParty.id;
          this.myNewScope.secondParty = this.scopeToDuplicate.identity.secondParty;
          if (this.scopeToDuplicate.identity.brand != null) {
            this.myNewScope.brandId = this.scopeToDuplicate.identity.brand.id;
            this.myNewScope.brand = this.scopeToDuplicate.identity.brand;
          }
          if (this.scopeToDuplicate.identity.scopeTemplate != null) {
            this.myNewScope.scopeTemplate = this.scopeToDuplicate.identity.scopeTemplate;
          }
          this.myNewScope.ratecard = this.scopeToDuplicate.identity.rateCard
          if (this.scopeToDuplicate.budget != null) {
            this.myNewScope.budget = this.scopeToDuplicate.budget.amount;
          }
          if (this.scopeToDuplicate.thirdPartyCostBudget != null) {
            this.myNewScope.thirdPartyCostBudget = this.scopeToDuplicate.thirdPartyCostBudget.amount;
          }
          if (this.scopeToDuplicate.identity.template != null) {
            this.myNewScope.outputTemplate = this.scopeToDuplicate.identity.template;
            this.myNewScope.outputTemplateId = this.scopeToDuplicate.identity.template.id;
          }
          if (this.scopeToDuplicate.identity.xlsxTemplate != null) {
            this.myNewScope.xlsxTemplate = this.scopeToDuplicate.identity.xlsxTemplate;
            this.myNewScope.xlsxTemplateId = this.scopeToDuplicate.identity.xlsxTemplate.id;
          }
          if (this.scopeToDuplicate.identity.contact != null) {
            this.myNewScope.contact = this.scopeToDuplicate.identity.contact;
            this.myNewScope.contactId = this.scopeToDuplicate.identity.contact.id;
          }
          if (this.scopeToDuplicate.identity.location != null) {
            this.myNewScope.location = this.scopeToDuplicate.identity.location;
            this.myNewScope.locationId = this.scopeToDuplicate.identity.location.id;
          }
          this.getSecondParty(this.myNewScope.secondPartyId)
          this.isDifferentRatecardVersion = scopeResponse.identity.rateCard.version !=
            scopeResponse.identity.rateCard.rateCardIdentity.currentVersionNumber;
          this.initiateScopeVersionCustomFields()

          if (this.authService.loggedInUser?.company.hasApplicationSetting('SCOPE_DYNAMIC_FIELDS')) {
            this.myNewScope.dynamicFields = new Map(this.scopeToDuplicate.dynamicFields ? toPairs(this.scopeToDuplicate.dynamicFields) : [])
            this.initialScopeDynamicFieldSettings()
          }

          this.myNewScope.trafficSystemMetadata = scopeResponse.trafficSystemEntityMetadata
          Object.entries(this.myNewScope.trafficSystemMetadata).forEach(([key, value]: [string, any]) => {
            this.trafficMetaDataFieldTemp[key] = value.value;
          });
          this.myNewScope.defaultView = this.scopeToDuplicate.defaultView
          this.myNewScope.scenarioId = this.scopeToDuplicate.identity.scenario?.id
          this.myNewScope.configuration = this.scopeToDuplicate.configuration
          this.myNewScope.configurationSubmitted = this.scopeToDuplicate.configurationSubmitted
          this.myNewScope.componentModifierValues = this.scopeToDuplicate.componentModifierValues
        },
        error: () => {
          this.snackbarService.showSnackbar(`An error occurred while fetching the ${this.lang.get('scope|l')} to duplicate`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.ERROR)
          this.loading = false
        }
      })
  }

  goToStep(step: number) {
    if (this.isFormValid()) {
      this.step = step
    }
  }

  onSelectScopeType($event: { event: any; componentId?: any }) {
    this.myNewScope.scopeType = $event.event
    this.myNewScope.scopeTypeId = $event.event?.id
  }

  protected readonly DynamicFieldType = DynamicFieldType
}

import { Action, createReducer, on } from '@ngrx/store'
import {
  ArchiveFolderSuccessAction,
  CreateFolderSuccessAction,
  DeleteFolderSuccessAction,
  GetAllFoldersSuccessAction,
  GetArchivedFoldersSuccessAction,
  GetFoldersColumnsSuccessAction,
  GetFoldersSuccessAction,
  GetGroupSettingsSuccessAction,
  LibraryManagementFolder,
  OpenMoveFolderModalAction,
  SetArchivedFoldersPageAction,
  SetArchivedFoldersSortAction,
  SetFoldersDialogIdAction,
  SetFoldersPageAction,
  SetFoldersSortAction,
  SetGroupSettingsSuccessAction,
  UpdateFolderSuccessAction,
} from '@app/features/library-management/store/models/library-folders'
import {
  LibraryItemTypes,
  LibraryManagementActions,
  LibraryManagementFoldersActions,
  LibraryManagementItemsActions,
  libraryFoldersAdapter,
  libraryManagementFoldersInitialState,
  libraryManagementPaginationInitialState,
} from '@app/features/library-management/store'
import { find, join } from 'lodash'

import { LibraryManagementFoldersState } from '@app/features/library-management/store/models/library-folders'
import { plainToInstance } from 'class-transformer'

const loadingTrueHandler = (state: LibraryManagementFoldersState) => ({ ...state, isLoading: true })
const loadingFalseHandler = (state: LibraryManagementFoldersState) => ({ ...state, isLoading: false })

const getAllFoldersHandler = (state: LibraryManagementFoldersState) => ({
  ...state,
  isLoadingAllFolders: true,
})
const getAllFoldersFailHandler = (state: LibraryManagementFoldersState) => ({
  ...state,
  isLoadingAllFolders: false,
})
const getAllFoldersSuccessHandler = (state: LibraryManagementFoldersState, action: GetAllFoldersSuccessAction) => ({
  ...state,
  isLoadingAllFolders: false,
  isLoadedAllFolders: true,
  allFoldersList: libraryFoldersAdapter.setAll(action.list, state.list),
})

const getFoldersSuccessHandler = (state: LibraryManagementFoldersState, action: GetFoldersSuccessAction) => ({
  ...state,
  isLoading: false,
  list: libraryFoldersAdapter.setAll(action.list, state.list),
  pagination: { ...state.pagination, page: 0 },
})

const getArchivedFoldersHandler = (state: LibraryManagementFoldersState) => ({
  ...state,
  isLoadingArchived: true,
})

const getArchivedFoldersFailHandler = (state: LibraryManagementFoldersState) => ({
  ...state,
  isLoadingArchived: false,
})

const getArchivedFoldersSuccessHandler = (
  state: LibraryManagementFoldersState,
  action: GetArchivedFoldersSuccessAction
) => ({
  ...state,
  isLoadingArchived: false,
  archivedList: libraryFoldersAdapter.setAll(action.list, state.list),
  archivedPagination: { ...state.archivedPagination, page: 0 },
})

const setPageHandler = (state: LibraryManagementFoldersState, action: SetFoldersPageAction) => ({
  ...state,
  pagination: { ...state.pagination, page: action.page },
})

const setSortHandler = (state: LibraryManagementFoldersState, action: SetFoldersSortAction) => ({
  ...state,
  isLoading: true,
  sort: action.sort,
})

const setArchivedPageHandler = (state: LibraryManagementFoldersState, action: SetArchivedFoldersPageAction) => ({
  ...state,
  archivedPagination: { ...state.archivedPagination, page: action.page },
})

const setArchivedSortHandler = (state: LibraryManagementFoldersState, action: SetArchivedFoldersSortAction) => ({
  ...state,
  isLoadingArchived: true,
  archivedSort: action.sort,
})

const getColumnsHandler = (state: LibraryManagementFoldersState) => ({ ...state, isLoadingColumns: true })
const getColumnsFailHandler = (state: LibraryManagementFoldersState) => ({ ...state, isLoadingColumns: false })
const getColumnsSuccessHandler = (state: LibraryManagementFoldersState, action: GetFoldersColumnsSuccessAction) => ({
  ...state,
  columns: { ...state.columns, ...action.preferences },
  isLoadingColumns: false,
})

const setFolderIdHandler = (state: LibraryManagementFoldersState) => ({
  ...state,
  list: libraryFoldersAdapter.setAll([], state.list),
  archivedList: libraryFoldersAdapter.setAll([], state.archivedList),
  pagination: libraryManagementPaginationInitialState,
  archivedPagination: libraryManagementPaginationInitialState,
})

const setDialogIdHandler = (state: LibraryManagementFoldersState, action: SetFoldersDialogIdAction) => ({
  ...state,
  dialogId: action.id,
})

const createFolderSuccessHandler = (state: LibraryManagementFoldersState, action: CreateFolderSuccessAction) => ({
  ...state,
  list: libraryFoldersAdapter.addOne(
    plainToInstance(LibraryManagementFolder, { ...action.folder, libraryItemType: LibraryItemTypes.FOLDER }),
    state.list
  ),
})

const updateFolderSuccessHandler = (state: LibraryManagementFoldersState, action: UpdateFolderSuccessAction) => ({
  ...state,
  list: libraryFoldersAdapter.updateOne({ id: action.folder.id, changes: { name: action.folder.name } }, state.list),
  archivedList: libraryFoldersAdapter.updateOne(
    { id: action.folder.id, changes: { name: action.folder.name } },
    state.archivedList
  ),
})

const deleteFolderSuccessHandler = (state: LibraryManagementFoldersState, action: DeleteFolderSuccessAction) => ({
  ...state,
  isLoading: false,
  list: libraryFoldersAdapter.removeOne(action.folder.id, state.list),
  archivedList: libraryFoldersAdapter.removeOne(action.folder.id, state.archivedList),
})

const archiveFolderSuccessHandler = (state: LibraryManagementFoldersState, action: ArchiveFolderSuccessAction) => ({
  ...state,
  isLoading: false,
  ...(state.list.entities[action.folder.id]
    ? {
        list: libraryFoldersAdapter.removeOne(action.folder.id, state.list),
        archivedList: libraryFoldersAdapter.addOne(action.folder, state.archivedList),
      }
    : {
        list: libraryFoldersAdapter.addOne(action.folder, state.list),
        archivedList: libraryFoldersAdapter.removeOne(action.folder.id, state.archivedList),
      }),
})

const openMoveFolderModalHandler = (state: LibraryManagementFoldersState, action: OpenMoveFolderModalAction) => ({
  ...state,
  folderToMove: action.folder,
})
const closeMoveFolderModalHandler = (state: LibraryManagementFoldersState) => ({
  ...state,
  folderToMove: undefined,
})

const getGroupSettingsSuccessHandler = (
  state: LibraryManagementFoldersState,
  { groupSettings }: GetGroupSettingsSuccessAction
) => ({ ...state, isLoading: false, groupSettings })

const setGroupSettingsSuccessHandler = (
  state: LibraryManagementFoldersState,
  { id, groupSettings }: SetGroupSettingsSuccessAction
) => {
  const isSharedByCompanies = !!find(groupSettings, { isShared: true })
  const sharedCompaniesNames = isSharedByCompanies
    ? join(
        groupSettings.filter((g) => g.isShared).map((g) => g.companyGroupName),
        ','
      )
    : undefined
  const changes = { id, changes: { isSharedByCompanies, sharedCompaniesNames } }

  return {
    ...state,
    isLoading: false,
    groupSettings: undefined,
    list: libraryFoldersAdapter.updateOne(changes, state.list),
    archivedList: libraryFoldersAdapter.updateOne(changes, state.archivedList),
  }
}

export function libraryManagementFoldersReducer(
  state = libraryManagementFoldersInitialState,
  action: Action
): LibraryManagementFoldersState {
  return createReducer(
    libraryManagementFoldersInitialState,
    on(
      LibraryManagementFoldersActions.getFolders,
      LibraryManagementFoldersActions.archiveFolder,
      LibraryManagementFoldersActions.deleteFolder,
      LibraryManagementActions.getFolderSuccess,
      loadingTrueHandler
    ),
    on(
      LibraryManagementFoldersActions.getFoldersFail,
      LibraryManagementFoldersActions.archiveFolderFail,
      LibraryManagementFoldersActions.deleteFolderFail,
      loadingFalseHandler
    ),
    on(LibraryManagementFoldersActions.getAllFoldersTrigger, getAllFoldersHandler),
    on(LibraryManagementFoldersActions.getAllFoldersFail, getAllFoldersFailHandler),
    on(LibraryManagementFoldersActions.getAllFoldersSuccess, getAllFoldersSuccessHandler),
    on(LibraryManagementFoldersActions.getFoldersSuccess, getFoldersSuccessHandler),
    on(
      LibraryManagementFoldersActions.getArchivedFolders,
      LibraryManagementActions.getFolderSuccess,
      getArchivedFoldersHandler
    ),
    on(
      LibraryManagementFoldersActions.getArchivedFoldersFail,
      LibraryManagementActions.getFolderSuccess,
      getArchivedFoldersFailHandler
    ),
    on(LibraryManagementFoldersActions.getArchivedFoldersSuccess, getArchivedFoldersSuccessHandler),
    on(LibraryManagementFoldersActions.setPage, setPageHandler),
    on(LibraryManagementFoldersActions.setSort, setSortHandler),
    on(LibraryManagementFoldersActions.setArchivedPage, setArchivedPageHandler),
    on(LibraryManagementFoldersActions.setArchivedSort, setArchivedSortHandler),
    on(LibraryManagementFoldersActions.getColumns, getColumnsHandler),
    on(LibraryManagementFoldersActions.getColumnsFail, getColumnsFailHandler),
    on(
      LibraryManagementFoldersActions.getColumns,
      LibraryManagementFoldersActions.updateColumnPreference,
      getColumnsHandler
    ),
    on(
      LibraryManagementFoldersActions.getColumnsFail,
      LibraryManagementFoldersActions.updateColumnPreferenceFail,
      getColumnsFailHandler
    ),
    on(
      LibraryManagementFoldersActions.getColumnsSuccess,
      LibraryManagementFoldersActions.updateColumnPreferenceSuccess,
      getColumnsSuccessHandler
    ),
    on(LibraryManagementActions.setFolderId, setFolderIdHandler),
    on(LibraryManagementFoldersActions.setDialogId, setDialogIdHandler),
    on(
      LibraryManagementFoldersActions.createFolderSuccess,
      LibraryManagementItemsActions.createFolderAndMoveLibraryItemSuccess,
      createFolderSuccessHandler
    ),
    on(LibraryManagementFoldersActions.updateFolderSuccess, updateFolderSuccessHandler),
    on(LibraryManagementFoldersActions.archiveFolderSuccess, archiveFolderSuccessHandler),
    on(LibraryManagementFoldersActions.deleteFolderSuccess, deleteFolderSuccessHandler),
    on(LibraryManagementFoldersActions.openMoveFolderModal, openMoveFolderModalHandler),
    on(LibraryManagementFoldersActions.closeMoveFolderModal, closeMoveFolderModalHandler),
    on(LibraryManagementFoldersActions.getGroupSettingsSuccess, getGroupSettingsSuccessHandler),
    on(LibraryManagementFoldersActions.setGroupSettingsSuccess, setGroupSettingsSuccessHandler)
  )(state, action)
}

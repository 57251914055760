import { Component, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedModule } from '@app/shared/shared.module';
import { FormulaBuilderComponent } from '@shared/components/formula-builder/formula-builder.component';
import {
  ComponentEntryComplexityRole
} from '@app/features/company-management/models/component-entry-complexity-role.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import {
  ScopeUiCheckboxComponent
} from '@shared/components/ui-components/scope-ui-checkbox/scope-ui-checkbox.component';
import { SvgIconComponent } from '@shared/components/svg-icon/svg-icon.component';
import { SortDirection } from '@core/model/enums/sort-direction.enum';
import { ComplexitiesList } from '@core/model/enums/complexity.enum';

export interface OverrideModifiersModalConfig {
  overrideComplexities: ComponentEntryComplexityRole[],
  callback: (excludeComplexities: number[]) => void
}

@Component({
  selector: 'override-modifiers-modal',
  standalone: true,
  imports: [CommonModule, SharedModule, FormulaBuilderComponent, ScopeUiCheckboxComponent, SvgIconComponent],
  templateUrl: './override-modifiers-modal.component.html',
  styleUrls: ['./override-modifiers-modal.component.scss'],
})
export class OverrideModifiersModalComponent {
  dataSource: MatTableDataSource<ComponentEntryComplexityRole>
  excludeComplexities: Set<number> = new Set()
  sortDirection: string
  sortedColumn: string
  showFormula: { [key: number] : boolean } = {}

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort
    this.setSortingDataAccessor()
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OverrideModifiersModalConfig
  ) {
    this.dataSource = new MatTableDataSource(data.overrideComplexities)
    this.clear()
  }

  setSortingDataAccessor() {
    this.dataSource.sortData = (data: any[], sort: MatSort) => {
      const active = sort.active
      const direction = sort.direction
      if (!active || direction == SortDirection.NONE) return data

      return data.sort((a, b) => {
        let valueA = a[active]
        let valueB = b[active]

        if (active === 'complexity') {
          valueA = ComplexitiesList.indexOf(valueA)
          valueB = ComplexitiesList.indexOf(valueB)
          let comparatorResult = 0
          if (valueA > valueB) comparatorResult = 1
          else if (valueA < valueB) comparatorResult = -1
          return comparatorResult * (direction == SortDirection.ASC ? 1 : -1)
        }

        return valueA.localeCompare(valueB) * (direction == SortDirection.ASC ? 1 : -1)
      })
    }
  }

  clear() {
    this.excludeComplexities = new Set(this.data.overrideComplexities.map((c) => c.id))
  }

  selected(element: ComponentEntryComplexityRole) {
    return !this.excludeComplexities.has(element.id)
  }

  select(element: ComponentEntryComplexityRole) {
    if (this.excludeComplexities.has(element.id)) {
      this.excludeComplexities.delete(element.id)
    } else {
      this.excludeComplexities.add(element.id)
    }
  }

  selectAll() {
    this.excludeComplexities = new Set()
  }

  submit() {
    this.data.callback([...this.excludeComplexities])
  }

  onSortChange(event: Sort) {
    this.sortDirection = event.direction
    this.sortedColumn = event.active
  }
}

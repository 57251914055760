import { LibraryItemType, LibraryItemTypes } from '@app/features/library-management/store/enums'

import { LibraryManagementEntry } from './library-management-entry.model'
import { Privileged } from '@app/core/class/privileged.class'
import { Type } from 'class-transformer'
import { User } from '@app/core/model/user.model'

export class LibraryManagementItem extends Privileged {
  id: number
  createdTs: string
  updatedTs: string
  @Type(() => User) createdBy: User
  @Type(() => User) updatedBy: User
  company: LibraryItemCompany
  name: string
  archived: boolean
  deleted: boolean
  itemType: LibraryItemType
  language: string
  privilegeCategories: string[]
  libraryComponentEntries: LibraryManagementEntry[]
  libraryDeliverableEntrySet: LibraryManagementEntry[]
  fixedPricing: boolean
  path: string
  libraryItemType: LibraryItemTypes
  folder: boolean
  version?: number
  cascadeLibrary?: boolean
  unitCost?: number
  libraryFolder?: { id: number; name: string }
  isLoading?: boolean
}

interface LibraryItemCompany {
  id: number
  name: string
  child: boolean
}

<loader-modal *ngIf="isApprovalLoading || (loadingAllTasks$ | async)"></loader-modal>
<ng-container *ngIf="currentScope$ | async as currentScope">
  <div class="flex flex-col h-full">
    <div class="scope-overview-header" *ngIf="currentScope">
      <!-- title -->
      <div class="scope-overview-header-title">
        <div class="scope-name">
          <i class="material-symbols-rounded" *ngIf="currentScope.identity.isTemplate">copy_all</i>
          <i class="material-symbols-rounded" *ngIf="!currentScope.identity.isTemplate && !currentScope.isScopeByRole() && !currentScope.isScopeByScenario()">equalizer</i>
          <i class="material-symbols-rounded" *ngIf="!currentScope.identity.isTemplate && currentScope.isScopeByRole()">table_chart</i>
          <i class="material-symbols-rounded" *ngIf="!currentScope.identity.isTemplate && currentScope.isScopeByScenario()">alt_route</i>
          <h3 class="product-heading-bold primary-blue-violet-p pt-1.5" *ngIf="!editName">{{currentScope.name}}</h3>
          <mat-icon (click)="editName = true; $event.stopPropagation()" *ngIf="!editName && scopeApprovalFlowService.isScopeEditable(currentScope)"
                    class="material-symbols-rounded fill orange text-base ml-1.5 pointer">mode_edit</mat-icon>
          <scope-ui-input
            *ngIf="editName"
            class="edit-name"
            (click)='$event.stopPropagation()'
            [initialValue]="currentScope.name"
            [autofocus]='true'
            (onInputBlurChange)="updateScopeName($event, currentScope)"
          />
        </div>
      </div>

      <!-- Scope Status -->
      <div class="font-newJuneBold">
        <div class="text-xl mb-2" [class]="getCellClass(currentScope.status)">
          {{ currentScope.getStatusText() }}
        </div>
        <div class="text-xs">
          <span class="neutral-black">Created by:</span> {{ currentScope.createdBy.getFullname() }}
        </div>
      </div>

      <div class="scope-number-date font-newJuneBold">
        <div class="text-xl flex">
          <span
            class="primary-blue-violet cursor-pointer sow-link" (click)="redirectToSow(currentScope.scopeOfWorkVersion.identity.id)"
            *ngIf="currentScope.scopeOfWorkVersion"
            >F-{{ currentScope.scopeOfWorkVersion.identity.localId }}</span
          >
          <span
            *ngIf="currentScope.scopeOfWorkVersion"
            class="material-symbols-rounded mx-1 primary-blue-violet-p cursor-default">
            arrow_forward
          </span>
          <span class="primary-blue-violet-p">S-{{ currentScope.identity.localId }}</span>
        </div>
        <div class="text-xs">
          <span class="neutral-black">Date created:</span> {{ currentScope.createdTs | dateFormat: 'shortDate' }}
        </div>
      </div>
    </div>

    <description-editor
      class="scope-description px-10"
      *ngIf="currentScope"
      [(visible)]="editScopeDescription"
      [model]="currentScope"
      [editable]='scopeApprovalFlowService.isScopeEditable(currentScope)'
      (onSaveDescription)="saveScopeDescription($event, currentScope)"
      (onSaveNote)="saveScopeNote($event, currentScope)" />

    <details-overview
      class="pt-2"
      *ngIf="currentScope"
      [scopeVersion]="currentScope"
      [scopeRetainedHoursStats]="scopeRetainedHoursStats$ | async"
      [sowRetainedHoursStats]="sowRetainedHoursStats$ | async"
      [trafficMetadataLookupFields]="userSettingsService.getTrafficMetadataFields(this.loggedInUser)"
      [thirdPartyCostBalance]="thirdPartyCostBalance$ | async"
      (onVersionChange)="changeVersion($event)" />

    <div
      class="scope-page flex-1 pt-2 {{ currentTab === 'Configuration' ? 'overflow-auto' : '' }}"
      *ngIf="currentScope">
      <scope-ui-tabs
        [fontSize]="'14px'"
        (onSelect)="selectTab($event)"
        (onInit)="selectTab($event)"
        [confirm]="confirmTab">
        <scope-ui-tab
          *ngIf="currentScope.identity.scenario"
          tabTitle="Configuration"
          class="scope-tab white-background overflow-auto"
          [active]='route.snapshot.queryParamMap.get("tab") === "configuration"'>
          <ng-container *ngIf="currentTab === 'Configuration'">
            <scope-configuration [currentScope]="currentScope" (onSubmit)="selectTab('Scope')"/>
          </ng-container>
        </scope-ui-tab>

        <scope-ui-tab
          tabTitle="Scope"
          class="scope-tab white-background"
          [active]="currentTab === 'Scope'"
          [disabled]="scopeApprovalFlowService.isStateConfigDraft(currentScope.status)"
          [default]="true">
          <scope-tab
            *ngIf="(currentScope?.identity.identificationType === 'SCOPE_BY_WORK' || currentScope?.identity.identificationType === 'SCOPE_BY_SCENARIO') && currentTab === 'Scope'"
            [scopeVersion]="currentScope"
            [availableRoles]="removeDuplicates(availableRoles$ | async)"
            [breadcrumbs]="findReplaceBreadcrumbs$ | async"
            [isLoading]="isFindReplaceLoading$ | async"
            [deliverablesCount]="(findReplaceBreadcrumbs$ | async)?.length"
            [filteredRoles]="findReplaceService.filteredRoles$ | async"
            [revertSummaryResponse]="revertSummaryResponse$ | async"
            [replaceSummarySuccess]="replaceSummarySuccess$ | async"
            [replaceSummaryResponse]="replaceSummaryResponse$ | async"
            [revertSummarySuccess]="revertSummarySuccess$ | async"
            [availableDeliverables]="availableDeliverables$ | async"
            [availableComponents]="availableComponents$ | async"
            [availableDepartments]="removeDuplicates(availableDepartments$ | async)"
            [filters$]="scopeOverviewFilters$"
            [searchedComponents$]="searchedComponents$"
            [searchedDepartments$]="searchedDepartments$"
            [searchedRoles$]="searchedRoles$"
            [showFilterView]="showFilterView"
            [filteredSections$]="filteredSections$"
            [filteredDeliverables$]="filteredDeliverables$"
            [filteredComponents$]="filteredComponents$"
            [filteredDepartments$]="filteredDepartments$"
            [filteredRoles$]="filteredRoles$"
            [filteredScopeFees$]="filteredScopeFees$"
            [filteredDeliverableFees$]="filteredDeliverableFees$"
            [filteredComponentFees$]="filteredComponentFees$"
            [filteredDeliverableTPCs$]="filteredDeliverableTPCs$"
            [filteredDeliverableSectionTPCs$]="filteredDeliverableSectionTPCs$"
            [filteredComponentTPCs$]="filteredComponentTPCs$"
            [filterPreferences]="scopeColumnFilterPreferences$ | async"
            [currentUser]="loggedInUser"
            [currentRatecard]="rateCard$ | async"
            [showUseRetainedTeamToggle]="showUseRetainedTeamToggle"
            [scopeRetainedHoursStats]="scopeRetainedHoursStats$ | async"
            [cdnConfig]="cdnConfig"
            (onSearch)="onSearch($event)"
            (onSearchReplaceRoleEvent)="onSearchReplaceRole($event)"
            (onSelectionChange)="onSelectionChange($event)"
            (onRevert)="onRevert($event)"
            (onSubmitFindReplace)="submitReplacedRoles($event)"
            (onAddScopeSection)="addScopeSection(currentScope)"
            (onArchiveScope)="archiveScope(currentScope)"
            (onUnarchiveScope)="unarchiveScope(currentScope)"
            (onCreateDeliverable)="createDeliverable(currentScope)"
            (onCreateFixedFee)="createDeliverable(currentScope, true)"
            (onFetchDeliverable)="fetchDeliverable($event)"
            (onUpdateDeliverable)="updateDeliverable($event)"
            (onUpdateComponent)="updateComponent($event)"
            (onMoveComponent)="onMoveComponent($event,currentScope)"
            (onMoveComponentOutsideDeliverable)="onMoveComponentOutsideDeliverable($event,currentScope)"
            (onDeleteScope)="deleteScope(currentScope)"
            (onDeleteScopeSection)="deleteScopeSection($event, currentScope)"
            (onDuplicateDeliverable)="duplicateDeliverable($event)"
            (onDeleteDeliverable)="deleteDeliverable($event)"
            (onDeleteFee)="deleteFee($event)"
            (onSearchComponents)="onSearchComponents($event, currentScope)"
            (onSearchDepartments)="onSearchDepartments($event, currentScope)"
            (onSearchRoles)="onSearchRoles($event, currentScope)"
            (onFilterScope)="onFilterScopes($event, currentScope)"
            (onAcceptDeliverable)="acceptDeliverable($event)"
            (onRejectDeliverable)="rejectDeliverable($event)"
            (onAddFee)="addFee(currentScope.identity.rateCard.currencyCode, $event)"
            (onAddAndLinkFee)="addAndLinkFee(currentScope)"
            (onEditFee)="editFee($event, currentScope.identity.rateCard.currencyCode)"
            (onUpdateFee)="updateFee($event)"
            (onOverrideQuantity)="onOverrideQuantity($event.deliverable, $event.component)"
            (onOverrideRateCardRoleHours)="onOverrideRateCardRoleHours($event.deliverable, $event.component, $event.role)"
            (onTradeDeliverable)="tradeDeliverableModal($event, currentScope)"
            (onCompleteTrade)="completeTradeModal($event, currentScope)"
            (onStopProgressTrade)="stopProgressTradeModal($event, currentScope)"
            (onConfirmTrade)="confirmTradeModal($event)"
            (onCancelTrade)="cancelTradeModal($event, currentScope)"
            (onReopenTrade)="reopenTradeModal($event, currentScope)"
            (onRefreshActuals)="refreshActuals($event, currentScope)"
            (onAddDeliverableToTrade)="tradeDeliverableModal($event, currentScope)"
            (onShowDeliverableApplyLocationCardDialog)="showDeliverableApplyLocationCardDialog($event)"
            (onAddDiscount)="addDiscount(currentScope)"
            (onDeleteDiscount)="deleteDiscount(currentScope)"
            (onEditMsaLineItem)="editMsaLineItem(currentScope)"
            (onDeleteMsaLineItem)="deleteMsaLineItem($event, currentScope)"
          />
          <scope-by-role-tab
            *ngIf="currentScope?.identity.identificationType === 'SCOPE_BY_ROLE' && currentTab === 'Scope'"
            [scopeVersion]="currentScope"
            [currentUser]="loggedInUser"
            [currentRatecard]="rateCard$ | async"
            [cdnConfig]="cdnConfig"
            (onArchiveScope)="archiveScope(currentScope)"
            (onUnarchiveScope)="unarchiveScope(currentScope)"
            (onAddTask)="addTask(currentScope.identity.rateCard.id)"
            (onDeleteScope)="deleteScope(currentScope)"
            (onExport)="exportScopeByRole(currentScope)"
            (onDeleteDeliverable)="deleteDeliverable($event)"
            (onDeleteFee)="deleteFee($event)"
            (onAddFee)="addFee(currentScope.identity.rateCard.currencyCode, $event)"
            (onEditFee)="editFee($event, currentScope.identity.rateCard.currencyCode)"
            (onUpdateFee)="updateFee($event)"
            (onAddRole)="addRolesModal(currentScope)"
            (onAddDiscount)="addDiscount(currentScope)"
            (onDeleteDiscount)="deleteDiscount(currentScope)"
            (onEditMsaLineItem)="editMsaLineItem(currentScope)"
            (onDeleteMsaLineItem)="deleteMsaLineItem($event, currentScope)"
            (onAddThirdPartyCost)="addThirdPartyCost(currentScope)"
            (onUpdateThirdPartyCost)="updateThirdPartyCost($event)"
            (onDeleteThirdPartyCost)="deleteScopeThirdPartyCost($event)"
            (onAcceptDeliverable)="acceptDeliverable($event)"
            (onRejectDeliverable)="rejectDeliverable($event)"
          />
          <scope-approval-flow
            [stateChecks]="scopeApprovalFlowService.stateChecks"
            [currentUser]="loggedInUser"
            [currentApprovalState]="currentScope.status"
            [isTemplate]="currentScope.identity.isTemplate"
            [cdnConfig]="cdnConfig"
            [companyIntegrations]="companyIntegrations"
            [currentScope]="currentScope"
            (onWithdrawSubmission)="withdrawSubmissionModal()"
            (onSubmitDraft)="submitDraftScopeModal(currentScope)"
            (onCompleteReview)="completeReviewModal(currentScope)"
            (onAgreeToScope)="agreeToScopeModal(currentScope)"
            (onDeclineScope)="declineScopeModal()"
            (onAcceptScope)="acceptScopeModal()"
            (onRejectScope)="rejectScopeModal()"
            (onCloseScope)="closeScopeModal()"
            (onSendReminder)="sendNotificationReminder(currentScope)"
            (onAddTeamMember)="showAddTeamMemberModal(currentScope)"
            (onOpenExportModal)="openExportModal(currentScope)"
            (onTrafficExportModal)="openTrafficExportModal(currentScope)" />
        </scope-ui-tab>

        <scope-ui-tab tabTitle="Activity" [active]='route.snapshot.queryParamMap.get("tab") === "activity"'>
          <scope-activity
            *ngIf="currentTab === 'Activity'"
            [currentUser]='loggedInUser'
            [currentScope]="currentScope"
            [scopeId]="id"
            [cdnConfig]="cdnConfig"
            (onSelectTab)='selectTab($event)' />
        </scope-ui-tab>

        <scope-ui-tab requiredSetting="SCOPE__DETAILS" tabTitle="Details" [active]='route.snapshot.queryParamMap.get("tab") === "details"'>
          <app-scope-details
            *ngIf="currentTab === 'Details'"
            [loggedInUser]='loggedInUser'
            [currentScope]="currentScope"
            [officeLocations]="scopeDetailsService.officeLocations$ | async"
            [secondParties]="scopeDetailsService.secondParties$ | async"
            [benchmarkRateCards]="scopeDetailsService.benchmarkRateCardOptions$ | async"
            [selectedSecondParty]="scopeDetailsService.secondParty$ | async"
            [selectedOfficeLocation]="scopeDetailsService.officeLocation$ | async"
            [contacts]="(scopeDetailsService.secondParty$ | async)?.contacts || []"
            [locations]="(scopeDetailsService.secondParty$ | async)?.locations || []"
            [customFieldValueStructure]="(currentScope$ | async).scopeVersionCustomFieldValueStructure"
            [customFieldsDefinitionOutdated]="scopeDetailsService.customFieldsOutdated$ | async"
            [showUseRetainedTeamToggle]="showUseRetainedTeamToggle"
            [xlsxTemplates]="scopeDetailsService.xlsxTemplates$ | async"
            [trafficMetadataLookupFields]="userSettingsService.getTrafficMetadataFields(this.loggedInUser)"
            (onGetSecondParty)="scopeDetailsService.getSecondParty($event)"
            (onGetOfficeLocation)="scopeDetailsService.getOfficeLocation($event)"
            (onConfirmMetaStructureUpdate)="confirmMetaStructureUpdate($event)"
            (onSaveScope)="saveDetails($event)"></app-scope-details>
        </scope-ui-tab>

        <scope-ui-tab tabTitle="Team" [active]='route.snapshot.queryParamMap.get("tab") === "team"'>
          <app-team
            *ngIf="currentTab === 'Team'"
            [loggedInUser]='loggedInUser'
            [currentScope]="currentScope"
            [currentScope$]="currentScope$"
            [cdnConfig]="(cdnConfig$ | async)!"
            (onShowAddCollaboratorModal)="scopeTeamService.showAddCollaboratorModal(currentScope, cdnConfig)"
            (onShowAddReviewerModal)="scopeTeamService.showAddReviewerModal(currentScope, cdnConfig)"
            (onShowAddApproverModal)="scopeTeamService.showAddApproverModal(currentScope, cdnConfig)"
            (onShowAddTraffickerModal)="scopeTeamService.showAddTraffickerModal(currentScope, cdnConfig)"
            (onUpdateTeamMember)="scopeTeamService.updateTeamMember($event, currentScope)"
            (onDeleteCollaborator)="scopeTeamService.deleteCollaborator($event, currentScope)"
            (onDeleteReviewer)="scopeTeamService.deleteReviewer($event, currentScope)"
            (onDeleteApprover)="scopeTeamService.deleteApprover($event, currentScope)"
            (onDeleteTrafficker)="scopeTeamService.deleteTrafficker($event, currentScope)"></app-team>
        </scope-ui-tab>

        <scope-ui-tab tabTitle="Breakdown" [active]='route.snapshot.queryParamMap.get("tab") === "breakdown"'>
          <app-scope-breakdown *ngIf="currentTab === 'Breakdown'" [currentScope]="currentScope" [currentRatecard]="rateCard$ | async"></app-scope-breakdown>
        </scope-ui-tab>

        <scope-ui-tab tabTitle="Output Editor" [active]='route.snapshot.queryParamMap.get("tab") === "output"' class="white-background">
          <app-scope-output
            *ngIf="currentTab === 'Output Editor'"
            class="flex flex-col h-full"
            [currentScope]="currentScope"
            [loggedInUser]='loggedInUser'
            (onPreviewExport)="previewExport($event)">
          </app-scope-output>
        </scope-ui-tab>
      </scope-ui-tabs>
    </div>
  </div>
</ng-container>

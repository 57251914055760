export class ThirdPartyCostGroup {
  id: number
  name: string
  description: string
  nonTranslatedName: string
  nonTranslatedDescription: string
  parentId: number
  foreignSource: string
  scopeType: string
  groupType: string
  typeOptions: any
  active: boolean
  overridden: boolean
  translated: boolean

  get isUnCategorizedLibraryGroup() {
    return this.id === 0 && this.name === 'Uncategorized' && this.groupType === 'LIBRARY'
  }
}

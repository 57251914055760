export enum DynamicFieldType {
  TEXT = 'TEXT',
  DROPDOWN = 'DROPDOWN',
  DROPDOWN_EXTERNAL = 'DROPDOWN_EXTERNAL'
}

export class ScopeDynamicFieldSettingModel {
  id: number;
  name: string;
  displayName: string;
  type: DynamicFieldType;
  mandatory: boolean;
  value?: string;
  jsonValueName?: string;
  companyApiIntegrationId?: number;
  externalSystemResourceUri?: string;
  fetchFirstPageOnly: boolean = true;
  apiIntegrationName?: string;
}

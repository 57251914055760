import { User } from '@app/core/model/user.model'
import { LibraryItemType } from '@app/features/library-management/store/enums'
import { Money } from '@app/features/scope-overview/model/money.model'
import { LibraryDiscipline } from '../library-discipline.model'
import { LibraryComponentEntry } from './library-component-entry.model'

export class LibraryComponent {
  id?: number
  name: string
  fixedPricing?: boolean
  itemType: LibraryItemType
  libraryFolder?: {
    id: number
    itemType: LibraryItemType
    name: string
  }
  libraryComponentEntries?: LibraryComponentEntry[]
  quantity?: number
  complexity?: string
  agencyHours?: number
  agencyPrice?: Money
  roles?: any[]
  departments?: any[]
  discipline?: LibraryDiscipline
  language?: string
  createdBy?: User
}

import { User } from '@app/core/model/user.model'
import { Type } from 'class-transformer'
import { LibraryItemType } from '../../enums'
import { LibraryDeliverableEntry } from './library-deliverable-entry.model'

export class LibraryDeliverable {
  id: number
  name: string
  description: string
  fixedPricing: boolean
  libraryItemType: string
  itemType: LibraryItemType
  internalNote?: LibraryItemType
  language?: string
  libraryFolder?: {
    id: number
    itemType: LibraryItemType
    name: string
  }
  @Type(() => User) createdBy?: User
  @Type(() => Date) createdTs?: Date
  libraryDeliverableEntrySet?: LibraryDeliverableEntry[]
}

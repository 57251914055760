import {
  Component,
  Inject,
  AfterViewInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SharedModule } from '@app/shared/shared.module';
import {
  ScopeUiDatepickerComponent,
} from '@app/shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component';
import {
  ScopeUiAutocompleteComponent,
} from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxMaskDirective } from 'ngx-mask';
import {
  ScopeUiDropdownComponent,
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { Store } from '@ngrx/store';
import { LanguageService } from '@core/service/language.service';
import { QuestionType } from '@core/model/enums/question-type.enum';
import { FormControl } from '@angular/forms';
import { FormulaBuilderComponent } from '@shared/components/formula-builder/formula-builder.component';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { Observable } from 'rxjs';
import { ScopeUiChipsComponent } from '@shared/components/ui-components/scope-ui-chips/scope-ui-chips.component';
import { ScopeUiRadioComponent } from '@shared/components/ui-components/scope-ui-radio/scope-ui-radio.component';
import {
  LibraryDeliverableEntryDetails,
} from '@app/features/library-management/store/models/deliverable/library-deliverable-entry-details.model';

import {
  ScopeUiCheckboxComponent,
} from '@shared/components/ui-components/scope-ui-checkbox/scope-ui-checkbox.component';

import {
  LibraryDeliverableEntryDetailsActions,
} from '@app/features/library-management/store/models/deliverable/library-deliverable-entry-details.action.interface';
import {
  selectAllDeliverableEntryDetails, selectDeliverableEntryDetailsLastPage,
  selectDeliverableEntryDetailsLoading,
} from '@app/features/library-management/store/selectors/library-deliverable-entry-details.selectors';
import { LibraryManagementNgrxModule } from '@app/features/library-management/library-management-ngrx.module';
import {
  PromptAutocompleteComponent,
} from '@app/features/company-management/components/prompt-autocomplete/prompt-autocomplete.component';
import { ScopeUiTableComponent } from '@shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { User } from '@core/model/user.model';
import { AuthService } from '@core/service/auth.service';

import { MatTableDataSource } from '@angular/material/table';
import { SvgIconComponent } from '@shared/components/svg-icon/svg-icon.component';
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model';
import {
  LibraryDeliverableEntryDetailsService
} from '@app/features/library-management/services/library-deliverable-entry-details.service';
import { untilDestroyed } from '@shared/utils/utils';
import { SnackbarService } from '@shared/utils/snackbar.service';
import {
  CompanyManagementSelectors
} from '@app/features/company-management/store/selectors/company-management.selectors';

export interface AddDeliverablePromptModalConfig {
  scenario: Scenario,
  question?: ScenarioQuestion,
  confirmCallback: (question: any) => void;
}

@Component({
  selector: 'add-deliverable-prompt-modal',
  standalone: true,
  imports: [
    CommonModule,
    LibraryManagementNgrxModule,
    ScopeUiDatepickerComponent,
    ScopeUiAutocompleteComponent,
    SharedModule,
    MatDatepickerModule,
    CKEditorModule,
    ScopeUiInputComponent,
    NgxSliderModule,
    NgxMaskDirective,
    ScopeUiDropdownComponent,
    FormulaBuilderComponent,
    ScopeUiChipsComponent,
    ScopeUiRadioComponent,
    ScopeUiCheckboxComponent,
    PromptAutocompleteComponent,
    ScopeUiTableComponent,
    SvgIconComponent,
  ],
  templateUrl: './add-deliverable-prompt-modal.component.html',
  styleUrls: ['./add-deliverable-prompt-modal.component.scss'],
})
export class AddDeliverablePromptModalComponent implements AfterViewInit {
  private readonly destroy$;

  title: string;
  instructions: string;
  questionTextLabel: string;
  configureTitle: string;
  questionText: string;
  required: boolean = false;
  visible: boolean = true;
  showAlways: boolean = true;
  showOnCondition: boolean = false;
  condition: string;
  step: number = 1;
  page: number = 0;
  loggedInUser!: User;
  scenario: Scenario;
  conditionControl = new FormControl();
  deliverableEntryDetails$: Observable<LibraryDeliverableEntryDetails[]>;
  isLoading$: Observable<boolean>;
  lastPage$: Observable<boolean>;
  selectedDeliverables: LibraryDeliverableEntryDetails[] = [];
  dataSource: MatTableDataSource<LibraryDeliverableEntryDetails>;
  sortedColumn: string | null = null;
  sortDirection: string | null = '';
  displayedColumns: string[] = ['pin', 'deliverable_name', 'discipline_name', 'delete'];
  updateLoading$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddDeliverablePromptModalConfig,
    private store: Store,
    public dialog: MatDialog,
    private lang: LanguageService,
    private authService: AuthService,
    private libraryDeliverableEntryDetailsService: LibraryDeliverableEntryDetailsService,
    private snackbarService: SnackbarService,
  ) {
    this.loggedInUser = this.authService.loggedInUser;
    this.updateLoading$ = this.store.select(CompanyManagementSelectors.selectLoadingAddUpdateScenarioQuestion)
    this.scenario = this.data.scenario
    this.destroy$ = untilDestroyed();
    if (data.question) {
      this.questionText = data.question.displayText
      this.required = data.question.mandatory
      this.showAlways = !data.question.hidden && !data.question.displayCondition
      this.showOnCondition = !!data.question.displayCondition
      this.condition = data.question.displayCondition

      let deliverableIds = data.question.deliverablePrompt.deliverablePromptOptions.map(option => option.deliverableId);
      this.libraryDeliverableEntryDetailsService.getDeliverableEntryDetailsByIds(deliverableIds)
        .pipe(this.destroy$())
        .subscribe({
          next: (deliverables: LibraryDeliverableEntryDetails[]) => {
            data.question.deliverablePrompt.deliverablePromptOptions.forEach(option => {
              const match = deliverables.find(del => del.libraryDeliverableEntryId === option.deliverableId);
              if (match) match.required = option.required
            });
            this.selectedDeliverables = deliverables;
            this.refreshTable()
          },
          error: () => {
            this.snackbarService.showDefaultErrorSnackbar();
          },
        });
    }

    this.title = `Configure ${this.lang.get('deliverable')} prompt`;
    this.instructions = `Set up the question by adding display text, marking it as required if needed, and defining its visibility. Choose ${this.lang.get('deliverable.p|l')} for the question, and pin them to ensure they’re always included in the scope.`;
    this.questionTextLabel = `Enter a prompt for selecting ${this.lang.get('deliverable.p|l')}`;
    this.configureTitle = `Set when this ${this.lang.get('deliverable')} Selection appears`;

    this.dataSource = new MatTableDataSource<LibraryDeliverableEntryDetails>([]);
  }

  ngAfterViewInit(): void {
    this.refreshTable();
  }

  onSort(column: string): void {
    if (this.sortedColumn === column) {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else if (this.sortDirection === 'desc') {
        this.sortedColumn = null;
        this.sortDirection = '';
      } else {
        this.sortDirection = 'asc';
      }
    } else {
      this.sortedColumn = column;
      this.sortDirection = 'asc';
    }
    this.orderData();
  }

  private orderData(): void {
    const data = [...this.selectedDeliverables];

    if (!this.sortedColumn || !this.sortDirection) {
      this.dataSource.data = data;
      return;
    }

    const direction = this.sortDirection;
    const column = this.sortedColumn;

    data.sort((a, b) => {
      let valA: string = '';
      let valB: string = '';

      if (column === 'deliverable_name') {
        valA = (a.libraryItemName || '').toLowerCase();
        valB = (b.libraryItemName || '').toLowerCase();
      } else if (column === 'discipline_name') {
        valA = (a.disciplineName || '').toLowerCase();
        valB = (b.disciplineName || '').toLowerCase();
      }

      if (valA > valB) return direction === 'asc' ? 1 : -1;
      if (valA < valB) return direction === 'asc' ? -1 : 1;
      return 0;
    });

    this.dataSource.data = data;
  }

  private refreshTable() {
    this.dataSource.data = [...this.selectedDeliverables];
    this.orderData();
  }

  updateSelectedDeliverables(selected: any): void {
    this.selectedDeliverables = [...this.selectedDeliverables, selected];
    this.refreshTable();
  }

  goToStep(step: number) {
    if (step === 2) {
      this.submit();
    } else {
      this.deliverableEntryDetails$ = this.store.select(selectAllDeliverableEntryDetails);
      this.isLoading$ = this.store.select(selectDeliverableEntryDetailsLoading);
      this.lastPage$ = this.store.select(selectDeliverableEntryDetailsLastPage);
      ++this.step;
    }
  }

  optionFunction = (option: LibraryDeliverableEntryDetails) => {
    return `<div class='item-title'>${option.libraryItemName}</div>
            <div class='item-description'>
              Discipline: ${option.disciplineName || '-'}
            </div>`
  };

  fetchDeliverables(event: { page: number, searchString: string }): void {
    this.store.dispatch(
      LibraryDeliverableEntryDetailsActions.loadDeliverableEntryDetails({
        page: event.page,
        pageSize: 10,
        filter: { libraryItemName: event.searchString },
      }),
    );
  }

  disableNextStep(): boolean {
    return !this.questionText ||
      (this.showOnCondition && (!this.condition || this.conditionControl.hasError('invalid'))) ||
      (this.step === 2 && !this.selectedDeliverables.length);
  }

  submit() {
    const deliverablePrompt = {
      deliverablePromptOptions: this.selectedDeliverables.map(deliverable => ({
        deliverableId: deliverable.libraryDeliverableEntryId,
        libraryItemName: deliverable.libraryItemName,
        required: deliverable.required,
      })),
    };
    this.data.confirmCallback(
      {
        id: this.data.question?.id,
        displayText: this.questionText,
        mandatory: this.required,
        displayCondition: this.condition,
        type: QuestionType.DELIVERABLE,
        deliverablePrompt: deliverablePrompt,
      },
    );
  }

  deleteDeliverable(deliverable: LibraryDeliverableEntryDetails) {
    this.selectedDeliverables = this.selectedDeliverables.filter(
      (d) => d.libraryDeliverableEntryId !== deliverable.libraryDeliverableEntryId,
    );
    this.refreshTable();
  }

  pinDeliverable(deliverable: LibraryDeliverableEntryDetails) {
    const updated = {
      ...deliverable,
      required: !deliverable.required,
    };
    this.selectedDeliverables = this.selectedDeliverables.map((d) =>
      d.libraryDeliverableEntryId === deliverable.libraryDeliverableEntryId ? updated : d,
    );
    this.refreshTable();
  }

  isSelected = (option: LibraryDeliverableEntryDetails) => {
    return this.selectedDeliverables.some(
      (selected) => selected.libraryDeliverableEntryId === option.libraryDeliverableEntryId
    );
  }
}

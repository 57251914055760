import { Dictionary, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import {
  LibraryManagementFolder,
  LibraryManagementFolderGroupSettingsModel,
  LibraryManagementFoldersState,
} from '@app/features/library-management/store/models/library-folders'
import { chunk, groupBy, last, sortBy } from 'lodash'

import { LibraryManagementFeatureState } from '@app/features/library-management/store/models/state'
import { Pagination } from '@app/core/model/definitions/pagination.interface'
import { Preference } from '@app/core/model/user-preferences.interface'
import { Sort } from '@angular/material/sort'
import { createSelector } from '@ngrx/store'
import { getLibraryManagementFeatureState } from './library-management-feature.selectors'
import { plainToInstance } from 'class-transformer'
import { TableColumnPreferences } from '@app/shared/components/ui-components/scope-ui-table/table-column-preferences.type'

export const libraryFoldersAdapter: EntityAdapter<LibraryManagementFolder> =
  createEntityAdapter<LibraryManagementFolder>({
    selectId: (item) => item.id,
  })

export const libraryFoldersAdapterSelectors = libraryFoldersAdapter.getSelectors()

const getLibraryManagementFoldersState = createSelector(
  getLibraryManagementFeatureState,
  (state: LibraryManagementFeatureState): LibraryManagementFoldersState => state?.folders
)

const selectIsLoading = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): boolean => state?.isLoading || state?.isLoadingColumns
)
const selectIsLoadingArchived = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): boolean => state?.isLoadingArchived || state?.isLoadingColumns
)

const selectAllFolders = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): any[] =>
    libraryFoldersAdapterSelectors
      .selectAll(state?.allFoldersList)
      .map((folder: LibraryManagementFolder) => ({ ...folder, parent: last(folder.path.split('/')) }))
)

const selectIsLoadingAllFolders = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): boolean => state?.isLoadingAllFolders
)

const selectIsLoadedAllFolders = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): boolean => state?.isLoadedAllFolders
)

const selectPagination = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): Pagination => state?.pagination
)

const selectSort = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): Sort => state?.sort
)

const selectFolders = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): LibraryManagementFolder[] =>
    libraryFoldersAdapterSelectors.selectAll(state?.list)
)

const selectPaginatedFolders = createSelector(
  selectFolders,
  selectPagination,
  (folders: LibraryManagementFolder[], pagination: Pagination): LibraryManagementFolder[] =>
    getPaginatedFolders(folders, pagination)
)

const selectTotal = createSelector(selectFolders, (folders: LibraryManagementFolder[]): number => folders?.length || 0)

const selectAllFoldersMap = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): Dictionary<LibraryManagementFolder> =>
    libraryFoldersAdapterSelectors.selectEntities(state.allFoldersList)
)

const selectColumns = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): TableColumnPreferences => state?.columns
)

const selectDisplayedColumns = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): Preference[] => Object.values(state?.columns)
)

const selectDialogId = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): string => state?.dialogId
)

const selectGroupSettings = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): LibraryManagementFolderGroupSettingsModel[] => state.groupSettings
)

const selectArchivedFolders = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): LibraryManagementFolder[] =>
    libraryFoldersAdapterSelectors.selectAll(state?.archivedList)
)

const selectArchivedPagination = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): Pagination => state?.archivedPagination
)

const selectPaginatedArchivedFolders = createSelector(
  selectArchivedFolders,
  selectArchivedPagination,
  (folders: LibraryManagementFolder[], pagination: Pagination): LibraryManagementFolder[] =>
    getPaginatedFolders(folders, pagination)
)

const selectArchivedSort = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): Sort => state?.archivedSort
)

const selectArchivedTotal = createSelector(
  selectArchivedFolders,
  (folders: LibraryManagementFolder[]): number => folders?.length
)

const selectFolderId = createSelector(
  getLibraryManagementFeatureState,
  (state: LibraryManagementFeatureState): number => state.libraryManagement?.filters?.folderId
)

const selectFolderToMove = createSelector(
  getLibraryManagementFoldersState,
  (state: LibraryManagementFoldersState): LibraryManagementFolder => state?.folderToMove
)

const selectAllFoldersTree = createSelector(
  selectAllFolders,
  selectFolderToMove,
  selectFolderId,
  (
    folders: LibraryManagementFolder[],
    folder: LibraryManagementFolder,
    currentFolderId: number
  ): LibraryManagementFolder[] => {
    let mappedFolders = folders

    if (folder) mappedFolders = folders.filter((f) => f.id !== folder.id && f.parent !== folder.name)
    if (currentFolderId)
      mappedFolders = mappedFolders.map((f) => ((f.parent = f.parent === '' ? 'Library' : f.parent), f))

    const groupedFolders = groupBy(mappedFolders, 'parent')

    const mapChild = (folder: LibraryManagementFolder) => ({
      ...folder,
      children: sortBy(
        [...(groupedFolders?.[folder.name] || []).map((child) => mapChild(child)), { parent: folder.id }],
        'name'
      ),
    })

    return currentFolderId
      ? [mapChild({ id: -1, name: 'Library' } as LibraryManagementFolder)]
      : sortBy(
          [
            ...mappedFolders.reduce((arr, folder) => (folder.path ? arr : [...arr, mapChild(folder)]), []),
            { parent: '' },
          ],
          'name'
        )
  }
)

const getPaginatedFolders = (folders: LibraryManagementFolder[], pagination: Pagination) =>
  chunk(folders, pagination.pageSize)[pagination.page]?.map((folder) =>
    plainToInstance(LibraryManagementFolder, {
      ...folder,
      displayName: folder.name + (folder.sharedCompaniesNames?.length ? ` (${folder.sharedCompaniesNames})` : ''),
    })
  )

export const LibraryManagementFoldersSelectors = {
  selectIsLoading,
  selectIsLoadingArchived,
  selectIsLoadingAllFolders,
  selectIsLoadedAllFolders,
  selectAllFolders,
  selectAllFoldersTree,
  selectAllFoldersMap,
  selectFolders,
  selectPagination,
  selectPaginatedFolders,
  selectSort,
  selectTotal,
  selectColumns,
  selectDisplayedColumns,
  selectDialogId,
  selectGroupSettings,
  selectArchivedFolders,
  selectArchivedPagination,
  selectPaginatedArchivedFolders,
  selectArchivedSort,
  selectArchivedTotal,
}

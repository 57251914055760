import { LibraryThirdPartyCostState, ThirdPartyCostGroup } from '../models/third-party-cost'

import { LibraryManagementFeatureState } from '@app/features/library-management/store/models/state'
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'
import { ThirdPartyCostFormula } from '@app/core/model/third-party-cost-formula.model'
import { createSelector } from '@ngrx/store'
import { getLibraryManagementFeatureState } from './library-management-feature.selectors'

const getLibraryThirdPartyCostState = createSelector(
  getLibraryManagementFeatureState,
  (state: LibraryManagementFeatureState): LibraryThirdPartyCostState => state?.thirdPartyCost
)

const selectIsLoading = createSelector(
  getLibraryThirdPartyCostState,
  (state: LibraryThirdPartyCostState): boolean => state?.isLoading || state?.isTpcLoading
)

const selectAllGroups = createSelector(
  getLibraryThirdPartyCostState,
  (state: LibraryThirdPartyCostState): ThirdPartyCostGroup[] => state?.groups
)

const selectGroups = createSelector(selectAllGroups, (groups: ThirdPartyCostGroup[]): ThirdPartyCostGroup[] =>
  groups?.filter((g) => !g.isUnCategorizedLibraryGroup)
)

const selectIsGroupsLoaded = createSelector(
  getLibraryThirdPartyCostState,
  (state: LibraryThirdPartyCostState): boolean => state?.isGroupsLoaded
)

const selectFormulas = createSelector(
  getLibraryThirdPartyCostState,
  (state: LibraryThirdPartyCostState): ThirdPartyCostFormula[] => state?.formulas
)

const selectIsFormulasLoaded = createSelector(
  getLibraryThirdPartyCostState,
  (state: LibraryThirdPartyCostState): boolean => state?.isFormulasLoaded
)

const selectThirdPartyCost = createSelector(
  getLibraryThirdPartyCostState,
  (state: LibraryThirdPartyCostState): ThirdPartyCost => state?.tpc
)

export const LibraryThirdPartyCostSelectors = {
  selectIsLoading,
  selectAllGroups,
  selectGroups,
  selectIsGroupsLoaded,
  selectFormulas,
  selectIsFormulasLoaded,
  selectThirdPartyCost,
}

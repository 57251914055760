import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { ScopeVersion } from '@core/model/scope-version';
import { ApprovalFlowService } from '@app/features/scope-overview/service/approval-flow.service';
import { User } from '@app/core/model/user.model';
import { ScopeComponent } from '@app/features/scoping/models/component.model';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ScopeUiTableComponent } from '@app/shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { NgxMaskDirective } from 'ngx-mask'
import { ScopeTabService } from '@app/features/scope-overview/service/scope-tab.service'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model'
import { Role } from '@app/features/scoping/models/role.model'
import { ScopeSection } from '@app/features/scope-overview/model/scope-section'
import {
  DeliverableRowComponent
} from '@app/features/scope-overview/components/scope-tab/deliverable-row/deliverable-row.component'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop'
import { select, Store } from '@ngrx/store';
import { ToggleListComponent } from '@shared/components/ui-components/toggle-list/toggle-list.component'
import { Preference } from '@core/model/user-preferences.interface'
import { BootstrapActions } from '@core/store'
import { cloneDeep } from 'lodash'
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action'
import {
  SectionRowComponent
} from '@app/features/scope-overview/components/scope-tab/section-row/section-row.component'
import { ScopeOverviewSelectors } from '@app/features/scope-overview/store/selectors/scope-overview.selector';
import { filter } from 'rxjs/operators';
import { take } from 'rxjs';
import { MenuOptions } from '@core/model/definitions/menu-options.interface'
import { trackById } from '@shared/utils/utils';
import { CdnConfig } from '@core/model/cdn-config.model';

@Component({
  selector: 'scope-table',
  imports: [CommonModule, SharedModule, ScopeUiTableComponent, NgxMaskDirective, DeliverableRowComponent, ScopeUiInputComponent, ToggleListComponent, SectionRowComponent],
  templateUrl: './scope-table.component.html',
  styleUrls: ['./scope-table.component.scss'],
  standalone: true,
})
export class ScopeTableComponent {
  @Input() isTimeline: boolean = false;

  @Input() currentUser!: User;

  @Input() scopeVersion!: ScopeVersion;

  @Input() feeMenuOptions!: MenuOptions[]

  @Input() currentRatecard: RatecardVersion

  @Input() mainColumnClass: string;

  @Input() componentMainColumnClass: string;

  @Input() roleMainColumnClass: string;

  @Input() sortedSections: ScopeSection[];

  @Input() sortedDefaultSectionDeliverables: Deliverable[];

  @Input() cdnConfig?: CdnConfig

  @Output() onFetchDeliverable!: EventEmitter<number>;

  @Output() onUpdateDeliverable!: EventEmitter<Deliverable>;

  @Output() onUpdateComponent!: EventEmitter<ScopeComponent>;

  @Output() onAcceptDeliverable!: EventEmitter<Deliverable>;

  @Output() onRejectDeliverable!: EventEmitter<Deliverable>;

  @Output() onDuplicateDeliverable!: EventEmitter<Deliverable>

  @Output() onDeleteDeliverable!: EventEmitter<Deliverable>

  @Output() onAddDeliverableFee!: EventEmitter<Deliverable>;

  @Output() onAddComponentFee!: EventEmitter<ScopeComponent>;

  @Output() onEditDeliverable!: EventEmitter<number>;

  @Output() onCompleteTrade!: EventEmitter<Deliverable>

  @Output() onTradeDeliverable!: EventEmitter<Deliverable>

  @Output() onStopProgressTrade!: EventEmitter<Deliverable>

  @Output() onAddDeliverableToTrade!: EventEmitter<Deliverable>

  @Output() onConfirmTrade!: EventEmitter<Deliverable>

  @Output() onCancelTrade!: EventEmitter<Deliverable>

  @Output() onReopenTrade!: EventEmitter<Deliverable>

  @Output() onShowDeliverableApplyLocationCardDialog!: EventEmitter<Deliverable>

  @Output() onUpdateFeeItem!: EventEmitter<{
    feeItem: FeeItemInstance,
    section?: ScopeSection,
    deliverable?: Deliverable,
    component?: ScopeComponent
  }>

  @Output() onOverrideQuantity!: EventEmitter<{ deliverable: Deliverable, component: ScopeComponent }>

  @Output() onOverrideRateCardRoleHours!: EventEmitter<{
    deliverable: Deliverable,
    component: ScopeComponent,
    role: Role
  }>

  @Output() onAddSectionFee: EventEmitter<ScopeSection>

  @Output() onDeleteSection: EventEmitter<ScopeSection>

  @Output() onMoveComponent: EventEmitter<any>;

  @Output() onMoveComponentOutsideDeliverable: EventEmitter<ScopeComponent>;

  sectionDragging: boolean;

  constructor(public scopeApprovalFlowService: ApprovalFlowService, public scopeTabService: ScopeTabService, private store: Store) {
    this.onFetchDeliverable = new EventEmitter<number>()
    this.onUpdateDeliverable = new EventEmitter<Deliverable>()
    this.onUpdateComponent = new EventEmitter<ScopeComponent>()
    this.onDuplicateDeliverable = new EventEmitter<Deliverable>()
    this.onDeleteDeliverable = new EventEmitter<Deliverable>()
    this.onAcceptDeliverable = new EventEmitter<Deliverable>()
    this.onRejectDeliverable = new EventEmitter<Deliverable>()
    this.onAddDeliverableFee = new EventEmitter<Deliverable>()
    this.onAddComponentFee = new EventEmitter<ScopeComponent>()
    this.onEditDeliverable = new EventEmitter<number>()
    this.onCompleteTrade = new EventEmitter<Deliverable>()
    this.onTradeDeliverable = new EventEmitter<Deliverable>()
    this.onStopProgressTrade = new EventEmitter<Deliverable>()
    this.onAddDeliverableToTrade = new EventEmitter<Deliverable>()
    this.onConfirmTrade = new EventEmitter<Deliverable>()
    this.onCancelTrade = new EventEmitter<Deliverable>()
    this.onReopenTrade = new EventEmitter<Deliverable>()
    this.onShowDeliverableApplyLocationCardDialog = new EventEmitter<Deliverable>()
    this.onUpdateFeeItem = new EventEmitter<{
      feeItem: FeeItemInstance,
      section?: ScopeSection,
      deliverable?: Deliverable,
      component?: ScopeComponent
    }>()
    this.onOverrideQuantity = new EventEmitter<{ deliverable: Deliverable, component: ScopeComponent }>()
    this.onOverrideRateCardRoleHours = new EventEmitter<{
      deliverable: Deliverable,
      component: ScopeComponent,
      role: Role
    }>()
    this.onAddSectionFee = new EventEmitter<ScopeSection>()
    this.onDeleteSection = new EventEmitter<ScopeSection>()
    this.onMoveComponent = new EventEmitter<any>();
    this.onMoveComponentOutsideDeliverable = new EventEmitter<ScopeComponent>();
  }

  addSectionFee(section: ScopeSection) {
    this.onAddSectionFee.emit(section)
  }

  deleteSection(section: ScopeSection) {
    this.onDeleteSection.emit(section)
  }

  fetchDeliverable(deliverableId: number) {
    this.onFetchDeliverable.emit(deliverableId);
  }

  updateDeliverable(deliverable: Deliverable) {
    this.onUpdateDeliverable.emit(deliverable);
  }

  updateComponent(component: ScopeComponent) {
    this.onUpdateComponent.emit(component);
  }

  duplicateDeliverable(deliverable: Deliverable) {
    this.onDuplicateDeliverable.emit(deliverable)
  }

  deleteDeliverable(deliverable: Deliverable) {
    this.onDeleteDeliverable.emit(deliverable);
  }

  addDeliverableFee(deliverable: Deliverable) {
    this.onAddDeliverableFee.emit(deliverable);
  }

  addComponentFee(component: ScopeComponent) {
    this.onAddComponentFee.emit(component);
  }

  editDeliverable(deliverableId: number) {
    this.onEditDeliverable.emit(deliverableId);
  }

  showDeliverableApplyLocationCardDialog(deliverable: Deliverable) {
    this.onShowDeliverableApplyLocationCardDialog.emit(deliverable)
  }

  updateFeeItem(feeItem: FeeItemInstance, section?: ScopeSection, deliverable?: Deliverable, component?: ScopeComponent) {
    this.onUpdateFeeItem.emit({ feeItem, section, deliverable, component })
  }

  overrideQuantity(event: { deliverable: Deliverable, component: ScopeComponent }) {
    this.onOverrideQuantity.emit(event)
  }

  overrideRateCardRoleHours(event: { deliverable: Deliverable, component: ScopeComponent, role: Role }) {
    this.onOverrideRateCardRoleHours.emit(event)
  }

  completeTrade(deliverable: Deliverable) {
    this.onCompleteTrade.emit(deliverable)
  }

  tradeDeliverable(deliverable: Deliverable) {
    this.onTradeDeliverable.emit(deliverable)
  }

  stopProgressTrade(deliverable: Deliverable) {
    this.onStopProgressTrade.emit(deliverable)
  }

  addDeliverableToTrade(deliverable: Deliverable) {
    this.onAddDeliverableToTrade.emit(deliverable)
  }

  confirmTrade(deliverable: Deliverable) {
    this.onConfirmTrade.emit(deliverable)
  }

  cancelTrade(deliverable: Deliverable) {
    this.onCancelTrade.emit(deliverable)
  }

  reopenTrade(deliverable: Deliverable) {
    this.onReopenTrade.emit(deliverable)
  }

  acceptDeliverable(deliverable: Deliverable) {
    this.onAcceptDeliverable.emit(deliverable);
  }

  rejectDeliverable(deliverable: Deliverable) {
    this.onRejectDeliverable.emit(deliverable);
  }

  onToggleScopePreference(preference: Preference) {
    this.store.dispatch(BootstrapActions.updateScopeOverviewColumnPreferences({ updatedPrefs: preference }));
  }

  dropSection(event: CdkDragDrop<any>) {
    let sections = cloneDeep(event.container.data)
    moveItemInArray(sections, event.previousIndex, event.currentIndex);
    sections.forEach((d, i) => d.order = i)
    this.store.dispatch(ScopeOverviewActions.updateScopeSectionsOrder( { scopeId: this.scopeVersion.identity.id, sections: sections } ))
  }

  get sectionDrops() {
    let str: Array<string> = [];
    this.scopeVersion.sections.forEach((s) => {
      str.push('list-' + s.id);
    });
    str.push('list-one');
    return str;
  }

  dropDeliverable(event: CdkDragDrop<any>, section: ScopeSection | null) {
    let deliverables = cloneDeep(event.container.data)
    if (event.previousContainer != event.container) {
      let previousSectionDeliverables = cloneDeep(event.previousContainer.data)
      transferArrayItem(previousSectionDeliverables, deliverables, event.previousIndex, event.currentIndex)
      this.updateScopeDeliverableOrder(previousSectionDeliverables, deliverables[event.currentIndex].section?.id)

      if (section != null) {
        if (!this.scopeTabService.sectionSelectedStates[section.id]) {
          this.scopeTabService.toggleSection(section.id)
        }
        deliverables[event.currentIndex].section = section.id != null ? { id: section.id } : null;
      } else {
        deliverables[event.currentIndex].section = null;
      }
      deliverables.forEach((d, i) => d.order = i)
      this.onUpdateDeliverable.emit(deliverables[event.currentIndex])
      this.store.pipe(
        select(ScopeOverviewSelectors.selectLoadedUpdateDeliverable),
        filter(loaded => loaded),
        take(1),
      ).subscribe(() => {
        this.store.dispatch(ScopeOverviewActions.updateDeliverablesOrder( { scopeId: this.scopeVersion.identity.id, sectionId: section?.id, deliverables: deliverables } ))
      });
    } else if (event.previousIndex != event.currentIndex) {
      moveItemInArray(deliverables, event.previousIndex, event.currentIndex);
      this.updateScopeDeliverableOrder(deliverables, section?.id)
    }
  }

  updateScopeDeliverableOrder(data: Deliverable[], sectionId?: number) {
    data.forEach((d, i) => d.order = i)
    this.store.dispatch(ScopeOverviewActions.updateDeliverablesOrder( { scopeId: this.scopeVersion.identity.id, sectionId: sectionId, deliverables: data } ))
  }

  onDragEnter(section: ScopeSection) {
    if (this.scopeTabService.deliverableDragging) {
      this.scopeTabService.dragTargetId = section.id
    }
  }

  moveComponent(event: any) {
    this.onMoveComponent.emit(event);
  }

  moveComponentOutsideDeliverable(component: ScopeComponent) {
    this.onMoveComponentOutsideDeliverable.emit(component);
  }

  get deliverableDrops(){
    let str: Array<string> = [];
    this.scopeVersion.deliverables.forEach((s) => {
      str.push('deliverbale-' + s.id);
    });
    return str;
  }

  protected readonly trackById = trackById;
}

<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>{{ data.title }}</h1>
    </div>

    <div class="mt-2" [ngClass]="{ 'w-450': data.limitModalWidth }">
      <span>
        {{ data.body }}
      </span>
    </div>

    <button class="close-button" mat-dialog-close>
      <i class="material-symbols-rounded">close</i>
    </button>
  </div>
</div>

<mat-dialog-content aria-label="Generic modal">
  <ng-container *ngIf="isAdobesignForm">
    <mat-dialog-actions class="export-scope-actions">
      <div *ngFor="let output of outputOptions" class="docusign-output">
        <span (click)="onFormatOptionChange(output)" [ngClass]="{ 'active-option': output.selected }">on</span>
        |
        <span (click)="onFormatOptionChange(output)" [ngClass]="{ 'active-option': !output.selected }">off</span>
        <span class="export-scope-type">{{ output.name }}</span>
      </div>
      <button
        (click)="confirmOutputFormat()"
        [disabled]="!selectedOption"
        [ngClass]="{ disabled: !selectedOption }"
        class="export-scope-button">
        <span class="export-scope-type">Confirm</span>
      </button>
    </mat-dialog-actions>
  </ng-container>

  <ng-container *ngIf="!isAdobesignForm">
    <form class="signer-form">
      <scope-ui-autocomplete
        [label]="'Signer (Required - 1 email address allowed)'"
        [options]="this.filteredContacts"
        [hasIcon]="true"
        [iconName]="'search'"
        [optionFn]='optionFunction'
        [displayFn]='optionFunction'
        [noResultsMessage]="'Not found. <u>Create new</u>'"
        [required]='true'
        [enableDefaultOption]="true"
        (onSelectionChange)="onSelectionChange($event.event, false)"
        (onSelectDefaultOption)="onSelectionChange($event, false)" />

      <p *ngIf="noSigner">No signer selected</p>

      <div class="docusign-confirm">
        <button
          (click)="onConfirm()"
          [disabled]="!isFormValid"
          [ngClass]="{ disabled: !isFormValid }"
          class="export-scope-confirm">
          Confirm
        </button>
      </div>
    </form>
  </ng-container>
</mat-dialog-content>

import { Type } from 'class-transformer';
import { User } from '@core/model/user.model';
import { QuestionType } from '@core/model/enums/question-type.enum';
import { DeliverablePrompt } from '@app/features/scope-overview/model/deliverable-prompt.model';
import { FeePrompt } from '@app/features/scope-overview/model/fee-prompt.model';

export class ScenarioQuestion {
  id!: number
  fieldId!: string
  displayText!: string
  fieldLabel!: string
  orderIndex!: number
  type!: QuestionType
  hidden?: boolean
  mandatory?: boolean
  value?: any
  formula?: string
  optionsList?: string
  displayCondition?: string
  deliverablePrompt?: DeliverablePrompt
  feePrompt?: FeePrompt
  maxLength?: number
  minimum?: number
  maximum?: number
  wholeNumber?: boolean
  @Type(() => Date) minDate?: Date
  @Type(() => Date) maxDate?: Date
  @Type(() => User) createdBy!: User
  @Type(() => Date) createdOn!: Date
  @Type(() => Date) updatedOn?: Date
}

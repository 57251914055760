import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { PipesModule } from '@app/shared/pipe/index.module'
import { SharedModule } from '@app/shared/shared.module'
import { ExportDocumentService } from '../../service/export-document-service'
import { DialogEventsService } from '../../service/dialog-events.service'
import { ExportDocusignModal } from '../export-docusign/export-docusign.component'
import { ExportModalConfig } from '../../model/export-modal-config'
import {ExportAdobsignModal} from "@app/features/scope-overview/components/export-adobesign/export-adobesign.component";

@Component({
  selector: 'export-modal',
  standalone: true,
  imports: [CommonModule, PipesModule, MatDialogModule, SharedModule],
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
})
export class ExportModalComponent {
  hasDocusignPermission: boolean
  hasAdobeSignPermission: boolean

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ExportModalConfig,
    public dialogRef: MatDialogRef<ExportModalComponent>,
    public dialog: MatDialog,
    private dialogEventService: DialogEventsService,
    private exportDocumentService: ExportDocumentService
  ) {
    this.hasDocusignPermission = this.exportDocumentService.hasDocusignPermission(
      this.data.currentScope,
      this.data.currentUser
    )
    this.hasAdobeSignPermission = this.exportDocumentService.hasAdobeSignPermission(
      this.data.currentScope,
      this.data.currentUser
    )
  }

  exportToCSV() {
    this.exportDocumentService.exportToCSV(this.data.scopeId, this.data.currentScope.name)
    this.dialogRef.close()
  }

  exportToWordDoc() {
    this.exportDocumentService.exportToWordDoc(this.data.scopeId, this.data.currentScope.name)
    this.dialogRef.close()
  }

  // NOTE: Working but will need to be tested on deployed version.
  exportScopeToPdf() {
    this.exportDocumentService.exportToPdf(this.data.scopeId, this.data.currentScope.name)
    this.dialogRef.close()
  }

  exportScopeToExcel() {
    this.dialogEventService.emitEvent({ key: 'exportScopeToExcel' })
    this.dialogRef.close()
  }

  showDocusignModal() {
    this.dialogRef.close()
    let dialogConfig: ExportModalConfig = {
      title: 'Export As DocuSign Document',
      body: 'Select which output will be sent via DocuSign',
      isOutputOption: true,
      currentScope: this.data.currentScope,
      currentUser: this.data.currentUser,
      scopeId: this.data.scopeId,
    }

    this.dialog.open(ExportDocusignModal, {
      data: dialogConfig,
    })
  }

  showAdobesignModal() {
    this.dialogRef.close()
    let dialogConfig: ExportModalConfig = {
      title: 'Export As AdobeSign Document',
      body: 'Select which output will be sent via AdobeSign',
      isOutputOption: true,
      currentScope: this.data.currentScope,
      currentUser: this.data.currentUser,
      scopeId: this.data.scopeId,
    }

    this.dialog.open(ExportAdobsignModal, {
      data: dialogConfig,
    })
  }
}

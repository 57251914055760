import {
  LibraryComponent,
  LibraryComponentEntry,
  LibraryComponentEntryPayload,
} from '@app/features/library-management/store/models/component'
import { createAction, props } from '@ngrx/store'

import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'

export enum LibraryComponentActionTypes {
  SET_PAGE = '[Library Component] Set page',
  DESTROY_PAGE = '[Library Component] Reset flags on page destroy',

  GET_COMPONENT = '[Library Component] Get Component',
  GET_COMPONENT_FAIL = '[Library Component] Get Component Fail',
  GET_COMPONENT_SUCCESS = '[Library Component] Get Component Success',

  ADD_COMPONENT_ENTRY = '[Library Component] Add Component Entry',
  ADD_COMPONENT_ENTRY_FAIL = '[Library Component] Add Component Entry Fail',
  ADD_COMPONENT_ENTRY_SUCCESS = '[Library Component] Add Component Entry Success',

  UPDATE_COMPONENT = '[Library Component] Update Component',
  UPDATE_COMPONENT_FAIL = '[Library Component] Update Component Fail',
  UPDATE_COMPONENT_SUCCESS = '[Library Component] Update Component Success',

  DELETE_COMPONENT = '[Library Component] Delete Component',
  DELETE_COMPONENT_FAIL = '[Library Component] Delete Component Fail',
  DELETE_COMPONENT_SUCCESS = '[Library Component] Delete Component Success',

  DELETE_COMPONENT_ENTRY = '[Library Component] Delete Component Entry',
  DELETE_COMPONENT_ENTRY_FAIL = '[Library Component] Delete Component Entry Fail',
  DELETE_COMPONENT_ENTRY_SUCCESS = '[Library Component] Delete Component Entry Success',

  DUPLICATE_COMPONENT_ENTRY = '[Library Component] Duplicate Component Entry',
  DUPLICATE_COMPONENT_ENTRY_FAIL = '[Library Component] Duplicate Component Entry Fail',
  DUPLICATE_COMPONENT_ENTRY_SUCCESS = '[Library Component] Duplicate Component Entry Success',

  TOGGLE_ENTRY_FIXED_COST_EDITABLE = '[Library Component] Toggle Entry Fixed Cost Editable',
  TOGGLE_ENTRY_FIXED_COST_EDITABLE_FAIL = '[Library Component] Toggle Entry Fixed Cost Editable Fail',
  TOGGLE_ENTRY_FIXED_COST_EDITABLE_SUCCESS = '[Library Component] Toggle Entry Fixed Cost Editable Success',

  /** Entry */
  GET_ENTRY_RATECARD = '[Library Component Entry] Get Component Entry RateCard',
  GET_ENTRY_RATECARD_FAIL = '[Library Component Entry] Get Component Entry RateCard Fail',
  GET_ENTRY_RATECARD_SUCCESS = '[Library Component Entry] Get Component Entry RateCard Success',

  UPDATE_COMPONENT_ENTRY = '[Library Component Entry] Update Component Entry',

  SAVE_COMPONENT_ENTRY = '[Library Component Entry] Save Component Entry',
  SAVE_COMPONENT_ENTRY_FAIL = '[Library Component Entry] Save Component Entry Fail',
  SAVE_COMPONENT_ENTRY_SUCCESS = '[Library Component Entry] Save Component Entry Success',

  CASCADE_ENTRY_CHANGES = '[Library Component Entry] Cascade Entry changes to Library Deliverables',
}

const setPage = createAction(LibraryComponentActionTypes.SET_PAGE, props<{ page: number }>())
const destroyPage = createAction(LibraryComponentActionTypes.DESTROY_PAGE)

const getComponent = createAction(LibraryComponentActionTypes.GET_COMPONENT, props<{ id: number }>())
const getComponentFail = createAction(LibraryComponentActionTypes.GET_COMPONENT_FAIL, props<{ error: Error }>())
const getComponentSuccess = createAction(
  LibraryComponentActionTypes.GET_COMPONENT_SUCCESS,
  props<{ component: LibraryComponent }>()
)

const addComponentEntry = createAction(
  LibraryComponentActionTypes.ADD_COMPONENT_ENTRY,
  props<{ payload: LibraryComponentEntryPayload }>()
)
const addComponentEntryFail = createAction(
  LibraryComponentActionTypes.ADD_COMPONENT_ENTRY_FAIL,
  props<{ error: Error }>()
)
const addComponentEntrySuccess = createAction(
  LibraryComponentActionTypes.ADD_COMPONENT_ENTRY_SUCCESS,
  props<{ id: number }>()
)

const updateComponent = createAction(
  LibraryComponentActionTypes.UPDATE_COMPONENT,
  props<{ component: LibraryComponent }>()
)
const updateComponentFail = createAction(LibraryComponentActionTypes.UPDATE_COMPONENT_FAIL, props<{ error: Error }>())
const updateComponentSuccess = createAction(
  LibraryComponentActionTypes.UPDATE_COMPONENT_SUCCESS,
  props<{ component: LibraryComponent }>()
)

const deleteComponent = createAction(
  LibraryComponentActionTypes.DELETE_COMPONENT,
  props<{ component: LibraryComponent }>()
)
const deleteComponentFail = createAction(LibraryComponentActionTypes.DELETE_COMPONENT_FAIL, props<{ error: Error }>())
const deleteComponentSuccess = createAction(LibraryComponentActionTypes.DELETE_COMPONENT_SUCCESS)

const deleteComponentEntry = createAction(
  LibraryComponentActionTypes.DELETE_COMPONENT_ENTRY,
  props<{ id: number; entryId: number }>()
)
const deleteComponentEntryFail = createAction(
  LibraryComponentActionTypes.DELETE_COMPONENT_ENTRY_FAIL,
  props<{ error: Error }>()
)
const deleteComponentEntrySuccess = createAction(
  LibraryComponentActionTypes.DELETE_COMPONENT_ENTRY_SUCCESS,
  props<{ entryId: number }>()
)

const duplicateComponentEntry = createAction(
  LibraryComponentActionTypes.DUPLICATE_COMPONENT_ENTRY,
  props<{ payload: LibraryComponentEntryPayload }>()
)
const duplicateComponentEntryFail = createAction(
  LibraryComponentActionTypes.DUPLICATE_COMPONENT_ENTRY_FAIL,
  props<{ error: Error }>()
)
const duplicateComponentEntrySuccess = createAction(
  LibraryComponentActionTypes.DUPLICATE_COMPONENT_ENTRY_SUCCESS,
  props<{ id: number }>()
)

const toggleEntryFixedCostEditable = createAction(
  LibraryComponentActionTypes.TOGGLE_ENTRY_FIXED_COST_EDITABLE,
  props<{ entry: LibraryComponentEntry }>()
)
const toggleEntryFixedCostEditableFail = createAction(
  LibraryComponentActionTypes.TOGGLE_ENTRY_FIXED_COST_EDITABLE_FAIL,
  props<{ error: Error }>()
)
const toggleEntryFixedCostEditableSuccess = createAction(
  LibraryComponentActionTypes.TOGGLE_ENTRY_FIXED_COST_EDITABLE_SUCCESS,
  props<{ id: number }>()
)

/** Entry */
const getEntryRateCard = createAction(
  LibraryComponentActionTypes.GET_ENTRY_RATECARD,
  props<{ id: number; entryId: number }>()
)
const getEntryRateCardFail = createAction(
  LibraryComponentActionTypes.GET_ENTRY_RATECARD_FAIL,
  props<{ error: Error }>()
)
const getEntryRateCardSuccess = createAction(
  LibraryComponentActionTypes.GET_ENTRY_RATECARD_SUCCESS,
  props<{ rateCard: RatecardVersion; entryId: number }>()
)

const updateComponentEntry = createAction(
  LibraryComponentActionTypes.UPDATE_COMPONENT_ENTRY,
  props<{ entry: LibraryComponentEntry }>()
)

const saveComponentEntry = createAction(
  LibraryComponentActionTypes.SAVE_COMPONENT_ENTRY,
  props<{ entry?: LibraryComponentEntry; entryId?: number }>()
)
const saveComponentEntryFail = createAction(
  LibraryComponentActionTypes.SAVE_COMPONENT_ENTRY_FAIL,
  props<{ error: Error }>()
)
const saveComponentEntrySuccess = createAction(
  LibraryComponentActionTypes.SAVE_COMPONENT_ENTRY_SUCCESS,
  props<{ entry: LibraryComponentEntry }>()
)

const cascadeEntryChanges = createAction(LibraryComponentActionTypes.CASCADE_ENTRY_CHANGES, props<{ id: number }>())

export const LibraryComponentActions = {
  setPage,
  destroyPage,
  getComponent,
  getComponentFail,
  getComponentSuccess,
  addComponentEntry,
  addComponentEntryFail,
  addComponentEntrySuccess,
  updateComponent,
  updateComponentFail,
  updateComponentSuccess,
  deleteComponent,
  deleteComponentFail,
  deleteComponentSuccess,
  deleteComponentEntry,
  deleteComponentEntryFail,
  deleteComponentEntrySuccess,
  duplicateComponentEntry,
  duplicateComponentEntryFail,
  duplicateComponentEntrySuccess,
  toggleEntryFixedCostEditable,
  toggleEntryFixedCostEditableFail,
  toggleEntryFixedCostEditableSuccess,
  /** Entry */
  getEntryRateCard,
  getEntryRateCardFail,
  getEntryRateCardSuccess,
  updateComponentEntry,
  saveComponentEntry,
  saveComponentEntryFail,
  saveComponentEntrySuccess,
  cascadeEntryChanges,
}

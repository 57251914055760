<div
  [hidden]="!loading"
  class="mt-1 {{ tableClass }}"
  [class.condensed]="condensed"
  [class.block]="loading">
  <div class="th-skeleton"></div>
  <div
    class="td-skeleton"
    *ngFor="let x of [].constructor(amountOfRows)"></div>
</div>

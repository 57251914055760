import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  ArchiveFolderAction,
  CreateFolderAction,
  DeleteFolderAction,
  LibraryManagementFolder,
  LibraryManagementFolderGroupSettingsModel,
  OpenMoveFolderModalAction,
  UpdateFolderAction,
  UpdateFoldersColumnPreferenceAction,
  libraryFoldersColumnsBaseConfig,
} from '@app/features/library-management/store/models/library-folders'
import { CreateFolderAndMoveLibraryItemAction, MoveLibraryItemAction } from '../models/library-items'
import {
  LibraryFormError,
  LibraryManagementActionTypes,
  LibraryManagementFoldersActionTypes,
  LibraryManagementFoldersActions,
  LibraryManagementFoldersSelectors,
  LibraryManagementSelectors,
} from '@app/features/library-management/store'
import {
  LibraryManagementApiMappingService,
  LibraryManagementFoldersService,
  LibraryManagementItemsService,
  LibraryManagementMappingService,
} from '@app/features/library-management/services'
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@app/shared/utils/snackbar.service'
import { catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs'

import { Injectable } from '@angular/core'
import { LibraryMoveItemModalComponent } from '@app/features/library-management/components/modals'
import { LibraryMoveItemModalConfig } from '@app/features/library-management/models'
import { MatDialog } from '@angular/material/dialog'
import { Sort } from '@angular/material/sort'
import { Store } from '@ngrx/store'
import { UserPreferences } from '@app/core/model/user-preferences.interface'
import { UserPreferencesColumns } from '@app/core/model/enums/user-preferences-columns.enum'
import { UserService } from '@app/features/user-account/services/user.service'
import { getPlainHttpParams, mergePreferencesWithTableConfig } from '@app/shared/utils/utils';
import { TableColumnPreferences } from '@app/shared/components/ui-components/scope-ui-table/table-column-preferences.type'

@Injectable()
export class LibraryManagementFoldersEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private libraryItemsService: LibraryManagementItemsService,
    private libraryFoldersService: LibraryManagementFoldersService,
    private libraryMappingService: LibraryManagementMappingService,
    private libraryApiMappingService: LibraryManagementApiMappingService,
    private dialog: MatDialog
  ) {}

  getAllFolders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.GET_ALL_FOLDERS),
      withLatestFrom(
        this.store$.select(LibraryManagementFoldersSelectors.selectIsLoadedAllFolders),
        this.store$.select(LibraryManagementFoldersSelectors.selectIsLoadingAllFolders)
      ),
      filter(([, isLoaded, isLoading]) => !isLoaded && !isLoading),
      map(() => LibraryManagementFoldersActions.getAllFoldersTrigger())
    )
  })

  getAllFoldersTrigger$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.GET_ALL_FOLDERS_TRIGGER),
      switchMap(() =>
        this.libraryFoldersService.getAllFolders().pipe(
          map((list: LibraryManagementFolder[]) => LibraryManagementFoldersActions.getAllFoldersSuccess({ list })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during loading Folders',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryManagementFoldersActions.getAllFoldersFail({ error }))
          })
        )
      )
    )
  })

  getFolders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        LibraryManagementFoldersActionTypes.GET_FOLDERS,
        LibraryManagementFoldersActionTypes.SET_SORT,
        LibraryManagementActionTypes.GET_FOLDER_SUCCESS
      ),
      withLatestFrom(
        this.store$.select(LibraryManagementFoldersSelectors.selectSort),
        this.store$.select(LibraryManagementSelectors.selectFolderId)
      ),
      switchMap(([{ type, folder }, sort, folderId]: [any, Sort, number]) => {
        let id = type === LibraryManagementActionTypes.GET_FOLDER_SUCCESS ? folder?.id : folderId
        let params = getPlainHttpParams({
          orderByField: sort?.active,
          order: sort?.direction?.toUpperCase(),
          archived: false,
        })

        return (
          id
            ? this.libraryFoldersService.getFoldersByFolderId(id, params)
            : this.libraryFoldersService.getFolders(params)
        ).pipe(
          map(({ content, totalElements }) =>
            LibraryManagementFoldersActions.getFoldersSuccess({ list: content, totalElements })
          ),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during loading Library Folders',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryManagementFoldersActions.getFoldersFail({ error }))
          })
        )
      })
    )
  })

  getArchivedFolders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        LibraryManagementFoldersActionTypes.GET_ARCHIVED_FOLDERS,
        LibraryManagementFoldersActionTypes.SET_ARCHIVED_SORT,
        LibraryManagementActionTypes.GET_FOLDER_SUCCESS
      ),
      withLatestFrom(
        this.store$.select(LibraryManagementFoldersSelectors.selectSort),
        this.store$.select(LibraryManagementSelectors.selectFolderId)
      ),
      switchMap(([{ type, folder }, sort, folderId]: [any, Sort, number]) => {
        let id = type === LibraryManagementActionTypes.GET_FOLDER_SUCCESS ? folder?.id : folderId
        let params = getPlainHttpParams({
          orderByField: sort?.active,
          order: sort?.direction?.toUpperCase(),
          archived: true,
        })

        return (
          id
            ? this.libraryFoldersService.getFoldersByFolderId(id, params)
            : this.libraryFoldersService.getFolders(params)
        ).pipe(
          map(({ content, totalElements }) =>
            LibraryManagementFoldersActions.getArchivedFoldersSuccess({ list: content, totalElements })
          ),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during loading archived Library Folders',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryManagementFoldersActions.getArchivedFoldersFail({ error }))
          })
        )
      })
    )
  })

  getColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.GET_COLUMNS),
      switchMap(() =>
        this.userService.getUserPreferences(UserPreferencesColumns.LIBRARY_FOLDERS_USERCOLUMNS).pipe(
          map(
            (preferences) =>
              mergePreferencesWithTableConfig(libraryFoldersColumnsBaseConfig, preferences)
          ),
          map((preferences) => LibraryManagementFoldersActions.getColumnsSuccess({ preferences })),
          catchError((error) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during loading Library Folders Columns preferences',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryManagementFoldersActions.getColumnsFail(error))
          })
        )
      )
    )
  )

  setColumns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.UPDATE_COLUMN_PREFERENCE),
      withLatestFrom(this.store$.select(LibraryManagementFoldersSelectors.selectColumns)),
      switchMap(
        ([{ preference }, currentPreferences]: [UpdateFoldersColumnPreferenceAction, TableColumnPreferences]) => {
          const updatedPreferences: TableColumnPreferences = {
            ...currentPreferences,
            [preference.key]: preference,
          }

          const userPreferences: UserPreferences = updatedPreferences as UserPreferences

          return this.userService
            .setUserPreferences(UserPreferencesColumns.LIBRARY_FOLDERS_USERCOLUMNS, userPreferences)
            .pipe(
              map(() =>
                LibraryManagementFoldersActions.updateColumnPreferenceSuccess({
                  preferences: updatedPreferences,
                })
              )
            )
        }
      ),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during updating Library Folders Columns Preferences',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementFoldersActions.updateColumnPreferenceFail(error))
      })
    )
  })

  createFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.CREATE_FOLDER),
      withLatestFrom(
        this.store$.select(LibraryManagementSelectors.selectDialogId),
        this.store$.select(LibraryManagementSelectors.selectFolder)
      ),
      switchMap(([{ name }, dialogId, folder]: [CreateFolderAction, string, LibraryManagementFolder]) =>
        this.libraryFoldersService.createFolder(this.libraryApiMappingService.mapFolderToApi({ name }, folder)).pipe(
          map(
            (folder) => (
              this.dialog.getDialogById(dialogId)?.close(),
              LibraryManagementFoldersActions.createFolderSuccess({ folder })
            )
          ),
          catchError((error) => {
            if (error.status === 409) this.setAlreadyExistsError(dialogId)
            return of(LibraryManagementFoldersActions.createFolderFail(error))
          })
        )
      ),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during creating Library Folder',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementFoldersActions.createFolderFail(error))
      })
    )
  })

  updateFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.UPDATE_FOLDER),
      withLatestFrom(this.store$.select(LibraryManagementFoldersSelectors.selectDialogId)),
      switchMap(([{ folder }, dialogId]: [UpdateFolderAction, string]) =>
        this.libraryFoldersService.updateFolder(folder.id, this.libraryApiMappingService.mapFolderToApi(folder)).pipe(
          map(
            (folder) => (
              this.dialog.getDialogById(dialogId)?.close(),
              LibraryManagementFoldersActions.updateFolderSuccess({ folder })
            )
          ),
          catchError((error) => {
            if (error.status === 409) this.setAlreadyExistsError(dialogId)
            return of(LibraryManagementFoldersActions.updateFolderFail(error))
          })
        )
      ),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during updating Library Folder',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementFoldersActions.updateFolderFail(error))
      })
    )
  })

  archiveFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.ARCHIVE_FOLDER),
      map(({ folder }: ArchiveFolderAction) => ({ ...folder, archived: !folder.archived })),
      switchMap((folder: LibraryManagementFolder) =>
        this.libraryItemsService
          .archiveLibraryItem(folder.id)
          .pipe(map(() => LibraryManagementFoldersActions.archiveFolderSuccess({ folder })))
      ),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during archiving Folder',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementFoldersActions.archiveFolderFail(error))
      })
    )
  )

  deleteFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.DELETE_FOLDER),
      switchMap(({ folder }: DeleteFolderAction) =>
        this.libraryItemsService
          .deleteLibraryItem(folder.id)
          .pipe(map(() => LibraryManagementFoldersActions.deleteFolderSuccess({ folder })))
      ),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during deleting Folder',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementFoldersActions.deleteFolderFail(error))
      })
    )
  )

  openMoveToFolderModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.OPEN_MOVE_FOLDER_MODAL),
      map(({ folder }: OpenMoveFolderModalAction) => {
        const modalConfig: LibraryMoveItemModalConfig = {
          item: folder,
          onMoveItem: (folderId: number) => {
            dialogInstance.close()
            this.store$.dispatch(LibraryManagementFoldersActions.moveFolder({ id: folder.id, folderId }))
          },
          onCreateAndMoveItem: (folderId: number, name: string) =>
            this.store$.dispatch(
              LibraryManagementFoldersActions.createAndMoveFolder({ id: folder.id, folderId, name })
            ),
        }

        let dialogInstance = this.dialog.open(LibraryMoveItemModalComponent, { data: modalConfig })

        return LibraryManagementFoldersActions.setDialogId({ id: dialogInstance.id })
      })
    )
  )

  moveFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.MOVE_FOLDER),
      switchMap(({ id, folderId }: MoveLibraryItemAction) =>
        this.libraryItemsService.moveLibraryItem(id, getPlainHttpParams({ folderId })).pipe(
          map(() => LibraryManagementFoldersActions.moveFolderSuccess()),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during moving item to folder',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryManagementFoldersActions.moveFolderFail({ error }))
          })
        )
      )
    )
  )

  createAndMoveFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.CREATE_AND_MOVE_FOLDER),
      withLatestFrom(this.store$.select(LibraryManagementFoldersSelectors.selectDialogId)),
      switchMap(([{ id, folderId, name }, dialogId]: [CreateFolderAndMoveLibraryItemAction, string]) =>
        this.libraryFoldersService
          .createFolder(getPlainHttpParams(this.libraryApiMappingService.mapFolderToApi({ name }, { id: folderId })))
          .pipe(
            map((folder: LibraryManagementFolder) => {
              this.dialog.getDialogById(dialogId).close()
              return LibraryManagementFoldersActions.moveFolder({ id, folderId: folder.id })
            }),
            catchError((error: any) => {
              if (error.status === 409) {
                this.setAlreadyExistsError(dialogId)
                return of(LibraryManagementFoldersActions.createAndMoveFolderFail(error))
              }

              console.error(error)
              this.snackbarService.showSnackbar(
                'An error occurred during moving item to folder',
                SNACKBAR_LENGTH_LONG,
                SnackbarEventType.ERROR
              )
              return of(LibraryManagementFoldersActions.createAndMoveFolderFail({ error }))
            })
          )
      )
    )
  )

  getFolderGroupSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.GET_GROUP_SETTINGS),
      switchMap(({ id }: CreateFolderAndMoveLibraryItemAction) =>
        this.libraryFoldersService.getFolderGroupSettings(id).pipe(
          map((groupSettings: LibraryManagementFolderGroupSettingsModel[]) =>
            LibraryManagementFoldersActions.getGroupSettingsSuccess({ groupSettings })
          ),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during loading folder group settings',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryManagementFoldersActions.getGroupSettingsFail({ error }))
          })
        )
      )
    )
  )

  setFolderGroupSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.SET_GROUP_SETTINGS),
      withLatestFrom(this.store$.select(LibraryManagementFoldersSelectors.selectDialogId)),
      switchMap(([{ id, groupSettings }, dialogId]) =>
        this.libraryFoldersService.setFolderGroupSettings(groupSettings).pipe(
          map(() => {
            this.dialog.getDialogById(dialogId)?.close()
            return LibraryManagementFoldersActions.setGroupSettingsSuccess({ id, groupSettings })
          }),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during setting folder group settings',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryManagementFoldersActions.setGroupSettingsFail({ error }))
          })
        )
      )
    )
  )

  getDataAfterChanges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryManagementFoldersActionTypes.MOVE_FOLDER_SUCCESS),
      map(() => LibraryManagementFoldersActions.getFolders())
    )
  })

  setAlreadyExistsError(dialogId: string) {
    let modalForm = this.dialog.getDialogById(dialogId)?.componentInstance.modalForm
    modalForm.get('name').setErrors({ [LibraryFormError.ALREADY_EXISTS]: 'Folder with this name already exists' })
    modalForm.markAllAsTouched()
  }
}

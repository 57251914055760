import {
  libraryComponentInitialState,
  libraryDeliverableInitialState,
  libraryManagementFeatureKey,
  libraryManagementFoldersInitialState,
  libraryManagementInitialState,
  libraryManagementItemsInitialState,
  libraryThirdPartyCostInitialState,
} from '@app/features/library-management/store'

import { LibraryManagementFeatureState } from '@app/features/library-management/store/models/state'
import { createFeatureSelector } from '@ngrx/store'
import {
  libraryDeliverableEntryDetailsState
} from '@app/features/library-management/store/reducers/library-deliverable-entry-details.reducer';

export const libraryManagementFeatureInitialState: LibraryManagementFeatureState = {
  libraryManagement: libraryManagementInitialState,
  libraryItems: libraryManagementItemsInitialState,
  folders: libraryManagementFoldersInitialState,
  thirdPartyCost: libraryThirdPartyCostInitialState,
  deliverable: libraryDeliverableInitialState,
  component: libraryComponentInitialState,
  deliverableEntryDetails: libraryDeliverableEntryDetailsState,
}

export const getLibraryManagementFeatureState =
  createFeatureSelector<LibraryManagementFeatureState>(libraryManagementFeatureKey)

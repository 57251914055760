import {
  Component,
  Inject,
  AfterViewInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SharedModule } from '@app/shared/shared.module';
import {
  ScopeUiDatepickerComponent,
} from '@app/shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component';
import {
  ScopeUiAutocompleteComponent,
} from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxMaskDirective } from 'ngx-mask';
import {
  ScopeUiDropdownComponent,
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { Store } from '@ngrx/store';
import { QuestionType } from '@core/model/enums/question-type.enum';
import { FormControl } from '@angular/forms';
import { FormulaBuilderComponent } from '@shared/components/formula-builder/formula-builder.component';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { Observable } from 'rxjs';
import { ScopeUiChipsComponent } from '@shared/components/ui-components/scope-ui-chips/scope-ui-chips.component';
import { ScopeUiRadioComponent } from '@shared/components/ui-components/scope-ui-radio/scope-ui-radio.component';
import {
  ScopeUiCheckboxComponent,
} from '@shared/components/ui-components/scope-ui-checkbox/scope-ui-checkbox.component';
import { LibraryManagementNgrxModule } from '@app/features/library-management/library-management-ngrx.module';
import { ScopeUiTableComponent } from '@shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { MatTableDataSource } from '@angular/material/table';
import { SvgIconComponent } from '@shared/components/svg-icon/svg-icon.component';
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model';
import { FeeItem } from '@app/features/scope-overview/model/fee-item.model';
import { ScopingSelectors } from '@app/features/scoping/store/selectors/scoping.selector';
import {
  PromptAutocompleteComponent
} from '@app/features/company-management/components/prompt-autocomplete/prompt-autocomplete.component';
import { FeePromptOption } from '@app/features/scope-overview/model/fee-prompt.model';
import { ScopeActions } from '@app/features/scoping/store/actions/scoping.actions';
import { plainToInstance } from 'class-transformer';
import {
  CompanyManagementSelectors
} from '@app/features/company-management/store/selectors/company-management.selectors';

export interface AddFeePromptModalConfig {
  scenario: Scenario,
  question?: ScenarioQuestion,
  confirmCallback: (question: any) => void;
}

@Component({
  selector: 'add-fee-prompt-modal',
  standalone: true,
  imports: [
    CommonModule,
    LibraryManagementNgrxModule,
    ScopeUiDatepickerComponent,
    ScopeUiAutocompleteComponent,
    SharedModule,
    MatDatepickerModule,
    CKEditorModule,
    ScopeUiInputComponent,
    NgxSliderModule,
    NgxMaskDirective,
    ScopeUiDropdownComponent,
    FormulaBuilderComponent,
    ScopeUiChipsComponent,
    ScopeUiRadioComponent,
    ScopeUiCheckboxComponent,
    PromptAutocompleteComponent,
    ScopeUiTableComponent,
    SvgIconComponent,
  ],
  templateUrl: './add-fee-prompt-modal.component.html',
  styleUrls: ['./add-fee-prompt-modal.component.scss'],
})
export class AddFeePromptModalComponent implements AfterViewInit {
  title: string;
  instructions: string;
  questionTextLabel: string;
  configureTitle: string;
  questionText: string;
  required: boolean = false;
  visible: boolean = true;
  showAlways: boolean = true;
  showOnCondition: boolean = false;
  condition: string;
  step: number = 1;
  page: number = 0;
  scenario: Scenario;
  conditionControl = new FormControl();
  feeItems$: Observable<FeeItem[]>;
  isLoading$: Observable<boolean>;
  selectedFeeItems: FeePromptOption[] = [];
  dataSource: MatTableDataSource<FeePromptOption>;
  sortedColumn: string | null = null;
  sortDirection: string | null = '';
  displayedColumns: string[] = ['pin', 'name', 'delete'];
  updateLoading$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddFeePromptModalConfig,
    private store: Store,
    public dialog: MatDialog
  ) {
    this.updateLoading$ = this.store.select(CompanyManagementSelectors.selectLoadingAddUpdateScenarioQuestion)
    this.scenario = this.data.scenario
    if (data.question) {
      this.questionText = data.question.displayText
      this.required = data.question.mandatory
      this.showAlways = !data.question.hidden && !data.question.displayCondition
      this.showOnCondition = !!data.question.displayCondition
      this.condition = data.question.displayCondition
      this.selectedFeeItems = data.question.feePrompt.feePromptOptions
    }

    this.title = `Configure Fee prompt`;
    this.instructions = `Set up the question by adding display text, marking it as required if needed, and defining its visibility. Choose fees for the question, and pin them to ensure they’re always included in the scope.`;
    this.questionTextLabel = `Enter a prompt for selecting fees`;
    this.configureTitle = `Set when this Fee Selection appears`;

    this.dataSource = new MatTableDataSource<FeePromptOption>([]);
  }

  ngAfterViewInit(): void {
    this.refreshTable();
  }

  onSort(column: string): void {
    if (this.sortedColumn === column) {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else if (this.sortDirection === 'desc') {
        this.sortedColumn = null;
        this.sortDirection = '';
      } else {
        this.sortDirection = 'asc';
      }
    } else {
      this.sortedColumn = column;
      this.sortDirection = 'asc';
    }
    this.orderData();
  }

  private orderData(): void {
    const data = [...this.selectedFeeItems];

    if (!this.sortedColumn || !this.sortDirection) {
      this.dataSource.data = data;
      return;
    }

    const direction = this.sortDirection;
    const column = this.sortedColumn;

    data.sort((a, b) => {
      let valA: string = '';
      let valB: string = '';

      if (column === 'name') {
        valA = (a.name || '').toLowerCase();
        valB = (b.name || '').toLowerCase();
      }

      if (valA > valB) return direction === 'asc' ? 1 : -1;
      if (valA < valB) return direction === 'asc' ? -1 : 1;
      return 0;
    });

    this.dataSource.data = data;
  }

  private refreshTable() {
    this.dataSource.data = [...this.selectedFeeItems];
    this.orderData();
  }

  updateSelectedFees(selected: FeeItem): void {
    this.selectedFeeItems = [...this.selectedFeeItems, plainToInstance(FeePromptOption, {
      feeId: selected.id,
      name: selected.name
    })];
    this.refreshTable();
  }

  goToStep(step: number) {
    if (step === 2) {
      this.submit();
    } else {
      this.store.dispatch(ScopeActions.getAllFeeItems())
      this.feeItems$ = this.store.select(ScopingSelectors.selectAllFeeItems)
      this.isLoading$ = this.store.select(ScopingSelectors.selectLoadingFees)
      ++this.step;
    }
  }

  optionFunction = (option: FeeItem) => {
    return `<div class='item-title'>${option.name}</div>`
  }

  disableNextStep(): boolean {
    return !this.questionText ||
      (this.showOnCondition && (!this.condition || this.conditionControl.hasError('invalid'))) ||
      (this.step === 2 && !this.selectedFeeItems.length);
  }

  submit() {
    const feePrompt = {
      feePromptOptions: this.selectedFeeItems,
    };
    this.data.confirmCallback(
      {
        id: this.data.question?.id,
        displayText: this.questionText,
        mandatory: this.required,
        displayCondition: this.condition,
        type: QuestionType.FEE,
        feePrompt: feePrompt,
      },
    );
  }

  removeFeeItem(feeItem: FeePromptOption) {
    this.selectedFeeItems = this.selectedFeeItems.filter(
      (d) => d.feeId !== feeItem.feeId,
    );
    this.refreshTable();
  }

  pinFeeItem(feeItem: FeePromptOption) {
    const updated = {
      ...feeItem,
      required: !feeItem.required,
    };
    this.selectedFeeItems = this.selectedFeeItems.map((d) =>
      d.feeId === feeItem.feeId ? updated : d,
    );
    this.refreshTable();
  }

  isSelected = (option: FeeItem) => {
    return this.selectedFeeItems.some((selected) => selected.feeId === option.id);
  }
}

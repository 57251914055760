import {
  LibraryManagementFoldersState,
  libraryFoldersColumnsBaseConfig,
} from '@app/features/library-management/store/models/library-folders'
import {
  LibraryManagementItemsState,
  libraryItemEntriesColumnsBaseConfig,
  libraryItemsColumnsBaseConfig,
} from '@app/features/library-management/store/models/library-items'

import { LibraryComponentState } from '@app/features/library-management/store/models/component'
import { LibraryDeliverableState } from '@app/features/library-management/store/models/deliverable'
import { LibraryManagementFilters } from '@app/features/library-management/store/models'
import { LibraryManagementState } from '@app/features/library-management/store/models/state'
import { LibraryThirdPartyCostState } from '@app/features/library-management/store/models/third-party-cost'
import { Pagination } from '@app/core/model/definitions/pagination.interface'
import { Sort } from '@angular/material/sort'

export const libraryManagementFiltersInitialState: LibraryManagementFilters = {
  searchString: undefined,
  folderId: undefined,
  itemTypes: undefined,
  archived: false,
  members: [],
  ratecards: [],
  createdDates: undefined,
  disciplines: [],
}

export const libraryManagementPaginationInitialState: Pagination = {
  totalCount: 0,
  page: 0,
  pageSize: 10,
}

export const libraryManagementSortInitialState: Sort = {
  active: undefined,
  direction: undefined,
}

export const libraryManagementInitialState: LibraryManagementState = {
  isLoading: false,
  filters: libraryManagementFiltersInitialState,
  rateCards: [],
  isRateCardsLoaded: false,
  disciplines: [],
  isDisciplinesLoaded: false,
  highlights: {
    counts: {
      LibraryFolder: 0,
      LibraryThirdPartyCost: 0,
      LibraryDeliverableTemplate: 0,
      LibraryComponentTemplate: 0,
    },
    created: null,
    creators: [],
    disciplines: [],
    ratecards: [],
  },
  folder: undefined,
  dialogId: undefined,
}

export const libraryManagementItemsInitialState: LibraryManagementItemsState = {
  isLoading: false,
  isLoadingArchived: false,
  isLoadingColumns: false,
  columns: libraryItemsColumnsBaseConfig,
  childColumns: libraryItemEntriesColumnsBaseConfig,
  dialogId: undefined,

  list: { ids: [], entities: {} },
  pagination: libraryManagementPaginationInitialState,
  sort: libraryManagementSortInitialState,

  archivedList: { ids: [], entities: {} },
  archivedPagination: libraryManagementPaginationInitialState,
  archivedSort: libraryManagementSortInitialState,
}

export const libraryManagementFoldersInitialState: LibraryManagementFoldersState = {
  isLoading: false,
  isLoadingArchived: false,
  isLoadingColumns: false,
  isLoadingAllFolders: false,
  isLoadedAllFolders: false,
  columns: libraryFoldersColumnsBaseConfig,
  allFoldersList: { ids: [], entities: {} },
  folderToMove: undefined,
  dialogId: undefined,
  groupSettings: [],

  list: { ids: [], entities: {} },
  pagination: libraryManagementPaginationInitialState,
  sort: libraryManagementSortInitialState,

  archivedList: { ids: [], entities: {} },
  archivedPagination: libraryManagementPaginationInitialState,
  archivedSort: libraryManagementSortInitialState,
}

export const libraryThirdPartyCostInitialState: LibraryThirdPartyCostState = {
  isLoading: false,
  groups: [],
  isGroupsLoaded: false,
  formulas: [],
  isFormulasLoaded: false,
  isTpcLoading: false,
  tpc: undefined,
}

export const libraryDeliverableInitialState: LibraryDeliverableState = {
  isLoading: false,
  isLoadingRateCard: false,
  deliverable: undefined,
  pagination: libraryManagementPaginationInitialState,
  rateCard: undefined,
  entriesMap: {},
}

export const libraryComponentInitialState: LibraryComponentState = {
  isLoading: false,
  isLoadingRateCard: false,
  isEntryChanged: false,
  rateCard: undefined,
  component: undefined,
  pagination: libraryManagementPaginationInitialState,
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LibraryDeliverableEntry } from '@app/features/library-management/store/models/deliverable'
import { Observable } from 'rxjs'
import { environment } from '@envs/environment'

@Injectable({ providedIn: 'root' })
export class LibraryDeliverableService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl

  constructor(private http: HttpClient) {}

  getDeliverable(id: number): Observable<any> {
    return this.http.get<any>(`${this.BASE_API_URL}library/deliverable/${id}`)
  }

  updateDeliverable(deliverable: any) {
    return this.http.put<any>(`${this.BASE_API_URL}library/deliverable/${deliverable.id}`, deliverable, {
      params: { updateComponents: false },
    })
  }

  getDeliverableEntry(id: number): Observable<any> {
    return this.http.get<any>(`${this.BASE_API_URL}library/deliverable-entry/${id}`)
  }

  createDeliverableEntry(deliverableId: number, entry): Observable<any> {
    return this.http.post<any>(`${this.BASE_API_URL}library/deliverable/${deliverableId}/entry`, entry)
  }

  deleteDeliverableEntry(deliverableId: number, entryId: number): Observable<any> {
    return this.http.delete<any>(`${this.BASE_API_URL}library/deliverable/${deliverableId}/entry/${entryId}`)
  }

  duplicateDeliverableEntry(deliverableId: number, entryId: number, rateCardId: number, payload): Observable<any> {
    return this.http.post<any>(
      `${this.BASE_API_URL}library/deliverable/${deliverableId}/entry/${entryId}/ratecard/${rateCardId}/duplicate`,
      payload
    )
  }

  getRateCard(id: number, version: number) {
    return this.http.get(`${this.BASE_API_URL}company/ratecard/${id}`, { params: { version } })
  }

  toggleFixedCostEditableStatus(entry: LibraryDeliverableEntry) {
    return this.http.put(`${this.BASE_API_URL}library/deliverable-entry/${entry.id}/toggle-fixed-cost-editable`, entry)
  }
}

import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import {
  LibraryDiscipline,
  LibraryItemType,
  LibraryManagementActions,
  LibraryManagementSelectors,
  LibraryRateCard,
} from '@app/features/library-management/store'
import { Store, select } from '@ngrx/store'

import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Privilege } from '@app/core/model/enums/privilege.enum'
import { LanguageService } from '@app/core/service/language.service'
import { LibraryTemplateType } from '@app/features/library-management/enums/library-template-type.enum'
import { LibraryModalConfig } from '@app/features/library-management/models'
import { Observable } from 'rxjs'

@Component({
  templateUrl: './library-create-deliverable-modal.component.html',
})
export class LibraryCreateDeliverableModalComponent {
  PrivilegeEnum = Privilege

  typeOptions = [
    { name: this.lang.get('deliverable'), id: LibraryTemplateType.DELIVERABLE },
    { name: 'Fixed Fee', id: LibraryTemplateType.FIXED_COST },
  ]

  modalForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    fixedPricing: new FormControl(false, [Validators.required]),
    itemType: new FormControl(LibraryItemType.DELIVERABLE, [Validators.required]),
    libraryItemType: new FormControl(this.typeOptions[0].id, [Validators.required]),
  })

  entriesForm: FormGroup = new FormGroup({
    discipline: new FormControl(null, [Validators.required]),
    rateCard: new FormControl(null, [Validators.required]),
    fixedCostEditable: new FormControl(true),
  })

  rateCards$: Observable<LibraryRateCard[]>
  disciplines$: Observable<LibraryDiscipline[]>

  get isFormValid(): boolean {
    return this.modalForm.valid && this.entriesForm.valid
  }

  get isFixedCost() {
    return this.getDeliverableControlValue('libraryItemType') === LibraryTemplateType.FIXED_COST
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LibraryModalConfig,
    private store$: Store,
    private lang: LanguageService
  ) {
    this.rateCards$ = this.store$.pipe(select(LibraryManagementSelectors.selectRateCards))
    this.disciplines$ = this.store$.pipe(select(LibraryManagementSelectors.selectDisciplines))

    this.store$.dispatch(LibraryManagementActions.getRatecards())
    this.store$.dispatch(LibraryManagementActions.getDisciplines())
  }

  getDeliverableControl = (key: string) => this.modalForm.get(key) as FormControl
  getDeliverableControlValue = (key: string) => this.getDeliverableControl(key).value
  setDeliverableControlValue = (key: string, value: any) => this.getDeliverableControl(key).setValue(value)
  getEntriesControl = (key: string) => this.entriesForm.get(key) as FormControl
  getEntriesControlValue = (key: string) => this.getEntriesControl(key).value
  setEntriesControlValue = (key: string, value: any) => this.getEntriesControl(key).setValue(value)

  submit() {
    this.modalForm.markAllAsTouched()
    this.entriesForm.markAllAsTouched()

    if (this.isFormValid) {
      this.setDeliverableControlValue('fixedPricing', this.isFixedCost)
      if (!this.isFixedCost) this.setEntriesControlValue('fixedCostEditable', false)

      this.data.onSubmit({
        deliverable: this.modalForm.value,
        entry: this.entriesForm.value,
      })
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '@envs/environment';
import { plainToInstance } from 'class-transformer';
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model';

@Injectable({
  providedIn: 'root',
})
export class ScenarioQuestionService {
  private readonly BASE_URL: string = environment.scopeApiUrl + 'scenarios';

  constructor(private http: HttpClient) {
  }

  create(scenarioId: number, categoryId: number, questionRequest: any): Observable<ScenarioQuestion> {
    return this.http.post<ScenarioQuestion>(`${this.BASE_URL}/${scenarioId}/categories/${categoryId}/questions`, questionRequest, { observe: 'response' })
      .pipe(map((data) => {
        if (data.status === 201) {
          return plainToInstance(ScenarioQuestion, data.body)
        } else {
          console.error(data.body)
          throw new Error('Failed to create scenario question')
        }
      }));
  }

  getAll(scenarioId: number, categoryId: number): Observable<ScenarioQuestion[]> {
    return this.http.get<ScenarioQuestion[]>(`${this.BASE_URL}/${scenarioId}/categories/${categoryId}/questions`)
      .pipe(map((questions) => plainToInstance(ScenarioQuestion, questions)));
  }

  get(scenarioId: number, categoryId: number, questionId: number): Observable<ScenarioQuestion> {
    return this.http.get<ScenarioQuestion>(`${this.BASE_URL}/${scenarioId}/categories/${categoryId}/questions/${questionId}`)
      .pipe(map((question) => plainToInstance(ScenarioQuestion, question)));
  }

  update(scenarioId: number, categoryId: number, questionId: number, questionRequest: any): Observable<ScenarioQuestion> {
    return this.http.put<void>(`${this.BASE_URL}/${scenarioId}/categories/${categoryId}/questions/${questionId}`, questionRequest)
      .pipe(map((question) => plainToInstance(ScenarioQuestion, question)));
  }

  delete(scenarioId: number, categoryId: number, questionId: number): Observable<void> {
    return this.http.delete<void>(`${this.BASE_URL}/${scenarioId}/categories/${categoryId}/questions/${questionId}`);
  }

  reorder(scenarioId: number, categoryId: number, orderedQuestionIds: number[]): Observable<void> {
    return this.http.put<void>(`${this.BASE_URL}/${scenarioId}/categories/${categoryId}/questions/reorder`, orderedQuestionIds);
  }
}

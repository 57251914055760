import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { MatSort, Sort } from '@angular/material/sort'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { formatMonetaryValue, trackById } from '@app/shared/utils/utils'
import { instanceToInstance, plainToInstance } from 'class-transformer'

import { CommonModule } from '@angular/common'
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service'
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model'
import { FolderVersion } from '@core/model/folder-version.model'
import { LangfPipe } from '@app/shared/pipe/langf.pipe'
import { LanguageService } from '@core/service/language.service'
import { LinkToSelectorComponent } from '@app/features/scope-overview/components/scope-tab/link-to-selector/link-to-selector.component'
import { MappedEntity } from '@app/features/scoping/models/mapped-entity.model'
import { MatTableDataSource } from '@angular/material/table'
import { MenuOptions } from '@app/core/model/definitions/menu-options.interface'
import { Money } from '@app/features/scope-overview/model/money.model'
import { PageEvent } from '@angular/material/paginator'
import { Preference } from '@app/core/model/user-preferences.interface'
import { ScopeOverviewSelectors } from '@app/features/scope-overview/store/selectors/scope-overview.selector'
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'
import { SharedModule } from '@app/shared/shared.module'
import { ScopeTeamMember } from '@core/model/scope-team.model'
import { ScopeUiAutocompleteComponent } from '@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import { ScopeUiBoxInputComponent } from '@shared/components/ui-components/scope-ui-box-input/scope-ui-box-input.component'
import { ScopeUiCounterComponent } from '@shared/components/ui-components/scope-ui-counter/scope-ui-counter.component'
import { ScopeUiDropdownComponent } from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { ScopeUiOptionsMenuComponent } from '../../scope-ui-options-menu/scope-ui-options-menu.component'
import { ScopeUiPaginatorComponent } from '@shared/components/ui-components/scope-ui-paginator/scope-ui-paginator.component'
import { SortDirection } from '@app/core/model/enums/sort-direction.enum'
import { Store } from '@ngrx/store'
import { SvgIconComponent } from '../../svg-icon/svg-icon.component'
import { TableColumnKey } from './table-column-key.enum';
import { ToggleListComponent } from '../toggle-list/toggle-list.component'
import { User } from '@app/core/model/user.model'
import dayjs from 'dayjs'
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormControl, Validators } from '@angular/forms'
import { ScopeUiOptionsMenuSeparatedComponent } from '../../scope-ui-options-separated-menu/scope-ui-options-separated-menu.component'
import { ComplexitiesList } from '@core/model/enums/complexity.enum';

export enum ScopeUiTableSelectMode {
  NONE,
  NAME,
  ROW
}

@Component({
  selector: 'scope-ui-table',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ToggleListComponent,
    SvgIconComponent,
    ScopeUiOptionsMenuComponent,
    ScopeUiOptionsMenuSeparatedComponent,
    ScopeUiDropdownComponent,
    ScopeUiInputComponent,
    ScopeUiPaginatorComponent,
    ScopeUiAutocompleteComponent,
    LinkToSelectorComponent,
    ScopeUiCounterComponent,
    ScopeUiBoxInputComponent,
    DragDropModule,
  ],
  templateUrl: './scope-ui-table.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  styleUrls: ['./scope-ui-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LangfPipe],
})
export class ScopeUiTableComponent implements OnInit {
  @Input() loading: boolean
  @Input() loaderRowsAmount: number = 10
  @Input() condensed: boolean
  @Input() expandable!: boolean
  @Input() selectMode = ScopeUiTableSelectMode.NONE
  @Input() sortDirection: string | null
  @Input() sortedColumn: string | null
  @Input() loggedInUser: User
  @Input() noSort: boolean | undefined
  @Input() noPagination: boolean | undefined
  @Input() noPreferences: boolean | undefined
  @Input() editAction: any | undefined
  @Input() deleteAction: any | undefined
  @Input() showToggleMenu: boolean = true
  @Input() addToggleListColumn
  @Input() showAddButton: boolean
  @Input() level: number
  @Input() page: number | undefined
  @Input() totalCount!: number | undefined
  @Input() componentColumns: any
  @Input() departmentColumns: any
  @Input() roleColumns: any
  @Input() showFooterRow!: boolean
  @Input() parentEntity: any
  @Input() headerExpandable!: boolean
  @Input() showExpandAll!: boolean
  @Input() showBreadcrumb!: boolean
  @Input() showDescription!: boolean
  @Input() hideHeader: boolean
  @Input() tableClass?: string
  @Input() isSubTable: boolean = false
  @Input() stickyHeader?: boolean
  @Input() includeEmptyMessage?: boolean = true
  @Input() editDeliverableAction: any
  @Input() maxColumns: number = 9
  @Input() useSourceId: boolean
  @Input() hideToggles: boolean
  @Input() optimise: boolean
  @Input() menuOptions?: MenuOptions[]
  @Input() secondaryMenuOptions?: MenuOptions[]
  @Input() childMenuOptions?: MenuOptions[]
  @Input() disableDragging!: boolean
  @Input() showHideDescriptionOnly: boolean = false
  @Input() displayAllDescriptions: boolean = false
  @Input() rowGaps: boolean = false
  @Input() enableDragging: boolean = false
  @Input() checkDropLocation: (data: any[], previousIndex: number, currentIndex: number) => boolean = () => true
  @Input() lineClamp?: number
  @Input() trackById = trackById;

  @Input() set data(value: any) {
    this._data = value
    this.mapDataSource()
  }
  @Input() set unmappedData(value: any) {
    this._unmappedData = value
    this.mapDataSource()
  }

  @Input() set displayedColumns(value: Preference[] | null) {
    if (value) {
      this.preferencesArray = value.filter((p) =>
        (!p.requiredPrivilege || this.loggedInUser?.hasPrivilege(p.requiredPrivilege)) &&
        (!p.requiredSetting || this.loggedInUser?.company.hasApplicationSetting(p.requiredSetting)));
      this._displayedColumns = this.preferencesArray.filter((p) => p.selected && p.key !== TableColumnKey.SCOPE_NUMBER)
      this.displayedColumnKeys = this._displayedColumns.map((it) => it.key);
      this.addToggleColumn();
    } else {
      this._displayedColumns = null
      this.displayedColumnKeys = null
    }
  }

  _tableExpanded: boolean;
  @Input() set tableExpanded(value: boolean) {
    this._tableExpanded = value
  }

  @Input() set sort(value: Sort) {
    if (value) {
      this._sort = value
      this.setSortingDataAccessor()
      this.orderData()
    }
  }

  @Input() expandedElements: { [id: number]: boolean } = {}
  @Output() expandedElementsChange = new EventEmitter<{ [id: number]: boolean }>()

  @Output() disableDraggingChange = new EventEmitter<boolean>()
  @Output() tableExpandedChange = new EventEmitter<boolean>()
  @Output() pageChange = new EventEmitter<PageEvent>()
  @Output() onSort = new EventEmitter<Sort>()
  @Output() onToggle = new EventEmitter<Preference>()
  @Output() onExpand = new EventEmitter<any>()
  @Output() onSelect = new EventEmitter<any>()
  @Output() addClicked = new EventEmitter<void>()
  @Output() updateFeeItem = new EventEmitter<FeeItemInstance>()
  @Output() updateScopeTpc = new EventEmitter<ThirdPartyCost>()
  @Output() onSaveElement = new EventEmitter<any>()
  @Output() linkElement = new EventEmitter<any>()
  @Output() orderChange = new EventEmitter<any[]>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if ((this._data || this._unmappedData) && this.noPagination) this.orderData(sort)
  }

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>

  isScopeLoading$ = this.store.select(ScopeOverviewSelectors.selectCurrentScopeLoading)

  loadingRowId: string | null = null
  isOpen: boolean
  levels: { name: string; value: string }[]
  preferencesArray!: any[]
  customMoreOptionsMenuClasses: string[]

  _displayedColumns!: Preference[] | null
  displayedColumnKeys!: TableColumnKey[] | null

  dataSource!: MatTableDataSource<any>
  _unmappedData: any
  _data: any
  _sort: Sort
  
  editableEntity: any
  editableIsEdit: { [key: string]: boolean } = {}
  editableControls: { [key: string]: FormControl } = {}

  constructor(
    private lang: LanguageService,
    private store: Store,
    private mappingService: DataMappingService,
    public cdr: ChangeDetectorRef
  ) {
    this.dataSource = new MatTableDataSource()
    this.page = 0
    this.customMoreOptionsMenuClasses = ['toggle-button', 'float-right']
    this.sortDirection = null
    this.sortedColumn = null
    this.levels = [
      { name: this.lang.get('scope'), value: 'scope' },
      { name: this.lang.get('stage'), value: 'section' },
      { name: this.lang.get('deliverable'), value: 'deliverable' },
      { name: this.lang.get('component'), value: 'component' },
    ]
  }

  ngOnInit(): void {
    this.addToggleColumn()
    this.mapDataSource()
  }

  addToggleColumn() {
    if (this.addToggleListColumn && this.displayedColumnKeys && !this.displayedColumnKeys.includes(TableColumnKey.TOGGLE_LIST)) {
      this._displayedColumns.push({ name: '', key: TableColumnKey.TOGGLE_LIST })
      this.displayedColumnKeys.push(TableColumnKey.TOGGLE_LIST)
    }
  }

  mapDataSource = () => {
    if (this._unmappedData && this._displayedColumns) {
      this.dataSource = new MatTableDataSource(
        this.mappingService.transformArray<any>(this._unmappedData, this._displayedColumns)
      )
      this.cdr.detectChanges()
      this.setSortingDataAccessor()
    } else if (this._data) {
      this.dataSource = new MatTableDataSource()
      if (!this.optimise) {
        this.dataSource.data = this._data
        this.cdr.detectChanges()
      }
      this.setSortingDataAccessor()
      this.orderData()
      if (this.optimise) {
        // Set data after sort for optimised rendering. Does not work in conjunction with async data
        this.dataSource.data = this._data
        setTimeout(() => this.cdr.detectChanges())
        this.optimise = false
      }
    }
  }

  setSortingDataAccessor() {
    if (!this.dataSource) return
    this.dataSource.sortData = (data: any[], sort: MatSort) => {
      const active = sort.active
      const direction = sort.direction
      if (!active || direction == SortDirection.NONE) return data

      return data.sort((a, b) => {
        let valueA = a[active] instanceof Money ? a[active].amount : a[active]
        let valueB = b[active] instanceof Money ? b[active].amount : b[active]

        if (active === TableColumnKey.LINK_TO) {
          valueA = valueA?.name || 'Scope'
          valueB = valueB?.name || 'Scope'
        }

        if (active === TableColumnKey.NAME && !valueA) {
          valueA = a.entity?.name
          valueB = b.entity?.name
        }

        if (active === TableColumnKey.COMPLEXITY) {
          valueA = ComplexitiesList.indexOf(valueA)
          valueB = ComplexitiesList.indexOf(valueB)
        }

        const valueAType = typeof valueA
        const valueBType = typeof valueB

        if (valueAType === 'string' && valueBType === 'string')
          return valueA.localeCompare(valueB) * (direction == SortDirection.ASC ? 1 : -1)

        if (valueAType !== valueBType) {
          if (valueAType === 'number') valueA += ''
          if (valueBType === 'number') valueB += ''
        }

        let comparatorResult = 0
        if (valueA != null && valueB != null) {
          if (valueA > valueB) comparatorResult = 1
          else if (valueA < valueB) comparatorResult = -1
        } else if (valueA != null) comparatorResult = 1
          else if (valueB != null) comparatorResult = -1

        return comparatorResult * (direction == SortDirection.ASC ? 1 : -1)
      })
    }
  }

  orderData(sort?: MatSort) {
    if (this.dataSource) {
      const matSort = sort || this.dataSource.sort || new MatSort()

      if (this._sort) matSort.sort({ id: this._sort.active, start: this._sort.direction, disableClear: false })

      this.dataSource.sort = matSort
    }
  }

  shouldDisplayScopeNumber(): boolean {
    const scopeNumberPref = this.preferencesArray?.find((pref) => pref.key === TableColumnKey.SCOPE_NUMBER)
    return scopeNumberPref && scopeNumberPref.selected
  }

  getCellClass(column: TableColumnKey, value: any, entity: any): string {
    if (column === TableColumnKey.STATUS) {
      switch (value) {
        case 'CLOSED': return 'text-error-600 font-newJuneBold'
        case 'SUBMITTED':
        case 'IN_REVIEW':
          return 'text-critical-600 font-newJuneBold'
        case 'AGENCY_APPROVED': return 'text-success-700 font-newJuneBold'
        case 'CLIENT_APPROVED': return 'text-safe-700 font-newJuneBold'
        case 'SCOPING': return 'text-success-500 font-newJuneBold'
        case 'TRAFFICKED': return 'text-blue-500 font-newJuneBold'
        case 'DRAFT': return 'text-blue-violet-500 font-newJuneBold'
        case 'REVIEWED': return 'text-critical-700 font-newJuneBold'
        case 'REJECTED': return 'text-error-800 font-newJuneBold'
        case 'CONFIG_DRAFT':
          return 'text-critical-700 font-newJuneBold'
        default: return ''
      }
    } else if (column === TableColumnKey.BUDGET && value !== '-') {
      return entity.hasEnoughBudget?.() ? 'text-success-800' : 'text-error-600'
    }
    else if (column === TableColumnKey.EDITABLE_AGENCY_PRICE) {
      return 'editable-row'
    }
    return this.level ? `level-${this.level}` : ''
  }

  transformDate(date: Date): string {
    let format = this.loggedInUser?.company?.companyDateFormat;
    if (!date) return '--';
    if (!format) format = 'DD/MM/YYYY';
    return dayjs.utc(date).format(format);
  }

  formatCellData(column: TableColumnKey, value: any, entity?: any): any {
    switch (column) {
      case TableColumnKey.DATE_CREATED:
      case TableColumnKey.DATE_APPROVED:
      case TableColumnKey.LAST_EDITED:
      case TableColumnKey.START_DATE:
      case TableColumnKey.END_DATE:
        return !value || value === '-' ? '-' : this.transformDate(new Date(value))

      case TableColumnKey.BUDGET:
      case TableColumnKey.BALANCE:
        return formatMonetaryValue(
          entity instanceof FolderVersion
            ? (value && value.length == 1 ? value[0].budget : value.budget)
            : value)

      case TableColumnKey.VALUE:
        return entity instanceof FolderVersion
          ? (value && value.length == 1
            ? formatMonetaryValue(value[0])
            : (!Array.isArray(value) ? formatMonetaryValue(value) : ''))
          : formatMonetaryValue(value)

      case TableColumnKey.PROFIT:
      case TableColumnKey.AGENCY_PRICE:
      case TableColumnKey.EDITABLE_AGENCY_PRICE:
      case TableColumnKey.SCOPEMARK_PRICE:
      case TableColumnKey.AGENCY_RATE:
      case TableColumnKey.AGENCY_COST:
      case TableColumnKey.EDITABLE_AGENCY_COST:
      case TableColumnKey.WEIGHTED_RATE:
      case TableColumnKey.UNIT_COST:
      case TableColumnKey.OVERTIME_RATE:
      case TableColumnKey.OVERTIME_TOTAL:
      case TableColumnKey.SELLING_PRICE:
      case TableColumnKey.PRICE:
        return formatMonetaryValue(value)

      case TableColumnKey.STATUS:
        return value
          .split('_')
          .map((v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase())
          .join(' ')

      case TableColumnKey.AMOUNT: return value instanceof Money ? formatMonetaryValue(value) : `${value}%`
      case TableColumnKey.MARK_UP: return value != null ? value + '%' : '-'
      case TableColumnKey.MARGIN: return value ? value.toFixed(2) + '%' : '-'
      case TableColumnKey.MANDATORY: return value ? 'Yes' : 'No'
      default: return value
    }
  }

  isEditableField = (key: TableColumnKey) =>
    [TableColumnKey.EDITABLE_NAME, TableColumnKey.EDITABLE_AGENCY_PRICE, TableColumnKey.EDITABLE_AGENCY_COST].indexOf(key) != -1

  onChangePage(pageEvent: PageEvent): void {
    this.pageChange.emit(pageEvent)
  }

  onSortChange(event: Sort) {
    this.sortDirection = event.direction
    this.sortedColumn = event.active
    this.onSort.emit(event.direction === '' ? { active: event.active, direction: SortDirection.DESC } : event)
    this.expandedElements = {}
  }

  onTogglePreference(preference: Preference) {
    this.onToggle.emit(preference)
  }

  onExpandElement(element: MappedEntity<any>, event?: any, value?: boolean) {
    event?.preventDefault()
    event?.stopPropagation()

    if (!this.expandable || element.isNotExpandable || !this.showToggle(element)) return

    if (value === undefined) value = !this.expandedElements[this.useSourceId ? element.entity.source.id : element.entity.id]

    if (value) this.expandedElements[this.useSourceId ? element.entity.source.id : element.entity.id] = value
    else delete this.expandedElements[this.useSourceId ? element.entity.source.id : element.entity.id]

    this.expandedElementsChange.emit(this.expandedElements)

    if (this.expandedElements[this.useSourceId ? element.entity.source.id : element.entity.id]) this.onExpand.emit(element)
  }

  expandTable() {
    if (!this.headerExpandable) return
    this._tableExpanded = !this._tableExpanded
    this.tableExpandedChange.emit(this._tableExpanded)
  }

  expandAll(event: any) {
    event.preventDefault()
    event.stopPropagation()
    if (!this.showExpandAll) return
    this.dataSource.data.forEach((element) =>
      this.onExpandElement(element, undefined, !Object.keys(this.expandedElements).length))
  }

  showToggle(element: MappedEntity<any>) {
    if (
      element.entity instanceof ScopeTeamMember ||
      element.entity.identity?.identificationType === 'SCOPE_BY_ROLE' ||
      element.isNotExpandable
    ) return false

    return !this.hideToggles
  }

  onSelectElement(element: MappedEntity<any>, event: any) {
    if (this.selectMode !== ScopeUiTableSelectMode.NONE) {
      event.preventDefault()
      event.stopPropagation()
      this.loadingRowId = element.entity.id // Assuming 'id' is the unique identifier of the row
      this.onSelect.emit(element)
    }
  }

  onAdd() {
    this.addClicked.emit()
  }

  isScopeOfWork(element: MappedEntity<any>) {
    return element.entity instanceof FolderVersion
  }

  isFolderBudgetDisplay(element: MappedEntity<any>, key: TableColumnKey) {
    return this.isScopeOfWork(element) && key === TableColumnKey.BUDGET && element[key]?.length > 1
  }

  isFolderValueDisplay(element: MappedEntity<any>, key: TableColumnKey) {
    return this.isScopeOfWork(element) && key === TableColumnKey.VALUE && element[key]?.length > 1
  }

  saveElementDescription(description: string, entity: any) {
    let entityInstance = instanceToInstance(entity)
    entityInstance.description = description
    this.onSaveElement.emit(entityInstance)
  }

  saveElementInternalNote(internalNote: string, entity: any) {
    let entityInstance = instanceToInstance(entity)
    entityInstance.internalNote = internalNote
    this.onSaveElement.emit(entityInstance)
  }

  showFeeItemDescription?: MappedEntity<FeeItemInstance>
  showScopeTpcDescription?: MappedEntity<ThirdPartyCost>
  newFeeItemDescription?: string
  newScopeTpcDescription?: string

  toggleEditFeeItemDescription(element: MappedEntity<FeeItemInstance>) {
    if (this.showFeeItemDescription === element) {
      this.showFeeItemDescription = null
      this.newFeeItemDescription = null
    } else {
      this.showFeeItemDescription = element
      this.newFeeItemDescription = element.entity.feeItem.description
    }
  }

  toggleScopeTpcDescription(element: MappedEntity<ThirdPartyCost>) {
    if (this.showScopeTpcDescription === element) {
      this.showScopeTpcDescription = null
      this.newScopeTpcDescription = null
    } else {
      this.showScopeTpcDescription = element
      this.newScopeTpcDescription = element.entity.description.replace(/<\/?[^>]+(>|$)/g, '')
    }
  }

  setEditFeeItemDescription(element: MappedEntity<FeeItemInstance>) {
    this.updateFeeItem.emit({
      ...element.entity,
      feeItem: { ...element.entity.feeItem, description: this.newFeeItemDescription },
    })
    this.toggleEditFeeItemDescription(element)
  }

  setScopeTpcItemDescription(element: MappedEntity<ThirdPartyCost>) {
    this.updateScopeTpc.emit(
      plainToInstance(ThirdPartyCost, { ...element.entity, description: this.newScopeTpcDescription })
    )
    this.toggleScopeTpcDescription(element)
  }

  onChangeLevel(feeItem: any, level: { name: string; value: string }) {
    feeItem.level = level
    if (level.value === 'scope') this.linkElement.emit({ feeItem })
  }

  onLinkElement(feeItem: any, linkTo: any) {
    this.linkElement.emit({ feeItem, linkTo })
  }

  editField($event: MouseEvent, entity: any, key: string, value: any, required = false) {
    this.preventDefault($event)
    this.editableEntity = entity
    this.editableIsEdit[key] = true
    if (this.editableControls[key])
      this.editableControls[key].setValue(value)
    else
      this.editableControls[key] = new FormControl(value, required ? [Validators.required] : [])
  }

  submitField(key: string, value: any, initialValue: any, propertyName = key) {
    if (value != initialValue)
      this.onSaveElement.emit({ ...this.editableEntity, [propertyName]: value })
    this.editableEntity = null
    this.editableIsEdit[key] = false
  }

  preventDefault(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
  }

  drop(event: CdkDragDrop<any[]>) {
    if (this.enableDragging && event.currentIndex != event.previousIndex &&
      this.checkDropLocation(this.dataSource.data, event.previousIndex, event.currentIndex)) {
      moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
      this.dataSource.data = this.dataSource.data.slice();
      this.orderChange.emit(this.dataSource.data);
      this.cdr.detectChanges();
    }
  }

  protected readonly Object = Object;
  protected readonly TableColumnKey = TableColumnKey;
  protected readonly ScopeUiTableSelectMode = ScopeUiTableSelectMode;
}

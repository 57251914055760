import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SharedModule } from '@app/shared/shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import {
  ScopeUiDropdownComponent,
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { FormulaBuilderComponent } from '@shared/components/formula-builder/formula-builder.component';
import {
  ComponentModifier,
  ScenarioModifierType,
} from '@app/features/company-management/models/component-modifier.model';
import { select, Store } from '@ngrx/store';
import { CompanyManagementActions } from '@app/features/company-management/store/actions/company-management.actions';
import { ComponentEntryFilter } from '@core/model/component-entry-filter.model';
import {
  CompanyManagementSelectors,
} from '@app/features/company-management/store/selectors/company-management.selectors';
import { LibraryManagementEntry } from '@app/features/library-management/store/models/library-items';
import { Observable, take } from 'rxjs';
import { LibraryComplexity } from '@app/features/library-management/store/models/library-complexity.model';
import { LibraryTemplateType } from '@app/features/library-management/enums';
import { LanguageService } from '@core/service/language.service';
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { Discipline } from '@app/features/scope-overview/model/discipline.model';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ComplexitiesList, Complexity } from '@core/model/enums/complexity.enum';
import { untilDestroyed } from '@shared/utils/utils';
import { FormControl, FormGroup } from '@angular/forms';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { SnackbarService } from '@shared/utils/snackbar.service';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import {
  ComponentEntryComplexityRole
} from '@app/features/company-management/models/component-entry-complexity-role.model';
import { BootstrapActions } from '@core/store';
import {
  OverrideModifiersModalComponent
} from '@app/features/company-management/components/override-modifiers-modal/override-modifiers-modal.component';
import { ScopeUiAutocompleteComponent } from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component';

export interface AssignModifierModalConfig {
  modifier: ComponentModifier,
  isNewModifier: boolean,
  showBackButton: boolean,
  assignCallback: (assignComplexities: number[], unassignComplexities: number[], hasOverride: boolean) => void
}

@Component({
  selector: 'assign-modifier-modal',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatDatepickerModule,
    ScopeUiInputComponent,
    ScopeUiDropdownComponent,
    FormulaBuilderComponent,
    InfiniteScrollModule,
    ScopeUiAutocompleteComponent,
  ],
  templateUrl: './assign-modifier-modal.component.html',
  styleUrls: ['./assign-modifier-modal.component.scss'],
})
export class AssignModifierModalComponent {
  componentEntries$: Observable<LibraryManagementEntry[]>
  currentComplexities: number[]
  selectedComplexities: Set<number> = new Set()
  filter: ComponentEntryFilter = {
    fixedPricing: null,
    disciplineTypeIds: [],
    rateCardVersionIds: [],
    libraryItemName: ''
  }
  rateCards$: Observable<RatecardVersion[]>
  disciplines$: Observable<Discipline[]>
  updateLoading$: Observable<boolean>

  typeOptions = [
    { name: this.lang.get('component'), id: LibraryTemplateType.COMPONENT },
    { name: 'Fixed Fee', id: LibraryTemplateType.FIXED_COST },
  ]

  page: number = 0
  pageSize: number = 128
  lastPage: boolean = false
  totalCount: number
  hideModifierOverrideWarning: boolean = false

  selectOptions = [
    { name: 'All Sizes', id: 'all' },
    { name: 'All S', id: Complexity.S },
    { name: 'All M', id: Complexity.M },
    { name: 'All L', id: Complexity.L },
    { name: 'All XL', id: Complexity.XL },
  ]
  selectControl: FormControl = new FormControl()
  assignedComplexities: ComponentEntryComplexityRole[]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AssignModifierModalConfig,
    private store: Store,
    private lang: LanguageService,
    private manageService: CompanyManagementService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {
    let destroy$ = untilDestroyed()
    this.getComponents()
    this.componentEntries$ = this.store.select(CompanyManagementSelectors.selectSearchedComponentEntries)
    this.store.select(CompanyManagementSelectors.lastComponentEntriesFetched).pipe(destroy$())
      .subscribe((lastPage: boolean) => this.lastPage = lastPage)
    this.store.select(CompanyManagementSelectors.componentEntriesTotalCount).pipe(destroy$())
      .subscribe((totalCount: number) => this.totalCount = totalCount)
    this.store.select(CompanyManagementSelectors.selectHideModifierOverrideWarning).pipe(destroy$())
      .subscribe((hideModifierOverrideWarning: boolean) => this.hideModifierOverrideWarning = hideModifierOverrideWarning)

    this.rateCards$ = this.store.pipe(select(CompanyManagementSelectors.selectRateCards))
    this.disciplines$ = this.store.pipe(select(CompanyManagementSelectors.selectDisciplines))
    this.updateLoading$ = this.store.select(CompanyManagementSelectors.selectLoadingUpdateModifiers)

    this.store.dispatch(CompanyManagementActions.getRateCards())
    this.store.dispatch(CompanyManagementActions.getDisciplines())

    this.store.select(CompanyManagementSelectors.selectModifiers).pipe(destroy$())
      .subscribe((modifiers) => {
        this.assignedComplexities = modifiers
          .filter((m) =>  m.id !== data.modifier.id && m.type === data.modifier.type)
          .flatMap((m) => m.complexityRoles.map((c) => {
            return { ...c, componentModifierName: m.name, formula: m.formula }
          }))
      })

    this.currentComplexities = data.modifier.complexityRoles.map((c) => c.id)
    this.selectedComplexities = new Set(this.currentComplexities)
  }

  getComponents(page: number = 0) {
    this.page = page
    this.store.dispatch(CompanyManagementActions.searchComponentEntries(
      { filter: { ...this.filter }, pageInfo: { page: this.page, pageSize: this.pageSize } }
    ))
  }

  onScroll() {
    if (!this.lastPage) this.getComponents(++this.page)
  }

  checkEntry(entry: LibraryManagementEntry) {
    this.selectControl.setValue('')
    if (!this.entryChecked(entry)) {
      Object.values(entry.componentComplexities).forEach((c) => this.addComplexity(c.id))
    } else {
      Object.values(entry.componentComplexities).forEach((c) => this.removeComplexity(c.id))
    }
  }

  entryChecked(entry: LibraryManagementEntry) {
    return Object.values(entry.componentComplexities).every((c) => this.complexityChecked(c))
  }

  entryIndeterminate(entry: LibraryManagementEntry) {
    return Object.values(entry.componentComplexities).some((c) => this.complexityChecked(c)) &&
      !this.entryChecked(entry)
  }

  addComplexity = (complexityId: number) => {
    if (!this.hideModifierOverrideWarning && this.complexityAssigned(complexityId)) {
      let dialog = this.dialog.open(ScopeUiModalComponent, {
        data: new ModalConfig(
          'By selecting this modifier you confirm that the modifier would override with the new modifier.',
          undefined,
          'Confirm',
          undefined,
          (form: FormGroup) => {
            if (form.get('showAgain').value) {
              this.store.dispatch(BootstrapActions.updateModifierOverrideWarningPreference({ preferences: {'HideModifierOverrideWarning': true} }))
            }
            dialog.close()
            this.selectedComplexities.add(complexityId)
          },
          () => dialog.close(),
          [{ name: 'showAgain', control: new FormControl(false), type: 'checkbox',
            label: `Don't show this message again` }],
          false,
          true,
          true
        ),
      })
    } else {
      this.selectedComplexities.add(complexityId)
    }
  }

  removeComplexity = (complexityId: number) => {
    this.selectedComplexities.delete(complexityId)
  }

  checkComplexity(complexity: LibraryComplexity) {
    this.selectControl.setValue('')
    if (!this.complexityChecked(complexity)) {
      this.addComplexity(complexity.id)
    } else {
      this.removeComplexity(complexity.id)
    }
  }

  complexityChecked(complexity?: LibraryComplexity) {
    if (!complexity) return false
    return this.selectedComplexities.has(complexity.id)
  }

  assign() {
    let assignComplexities = [...this.selectedComplexities]
      .filter((c) => !this.currentComplexities.includes(c))
    let overrideComplexities = this.assignedComplexities
      .filter((c) => assignComplexities.includes(c.id))
    if (overrideComplexities.length) {
      let dialog = this.dialog.open(OverrideModifiersModalComponent, {
        data: {
          overrideComplexities: overrideComplexities,
          callback: (excludeComplexities: number[]) => {
            dialog.close()
            assignComplexities = assignComplexities.filter((c) => !excludeComplexities.includes(c))
            let unassignComplexities = this.currentComplexities
              .filter((c) => !this.selectedComplexities.has(c))
            this.data.assignCallback(assignComplexities, unassignComplexities, overrideComplexities.length > excludeComplexities.length)
          }
        }
      })
    } else {
      let unassignComplexities = this.currentComplexities
        .filter((c) => !this.selectedComplexities.has(c))
      this.data.assignCallback(assignComplexities, unassignComplexities, false)
    }
  }

  submit() {
    if (!this.selectedComplexities.size && this.currentComplexities.length) {
      let dialog = this.dialog.open(ScopeUiModalComponent, {
        data: new ModalConfig(
          `Unassign "` + this.data.modifier.name + `" from all ${this.lang.get('component.p|l')}`,
          `This modifier will not be applied to any ${this.lang.get('component.p|l')}`,
          'Confirm',
          undefined,
          () => {
            dialog.close()
            this.assign()
          },
          () => dialog.close(),
          [],
          false,
          true,
          true
        ),
      })
    } else {
        this.assign()
    }
  }
  
  setDisciplineFilter({ event }) {
    this.filter.disciplineTypeIds = event?.length ? event.map((d) => d.id) : []
    this.getComponents()
  }

  setRateCardFilter({ event }) {
    this.filter.rateCardVersionIds = event?.length ? event.map((d) => d.id) : []
    this.getComponents()
  }

  setFixedPricingFilter($event: any) {
    if (!$event) this.filter.fixedPricing = null
    else if ($event.id === LibraryTemplateType.COMPONENT) this.filter.fixedPricing = false
    else if ($event.id === LibraryTemplateType.FIXED_COST) this.filter.fixedPricing = true
    this.getComponents()
  }

  onSearchKeyPressed(s: string) {
    if (this.filter.libraryItemName == s) return
    this.filter.libraryItemName = s
    this.getComponents()
  }

  clear() {
    this.selectedComplexities = new Set()
    this.selectControl.setValue('')
  }

  selectInBulk(event: Complexity | 'all') {
    if (this.lastPage) {
      this.componentEntries$.pipe(take(1)).subscribe((componentEntries) => {
        componentEntries.forEach((e) => {
          if (event === 'all')
            Object.values(e.componentComplexities).forEach((c) => this.selectedComplexities.add(c.id))
          else if (e.componentComplexities[event]) this.selectedComplexities.add(e.componentComplexities[event].id)
        })
      })
    } else {
      let filter = (event === 'all') ? this.filter : { ...this.filter, complexities: [event] }
      this.manageService.getComplexityRolesForBulkSelect(filter).subscribe({
        next: (complexityIds: number[]) => complexityIds.forEach((id) => this.selectedComplexities.add(id)),
        error: () => this.snackbarService.showDefaultErrorSnackbar()
      })
    }
  }

  get selectedCount() {
    return this.selectedComplexities.size
  }

  complexityAssigned(id: number) {
    let name = this.assignedComplexities.find((c) => c.id === id)?.componentModifierName
    return name ? `${this.data.modifier.type == ScenarioModifierType.QUANTITY_MODIFIER ? 'Quantity' : 'Price'} modifier ${name} already assigned` : null
  }

  protected readonly ScenarioModifierType = ScenarioModifierType;
  protected readonly Object = Object;
  protected readonly ComplexitiesList = ComplexitiesList;
}

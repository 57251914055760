import { Component, Inject, OnDestroy } from '@angular/core'
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms'
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree'
import {
  LibraryFormError,
  LibraryManagementFoldersActions,
  LibraryManagementFoldersSelectors,
} from '@app/features/library-management/store'

import { FlatTreeControl } from '@angular/cdk/tree'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LibraryMoveItemModalConfig } from '@app/features/library-management/models'
import { LibraryManagementFolder } from '@app/features/library-management/store/models/library-folders'
import { Store } from '@ngrx/store'

export interface LibraryFlatNode extends LibraryManagementFolder {
  expandable: boolean
  level: number
  children: LibraryManagementFolder[]
  parent: number
}

@Component({
  selector: 'library-move-item-modal.component',
  templateUrl: 'library-move-item-modal.component.html',
  styleUrls: ['library-move-item-modal.component.scss'],
})
export class LibraryMoveItemModalComponent implements OnDestroy {
  folders$ = this.store$.select(LibraryManagementFoldersSelectors.selectAllFoldersTree)
  isLoading$ = this.store$.select(LibraryManagementFoldersSelectors.selectIsLoadingAllFolders)

  LibraryFormErrorEnum = LibraryFormError
  addUnderFolderId: number
  modalForm: UntypedFormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
  })

  get nameControl() {
    return this.modalForm.get('name') as FormControl
  }

  treeControl = new FlatTreeControl<LibraryFlatNode>(
    ({ level }) => level,
    ({ expandable }) => expandable
  )
  dataSource = new MatTreeFlatDataSource(
    this.treeControl,
    new MatTreeFlattener(
      (node: any, level: number) => ({
        ...node,
        expandable: node?.children?.length > 0,
        level,
      }),
      ({ level }) => level,
      ({ expandable }) => expandable,
      ({ children }) => children
    )
  )

  hasChild = (_: number, node: LibraryFlatNode) => node.expandable

  constructor(@Inject(MAT_DIALOG_DATA) public data: LibraryMoveItemModalConfig, private store$: Store) {
    this.store$.dispatch(LibraryManagementFoldersActions.getAllFoldersTrigger())

    this.folders$.subscribe((folders) => {
      this.dataSource.data = folders
      const parentNode = this.treeControl.dataNodes.find((node) => node.id === -1)
      if (parentNode) this.treeControl.expand(parentNode)
    })
  }

  onMoveClick(event: Event, node: LibraryFlatNode) {
    event.preventDefault()
    event.stopPropagation()
    this.data.onMoveItem(node.id === -1 ? undefined : node.id)
  }

  onCreateAndMoveClick(event: Event, node: LibraryFlatNode) {
    event.preventDefault()
    event.stopPropagation()
    if (this.modalForm.valid) this.data.onCreateAndMoveItem(node.parent, this.nameControl.value)
  }

  onAddFolderClick(node: LibraryFlatNode) {
    this.addUnderFolderId = node.parent
    this.modalForm.reset()
  }

  ngOnDestroy() {
    this.store$.dispatch(LibraryManagementFoldersActions.closeMoveFolderModal())
  }
}

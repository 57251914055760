<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>Create {{ 'third_party_cost' | lang }}</h1>
    </div>

    <button
      class="close-button"
      mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content
  aria-label="Generic modal"
  class="modal-container md-dialog-container library">
  <div class="px-10 pb-2 w-full library-modal-content">
    <div class="flex-row">
      <scope-ui-input
        inputClass="scope-input--full-sized"
        inputLabel="{{ 'third_party_cost' | lang }} Name*"
        [maximumLength]="255"
        [control]="getControl('name')" />
    </div>

    <div class="flex-row">
      <description-editor
        class="px-10"
        status="DRAFT"
        [hideNote]="true"
        [descriptionRequired]="true"
        [descriptionDirty]="getControl('description').touched"
        [model]="{ description: getControlValue('description') }"
        (onSaveDescription)="setControlValue('description', $event)" />
    </div>

    <div class="flex-row">
      <scope-ui-autocomplete
        label="Group"
        [control]="getControl('group')"
        [options]="groups$ | async" />
    </div>

    <div class="flex-row pt-8">
      <scope-ui-input
        inputClass="scope-input--full-sized"
        inputLabel="Unit Cost*"
        type="number"
        [control]="getControl('unitCost')"
        (onInputChange)="onInputChange(getControlValue('markup'), $event)" />
    </div>

    <div class="flex-row pt-8">
      <scope-ui-autocomplete
        label="Formula"
        [control]="getControl('formula')"
        [options]="formulas$ | async" />
    </div>

    <div class="flex-row pt-8">
      <scope-ui-input
        inputClass="scope-input--full-sized"
        inputLabel="Mark Up (%)"
        type="number"
        [control]="getControl('markup')"
        (onInputChange)="onInputChange($event, getControlValue('unitCost'))" />
    </div>

    <div class="flex-row pt-8">
      <scope-ui-input
        inputClass="scope-input--full-sized"
        inputLabel="Selling Price*"
        type="number"
        [control]="getControl('sellingPrice')"
        (onInputChange)="onSellingPriceChange($event)" />
    </div>
  </div>

  <div class="d-flex d-flex-justify-end px-10 pt-8 pb-4">
    <div class="library-action-buttons">
      <button
        mat-button
        mat-dialog-close>
        Cancel
      </button>
      <button
        class="main-action-button ml-4"
        [class.disabled]="!modalForm.valid"
        mat-button
        (click)="submit()">
        Create
      </button>
    </div>
  </div>
</mat-dialog-content>

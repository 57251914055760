import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  LibraryDeliverableEntryDetailsService,
} from '@app/features/library-management/services/library-deliverable-entry-details.service';
import {
  LibraryDeliverableEntryDetailsActions,
} from '@app/features/library-management/store/models/deliverable/library-deliverable-entry-details.action.interface';

@Injectable()
export class LibraryDeliverableEntryDetailsEffects {
  constructor(
    private actions$: Actions,
    private service: LibraryDeliverableEntryDetailsService,
  ) {
  }

  loadDeliverableEntryDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryDeliverableEntryDetailsActions.loadDeliverableEntryDetails),
      mergeMap(({ page, pageSize, filter }) =>
        this.service.getAllDeliverableEntryDetails(page, pageSize, filter).pipe(
          map((response) =>
            LibraryDeliverableEntryDetailsActions.loadDeliverableEntryDetailsSuccess({
              data: response.content,
              total: response.totalElements,
              last: response.last,
              number: response.number
            }),
          ),
          catchError((error) =>
            of(LibraryDeliverableEntryDetailsActions.loadDeliverableEntryDetailsFailure({ error })),
          ),
        ),
      ),
    ),
  );

  loadDeliverableEntryDetailsByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryDeliverableEntryDetailsActions.loadDeliverableEntryDetailsByIds),
      mergeMap(({ ids }) =>
        this.service.getDeliverableEntryDetailsByIds(ids).pipe(
          map((data) =>
            LibraryDeliverableEntryDetailsActions.loadDeliverableEntryDetailsByIdsSuccess({ data }),
          ),
          catchError((error) =>
            of(LibraryDeliverableEntryDetailsActions.loadDeliverableEntryDetailsByIdsFailure({ error })),
          ),
        ),
      ),
    ),
  );
}

import { Observable, map } from 'rxjs'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'
import { ThirdPartyCostFormula } from '@app/core/model/third-party-cost-formula.model'
import { ThirdPartyCostGroup } from '../store/models/third-party-cost'
import { environment } from '@envs/environment'
import { plainToInstance } from 'class-transformer'

@Injectable({ providedIn: 'root' })
export class LibraryThirdPartyCostService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl

  constructor(private http: HttpClient) {}

  getThirdPartyCost(id: number): Observable<ThirdPartyCost> {
    return this.http
      .get<ThirdPartyCost>(`${this.BASE_API_URL}library/third-party-cost/${id}`)
      .pipe(map((api) => plainToInstance(ThirdPartyCost, api)))
  }

  getThirdPartyCostGroups(): Observable<ThirdPartyCostGroup[]> {
    return this.http
      .get<ThirdPartyCostGroup[]>(`${this.BASE_API_URL}predict/third-party-cost-group?includeTypes=false`)
      .pipe(map((api) => plainToInstance(ThirdPartyCostGroup, api)))
  }

  getThirdPartyCostFormulaDefinition(): Observable<ThirdPartyCostFormula[]> {
    return this.http
      .get<ThirdPartyCostFormula[]>(`${this.BASE_API_URL}third-party-cost-formula-definition`)
      .pipe(map((api) => plainToInstance(ThirdPartyCostFormula, api)))
  }

  createThirdPartyCost(tpc): Observable<any> {
    return this.http.post<any>(`${this.BASE_API_URL}library/third-party-cost`, tpc)
  }

  deleteThirdPartyCost(id: number): Observable<any> {
    return this.http.delete<any>(`${this.BASE_API_URL}library/item/${id}`)
  }

  updateThirdPartyCost(id: number, tpc: ThirdPartyCost): Observable<ThirdPartyCost> {
    return this.http
      .put<any>(`${this.BASE_API_URL}library/third-party-cost/${id}`, tpc)
      .pipe(map((api) => plainToInstance(ThirdPartyCost, api)))
  }

  duplicateThirdPartyCost(id: number, newName: string): Observable<ThirdPartyCost> {
    return this.http
      .post<any>(`${this.BASE_API_URL}library/third-party-cost/${id}/duplicate`, { newName })
      .pipe(map((api) => plainToInstance(ThirdPartyCost, api)))
  }
}

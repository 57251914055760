import { FormatHoursPipe } from '@app/shared/pipe/formatHours.pipe'
import { LibraryDeliverableEntry } from './library-deliverable-entry.model'
import { Preference } from '@app/core/model/user-preferences.interface'
import { Privilege } from '@app/core/model/enums/privilege.enum'
import { TableColumnKey } from '@app/shared/components/ui-components/scope-ui-table/table-column-key.enum'
import { libraryDeletedDisciplineDisplayName } from '@app/features/library-management/store/const/library-deleted-discipline-display-name.const'

export const libraryDeliverableEntriesColumnsConfig = [
  {
    key: TableColumnKey.DISCIPLINE,
    name: '{LANG:discipline|u}',
    field: 'disciplineTypeName',
    selected: true,
    value: (entry: LibraryDeliverableEntry) =>
      entry?.disciplineTypeName?.length ? entry?.disciplineTypeName : libraryDeletedDisciplineDisplayName,
  },
  { key: TableColumnKey.RATECARD, name: 'Ratecard', field: 'rateCardVersion.name', selected: true },
  {
    key: TableColumnKey.RATECARD_VERSION,
    name: 'Ratecard Version',
    field: 'rateCardVersion.version',
    selected: true,
  },
  { key: TableColumnKey.CREATED_BY, name: 'Created By', field: 'createdBy.company.name', selected: true },
  { key: TableColumnKey.DATE_CREATED, name: 'Date created', field: 'createdTs', selected: true },
  { key: TableColumnKey.LAST_EDITED, name: 'Last edited', field: 'updatedTs', selected: true },
  {
    key: TableColumnKey.VALUE,
    name: 'Value',
    field: 'value',
    selected: true,
    requiredPrivilege: Privilege.LIBRARY__VIEW_RATES,
  },
]

export const libraryDeliverableEntryComponentsColumnsConfig: Preference[] = [
  { key: TableColumnKey.NAME, name: '{LANG:component|u}', field: 'source.name', selected: true },
  { key: TableColumnKey.QUANTITY, name: 'Quantity', field: 'quantity', selected: true },
  { key: TableColumnKey.SIZE, name: 'Size', field: 'complexity', selected: true },
  {
    key: TableColumnKey.AGENCY_HOURS,
    name: '{LANG:agency} Hours',
    selected: true,
    value: (component) => new FormatHoursPipe().transform(component.agencyHours),
  },
  { key: TableColumnKey.AGENCY_PRICE, name: '{LANG:agency} Price', field: 'agencyPrice', selected: true },
]

export const libraryDeliverableEntryTpcsColumnsConfig: Preference[] = [
  { key: TableColumnKey.NAME, name: '{LANG:third_party_cost|u}', field: 'type.name', selected: true },
  { key: TableColumnKey.TPC_AMOUNT, name: 'Amount', field: 'quantity', selected: true },
  { key: TableColumnKey.UNIT, name: 'Unit', field: 'formula.subjectName', selected: true },
  { key: TableColumnKey.QUANTITY, name: 'Quantity', field: 'formula.unitTypeQuantity', selected: true },
  { key: TableColumnKey.UNIT_TYPE, name: 'Unit Type', field: 'formula.unitType.name', selected: true },
  { key: TableColumnKey.OVERTIME_RATE, name: 'Overtime Rate', field: 'overtimeRate', selected: true },
  { key: TableColumnKey.UNIT_COST, name: 'Unit Cost', field: 'cost', selected: true },
  { key: TableColumnKey.MARK_UP, name: 'Markup', field: 'markupPercentageDecimal', selected: true },
  { key: TableColumnKey.AGENCY_PRICE, name: '{LANG:agency} Price', field: 'agencyPrice', selected: true },
]

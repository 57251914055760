import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@envs/environment'
import { Observable } from 'rxjs'
import { LibraryManagementFolderGroupSettingsModel } from '../store/models/library-folders'

@Injectable({ providedIn: 'root' })
export class LibraryManagementFoldersService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl

  constructor(private http: HttpClient) {}

  getAllFolders(): Observable<any> {
    return this.http.get<any>(`${this.BASE_API_URL}library/folder`)
  }

  getFolders(params?: any): Observable<any> {
    return this.http.get<any>(`${this.BASE_API_URL}library/folders`, { params })
  }

  getFolder(id: number): Observable<any> {
    return this.http.get<any>(`${this.BASE_API_URL}library/folder/${id}`)
  }

  updateFolder(id: number, payload: any): Observable<any> {
    return this.http.put<any>(`${this.BASE_API_URL}library/folder/${id}`, payload)
  }

  archiveFolder(id: number) {
    return this.http.delete<any>(`${this.BASE_API_URL}library/folder/${id}/archive`)
  }

  createFolder(folder: any) {
    return this.http.post<any>(`${this.BASE_API_URL}library/folder`, folder)
  }

  getFoldersByFolderId(id: number, params?: any) {
    return this.http.get<any>(`${this.BASE_API_URL}library/folder/${id}/folders`, { params })
  }

  getItemsByFolderId(id: number, params?: any) {
    return this.http.get<any>(`${this.BASE_API_URL}library/folder/${id}/items`, { params })
  }

  getFolderGroupSettings(id: number) {
    return this.http.get<any>(`${this.BASE_API_URL}library/folder/${id}/groups`)
  }

  setFolderGroupSettings(config: LibraryManagementFolderGroupSettingsModel[]) {
    return this.http.post<any>(`${this.BASE_API_URL}library/folder/share/groups`, config)
  }
}

export var OutputVariables = {


  /**
   * SCOPE
   */
  "scope.id": {
    name: "Scope Id",
    description: "The id of the scope",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "scope.name": {
    name: "Scope Name",
    description: "The name of the scope",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "scope.description": {
    name: "Scope Description",
    description: "The description of the scope",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "scope.version": {
    name: "Scope Version",
    description: "The current version number of the scope",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "scope.createdby.name": {
    name: "Scope Author",
    description: "The name of the user that created this scope",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "scope.createddate": {
    name: "Scope Created Date",
    description: "The date that this scope was created",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "scope.startdate": {
    name: "Scope Start Date",
    description: "The start date of the scope if it has been defined",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "scope.enddate": {
    name: "Scope End Date",
    description: "The end date of the scope if it has been defined",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "scope.total.resources.sellingprice": {
    name: "Scope Total Resource Selling Price",
    description: "The total resource selling price of the scope excluding discounts and third party costs",
    supportsEntity: ['SCOPE','DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope.total.thirdpartycosts.sellingprice": {
    name: "Scope Total Third Party Cost Selling Price",
    description: "The total third party cost selling price of the scope excluding discounts and resources",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope.total.discount.name": {
    name: "Scope Total Discount Name",
    description: "The discount name ",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "scope.total.discount.percentage": {
    name: "Scope Total Discount Percentage",
    description: "The discount percentage ",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      defaultText: "0%"
    }
  },
  "scope.total.discount.value": {
    name: "Scope Total Discount Value",
    description: "The discount value ",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope.total.msa.value": {
    name: "Scope MSA Value",
    description: "The MSA value ",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope.total.msa.name": {
    name: "Scope MSA Name",
    description: "The MSA name ",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope.total.sellingprice": {
    name: "Scope Total Selling Price",
    description: "The total selling price including third party costs, resources, fixed costs, and discounts.",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none",
      filters: [
        {id: "includeTPC", default: true},
        {id: "includeDiscounts", default: true},
        {id: "includeComponentFee", default: true},
        {id: "includeDeliverableFee", default: true},
        {id: "includeScopeFee", default: true},
        {id: "includeStageFee", default: true},
        {id: "includeOvertime", default: true}
      ]
    }
  },
  "scope.fte-value": {
    name: "Scope FTE Percentage",
    description: "Current scope FTE percentage usage calculated according to initial FTE hours",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      defaultText: "0%"
    }
  },
  "scope.mark.total.selling.price": {
    name: "Scope Mark Total Selling Price",
    description: "The scope mark total selling price excluded third party costs and discounts, but included fixed costs.",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope.budget": {
    name: "Scope Budget",
    description: "The budget of the scope.",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope.deliverable-total-count": {
    name: "Total deliverables count",
    description: "Scope total deliverables count",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      countResults: true,
      uniqueResults: false
    }
  },
  "scope.component-total-count": {
    name: "Total components count",
    description: "Scope total components count",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      countResults: true,
      uniqueResults: false
    }
  },
  "scope.department-total-count": {
    name: "Total departments count",
    description: "Scope total departments count",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      countResults: true,
      uniqueResults: true
    }
  },
  "scope.role-total-count": {
    name: "Total roles count",
    description: "Scope total roles count",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      countResults: true,
      uniqueResults: true
    }
  },
  "scope.approvers": {
    name: "Scope Approvers Names",
    description: "The names of the scope approvers if present, otherwise reverts empty string",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "scope.duration": {
    name: "Scope Duration",
    description: "The duration of the scope start date and end date, otherwise empty string",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "scope.resource.hours": {
    name: "Scope Total Hours",
    description: "The scope resource hours, otherwise empty string",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "scope.type": {
    name: "Scope Type",
    description: "The scope ScopeType, otherwise empty string",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "scope.fixed.costs": {
    name: "Scope Fixed Fee",
    description: "The scope fixed fee names and costs, otherwise empty string",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "scope.fixed.costs.total": {
    name: "Scope Fixed Fee Total",
    description: "The scope fixed fee total, otherwise zero",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope.deliverable.total.value": {
    name: "Scope Deliverable Total Value",
    description: "The Deliverable Total Value, otherwise zero",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope.tpc.total.value": {
    name: "Scope TPC Total Value",
    description: "The TPC Total Value, otherwise zero",
    supportsEntity: ['SCOPE',"TPC-GROUP"],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },

  "scope.deliverable.total.quantity": {
    name: "Scope Deliverable Total Quantity",
    description: "The Deliverable Total Quantity, otherwise zero",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "scope.component.fixed-costs.total": {
    name: "Scope Component Fixed Costs Total",
    description: "The scope component fixed costs total, otherwise zero",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope.departments.cost.total": {
    name: "Scope departments Cost Total",
    description: "The scope departments cost total, otherwise zero",
    supportsEntity: ['SCOPE','DEPARTMENT','SCOPE-SECTION-DEPARTMENT'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope.deliverable.total.traded-to.pricevariance": {
    name: "Scope Total Traded Price Variance",
    description: "The total price variance of traded deliverables, otherwise zero",
    supportsEntity: ['SCOPE', 'DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope.deliverable.total.traded-to.hoursvariance": {
    name: "Scope Total Traded Hours Variance",
    description: "The total hours variance of traded deliverables, otherwise zero",
    supportsEntity: ['SCOPE', 'DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },

  /**
   * Folder of Work
   */
  "sow.id": {
    name: "Folder of Work Id",
    description: "The id of the sow",
    supportsEntity: ['SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "sow.name": {
    name: "Folder of Work Name",
    description: "The name of the sow",
    supportsEntity: ['SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "sow.description": {
    name: "Folder of Work Description",
    description: "The description of the sow",
    supportsEntity: ['SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "sow.budget": {
    name: "Folder of Work Budget",
    description: "The budget of the sow.",
    supportsEntity: ['SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "sow.version": {
    name: "Folder of Work Version",
    description: "The current version number of the sow",
    supportsEntity: ['SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "sow.createdby.name": {
    name: "Folder of Work Author",
    description: "The name of the user that created this sow",
    supportsEntity: ['SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "sow.createddate": {
    name: "Folder of Work Created Date",
    description: "The date that this sow was created",
    supportsEntity: ['SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "sow.startdate": {
    name: "Folder of Work Start Date",
    description: "The start date of the sow if it has been defined",
    supportsEntity: ['SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "sow.enddate": {
    name: "Folder of Work End Date",
    description: "The end date of the sow if it has been defined",
    supportsEntity: ['SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "sow.total.sellingprice": {
    name: "Folder of Work Total Selling Price",
    description: "The total selling price of the sow excluding discounts and third party costs",
    supportsEntity: ['SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "sow.total.resource.sellingprice": {
    name: "Folder of Work Resource Total Selling Price",
    description: "The total resource selling price of the sow",
    supportsEntity: ['SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "sow.total.tpc.sellingprice": {
    name: "Folder of Work TPC Total Selling Price",
    description: "The total selling price of the sow third party costs",
    supportsEntity: ['SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "sow.total.discount": {
    name: "Folder of Work Discount Total Value",
    description: "The total discount value of the sow",
    supportsEntity: ['SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },

  "ratecard.name": {
    name: "Ratecard Name",
    description: "The name of the ratecard",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "secondparty.name": {
    name: "Client Name",
    description: "The name of client",
    supportsEntity: ['SCOPE','SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "secondparty.brand.name": {
    name: "Brand Name",
    description: "The name of the client's brand if present, otherwise reverts to client's name",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "secondparty.location": {
    name: "Client Location Name",
    description: "The name of the client's location if present, otherwise reverts empty string",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "secondparty.location.address.address1": {
    name: "Client Location Address 1",
    description: "The address 1 of the client's location if present, otherwise reverts empty string",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "secondparty.location.address.address2": {
    name: "Client Location Address 2",
    description: "The address 2 of the client's location if present, otherwise reverts empty string",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "secondparty.location.address.city": {
    name: "Client Location City",
    description: "The city of the client's location if present, otherwise reverts empty string",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "secondparty.location.address.country": {
    name: "Client Location Country",
    description: "The city of the client's location if present, otherwise reverts empty string",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "secondparty.location.address.phone": {
    name: "Client Location Phone",
    description: "The phone number of the client's location if present, otherwise reverts empty string",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "secondparty.location.address.postcode": {
    name: "Client Location Postcode",
    description: "The postcode number of the client's location if present, otherwise reverts empty string",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "secondparty.contact.name": {
    name: "Client Contact",
    description: "The name of the client's contact if present, otherwise reverts empty string",
    supportsEntity: ['SCOPE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "secondparty.contact.role": {
    name: "Client Contact Role",
    description: "The role of the client's contact if present, otherwise reverts empty string",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "secondparty.contact.phone": {
    name: "Client Contact Phone",
    description: "The phone of the client's contact if present, otherwise reverts empty string",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "secondparty.contact.email": {
    name: "Client Contact Email",
    description: "The email of the client's contact if present, otherwise reverts empty string",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "creator.email": {
    name: "Creator Email",
    description: "The email of the sow creator",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },


  "company.name": {
    name: "Company Name",
    description: "The name of your company",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },

  "document.generation.date": {
    name: "Document Generation Date",
    description: "The date the document is generated",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "document.update.date": {
    name: "Document Update Date",
    description: "The date the document is updated",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "today.date": {
    name: "Today's Date",
    description: "Today's Date",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "document.pagenumber": {
    name: "Page No.",
    description: "The current page number of this document. This variable is only available for the header and footer.",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['HEADER', 'FOOTER']
  },
  "document.totalpages":  {
    name: "Total Pages",
    description: "The count of pages. This variable is only available for the header and footer.",
    supportsEntity: ['SCOPE', 'SOW'],
    supportsBlock: ['HEADER', 'FOOTER']
  },

  /**
   * WIDGETS
   * */
  "deliverable-section-object.section.name": {
    name: "Scope Section (Stage) Name",
    description: "Scope Section (Stage) Name",
    supportsEntity: ['DELIVERABLE-STAGE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-section-object.section.start.date": {
    name: "Scope Section (Stage) Start Date",
    description: "Scope Section (Stage) Start Date",
    supportsEntity: ['DELIVERABLE-STAGE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "deliverable-section-object.section.end.date": {
    name: "Scope Section (Stage) End Date",
    description: "Scope Section (Stage) End Date",
    supportsEntity: ['DELIVERABLE-STAGE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "deliverable-section-object.section.hours": {
    name: "Scope Section (Stage) Hours",
    description: "Scope Section (Stage) Hours",
    supportsEntity: ['DELIVERABLE-STAGE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-section-object.section.total.price": {
    name: "Scope Section (Stage) Total",
    description: "Scope Section (Stage) Total",
    supportsEntity: ['DELIVERABLE-STAGE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-section-object.previewdata.placeholder": createPreviewdata("DELIVERABLE-STAGE"),
  "deliverable-deliverable-section-object.section.name": {
    name: "Deliverable Section Name",
    description: "Deliverable Section Name",
    supportsEntity: ['DELIVERABLE-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-deliverable-section-object.section.start.date": {
    name: "Deliverable Section Start Date",
    description: "Deliverable Section Start Date",
    supportsEntity: ['DELIVERABLE-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "deliverable-deliverable-section-object.section.end.date": {
    name: "Deliverable Section End Date",
    description: "Deliverable Section End Date",
    supportsEntity: ['DELIVERABLE-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "deliverable-deliverable-section-object.section.hours": {
    name: "Deliverable Section Hours",
    description: "Deliverable Section Hours",
    supportsEntity: ['DELIVERABLE-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-deliverable-section-object.section.total.price": {
    name: "Deliverable Section Total",
    description: "Deliverable Section Total",
    supportsEntity: ['DELIVERABLE-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-deliverable-section-object.previewdata.placeholder": createPreviewdata("DELIVERABLE-SECTION"),
  "component-fixed-fee-object.name": {
    name: "Fixed Fee Name",
    description: "Fixed Fee Name",
    supportsEntity: ['COMPONENT-FIXED-FEE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "component-fixed-fee-object.description": {
    name: "Fixed Fee Description",
    description: "Fixed Fee Description",
    supportsEntity: ['COMPONENT-FIXED-FEE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "component-fixed-fee-object.quantity": {
    name: "Fixed Fee Quantity",
    description: "Fixed Fee Quantity",
    supportsEntity: ['COMPONENT-FIXED-FEE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "component-fixed-fee-object.value": {
    name: "Fixed Fee Total Value",
    description: "Fixed Fee Total Value By Quantity",
    supportsEntity: ['COMPONENT-FIXED-FEE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none",
      filters: [{id: "includeTPC", default: true}]
    }
  },
  "component-fixed-fee-object.item-value": {
    name: "Fixed Fee Value By Item",
    description: "Fixed Fee Value By Item",
    supportsEntity: ['COMPONENT-FIXED-FEE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none",
      filters: [{id: "includeTPC", default: true}]
    }
  },
  "component-fixed-fee-object.tpc.total.value": {
    name: "Fixed Fee TPC Total Value",
    description: "The TPC Total Value, otherwise zero",
    supportsEntity: ['COMPONENT-FIXED-FEE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "component-fixed-fee-object.roles": {
    name: "Fixed Fee Roles",
    description: "Fixed Fee Role names, comma separated",
    supportsEntity: ['COMPONENT-FIXED-FEE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "component-fixed-fee-object.tpcs": {
    name: "Fixed Fee TPCs",
    description: "Fixed Fee Third Party Cost names, comma separated",
    supportsEntity: ['COMPONENT-FIXED-FEE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "tpc-group-content-object.name": {
    name: "TPC Group Name",
    description: "TPC Group Name",
    supportsEntity: ['TPC-GROUP'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "tpc-group-content-object.description": {
    name: "TPC Group Description",
    description: "TPC Group Description",
    supportsEntity: ['TPC-GROUP'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },



  "tpc-content-object.name": {
    name: "TPC Name",
    description: "TPC Name",
    supportsEntity: ['TPC', 'SCOPE-SECTION-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "tpc-content-object.description": {
    name: "TPC Description",
    description: "TPC Description",
    supportsEntity: ['TPC', 'SCOPE-SECTION-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "tpc-content-object.item-cost": {
    name: "TPC Item Cost",
    description: "TPC Item Cost",
    supportsEntity: ['TPC', 'SCOPE-SECTION-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "tpc-content-object.item-markup": {
    name: "TPC Item Markup",
    description: "TPC Item Markup",
    supportsEntity: ['TPC', 'SCOPE-SECTION-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "tpc-content-object.item-quantity": {
    name: "TPC Item Quantity",
    description: "TPC Item Quantity",
    supportsEntity: ['TPC', 'SCOPE-SECTION-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-content-object.deliverable.type.name": {
    name: "Deliverable Type Name",
    description: "Deliverable Type Name",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-content-object.deliverable.name": {
    name: "Deliverable Name",
    description: "Deliverable Name",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-content-object.deliverable.description": {
    name: "Deliverable Description",
    description: "Deliverable Description",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-content-object.deliverable.hours": {
    name: "Deliverable Hours",
    description: "Deliverable Hours",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "task-content-object.task.name": {
    name: "Task Name",
    description: "Task Name",
    supportsEntity: ['TASK'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "task-content-object.task.description": {
    name: "Task Description",
    description: "Task Description",
    supportsEntity: ['TASK'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "task-content-object.task.hours": {
    name: "Task Hours",
    description: "Task Hours",
    supportsEntity: ['TASK'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "task-content-object.startdate": {
    name: "Task Start Date",
    description: "The start date of the task if it has been defined",
    supportsEntity: ['TASK'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "task-content-object.enddate": {
    name: "Task End Date",
    description: "The end date of the task if it has been defined",
    supportsEntity: ['TASK'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "deliverable-content-object.deliverable.fixed-component.total.quantity": {
    name: "Fixed Component Total Quantity",
    description: "Component Total Quantity",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-content-object.deliverable.item-price": {
    name: "Deliverable Item Value",
    description: "Deliverable Item Value",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-content-object.startdate": {
    name: "Deliverable Start Date",
    description: "The start date of the deliverable if it has been defined",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "deliverable-content-object.enddate": {
    name: "Deliverable End Date",
    description: "The end date of the deliverable if it has been defined",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      dateFormat: "UK",
      dateValue: "dd/MM/yy"
    }
  },
  "deliverable-fixed-fee-object.name": {
    name: "Deliverable Fixed Fee Name",
    description: "Deliverable Fixed Fee Name",
    supportsEntity: ['DELIVERABLE-FIXED', 'SCOPE-SECTION-FIXED-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-fixed-fee-object.description": {
    name: "Deliverable Fixed Fee Description",
    description: "Deliverable Fixed Fee Description",
    supportsEntity: ['DELIVERABLE-FIXED', 'SCOPE-SECTION-FIXED-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-fixed-fee-object.quantity": {
    name: "Deliverable Fixed Fee Quantity",
    description: "Deliverable Fixed Fee Quantity",
    supportsEntity: ['DELIVERABLE-FIXED', 'SCOPE-SECTION-FIXED-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-fixed-fee-object.item-value": {
    name: "Deliverable Fixed Fee Item Value",
    description: "Deliverable Fixed Fee Value For One Item",
    supportsEntity: ['DELIVERABLE-FIXED', 'SCOPE-SECTION-FIXED-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-fixed-fee-object.value": {
    name: "Deliverable Fixed Fee Value By Quantity",
    description: "Deliverable Fixed Fee Value By Quantity",
    supportsEntity: ['DELIVERABLE-FIXED', 'SCOPE-SECTION-FIXED-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-content-object.deliverable.fee": {
    name: "Deliverable Fee",
    description: "Deliverable Fee",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-content-object.deliverable.quantity": {
    name: "Deliverable Quantity",
    description: "Deliverable Quantity",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "deliverable-content-object.deliverable.roles.total": {
    name: "Roles Total",
    description: "Sub Total of roles",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-content-object.deliverable.price": {
    name: "Deliverable Price",
    description: "Deliverable Price",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "task-content-object.task.price": {
    name: "Task Price",
    description: "Task Price",
    supportsEntity: ['TASK'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-content-object.deliverable.totalPriceWithoutTpc": {
    name: "Deliverable Fee without OOP",
    description: "Deliverable total price excluding OOP/TPC price",
    supportsEntity: ['DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-content-object.deliverable.totalPriceWithoutFeeItems": {
    name: "Deliverable Fee without Fee Items",
    description: "Deliverable total price excluding fee items (component fee items, etc.)",
    supportsEntity: ['DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-content-object.deliverable.tpcsellingprice": {
    name: "Deliverable TPC Total Selling price",
    description: "Total price of deliverable third party costs",
    supportsEntity: ['DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-content-object.previewdata.placeholder": createPreviewdata("DELIVERABLE"),
  "task-content-object.previewdata.placeholder": createPreviewdata("TASK"),
  "deliverable-content-object.deliverable.original.name": {
    name: "Original Deliverable Name",
    description: "Original name of a deliverable if traded, else the current name",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-content-object.deliverable.original.description": {
    name: "Original Deliverable Description",
    description: "Original description of a deliverable if traded, else the current description",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-content-object.deliverable.original.price": {
    name: "Original Deliverable Price",
    description: "Original price of a deliverable if traded, else the current price",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-content-object.deliverable.original.hours": {
    name: "Original Deliverable Hours",
    description: "Original hours of a deliverable if traded, else the current hours",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-content-object.deliverable.traded-to.name": {
    name: "New Deliverable Name",
    description: "New name of a traded deliverable",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-content-object.deliverable.traded-to.description": {
    name: "New Deliverable Description",
    description: "New description of a traded deliverable",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-content-object.deliverable.traded-to.price": {
    name: "New Deliverable Price",
    description: "New price of a traded deliverable",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-content-object.deliverable.traded-to.hours": {
    name: "New Deliverable Hours",
    description: "New hours of a traded deliverable",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-content-object.deliverable.traded-to.pricevariance": {
    name: "Deliverable Trade Price Variance",
    description: "Deliverable Trade Price Variance",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-content-object.deliverable.traded-to.hoursvariance": {
    name: "Deliverable Trade Hours Variance",
    description: "Deliverable Trade Hours Variance",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-content-object.deliverable.createddate": {
    name: "Deliverable Created Date",
    description: "Deliverable Created Date",
    supportsEntity: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "task-content-object.task.createddate": {
    name: "Task Created Date",
    description: "Task Created Date",
    supportsEntity: ['TASK'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "department-content-object.previewdata.placeholder": createPreviewdata("DEPARTMENT"),
  "department-content-object.name": {
    name: "Department Name",
    description: "Department Name",
    supportsEntity: ['DEPARTMENT', 'SCOPE-SECTION-DEPARTMENT', 'DELIVERABLE-DEPARTMENT'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "department-content-object.total-value": {
    name: "Department Total Value",
    description: "Department Total Value",
    supportsEntity: ['DEPARTMENT', 'SCOPE-SECTION-DEPARTMENT', 'DELIVERABLE-DEPARTMENT'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "department-content-object.fte-value": {
    name: "Department FTE Percentage",
    description: "Department FTE percentage usage calculated according to Scope FTE hours",
    supportsEntity: ['DELIVERABLE-DEPARTMENT'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      defaultText: "0%"
    }
  },
  "department-content-object.total-hours": {
    name: "Department Total Hours",
    description: "Department Total Hours",
    supportsEntity: ['DEPARTMENT', 'SCOPE-SECTION-DEPARTMENT', 'DELIVERABLE-DEPARTMENT'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "component-section-object.section.name": {
    name: "Component Section Name",
    description: "Component Section Name",
    supportsEntity: ['COMPONENT-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "component-content-object.component.name": {
    name: "Component Name",
    description: "Component Name",
    supportsEntity: ['COMPONENT'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "component-content-object.component.description": {
    name: "Component Description",
    description: "Component Description",
    supportsEntity: ['COMPONENT'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "component-content-object.component.cost": {
    name: "Component Cost",
    description: "Component Cost",
    supportsEntity: ['COMPONENT'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none",
      filters: [{id: "includeTPC", default: true}]
    }
  },
  "component-content-object.component.hours": {
    name: "Component Hours",
    description: "Component Hours",
    supportsEntity: ['COMPONENT'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope.component.total.quantity": {
    name: "Scope Component Total Quantity",
    description: "The Component Total Quantity, otherwise zero",
    supportsEntity: ['SCOPE', 'COMPONENT'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },

  "component-content-object.previewdata.placeholder": createPreviewdata("COMPONENT"),
  "role-content-object.role.name": {
    name: "Role Name",
    description: "Role Name",
    supportsEntity: ['ROLE']
  },

  "component-content-object.component.total.ratecard.selling.price": {
    name: "Component Total Resource Selling Price",
    description: "Component Total Resource Selling Price",
    supportsEntity: ['COMPONENT'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },

  "component-content-object.component.total.tpc.selling.price": {
    name: "Component TPC Total Selling Price",
    description: "Component TPC Total Selling Price",
    supportsEntity: ['COMPONENT'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },

  "role-content-object.role.hours": {
    name: "Role Hours",
    description: "Role Hours",
    supportsEntity: ['ROLE'],
    configurable: {
      decimals: "none"
    }
  },
  "role-content-object.role.fte-summary": {
    name: "Role FTE Summary",
    description: "Role FTE Summary",
    supportsEntity: ['ROLE'],
    configurable: {
      decimals: "none"
    }
  },
  "role-content-object.role.fte-value": {
    name: "Role FTE Percentage",
    description: "Role FTE percentage usage calculated according to Scope FTE hours",
    supportsEntity: ['ROLE'],
    configurable: {
      defaultText: "0%"
    }
  },
  "role-content-object.role.cost": {
    name: "Role Cost",
    description: "Role Cost",
    supportsEntity: ['ROLE'],
    configurable: {
      decimals: "none"
    }
  },
  "role-content-object.role.total.cost": {
    name: "Role Total Cost",
    description: "Role Total Cost",
    supportsEntity: ['ROLE'],
    configurable: {
      decimals: "none"
    }
  },
  "role-content-object.previewdata.placeholder": createPreviewdata("ROLE"),
  "deliverable-tpc-content-object.deliverable.tpc.name": {
    name: "Deliverable TPC Name",
    description: "Deliverable Third Party Cost Name",
    supportsEntity: ['DELIVERABLE-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-tpc-content-object.deliverable.tpc.description": {
    name: "Deliverable TPC Description",
    description: "Deliverable Third Party Cost Description",
    supportsEntity: ['DELIVERABLE-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-tpc-content-object.deliverable.tpc.cost": {
    name: "Deliverable TPC Cost",
    description: "Deliverable Third Party Cost Cost",
    supportsEntity: ['DELIVERABLE-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-tpc-content-object.previewdata.placeholder": createPreviewdata("DELIVERABLE-TPC"),
  "deliverable-section-tpc-content-object.deliverable-section.tpc.name": {
    name: "Deliverable Section TPC Name",
    description: "Deliverable Section Third Party Cost Name",
    supportsEntity: ['DELIVERABLE-SECTION-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-section-tpc-content-object.deliverable-section.tpc.description": {
    name: "Deliverable Section TPC Description",
    description: "Deliverable Section Third Party Cost Description",
    supportsEntity: ['DELIVERABLE-SECTION-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "deliverable-section-tpc-content-object.deliverable-section.tpc.cost": {
    name: "Deliverable Section TPC Cost",
    description: "Deliverable Section Third Party Cost Cost",
    supportsEntity: ['DELIVERABLE-SECTION-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-section-tpc-content-object.previewdata.placeholder": createPreviewdata("DELIVERABLE-SECTION-TPC"),
  "component-tpc-content-object.component.tpc.name": {
    name: "Component TPC Name",
    description: "Component Third Party Cost Name",
    supportsEntity: ['COMPONENT-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "component-tpc-content-object.component.tpc.description": {
    name: "Component TPC Description",
    description: "Component Third Party Cost Description",
    supportsEntity: ['COMPONENT-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "component-tpc-content-object.component.tpc.cost": {
    name: "Component TPC Cost",
    description: "Component Third Party Cost Cost",
    supportsEntity: ['COMPONENT-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "component-tpc-content-object.sellingprice": {
    name: "Component TPC Selling Price",
    description: "Component Third Party Cost Selling Price",
    supportsEntity: ['COMPONENT-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "component-tpc-content-object.previewdata.placeholder": createPreviewdata("COMPONENT-TPC"),
  "scope-tpc-content-object.scope.tpc.name": {
    name: "Scope TPC Name",
    description: "Scope TPC name",
    supportsEntity: ['SCOPE-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER']
  },
  "scope-tpc-content-object.scope.tpc.description": {
    name: "Scope TPC Description",
    description: "Component TPC description",
    supportsEntity: ['SCOPE-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope-tpc-content-object.scope.tpc.cost": {
    name: "Scope TPC Cost",
    description: "Component TPC cost",
    supportsEntity: ['SCOPE-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope-tpc-content-object.scope.tpc.price": {
    name: "Scope TPC Price",
    description: "Scope TPC Price",
    supportsEntity: ['SCOPE-TPC'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope-tpc-content-object.previewdata.placeholder": createPreviewdata("SCOPE-TPC"),
  "scope-feeitem-content-object.name": {
    name: "Scope Fee name",
    description: "Scope Fee item name",
    supportsEntity: ['SCOPE-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "scope-feeitem-content-object.description": {
    name: "Scope Fee description",
    description: "Scope Fee item description",
    supportsEntity: ['SCOPE-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "scope-feeitem-content-object.amount": {
    name: "Scope Fee amount",
    description: "Scope Fee item amount",
    supportsEntity: ['SCOPE-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope-feeitem-content-object.amountType": {
    name: "Scope Fee amount type",
    description: "Scope Fee item amount type",
    supportsEntity: ['SCOPE-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "scope-feeitem-content-object.price": {
    name: "Scope Fee price",
    description: "Scope Fee item price",
    supportsEntity: ['SCOPE-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope-section-content-object.name": {
    name: "Scope Section Name",
    description: "Scope Section Name",
    supportsEntity: ['SCOPE-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "scope-section-content-object.description": {
    name: "Scope Section Description",
    description: "Scope Section Description",
    supportsEntity: ['SCOPE-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "scope-section-content-object.totalSellingPrice": {
    name: "Scope Section Total Selling Price",
    description: "Scope Section Total Selling Price of deliverables in this section",
    supportsEntity: ['SCOPE-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope-section-content-object.totalResourceSellingPrice": {
    name: "Scope Section Total Resource Selling Price",
    description: "Total resource selling price of deliverables in this section",
    supportsEntity: ['SCOPE-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope-section-content-object.totalTpcSellingPrice": {
    name: "Scope Section TPC Total Value",
    description: "Total selling price of third party costs in this section",
    supportsEntity: ['SCOPE-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope-section-content-object.totalFixedFeePrice": {
    name: "Scope Section Fixed Fee Total Value",
    description: "Total selling price of fixed fees in this section",
    supportsEntity: ['SCOPE-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope-section-content-object.totalRolesRateCardSellingPrice": {
    name: "Scope Section Roles Total Value",
    description: "Total selling price of roles in this section",
    supportsEntity: ['SCOPE-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope-section-content-object.totalRatecardHours": {
    name: "Scope Section Total Ratecard Hours",
    description: "Scope Section Total Ratecard Hours of deliverables in this section",
    supportsEntity: ['SCOPE-SECTION'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "scope-section-feeitem-content.name": {
    name: "Section Fee Name",
    description: "Scope Section Fee item display name",
    supportsEntity: ['SCOPE-SECTION-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "scope-section-feeitem-content.description": {
    name: "Section Fee Description",
    description: "Scope Section Fee item description",
    supportsEntity: ['SCOPE-SECTION-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "scope-section-feeitem-content.amount": {
    name: "Section Fee Amount",
    description: "Scope Section Fee item amount",
    supportsEntity: ['SCOPE-SECTION-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "scope-section-feeitem-content.amountType": {
    name: "Section Fee Amount Type",
    description: "Scope Section Fee item amount type",
    supportsEntity: ['SCOPE-SECTION-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "scope-section-feeitem-content.price": {
    name: "Section Fee Price",
    description: "Scope Section Fee item price",
    supportsEntity: ['SCOPE-SECTION-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "deliverable-feeitem-content.name": {
    name: "Deliverable Fee Name",
    description: "Deliverable Fee item display name",
    supportsEntity: ['DELIVERABLE-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "deliverable-feeitem-content.description": {
    name: "Deliverable Fee Description",
    description: "Deliverable Fee item description",
    supportsEntity: ['DELIVERABLE-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "deliverable-feeitem-content.amount": {
    name: "Deliverable Fee Amount",
    description: "Deliverable Fee item amount",
    supportsEntity: ['DELIVERABLE-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "deliverable-feeitem-content.amountType": {
    name: "Deliverable Fee Amount Type",
    description: "Deliverable Fee item amount type",
    supportsEntity: ['DELIVERABLE-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "deliverable-feeitem-content.price": {
    name: "Deliverable Fee Price",
    description: "Deliverable Fee item price",
    supportsEntity: ['DELIVERABLE-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  "component-feeitem-content.name": {
    name: "Component Fee Name",
    description: "Component Fee item display name",
    supportsEntity: ['COMPONENT-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "component-feeitem-content.description": {
    name: "Component Fee Description",
    description: "Component Fee item description",
    supportsEntity: ['COMPONENT-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "component-feeitem-content.amount": {
    name: "Component Fee Amount",
    description: "Component Fee item amount",
    supportsEntity: ['COMPONENT-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "component-feeitem-content.amountType": {
    name: "Component Fee Amount Type",
    description: "Component Fee item amount type",
    supportsEntity: ['COMPONENT-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
  },
  "component-feeitem-content.price": {
    name: "Component Fee Price",
    description: "Component Fee item price",
    supportsEntity: ['COMPONENT-FEE-ITEM'],
    supportsBlock: ['CONTENT', 'HEADER', 'FOOTER'],
    configurable: {
      decimals: "none"
    }
  },
  sowContentBlockValues: function() {
    if (this.cachedScopeContentValues) {
      return this.cachedScopeContentValues
    }
    this.cachedScopeContentValues = Object.values(this).filter(function (obj) {
      return typeof obj === 'object' && obj.supportsEntity && obj.supportsEntity.includes('SOW') && obj.supportsBlock.includes('CONTENT');
    })
    return this.cachedScopeContentValues;
  },
  sowHeaderFooterValues: function() {
    if (this.cachedScopeHeaderFooterValues) {
      return this.cachedScopeHeaderFooterValues
    }
    this.cachedScopeHeaderFooterValues = Object.values(this).filter(function (obj) {
      return typeof obj === 'object' && obj.supportsEntity && obj.supportsEntity.includes('SOW')
    })
    return this.cachedScopeHeaderFooterValues;
  },
  scopeContentBlockValues: function() {
    if (this.cachedScopeContentBlockValues) {
      return this.cachedScopeContentBlockValues;
    }
    this.cachedScopeContentBlockValues = Object.values(this).filter(function (obj) {
      return typeof obj === 'object' && obj.supportsEntity && obj.supportsEntity.includes('SCOPE') && obj.supportsBlock.includes('CONTENT');
    })
    return this.cachedScopeContentBlockValues;
  },
  scopeHeaderFooterValues: function() {
    if (this.cachedScopeHeaderFooterValues) {
      return this.cachedScopeHeaderFooterValues;
    }
    this.cachedScopeHeaderFooterValues = Object.values(this).filter(function (obj) {
      return typeof obj === 'object' && obj.supportsEntity && obj.supportsEntity.includes('SCOPE');
    })
    return this.cachedScopeHeaderFooterValues;
  },
  scopeWidgetValues: function(val) {
    this.cachedScopeWidgetValues = Object.values(this).filter(function (obj) {
      return typeof obj === 'object' && obj.supportsEntity && obj.supportsEntity.includes(val);
    })
    return this.cachedScopeWidgetValues;
  },
  names: function () {
    var that = this;
    return Object.keys(this).filter(function (obj) {
      return typeof (that[obj]) === 'object';
    })
  }
}

OutputVariables.names().forEach(function (name) {
  var type = OutputVariables[name];
  type.id = name;
  if (type.configurable){
    type.description = type.description + " [Configurable]"
    if (type.configurable.filters){
      type.description = type.description + " [Filterable]"
    }
  }
});

function createPreviewdata(supportedEntity){
  return {
    name: "Editable Preview Data",
    description: "Enable editable preview data area",
    supportsEntity: [supportedEntity],
    supportsBlock: ['CONTENT'],
    elementAttributes: [
      {
        key: 'data-previewdata-id',
        value: (el) => el.getUniqueId()
      }
    ]
  }
}

export var OutputResourceObjects = {
  "resourceobject-deliverable-resource-table" :{
    name: "Deliverable Resource Table One",
    description: "Table of resource breakdown and details of deliverable",
    supports: ['SCOPE']
  },
  "resourceobject-deliverable-resource-table-editable" :{
    name: "Deliverable Res.Table One Editable",
    description: "Table of resource breakdown and details of deliverable",
    supports: ['SCOPE']
  },
  "resourceobject-scope-collaborators-table" :{
    name: "List of Collaborators Table",
    description: "Table of collaborator for this scope",
    supports: ['SCOPE']
  },
  "resourceobject-scope-secondparty-contacts-table" :{
    name: "List of Second Party Contacts",
    description: "Table of contacts for second party",
    supports: ['SCOPE', 'SOW']
  },
  "resourceobject-breakdown-table" :{
    name: "Breakdown Table",
    description: "Table of breakdown for this scope.",
    supports: ['SCOPE', 'SOW']
  },
  "resourceobject-deliverable-role-table-editable" :{
    name: "Role Table",
    description: "Table of roles of deliverable",
    supports: ['SCOPE']
  },
  "resourceobject-deliverable-resource-table-two" :{
    name: "Deliverable Resource Table Two",
    description: "Table of deliverable for this single scope.",
    supports: ['SCOPE']
  },
  "resourceobject-deliverable-resource-table-three" :{
    name: "Deliverable Resource Table Three",
    description: "Table of deliverable for this single scope.",
    supports: ['SCOPE']
  },
  "resourceobject-sow-scope-resource-table" :{
    name: "Folder of Work Scopes Res. Table",
    description: "Table of sow's scopes.",
    supports: ['SOW']
  },

  "resourceobject-tpc-table" :{
    name: "TPC Table",
    description: "Table of third party costs for deliverables.",
    supports: ['SCOPE', 'SOW']
  },
  "resourceobject-deliverable-rolehours-table" :{
    name: "Deliverable Role Hours Table",
    description: "Table of role hours by deliverable for this scope.",
    supports: ['SCOPE']
  },
  "resourceobject-component-rolehours-table" :{
    name: "Component Role Hours Table",
    description: "Table of role hours by component for this scope.",
    supports: ['SCOPE']
  },
  "resourceobject-deliverable-content" :{
    name: "Deliverable Content",
    description: "Deliverable Content",
    supports: ['SCOPE']
  },
  "resourceobject-task-content" :{
    name: "Task Content",
    description: "Task Content in current scope",
    supports: ['SCOPE']
  },
  "resourceobject-scope-section-deliverable-content" :{
    name: "Scope Section (Stage) Deliverable Content",
    description: "Deliverable Content widget in Scope Section (Stage)",
    supports: ['SCOPE-SECTION']
  },
  "resourceobject-scope-feeitem-content" :{
    name: "Scope Fees content",
    description: "Scope Fees content",
    supports: ['SCOPE']
  },
  "resourceobject-scope-section-content" :{
    name: "Scope Section (Stage) content",
    description: "Scope Section content",
    supports: ['SCOPE']
  },
  "resourceobject-scope-section-feeitem-content" :{
    name: "Scope Section (Stage) Fees content",
    description: "Scope Section Fees content",
    supports: ['SCOPE-SECTION']
  },
  "resourceobject-deliverable-feeitem-content" :{
    name: "Deliverable Fees content",
    description: "Deliverable Fees content",
    supports: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE']
  },
  "resourceobject-component-feeitem-content" :{
    name: "Component Fees content",
    description: "Component Fees content",
    supports: ['COMPONENT']
  },
  "resourceobject-tpc-group-content" :{
    name: "TPC Group Content",
    description: "TPC Group Content",
    supports: ['SCOPE']
  },
  "resourceobject-tpc-content" :{
    name: "TPC Content",
    description: "TPC Content",
    supports: ['SCOPE','TPC-GROUP']
  },
  "resourceobject-scope-section-tpc-content" :{
    name: "Scope Section (Stage) TPC Content",
    description: "TPC Content widget for Scope Section (Stage)",
    supports: ['SCOPE-SECTION']
  },
  "resourceobject-deliverable-fixed-content" :{
    name: "Deliverable Fixed Content",
    description: "Deliverable Fixed Content",
    supports: ['SCOPE']
  },
  "resourceobject-scope-section-deliverable-fixed-content" :{
    name: "Scope Section (Stage) Deliverable Fixed Content",
    description: "Deliverable Fixed Content for Scope Section (Stage)",
    supports: ['SCOPE-SECTION']
  },
  "resourceobject-department-content" :{
    name: "Department Content (Scope)",
    description: "Department Content",
    supports: ['SCOPE']
  },
  "resourceobject-scope-section-department-content" :{
    name: "Scope Section (Stage) Department Content",
    description: "Department Content in Scope Section (Stage)",
    supports: ['SCOPE-SECTION']
  },
  "resourceobject-deliverable-department-content" :{
    name: "Deliverable Department Content",
    description: "Department Content in Deliverable",
    supports: ['DELIVERABLE', 'SCOPE-SECTION-DELIVERABLE', 'TASK']
  },
  "resourceobject-deliverable-section" :{
    name: "Deliverable Scope Section (Stage) Content",
    description: "Deliverable Scope Section (Stage) Content",
    supports: ["DELIVERABLE", 'SCOPE-SECTION-DELIVERABLE']
  },
  "resourceobject-deliverable-deliverable-section" :{
    name: "Deliverable Section Content",
    description: "Deliverable Section Content",
    supports: ["DELIVERABLE", 'SCOPE-SECTION-DELIVERABLE']
  },
  "resourceobject-component-fixed-fee" :{
    name: "Component Fixed Fee Content",
    description: "Component Fixed Fee Content",
    supports: ["DELIVERABLE", 'SCOPE-SECTION-DELIVERABLE', 'DELIVERABLE-SECTION']
  },
  "resourceobject-component-content" :{
    name: "Component Content",
    description: "Component Content",
    supports: ["DELIVERABLE", 'SCOPE-SECTION-DELIVERABLE', 'DELIVERABLE-SECTION']
  },
  "resourceobject-component-section" :{
    name: "Component Section Content",
    description: "Component Section Content",
    supports: ["COMPONENT"]
  },
  "resourceobject-role-content" :{
    name: "Role Content",
    description: "Role Content",
    supports: ['DELIVERABLE','DEPARTMENT', 'SCOPE-SECTION-DEPARTMENT', 'SCOPE-SECTION-DELIVERABLE', 'SCOPE-SECTION', 'DELIVERABLE-DEPARTMENT', 'COMPONENT']
  },
  "resourceobject-deliverable-tpc-content" :{
    name: "Deliverable TPC Content",
    description: "Deliverable Tpc Content",
    supports: ["DELIVERABLE", 'SCOPE-SECTION-DELIVERABLE']
  },
  "resourceobject-deliverable-section-tpc-content" :{
    name: "Deliverable Section TPC Content",
    description: "Deliverable Section Tpc Content",
    supports: ['DELIVERABLE-SECTION']
  },
  "resourceobject-component-tpc-content" :{
    name: "Component TPC Content",
    description: "Component Tpc Content",
    supports: ["COMPONENT", "COMPONENT-FIXED-FEE"]
  },
  "resourceobject-scope-tpc-content" :{
    name: "Scope TPC Content",
    description: "Scope Tpc Content",
    supports: ["SCOPE"]
  },
  "resourceobject-calculation-content" :{
    name: "Calculation Content",
    description: "Calculation Content",
    supports: ["SCOPE"]
  },

  scopeValues: function () {
    if (this.cachedScopeValues) {
      return this.cachedScopeValues
    }
    this.cachedScopeValues = Object.values(this).filter(function (obj) {
      return typeof obj === 'object'  && obj.supports && obj.supports.includes('SCOPE')
    })
    return this.cachedScopeValues;
  },
  widgetValues: function (widgetType) {
    this.cachedWidgetValues = Object.values(this).filter(function (obj) {
      return typeof obj === 'object' && obj.supports &&  obj.supports.includes(widgetType)
    })
    return this.cachedWidgetValues;
  },
  sowValues: function () {
    if (this.cachedSowValues) {
      return this.cachedSowValues
    }
    this.cachedSowValues = Object.values(this).filter(function (obj) {
      return typeof obj === 'object'  && obj.supports && obj.supports.includes('SOW')
    })
    return this.cachedSowValues;
  },
  names: function () {
    var that = this;
    return Object.keys(this).filter(function (obj) {
      return typeof (that[obj]) === 'object';
    })
  }
}

OutputResourceObjects.names().forEach(function (name) {
  var type = OutputResourceObjects[name];
  type.id = name;
  type.widget = name;
});

export var DEFINITIONS = {
  "resourceobject-deliverable-content": "DELIVERABLE",
  "resourceobject-task-content": "TASK",
  "resourceobject-scope-section-deliverable-content": "SCOPE-SECTION-DELIVERABLE",
  "resourceobject-scope-section-tpc-content": "SCOPE-SECTION-TPC",
  "resourceobject-scope-section-department-content": "SCOPE-SECTION-DEPARTMENT",
  "resourceobject-deliverable-department-content": "DELIVERABLE-DEPARTMENT",
  "resourceobject-scope-section-deliverable-fixed-content": "SCOPE-SECTION-FIXED-DELIVERABLE",
  "resourceobject-tpc-group-content": "TPC-GROUP",
  "resourceobject-tpc-content": "TPC",
  "resourceobject-deliverable-fixed-content": "DELIVERABLE-FIXED",
  "resourceobject-department-content": "DEPARTMENT",
  "resourceobject-deliverable-section": "DELIVERABLE-STAGE",
  "resourceobject-deliverable-deliverable-section": "DELIVERABLE-SECTION",
  "resourceobject-component-fixed-fee": "COMPONENT-FIXED-FEE",
  "resourceobject-component-content": "COMPONENT",
  "resourceobject-component-section": "COMPONENT-SECTION",
  "resourceobject-deliverable-tpc-content": "DELIVERABLE-TPC",
  "resourceobject-deliverable-section-tpc-content": "DELIVERABLE-SECTION-TPC",
  "resourceobject-component-tpc-content": "COMPONENT-TPC",
  "resourceobject-scope-tpc-content": "SCOPE-TPC",
  "resourceobject-role-content" : "ROLE",
  "resourceobject-scope-feeitem-content" : "SCOPE-FEE-ITEM",
  "resourceobject-scope-section-content" : "SCOPE-SECTION",
  "resourceobject-scope-section-feeitem-content" : "SCOPE-SECTION-FEE-ITEM",
  "resourceobject-deliverable-feeitem-content" : "DELIVERABLE-FEE-ITEM",
  "resourceobject-component-feeitem-content" : "COMPONENT-FEE-ITEM",
}

export function getDefinitionNameVariables(matchInfo) {
  let name = matchInfo.autocomplete.editor.widgets.widgetHoldingFocusedEditable.definition.name;
  if (DEFINITIONS[name]) {
    return DEFINITIONS[name];
  }
  return null;
}

export function getDefinitionNameWidgets(matchInfo) {
  let name = matchInfo.autocomplete.editor.widgets.widgetHoldingFocusedEditable.definition.name;
  if (DEFINITIONS[name]) {
    return DEFINITIONS[name];
  }
  return null;
}

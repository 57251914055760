import { createSelector } from '@ngrx/store'
import * as fromFeature from '@app/features/scope-overview/store/reducers/index'
import { ScopeOverviewState } from '../../model/scope-overview.state.model'
import { Activities } from '../../model/activity.model'
import { ScopeVersion, StatusType } from '@app/core/model/scope-version';
import { ScopeRetainedHoursStats } from '../../model/scope-retained-hours-stats.model'
import { SowRetainedHoursStats } from '../../model/sow-retained-hours-stats.model'
import { Money } from '../../model/money.model'
import { SecondParty } from '@core/model/second-party.model'
import { OfficeLocation } from '@core/model/office-location'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { SowRetainedTeamStats } from '@app/features/scope-overview/model/sow-retained-team-stats.model'
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model'
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'
import { plainToInstance } from 'class-transformer';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';

export const getScopingFromState = createSelector(
  fromFeature.getScopeOverviewState,
  (state: fromFeature.State) => state.scopeOverview
)

const getCurrentScope = (state: ScopeOverviewState): ScopeVersion => state.currentScope

const selectCurrentScope = createSelector(getScopingFromState, getCurrentScope)

const getActivites = (state: ScopeOverviewState): Activities => state.activities

const selectActivities = createSelector(getScopingFromState, getActivites)

const selectComponentsByDeliverable = (deliverableId: number) =>
  createSelector(getScopingFromState, (state): any => {
    return state.currentScope.deliverables.find((d) => d.id === deliverableId)?.components
  })

const getLoading = (state: ScopeOverviewState): boolean => state.loading.loadingScope

const getLoaded = (state: ScopeOverviewState): boolean => state.loaded.loadedScope

const selectCurrentScopeLoading = createSelector(getScopingFromState, getLoading)

const selectCurrentScopeLoaded = createSelector(getScopingFromState, getLoaded)

const getUpdateScopeDetailsLoaded = (state: ScopeOverviewState): boolean => state.loaded.loadedUpdateScopeDetails

const selectUpdateScopeDetailsLoaded = createSelector(getScopingFromState, getUpdateScopeDetailsLoaded)

const getUpdateScopeDetailsLoading = (state: ScopeOverviewState): boolean => state.loading.loadingUpdateScopeDetails

const selectUpdateScopeDetailsLoading = createSelector(getScopingFromState, getUpdateScopeDetailsLoading)

const getLoadingSaveConfiguration = (state: ScopeOverviewState): boolean => state.loading.loadingSaveConfiguration

const selectLoadingSaveConfiguration = createSelector(getScopingFromState, getLoadingSaveConfiguration)

const getLoadingSubmitConfiguration = (state: ScopeOverviewState): boolean => state.loading.loadingSubmitConfiguration

const selectLoadingSubmitConfiguration = createSelector(getScopingFromState, getLoadingSubmitConfiguration)

const getLoadedSubmitConfiguration = (state: ScopeOverviewState): boolean => state.loaded.loadedSubmitConfiguration

const selectLoadedSubmitConfiguration = createSelector(getScopingFromState, getLoadedSubmitConfiguration)

const getScopeRetainedHourStats = (state: ScopeOverviewState): ScopeRetainedHoursStats => state.scopeRetainedHourStats

const getSowRetainedHourStats = (state: ScopeOverviewState): SowRetainedHoursStats => state.sowRetainedHourStats

const selectScopeRetainedHourStats = createSelector(getScopingFromState, getScopeRetainedHourStats)

const selectSowRetainedHourStats = createSelector(getScopingFromState, getSowRetainedHourStats)

const getThirdPartyCostBalance = (state: ScopeOverviewState): Money => state.thirdPartyCostBalance

const selectThirdPartyCostBalance = createSelector(getScopingFromState, getThirdPartyCostBalance)

const selectAvailableDeliverableTypes = (currencyCode: string) =>
  createSelector(getScopingFromState, (state: ScopeOverviewState): any =>
    (state.availableDeliverableTypes || []).filter((d) => !d.isFixedCost || d.complexityPriceL?.currency === currencyCode).sort((a, b) => a.name.toLowerCase().trim() > b.name.toLowerCase().trim() ? 1 : -1)
  )

const getDeliverableLibraryTemplate = (state: ScopeOverviewState): any => state.deliverableLibraryTemplate;

const selectDeliverableLibraryTemplate = createSelector(getScopingFromState, getDeliverableLibraryTemplate)

const getDeliverableLibraryEntry = (state: ScopeOverviewState): any => state.deliverableLibraryEntry;

const selectDeliverableLibraryEntry = createSelector(getScopingFromState, getDeliverableLibraryEntry);

const getLibraryTemplateLoaded = (state: ScopeOverviewState): boolean => state.loaded.loadedLibraryTemplate

const getAllRateCards = (state: ScopeOverviewState): RatecardVersion[] => state.rateCards

const getFilteredDeliverables = (state: ScopeOverviewState): any => state.filteredDeliverables

const getAllFees = (state: ScopeOverviewState): FeeItemInstance[] => {
  if (!state.loaded.loadedAllFees) return []
  let scopeFees = state.currentScope.feeItems.map((feeItem) => {
    return {...feeItem, level: { value: 'scope' }}
  })
  let sectionFees = state.currentScope.sections
    .flatMap((section) => section.feeItems.map((feeItem) => {
      return {...feeItem, level: { value: 'section' }}
    }))
  let deliverableFees = state.currentScope.deliverables
    .flatMap((deliverable) => deliverable.feeItems.map((feeItem) => {
      return {...feeItem, level: { value: 'deliverable' }}
    }))
  let componentFees = state.allComponentFees.map((feeItem) => {
    return {...feeItem, level: { value: 'component' }}
  }) || []
  return scopeFees.concat(sectionFees).concat(deliverableFees).concat(componentFees)
}

const getAllFeesLoaded = (state: ScopeOverviewState): boolean => {
  return state.loaded.loadedAllFees
}

const getAllThirdPartyCosts = (state: ScopeOverviewState): ThirdPartyCost[] => {
  if (!state.loaded.loadedAllThirdPartyCosts) return []
  let deliverableThirdPartyCosts = state.currentScope.deliverables
    .flatMap((deliverable) => deliverable.thirdPartyCosts)

  return deliverableThirdPartyCosts.concat(state.allSectionTPCs || []).concat(state.allComponentTPCs || []).concat(state.currentScope.thirdPartyCosts || [])
}

const getAllThirdPartyCostsLoaded = (state: ScopeOverviewState): boolean => {
  return state.loaded.loadedAllThirdPartyCosts
}

const selectLibraryTemplateLoaded = createSelector(getScopingFromState, getLibraryTemplateLoaded)

const selectOutputTemplate = createSelector(getScopingFromState, (state: ScopeOverviewState): any => state.outputTemplate)

const selectDocxPreview = createSelector(getScopingFromState, (state: ScopeOverviewState): any => state.docxPreview)

const selectPreviewError = createSelector(getScopingFromState, (state: ScopeOverviewState): any => state.previewError)

const selectSecondPartyForScope =
  createSelector(getScopingFromState, (state): SecondParty => {
    return state.secondParty
  })

const selectOfficeLocationForScope =
  createSelector(getScopingFromState, (state): OfficeLocation => {
    return state.officeLocation
  })

const selectCustomFieldsOutdated =
  createSelector(getScopingFromState, (state): boolean => {
    return state.customFieldsOutdated
  })

const selectDefaultRateCard =
  createSelector(getScopingFromState, (state): RatecardVersion => {
    return state.defaultRateCard
  })

const selectRetainedTeamStats =
  createSelector(getScopingFromState, (state): SowRetainedTeamStats[] => {
    return state.sowRetainedTeamStats
  })

const selectAllRateCards = createSelector(getScopingFromState, getAllRateCards)

const selectTeamError = createSelector(getScopingFromState, (state: ScopeOverviewState): any => state.teamError)

const selectFilteredDeliverables = createSelector(getScopingFromState, getFilteredDeliverables)

export const selectLoadedUpdateDeliverable = createSelector(
  getScopingFromState,
  (state) => state.loaded.loadedUpdateDeliverable
);

const selectScopeFees = createSelector(getScopingFromState, (state: ScopeOverviewState) => state.currentScope.feeItems)

const selectAllFees = createSelector(getScopingFromState, getAllFees)

const selectAllFeesLoaded = createSelector(getScopingFromState, getAllFeesLoaded)

const selectAllThirdPartyCosts = createSelector(getScopingFromState, getAllThirdPartyCosts)

const selectAllThirdPartyCostsLoaded = createSelector(getScopingFromState, getAllThirdPartyCostsLoaded)

const searchComponentsForScope = (state: ScopeOverviewState): any => state.scopeComponents
const searchNonDistinctComponentsForScope = (state: ScopeOverviewState): any => state.nonDistinctComponents
const searchDepartmentsForScope = (state: ScopeOverviewState): any => state.scopeDepartments
const searchRolesForScope = (state: ScopeOverviewState): any => state.scopeRoles

const selectSearchedComponentsForScope = createSelector(getScopingFromState, searchComponentsForScope)
const selectSearchedNonDistinctComponentsForScope = createSelector(getScopingFromState, searchNonDistinctComponentsForScope)
const selectSearchedDepartmentsForScope = createSelector(getScopingFromState, searchDepartmentsForScope)
const selectSearchedRolesForScope = createSelector(getScopingFromState, searchRolesForScope)

const selectDeliverable = (deliverableId: number) => createSelector(getScopingFromState,
  (state: ScopeOverviewState): any => state.currentScope.deliverables.find((deliverable) => deliverable.id === deliverableId)
)

const selectLoadingAllTasks = createSelector(getScopingFromState, (state: ScopeOverviewState): boolean => state.loading?.loadingAllTasks)

const selectDialogId = createSelector(getScopingFromState, (state: ScopeOverviewState): string => state.dialogId)

const selectScenario = createSelector(getScopingFromState, (state: ScopeOverviewState) => plainToInstance(Scenario, state.scenario));

const selectConfigurationHasUpdates = createSelector(getScopingFromState, (state: ScopeOverviewState) => state.configurationHasUpdates);

const getScopeStatus = (state: ScopeOverviewState): StatusType => state.currentScope.status

const selectScopeStatus = createSelector(getScopingFromState, getScopeStatus)

export const ScopeOverviewSelectors = {
  selectActivities,
  selectComponentsByDeliverable,
  selectCurrentScope,
  selectCurrentScopeLoading,
  selectScopeRetainedHourStats,
  selectSowRetainedHourStats,
  selectThirdPartyCostBalance,
  selectCurrentScopeLoaded,
  selectAvailableDeliverableTypes,
  selectDeliverableLibraryTemplate,
  selectDeliverableLibraryEntry,
  selectLibraryTemplateLoaded,
  selectOutputTemplate,
  selectDocxPreview,
  selectPreviewError,
  selectSecondPartyForScope,
  selectOfficeLocationForScope,
  selectCustomFieldsOutdated,
  selectDefaultRateCard,
  selectRetainedTeamStats,
  selectAllRateCards,
  selectUpdateScopeDetailsLoaded,
  selectUpdateScopeDetailsLoading,
  selectLoadingSaveConfiguration,
  selectLoadingSubmitConfiguration,
  selectLoadedSubmitConfiguration,
  selectTeamError,
  selectFilteredDeliverables,
  selectLoadedUpdateDeliverable,
  selectScopeFees,
  selectAllFees,
  selectAllFeesLoaded,
  selectAllThirdPartyCosts,
  selectAllThirdPartyCostsLoaded,
  selectSearchedComponentsForScope,
  selectSearchedNonDistinctComponentsForScope,
  selectSearchedDepartmentsForScope,
  selectSearchedRolesForScope,
  selectDeliverable,
  selectLoadingAllTasks,
  selectDialogId,
  selectScenario,
  selectConfigurationHasUpdates,
  selectScopeStatus
}

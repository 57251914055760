import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { User } from '@core/model/user.model';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { AuthService } from '@core/service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  SNACKBAR_LENGTH_SHORT,
  SnackbarEventType,
  SnackbarService,
} from '@shared/utils/snackbar.service';
import { Preference } from '@core/model/user-preferences.interface';
import { MenuOptions } from '@core/model/definitions/menu-options.interface';
import { combineLatest, map, Observable } from 'rxjs';
import { MappedEntity } from '@app/features/scoping/models/mapped-entity.model';
import { Store } from '@ngrx/store';
import {
  CompanyManagementSelectors,
} from '@app/features/company-management/store/selectors/company-management.selectors';
import { CompanyManagementActions } from '@app/features/company-management/store/actions/company-management.actions';
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service';
import { FormControl } from '@angular/forms';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import { ScenarioService } from '@app/features/company-management/service/scenario.service';
import { ScopeUiTableSelectMode } from '@app/shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { TableColumnKey } from '@shared/components/ui-components/scope-ui-table/table-column-key.enum';
import { searchInText } from '@app/shared/utils/search-utils.const';

@Component({
  selector: 'scenarios',
  templateUrl: './scenarios.component.html',
  styleUrls: ['./scenarios.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenariosComponent {
  loggedInUser!: User;
  scenarios$: Observable<Scenario[]>;
  loading$: Observable<boolean>;
  mappedScenarioDataSource$!: Observable<MappedEntity<Scenario>[]>;
  scenarios: Scenario[];
  searchText = '';
  scenarioColumns!: { [key: string]: Preference; };
  scenarioPreferenceColumns!: Preference[];
  scenarioMenuOptions: MenuOptions[];

  constructor(private store: Store,
              private authService: AuthService,
              private mappingService: DataMappingService,
              private scenarioService: ScenarioService,
              private cdr: ChangeDetectorRef,
              private router: Router,
              public route: ActivatedRoute,
              private dialog: MatDialog,
              private snackbarService: SnackbarService) {
    this.loggedInUser = this.authService.loggedInUser;
    this.scenarios$ = this.store.select(CompanyManagementSelectors.selectScenarios);
    this.loading$ = this.store.select(CompanyManagementSelectors.selectLoadingScenarios);
  }

  ngOnInit(): void {
    this.store.dispatch(CompanyManagementActions.getScenarios());
    this.mapScenarioConfigToDataSource();
    this.initScenarioColumns();
    this.initScenarioMenuOptions();
  }

  mapScenarioConfigToDataSource() {
    this.mappedScenarioDataSource$ = combineLatest([this.scenarios$]).pipe(
      map(([scenarios]) => {
        return this.mappingService.transformArray(scenarios, this.scenarioPreferenceColumns);
      }),
    );
  }

  initScenarioColumns() {
    this.scenarioColumns = {
      NAME: {
        key: TableColumnKey.NAME,
        name: 'Name',
        valueIconFunction: () => {
          return 'edit_document';
        },
        selected: true,
        field: 'name',
      },
      CREATED_BY: {
        key: TableColumnKey.CREATED_BY,
        name: 'Created By',
        selected: true,
        value: (scenario: Scenario) => {
          return scenario.auditMetadata?.createdBy?.getFullname();
        },
      },
      CREATED_DATE: {
        key: TableColumnKey.DATE_CREATED,
        name: 'Created Date',
        selected: true,
        field: 'auditMetadata.createdOn',
      },
    };
    this.initScenarioPreferenceColumns();
  }

  initScenarioPreferenceColumns() {
    this.scenarioPreferenceColumns = Object.values(this.scenarioColumns);
  }

  filterScenarios() {
    const filteredScenarios$ = this.scenarios$.pipe(
      map(scenarios => scenarios.filter(scenario => searchInText(scenario.name, this.searchText))),
    );
    this.mappedScenarioDataSource$ = filteredScenarios$.pipe(
      map(filteredScenarios =>
        this.mappingService.transformArray(filteredScenarios, this.scenarioPreferenceColumns),
      ),
    );
  }

  openCreateModal() {
    let inputs: any[] = [
      {
        name: 'name', control: new FormControl(''), type: 'text', required: true,
        label: `Form name`, maxLength: 50,
      },
    ];

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        'Add Scenario Form',
        `Create a new Scenario Form`,
        `Add Scenario Form`,
        undefined,
        (form: FormControl) => {
          let scenario = {
            name: form.get('name').value,
          };
          this.scenarioService.create(scenario).subscribe({
            next: (result) => {
              dialog.close();
              this.store.dispatch(CompanyManagementActions.createScenarioSuccess({ scenario: result }));
              this.snackbarService.showSnackbar(`Scenario Form ${result.name} was successfully created.`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS);
            },
            error: () => {
              this.snackbarService.showDefaultErrorSnackbar();
            },
          });
        },
        undefined,
        inputs,
        false,
        true,
        undefined,
        (form: FormControl) => !!form.get('name')?.value,
      ),
    });
  }

  initScenarioMenuOptions() {
    this.scenarioMenuOptions = [
      {
        callback: (element) => this.onDelete(element.entity),
        icon: () => 'delete',
        name: () => 'Delete',
      },
    ];
  }

  onSelect(scenario: Scenario) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { 'tab': 'scenarios', 'id': scenario.id },
        replaceUrl: true
      }
    )
  }

  onDelete(scenario: Scenario) {
    const scenarioDeleteModalConfig: ModalConfig = {
      title: `Delete Scenario Form`,
      body: `Are you sure you want to delete ${scenario.name}?`,
      confirmText: 'Delete',
      confirmCallback: () => {
        this.store.dispatch(CompanyManagementActions.deleteScenario({ id: scenario.id }));
        dialog.close();
      },
      cancelCallback: undefined,
      inputs: [],
      isMemberModal: undefined,
      limitBodyWidth: true,
    };
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: scenarioDeleteModalConfig,
    });
  }

  protected readonly ScopeUiTableSelectMode = ScopeUiTableSelectMode;
}

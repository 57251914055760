import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LibraryItemUrl } from '@app/features/library-management/const'
import { LibraryItemTypes } from '@app/features/library-management/store'
import { LibraryManagementItem } from '@app/features/library-management/store/models/library-items'
import { environment } from '@envs/environment'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class LibraryManagementItemsService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl

  constructor(private http: HttpClient) {}

  getLibraryItems(params?: any): Observable<any> {
    return this.http.get<any>(`${this.BASE_API_URL}library/item`, { params })
  }

  searchLibraryItems(params?: any): Observable<any> {
    return this.http.get<any>(`${this.BASE_API_URL}library/item/search`, { params })
  }

  getFilteredLibraryItems(params: any): Observable<any> {
    return this.http.get<any>(`${this.BASE_API_URL}library/item/filter`, { params })
  }

  getLibraryItem(id: number, type: LibraryItemTypes): Observable<LibraryManagementItem> {
    return this.http.get<any>(`${this.BASE_API_URL}library/${LibraryItemUrl[type]}/${id}`)
  }

  updateComponent(component: any) {
    return this.http.put<any>(`${this.BASE_API_URL}library/component`, component)
  }

  updateThirdPartyCost(thirdPartyCost: any) {
    return this.http.put<any>(`${this.BASE_API_URL}library/third-party-cost/${thirdPartyCost.id}`, thirdPartyCost)
  }

  duplicateLibraryItem(id: number, type: LibraryItemTypes, newName: string) {
    return this.http.post<any>(`${this.BASE_API_URL}library/${LibraryItemUrl[type]}/${id}/duplicate`, { newName })
  }

  archiveLibraryItem(id: number) {
    return this.http.delete<any>(`${this.BASE_API_URL}library/item/${id}/archive`)
  }

  deleteLibraryItem(id: number) {
    return this.http.delete<any>(`${this.BASE_API_URL}library/item/${id}`)
  }

  deleteLibraryEntry(id: number, entryId: number, type: LibraryItemTypes) {
    return this.http.delete<any>(`${this.BASE_API_URL}library/${LibraryItemUrl[type]}/${id}/entry/${entryId}`)
  }

  moveLibraryItem(itemId: number, params) {
    return this.http.put<any>(`${this.BASE_API_URL}library/item/${itemId}/folder`, null, { params })
  }
}

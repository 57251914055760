import { Privileged } from '@app/core/class/privileged.class'
import { Company } from '@app/core/model/company.model'
import { User } from '@app/core/model/user.model'
import { Money } from '@app/features/scope-overview/model/money.model'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { Type } from 'class-transformer'
import { LibraryComponent } from '../component/library-component.model'
import { LibraryDiscipline } from '../library-discipline.model'
import { LibraryRateCard } from '../library-rate-card.model'

export class LibraryDeliverableEntry extends Privileged {
  id: number
  name: string
  language: string
  discipline: LibraryDiscipline
  rateCard: LibraryRateCard
  rateCardVersion: RatecardVersion
  fixedCostEditable: boolean
  disciplineTypeId: number
  disciplineTypeName: string
  sections: any[]
  libraryComponents: LibraryComponent[]
  thirdPartyCosts: any[]
  feeItems: null
  deliverable: {
    id: number
    name: string
  }
  description: string
  internalNote: string
  createdTs: string
  updatedTs: string
  @Type(() => User) createdBy: User
  @Type(() => User) updatedBy: User
  archived: boolean
  fixedPricing: boolean
  @Type(() => Money) cachedTotalValue: Money
  @Type(() => Money) fixedCostOffset: Money
  privilegeCategories: string[]
  initialized: boolean
  @Type(() => Company) company: Company
  compoundKey: {
    ratecardVersionId: 1094
    disciplineId: 1
  }

  get value(): Money {
    const value = Money.of(this.cachedTotalValue?.getValue(), this.cachedTotalValue?.getCurrencyCode())
    if (this.fixedCostOffset) value.amount = this.cachedTotalValue?.getValue() - this.fixedCostOffset.getValue()
    return value
  }
}

import { Component, Inject } from '@angular/core'
import { LibraryDeliverableActions, LibraryDeliverableSelectors } from '@app/features/library-management/store'
import { LibraryComponent } from '@app/features/library-management/store/models/component'
import { Store } from '@ngrx/store'

import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LibraryDuplicateEntryModalConfig } from '@app/features/library-management/models'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { combineLatest } from 'rxjs'

@Component({
  templateUrl: './library-map-deliverable-entry-roles-modal.component.html',
  styleUrls: ['./library-map-deliverable-entry-roles-modal.component.scss'],
})
export class LibraryMapDeliverableEntryRolesModalComponent {
  mappingRoles: any[] = []
  targetRoles: any[] = [{ name: 'Unassigned', id: null }]
  targetRateCard: RatecardVersion
  entryComponents: LibraryComponent[]

  isLoadingRateCard$ = this.store$.select(LibraryDeliverableSelectors.selectIsLoadingRateCard)

  constructor(@Inject(MAT_DIALOG_DATA) public data: LibraryDuplicateEntryModalConfig, private store$: Store) {
    this.store$.dispatch(LibraryDeliverableActions.getRateCard({ id: data.rateCardId, version: data.rateCardVersion }))
    this.store$.dispatch(LibraryDeliverableActions.getDeliverableEntry({ id: data.entry.id }))

    combineLatest([
      this.store$.select(LibraryDeliverableSelectors.selectRateCard),
      this.store$.select(LibraryDeliverableSelectors.selectEntriesMap),
    ]).subscribe(([rateCard, entriesMap]) => {
      this.targetRateCard = rateCard
      this.entryComponents = entriesMap?.[data.entry.id]?.components
      if (this.targetRateCard && this.entryComponents) this.prepareMapping()
    })
  }

  prepareMapping() {
    let targetDepartments = this.targetRateCard!.departments
    let targetDepartmentsRolesByName: { [key: string]: any } = {}
    let distinctRolesMap = {}

    if (targetDepartments) {
      targetDepartments.forEach((targetDepartment) =>
        targetDepartment.roles.forEach(
          (targetRole) => (targetDepartmentsRolesByName[targetRole.name.toLowerCase()] = targetRole)
        )
      )
      this.targetRoles = this.targetRoles.concat(targetDepartments.flatMap((department) => department.roles))

      this.entryComponents.forEach((component) =>
        component.roles?.forEach((role) => {
          let key = role.rateCardRole.name.toLowerCase()
          let targetRole = targetDepartmentsRolesByName[key]
          let distinctRole = {
            name: role.rateCardRole.name,
            id: role.rateCardRole.id,
            rate: role.rateCardRole.rate,
            targetMappingRole: targetRole || null,
          }
          distinctRolesMap[key] = distinctRole
        })
      )
      this.mappingRoles = Object.values(distinctRolesMap)
    }
  }

  getMappedRoles() {
    return this.mappingRoles.reduce((res, role) => ({ ...res, [role.id]: role.targetMappingRole?.id }), {})
  }

  submit() {
    this.data.onSubmit(this.getMappedRoles())
  }
}

import {
  LibraryComponent,
  LibraryComponentEntry,
  LibraryComponentState,
} from '@app/features/library-management/store/models/component'
import { LibraryDiscipline, LibraryLocationCard, LibraryRateCard } from '@app/features/library-management/store/models'
import { chunk, keyBy } from 'lodash'

import { Department } from '@app/features/scoping/models/department.model'
import { LibraryManagementFeatureState } from '@app/features/library-management/store/models/state'
import { LibraryManagementSelectors } from './library-management.selectors'
import { Pagination } from '@app/core/model/definitions/pagination.interface'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { createSelector } from '@ngrx/store'
import { getLibraryManagementFeatureState } from './library-management-feature.selectors'

const getLibraryComponentState = createSelector(
  getLibraryManagementFeatureState,
  (state: LibraryManagementFeatureState): LibraryComponentState => state?.component
)

const selectIsLoading = createSelector(
  getLibraryComponentState,
  (state: LibraryComponentState): boolean => state?.isLoading
)

const selectIsLoadingRateCard = createSelector(
  getLibraryComponentState,
  (state: LibraryComponentState): boolean => state?.isLoadingRateCard
)

const selectIsEntryChanged = createSelector(
  getLibraryComponentState,
  (state: LibraryComponentState): boolean => state?.isEntryChanged
)

const selectComponent = createSelector(
  getLibraryComponentState,
  (state: LibraryComponentState): LibraryComponent => state?.component
)

const selectRateCard = createSelector(
  getLibraryComponentState,
  (state: LibraryComponentState): RatecardVersion => state?.rateCard
)

const selectLocationCards = createSelector(
  selectRateCard,
  (state: RatecardVersion): LibraryLocationCard[] => state?.locationCards
)

const selectRateCardDepartmentsMap = createSelector(
  selectRateCard,
  (rateCard: RatecardVersion): { [key: number]: Department } =>
    keyBy(rateCard?.departments, (department) => department.id)
)

const selectRateCardDepartmentByRoleIdMap = createSelector(
  selectRateCard,
  (rateCard: RatecardVersion): { [key: number]: number } =>
    rateCard?.departments.reduce((res, dep) => {
      dep.roles?.forEach((role) => (res[role.id] = dep.id))
      return res
    }, {})
)

const selectComponentEntries = createSelector(
  selectComponent,
  (component): LibraryComponentEntry[] => component?.libraryComponentEntries
)

const selectPagination = createSelector(
  getLibraryComponentState,
  (state: LibraryComponentState): Pagination => state?.pagination
)

const selectPaginatedComponentEntries = createSelector(
  selectComponentEntries,
  selectPagination,
  (entries: LibraryComponentEntry[], pagination: Pagination): LibraryComponentEntry[] =>
    chunk(entries, pagination.pageSize)[pagination.page]
)

const selectUnusedDisciplines = (rateCard: LibraryRateCard) =>
  createSelector(
    LibraryManagementSelectors.selectDisciplines,
    selectComponentEntries,
    (disciplines: LibraryDiscipline[], entries: LibraryComponentEntry[]) => {
      if (rateCard) {
        let usedDisciplinesMap = entries.reduce((map, entry) => {
          var rateCardId = entry.rateCardVersion.rateCardIdentity.id
          var rateCardVersion = entry.rateCardVersion.version

          if (!map[rateCardId]) map[rateCardId] = {}
          if (!map[rateCardId][rateCardVersion]) map[rateCardId][rateCardVersion] = {}

          map[rateCardId][rateCardVersion][entry.discipline.id] = true

          return map
        }, {})

        let usedDisciplines = usedDisciplinesMap[rateCard.rateCardIdentity.id]?.[rateCard.version]

        return usedDisciplines ? disciplines.filter((d) => !usedDisciplines[d.id]) : disciplines
      }

      return disciplines
    }
  )

export const LibraryComponentSelectors = {
  selectIsLoading,
  selectIsLoadingRateCard,
  selectIsEntryChanged,
  selectRateCard,
  selectRateCardDepartmentsMap,
  selectRateCardDepartmentByRoleIdMap,
  selectLocationCards,
  selectComponent,
  selectComponentEntries,
  selectPaginatedComponentEntries,
  selectPagination,
  selectUnusedDisciplines,
}

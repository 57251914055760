import {
  LibraryDeliverable,
  LibraryDeliverableEntryRequest,
} from '@app/features/library-management/store/models/deliverable'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UploadType } from '@app/features/company-management/models/upload-modal-config'
import { LibraryExportType } from '@app/features/library-management/store'
import { LibraryComponent } from '@app/features/library-management/store/models/component'
import { getPlainHttpParams } from '@app/shared/utils/utils'
import { environment } from '@envs/environment'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class LibraryManagementService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl

  constructor(private http: HttpClient) {}

  getHighlights(params): Observable<any> {
    return this.http.get<any>(`${this.BASE_API_URL}library/highlights`, { params })
  }

  getRateCards() {
    return this.http.get<any>(`${this.BASE_API_URL}company/ratecard`)
  }

  getDisciplines() {
    return this.http.get<any>(`${this.BASE_API_URL}predict/disciplines`)
  }

  createDeliverable(deliverable: LibraryDeliverable, scopeId?: number): Observable<any> {
    return this.http.post<any>(
      `${this.BASE_API_URL}library/deliverable${scopeId ? '?originatingScopeId=' + scopeId : ''}`,
      deliverable
    )
  }

  createComponent(component: LibraryComponent): Observable<any> {
    return this.http.post<any>(`${this.BASE_API_URL}library/component`, component)
  }

  createDeliverableEntry(deliverableId: number, entry: LibraryDeliverableEntryRequest): Observable<any> {
    return this.http.post<any>(`${this.BASE_API_URL}library/deliverable/${deliverableId}/entry`, entry)
  }

  verify(file: any, type: UploadType, includeRoleAndHour?: boolean) {
    let formData = new FormData()
    formData.append('file', file)
    return this.http.post(`${this.BASE_API_URL}library/${type}/import/verify`, formData, {
      responseType: 'arraybuffer',
      params: getPlainHttpParams({ includeRoleAndHour }),
    })
  }

  import(file: any, type: UploadType, includeRoleAndHour?: boolean) {
    let formData = new FormData()
    formData.append('image', file)
    return this.http.post(`${this.BASE_API_URL}library/${type}/import`, formData, {
      params: getPlainHttpParams({ includeRoleAndHour }),
    })
  }

  export(type: LibraryExportType, rateCardIdentityId: number) {
    return this.http.post(`${this.BASE_API_URL}library/${type}/export`, null, {
      params: getPlainHttpParams({ rateCardIdentityId }),
    })
  }
}

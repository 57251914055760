<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>Duplicate {{ 'deliverable' | lang }} Entry</h1>
    </div>

    <button
      class="close-button"
      mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content
  aria-label="Generic modal"
  class="modal-container md-dialog-container library">
  <div class="px-10 pb-4 w-full library-modal-content">
    <div class="flex-row">
      <scope-ui-autocomplete
        label="Choose Rate Card"
        [control]="getControl('rateCard')"
        [options]="rateCards$ | async" />
    </div>

    <div class="flex-row pt-8">
      <scope-ui-autocomplete
        label="Choose Discipline"
        [control]="getControl('discipline')"
        [options]="disciplines$ | async" />
    </div>
  </div>

  <div class="d-flex d-flex-justify-end px-10 pt-8 pb-4">
    <div class="role-actions">
      <button
        mat-button
        mat-dialog-close>
        Cancel
      </button>
      <button
        class="main-action-button ml-4"
        [class.disabled]="!modalForm.valid"
        mat-button
        (click)="submit()">
        Duplicate
      </button>
    </div>
  </div>
</mat-dialog-content>

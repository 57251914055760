import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { Type } from 'class-transformer';
import { AuditMetadata } from '@core/model/auditMetadata.model';
import {
  ComponentEntryComplexityRole
} from '@app/features/company-management/models/component-entry-complexity-role.model';

export enum ScenarioModifierType {
  PRICE_MODIFIER = 'PRICE_MODIFIER',
  QUANTITY_MODIFIER = 'QUANTITY_MODIFIER'
}

export class ComponentModifier {
  id!: number
  name!: string
  type!: ScenarioModifierType
  formula!: string
  scenario!: Scenario
  @Type(() => ComponentEntryComplexityRole) complexityRoles?: ComponentEntryComplexityRole[]
  @Type(() => AuditMetadata) auditMetadata!: AuditMetadata
}

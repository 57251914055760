import { Tab, TabDefinition, TabUtil, Tabs } from '@shared/utils/tabs-util.service'

import { AuthService } from '@core/service/auth.service'
import { Component } from '@angular/core'
import { DataTab } from './enums'
import { EnvService } from '@envs/env-service'
import { LanguageService } from '@core/service/language.service'
import { Privilege } from '@core/model/enums/privilege.enum'
import { User } from '@core/model/user.model'
import { UserRoleLevel } from '@app/core/model/enums/user-role-level.enum'
import { environment } from '@envs/environment'

@Component({
  selector: 'manage-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss'],
})
export class DataComponent {
  loggedInUser!: User
  tabs: Tabs
  baseJsUrl: string
  globalTabs: TabDefinition[] = [
    {
      key: DataTab.DELIVERABLES,
      name: this.lang.get('deliverable.p'),
      icon: 'description',
    },
    {
      key: DataTab.DISCIPLINES,
      name: this.lang.get('discipline.p'),
      icon: 'schema',
    },
    {
      key: DataTab.TASKS,
      name: 'Tasks',
      privileges: [Privilege.LIBRARY_DELIVERABLE__VIEW],
      icon: 'task_alt',
    },
    {
      key: DataTab.FEES,
      name: 'Fees',
      privileges: [Privilege.FEE_ITEM__VIEW],
      settings: ['FEE_ITEM__ENABLE'],
      icon: 'request_quote',
    },
    {
      key: DataTab.COST_PLUS,
      name: 'Cost plus',
      settings: ['COST_PLUS'],
      icon: 'compare_arrows',
    },
    {
      key: DataTab.THIRDPARTYCOSTS,
      name: this.lang.get('third_party_cost.p'),
      icon: 'request_quote',
    },
    {
      key: DataTab.UNIT_TYPES,
      name: 'Unit Types',
      icon: 'straighten',
      subTab: true,
    },
    {
      key: DataTab.FORMULAS,
      name: 'Formulas',
      icon: 'calculate',
      subTab: true,
    },
    {
      key: DataTab.RATECARD_STRUCTURE,
      name: 'Ratecard Structure',
      privileges: [Privilege.RATECARD_STRUCTURE__VIEW],
      icon: 'demography',
    },
    {
      key: DataTab.RATECARD_LOCATIONS,
      name: 'Ratecard Location',
      settings: ['RATECARD_LOCATION__ENABLE'],
      icon: 'location_on',
    },
    {
      key: DataTab.SCOPE_FIELDS,
      name: 'Scope Fields',
      settings: ['SCOPE_DYNAMIC_FIELDS'],
      icon: 'data_table',
      roles: [UserRoleLevel.SYSTEM, UserRoleLevel.ADMIN],
    },
  ]

  public get isOldApp() {
    return !this.tabs.get(DataTab.TASKS)?.isSelected() && !this.tabs.get(DataTab.SCOPE_FIELDS)?.isSelected()
  }

  constructor(
    private authService: AuthService,
    private lang: LanguageService,
    private tabUtils: TabUtil,
    private envService: EnvService
  ) {
    this.loggedInUser = this.authService.loggedInUser
    this.baseJsUrl = `${this.envService.baseJsUrl}/admin?host=${environment.baseHost}&childCompany=${this.loggedInUser.company.id}&tab=data&subTab=deliverables`
  }

  ngOnInit() {
    this.tabs = this.tabUtils.createTabs(this.globalTabs, this.loggedInUser)
  }

  isSelected = (tab: DataTab) => this.tabs.get(tab)?.isSelected()

  onSelect(tab: Tab) {
    tab.switch({}, this.tabUtils.shouldDisableTabSwitching)
    this.baseJsUrl = `${this.envService.baseJsUrl}/admin?host=${environment.baseHost}&childCompany=${
      this.loggedInUser.company.id
    }&tab=data&subTab=${tab.key.toLowerCase()}`
  }

  protected readonly DataTab = DataTab
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '@core/model/user.model';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { AuthService } from '@core/service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '@core/service/language.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  SNACKBAR_LENGTH_LONG,
  SNACKBAR_LENGTH_SHORT,
  SnackbarEventType,
  SnackbarService,
} from '@shared/utils/snackbar.service';
import { MenuOptions } from '@core/model/definitions/menu-options.interface';
import { map, Observable, take } from 'rxjs';
import { MappedEntity } from '@app/features/scoping/models/mapped-entity.model';
import { Store } from '@ngrx/store';
import {
  CompanyManagementSelectors,
} from '@app/features/company-management/store/selectors/company-management.selectors';
import { CompanyManagementActions } from '@app/features/company-management/store/actions/company-management.actions';
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service';
import { FormControl } from '@angular/forms';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model';
import { plainToInstance } from 'class-transformer';
import { ScenarioCategoryService } from '@app/features/company-management/service/scenario-category.service';
import { ScopeTabService } from '@app/features/scope-overview/service/scope-tab.service';
import {
  AddEditQuestionModalComponent,
  AddEditQuestionModalConfig,
} from '@app/features/company-management/components/add-question-modal/add-edit-question-modal.component';
import { ScenarioQuestionService } from '@app/features/company-management/service/scenario-question.service';
import { QuestionType } from '@core/model/enums/question-type.enum';
import { filter } from 'rxjs/operators';
import { FormulaService } from '@shared/services/formula.service';
import {
  scenarioCategoryColumnsBaseConfig,
  scenarioQuestionsColumnsBaseConfig,
} from '@app/features/company-management/store/models/scenario';
import { ScopeUiTableSelectMode } from '@app/shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model';
import {
  AddDeliverablePromptModalComponent,
  AddDeliverablePromptModalConfig,
} from '@app/features/company-management/components/add-deliverable-prompt-modal/add-deliverable-prompt-modal.component';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { ComponentModifier } from '@app/features/company-management/models/component-modifier.model';
import { untilDestroyed } from '@shared/utils/utils';
import {
  AddEditCategoryModalConfig, AddEditScenarioCategoryModalComponent,
} from '@app/features/company-management/components/add-scenario-category-modal/add-edit-scenario-category-modal.component';
import {
  AddFeePromptModalComponent,
  AddFeePromptModalConfig,
} from '@app/features/company-management/components/add-fee-prompt-modal/add-fee-prompt-modal.component';
import { searchInText } from '@app/shared/utils/search-utils.const';

@Component({
  selector: 'scenario',
  templateUrl: './scenario.component.html',
  styleUrls: ['./scenario.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioComponent {
  @Input() id!: number;

  private readonly destroy$
  loggedInUser!: User;
  scenario$: Observable<Scenario>;
  scenario: Scenario;
  loading$: Observable<boolean>;
  modifiers$: Observable<ComponentModifier[]>;
  modifiers: ComponentModifier[];
  mappedCategoriesDataSource$!: Observable<MappedEntity<ScenarioCategory>[]>;
  editScenarioName: boolean = false;
  searchText = '';
  categoryPreferenceColumns = scenarioCategoryColumnsBaseConfig
  questionsPreferenceColumns = scenarioQuestionsColumnsBaseConfig
  categoryMenuOptions: MenuOptions[];
  questionsMenuOptions: MenuOptions[];
  tab: string = "Configuration form";

  constructor(private store: Store,
              private authService: AuthService,
              private mappingService: DataMappingService,
              private scenarioCategoryService: ScenarioCategoryService,
              public scopeTabService: ScopeTabService,
              public route: ActivatedRoute,
              public router: Router,
              private lang: LanguageService,
              private dialog: MatDialog,
              private snackbarService: SnackbarService,
              private scenarioQuestionService: ScenarioQuestionService,
              private manageService: CompanyManagementService,
              private formulaService: FormulaService) {
    this.destroy$ = untilDestroyed()
    this.loggedInUser = this.authService.loggedInUser;
    this.scenario$ = this.store.select(CompanyManagementSelectors.selectScenario);
    this.modifiers$ = this.store.select(CompanyManagementSelectors.selectModifiers);
    this.loading$ = this.store.select(CompanyManagementSelectors.selectLoadingScenario);

    this.scenario$.pipe(this.destroy$()).subscribe((scenario: Scenario) => this.scenario = scenario);
    this.modifiers$.pipe(this.destroy$()).subscribe((modifiers: ComponentModifier[]) => this.modifiers = modifiers);
  }

  ngOnInit(): void {
    this.initScenario();
    this.mapCategoriesConfigToDataSource();
    this.initScenarioMenuOptions();
    this.initQuestionMenuOptions();
  }

  initScenario() {
    this.store.dispatch(CompanyManagementActions.getScenario({ scenarioId: this.id }));
    this.store.dispatch(CompanyManagementActions.getComponentModifiers({ scenarioId: this.id }));
  }

  mapCategoriesConfigToDataSource() {
    this.mappedCategoriesDataSource$ = this.scenario$.pipe(
      filter((scenario) => scenario !== undefined),
      map((scenario) => {
        const sortedCategories = [...scenario.categories]
          .sort((c1, c2) => c1.orderIndex - c2.orderIndex);
        return this.mappingService.transformArray(sortedCategories, this.categoryPreferenceColumns);
      })
    );
  }

  renameScenario(name: any, scenario: Scenario) {
    this.editScenarioName = false;
    let updatedScenario = plainToInstance(Scenario, { ...scenario, name });
    this.store.dispatch(CompanyManagementActions.updateScenario({ id: this.id, scenario: updatedScenario }));
  }

  filterCategories() {
    const searchFields = ['displayText', 'fieldLabel', 'fieldId', 'type']
    const searchFn = (str: string) => searchInText(str, this.searchText)

    const filteredCategories$ = this.scenario$.pipe(
      map(scenario => scenario.categories
        .map(category => ({
          ...category,
          questions: searchFn(category.name)
            ? category.questions
            : category.questions.filter(question => searchFields.find(field => searchFn(question[field])))
        }))
        .filter(category => searchFn(category.name) || category.questions.length),
      ),
    );

    this.mappedCategoriesDataSource$ = filteredCategories$.pipe(
      map(filteredCategories =>
        this.mappingService.transformArray(filteredCategories, this.categoryPreferenceColumns),
      ),
    );
  }

  openCreateModal = () => {
    const modalConfig: AddEditCategoryModalConfig = {
      scenario: this.scenario,
      modifiers: this.modifiers,
      confirmCallback: (category: any) => {
        this.store.dispatch(CompanyManagementActions.createScenarioCategory({ scenarioId: this.id, category: category }))
        this.store.select(CompanyManagementSelectors.selectLoadingAddUpdateScenarioCategory).pipe(
          filter((loading) => !loading),
          take(1)
        ).subscribe(() => {
          dialog.close()
        })
      }
    }
    let dialog = this.dialog.open(AddEditScenarioCategoryModalComponent, { data: modalConfig })
  }

  initScenarioMenuOptions() {
    this.categoryMenuOptions = [
      {
        callback: (element) => this.insertQuestion(element.entity, QuestionType.TEXT),
        icon: () => 'insert_text',
        name: () => 'Question with Text Answer',
      },
      {
        callback: (element) => this.insertQuestion(element.entity, QuestionType.NUMBER),
        img: () => '/assets/icons/insert-number.svg',
        name: () => 'Question with Number Answer',
      },
      {
        callback: (element) => this.insertQuestion(element.entity, QuestionType.DATE),
        icon: () => 'calendar_today',
        name: () => 'Question with Date Answer',
        hasBorder: true
      },
      {
        callback: (element) => this.insertQuestion(element.entity, QuestionType.DROPDOWN),
        icon: () => 'checklist',
        name: () => 'Question with Dropdown Options Answer'
      },
      {
        callback: (element) => this.insertQuestion(element.entity, QuestionType.FORMULA),
        icon: () => 'calculate',
        name: () => 'Question with Calculated Answer',
        hasBorder: true
      },
      {
        callback: (element) => this.insertDeliverablePrompt(element.entity),
        icon: () => 'description',
        name: () => `${this.lang.get('deliverable')} prompt`
      },
      {
        callback: (element) => this.insertFeePrompt(element.entity),
        icon: () => 'attach_money',
        name: () => `Fee prompt`,
        hasBorder: true
      },
      {
        callback: (element) => this.editCategory(element.entity),
        icon: () => 'edit',
        name: () => 'Edit Category',
      },
      {
        callback: (element) => this.deleteCategory(element.entity),
        icon: () => 'delete',
        name: () => 'Delete Category',
      },
    ];
  }

  editCategory(scenarioCategory: ScenarioCategory) {
    const modalConfig: AddEditCategoryModalConfig = {
      category: scenarioCategory,
      scenario: this.scenario,
      modifiers: this.modifiers,
      confirmCallback: (category: any) => {
        this.store.dispatch(CompanyManagementActions.updateScenarioCategory({ scenarioId: this.id, categoryId: scenarioCategory.id, category: category }))
        this.store.select(CompanyManagementSelectors.selectLoadingAddUpdateScenarioCategory).pipe(
          filter((loading) => !loading),
          take(1)
        ).subscribe(() => {
          dialog.close()
        })
      }
    }
    let dialog = this.dialog.open(AddEditScenarioCategoryModalComponent, { data: modalConfig })
  }

  deleteCategory(category: ScenarioCategory) {
    let {questionDependencies, modifierDependencies} =
      this.formulaService.getReferencedFieldsForCategory(category, this.scenario, this.modifiers)
    if (questionDependencies.length || modifierDependencies.length) {
      let modalConfig = {
        title: `Category Has Dependencies`,
        body: `Update or delete the following questions/modifiers to remove the dependencies, then try again:\n\n` +
          (questionDependencies.length ?
            'Questions:\n' + questionDependencies.map((d) => `\u2022 ${d.categoryName} > ${d.displayText}\n`).join('') : '') +
          (modifierDependencies.length ?
            'Modifiers:\n' + modifierDependencies.map((d) => `\u2022 ${d.name}\n`).join('') : ''),
        confirmText: 'Ok',
        confirmCallback: () => {
          dialog.close();
        },
        cancelCallback: undefined,
        inputs: [],
        isMemberModal: undefined,
        limitBodyWidth: true
      };
      let dialog = this.dialog.open(ScopeUiModalComponent, { data: modalConfig });
    } else {
      const categoryDeleteModalConfig: ModalConfig = {
        title: `Delete Category`,
        body: `Are you sure you want to delete ${category.name}?`,
        confirmText: 'Delete',
        confirmCallback: () => {
          this.store.dispatch(CompanyManagementActions.deleteScenarioCategory({
            scenarioId: this.id,
            categoryId: category.id,
          }));
          dialog.close();
        },
        cancelCallback: undefined,
        inputs: [],
        isMemberModal: undefined,
        limitBodyWidth: true,
      };
      let dialog = this.dialog.open(ScopeUiModalComponent, { data: categoryDeleteModalConfig });
    }
  }

  reorderCategory(categories: ScenarioCategory[]) {
    let orderedCategoryIds = categories.map(c => c.id);
    this.store.dispatch(CompanyManagementActions.reorderScenarioCategories({
      scenarioId: this.id,
      orderedCategoryIds: orderedCategoryIds,
    }));
  }

  reorderQuestion(questions: ScenarioQuestion[], category: ScenarioCategory) {
    let orderedQuestionIds = questions.map(q => q.id);
    this.store.dispatch(CompanyManagementActions.reorderScenarioQuestions({
      scenarioId: this.id,
      categoryId: category.id,
      orderedQuestionIds: orderedQuestionIds,
    }));
  }

  insertDeliverablePrompt(category: ScenarioCategory) {
    const config: AddDeliverablePromptModalConfig = {
      scenario: this.scenario,
      confirmCallback: (question: any) => {
        this.scenarioQuestionService.create(this.id, category.id, question).subscribe({
          next: (result) => {
            dialog.close();
            this.store.dispatch(CompanyManagementActions.createScenarioQuestionSuccess({
              categoryId: category.id,
              question: result,
            }));
            this.snackbarService.showSnackbar(`Scenario {LANG:deliverable} Prompt was successfully created.`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS);
          },
          error: () => {
            this.snackbarService.showDefaultErrorSnackbar();
          },
        });
      },
    };
    let dialog = this.dialog.open(AddDeliverablePromptModalComponent, {
      data: config,
    });
  }

  insertFeePrompt(category: ScenarioCategory) {
    const config: AddFeePromptModalConfig = {
      scenario: this.scenario,
      confirmCallback: (question: any) => {
        this.scenarioQuestionService.create(this.id, category.id, question).subscribe({
          next: (result) => {
            dialog.close();
            this.store.dispatch(CompanyManagementActions.createScenarioQuestionSuccess({
              categoryId: category.id,
              question: result,
            }));
            this.snackbarService.showSnackbar(`Scenario Fee Prompt was successfully created.`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS);
          },
          error: () => {
            this.snackbarService.showDefaultErrorSnackbar();
          },
        });
      },
    };
    let dialog = this.dialog.open(AddFeePromptModalComponent, {
      data: config,
    });
  }

  insertQuestion(category: ScenarioCategory, type: QuestionType) {
    const modalConfig: AddEditQuestionModalConfig = {
      type: type,
      category: category,
      scenario: this.scenario,
      modifiers: this.modifiers,
      confirmCallback: (question: any, fieldIDControl: FormControl) => {
        this.store.dispatch(CompanyManagementActions.createScenarioQuestion({ scenarioId: this.id, categoryId: category.id, question: question }))
        this.store.select(CompanyManagementSelectors.selectLoadingAddUpdateScenarioQuestion).pipe(
          filter((loading) => !loading),
          take(1)
        ).subscribe(() => {
          this.store.select(CompanyManagementSelectors.selectCreateQuestionError).pipe(
            take(1)
          ).subscribe((error) => {
            if (error) {
              if (error.status == 409) {
                fieldIDControl.setErrors({ conflict: true })
              } else {
                this.snackbarService.showDefaultErrorSnackbar()
              }
            } else {
              this.snackbarService.showSnackbar(`Scenario Question was successfully created.`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
              dialog.close()
            }
          })
        })
      }
    };
    let dialog = this.dialog.open(AddEditQuestionModalComponent, { data: modalConfig })
  }

  initQuestionMenuOptions() {
    this.questionsMenuOptions = [
      {
        name: () => 'Edit',
        icon: () => 'edit',
        callback: this.editQuestion,
      },
      {
        name: () => 'Delete',
        icon: () => 'delete',
        callback: this.deleteQuestion,
      }
    ];
  }

  updateQuestion(categoryId: number, questionId: number, question: ScenarioQuestion, dialog: MatDialogRef<any>) {
    this.store.dispatch(CompanyManagementActions.updateScenarioQuestion({ scenarioId: this.id, categoryId: categoryId, questionId: questionId, question: question }))
    this.store.select(CompanyManagementSelectors.selectLoadingAddUpdateScenarioQuestion).pipe(
      filter((loading) => !loading),
      take(1)
    ).subscribe(() => {
      this.snackbarService.showSnackbar(`Scenario Question was successfully updated.`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
      dialog.close()
    })
  }

  editQuestion = ({ entity }: MappedEntity<ScenarioQuestion>, category) => {
    if (entity.type === QuestionType.DELIVERABLE) {
      const modalConfig: AddDeliverablePromptModalConfig = {
        question: entity,
        scenario: this.scenario,
        confirmCallback: (question: any) => this.updateQuestion(category.id, question.id, question, dialog)
      };
      let dialog = this.dialog.open(AddDeliverablePromptModalComponent, { data: modalConfig })
    } else if (entity.type === QuestionType.FEE) {
      const modalConfig: AddFeePromptModalConfig = {
        question: entity,
        scenario: this.scenario,
        confirmCallback: (question: any) => this.updateQuestion(category.id, question.id, question, dialog)
      };
      let dialog = this.dialog.open(AddFeePromptModalComponent, { data: modalConfig })
    } else {
      const modalConfig: AddEditQuestionModalConfig = {
        type: entity.type,
        question: entity,
        category: category,
        modifiers: this.modifiers,
        scenario: this.scenario,
        confirmCallback: (question: any) => this.updateQuestion(category.id, question.id, question, dialog)
      };
      let dialog = this.dialog.open(AddEditQuestionModalComponent, { data: modalConfig })
    }
  }

  deleteQuestion = ({ entity }: MappedEntity<ScenarioQuestion>, category) => {
    if (entity.type !== QuestionType.DELIVERABLE && entity.type !== QuestionType.FEE) {
      let {questionDependencies, modifierDependencies} =
        this.formulaService.getReferencedFieldsForQuestion(entity.fieldId, this.scenario, this.modifiers)
      if (questionDependencies.length || modifierDependencies.length) {
        let modalConfig = {
          title: `Question Has Dependencies`,
          body: `Update or delete the following questions/modifiers to remove the dependencies, then try again:\n\n` +
            (questionDependencies.length ?
              'Questions:\n' + questionDependencies.map((d) => `\u2022 ${d.categoryName} > ${d.displayText}\n`).join('') : '') +
            (modifierDependencies.length ?
              'Modifiers:\n' + modifierDependencies.map((d) => `\u2022 ${d.name}\n`).join('') : ''),
          confirmText: 'Ok',
          confirmCallback: () => {
            dialog.close();
          },
          cancelCallback: undefined,
          inputs: [],
          isMemberModal: undefined,
          limitBodyWidth: true
        };
        let dialog = this.dialog.open(ScopeUiModalComponent, { data: modalConfig });
        return;
      }
    }

    let modalConfig = {
      title: `Delete Question`,
      body: `Are you sure you want to delete this question?`,
      confirmText: 'Delete',
      confirmCallback: () => {
        this.store.dispatch(CompanyManagementActions.deleteScenarioQuestion({
          scenarioId: this.id,
          categoryId: category.id,
          questionId: entity.id,
        }));
        dialog.close();
      },
      cancelCallback: undefined,
      inputs: [],
      isMemberModal: undefined,
      limitBodyWidth: true,
    };
    let dialog = this.dialog.open(ScopeUiModalComponent, { data: modalConfig });
  }

  backToScenarios() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { 'tab': 'scenarios' }
      }
    )
  }

  checkCategoryDependencies = (data: MappedEntity<ScenarioCategory>[], previousIndex: number, currentIndex: number) => {
    if (currentIndex > previousIndex) {
      let fieldIds = data[previousIndex].entity.questions.flatMap((q) => q.fieldId)
      if (data.slice(previousIndex+1, currentIndex+1).flatMap((c) => c.entity.questions).find((q) => {
          return (q.formula && this.formulaService.getReferencedFields(q.formula).some((f) => fieldIds.includes(f))) ||
            (q.displayCondition && this.formulaService.getReferencedFields(q.displayCondition).some((f) => fieldIds.includes(f)))
        })) {
        this.snackbarService.showSnackbar(`This category cannot be moved here as it contains questions that would be referenced in preceding categories.`, SNACKBAR_LENGTH_LONG, SnackbarEventType.WARNING)
        return false
      }
    } else if (currentIndex < previousIndex) {
      let fieldIds = data.slice(currentIndex, previousIndex)
        .flatMap((c) => c.entity.questions.flatMap((q) => q.fieldId))
      if (data[previousIndex].entity.questions.find((q) => {
        return (q.formula && this.formulaService.getReferencedFields(q.formula).some((f) => fieldIds.includes(f))) ||
          (q.displayCondition && this.formulaService.getReferencedFields(q.displayCondition).some((f) => fieldIds.includes(f)))
      })) {
        this.snackbarService.showSnackbar(`This category cannot be currently moved here as it cannot refer to questions in subsequent categories.`, SNACKBAR_LENGTH_LONG, SnackbarEventType.WARNING)
        return false
      }
    }
    return true
  }

  checkQuestionDependencies = (data: MappedEntity<ScenarioQuestion>[], previousIndex: number, currentIndex: number) => {
    if (currentIndex > previousIndex) {
      let fieldId = data[previousIndex].entity.fieldId
      if (data.slice(previousIndex+1, currentIndex+1).find(({ entity }) => {
          return (entity.formula && this.formulaService.getReferencedFields(entity.formula).some((f) => fieldId == f)) ||
            (entity.displayCondition && this.formulaService.getReferencedFields(entity.displayCondition).some((f) => fieldId == f))
        })) {
        this.snackbarService.showSnackbar(`This question cannot be moved here as it would referenced by preceding questions.`, SNACKBAR_LENGTH_LONG, SnackbarEventType.WARNING)
        return false
      }
    } else if (currentIndex < previousIndex) {
      let question = data[previousIndex].entity
      let fieldIds = data.slice(currentIndex, previousIndex).flatMap(({ entity }) => entity.fieldId)
      if ((question.formula && this.formulaService.getReferencedFields(question.formula).some((f) => fieldIds.includes(f))) ||
          (question.displayCondition && this.formulaService.getReferencedFields(question.displayCondition).some((f) => fieldIds.includes(f)))
      ) {
        this.snackbarService.showSnackbar(`This question cannot be currently moved here as it cannot refer to subsequent questions.`, SNACKBAR_LENGTH_LONG, SnackbarEventType.WARNING)
        return false
      }
    }
    return true
  }

  protected readonly ScopeUiTableSelectMode = ScopeUiTableSelectMode;
}

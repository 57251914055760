import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { LibraryThirdPartyCostSelectors } from '@app/features/library-management/store'
import { Store, select } from '@ngrx/store'

import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LibraryModalConfig } from '@app/features/library-management/models'
import { LibraryThirdPartyCostActions } from '@app/features/library-management/store/actions/library-third-party-cost.actions'

@Component({
  templateUrl: './library-create-tpc-modal.component.html',
})
export class LibraryCreateTPCModalComponent {
  groups$ = this.store$.pipe(select(LibraryThirdPartyCostSelectors.selectGroups))
  formulas$ = this.store$.pipe(select(LibraryThirdPartyCostSelectors.selectFormulas))

  modalForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    group: new FormControl(null),
    formula: new FormControl(null),
    unitCost: new FormControl('', [Validators.required]),
    markup: new FormControl(''),
    sellingPrice: new FormControl('', [Validators.required]),
  })

  constructor(@Inject(MAT_DIALOG_DATA) public data: LibraryModalConfig, private store$: Store) {
    this.store$.dispatch(LibraryThirdPartyCostActions.getGroups())
    this.store$.dispatch(LibraryThirdPartyCostActions.getFormulas())
  }

  getControl = (key: string) => this.modalForm.get(key) as FormControl
  getControlValue = (key: string) => this.getControl(key).value
  setControlValue = (key: string, value: any) => this.getControl(key).setValue(value)

  submit() {
    this.modalForm.markAllAsTouched()
    if (this.modalForm.valid) this.data.onSubmit(this.modalForm.value)
  }

  onInputChange(markup: number, unitCost: number) {
    markup = +markup
    unitCost = +unitCost
    this.setControlValue('sellingPrice', !markup ? unitCost : unitCost ? (markup / 100) * unitCost + unitCost : null)
  }

  onSellingPriceChange(value: number) {
    var unitCost = +this.getControlValue('unitCost')
    var diff = value - unitCost
    this.setControlValue('markup', (diff / unitCost) * 100)
  }
}

import { createAction, props } from '@ngrx/store'

import { LibraryComponent } from '@app/features/library-management/store/models/component'
import { LibraryCreateComponentActionPayload } from '@app/features/library-management/store/models/modals'

export enum LibraryCreateComponentActionTypes {
  CREATE_COMPONENT = '[Library Management] Create Component',
  CREATE_COMPONENT_FAIL = '[Library Management] Create Component Fail',
  CREATE_COMPONENT_SUCCESS = '[Library Management] Create Component Success',

  NAVIGATE_TO_COMPONENT_OVERVIEW = '[Library Management] Navigate to Component Overview',
}

const createComponent = createAction(
  LibraryCreateComponentActionTypes.CREATE_COMPONENT,
  props<LibraryCreateComponentActionPayload>()
)
const createComponentFail = createAction(
  LibraryCreateComponentActionTypes.CREATE_COMPONENT_FAIL,
  props<{ error: Error }>()
)
const createComponentSuccess = createAction(
  LibraryCreateComponentActionTypes.CREATE_COMPONENT_SUCCESS,
  props<{ component: LibraryComponent }>()
)

const navigateToComponentOverview = createAction(
  LibraryCreateComponentActionTypes.NAVIGATE_TO_COMPONENT_OVERVIEW,
  props<{ id: number; entryId?: number }>()
)

export const LibraryCreateComponentActions = {
  createComponent,
  createComponentFail,
  createComponentSuccess,
  navigateToComponentOverview,
}

import { Injectable } from '@angular/core';
import { environment } from '@envs/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs';
import { plainToInstance } from 'class-transformer';
import { ScopeConfiguration, ScopeVersion } from '@core/model/scope-version';
import { ScopeRetainedHoursStats } from '@app/features/scope-overview/model/scope-retained-hours-stats.model';
import { SowRetainedHoursStats } from '@app/features/scope-overview/model/sow-retained-hours-stats.model';
import { Money } from '@app/features/scope-overview/model/money.model';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { Deliverable, TradedDeliverable } from '@app/features/scoping/models/deliverable.model';
import { Privilege } from '@core/model/enums/privilege.enum';
import { User } from '@core/model/user.model';
import { ScopeTeamMember } from '@core/model/scope-team.model';
import { ScopeSection } from '@app/features/scope-overview/model/scope-section';
import { SecondParty } from '@core/model/second-party.model';
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { RefreshCustomFieldsResponse } from '@app/features/scope-overview/model/refresh-custom-fields-response.interface';
import { ScopeOverviewFilter } from '@core/model/scope-overview-filter.model';
import { ScopeComponent } from '@app/features/scoping/models/component.model';
import { Department } from '@app/features/scoping/models/department.model';
import { Role } from '@app/features/scoping/models/role.model';
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model';
import { BreakdownSummary } from '@app/features/scope-overview/model/breakdown-summary.model';
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model';
import { SowRetainedTeamStats } from '@app/features/scope-overview/model/sow-retained-team-stats.model';
import { DeliverableTradePayload } from '../components/track-trade-modal/track-trade-modal.component'
import { Discount } from '@core/model/discount.model'
import { ScopeMsaLineItem } from '@core/model/scope-msa-line-item.model'
import { Discipline } from '@app/features/scope-overview/model/discipline.model'
import { DeliverableType } from '@app/features/scope-overview/model/deliverable-type'
import { DeliverableSection } from '@app/features/scope-overview/model/deliverable-section'
import { GenericFilterValue, TypedFilterValue } from '@core/model/filter-option.interface'
import { NewTask } from '@core/model/new-task.model';
import { ThirdPartyCostGroup } from '@app/features/scoping/models/third-party-cost-group.model';
import { ThirdPartyCostFormula } from '@app/core/model/third-party-cost-formula.model';

@Injectable({
  providedIn: 'root',
})
export class ScopeOverviewService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl;

  constructor(private http: HttpClient) {}

  getScope(id: number | string): Observable<ScopeVersion> {
    return this.http.get<any>(`${this.BASE_API_URL}scope/` + id).pipe(
      map((api) => plainToInstance(ScopeVersion, api))
    );
  }

  getScopeVersionById(id: number | string, versionId: number | string): Observable<ScopeVersion> {
    return this.http
      .get<any>(`${this.BASE_API_URL}scope/` + id + `?version=` + versionId)
      .pipe(map((api) => plainToInstance(ScopeVersion, api)));
  }

  getScopeThirdPartyCostBalanceById(id: number | string): Observable<Money> {
    return this.http
      .get<any>(`${this.BASE_API_URL}scope/` + id + `/third-party-cost/balance`)
      .pipe(map((api) => plainToInstance(Money, api)));
  }

  getScopeRetainedHoursStats(sowId: number | string, scopeId: number | string): Observable<ScopeRetainedHoursStats> {
    return this.http
      .get<any>(`${this.BASE_API_URL}sow/` + sowId + `/` + scopeId + `/retained-hours-stats`)
      .pipe(map((api) => plainToInstance(ScopeRetainedHoursStats, api)));
  }

  updateScopeRetainedHoursStats(sowId: number | string, scopeId: number | string, useRetainedHours: boolean) {
    return this.http
      .put<any>(`${this.BASE_API_URL}sow/` + sowId + `/` + scopeId + `/retained-hours-stats`, { useRetainedHours: useRetainedHours })
      .pipe(map((api) => plainToInstance(ScopeRetainedHoursStats, api)));
  }

  getSowRetainedHoursStats(sowId: number | string): Observable<SowRetainedHoursStats> {
    return this.http
      .get<any>(`${this.BASE_API_URL}sow/` + sowId + `/retained-hours-stats`)
      .pipe(map((api) => plainToInstance(SowRetainedHoursStats, api)));
  }

  getScopeTemplate(scopeId: number | string): Observable<OutputTemplate> {
    return this.http
      .get<any>(`${this.BASE_API_URL}scope/` + scopeId + `/scope-template`)
      .pipe(map((api) => plainToInstance(OutputTemplate, api)));
  }

  getXlsxTemplate(templateId: number | string): Observable<any> {
    return this.http
      .get<any>(`${this.BASE_API_URL}xlsx-templates/` + templateId)
  }

  exportScopeToXlsxTemplate(scopeId: number | string): Observable<any> {
    return this.http
      .get(`${this.BASE_API_URL}scope/${scopeId}/export/xlsx-template`)
  }

  updateCompanyCustomOutputTemplateFromScope(template: OutputTemplate) {
    return this.http
      .put(`${this.BASE_API_URL}company/custom-output-template-scope`, template)
      .pipe(map((api) => plainToInstance(OutputTemplate, api)));
  }

  previewScopeDocx(scopeId: number) {
    return this.http
      .get(`${this.BASE_API_URL}scope/${scopeId}/preview/docx`, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          let contentDisposition = response.headers.get('content-disposition');
          let name = contentDisposition.split('name="')[1].split('"')[0];
          let filename = contentDisposition.split('filename="')[1].split('"')[0];
          return { data: response.body, name: name, filename: filename };
        })
      );
  }

  downloadExport(templateId: number, downloadFileName: string) {
    let url = `${this.BASE_API_URL}scope/${templateId}/export/download`;
    if (downloadFileName) {
      url += `?downloadFileName=${downloadFileName}`;
    }
    return this.http
      .get(url, {
        responseType: 'blob',
        observe: 'response',
      })
      .subscribe({
        next: (res: any) => {
          if (res.body != null) {
            const a = document.createElement('a');
            const fileName = res.headers.get('content-disposition');
            a.href = URL.createObjectURL(res.body);
            if (fileName != null) {
              a.download = fileName.split('filename=')[1].split(';')[0];
            }
            a.click();
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  updateScopeDeliverable(scopeId: number | string, deliverable: Deliverable) {
    return this.http
      .put(`${this.BASE_API_URL}scope/` + scopeId + `/deliverable/` + deliverable.id, deliverable)
      .pipe(map((api) => plainToInstance(Deliverable, api)));
  }

  updateScopeComponent(scopeId: number | string, component: ScopeComponent, approvalOverride: boolean) {
    return this.http
      .put(`${this.BASE_API_URL}scope/${ scopeId }/deliverable/${ component.deliverable.id }/component/${ component.id }${approvalOverride ? '?approvalOverride=true' : ''}`,
        component
      ).pipe(map((api) => plainToInstance(ScopeComponent, api)));
  }

  updateDeliverableSection(scopeId: number | string, section: DeliverableSection) {
    return this.http
      .put(`${this.BASE_API_URL}scope/${ scopeId }/deliverable/${ section.deliverableId }/section/${ section.id }`,
        section
      ).pipe(map((api) => plainToInstance(DeliverableSection, api)));
  }

  updateScopeDeliverableOrder(scopeId: number | string, request: Object[]) {
    return this.http.put(`${this.BASE_API_URL}scope/${scopeId}/deliverable/order`, request);
  }

  updateScopeSectionOrder(scopeId: number | string, request: Object[]) {
    return this.http.put(`${this.BASE_API_URL}scope/${scopeId}/sections/order`, request);
  }

  updateFinancialsOrder(scopeId: number | string, request: string) {
    return this.http.put(`${this.BASE_API_URL}scope/${scopeId}/financials/order`, request);
  }

  updateComponentRole(scopeId, componentId, role, approvalOverride) {
    return this.http
      .put(`${this.BASE_API_URL}scope/${ scopeId }/component/${ componentId }/role/${ role.id }${approvalOverride ? '?approvalOverride=true' : ''}`,
        role
      ).pipe(map((api) => plainToInstance(Role, api)));
  };

  getScopeDeliverable(scopeId: number | string, deliverableId: number) {
    return this.http
      .get(`${this.BASE_API_URL}scope/` + scopeId + `/deliverable/` + deliverableId)
      .pipe(map((api) => plainToInstance(Deliverable, api)));
  }

  getAllTasks(scopeId: number, scopeVersion?: number) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/deliverables?${scopeVersion ? `version=${scopeVersion}` : ''}`
    return this.http
      .get<Deliverable[]>(url)
      .pipe(map((api) => plainToInstance(Deliverable, api)));
  }

  addScopeSection(id: number, section: ScopeSection): Observable<ScopeSection> {
    return this.http
      .post<ScopeSection>(`${this.BASE_API_URL}scope/` + id + `/section`, section)
      .pipe(map((api) => plainToInstance(ScopeSection, api)));
  }

  deleteScopeSection(id: number, sectionId: number): Observable<ScopeSection> {
    return this.http
      .delete<ScopeSection>(`${this.BASE_API_URL}scope/` + id + `/section/` + sectionId)
      .pipe(map((api) => plainToInstance(ScopeSection, api)));
  }

  updateScopeSection(id: number, section: ScopeSection): Observable<ScopeSection> {
    return this.http
      .put<ScopeSection>(`${this.BASE_API_URL}scope/` + id + `/section/` + section.id, section)
      .pipe(map((api) => plainToInstance(ScopeSection, api)));
  }

  duplicateScopeDeliverable(id: number, deliverableId: number): Observable<Deliverable> {
    return this.http
      .post(`${this.BASE_API_URL}scope/${id}/deliverable/${deliverableId}/duplicate`, {})
      .pipe(map((api) => plainToInstance(Deliverable, api)));
  }

  moveComponentToDeliverable(scopeId: number, componentId: number, deliverableId: number, name: string, order: number): Observable<Deliverable> {
    return this.http
      .put(`${this.BASE_API_URL}scope/${scopeId}/component/${componentId}/move?name=${name}&order=${order}&deliverableId=${deliverableId}`, {})
      .pipe(map((api) => plainToInstance(Deliverable, api)));
  }

  deleteScopeDeliverable(id: number, deliverableId: number): Observable<Deliverable> {
    return this.http
      .delete<Deliverable>(`${this.BASE_API_URL}scope/` + id + `/deliverable/` + deliverableId)
      .pipe(map((api) => plainToInstance(Deliverable, api)));
  }

  getAvailableScopeTeamMembersWithPrivilege(id: number, privilege: Privilege, includePermissions: boolean = false): Observable<User[]> {
    return this.http
      .get<User[]>(`${this.BASE_API_URL}scope/` + id + `/available-members?privilege=${privilege}&includePermissions=${includePermissions}`)
      .pipe(map((api) => plainToInstance(User, api)));
  }

  addScopeCollaborator(id: number, users: number[]): Observable<ScopeTeamMember[]> {
    return this.http
      .post<ScopeTeamMember[]>(`${this.BASE_API_URL}scope/` + id + `/team/collaborator`, users)
      .pipe(map((api) => plainToInstance(ScopeTeamMember, api)));
  }

  addScopeReviewer(id: number, users: number[]): Observable<ScopeTeamMember[]> {
    return this.http
      .post<ScopeTeamMember[]>(`${this.BASE_API_URL}scope/` + id + `/team/reviewer`, users)
      .pipe(map((api) => plainToInstance(ScopeTeamMember, api)));
  }

  addScopeApprover(id: number, users: number[]): Observable<ScopeTeamMember[]> {
    return this.http
      .post<ScopeTeamMember[]>(`${this.BASE_API_URL}scope/` + id + `/team/approver`, users)
      .pipe(map((api) => plainToInstance(ScopeTeamMember, api)));
  }

  addScopeTrafficker(id: number, users: number[]): Observable<ScopeTeamMember[]> {
    return this.http
      .post<ScopeTeamMember[]>(`${this.BASE_API_URL}scope/` + id + `/team/trafficker`, users)
      .pipe(map((api) => plainToInstance(ScopeTeamMember, api)));
  }

  updateTeamMember(id: number, member: ScopeTeamMember): Observable<ScopeTeamMember> {
    return this.http
      .put<ScopeTeamMember>(`${this.BASE_API_URL}scope/${id}/team/${member.id}`, member)
      .pipe(map((api) => plainToInstance(ScopeTeamMember, api)));
  }

  removeScopeCollaborator(scopeId: number, memberId: number) {
    return this.http.delete<any>(`${this.BASE_API_URL}scope/${scopeId}/team/collaborator/${memberId}`);
  }

  removeScopeReviewer(scopeId: number, memberId: number) {
    return this.http.delete<any>(`${this.BASE_API_URL}scope/${scopeId}/team/reviewer/${memberId}`);
  }

  removeScopeApprover(scopeId: number, memberId: number) {
    return this.http.delete<any>(`${this.BASE_API_URL}scope/${scopeId}/team/approver/${memberId}`);
  }

  removeScopeTrafficker(scopeId: number, memberId: number) {
    return this.http.delete<any>(`${this.BASE_API_URL}scope/${scopeId}/team/trafficker/${memberId}`);
  }

  getAvailableDeliverableTypes(deliverableTypeOptions: any): Observable<DeliverableType[]> {
    return this.http.get<DeliverableType[]>(
      `${this.BASE_API_URL}predict/deliverable?mergeLibraryDeliverableEntryStrategy=${deliverableTypeOptions}`
    ).pipe(map((api) => plainToInstance(DeliverableType, api)));
  }

  deliverableTypePredictSearch(searchQuery: string, rateCardVersionId: number, language: string, fixedOnly?: boolean): Observable<DeliverableType[]> {
    return this.http.get<DeliverableType[]>(
      `${this.BASE_API_URL}predict/deliverable-search?language=${language}&ratecardVersionId=${rateCardVersionId}&search=${searchQuery}${fixedOnly ? '&fixedOnly=true': ''}`
    ).pipe(map((api) => plainToInstance(DeliverableType, api)));
  }

  getDisciplines(): Observable<any[]> {
    return this.http.get<any[]>(`${this.BASE_API_URL}predict/disciplines`);
  }

  addScopeDeliverable(id: number, deliverable: Deliverable): Observable<Deliverable> {
    return this.http
      .post<Deliverable>(`${this.BASE_API_URL}scope/` + id + `/deliverable`, deliverable)
      .pipe(map((api) => plainToInstance(Deliverable, api)));
  }

  createLibraryDeliverableTemplate(scopeId: number, deliverable: any): Observable<any> {
    let url = `library/deliverable`;
    if (scopeId) {
      url = url + `?originatingScopeId=` + scopeId;
    }
    return this.http.post<any>(`${this.BASE_API_URL}${url}`, deliverable).pipe(map((api) => api));
  }

  createLibraryDeliverableEntry(delId: number, entry: any): Observable<any> {
    return this.http
      .post<any>(`${this.BASE_API_URL}library/deliverable/` + delId + `/entry`, entry)
      .pipe(map((api) => api));
  }

  exportScopeToDocx(scopeId: number | string) {
    return this.http.get(`${this.BASE_API_URL}scope/` + scopeId + `/export/docx`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  downloadDataExport(uuid: string, companyId: number | string) {
    let cId = companyId ? companyId : '';
    cId = typeof cId == 'number' ? cId : '';
    let url = `data-export/user/${uuid}?companyId=${cId}`;
    return this.http.get(`${this.BASE_API_URL}${url}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  updateScopeTemplateByTemplate(scopeId: number | string, companyTemplateId: number | string) {
    return this.http
      .put(`${this.BASE_API_URL}scope/` + scopeId + `/scope-template/` + companyTemplateId, null)
      .pipe(map((api) => plainToInstance(OutputTemplate, api)));
  }

  updateScopeTemplateByCompanyTemplate(scopeId: number) {
    return this.http
      .put(`${this.BASE_API_URL}scope/` + scopeId + `/scope-template`, null)
      .pipe(map((api) => plainToInstance(OutputTemplate, api)));
  }

  isCompanyScopeCustomFieldsDefinitionOutdated(scopeId: number) {
    return this.http
      .get<boolean>(`${this.BASE_API_URL}scope/${scopeId}/custom-fields/outdated`)
      .pipe(map((api: any) => api.outdated));
  }

  getSecondPartyByIdForScope(secondPartyId: number) {
    return this.http
      .get<SecondParty>(`${this.BASE_API_URL}second-party/${secondPartyId}/for-scope`)
      .pipe(map((api) => plainToInstance(SecondParty, api)));
  }

  getOfficeLocation(officeLocationId: number) {
    return this.http.get<any>(`${this.BASE_API_URL}office-locations/${officeLocationId}`);
  }

  updateScope(scope: ScopeVersion) {
    return this.http
      .put<ScopeVersion>(`${this.BASE_API_URL}scope/${scope.identity.id}`, scope)
      .pipe(map((api) => plainToInstance(ScopeVersion, api)));
  }

  getAllRateCardsForScopeCreate() {
    return this.http
      .get<RatecardVersion[]>(`${this.BASE_API_URL}scope/ratecard`)
      .pipe(map((api) => plainToInstance(RatecardVersion, api)));
  }

  getDefaultRatecard() {
    return this.http
      .get<RatecardVersion>(`${this.BASE_API_URL}scope/ratecard/default/summary`)
      .pipe(map((api) => plainToInstance(RatecardVersion, api)));
  }

  getRetainedTeamStats(folderId: number) {
    return this.http
      .get<any>(`${this.BASE_API_URL}sow/${folderId}/retained-team/stats`)
      .pipe(map((api) => plainToInstance(SowRetainedTeamStats, api)));
  }

  refreshOutdatedScopeVersionCustomFields(scopeId: number, scopeVersionCustomFieldValueStructure: any) {
    return this.http.post<RefreshCustomFieldsResponse>(
      `${this.BASE_API_URL}scope/${scopeId}/custom-fields/refresh`,
      scopeVersionCustomFieldValueStructure
    );
  }
  getActivityComments(scopeId: string) {
    return this.http.get<any>(`${this.BASE_API_URL}scope/${scopeId}/comment`);
  }

  submitComment(scopeId: string, comment: any) {
    return this.http.put<any>(`${this.BASE_API_URL}scope/${scopeId}/comment`, comment);
  }

  getScopeBreakDownSummary(scopeId: string | number) {
    return this.http
      .get<BreakdownSummary>(`${this.BASE_API_URL}scope/${scopeId}/breakdown`)
      .pipe(map((api) => plainToInstance(BreakdownSummary, api)));
  }

  getDeliverablesBreakdownSummaryList(scopeId: string | number) {
    return this.http
      .get<BreakdownSummary[]>(`${this.BASE_API_URL}scope/${scopeId}/deliverable/breakdown/all`)
      .pipe(map((api) => plainToInstance(BreakdownSummary, api)));
  }

  getComponentBreakdownSummaryList(scopeId: string | number) {
    return this.http
      .get<BreakdownSummary[]>(`${this.BASE_API_URL}scope/${scopeId}/breakdown/component/all`)
      .pipe(map((api) => plainToInstance(BreakdownSummary, api)));
  }

  getDeliverableBreakdownSummary(scopeId: string | number, delId: string | number) {
    return this.http
      .get<BreakdownSummary>(`${this.BASE_API_URL}scope/${scopeId}/deliverable/${delId}/breakdown`)
      .pipe(map((api) => plainToInstance(BreakdownSummary, api)));
  }

  getComponentBreakdownSummaryById(scopeId: string | number, compId: string | number) {
    return this.http
      .get<BreakdownSummary[]>(`${this.BASE_API_URL}scope/${scopeId}/breakdown/component/${compId}`)
      .pipe(map((api) => plainToInstance(BreakdownSummary, api)));
  }

  getComponentKeyPair(scopeId: string | number) {
    return this.http.get<any>(`${this.BASE_API_URL}scope/${scopeId}/components/keypair`);
  }

  applyLocationToDepartment(
    department: any,
    locationId: string | number,
    scopeId: string | number,
    deliverableId: number | string,
    componentId: number | string
  ) {
    let optionalDeliverableId = deliverableId ? deliverableId : '';
    let optionalComponentId = componentId ? componentId : '';
    let url = `scope/${scopeId}/location/?location=${locationId}&department=${department.name}&deliverable=${optionalDeliverableId}&component=${optionalComponentId}`;
    return this.http.put<any>(`${this.BASE_API_URL}${url}`, department);
  }

  applyLocationToRole(
    roleId: number,
    locationRoleId: number,
    scopeId: number,
    deliverableId?: number,
    componentId?: number
  ) {
    let optionalLocationRoleId = locationRoleId ? locationRoleId : '';
    let optionalDeliverableId = deliverableId ? deliverableId : '';
    let optionalComponentId = componentId ? componentId : '';
    let url = `scope/${scopeId}/role/${roleId}/location-role/?locationRoleId=${optionalLocationRoleId}&deliverable=${optionalDeliverableId}&component=${optionalComponentId}`;
    return this.http.put<any>(`${this.BASE_API_URL}${url}`, null);
  }

  filterScopeSections(id: number, versionId: number, filters: ScopeOverviewFilter) {
    return this.http
      .post<ScopeSection[]>(`${this.BASE_API_URL}scope/${id}/filter-sections`, filters, {
        params: { version: versionId },
      })
      .pipe(map((api) => plainToInstance(ScopeSection, api)));
  }

  filterScopeDeliverables(id: number, versionId: number, filters: ScopeOverviewFilter) {
    return this.http
      .post<Deliverable[]>(`${this.BASE_API_URL}scope/${id}/filter-deliverables`, filters, {
        params: { version: versionId },
      })
      .pipe(map((api) => plainToInstance(Deliverable, api)));
  }

  filterScopeComponents(id: number, versionId: number, filters: ScopeOverviewFilter) {
    return this.http
      .post<ScopeComponent[]>(`${this.BASE_API_URL}scope/${id}/filter-components`, filters, {
        params: { version: versionId },
      })
      .pipe(map((api) => plainToInstance(ScopeComponent, api)));
  }

  filterScopeDepartments(id: number, versionId: number, filters: ScopeOverviewFilter) {
    return this.http
      .post<Department[]>(`${this.BASE_API_URL}scope/${id}/filter-departments`, filters, {
        params: { version: versionId },
      })
      .pipe(map((api) => plainToInstance(Department, api)));
  }

  filterScopeRoles(id: number, versionId: number, filters: ScopeOverviewFilter) {
    return this.http
      .post<Role[]>(`${this.BASE_API_URL}scope/${id}/filter-roles`, filters, { params: { version: versionId } })
      .pipe(map((api) => plainToInstance(Role, api)));
  }

  filterScopeFees(id: number, versionId: number, filters: ScopeOverviewFilter) {
    return this.http.post<any[]>(`${this.BASE_API_URL}scope/${id}/filter-fees`, filters, {
      params: { version: versionId },
    });
  }

  filterScopeDeliverableFees(id: number, versionId: number, filters: ScopeOverviewFilter) {
    return this.http.post<any[]>(`${this.BASE_API_URL}scope/${id}/filter-deliverable-fees`, filters, {
      params: { version: versionId },
    });
  }

  filterScopeDeliverableTPCs(id: number, versionId: number, filters: ScopeOverviewFilter) {
    return this.http
      .post<ThirdPartyCost[]>(`${this.BASE_API_URL}scope/${id}/filter-deliverable-tpcs`, filters, {
        params: { version: versionId },
      })
      .pipe(map((api) => plainToInstance(ThirdPartyCost, api)));
  }

  filterScopeDeliverableSectionTPCs(id: number, versionId: number, filters: ScopeOverviewFilter) {
    return this.http
      .post<ThirdPartyCost[]>(`${this.BASE_API_URL}scope/${id}/filter-deliverable-section-tpcs`, filters, {
        params: { version: versionId },
      })
      .pipe(map((api) => plainToInstance(ThirdPartyCost, api)));
  }

  filterScopeComponentTPCs(id: number, versionId: number, filters: ScopeOverviewFilter) {
    return this.http
      .post<ThirdPartyCost[]>(`${this.BASE_API_URL}scope/${id}/filter-component-tpcs`, filters, {
        params: { version: versionId },
      })
      .pipe(map((api) => plainToInstance(ThirdPartyCost, api)));
  }

  filterScopeComponentFees(id: number, versionId: number, filters: ScopeOverviewFilter) {
    return this.http.post<any[]>(`${this.BASE_API_URL}scope/${id}/filter-component-fees`, filters, {
      params: { version: versionId },
    });
  }

  createScopeFeeItem(scopeId: number, feeItem: any) {
    return this.http
      .post(`${this.BASE_API_URL}scope/${scopeId}/scope-fee-item`, feeItem)
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  updateScopeFeeItem(scopeId: number, feeItemId: number, feeItem: any) {
    return this.http
      .put(`${this.BASE_API_URL}scope/${scopeId}/scope-fee-item/${feeItemId}`, feeItem)
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkScopeFeeItemToComponent(scopeId: number, feeItemId: number, newComponentId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/scope-fee-item/${feeItemId}/link-to-component?new-component=${newComponentId}`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkScopeFeeItemToDeliverable(scopeId: number, feeItemId: number, newDeliverableId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/scope-fee-item/${feeItemId}/link-to-deliverable?new-deliverable=${newDeliverableId}`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkScopeFeeItemToScopeSection(scopeId: number, feeItemId: number, newSectionId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/scope-fee-item/${feeItemId}/link-to-scope-section?new-section=${newSectionId}`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  deleteScopeFeeItem(scopeId: number, feeItemId: number) {
    return this.http.delete<any>(`${this.BASE_API_URL}scope/${scopeId}/scope-fee-item/${feeItemId}`)
  }

  createSectionFeeItem(scopeId: number, sectionId: number, feeItem: any) {
    return this.http
      .post(`${this.BASE_API_URL}scope/${scopeId}/section/${sectionId}/scope-section-fee-item`, feeItem)
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  updateSectionFeeItem(scopeId: number, sectionId: number, feeItemId: number, feeItem: any) {
    return this.http
      .put(`${this.BASE_API_URL}scope/${scopeId}/section/${sectionId}/scope-section-fee-item/${feeItemId}`, feeItem)
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkSectionFeeItemToComponent(scopeId: number, sectionId: number, feeItemId: number, newComponentId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/section/${sectionId}/scope-section-fee-item/${feeItemId}/link-to-component?new-component=${newComponentId}`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkSectionFeeItemToDeliverable(scopeId: number, sectionId: number, feeItemId: number, newDeliverableId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/section/${sectionId}/scope-section-fee-item/${feeItemId}/link-to-deliverable?new-deliverable=${newDeliverableId}`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkSectionFeeItemToScopeSection(scopeId: number, sectionId: number, feeItemId: number, newSectionId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/section/${sectionId}/scope-section-fee-item/${feeItemId}/link-to-scope-section?new-section=${newSectionId}`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkSectionFeeItemToScope(scopeId: number, sectionId: number, feeItemId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/section/${sectionId}/scope-section-fee-item/${feeItemId}/link-to-scope`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  deleteSectionFeeItem(scopeId: number, sectionId: number, feeItemId: number) {
    return this.http.delete<any>(
      `${this.BASE_API_URL}scope/${scopeId}/section/${sectionId}/scope-section-fee-item/${feeItemId}`
    );
  }

  createDeliverableFeeItem(scopeId: number, deliverableId: number, feeItem) {
    return this.http
      .post(`${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/deliverable-fee-item`, feeItem)
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  updateDeliverableFeeItem(scopeId: number, deliverableId: number, feeItemId: number, feeItem: any) {
    return this.http
      .put(
        `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/deliverable-fee-item/${feeItemId}`,
        feeItem
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkDeliverableFeeItemToComponent(scopeId: number, deliverableId: number, feeItemId: number, newComponentId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/deliverable-fee-item/${feeItemId}/link-to-component?new-component=${newComponentId}`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkDeliverableFeeItemToDeliverable(scopeId: number, deliverableId: number, feeItemId: number, newDeliverableId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/deliverable-fee-item/${feeItemId}/link-to-deliverable?new-deliverable=${newDeliverableId}`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkDeliverableFeeItemToScopeSection(scopeId: number, deliverableId: number, feeItemId: number, sectionId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/deliverable-fee-item/${feeItemId}/link-to-scope-section?new-section=${sectionId}`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkDeliverableFeeItemToScope(scopeId: number, deliverableId: number, feeItemId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/deliverable-fee-item/${feeItemId}/link-to-scope`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  deleteDeliverableFeeItem(scopeId: number, deliverableId: number, feeItemId: number) {
    return this.http.delete<any>(
      `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/deliverable-fee-item/${feeItemId}`
    );
  }

  createComponentFeeItem(scopeId: number, deliverableId: number, componentId: number, feeItem: any) {
    return this.http
      .post(
        `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/component/${componentId}/component-fee-item`,
        feeItem
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  updateComponentFeeItem(scopeId: number, deliverableId: number, componentId: number, feeItemId: number, feeItem: any) {
    return this.http
      .put(
        `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/component/${componentId}/component-fee-item/${feeItemId}`,
        feeItem
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  deleteComponentFeeItem(scopeId: number, deliverableId: number, componentId: number, feeItemId: number) {
    return this.http.delete<any>(
      `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/component/${componentId}/component-fee-item/${feeItemId}`
    );
  }

  linkComponentFeeItemToComponent(scopeId: number, deliverableId: number, componentId: number, feeItemId: number, newComponentId: number) {
    return this.http
      .get<any>(
      `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/component/${componentId}/component-fee-item/${feeItemId}/link-to-component?new-component=${newComponentId}`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkComponentFeeItemToDeliverable(scopeId: number, deliverableId: number, componentId: number, feeItemId: number, newDeliverableId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/component/${componentId}/component-fee-item/${feeItemId}/link-to-deliverable?new-deliverable=${newDeliverableId}`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkComponentFeeItemToScopeSection(scopeId: number, deliverableId: number, componentId: number, feeItemId: number, sectionId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/component/${componentId}/component-fee-item/${feeItemId}/link-to-scope-section?new-section=${sectionId}`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  linkComponentFeeItemToScope(scopeId: number, deliverableId: number, componentId: number, feeItemId: number) {
    return this.http
      .get<any>(
        `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/component/${componentId}/component-fee-item/${feeItemId}/link-to-scope`
      )
      .pipe(map((api) => plainToInstance(FeeItemInstance, api)));
  }

  updateCompanyFeeItem(feeItem) {
    return this.http.put(`${this.BASE_API_URL}company/fee-item/${feeItem.id}`, feeItem);
  }

  updateScopeDiscount(scopeId: number, discount: Discount) {
    return this.http.put(`${this.BASE_API_URL}scope/${scopeId}/discount`, discount)
  };

  updateScopeMsa(scopeId: number, msa: ScopeMsaLineItem) {
    return this.http.put(`${this.BASE_API_URL}scope/${scopeId}/msa-line-item`, msa)
  };

  trafficScope(scopeId: number): Observable<any> {
    const url = `${this.BASE_API_URL}scope/${scopeId}/traffic`;
    return this.http.post(url, null);
  }

  trafficWorkatoScope(target:string, scopeId: number): Observable<any> {
    const url = `${this.BASE_API_URL}scope/${scopeId}/trafficworkato`;
    return this.http.post(url, {target: target});
  }

  trafficScopeThroughWorkato(scopeId: number): Observable<any> {
    const url = `${this.BASE_API_URL}scope/${scopeId}/traffic-through-workato`;
    return this.http.post(url, null);
  }

  // TRACK AND TRADE
  initiateTrade(scopeId: number, deliverableId: number, deliverables: DeliverableTradePayload[]) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/initiate-trade`;
    return this.http.post<TradedDeliverable[]>(url, deliverables)
  }

  resumeTrade(scopeId: number, deliverableId: number) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/resume`;
    return this.http.post<TradedDeliverable[]>(url, null)
  }

  stopTrade(scopeId: number, deliverableId: number) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/stop`;
    return this.http.post<TradedDeliverable[]>(url, null)
  }

  completeTrade(scopeId: number, deliverableId: number) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/complete`;
    return this.http.post<TradedDeliverable[]>(url, null)
  }

  cancelTrade(scopeId: number, deliverableId: number) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/cancel-trade`;
    return this.http.post<TradedDeliverable[]>(url, null)
  }

  confirmTrade(scopeId: number, deliverableId: number) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/confirm-trade`;
    return this.http.post<TradedDeliverable[]>(url, null)
  }

  applyLocationCardToDeliverable(scopeId: number, deliverableId: number, locationId: number) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/locationCard/${locationId}`;
    return this.http.put(url, { }).pipe(map((api) => plainToInstance(Deliverable, api)))
  }

  deleteScopeDiscount(scopeId: number) {
    return this.http.delete<any>(`${this.BASE_API_URL}scope/${scopeId}/discount`)
  }

  deleteScopeMsa(scopeId: number, reason: string) {
    return this.http.delete<any>(`${this.BASE_API_URL}scope/${scopeId}/msa-line-item`, { body: { reason: reason } })
  }

  getScopeRatecardByVersion(id: number, version: number) {
    return this.http.get<any>(`${this.BASE_API_URL}scope/${id}/ratecard/${version}`)
  }

  getRatecardVersionForDeliverableEntry(libraryDeliverableTemplateId: number, entryId: number) {
    return this.http.get<any>(`${this.BASE_API_URL}library/deliverable/${libraryDeliverableTemplateId}/entry/${entryId}/ratecard`)
  };

  getRatecardVersion(rateCardId: number) {
    return this.http.get<RatecardVersion>(`${this.BASE_API_URL}company/ratecard-version/${rateCardId}`).pipe(map((api) => plainToInstance(RatecardVersion, api)))
  }

  getDeliverableEntry(deliverableId: number, disciplineId: number, ratecardId: number) {
    return this.http.get<any>(`${this.BASE_API_URL}library/deliverable/${deliverableId}/ratecard/${ratecardId}/discipline/${disciplineId}`)
  }

  getLibraryDeliverableEntry(entryId: number) {
    return this.http.get<any>(`${this.BASE_API_URL}library/deliverable-entry/${entryId}`)
  }

  duplicateDeliverableEntry(deliverableId: number, entryId: number, newDiscipline: Discipline, newRatecardId: number, rolesMapping: { [key: number]: number }, oneTimeOnly: boolean) {
    let url = `${this.BASE_API_URL}library/deliverable/${deliverableId}/entry/${entryId}
      /ratecard/${newRatecardId}/duplicate${oneTimeOnly ? '-for-one-time-use' : ''}`
    return this.http.post<any>(url, { newDiscipline: newDiscipline, rolesMapping: rolesMapping })
  }

  convertAndInsertLibraryDeliverable(deliverableId: number, entryId: number, scopeId: number, rolesMapping: any, newName: string, opts: any = {}) {
    let roles = {};
    rolesMapping.forEach(function (roleMapping) {
      if (roleMapping.targetMappingRole) {
        roles[roleMapping.rateCardRole.id] = roleMapping.targetMappingRole.id;
      }
    });

    return this.http.post(`${this.BASE_API_URL}library/deliverable/${deliverableId}/entry/${entryId}/convert-insert-into-scope/${scopeId}`, {
      "rolesMapping": roles,
      "newName": newName ? newName : null,
      "description": opts.description,
      "budget": opts.budget,
      "startDate": opts.startDate,
      "endDate": opts.endDate
    });
  }

  getDeliverableAndComponentFees(scopeId: number, versionId: number) {
    return this.http.get<any>(`${this.BASE_API_URL}scope/${scopeId}/deliverable-component-fees?version=${versionId}`).pipe(map((api) => {
      return {
        deliverableFeeItems: plainToInstance(FeeItemInstance, api.deliverableFeeItems),
        componentFeeItems: plainToInstance(FeeItemInstance, api.componentFeeItems)
      }
    }))
  }

  getAllDeliverableThirdPartyCosts(scopeId: number, versionId: number) {
    return this.http.get<any>(`${this.BASE_API_URL}scope/${scopeId}/all-deliverable-tpcs?version=${versionId}`).pipe(map((api) => {
      return {
        deliverableThirdPartyCosts: plainToInstance(ThirdPartyCost, api.deliverableThirdPartyCosts),
        componentThirdPartyCosts: plainToInstance(ThirdPartyCost, api.componentThirdPartyCosts),
        sectionThirdPartyCosts: plainToInstance(ThirdPartyCost, api.sectionThirdPartyCosts)
      }
    }))
  }

  searchComponentsForScope(scopeId: number, versionId: number, searchText: string): Observable<TypedFilterValue[]> {
    return this.http
      .get<TypedFilterValue[]>(`${this.BASE_API_URL}scope/${scopeId}/component-search`, { params: { search: searchText, version: versionId } })
      .pipe(map((api) => plainToInstance(TypedFilterValue, api)))
  }

  searchNonDistinctComponents(scopeId: number, versionId: number, searchText: string): Observable<ScopeComponent[]> {
    return this.http
      .get<ScopeComponent[]>(`${this.BASE_API_URL}scope/${scopeId}/search-components`, { params: { search: searchText, version: versionId } })
      .pipe(map((api) => plainToInstance(ScopeComponent, api)))
  }

  searchDepartmentsForScope(scopeId: number, versionId: number, searchText: string): Observable<GenericFilterValue[]> {
    return this.http
      .get<GenericFilterValue[]>(`${this.BASE_API_URL}scope/${scopeId}/department-search`, { params: { search: searchText, version: versionId } })
      .pipe(map((api) => plainToInstance(GenericFilterValue, api)))
  }

  searchRolesForScope(scopeId: number, versionId: number, searchText: string): Observable<GenericFilterValue[]> {
    return this.http
      .get<GenericFilterValue[]>(`${this.BASE_API_URL}scope/${scopeId}/role-search`, { params: { search: searchText, version: versionId } })
      .pipe(map((api) => plainToInstance(GenericFilterValue, api)))
  }

  getTaskTemplates(ratecardVersionId: number, searchText: string = '') {
    return this.http.get<any>(`${this.BASE_API_URL}library/task-search?ratecardVersionId=${ratecardVersionId}&search=${searchText}`)
  }

  addTask(scopeId: number, task: NewTask) {
    return this.http.post<any>(`${this.BASE_API_URL}scope/${scopeId}/task`, task)
  }

  addTasksFromLibrary(scopeId: number, templateIds: number[]) {
    return this.http.post<any>(`${this.BASE_API_URL}scope/${scopeId}/task/templates`, templateIds)
  }

  updateTaskRole(scopeId: number, taskId: number, roleId: number, updateValue: any) {
    return this.http.put(`${this.BASE_API_URL}scope/${scopeId}/deliverable/${taskId}/role/${roleId}`, updateValue)
      .pipe(map((api) => plainToInstance(Role, api)))
  }

  addScopeRateCardRoles(scopeId: number, roles: any[]) {
    return this.http.post<any>(`${this.BASE_API_URL}scope/${scopeId}/roles`, roles)
  }

  createLibraryTaskTemplate(scopeId: number, ids: number[]) {
    return this.http.post(`${this.BASE_API_URL}library/task-template/${scopeId}/template`, ids)
  }

  clearTaskHours(scopeId: number, id: number) {
    return this.http.put(`${this.BASE_API_URL}scope/${scopeId}/deliverable/${id}/clear-roles-hours`, {})
  }

  getThirdPartyCostFormulaDefinition(): Observable<ThirdPartyCostFormula[]> {
    return this.http
      .get<ThirdPartyCostFormula[]>(`${this.BASE_API_URL}third-party-cost-formula-definition`)
      .pipe(map((api) => plainToInstance(ThirdPartyCostFormula, api)))
  }

  getThirdPartyCostGroups(includeTypes: boolean): Observable<ThirdPartyCostGroup[]> {
    return this.http
      .get<ThirdPartyCostGroup[]>(`${this.BASE_API_URL}predict/third-party-cost-group`, { params: { includeTypes: includeTypes } })
      .pipe(map((api) => plainToInstance(ThirdPartyCostGroup, api)))
  }

  addScopeThirdPartyCost(scopeId: number, tpc: ThirdPartyCost) {
    return this.http.post<any>(`${this.BASE_API_URL}scope/${scopeId}/third-party-cost`, tpc)
  }

  updateScopeThirdPartyCost(scopeId: number, tpc: ThirdPartyCost) {
    return this.http.put<any>(`${this.BASE_API_URL}scope/${scopeId}/third-party-cost/${tpc.id}`, tpc)
  }

  deleteScopeThirdPartyCost(scopeId: number, tpcId: number) {
    return this.http.delete<any>(`${this.BASE_API_URL}scope/${scopeId}/third-party-cost/${tpcId}`)
  }

  exportScopeByRoleToExcel(scopeId: number) {
    return this.http.get<any>(`${this.BASE_API_URL}scope/${scopeId}/export/scope-by-role`)
  };

  refreshActuals(sowId: number): Observable<any> {
    return this.http
      .post<any>(`${this.BASE_API_URL}sow/` + sowId + `/timesheets/refresh-actuals`, null)
  }

  importScopeTimeSheets(scopeId: number): Observable<any> {
    return this.http
      .post<any>(`${this.BASE_API_URL}scope/` + scopeId + `/traffic-system/time-sheet/import`, null)
  }

  saveConfiguration(scopeId: number, configuration: ScopeConfiguration) {
    return this.http
      .post<any>(`${this.BASE_API_URL}scope/${scopeId}/configuration`, configuration)
  }

  submitConfiguration(scopeId: number, modifiers: { [key: number]: number }) {
    return this.http.post<ScopeVersion>(`${this.BASE_API_URL}scope/${scopeId}/submit-configuration`, modifiers)
      .pipe(map((api) => plainToInstance(ScopeVersion, api)));
  }
}

<form class='auto-complete-form'>
  <mat-form-field
    class='auto-complete-form-field'
    subscriptSizing='dynamic'>
    <mat-label>{{title}}<span class='orange'> *</span></mat-label>
    <span class='flex gap-2 items-center'>
      <i class='material-icons material-symbols-rounded text-base'>search</i>
      <input type='text' id='Find item' matInput [formControl]='control' [matAutocomplete]='auto'
             [required]='required' />
      <mat-icon *ngIf='control.value' class='material-symbols-rounded clear' aria-label='Clear' (click)='clear($event)'
                matSuffix>close</mat-icon>
      <mat-icon *ngIf='!control.value ' class='material-symbols-rounded expand-icon'
                [ngClass]="{'panel-open': matAutocompleteTrigger?.panelOpen}" aria-label='Expand'
                (click)='toggle($event)' matSuffix>expand_more</mat-icon>
    </span>
    <mat-autocomplete
      #auto='matAutocomplete'
      [disableRipple]="true">
      <div class='options-wrapper' infiniteScroll [infiniteScrollDisabled]="isFetching || lastPage || noPagination"
           [infiniteScrollDistance]="2" [infiniteScrollThrottle]="150" [scrollWindow]="false" (scrolled)="onScroll()">
        <mat-option
          *ngFor='let option of _options'
          class='deliverable-type'
          [value]='null'
          (click)='$event.stopPropagation()'>
          <div class='item-content' [innerHtml]="optionFn(option)"></div>
          <mat-pseudo-checkbox
            *ngIf='isSelected(option)'
            class='mat-pseudo-checkbox'
            [state]="'checked'"
            appearance='minimal'>
          </mat-pseudo-checkbox>
          <button
            *ngIf='!isSelected(option)'
            mat-flat-button
            class='add-button'
            matTooltip="Add to prompt"
            (click)='onAdd(option, $event)'>
            Add
          </button>
        </mat-option>
      </div>
    </mat-autocomplete>
    <mat-error *ngIf="control.hasError('required')">This is required</mat-error>
  </mat-form-field>
</form>

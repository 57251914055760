import {
  LibraryDeliverableService,
  LibraryManagementApiMappingService,
  LibraryManagementFoldersService,
  LibraryManagementItemsService,
  LibraryManagementMappingService,
} from '@app/features/library-management/services'
import {
  LibraryFormError,
  LibraryItemName,
  LibraryItemTypes,
  LibraryManagementActionTypes,
  LibraryManagementFilters,
  LibraryManagementItemsActionTypes,
  LibraryManagementItemsActions,
  LibraryManagementItemsSelectors,
  LibraryManagementSelectors,
  libraryManagementFiltersInitialState,
} from '@app/features/library-management/store'
import {
  ArchiveLibraryItemAction,
  CreateFolderAndMoveLibraryItemAction,
  DeleteLibraryEntryAction,
  DeleteLibraryItemAction,
  DuplicateLibraryItemAction,
  GetLibraryItemAction,
  LibraryManagementItem,
  MoveLibraryItemAction,
  UpdateLibraryItemAction,
  UpdateLibraryItemEntriesColumnPreference,
  UpdateLibraryItemsColumnPreference,
  libraryItemEntriesColumnsBaseConfig,
  libraryItemsColumnsBaseConfig,
} from '@app/features/library-management/store/models/library-items'
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@app/shared/utils/snackbar.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs'

import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Sort } from '@angular/material/sort'
import { Router } from '@angular/router'
import { Pagination } from '@app/core/model/definitions/pagination.interface'
import { UserPreferencesColumns } from '@app/core/model/enums/user-preferences-columns.enum'
import { UserPreferences } from '@app/core/model/user-preferences.interface'
import { LanguageService } from '@app/core/service/language.service'
import { LibraryManagementFolder } from '@app/features/library-management/store/models/library-folders'
import { UserService } from '@app/features/user-account/services/user.service'
import { getPlainHttpParams, mergePreferencesWithTableConfig } from '@app/shared/utils/utils';
import { isEqual } from 'lodash'
import { TableColumnPreferences } from '@app/shared/components/ui-components/scope-ui-table/table-column-preferences.type'

@Injectable()
export class LibraryManagementItemsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private libraryFoldersService: LibraryManagementFoldersService,
    private libraryItemsService: LibraryManagementItemsService,
    private libraryDeliverableService: LibraryDeliverableService,
    private libraryMappingService: LibraryManagementMappingService,
    private libraryApiMappingService: LibraryManagementApiMappingService,
    private dialog: MatDialog,
    private lang: LanguageService,
    private router: Router
  ) {}

  updateFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryManagementActionTypes.UPDATE_FILTERS, LibraryManagementActionTypes.SET_FOLDER_ID),
      switchMap(() => [
        LibraryManagementItemsActions.setPage({ page: 0 }),
        LibraryManagementItemsActions.setArchivedPage({ page: 0 }),
      ])
    )
  })

  getLibraryItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LibraryManagementItemsActionTypes.GET_LIBRARY_ITEMS,
        LibraryManagementItemsActionTypes.SET_PAGE,
        LibraryManagementItemsActionTypes.SET_SORT,
        LibraryManagementActionTypes.GET_FOLDER_SUCCESS
      ),
      withLatestFrom(
        this.store.select(LibraryManagementItemsSelectors.selectPagination),
        this.store.select(LibraryManagementItemsSelectors.selectSort),
        this.store.select(LibraryManagementSelectors.selectFilters)
      ),
      switchMap(
        ([{ type, folder }, { page, pageSize }, sort, filters]: [any, Pagination, Sort, LibraryManagementFilters]) => {
          let params = getPlainHttpParams({
            ...this.libraryApiMappingService.mapFiltersToApi(filters),
            folderId: type === LibraryManagementActionTypes.GET_FOLDER_SUCCESS ? folder?.id : filters.folderId,
            page,
            pageSize,
            orderByField: sort?.active,
            order: sort?.direction?.toUpperCase(),
          })

          return (
            isEqual(filters, libraryManagementFiltersInitialState)
              ? this.libraryItemsService.getLibraryItems(params)
              : this.libraryItemsService.getFilteredLibraryItems(params)
          ).pipe(
            map(({ content, totalElements }) =>
              LibraryManagementItemsActions.getLibraryItemsSuccess({ list: content, totalElements })
            ),
            catchError((error: any) => {
              console.error(error)
              this.snackbarService.showSnackbar(
                'An error occurred during loading Library Items',
                SNACKBAR_LENGTH_LONG,
                SnackbarEventType.ERROR
              )
              return of(LibraryManagementItemsActions.getLibraryItemsFail({ error }))
            })
          )
        }
      )
    )
  )

  getArchivedLibraryItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LibraryManagementItemsActionTypes.GET_ARCHIVED_LIBRARY_ITEMS,
        LibraryManagementItemsActionTypes.SET_ARCHIVED_PAGE,
        LibraryManagementItemsActionTypes.SET_ARCHIVED_SORT,
        LibraryManagementActionTypes.GET_FOLDER_SUCCESS
      ),
      withLatestFrom(
        this.store.select(LibraryManagementItemsSelectors.selectArchivedPagination),
        this.store.select(LibraryManagementItemsSelectors.selectArchivedSort),
        this.store.select(LibraryManagementSelectors.selectFilters)
      ),
      map(([{ type, folder }, { page, pageSize }, sort, filters]: [any, Pagination, Sort, LibraryManagementFilters]) =>
        getPlainHttpParams({
          ...this.libraryApiMappingService.mapFiltersToApi(filters),
          folderId: type === LibraryManagementActionTypes.GET_FOLDER_SUCCESS ? folder?.id : filters.folderId,
          page,
          pageSize,
          orderByField: sort?.active,
          order: sort?.direction?.toUpperCase(),
          archived: true,
        })
      ),
      switchMap((params) =>
        this.libraryItemsService.getFilteredLibraryItems(params).pipe(
          map(({ content, totalElements }) =>
            LibraryManagementItemsActions.getArchivedLibraryItemsSuccess({ list: content, totalElements })
          ),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during loading archived Library Items',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryManagementItemsActions.getArchivedLibraryItemsFail({ error }))
          })
        )
      )
    )
  )

  getLibraryItemsColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementItemsActionTypes.GET_LIBRARY_ITEMS_COLUMNS),
      switchMap(() =>
        this.userService.getUserPreferences(UserPreferencesColumns.LIBRARY_USERCOLUMNS).pipe(
          map(
            (preferences) =>
              mergePreferencesWithTableConfig(libraryItemsColumnsBaseConfig, preferences)
          ),
          map((preferences) => LibraryManagementItemsActions.getLibraryItemsColumnsSuccess({ preferences })),
          catchError((error) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during loading Library Items Columns preferences',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryManagementItemsActions.getLibraryItemsColumnsFail(error))
          })
        )
      )
    )
  )

  setLibraryItemsColumns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryManagementItemsActionTypes.UPDATE_LIBRARY_ITEMS_COLUMN_PREFERENCE),
      withLatestFrom(this.store.pipe(select(LibraryManagementItemsSelectors.selectColumns))),
      switchMap(
        ([{ preference }, currentPreferences]: [UpdateLibraryItemsColumnPreference, TableColumnPreferences]) => {
          const updatedPreferences: TableColumnPreferences = {
            ...currentPreferences,
            [preference.key]: preference,
          }

          const userPreferences: UserPreferences = updatedPreferences as UserPreferences

          return this.userService.setUserPreferences(UserPreferencesColumns.LIBRARY_USERCOLUMNS, userPreferences).pipe(
            map(() =>
              LibraryManagementItemsActions.updateLibraryItemsColumnPreferenceSuccess({
                preferences: updatedPreferences,
              })
            )
          )
        }
      ),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during updating Library Items Columns Preferences',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementItemsActions.updateLibraryItemsColumnPreferenceFail(error))
      })
    )
  })

  getLibraryItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryManagementItemsActionTypes.GET_LIBRARY_ITEM),
      mergeMap(({ entity }: GetLibraryItemAction) =>
        this.libraryItemsService.getLibraryItem(entity.id, entity.libraryItemType)
      ),
      map((item: LibraryManagementItem) => LibraryManagementItemsActions.getLibraryItemSuccess({ item })),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during loading Library Item',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementItemsActions.getLibraryItemFail(error))
      })
    )
  })

  getLibraryItemEntriesColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementItemsActionTypes.GET_LIBRARY_ITEM_ENTRIES_COLUMNS),
      switchMap(() =>
        this.userService.getUserPreferences(UserPreferencesColumns.LIBRARY_ENTRIES_USERCOLUMNS).pipe(
          map(
            (preferences) =>
              mergePreferencesWithTableConfig(libraryItemEntriesColumnsBaseConfig, preferences)
          ),
          map((preferences) => LibraryManagementItemsActions.getLibraryItemEntriesColumnsSuccess({ preferences }))
        )
      ),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during loading Library Item Entries Columns preferences',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementItemsActions.getLibraryItemEntriesColumnsFail(error))
      })
    )
  )

  setLibraryItemEntriesColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementItemsActionTypes.UPDATE_LIBRARY_ITEM_ENTRIES_COLUMN_PREFERENCE),
      withLatestFrom(this.store.pipe(select(LibraryManagementItemsSelectors.selectChildColumns))),
      map(
        ([{ preference }, currentPreferences]: [
          UpdateLibraryItemEntriesColumnPreference,
          TableColumnPreferences
        ]) => ({
          ...currentPreferences,
          [preference.key]: preference,
        })
      ),
      switchMap((preferences) =>
        this.userService
          .setUserPreferences(UserPreferencesColumns.LIBRARY_ENTRIES_USERCOLUMNS, preferences)
          .pipe(
            map(() => LibraryManagementItemsActions.updateLibraryItemEntriesColumnPreferenceSuccess({ preferences }))
          )
      ),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during updating Library Item Entries Columns Preferences',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementItemsActions.updateLibraryItemEntriesColumnPreferenceFail(error))
      })
    )
  )

  updateLibraryItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementItemsActionTypes.UPDATE_LIBRARY_ITEM),
      withLatestFrom(this.store.select(LibraryManagementItemsSelectors.selectDialogId)),
      switchMap(([{ item }, dialogId]: [UpdateLibraryItemAction, string]) => {
        let requestBody = this.libraryApiMappingService.mapLibraryItemToApi(item)
        let api = this.libraryItemsService.updateComponent(requestBody)

        switch (item.libraryItemType) {
          case LibraryItemTypes.DELIVERABLE:
            api = this.libraryDeliverableService.updateDeliverable(requestBody)
            break
          case LibraryItemTypes.THIRD_PARTY_COST:
            api = this.libraryItemsService.updateThirdPartyCost(this.libraryApiMappingService.mapLibraryTcpToApi(item))
            break
        }

        return api.pipe(
          map(
            (response: LibraryManagementItem) => (
              this.dialog.getDialogById(dialogId)?.close(),
              LibraryManagementItemsActions.updateLibraryItemSuccess({ item: response })
            )
          ),
          catchError((error) => {
            if (error.status === 409)
              this.setAlreadyExistsError(this.lang.get(LibraryItemName[item.libraryItemType]), dialogId)
            return of(LibraryManagementItemsActions.updateLibraryItemFail(error))
          })
        )
      }),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during updating Library Item',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementItemsActions.updateLibraryItemFail(error))
      })
    )
  )

  duplicateLibraryItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementItemsActionTypes.DUPLICATE_LIBRARY_ITEM),
      withLatestFrom(this.store.select(LibraryManagementItemsSelectors.selectDialogId)),
      switchMap(([{ item }, dialogId]: [DuplicateLibraryItemAction, string]) =>
        this.libraryItemsService.duplicateLibraryItem(item.id, item.libraryItemType, item.name).pipe(
          map(
            (response) => (
              this.dialog.getDialogById(dialogId)?.close(),
              LibraryManagementItemsActions.duplicateLibraryItemSuccess({
                item: { ...response, libraryItemType: item.libraryItemType },
              })
            )
          ),
          catchError((error) => {
            if (error.status === 409)
              this.setAlreadyExistsError(this.lang.get(LibraryItemName[item.libraryItemType]), dialogId)
            return of(LibraryManagementItemsActions.duplicateLibraryItemFail(error))
          })
        )
      ),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during duplicating Library Item',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementItemsActions.duplicateLibraryItemFail(error))
      })
    )
  )

  archiveLibraryItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementItemsActionTypes.ARCHIVE_LIBRARY_ITEM),
      switchMap(({ item }: ArchiveLibraryItemAction) =>
        this.libraryItemsService
          .archiveLibraryItem(item.id)
          .pipe(map(() => LibraryManagementItemsActions.archiveLibraryItemSuccess({ item })))
      ),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during archiving Library Item',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementItemsActions.archiveLibraryItemFail(error))
      })
    )
  )

  deleteLibraryItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementItemsActionTypes.DELETE_LIBRARY_ITEM),
      switchMap(({ item }: DeleteLibraryItemAction) =>
        this.libraryItemsService
          .deleteLibraryItem(item.id)
          .pipe(map(() => LibraryManagementItemsActions.deleteLibraryItemSuccess({ item })))
      ),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during deleting Library Item',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementItemsActions.deleteLibraryItemFail(error))
      })
    )
  )

  moveLibraryItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementItemsActionTypes.MOVE_LIBRARY_ITEM),
      switchMap(({ id, folderId }: MoveLibraryItemAction) =>
        this.libraryItemsService.moveLibraryItem(id, getPlainHttpParams({ folderId })).pipe(
          map(() => LibraryManagementItemsActions.moveLibraryItemSuccess()),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during moving item to folder',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryManagementItemsActions.moveLibraryItemFail({ error }))
          })
        )
      )
    )
  )

  createFolderAndMoveLibraryItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementItemsActionTypes.CREATE_FOLDER_AND_MOVE_LIBRARY_ITEM),
      withLatestFrom(this.store.select(LibraryManagementItemsSelectors.selectDialogId)),
      switchMap(([{ id, folderId, name }, dialogId]: [CreateFolderAndMoveLibraryItemAction, string]) =>
        this.libraryFoldersService
          .createFolder(getPlainHttpParams(this.libraryApiMappingService.mapFolderToApi({ name }, { id: folderId })))
          .pipe(
            switchMap((folder: LibraryManagementFolder) => {
              this.dialog.getDialogById(dialogId).close()
              return [
                LibraryManagementItemsActions.moveLibraryItem({ id, folderId: folder.id }),
                LibraryManagementItemsActions.createFolderAndMoveLibraryItemSuccess({ folder }),
              ]
            }),
            catchError((error: any) => {
              if (error.status === 409) {
                this.setAlreadyExistsError('Folder', dialogId)
                return of(LibraryManagementItemsActions.createFolderAndMoveLibraryItemFail(error))
              }

              console.error(error)
              this.snackbarService.showSnackbar(
                'An error occurred during moving item to folder',
                SNACKBAR_LENGTH_LONG,
                SnackbarEventType.ERROR
              )
              return of(LibraryManagementItemsActions.createFolderAndMoveLibraryItemFail({ error }))
            })
          )
      )
    )
  )

  deleteLibraryEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementItemsActionTypes.DELETE_LIBRARY_ENTRY),
      switchMap(({ item }: DeleteLibraryEntryAction) =>
        this.libraryItemsService
          .deleteLibraryEntry(
            item.deliverable?.id || item.libraryComponentTemplate?.id,
            item.id,
            item.deliverable ? LibraryItemTypes.DELIVERABLE : LibraryItemTypes.COMPONENT
          )
          .pipe(map(() => LibraryManagementItemsActions.deleteLibraryEntrySuccess({ item })))
      ),
      catchError((error) => {
        console.error(error)
        this.snackbarService.showSnackbar(
          'An error occurred during deleting Library Entry',
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
        return of(LibraryManagementItemsActions.deleteLibraryEntryFail(error))
      })
    )
  )

  deleteLibraryEntrySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LibraryManagementItemsActionTypes.DELETE_LIBRARY_ENTRY_SUCCESS),
        map(({ item }: DeleteLibraryEntryAction) =>
          this.router.navigate([
            `library/${item.deliverable ? 'deliverable' : 'component'}/${
              item.deliverable?.id || item.libraryComponentTemplate?.id
            }`,
          ])
        )
      )
    },
    { dispatch: false }
  )

  getDataAfterChanges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        LibraryManagementItemsActionTypes.ARCHIVE_LIBRARY_ITEM_SUCCESS,
        LibraryManagementItemsActionTypes.DELETE_LIBRARY_ITEM_SUCCESS,
        LibraryManagementItemsActionTypes.MOVE_LIBRARY_ITEM_SUCCESS,
        LibraryManagementItemsActionTypes.DUPLICATE_LIBRARY_ITEM_SUCCESS
      ),
      switchMap(() => [
        LibraryManagementItemsActions.getLibraryItems(),
        LibraryManagementItemsActions.getArchivedLibraryItems(),
      ])
    )
  })

  setAlreadyExistsError(type: string, dialogId: string) {
    let modalForm = this.dialog.getDialogById(dialogId).componentInstance.modalForm
    modalForm.get('name').setErrors({ [LibraryFormError.ALREADY_EXISTS]: `${type} with this name already exists` })
    modalForm.markAllAsTouched()
  }
}

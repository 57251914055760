import { LibraryDepartment } from './library-department.model'
import { LibraryLocationCard } from './library-location-card.model'

export class LibraryRateCard {
  createdTs: string
  currencyCode: string
  id: number
  name: string
  userAdditionalPrivileges: string[]
  userPrivilegeRestrictions: string[]
  version: number
  rateCardIdentity: {
    archived: boolean
    company: { id: number; name: string; child: boolean; parentCompany: number }
    costPlus: boolean
    currentVersionNumber: number
    defaultCard: boolean
    id: number
    scopeMarkCard: boolean
    trafficImported: boolean
    updatedTs: string
  }
  nameWithVersion: string
  departments?: LibraryDepartment[]
  locationCards: LibraryLocationCard[]

  getTotalRolesCount() {
    return this.departments.flatMap((d) => d.roles).length
  }
}

<div class="flex flex-col configuration-wrapper">
  <div class="flex flex-row flex-1 overflow-hidden">
    <div class="datapoint-nav-container">
      <div class="title">
        <mat-icon class="material-symbols-rounded">edit_document</mat-icon><h3 class="product-heading-bold">Categories</h3>
      </div>
      <ul>
        <ng-container *ngFor="let category of scenario?.categories; trackBy: trackById">
          <li class="data-tab-nodes" *ngIf="!categoryStates[category.id].excluded"
              [ngClass]="{'active': category.id === selectedCategoryId, 'disabled': editable && !categoryStates[category.id]?.enabled}"
              (click)="selectCategory(category.id)">
            <span class="flex-1">{{category.name}}</span>
            <ng-container *ngIf="categoryStates[category.id]?.completed">
              <mat-icon class="material-symbols-rounded completed" *ngIf="!categoryStates[category.id]?.errorCount">check</mat-icon>
              <span class="error" *ngIf="categoryStates[category.id]?.errorCount">
                {{categoryStates[category.id]?.errorCount}}</span>
            </ng-container>
            <mat-icon class="material-symbols-rounded orange" *ngIf="!editable || categoryStates[category.id]?.enabled">chevron_right</mat-icon>
          </li>
        </ng-container>
      </ul>
    </div>

    <ng-container *ngFor="let category of scenario?.categories; trackBy: trackById">
      <div class="datapoint-content-container" *ngIf="category.id === selectedCategoryId">
        <div class="datapoint-content-container-inner">
          <h2 class="product-heading-bold">{{category.name}}</h2>
          <div class="wrapper">
            <ng-container *ngFor="let question of category.questions; trackBy: trackById">
              <ng-container *ngIf="!questionStates[question.id].excluded && !question.hidden">
                <div class="question">
                  <span>{{question.displayText}}</span><span class="orange" *ngIf="question.mandatory"> *</span>
                </div>
                <scope-ui-input id="{{question.id}}" *ngIf="question.type === QuestionType.TEXT" [type]="'text'"
                                class="form-group pb-8 w-full" [initialValue]="question.value" [isTextarea]="true"
                                [isDisabled]="!editable" [control]="questionStates[question.id].control" [textareaRows]="1"
                                [inputPlaceholder]="question.fieldLabel" [formSubmitted]="formSubmitted"
                                [autoExpand]="true" [maximumLength]="question.maxLength || 9999" (onInputBlurChange)="setAnswer(question, $event)" />
                <scope-ui-input id="{{question.fieldId}}" *ngIf="question.type === QuestionType.NUMBER" [type]="'number'"
                                class="form-group pb-8 w-full" [inputPlaceholder]="question.fieldLabel"
                                [isDisabled]="!editable" [initialValue]="question.value" [noArrows]="true"
                                [control]="questionStates[question.id].control" [formSubmitted]="formSubmitted"
                                [isInteger]="question.wholeNumber" [minimum]="question.minimum" [maximum]="question.maximum"
                                (onInputBlurChange)="setAnswer(question, $event)"/>
                <scope-ui-datepicker id="{{question.id}}" *ngIf="question.type === QuestionType.DATE"
                                     [singleDatePicker]="true" [inputPlaceholder]="question.fieldLabel"
                                     [initialValue]="question.value" [disabled]="!editable"
                                     [formSubmitted]="formSubmitted" [dateControl]="questionStates[question.id].control"
                                     [minDate]="question.minDate" [maxDate]="question.maxDate"
                                     (onSelect)="setAnswer(question, $event)" />
                <scope-ui-dropdown id="{{question.id}}" *ngIf="question.type === QuestionType.DROPDOWN"
                                   [selectedValue]="question.value" [isStandardDropdown]="true"
                                   [disabled]="!editable" [formSubmitted]="formSubmitted"
                                   [selectControl]="questionStates[question.id].control" [inputPlaceholder]="question.fieldLabel"
                                   [dropdownOptions]="question.optionsList.split(',')"
                                   (onSelectionChange)="setAnswer(question, $event)" />
                <div id="{{question.id}}" class="dropdown-container" *ngIf="question.type === QuestionType.DELIVERABLE">
                  <div class="pinned-deliverables">
                    <div class="pinned-items">
                      <ng-container *ngFor="let deliverable of questionStates[question.id].deliverablePromptOptions;">
                        <div *ngIf="deliverable.selected && deliverableMap[deliverable.deliverableId] as deliverableEntryDetails" class="pinned-deliverable">
                          {{ deliverableEntryDetails.libraryItemName + " (" + deliverableEntryDetails.disciplineName + ")" }}
                          <mat-icon class="material-symbols-rounded center pointer" (click)="unselectDeliverable(deliverable, question)" *ngIf="!deliverable.required">close</mat-icon>
                        </div>
                      </ng-container>
                    </div>
                    <mat-icon *ngIf="isDeliverableToggleAvailable(question)" class="material-symbols-rounded orange pointer" (click)="toggleDropdown(question.id)">
                      {{ isDropdownOpen.get(question.id) ? 'close' : 'add' }}
                    </mat-icon>
                  </div>
                  <div class="dropdown-menu" *ngIf="isDropdownOpen.get(question.id)">
                    <ng-container *ngFor="let deliverable of questionStates[question.id].deliverablePromptOptions; trackBy: trackByDeliverableId">
                      <div
                        class="dropdown-item"
                        *ngIf="deliverableMap[deliverable.deliverableId] as deliverableEntryDetails"
                        (click)="selectDeliverable(deliverable, question)">
                        <span class="dropdown-item-label" [ngClass]="{ 'bold': deliverable.selected }">
                          {{ deliverableEntryDetails.libraryItemName + " (" + deliverableEntryDetails.disciplineName + ")" }}
                        </span>
                        <span class="dropdown-item-icon">
                          <mat-icon *ngIf="deliverable.selected" class="material-symbols-rounded">check</mat-icon>
                        </span>
                      </div>
                    </ng-container>
                  </div>
                  <mat-error *ngIf="formSubmitted && question.mandatory && questionStates[question.id].control?.invalid">
                    At least one deliverable must be selected.
                  </mat-error>
                </div>
                <div id="{{question.id}}" class="dropdown-container" *ngIf="question.type === QuestionType.FEE">
                  <div class="pinned-deliverables">
                    <div class="pinned-items">
                      <span *ngFor="let fee of questionStates[question.id].feePromptOptions;">
                        <div *ngIf="fee.selected" class="pinned-deliverable">{{ fee.name }}
                          <mat-icon class="material-symbols-rounded center pointer" (click)="unselectFee(fee, question)" *ngIf="!fee.required">close</mat-icon>
                        </div>
                      </span>
                    </div>
                    <mat-icon *ngIf="isFeeToggleAvailable(question)" class="material-symbols-rounded orange pointer" (click)="toggleDropdown(question.id)">
                      {{ isDropdownOpen.get(question.id) ? 'close' : 'add' }}
                    </mat-icon>
                  </div>
                  <div class="dropdown-menu" *ngIf="isDropdownOpen.get(question.id)">
                    <div
                      class="dropdown-item"
                      *ngFor="let fee of questionStates[question.id].feePromptOptions; trackBy: trackByFeeId"
                      (click)="selectFee(fee, question)">
                      <span class="dropdown-item-label" [ngClass]="{ 'bold': fee.selected }">
                        {{ fee.name }}
                      </span>
                      <span class="dropdown-item-icon">
                        <mat-icon *ngIf="fee.selected" class="material-symbols-rounded">check</mat-icon>
                      </span>
                    </div>
                  </div>
                  <mat-error *ngIf="formSubmitted && question.mandatory && questionStates[question.id].control?.invalid">
                    At least one fee must be selected.
                  </mat-error>
                </div>
                <div class="formula-container" *ngIf="question.type === QuestionType.FORMULA">
                  <span *ngIf="questionStates[question.id]?.calcError" class="result text-error-600">
                    Value cannot be calculated. Please contact your administrator.
                  </span>
                  <span class="result">{{configuration[category.id]?.[question.id]}}</span>
                  <button class="orange text-link small" (click)="showFormulaMap[question.id] = !showFormulaMap[question.id]">
                    {{showFormulaMap[question.id] ? 'Hide Formula' : 'View Formula'}}
                  </button>
                </div>
                <formula-builder id="{{question.id}}" class="w-full col-span-2"
                                 *ngIf="question.type === QuestionType.FORMULA" [hidden]="!showFormulaMap[question.id]"
                                 [scenario]="scenario" [(value)]="question.formula" [showInput]="false"
                                 [showFormula]="showFormulaMap[question.id]" />
                <div class="w-full col-span-2" *ngIf="question.type !== QuestionType.FORMULA" [hidden]="true"></div>
              </ng-container>
            </ng-container>
          </div>
          <ng-container *ngIf="!(saveLoading$ | async) && !(loadingModifiers$ | async) && !(submitLoading$ | async) && editable">
            <button *ngIf="(categoryStates[category.id]?.updated) && !(totalErrorCount > categoryStates[category.id]?.errorCount)"
                    mat-flat-button class="action-button" [disabled]="!editable" (click)="save(scenario, category)">
              Save answers
            </button>
            <ng-container *ngIf="!categoryStates[category.id]?.updated">
              <ng-template #nextButton let-nextCategoryId="nextCategoryId">
                <button *ngIf="nextCategoryId && !categoryStates[nextCategoryId]?.enabled" mat-flat-button
                        class="action-button" (click)="save(scenario, category, true)" [disabled]="!editable">
                  Next Category
                </button>
                <button *ngIf="nextCategoryId && categoryStates[nextCategoryId]?.enabled" mat-flat-button
                        class="action-button" (click)="selectCategory(nextCategoryId)" [disabled]="!editable">
                  Next Category
                </button>
                <button *ngIf="!nextCategoryId && !configurationSubmitted" mat-flat-button class="action-button" (click)="submit()"
                        [disabled]="!editable">
                  Submit Form
                </button>
              </ng-template>
              <ng-container *ngTemplateOutlet="nextButton; context: {nextCategoryId: includedCategories[includedCategories.indexOf(category.id) + 1]}"></ng-container>
            </ng-container>
          </ng-container>
          <button mat-flat-button class="action-button active" *ngIf="(saveLoading$ | async) || (loadingModifiers$ | async) || (submitLoading$ | async)">
            <img ngSrc="/assets/icons/loading.svg" alt="loading" class="icon-spinner" height="25" width="24">
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>

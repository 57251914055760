import { LibraryItemType } from '@app/features/library-management/store/enums'
import { LibraryManagementEntry } from './library-management-entry.model'
import { Privilege } from '@app/core/model/enums/privilege.enum'
import { TableColumnKey } from '@app/shared/components/ui-components/scope-ui-table/table-column-key.enum'
import { libraryDeletedDisciplineDisplayName } from '@app/features/library-management/store/const/library-deleted-discipline-display-name.const'

export const libraryItemsColumnsBaseConfig = {
  [TableColumnKey.NAME]: {
    key: TableColumnKey.NAME,
    name: 'NAME',
    field: 'name',
    selected: true,
    isVisibleInList: () => false,
    valueIconFunction: (entity: any) => {
      switch (entity.itemType) {
        case LibraryItemType.COMPONENT:
          return entity.fixedPricing ? 'view_day' : 'view_agenda'
        case LibraryItemType.DELIVERABLE:
          return entity.fixedPricing ? 'book' : 'description'
        case LibraryItemType.THIRD_PARTY_COST:
          return 'view_week'
        case LibraryItemType.FOLDER:
          return entity.isSharedByCompanies ? 'folder_shared' : 'folder'
      }
      return 'equalizer'
    },
  },
  [TableColumnKey.LIBRARY_ITEM_TYPE]: {
    key: TableColumnKey.LIBRARY_ITEM_TYPE,
    name: 'Item Type',
    field: 'libraryItemType',
    selected: true,
    noSort: true,
  },
  [TableColumnKey.CREATED_BY]: {
    key: TableColumnKey.CREATED_BY,
    name: 'Created By',
    field: 'createdBy.company.name',
    selected: true,
  },
  [TableColumnKey.DATE_CREATED]: {
    key: TableColumnKey.DATE_CREATED,
    name: 'Date created',
    field: 'createdTs',
    selected: true,
  },
  [TableColumnKey.LAST_EDITED]: {
    key: TableColumnKey.LAST_EDITED,
    name: 'Last edited',
    field: 'updatedTs',
    selected: true,
  },
}

export const libraryItemEntriesColumnsBaseConfig = {
  [TableColumnKey.NAME]: {
    key: TableColumnKey.NAME,
    name: '{LANG:discipline|u}',
    field: 'disciplineTypeName',
    selected: true,
    isVisibleInList: () => false,
    value: (entry: LibraryManagementEntry) =>
      entry?.disciplineTypeName?.length ? entry?.disciplineTypeName : libraryDeletedDisciplineDisplayName,
  },
  [TableColumnKey.VALUE]: {
    key: TableColumnKey.VALUE,
    name: 'Value',
    field: 'value',
    selected: true,
    requiredPrivilege: Privilege.LIBRARY__VIEW_RATES,
  },
  [TableColumnKey.RATECARD]: {
    key: TableColumnKey.RATECARD,
    name: 'Ratecard',
    field: 'rateCardVersion.name',
    selected: true,
  },
  [TableColumnKey.RATECARD_VERSION]: {
    key: TableColumnKey.RATECARD_VERSION,
    name: 'Ratecard Version',
    field: 'rateCardVersion.version',
    selected: true,
  },
  [TableColumnKey.CREATED_BY]: {
    key: TableColumnKey.CREATED_BY,
    name: 'Created By',
    field: 'createdBy.company.name',
    selected: true,
  },
  [TableColumnKey.DATE_CREATED]: {
    key: TableColumnKey.DATE_CREATED,
    name: 'Date created',
    field: 'createdTs',
    selected: true,
  },
  [TableColumnKey.LAST_EDITED]: {
    key: TableColumnKey.LAST_EDITED,
    name: 'Last edited',
    field: 'updatedTs',
    selected: true,
  },
}

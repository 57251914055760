import {
  LibraryDiscipline,
  LibraryHighlights,
  LibraryHighlightsCounts,
  LibraryManagementFilters,
  LibraryRateCard,
} from '@app/features/library-management/store/models'
import {
  LibraryManagementFeatureState,
  LibraryManagementState,
} from '@app/features/library-management/store/models/state'

import { BreadCrumb } from '@app/core/model/definitions/breadcrumb.interface'
import { LibraryItemType } from '@app/features/library-management/store/enums'
import { LibraryManagementFolder } from '@app/features/library-management/store/models/library-folders'
import { LibraryManagementFoldersSelectors } from './library-management-folders.selectors'
import { createSelector } from '@ngrx/store'
import { getLibraryManagementFeatureState } from './library-management-feature.selectors'

const getLibraryManagementState = createSelector(
  getLibraryManagementFeatureState,
  (state: LibraryManagementFeatureState): LibraryManagementState => state?.libraryManagement
)

const selectIsLoading = createSelector(
  getLibraryManagementState,
  (state: LibraryManagementState): boolean => state?.isLoading
)

const selectDialogId = createSelector(
  getLibraryManagementState,
  (state: LibraryManagementState): string => state?.dialogId
)

const selectHighlights = createSelector(
  getLibraryManagementState,
  (state: LibraryManagementState): LibraryHighlights => state?.highlights
)

const selectHighlightsCounts = createSelector(
  selectHighlights,
  (state: LibraryHighlights): LibraryHighlightsCounts => state?.counts
)

const selectFilters = createSelector(
  getLibraryManagementState,
  (state: LibraryManagementState): LibraryManagementFilters => state?.filters
)

const selectFolderId = createSelector(selectFilters, (filters: LibraryManagementFilters): number => filters?.folderId)

const selectActiveType = createSelector(
  selectFilters,
  (state: LibraryManagementFilters): LibraryItemType => state?.itemTypes
)

const selectSearch = createSelector(selectFilters, (state: LibraryManagementFilters): string => state?.searchString)

const selectIsRateCardsLoaded = createSelector(
  getLibraryManagementState,
  (state: LibraryManagementState): boolean => state?.isRateCardsLoaded
)

const selectIsDisciplinesLoaded = createSelector(
  getLibraryManagementState,
  (state: LibraryManagementState): boolean => state?.isDisciplinesLoaded
)

const selectRateCards = createSelector(
  getLibraryManagementState,
  (state: LibraryManagementState): LibraryRateCard[] => state?.rateCards
)

const selectDisciplines = createSelector(
  getLibraryManagementState,
  (state: LibraryManagementState): LibraryDiscipline[] => state?.disciplines
)

const selectFolder = createSelector(
  getLibraryManagementState,
  (state: LibraryManagementState): LibraryManagementFolder => state?.folder
)

const selectBreadcrumbs = createSelector(
  selectFolder,
  LibraryManagementFoldersSelectors.selectAllFoldersMap,
  (folder: LibraryManagementFolder, foldersMap: { [key: number]: LibraryManagementFolder }): BreadCrumb[] => {
    const breadcrumbs = []
    const libraryBreadcrumb = folder ? { label: 'Library', url: '/library' } : undefined

    while (folder) {
      breadcrumbs.unshift({ label: folder.name, url: `/library/folder/${folder.id}` })
      folder = foldersMap[folder.libraryFolder?.id]
    }

    if (libraryBreadcrumb) breadcrumbs.unshift(libraryBreadcrumb)

    return breadcrumbs
  }
)

export const LibraryManagementSelectors = {
  selectIsLoading,
  selectDialogId,
  selectHighlights,
  selectHighlightsCounts,
  selectFilters,
  selectFolderId,
  selectActiveType,
  selectSearch,
  selectIsRateCardsLoaded,
  selectIsDisciplinesLoaded,
  selectRateCards,
  selectDisciplines,
  selectFolder,
  selectBreadcrumbs,
}

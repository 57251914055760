import { Action, createReducer, on } from '@ngrx/store';
import { CompanyManagementState } from '../../models/company-management.state.model';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import {
  CompanyManagementActions
} from '@app/features/company-management/store/actions/company-management.actions';
import { Page } from '@app/features/scoping/models/page.model';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { BootstrapActions } from '@core/store';
import {
  modifierColumnBaseConfig,
  taskColumnBaseConfig
} from '@app/features/company-management/models/manage-columns.model';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model';
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model';
import { cloneDeep } from 'lodash';
import { ComponentModifier } from '@app/features/company-management/models/component-modifier.model';
import {
  LibraryManagementEntry
} from '@app/features/library-management/store/models/library-items';
import { plainToInstance } from 'class-transformer';
import { Complexity } from '@core/model/enums/complexity.enum';
import { ComponentEntryFilter } from '@core/model/component-entry-filter.model';
import { ComponentEntryDetailsView } from '@app/features/company-management/models/component-entry-details-view';

export const initialState: CompanyManagementState = {
  outputTemplates: [],
  loadingOutputTemplates: false,
  loadedOutputTemplates: false,
  groupId: undefined,
  tasks: new Page<Deliverable>(Deliverable),
  taskColumns: taskColumnBaseConfig,
  loadingTasks: false,
  loadedTasks: false,
  scenarios: [],
  loadingScenarios: false,
  loadedScenarios: false,
  scenario: undefined,
  loadingScenario: false,
  loadedScenario: false,
  modifiers: [],
  newModifier: undefined,
  modifierColumns: modifierColumnBaseConfig,
  loadingModifiers: false,
  loadedModifiers: false,
  loadingUpdateModifiers: false,
  searchedComponentEntries: new Map(),
  loadingComponentEntries: false,
  loadedComponentEntries: false,
  rateCards: [],
  disciplines: [],
  isRateCardsLoaded: false,
  isDisciplinesLoaded: false,
  lastComponentEntriesFetched: false,
  componentEntriesTotalCount: undefined,
  hideModifierOverrideWarning: false,
  loadingAddUpdateScenarioQuestion: false,
  createQuestionError: null,
  loadingAddUpdateScenarioCategory: false
};

function getOutputTemplatesHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingOutputTemplates: true,
    loadedOutputTemplates: false,
  };
}

function getOutputTemplatesSuccessHandler(state: CompanyManagementState, action: { outputTemplates: OutputTemplate[] }) {
  return {
    ...state,
    outputTemplates: action.outputTemplates,
    loadingOutputTemplates: false,
    loadedOutputTemplates: true,
  };
}

function getOutputTemplatesFailHandler(state: CompanyManagementState, action: { error: Error }) {
  return {
    ...state,
    loadingOutputTemplates: false,
    loadedOutputTemplates: true,
    error: action.error,
  };
}

function getTasksHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingTasks: true,
    loadedTasks: false,
  };
}

function getTasksSuccessHandler(state: CompanyManagementState, action: { tasks: Page<Deliverable> }) {
  return {
    ...state,
    tasks: action.tasks,
    loadingTasks: false,
    loadedTasks: true,
  };
}

function getTasksFailHandler(state: CompanyManagementState, action: { error: Error }) {
  return {
    ...state,
    loadingTasks: false,
    loadedTasks: true,
    error: action.error,
  };
}

function setGroupIdHandler(state: CompanyManagementState, { groupId }) {
  return {
    ...state,
    groupId
  };
}

function loadTaskPreferencesSuccessHandler(state: CompanyManagementState, action: any) {
  return {
    ...state,
    taskColumns: {
      ...state.taskColumns,
      ...action.preferences
    }
  }
}

function updateTaskColumnsHandler(state: CompanyManagementState, action: any) {
  const { preference } = action

  return {
    ...state,
    taskColumns: {
      ...state.taskColumns,
      [preference.key]: preference,
    },
  }
}

function getScenariosHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarios: true,
    loadedScenarios: false,
  };
}

function getScenariosSuccessHandler(state: CompanyManagementState, action: { scenarios: Scenario[] }) {
  return {
    ...state,
    scenarios: action.scenarios,
    loadingScenarios: false,
    loadedScenarios: true,
  };
}

function getScenariosFailHandler(state: CompanyManagementState, action: { error: Error }) {
  return {
    ...state,
    loadingScenarios: false,
    loadedScenarios: true,
    error: action.error,
  };
}

function createScenarioHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarios: true,
  };
}

function createScenarioSuccessHandler(state: CompanyManagementState, action: any) {
  return {
    ...state,
    scenarios: [...state.scenarios, action.scenario],
    loadingScenarios: false,
  };
}

function createScenarioFailHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarios: false,
  };
}

function deleteScenarioHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarios: true,
  };
}

function deleteScenarioSuccessHandler(state: CompanyManagementState, action: any) {
  return {
    ...state,
    scenarios: state.scenarios.filter((s) => s.id !== action.id),
    loadingScenarios: false,
  };
}

function deleteScenarioFailHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarios: false,
  };
}

function updateScenarioHandler(state: CompanyManagementState, action: { scenario: Scenario }) {
  return {
    ...state,
    scenario: action.scenario,
    loadingScenarios: true,
  };
}

function updateScenarioSuccessHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarios: false,
  };
}

function updateScenarioFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenarios: false,
    error: action.error,
  };
}

function getScenarioHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenario: true,
    loadedScenario: false,
  };
}

function getScenarioSuccessHandler(state: CompanyManagementState, action: { scenario: Scenario }) {
  return {
    ...state,
    scenario: action.scenario,
    loadingScenario: false,
    loadedScenario: true,
  };
}

function getScenarioFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenario: false,
    loadedScenario: true,
    error: action.error,
  };
}

function createScenarioCategoryHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingAddUpdateScenarioCategory: true,
  };
}

function createScenarioCategorySuccessHandler(state: CompanyManagementState, action: { category: ScenarioCategory }) {
  return {
    ...state,
    scenario: {...state.scenario, categories: [...state.scenario.categories, action.category]},
    loadingAddUpdateScenarioCategory: false,
  };
}

function createScenarioCategoryFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingAddUpdateScenarioCategory: false,
    error: action.error,
  };
}

function deleteScenarioCategoryHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenario: true,
  };
}

function deleteScenarioCategorySuccessHandler(state: CompanyManagementState, action: { categoryId: number }) {
  return {
    ...state,
    scenario: {...state.scenario, categories: state.scenario.categories.filter(category => category.id !== action.categoryId)},
    loadingScenario: false,
  };
}

function deleteScenarioCategoryFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenario: false,
    error: action.error,
  };
}

function reorderScenarioCategoriesHandler(state: CompanyManagementState, action: { scenarioId, orderedCategoryIds }) {
  let categories = cloneDeep(state.scenario.categories)
  action.orderedCategoryIds.forEach((id, index) => {
    categories.find((q) => q.id == id).orderIndex = index
  })
  return {
    ...state,
    scenario: { ...state.scenario, categories: categories }
  };
}

function updateScenarioCategoryHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingAddUpdateScenarioCategory: true,
  };
}

function updateScenarioCategorySuccessHandler(state: CompanyManagementState, action: { category: ScenarioCategory }) {
  return {
    ...state,
    scenario: {...state.scenario, categories: state.scenario.categories.map(category =>
        category.id === action.category.id ? action.category : category)},
    loadingAddUpdateScenarioCategory: false,
  };
}

function updateScenarioCategoryFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingAddUpdateScenarioCategory: false,
    error: action.error,
  };
}

function getScenarioQuestionsHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarioQuestions: true,
    loadedScenarioQuestions: false,
  };
}

function getScenarioQuestionsSuccessHandler(state: CompanyManagementState, action: { categoryId: number, scenarioQuestions: ScenarioQuestion[] }) {
  return {
    ...state,
    scenario: { ...state.scenario, categories: state.scenario.categories
        .map((c) => c.id === action.categoryId ? { ...c, questions: action.scenarioQuestions} : c) },
    loadingScenarioQuestions: false,
    loadedScenarioQuestions: true,
  };
}

function getScenarioQuestionsFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenarioQuestions: false,
    loadedScenarioQuestions: true,
    error: action.error,
  };
}

function createScenarioQuestionHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingAddUpdateScenarioQuestion: true,
    createQuestionError: null
  };
}

function createScenarioQuestionSuccessHandler(state: CompanyManagementState, action: { categoryId: number, question: ScenarioQuestion }) {
  return {
    ...state,
    scenario: { ...state.scenario, categories: state.scenario.categories
        .map((c) => c.id === action.categoryId ? { ...c, questions: [...c.questions, action.question]} : c) },
    loadingAddUpdateScenarioQuestion: false,
    createQuestionError: null
  };
}

function createScenarioQuestionFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingAddUpdateScenarioQuestion: false,
    createQuestionError: action.error,
  };
}

function deleteScenarioQuestionHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingScenarioQuestions: true,
  };
}

function deleteScenarioQuestionSuccessHandler(state: CompanyManagementState, action: { categoryId: number, questionId: number }) {
  return {
    ...state,
    scenario: { ...state.scenario, categories: state.scenario.categories
        .map((c) => c.id === action.categoryId ? { ...c, questions: c.questions.filter((q) => q.id !== action.questionId)} : c) },
    loadingScenarioQuestions: false,
  };
}

function deleteScenarioQuestionFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingScenarioQuestions: false,
    error: action.error,
  };
}

function reorderScenarioQuestionsHandler(state: CompanyManagementState, action: { scenarioId, categoryId, orderedQuestionIds }) {
  let questions = cloneDeep(state.scenario.categories.find((c) => c.id == action.categoryId).questions)
  action.orderedQuestionIds.forEach((id, index) => {
    questions.find((q) => q.id == id).orderIndex = index
  })
  return {
    ...state,scenario: { ...state.scenario, categories: state.scenario.categories
        .map((c) => c.id === action.categoryId ? { ...c, questions: questions} : c) },
  };
}

function updateScenarioQuestionHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingAddUpdateScenarioQuestion: true,
  };
}

function updateScenarioQuestionSuccessHandler(state: CompanyManagementState, action: { categoryId: number, question: ScenarioQuestion }) {
  return {
    ...state,
    scenario: { ...state.scenario, categories: state.scenario.categories
        .map((c) => c.id === action.categoryId ?
          { ...c, questions: c.questions.map((q) => q.id === action.question.id ? action.question : q)} :
          c) },
    loadingAddUpdateScenarioQuestion: false,
  };
}

function updateScenarioQuestionFailHandler(state: CompanyManagementState, action: { error: any }) {
  return {
    ...state,
    loadingAddUpdateScenarioQuestion: false,
    error: action.error,
  };
}

function getModifiersHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingModifiers: true,
    loadedModifiers: false,
  };
}

function getModifiersSuccessHandler(state: CompanyManagementState, action: { componentModifiers: ComponentModifier[] }) {
  return {
    ...state,
    modifiers: action.componentModifiers,
    loadingModifiers: false,
    loadedModifiers: true,
  };
}

function getModifiersFailHandler(state: CompanyManagementState, action: { error: Error }) {
  return {
    ...state,
    loadingModifiers: false,
    loadedModifiers: true,
    error: action.error,
  };
}

function getComponentModifierHandler(state: CompanyManagementState) {
  return {
    ...state,
    newModifier: undefined,
    loadingUpdateModifiers: true,
  };
}

function getComponentModifierSuccessHandler(state: CompanyManagementState, action: any) {
  return {
    ...state,
    modifiers: state.modifiers.map(modifier =>
      modifier.id === action.componentModifier.id ? action.componentModifier : modifier),
    loadingUpdateModifiers: false,
  };
}

function getComponentModifierFailHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingUpdateModifiers: false,
  };
}

function loadModifierPreferencesSuccessHandler(state: CompanyManagementState, action: any) {
  return {
    ...state,
    modifierColumns: {
      ...state.modifierColumns,
      ...action.preferences
    }
  }
}

function updateModifierColumnsHandler(state: CompanyManagementState, action: any) {
  const { preference } = action

  return {
    ...state,
    modifierColumns: {
      ...state.modifierColumns,
      [preference.key]: preference,
    },
  }
}

function deleteComponentModifierSuccessHandler(state: CompanyManagementState, action: { componentModifierId: number}) {
  return {
    ...state,
    modifiers: state.modifiers.filter((m) => m.id !== action.componentModifierId)
  };
}

function createComponentModifierHandler(state: CompanyManagementState) {
  return {
    ...state,
    newModifier: undefined,
    loadingUpdateModifiers: true,
  };
}

function createComponentModifierSuccessHandler(state: CompanyManagementState, action: any) {
  return {
    ...state,
    modifiers: [...state.modifiers, action.componentModifier],
    newModifier: action.componentModifier,
    loadingUpdateModifiers: false,
  };
}

function createComponentModifierFailHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingUpdateModifiers: false,
  };
}

function updateComponentModifierHandler(state: CompanyManagementState) {
  return {
    ...state,
    newModifier: undefined,
    loadingUpdateModifiers: true,
  };
}

function updateComponentModifierSuccessHandler(state: CompanyManagementState, action: any) {
  return {
    ...state,
    modifiers: state.modifiers.map(modifier =>
      modifier.id === action.componentModifier.id ? action.componentModifier : modifier),
    newModifier: action.componentModifier,
    loadingUpdateModifiers: false,
  };
}

function updateComponentModifierFailHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingUpdateModifiers: false,
  };
}

function assignComponentModifierHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingUpdateModifiers: true,
  };
}

function assignComponentModifierSuccessHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingUpdateModifiers: false,
  };
}

function assignComponentModifierFailHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingUpdateModifiers: false,
  };
}

function searchComponentEntriesHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingComponentEntries: true,
    loadedComponentEntries: false,
  };
}

function searchComponentEntriesSuccessHandler(state: CompanyManagementState, action: { componentEntries: Page<ComponentEntryDetailsView>, filter: ComponentEntryFilter }) {
  let componentEntryMap: Map<number, LibraryManagementEntry> = action.componentEntries.number == 0 ?
    new Map() : state.searchedComponentEntries
  action.componentEntries.content.map((c) => {
    let componentEntry = componentEntryMap.get(c.libraryComponentEntryId)
    if (!componentEntry) {
      componentEntry = plainToInstance(LibraryManagementEntry, {
        id: c.libraryComponentEntryId,
        libraryComponentTemplate: { id: c.libraryItemId, name: c.libraryItemName },
        disciplineTypeId: c.disciplineTypeId,
        disciplineTypeName: c.disciplineName,
        fixedPricing: c.fixedPricing,
        rateCardVersion: { id: c.rateCardVersionId, name: c.rateCardVersionName, version: c.rateCardVersion },
        componentComplexities: {}
      })
    }
    componentEntry.componentComplexities[c.componentComplexitiesKey] = {
      id: c.complexityRoleId as number,
      complexity: Complexity[c.componentComplexitiesKey],
      aliasName: c.complexityRoleAliasName
    }
    componentEntryMap.set(c.libraryComponentEntryId, componentEntry)
  })
  let hasFilter = action.filter.fixedPricing != null || action.filter.disciplineTypeIds.length ||
    action.filter.rateCardVersionIds.length || action.filter.libraryItemName.length
  return {
    ...state,
    searchedComponentEntries: componentEntryMap,
    lastComponentEntriesFetched: action.componentEntries.last,
    componentEntriesTotalCount: hasFilter ? state.componentEntriesTotalCount : action.componentEntries.totalElements,
    loadingComponentEntries: false,
    loadedComponentEntries: true,
  };
}

function searchComponentEntriesFailHandler(state: CompanyManagementState, action: { error: Error }) {
  return {
    ...state,
    loadingComponentEntries: false,
    loadedComponentEntries: true,
    error: action.error,
  };
}

const getRateCardsSuccessHandler = (state: CompanyManagementState, action: any) => ({
  ...state,
  rateCards: action.rateCards,
  isRateCardsLoaded: true,
})

const getDisciplinesSuccessHandler = (state: CompanyManagementState, action: any) => ({
  ...state,
  disciplines: action.disciplines,
  isDisciplinesLoaded: true,
})

function getModifierOverrideWarningPreferenceSuccessHandler(state: CompanyManagementState, action: any) {
  return {
    ...state,
    hideModifierOverrideWarning: action.preferences?.HideModifierOverrideWarning
  }
}

function updateModifierOverrideWarningPreferenceHandler(state: CompanyManagementState, action: any) {
  return {
    ...state,
    hideModifierOverrideWarning: action.preferences.HideModifierOverrideWarning,
  }
}

export const CompanyManagementReducer = createReducer(
  initialState,
  on(CompanyManagementActions.getOutputTemplates, getOutputTemplatesHandler),
  on(CompanyManagementActions.getOutputTemplatesSuccess, getOutputTemplatesSuccessHandler),
  on(CompanyManagementActions.getOutputTemplatesFail, getOutputTemplatesFailHandler),
  on(CompanyManagementActions.getTasks, getTasksHandler),
  on(CompanyManagementActions.getTasksSuccess, getTasksSuccessHandler),
  on(CompanyManagementActions.getTasksFail, getTasksFailHandler),
  on(CompanyManagementActions.setGroupId, setGroupIdHandler),
  on(CompanyManagementActions.getScenarios, getScenariosHandler),
  on(CompanyManagementActions.getScenariosSuccess, getScenariosSuccessHandler),
  on(CompanyManagementActions.getScenariosFail, getScenariosFailHandler),
  on(CompanyManagementActions.createScenario, createScenarioHandler),
  on(CompanyManagementActions.createScenarioSuccess, createScenarioSuccessHandler),
  on(CompanyManagementActions.createScenarioFail, createScenarioFailHandler),
  on(CompanyManagementActions.deleteScenario, deleteScenarioHandler),
  on(CompanyManagementActions.deleteScenarioSuccess, deleteScenarioSuccessHandler),
  on(CompanyManagementActions.deleteScenarioFail, deleteScenarioFailHandler),
  on(CompanyManagementActions.updateScenario, updateScenarioHandler),
  on(CompanyManagementActions.updateScenarioSuccess, updateScenarioSuccessHandler),
  on(CompanyManagementActions.updateScenarioFail, updateScenarioFailHandler),
  on(CompanyManagementActions.getScenario, getScenarioHandler),
  on(CompanyManagementActions.getScenarioSuccess, getScenarioSuccessHandler),
  on(CompanyManagementActions.getScenarioFail, getScenarioFailHandler),
  on(CompanyManagementActions.createScenarioCategory, createScenarioCategoryHandler),
  on(CompanyManagementActions.createScenarioCategorySuccess, createScenarioCategorySuccessHandler),
  on(CompanyManagementActions.createScenarioCategoryFail, createScenarioCategoryFailHandler),
  on(CompanyManagementActions.deleteScenarioCategory, deleteScenarioCategoryHandler),
  on(CompanyManagementActions.deleteScenarioCategorySuccess, deleteScenarioCategorySuccessHandler),
  on(CompanyManagementActions.deleteScenarioCategoryFail, deleteScenarioCategoryFailHandler),
  on(CompanyManagementActions.reorderScenarioCategories, reorderScenarioCategoriesHandler),
  on(CompanyManagementActions.updateScenarioCategory, updateScenarioCategoryHandler),
  on(CompanyManagementActions.updateScenarioCategorySuccess, updateScenarioCategorySuccessHandler),
  on(CompanyManagementActions.updateScenarioCategoryFail, updateScenarioCategoryFailHandler),
  on(CompanyManagementActions.getScenarioQuestions, getScenarioQuestionsHandler),
  on(CompanyManagementActions.getScenarioQuestionsSuccess, getScenarioQuestionsSuccessHandler),
  on(CompanyManagementActions.getScenarioQuestionsFail, getScenarioQuestionsFailHandler),
  on(CompanyManagementActions.createScenarioQuestion, createScenarioQuestionHandler),
  on(CompanyManagementActions.createScenarioQuestionSuccess, createScenarioQuestionSuccessHandler),
  on(CompanyManagementActions.createScenarioQuestionFail, createScenarioQuestionFailHandler),
  on(CompanyManagementActions.deleteScenarioQuestion, deleteScenarioQuestionHandler),
  on(CompanyManagementActions.deleteScenarioQuestionSuccess, deleteScenarioQuestionSuccessHandler),
  on(CompanyManagementActions.deleteScenarioQuestionFail, deleteScenarioQuestionFailHandler),
  on(CompanyManagementActions.reorderScenarioQuestions, reorderScenarioQuestionsHandler),
  on(CompanyManagementActions.updateScenarioQuestion, updateScenarioQuestionHandler),
  on(CompanyManagementActions.updateScenarioQuestionSuccess, updateScenarioQuestionSuccessHandler),
  on(CompanyManagementActions.updateScenarioQuestionFail, updateScenarioQuestionFailHandler),
  on(CompanyManagementActions.getComponentModifiers, getModifiersHandler),
  on(CompanyManagementActions.getComponentModifiersSuccess, getModifiersSuccessHandler),
  on(CompanyManagementActions.getComponentModifiersFail, getModifiersFailHandler),
  on(CompanyManagementActions.getComponentModifier, getComponentModifierHandler),
  on(CompanyManagementActions.getComponentModifierSuccess, getComponentModifierSuccessHandler),
  on(CompanyManagementActions.getComponentModifierFail, getComponentModifierFailHandler),
  on(CompanyManagementActions.deleteComponentModifierSuccess, deleteComponentModifierSuccessHandler),
  on(CompanyManagementActions.createComponentModifier, createComponentModifierHandler),
  on(CompanyManagementActions.createComponentModifierSuccess, createComponentModifierSuccessHandler),
  on(CompanyManagementActions.createComponentModifierFail, createComponentModifierFailHandler),
  on(CompanyManagementActions.updateComponentModifier, updateComponentModifierHandler),
  on(CompanyManagementActions.updateComponentModifierSuccess, updateComponentModifierSuccessHandler),
  on(CompanyManagementActions.updateComponentModifierFail, updateComponentModifierFailHandler),
  on(CompanyManagementActions.assignComponentModifier, assignComponentModifierHandler),
  on(CompanyManagementActions.assignComponentModifierSuccess, assignComponentModifierSuccessHandler),
  on(CompanyManagementActions.assignComponentModifierFail, assignComponentModifierFailHandler),
  on(CompanyManagementActions.searchComponentEntries, searchComponentEntriesHandler),
  on(CompanyManagementActions.searchComponentEntriesSuccess, searchComponentEntriesSuccessHandler),
  on(CompanyManagementActions.searchComponentEntriesFail, searchComponentEntriesFailHandler),
  on(CompanyManagementActions.getRateCardsSuccess, getRateCardsSuccessHandler),
  on(CompanyManagementActions.getDisciplinesSuccess, getDisciplinesSuccessHandler),
  on(BootstrapActions.loadTaskPreferencesSuccess, loadTaskPreferencesSuccessHandler),
  on(BootstrapActions.updateTaskColumnPreferences, updateTaskColumnsHandler),
  on(BootstrapActions.loadModifierPreferencesSuccess, loadModifierPreferencesSuccessHandler),
  on(BootstrapActions.updateModifierColumnPreferences, updateModifierColumnsHandler),
  on(BootstrapActions.getModifierOverrideWarningPreferenceSuccess, getModifierOverrideWarningPreferenceSuccessHandler),
  on(BootstrapActions.updateModifierOverrideWarningPreference, updateModifierOverrideWarningPreferenceHandler),
);

export function reducer(state: CompanyManagementState | undefined, action: Action): CompanyManagementState {
  return CompanyManagementReducer(state, action);
}

import { Component, Input } from '@angular/core';
import { User } from '@core/model/user.model';
import { AuthService } from '@core/service/auth.service';
import { Preference } from '@core/model/user-preferences.interface';
import { MenuOptions } from '@core/model/definitions/menu-options.interface';
import { combineLatest, map, Observable, take } from 'rxjs';
import { MappedEntity } from '@app/features/scoping/models/mapped-entity.model';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service';
import { Store } from '@ngrx/store';
import {
  CompanyManagementSelectors
} from '@app/features/company-management/store/selectors/company-management.selectors';
import { CompanyManagementActions } from '@app/features/company-management/store/actions/company-management.actions';
import { BootstrapActions } from '@core/store';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '@shared/utils/snackbar.service';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import { ComponentModifier } from '@app/features/company-management/models/component-modifier.model';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import {
  AddEditModifierModalComponent,
  AddEditModifierModalConfig,
} from '@app/features/company-management/components/add-modifier-modal/add-edit-modifier-modal.component';
import { ScopeDynamicFieldSettingModel } from '@app/features/scoping/models/scope-dynamic-field-setting.model';
import { ScopingSelectors } from '@app/features/scoping/store/selectors/scoping.selector';
import { ScopeActions } from '@app/features/scoping/store/actions/scoping.actions';
import { ScopeUiTableSelectMode } from '@shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import {
  AssignModifierModalComponent,
  AssignModifierModalConfig,
} from '@app/features/company-management/components/assign-modifier-modal/assign-modifier-modal.component';
import { LanguageService } from '@core/service/language.service';
import { filter } from 'rxjs/operators';
import { searchInText } from '@app/shared/utils/search-utils.const';

@Component({
  selector: 'component-modifiers',
  templateUrl: './component-modifiers.component.html',
  styleUrls: ['./component-modifiers.component.scss']
})
export class ComponentModifiersComponent {
  @Input() scenario!: Scenario

  loggedInUser!: User
  modifierColumns$!: Observable<Preference[]>
  menuOptions!: MenuOptions[]
  loading$: Observable<boolean>
  modifiers$: Observable<ComponentModifier[]>
  mappedModifiersDataSource$!: Observable<MappedEntity<ComponentModifier>[]>
  searchText: string
  dynamicFields$: Observable<ScopeDynamicFieldSettingModel[]>

  constructor(private authService: AuthService,
              private manageService: CompanyManagementService,
              private mappingService: DataMappingService,
              private store: Store,
              private dialog: MatDialog,
              private snackbarService: SnackbarService,
              private lang: LanguageService) {
    this.loggedInUser = this.authService.loggedInUser
    this.modifiers$ = this.store.select(CompanyManagementSelectors.selectModifiers)
    this.modifierColumns$ = this.store.select(CompanyManagementSelectors.selectModifierColumns)
    this.loading$ = this.store.select(CompanyManagementSelectors.selectLoadingModifiers)
    this.dynamicFields$ = this.store.select(ScopingSelectors.selectDynamicFieldSettings)
  }

  ngOnInit() {
    this.getComponentModifiers()
    this.store.dispatch(BootstrapActions.loadModifierPreferences())
    this.store.dispatch(BootstrapActions.getModifierOverrideWarningPreference())
    this.store.dispatch(ScopeActions.getDynamicFieldSettings())
    this.menuOptions = [
      {
        callback: (element: { entity: ComponentModifier }) => this.editModifier(element.entity),
        name: () => 'Edit/Assign Modifier',
        icon: () => 'edit',
        hasBorder: true
      },
      {
        callback: (element: { entity: ComponentModifier }) => this.deleteModifier(element.entity),
        name: () => 'Delete',
        icon: () => 'delete'
      }
    ]
  }

  getComponentModifiers() {
    this.mappedModifiersDataSource$ = combineLatest([this.modifiers$, this.modifierColumns$]).pipe(
      map(([modifiers, userPreferences]) => {
        return this.mappingService.transformArray<ComponentModifier>(modifiers, userPreferences)
      })
    )
  }

  editModifier(modifier: ComponentModifier) {
    const modalConfig: AddEditModifierModalConfig = {
      scenario: this.scenario,
      dynamicFields$: this.dynamicFields$,
      modifiers$: this.modifiers$,
      modifier: modifier,
      callback: () => dialog.close(),
      assign: (modifier) => this.openAssignModifierModal(modifier, false, true),
      loggedInUser: this.loggedInUser
    };
    let dialog = this.dialog.open(AddEditModifierModalComponent, {
      data: modalConfig
    })
  }

  private deleteModifier(modifier: ComponentModifier) {
    let body = `Are you sure you want to delete "` + modifier.name + `"?`
    if (modifier.complexityRoles.length) {
      body += ` It will be unassigned from ${modifier.complexityRoles.length} ${this.lang.get('component|l')} size${modifier.complexityRoles.length > 1 ? 's' : ''}.`
    }
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete "` + modifier.name + `"`,
        body,
        'Delete',
        undefined,
        () => {
          dialog.close();
          this.manageService.deleteComponentModifier(this.scenario.id, modifier.id).subscribe({
            next: () => {
              this.store.dispatch(CompanyManagementActions.deleteComponentModifierSuccess({ componentModifierId: modifier.id }))
            },
            error: (error) => {
              this.snackbarService.showDefaultErrorSnackbar()
              return error
            },
          })
        },
        undefined,
        [],
        false,
        true
      ),
    })
  }

  onTogglePreference(preference: Preference) {
    this.store.dispatch(BootstrapActions.updateModifierColumnPreferences({ preference: preference }))
  }

  openCreateModal = () => {
    const modalConfig: AddEditModifierModalConfig = {
      scenario: this.scenario,
      dynamicFields$: this.dynamicFields$,
      modifiers$: this.modifiers$,
      callback: (modifier: ComponentModifier) => {
        dialog.close()
        this.openAssignModifierModal(modifier, true)
      },
      loggedInUser: this.loggedInUser
    };
    let dialog = this.dialog.open(AddEditModifierModalComponent, {
      data: modalConfig
    })
  }

  openAssignModifierModal = (modifier: ComponentModifier, isNewModifier: boolean = false, showBackButton = false) => {
    const modalConfig: AssignModifierModalConfig = {
      modifier: modifier,
      isNewModifier: isNewModifier,
      showBackButton: showBackButton,
      assignCallback: (assignComplexities, unassignComplexities: number[], hasOverride: boolean) => {
        this.store.dispatch(CompanyManagementActions.assignComponentModifier({
          scenarioId: this.scenario.id, componentModifierId: modifier.id,  assignComplexities, unassignComplexities
        }))
        this.store.select(CompanyManagementSelectors.selectLoadingUpdateModifiers).pipe(
          filter((loading) => !loading),
          take(1)
        ).subscribe(() => {
          this.store.dispatch(hasOverride ?
            CompanyManagementActions.getComponentModifiers({ scenarioId: this.scenario.id }) :
            CompanyManagementActions.getComponentModifier({ scenarioId: this.scenario.id, componentModifierId: modifier.id }))
          dialog.close()
        })
      },
    }
    let dialog = this.dialog.open(AssignModifierModalComponent, {
      data: modalConfig
    })
  }

  filterModifiers() {
    const filteredModifiers$ = this.modifiers$.pipe(
      map(modifiers => modifiers.filter(modifier => searchInText(modifier.name, this.searchText)))
    );

    this.mappedModifiersDataSource$ = combineLatest([filteredModifiers$, this.modifierColumns$]).pipe(
      map(([modifiers, userPreferences]) => {
        return this.mappingService.transformArray<ComponentModifier>(modifiers, userPreferences)
      })
    )
  }

  protected readonly ScopeUiTableSelectMode = ScopeUiTableSelectMode;
}

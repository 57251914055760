import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { SharedModule } from '@app/shared/shared.module'
import { ScopeUiDatepickerComponent } from '@app/shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component'
import { ScopeUiAutocompleteComponent } from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { ScopeVersion } from '@app/core/model/scope-version'
import { Observable } from 'rxjs'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { NgxMaskDirective } from 'ngx-mask'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { Role } from '@app/features/scoping/models/role.model';
import { Department } from '@app/features/scoping/models/department.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep } from 'lodash';
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service';
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action';
import { Store } from '@ngrx/store';
import { searchInText } from '@app/shared/utils/search-utils.const';

export interface AddRolesModalConfig {
  title: string
  rateCard$: Observable<RatecardVersion>
  currentScope: ScopeVersion
}

@Component({
  selector: 'add-roles-modal',
  standalone: true,
  imports: [CommonModule, ScopeUiDatepickerComponent, ScopeUiAutocompleteComponent, SharedModule, MatDatepickerModule, CKEditorModule, ScopeUiInputComponent, NgxSliderModule, NgxMaskDirective],
  templateUrl: './add-roles-modal.component.html',
  styleUrls: ['./add-roles-modal.component.scss'],
})
export class AddRolesModalComponent implements OnInit {
  searchText: string
  openCloseAll: boolean
  roles: number[]
  ratecard: RatecardVersion

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddRolesModalConfig,
    private scopeService: ScopeOverviewService,
    private store: Store,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
  ) {
    this.roles = []
  }

  ngOnInit(): void {
    this.data.rateCard$.subscribe(rt => this.ratecard = cloneDeep(rt))
    this.roles.push(...this.data.currentScope.scopeRoles.map(r => r.id))
  }

  onSearch(event: string) {
    if (!event) {
      this.openCloseAll = false
      this.ratecard.departments.forEach(d => d.selected = this.openCloseAll)
      return
    }
    this.searchText = event
    this.openCloseAll = true
    this.ratecard.departments.forEach(d => d.selected = this.openCloseAll)
  }

  hideRole(item: Role) {
    return this.searchText?.length ? !searchInText(item.name, this.searchText) : false
  }

  hideDepartment(dep: Department) {
    return dep.roles.every(r => this.hideRole(r));
  }

  expandAll() {
    this.openCloseAll = !this.openCloseAll
    this.ratecard.departments.forEach(d => d.selected = this.openCloseAll)
  }

  checkRole(role: Role) {
    if (this.roleChecked(role)) {
      this.roles = this.roles.filter(r => r !== role.id)
    } else {
      this.roles.push(role.id)
    }
  }

  roleChecked(role: Role) {
    return this.roles.includes(role.id)
  }

  checkDepartment(dep: Department) {
    if (this.departmentChecked(dep)) {
      this.roles = this.roles.filter(r => !dep.roles.map(dr => dr.id).includes(r))
    } else {
      this.roles = this.roles.filter(r => !dep.roles.map(dr => dr.id).includes(r))
      this.roles.push(...dep.roles.map(dr => dr.id))
    }
  }

  checkAll(event: MatCheckboxChange) {
    this.roles = []
    if (event.checked) {
      this.roles.push(...(this.ratecard.departments.flatMap(d => d.roles.map(dr => dr.id))))
    }
  }

  departmentChecked(dep: Department) {
    return dep.roles.every(role => this.roles.includes(role.id));
  }

  depPartChecked(dep: Department) {
    return dep.roles.some(role => this.roles.includes(role.id)) && !dep.roles.every(role => this.roles.includes(role.id));
  }

  departmentRolesCount(dep: Department) {
    return this.roles.filter(r => dep.roles.map(r => r.id).includes(r)).length
  }

  submit() {
    this.scopeService.addScopeRateCardRoles(this.data.currentScope.identity.id, this.roles).subscribe({
      next: () => {
        this.store.dispatch(ScopeOverviewActions.getCurrentScope({ scopeId: this.data.currentScope.identity.id }))
        this.snackbarService.showSnackbar('Roles were updated successfully.', SNACKBAR_LENGTH_LONG, SnackbarEventType.SUCCESS)
      },
      error: () => this.snackbarService.showDefaultErrorSnackbar()
    })
    this.dialog.closeAll()
  }
}

import { LibraryManagementApiMappingService, LibraryManagementService } from '@app/features/library-management/services'
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@app/shared/utils/snackbar.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs'
import {
  LibraryCreateDeliverableActionTypes,
  LibraryCreateDeliverableActions,
  LibraryManagementActions,
} from '../actions'

import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Privilege } from '@app/core/model/enums/privilege.enum'
import { AuthService } from '@app/core/service/auth.service'
import { LanguageService } from '@app/core/service/language.service'
import { LibraryItemName } from '@app/features/library-management/store/enums'
import { LibraryDeliverable } from '@app/features/library-management/store/models/deliverable'
import { Store } from '@ngrx/store'
import { LibraryFormError } from '../enums'
import { LibraryManagementSelectors } from '../selectors'

@Injectable()
export class LibraryCreateDeliverableEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private snackbarService: SnackbarService,
    private libraryManagementService: LibraryManagementService,
    private libraryApiMappingService: LibraryManagementApiMappingService,
    private authService: AuthService,
    private lang: LanguageService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  navigateToDeliverableOverview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryCreateDeliverableActionTypes.NAVIGATE_TO_DELIVERABLE_OVERVIEW),
        map(({ id, entryId }) => {
          this.router.navigate([entryId ? `library/deliverable/${id}/edit/${entryId}` : `library/deliverable/${id}`])
        })
      ),
    { dispatch: false }
  )

  createDeliverable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryCreateDeliverableActionTypes.CREATE_DELIVERABLE),
      withLatestFrom(
        this.store$.select(LibraryManagementSelectors.selectDialogId),
        this.store$.select(LibraryManagementSelectors.selectFolder)
      ),
      switchMap(([{ deliverable, entry }, dialogId, folder]) =>
        this.libraryManagementService
          .createDeliverable(this.libraryApiMappingService.mapDeliverableToApi(deliverable, folder))
          .pipe(
            switchMap((response: LibraryDeliverable) => [
              LibraryCreateDeliverableActions.createDeliverableSuccess({ deliverable: response }),
              ...(this.authService.loggedInUser.hasPrivilege(Privilege.LIBRARY_DELIVERABLE__EDIT)
                ? [LibraryCreateDeliverableActions.createDeliverableEntry({ id: response.id, entry })]
                : [
                    LibraryManagementActions.closeActiveModal(),
                    LibraryCreateDeliverableActions.navigateToDeliverableOverview({ id: response.id }),
                  ]),
            ]),
            catchError((error: any) => {
              if (error.status === 409) {
                this.setAlreadyExistsError(dialogId)
                return of(LibraryCreateDeliverableActions.createDeliverableFail({ error }))
              }

              console.error(error)
              this.snackbarService.showSnackbar(
                'An error occurred during creating Deliverable Template',
                SNACKBAR_LENGTH_LONG,
                SnackbarEventType.ERROR
              )
              return of(LibraryCreateDeliverableActions.createDeliverableFail({ error }))
            })
          )
      )
    )
  )

  createDeliverableEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryCreateDeliverableActionTypes.CREATE_DELIVERABLE_ENTRY),
      map(({ id, entry }) => ({ id, entry: this.libraryApiMappingService.mapDeliverableEntryToApi(entry) })),
      switchMap(({ id, entry }) =>
        this.libraryManagementService.createDeliverableEntry(id, entry).pipe(
          switchMap((entry) => [
            LibraryManagementActions.closeActiveModal(),
            LibraryCreateDeliverableActions.createDeliverableEntrySuccess({ entry }),
            LibraryCreateDeliverableActions.navigateToDeliverableOverview({ id, entryId: entry.id }),
          ]),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during creating Deliverable Template Entry',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryCreateDeliverableActions.createDeliverableEntryFail({ error }))
          })
        )
      )
    )
  )

  setAlreadyExistsError(dialogId: string) {
    let modalForm = this.dialog.getDialogById(dialogId)?.componentInstance.modalForm
    modalForm.get('name').setErrors({
      [LibraryFormError.ALREADY_EXISTS]: `${this.lang.get(LibraryItemName.DELIVERABLE)} with this name already exists`,
    })
    modalForm.markAllAsTouched()
  }
}

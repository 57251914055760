<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>Create {{ 'deliverable' | lang }} Template</h1>
    </div>

    <button
      class="close-button"
      mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content
  aria-label="Generic modal"
  class="modal-container md-dialog-container library">
  <div class="px-10 pb-2 w-full library-modal-content">
    <div class="flex-row">
      <scope-ui-input
        inputClass="scope-input--full-sized"
        inputLabel="{{ 'deliverable' | lang }} Name*"
        [maximumLength]="255"
        [control]="getDeliverableControl('name')" />
    </div>

    <div class="flex-row">
      <description-editor
        class="px-10"
        status="DRAFT"
        [hideNote]="true"
        [descriptionRequired]="true"
        [descriptionDirty]="getDeliverableControl('description').touched"
        [model]="{ description: getDeliverableControlValue('description') }"
        (onSaveDescription)="setDeliverableControlValue('description', $event)" />
    </div>

    <div class="flex-row">
      <scope-ui-dropdown
        class="d-block scope-input--full-sized"
        controlLabel="Item Type"
        dropdownClass="scope-input--full-sized"
        [selectedValue]="typeOptions[0]"
        [dropdownOptions]="typeOptions"
        [isStandardDropdown]="true"
        (onSelectionChange)="setDeliverableControlValue('libraryItemType', $event.id)" />
    </div>

    <ng-container [has-privilege]="PrivilegeEnum.LIBRARY_DELIVERABLE__EDIT">
      <div class="flex-row pt-8">
        <scope-ui-autocomplete
          label="{{ 'deliverable' | lang }} Entry Ratecard*"
          [control]="getEntriesControl('rateCard')"
          [options]="rateCards$ | async" />
      </div>

      <div class="flex-row pt-8">
        <scope-ui-autocomplete
          label="{{ 'deliverable' | lang }} Entry Discipline*"
          [control]="getEntriesControl('discipline')"
          [options]="disciplines$ | async" />
      </div>
    </ng-container>

    <div
      *ngIf="isFixedCost"
      class="flex-row pt-8">
      <scope-ui-toggle
        title="Fixed Fee is editable"
        [initialValue]="true"
        [control]="getEntriesControl('fixedCostEditable')">
      </scope-ui-toggle>
    </div>
  </div>

  <div class="d-flex d-flex-justify-end px-10 pt-8 pb-4">
    <div class="library-action-buttons">
      <button
        mat-button
        mat-dialog-close>
        Cancel
      </button>
      <button
        class="main-action-button ml-4"
        [class.disabled]="!isFormValid"
        mat-button
        (click)="submit()">
        Create
      </button>
    </div>
  </div>
</mat-dialog-content>

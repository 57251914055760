<div class="modal-container">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>Assign the {{data.isNewModifier ? 'created ' : ''}}{{data.modifier.type == ScenarioModifierType.QUANTITY_MODIFIER ? 'Quantity' : 'Price'}}
          modifier {{data.modifier.name}} to available {{'component|l' | lang}} sizes.</h1>
      </div>
      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>
  </div>

  <mat-dialog-content aria-label="Add question modal">
    <div class="modal__body flex flex-1 flex-col gap-4 w-full">
      <div class="filter-container">
        <h6 class="product-heading-bold">Filters:</h6>
        
        <div class="filter-options">
          <div class="filter-options-block">
            <scope-ui-autocomplete
              label="by Discipline"
              noResultsMessage="No Disciplines found"
              orderBy="name"
              [isCheckboxMultiselect]="true"
              [showSelectedOptionsAmount]="true"
              [options]="disciplines$ | async"
              (onSelectionChange)="setDisciplineFilter($event)" />
          </div>
          <div class="filter-options-block">
            <scope-ui-autocomplete
              label="by Rate Card"
              noResultsMessage="No Rate Cards found"
              orderBy="name"
              [isCheckboxMultiselect]="true"
              [showSelectedOptionsAmount]="true"
              [options]="rateCards$ | async"
              (onSelectionChange)="setRateCardFilter($event)" />
            <scope-ui-dropdown
              controlLabel="by Type"
              dropdownClass="text-sm"
              noSelectionOption="All"
              [dropdownOptions]="typeOptions"
              [isStandardDropdown]="true"
              (onSelectionChange)="setFixedPricingFilter($event)" />
          </div>
        </div>
        <div class="filter-options mt-2">
          <scope-ui-input
            class="flex-1"
            icon="search"
            inputLabel="Search by name"
            cancelIcon="cancel"
            inputClass="filter-search"
            (onEnter)="onSearchKeyPressed($event)"
            (onCancel)="onSearchKeyPressed('')" />
        </div>
      </div>
      <div class="options-list-container flex flex-col flex-1">
        <div class="options-list-header">
          <scope-ui-dropdown
            controlLabel="Select in Bulk"
            [dropdownOptions]="selectOptions"
            [isStandardDropdown]="true"
            [selectControl]="selectControl"
            (onSelectionChange)="selectInBulk($event.id)" />
          <mat-divider vertical />
          <span class="selected-count" *ngIf="totalCount">Selected {{selectedCount}} of {{totalCount}}</span>
          <div class="flex flex-1 justify-end">
            <button mat-button (click)="clear()">
              <span>Clear</span>
            </button>
          </div>
        </div>
        <div class="options-list" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
             [scrollWindow]="false" (scrolled)="onScroll()">
          <div class="entry-row" *ngFor="let entry of (componentEntries$ | async)">
            <div class="flex items-center">
              <mat-checkbox class="role-name"
                            [checked]='entryChecked(entry)'
                            [indeterminate]="entryIndeterminate(entry) && !entryChecked(entry)"
                            (change)='checkEntry(entry)'
                            aria-label='Use Retained Hours'
              ></mat-checkbox>
              <div class="flex flex-col">
                <span class="entry-name" [class.selected]="entryIndeterminate(entry) || entryChecked(entry)" isEllipsisActive>
                  {{entry.libraryComponentTemplate.name}}
                </span>
                <span class="entry-details" isEllipsisActive>
                  {{entry.rateCardVersion.name}} v{{entry.rateCardVersion.version}} | {{entry.disciplineTypeName}}
                </span>
              </div>
            </div>
            <div class="complexities">
              <span *ngFor="let complexity of ComplexitiesList"
                    [class.collapse]="!entry.componentComplexities[complexity]"
                    [class.selected]="complexityChecked(entry.componentComplexities[complexity])"
                    [attr.title]="complexityAssigned(entry.componentComplexities[complexity]?.id)"
                    (click)="checkComplexity(entry.componentComplexities[complexity])">{{complexity}}</span>
            </div>
          </div>
          <span *ngIf="!lastPage" class="loading-text">Loading {{'component|l' | lang}} sizes...</span>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="flex gap-2 justify-end">
    <div *ngIf="!(updateLoading$ | async) && data.showBackButton" class="flex-1">
      <button mat-button mat-dialog-close>
        <mat-icon class="material-symbols-rounded">chevron_left</mat-icon>
        Back to edit
      </button>
    </div>
    <button *ngIf="!(updateLoading$ | async) && !data.showBackButton" mat-button mat-dialog-close>
      {{data.isNewModifier ? 'Skip' : 'Cancel'}}
    </button>
    <button *ngIf="!(updateLoading$ | async)" (click)="submit()" mat-flat-button class="ml-8">
      Assign Modifier
    </button>
    <button mat-flat-button class="action-button active" *ngIf="(updateLoading$ | async)">
      <img src="/assets/icons/loading.svg" alt="loading" class="icon-spinner">
    </button>
  </mat-dialog-actions>
</div>

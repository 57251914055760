<div class="component-row items-center">
  <div class="row-content flex items-center" (click)="selectSection(section.id)">
    <div class="column level-{{level}} {{ componentMainColumnClass }} flex items-center">
      <mat-icon class="material-symbols-rounded arrow orange" [ngClass]="{ active: scopeTabService.deliverableSectionSelectedStates[section.id] }">expand_more</mat-icon>
      <div class="flex-col">
        <commentable [c-root-entity]="deliverable" [c-entity]="section" [c-key]="'name'" [c-type]="'section'">
          {{section.name}}
        </commentable>
      </div>
    </div>
    <div class="column one--column" [hidden]="isTimeline || !scopeVersion.isScopeByScenario()"></div>
    <div class="column one--column" [hidden]='!userColumns.QUANTITY?.selected'></div>
    <div class="column one--column" [hidden]="!(userColumns.START_DATE?.selected && userColumns.START_DATE.isVisibleInList())">
      <span>{{section.startDate || deliverable.startDate | dateFormat: 'shortDate'}}</span>
    </div>
    <div class="column one--column" [hidden]="!(userColumns.END_DATE?.selected && userColumns.END_DATE.isVisibleInList())">
      <span>{{section.endDate || deliverable.endDate | dateFormat: 'shortDate'}}</span>
    </div>
    <div class="column one--column" [hidden]="!userColumns.MARK_UP?.selected"></div>
    <div class="column one--column" [hidden]='isTimeline'>
      <commentable [c-root-entity]="deliverable" [c-entity]="section" [c-key]="'tpcValue'" [c-type]="'section'">
        {{section.calculateThirdPartyCosts(deliverable)?.amount | currency : section.currencyUnit}}
      </commentable>
    </div>
    <div class="column one--column" [hidden]='!userColumns.SCOPEMARK_HOURS?.selected'>
      <commentable [c-root-entity]="deliverable" [c-entity]="section" [c-key]="'rateCardHours'" [c-type]="'section'">
        {{ section.getTotalScopeMarkHours(deliverable) | formatHours }}
      </commentable>
    </div>
    <div class="column one--column" [hidden]='!userColumns.SCOPEMARK_PRICE?.selected'>
      <commentable [c-root-entity]="deliverable" [c-entity]="section" [c-key]="'scopeMarkSellingPrice'" [c-type]="'section'"
                   [has-privilege]="'SCOPE__VIEW_RATES'" [has-privilege-mask]="'--'"
                   [has-setting]="'RATECARD__SCOPEMARK__RATES'" [has-setting-disabled-mask]="'--'">
        {{ section.totalScopeMarkSellingPrice(deliverable)?.amount | currency : section.currencyUnit }}
      </commentable>
    </div>
    <div class="column {{ isTimeline ? 'three--columns' : 'one--column'}}" [hidden]="!isTimeline && !userColumns.AGENCY_HOURS?.selected">
      <commentable [c-root-entity]="deliverable" [c-entity]="section" [c-key]="'rateCardHours'" [c-type]="'section'">
        {{ section.getTotalRateCardHours(deliverable) | formatHours }}
      </commentable>
    </div>
    <div class="column one--column" [hidden]="isTimeline || !scopeVersion.isScopeByScenario()"></div>
    <div class="column {{ isTimeline ? 'three--columns' : 'one--column' }}" [hidden]="!isTimeline && !userColumns.AGENCY_PRICE?.selected">
      <commentable [c-root-entity]="deliverable" [c-entity]="section" [c-key]="'agencySellingPrice'" [c-type]="'section'"
                   [has-privilege]="'SCOPE__VIEW_RATES'" [has-privilege-mask]="'--'">
        {{ section.getTotalSellingPrice({}, deliverable)?.amount | currency : section.currencyUnit }}
      </commentable>
    </div>
    <div class="column one--column"></div>
  </div>
</div>

<div class="component-header flex items-center align-middle" [hidden]='!scopeTabService.deliverableSectionSelectedStates[section.id] || isTimeline'>
  <div class="column level-{{level+1}} {{ componentMainColumnClass }}">
    <span isEllipsisActive>{{ 'component|u' | lang }} NAME</span>
  </div>
  <div class="column one--column" [hidden]="!scopeVersion.isScopeByScenario()">
    <span>Quantity Modifier</span>
  </div>
  <div class="column one--column" [hidden]="!userColumns.QUANTITY?.selected">
    <span>Quantity</span>
  </div>
  <div class="column one--column" [hidden]="!(userColumns.START_DATE?.selected && userColumns.START_DATE.isVisibleInList())">
    <span>Start date</span>
  </div>
  <div class="column one--column" [hidden]="!(userColumns.END_DATE?.selected && userColumns.END_DATE.isVisibleInList())">
    <span>End date</span>
  </div>
  <div class='column one--column' [hidden]='!userColumns.MARK_UP?.selected'>
    <span>Mark-up</span>
  </div>
  <div class="column one--column">
    <span isEllipsisActive>{{ 'complexity' | lang }}</span>
  </div>
  <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_HOURS?.selected">
    <span isEllipsisActive>{{ 'scope_mark' | lang }}<br />hours</span>
  </div>
  <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_PRICE?.selected">
    <span isEllipsisActive>{{ 'scope_mark' | lang }}<br />price</span>
  </div>
  <div class="column one--column" [hidden]="!userColumns.AGENCY_HOURS?.selected">
    <span isEllipsisActive>{{ 'agency|p' | lang }}<br />hours</span>
  </div>
  <div class="column one--column" [hidden]="!scopeVersion.isScopeByScenario()">
    <span>Price Modifier</span>
  </div>
  <div class="column one--column" [hidden]="!userColumns.AGENCY_PRICE?.selected">
    <span isEllipsisActive *ngIf="!scopeVersion.identity.costPlus">{{ 'agency' | lang }} <br /> price</span>
    <span isEllipsisActive *ngIf="scopeVersion.identity.costPlus">Cost plus <br /> price</span>
  </div>
  <div class="column one--column"></div>
</div>

<ng-container *ngIf="scopeTabService.deliverableSectionSelectedStates[section.id]">
  <scope-component-row
    *ngFor="let component of section.getComponents(deliverable) | orderBy: 'order'; trackBy: trackById"
    [component]='component'
    [isTimeline]='isTimeline'
    [currentUser]="currentUser"
    [scopeVersion]="scopeVersion"
    [deliverable]="deliverable"
    [userColumns]="isTimeline ? scopeTabService.timelineColumns : scopeTabService.userColumns"
    [componentMainColumnClass]='componentMainColumnClass'
    [roleMainColumnClass]='roleMainColumnClass'
    [componentFeeColumns]="scopeTabService.componentFeeColumns"
    [feeMenuOptions]="feeMenuOptions"
    [level]="level+1"
    [componentSelectedStates]="scopeTabService.componentSelectedStates$ | async"
    [departmentSelectedStates]="scopeTabService.departmentSelectedStates$ | async"
    (onUpdateComponent)="updateComponent($event)"
    (onAddComponentFee)="addComponentFee($event)"
    (onUpdateFeeItem)="updateFeeItem($event.feeItem, $event.deliverable, $event.component)"
    (onOverrideQuantity)="overrideQuantity($event)"
    (onOverrideRateCardRoleHours)="overrideRateCardRoleHours($event)">
  </scope-component-row>
</ng-container>

<div *ngIf="section.thirdPartyCosts?.length" [hidden]="!scopeTabService.deliverableSectionSelectedStates[section.id] || isTimeline">
  <scope-ui-table
    [loggedInUser]="currentUser"
    [tableClass]="'tpc-table nested'"
    [headerExpandable]="false"
    [unmappedData]="section.thirdPartyCosts | orderBy: 'id'"
    [displayedColumns]="scopeTabService.thirdPartyCostColumns"
    [noSort]="true"
    [noPagination]="true"
    [noPreferences]="true"
    [showToggleMenu]="false"
    [expandable]="false"
    [showFooterRow]="false"
    [parentEntity]="section"
    [level]="level+1"
    [(disableDragging)]="scopeTabService.disableDragging">
  </scope-ui-table>
</div>

import { ApplicationSetting } from '@app/core/model/enums/application-setting.enum'
import { FormatHoursPipe } from '@app/shared/pipe/formatHours.pipe'
import { LibraryComponentEntry } from './library-component-entry.model'
import { Privilege } from '@app/core/model/enums/privilege.enum'
import { ScenarioModifierType } from '@app/features/company-management/models/component-modifier.model'
import { TableColumnKey } from '@app/shared/components/ui-components/scope-ui-table/table-column-key.enum'
import { libraryDeletedDisciplineDisplayName } from '@app/features/library-management/store/const/library-deleted-discipline-display-name.const'

export const libraryComponentColumnsConfig = [
  {
    key: TableColumnKey.DISCIPLINE,
    name: '{LANG:discipline|u}',
    field: 'disciplineTypeName',
    selected: true,
    value: (entry: LibraryComponentEntry) =>
      entry?.disciplineTypeName?.length ? entry?.disciplineTypeName : libraryDeletedDisciplineDisplayName,
  },
  { key: TableColumnKey.RATECARD, name: 'Ratecard', field: 'rateCardVersion.name', selected: true },
  {
    key: TableColumnKey.RATECARD_VERSION,
    name: 'Ratecard Version',
    field: 'rateCardVersion.version',
    selected: true,
  },
  { key: TableColumnKey.CREATED_BY, name: 'Created By', field: 'createdBy.company.name', selected: true },
  { key: TableColumnKey.DATE_CREATED, name: 'Date created', field: 'createdTs', selected: true },
  { key: TableColumnKey.LAST_EDITED, name: 'Last edited', field: 'updatedTs', selected: true },
]

export const getLibraryComponentEntryColumnsConfig = (entry: LibraryComponentEntry) => [
  { key: TableColumnKey.EDITABLE_NAME, name: '{LANG:component|u}', field: 'name', selected: true },
  {
    key: TableColumnKey.QUANTITY_MODIFIER,
    name: 'Quantity Modifier',
    selected: true,
    requiredSetting: 'SCOPE_BY_SCENARIO',
    value: (component) =>
      component.scenarioFormulas?.find((f) => f.type === ScenarioModifierType.QUANTITY_MODIFIER) ? 'YES' : 'NO',
  },
  {
    key: TableColumnKey.QUANTITY,
    name: 'Quantity',
    selected: true,
    value: (component) => component.quantity || 1,
  },
  { key: TableColumnKey.SIZE, name: 'Size', field: 'complexity', selected: true },
  {
    key: TableColumnKey.AGENCY_HOURS,
    name: '{LANG:agency} Hours',
    selected: true,
    requiredSetting: 'SCOPE__AGENCY_HOURS',
    value: (component) => new FormatHoursPipe().transform(component.agencyHours),
  },
  { key: TableColumnKey.AGENCY_COST, name: '{LANG:agency} Cost', field: 'agencyCost', selected: true },
  {
    key: TableColumnKey.EDITABLE_AGENCY_COST,
    name: '{LANG:agency} Cost',
    field: 'agencyCost',
    selected: true,
    isEditable: (component) =>
      component?.fixedPricing && !entry?.fixedFeeHasRoles && !component?.roles?.length && !component?.feeItems?.length,
    requiredPrivilege: Privilege.SCOPE_FIXED_COST_COMPONENT__EDIT,
    requiredSetting: ApplicationSetting.SCOPE__FIXED_COST_DELIVERABLE_EDITING,
  },
  {
    key: TableColumnKey.PRICE_MODIFIER,
    name: 'Price Modifier',
    selected: true,
    requiredSetting: 'SCOPE_BY_SCENARIO',
    value: (component) =>
      component.scenarioFormulas?.find((f) => f.type === ScenarioModifierType.PRICE_MODIFIER) ? 'YES' : 'NO',
  },
  {
    key: entry?.fixedPricing ? TableColumnKey.EDITABLE_AGENCY_PRICE : TableColumnKey.AGENCY_PRICE,
    name: '{LANG:agency} Price',
    selected: true,
    requiredPrivilege: Privilege.LIBRARY__VIEW_RATES,
    value: (component) => (component.fixedPricing && component.fixedCost ? component.fixedCost : component.agencyPrice),
  },
]

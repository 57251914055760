import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { LibraryManagementActions, LibraryManagementSelectors } from '@app/features/library-management/store'
import { Store, select } from '@ngrx/store'

import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LibraryAddDeliverableEntryModalConfig } from '@app/features/library-management/models'

@Component({
  templateUrl: './library-create-deliverable-entry-modal.component.html',
})
export class LibraryCreateDeliverableEntryModalComponent {
  rateCards$ = this.store$.pipe(select(LibraryManagementSelectors.selectRateCards))
  disciplines$ = this.store$.pipe(select(LibraryManagementSelectors.selectDisciplines))

  modalForm: FormGroup = new FormGroup({
    rateCard: new FormControl(null, [Validators.required]),
    discipline: new FormControl(null, [Validators.required]),
    description: new FormControl('', [Validators.required]),
    internalNote: new FormControl(''),
    fixedCostEditable: new FormControl(this.data.deliverable.fixedPricing),
  })

  constructor(@Inject(MAT_DIALOG_DATA) public data: LibraryAddDeliverableEntryModalConfig, private store$: Store) {
    this.store$.dispatch(LibraryManagementActions.getRatecards())
    this.store$.dispatch(LibraryManagementActions.getDisciplines())
  }

  getControl = (key: string) => this.modalForm.get(key) as FormControl
  getControlValue = (key: string) => this.getControl(key).value
  setControlValue = (key: string, value: any) => this.getControl(key).setValue(value)

  submit() {
    this.modalForm.markAllAsTouched()
    if (this.modalForm.valid) this.data.onSubmit(this.modalForm.value)
  }
}

import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import {
  ComponentModifier,
  ScenarioModifierType,
} from '@app/features/company-management/models/component-modifier.model';
import { TableColumnKey } from '@shared/components/ui-components/scope-ui-table/table-column-key.enum';
import { TableColumnPreferences } from '@shared/components/ui-components/scope-ui-table/table-column-preferences.type';

export const taskColumnBaseConfig: TableColumnPreferences = {
  [TableColumnKey.NAME]: {
    key: TableColumnKey.NAME,
    name: 'NAME',
    field: 'name',
    valueIconFunction: (entity: Deliverable) => {
      return entity.fixedPricing ? 'book' : 'task_alt';
    },
    selected: true,
    isVisibleInList: () => false
  },
  [TableColumnKey.RATE_CARD]: {
    key: TableColumnKey.RATE_CARD,
    name: 'Rate Card',
    field: 'rateCardVersion.name',
    selected: true
  },
  [TableColumnKey.RATE_CARD_VERSION]: {
    key: TableColumnKey.RATE_CARD_VERSION,
    name: 'Rate Card Version',
    field: 'rateCardVersion.version',
    selected: true
  },
  [TableColumnKey.CREATED_BY]: {
    key: TableColumnKey.CREATED_BY,
    name: 'Created By',
    field: 'createdBy.fullName',
    selected: true
  },
  [TableColumnKey.DATE_CREATED]: {
    key: TableColumnKey.DATE_CREATED,
    name: 'Date Created',
    field: 'createdTs',
    selected: true
  },
  [TableColumnKey.LAST_EDITED]: {
    key: TableColumnKey.LAST_EDITED,
    name: 'Last Edited',
    field: 'updatedTs',
    selected: true
  }
}

export const modifierColumnBaseConfig: TableColumnPreferences = {
  [TableColumnKey.NAME]: {
    key: TableColumnKey.NAME,
    name: 'NAME',
    field: 'name',
    valueIconFunction: () => 'calculate',
    selected: true,
    isVisibleInList: () => false
  },
  [TableColumnKey.TYPE]: {
    key: TableColumnKey.TYPE,
    name: 'Type',
    value: (entity: ComponentModifier) => entity.type === ScenarioModifierType.QUANTITY_MODIFIER ?
      'Quantity' : 'Price',
    selected: true
  },
  [TableColumnKey.ASSIGNED]: {
    key: TableColumnKey.ASSIGNED,
    name: 'Assigned to {LANG:component}(s)',
    value: (entity: ComponentModifier) => entity.complexityRoles?.length ?
      `Yes (${entity.complexityRoles?.length})` : 'No',
    selected: true
  },
  [TableColumnKey.CREATED_BY]: {
    key: TableColumnKey.CREATED_BY,
    name: 'Created By',
    field: 'auditMetadata.createdBy.fullName',
    selected: false
  },
  [TableColumnKey.DATE_CREATED]: {
    key: TableColumnKey.DATE_CREATED,
    name: 'Date Created',
    field: 'auditMetadata.createdOn',
    selected: false
  },
  [TableColumnKey.LAST_EDITED]: {
    key: TableColumnKey.LAST_EDITED,
    name: 'Last Edited',
    field: 'auditMetadata.updatedOn',
    selected: false
  }
}

declare const CKEDITOR: any;

export const ckeConfig = {
  allowedContent: true,
  forcePasteAsPlainText: false,
  tabSpaces: 4,
  autoParagraph: false,
  entities: false,
  toolbarGroups: [
    { name: 'add-variables-button', groups: ['add-variables-button'] },
    { name: 'signature', groups: ['signature'] },
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'list', groups: ['list'] },
    { name: 'indent', groups: ['indent', 'blocks'] },
    { name: 'paragraph', groups: ['align', 'bidi', 'paragraph'] },
    { name: 'links', groups: ['links'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'tools', groups: ['tools'] },
    { name: 'insert', groups: ['insert'] },
    { name: 'styles', groups: ['styles'] },
  ],
  filebrowserUploadUrl: 'https://coreapi.qa1.scope.expert/user-content/upload',
  extraPlugins: 'add-variables-button,signature,justify,indent,indentblock,blockquote,list,widget,clipboard,widgetselection,' +
    'autocomplete,textmatch,textwatcher,variableplaceholder,sourcearea,tableresize,tabletools,togglePreview,a4margins,bgimage,' +
    'resourceobject-scope-collaborators-table,resourceobject-scope-secondparty-contacts-table,resourceobject-deliverable-resource-table,lineheight,' +
    'resourceobject-breakdown-table,resourceobject-deliverable-rolehours-table,resourceobject-component-rolehours-table,resourceobject-deliverable-resource-table-two,' +
    'resourceobject-tpc-table,resourceobject-component-content,resourceobject-deliverable-content,resourceobject-task-content,resourceobject-tpc-group-content,' +
    'resourceobject-tpc-content,resourceobject-department-content,resourceobject-deliverable-fixed-content,resourceobject-deliverable-tpc-content,resourceobject-deliverable-section-tpc-content,resourceobject-component-tpc-content,' +
    'resourceobject-scope-tpc-content,resourceobject-sow-scope-resource-table,resourceobject-calculation-content,image2,borders,resourceobject-deliverable-resource-table-three,' +
    'resourceobject-role-content,resourceobject-deliverable-role-table-editable,resourceobject-deliverable-section,resourceobject-deliverable-deliverable-section,resourceobject-component-fixed-fee,resourceobject-component-section,' +
    'resourceobject-deliverable-resource-table-editable,resourceobject-scope-feeitem-content,resourceobject-scope-section-content,resourceobject-scope-section-feeitem-content,' +
    'resourceobject-deliverable-feeitem-content,resourceobject-component-feeitem-content,resourceobject-scope-section-deliverable-content,resourceobject-scope-section-tpc-content,' +
    'resourceobject-scope-section-department-content,resourceobject-deliverable-department-content,resourceobject-scope-section-deliverable-fixed-content',
  removePlugins: 'exportpdf, image',
  removeButtons: 'CreateDiv,Save,Templates,NewPage,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,Replace,Find,SelectAll,Scayt,' +
    'Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Superscript,Subscript,RemoveFormat,CopyFormatting,Language,' +
    'BidiRtl,BidiLtr,Anchor,Flash,Smiley,SpecialChar,PageBreak,Iframe,ShowBlocks,About',
  extraAllowedContent: 'img(*){*}[*]',
  clipboard_handleImages: false,
  pasteFromWord_keepZeroMargins: true,
  image2_maxSize: {
    height: 3000,
    width: 2000
  },
  on: {
    afterPasteFromWord: function(evt: any) {
      const fragment = CKEDITOR.htmlParser.fragment.fromHtml(evt.data.dataValue);
      const writer = new CKEDITOR.htmlParser.basicWriter();
      const filter = new CKEDITOR.htmlParser.filter({
        elements: {
          a: function(element: any) {
            // Filter out comments from word
            if (element.hasClass('msocomanchor')) {
              element.remove();
            }
            // Filter out bookmarks from word
            if (Object.keys(element.attributes).length === 1 && Object.keys(element.attributes)[0] === 'name') {
              element.remove();
            }
          },
          hr: function(element: any) {
            // Filter out comments from word
            if (element.hasClass('msocomoff')) {
              element.parent.remove();
            }
          },
          'v:line': function(element: any) {
            // Filter out line elements
            element.remove();
          },
          'o:wrapblock': function(element: any) {
            // Filter word wrapblock elements
            element.remove();
          },
          'w:sdt': function(element: any) {
            // Convert word sdt blocks to spans
            element.name = 'span';
            delete element.attributes['id'];
            return element;
          },
          img: function(element: any) {
            // Remove problematic image attributes
            delete element.attributes['o:ole'];
            return element;
          },
        },
        attributes: {
          style: function(attribute: any) {
            return attribute.endsWith(';') ? attribute : attribute + ';';
          },
        },
      });

      // Process, and overwrite evt.data.dataValue.
      filter.applyTo(fragment);
      fragment.writeHtml(writer);
      evt.data.dataValue = writer.getHtml();
    },
  },
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  LibraryDeliverableEntryDetails,
} from '@app/features/library-management/store/models/deliverable/library-deliverable-entry-details.model';
import { environment } from '@envs/environment';

@Injectable({
  providedIn: 'root',
})
export class LibraryDeliverableEntryDetailsService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl;

  constructor(private http: HttpClient) {
  }

  getAllDeliverableEntryDetails(
    page: number,
    pageSize: number,
    filter: { libraryItemName?: string },
  ): Observable<{ content: LibraryDeliverableEntryDetails[]; totalElements: number, last: boolean, number: number }> {
    return this.http.post<{ content: LibraryDeliverableEntryDetails[]; totalElements: number, last: boolean, number: number }>(
      `${this.BASE_API_URL}library/deliverable-entry-details-view`,
      filter,
      {
        params: { page: page.toString(), pageSize: pageSize.toString() },
      },
    );
  }

  getDeliverableEntryDetailsByIds(ids: number[]): Observable<LibraryDeliverableEntryDetails[]> {
    return this.http.post<LibraryDeliverableEntryDetails[]>(
      `${this.BASE_API_URL}library/deliverable-entry-details-by-ids`,
      ids,
    );
  }


}

<div class="modal-container">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{title}}</h1>
      </div>
      <div class="modal-text mt-2">
        <span class="whitespace-pre-line">{{body}}</span>
      </div>
      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>
  </div>

  <mat-dialog-content aria-label="Add question modal">
    <div class="modal__body flex flex-col gap-8">
      <div class="flex flex-row gap-8 items-baseline">
        <scope-ui-input class="flex-1"
                        [inputLabel]="'Modifier name'"
                        [inputClass]="'show-errors'"
                        [initialValue]="modifier.name"
                        [control]="getControl('nameControl')"
                        (onInputChange)="setName($event)" />
        <scope-ui-dropdown
          *ngIf="!isUpdate"
          [selectedValue]="modifier.type"
          [displayFn]="typeDisplayFn"
          [controlLabel]="'Select Modifier Type'"
          [dropdownOptions]="typeOptions"
          [isStandardDropdown]="true"
          [selectControl]="getControl('typeControl')"
          (onSelectionChange)="modifier.type = $event"/>
      </div>
      <formula-builder [scenario]="data.scenario" [dynamicFields]="dynamicFields"
                       [(value)]="modifier.formula" [required]="true" [control]="getControl('formulaControl')" />
      <div *ngIf="isUpdate" class="flex flex-col gap-3">
        <div class="flex justify-between w-full">
          <h4 class="product-heading-bold">Assigned to {{data.modifier.complexityRoles?.length}} {{'component|l' | lang}} sizes</h4>
          <button mat-button (click)="updateAssignments()">
            <span class="orange">Update Assignments</span>
          </button>
        </div>
        <scope-ui-table
          [tableClass]="'complexity-roles-table'"
          [stickyHeader]="true"
          [loading]="!data.modifier.complexityRoles"
          [loggedInUser]="data.loggedInUser"
          [unmappedData]="data.modifier.complexityRoles"
          [displayedColumns]="componentColumns"
          [noPagination]="true"
          [noPreferences]="true"
          [showToggleMenu]="false"
          [expandable]="false"
        />
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="flex gap-2 justify-end">
    <button mat-button mat-dialog-close *ngIf="!(updateLoading$ | async)">
      <span class='tracking-normal'>{{ assign ? 'Close' : 'Cancel' }}</span>
    </button>
    <button mat-flat-button class="ml-8" *ngIf="!(updateLoading$ | async) && !assign" (click)="submit()">
      <span class='tracking-normal'>{{ isUpdate ? 'Update' : 'Create' }} modifier</span>
    </button>
    <button mat-flat-button class="ml-8" *ngIf="!(updateLoading$ | async) && assign" (click)="assignModifier()">
      <span class='tracking-normal'>Assign modifier</span>
    </button>
    <button mat-flat-button class="action-button active" *ngIf="(updateLoading$ | async)">
      <img src="/assets/icons/loading.svg" alt="loading" class="icon-spinner">
    </button>
  </mat-dialog-actions>
</div>

<div class='flex gap-2 items-center justify-end mb-1'>
  <scope-ui-input
    class="z-10"
    [icon]="'search'"
    [cancelIcon]="'cancel'"
    [inputLabel]="'Quick Search'"
    [inputClass]="'search-field search-field-lg'"
    (onCancel)="searchText = ''; filterModifiers()"
    (onInputChange)='searchText = $event; filterModifiers()' />
  <mat-divider vertical />
  <button class="z-10" (click)='openCreateModal()' mat-flat-button>
    <mat-icon class='material-symbols-rounded'>add</mat-icon>
    Create Modifier
  </button>
</div>
<scope-ui-table
  [loading]="loading$ | async"
  [tableClass]="'overview-table'"
  [loggedInUser]='loggedInUser'
  [data]="mappedModifiersDataSource$ | async"
  [displayedColumns]="modifierColumns$ | async"
  [addToggleListColumn]="true"
  [noPagination]="true"
  [showToggleMenu]="true"
  [menuOptions]="menuOptions"
  [expandable]="false"
  [rowGaps]="true"
  [condensed]="true"
  (onToggle)="onTogglePreference($event)"
  [selectMode]="ScopeUiTableSelectMode.ROW"
  (onSelect)="editModifier($event.entity)">
</scope-ui-table>

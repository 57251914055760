import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  LibraryDeliverableEntryDetails,
} from '@app/features/library-management/store/models/deliverable/library-deliverable-entry-details.model';
import {
  LibraryDeliverableEntryDetailsActions,
} from '@app/features/library-management/store/models/deliverable/library-deliverable-entry-details.action.interface';

export interface LibraryDeliverableEntryDetailsState extends EntityState<LibraryDeliverableEntryDetails> {
  isLoading: boolean;
  error: any;
  total: number;
  last: boolean;
}

export const LibraryDeliverableEntryDetailsAdapter = createEntityAdapter<LibraryDeliverableEntryDetails>({
  selectId: (entity) => entity.libraryDeliverableEntryId,
});

export const libraryDeliverableEntryDetailsState: LibraryDeliverableEntryDetailsState = LibraryDeliverableEntryDetailsAdapter.getInitialState({
  isLoading: false,
  error: null,
  total: 0,
  last: false
});

export const deliverableEntryDetailsReducer = createReducer(
  libraryDeliverableEntryDetailsState,
  on(LibraryDeliverableEntryDetailsActions.loadDeliverableEntryDetails, (state) => {
    return { ...state, isLoading: true, error: null, lastPage: false };
  }),
  on(LibraryDeliverableEntryDetailsActions.loadDeliverableEntryDetailsSuccess, (state, { data, total, last, number }) => {
    return number == 0 ?
      LibraryDeliverableEntryDetailsAdapter.setAll(data, { ...state, isLoading: false, total, last }) :
      LibraryDeliverableEntryDetailsAdapter.addMany(data, { ...state, isLoading: false, total, last });
  }),
  on(LibraryDeliverableEntryDetailsActions.loadDeliverableEntryDetailsFailure, (state, { error }) => {
    return { ...state, isLoading: false, error };
  }),
  on(LibraryDeliverableEntryDetailsActions.loadDeliverableEntryDetailsByIds, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(LibraryDeliverableEntryDetailsActions.loadDeliverableEntryDetailsByIdsSuccess, (state, { data }) =>
    LibraryDeliverableEntryDetailsAdapter.setAll(data, {
      ...state,
      isLoading: false,
    }),
  ),
  on(LibraryDeliverableEntryDetailsActions.loadDeliverableEntryDetailsByIdsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);


import { Component, Inject } from '@angular/core'

import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LibraryDuplicateEntryModalConfig } from '@app/features/library-management/models'
import { LibraryComponentService } from '@app/features/library-management/services'
import { LibraryDeliverableActions, LibraryDeliverableSelectors } from '@app/features/library-management/store'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { Store } from '@ngrx/store'
import { size } from 'lodash'

@Component({
  templateUrl: './library-map-component-entry-roles-modal.component.html',
  styleUrls: ['./library-map-component-entry-roles-modal.component.scss'],
})
export class LibraryMapComponentEntryRolesModalComponent {
  mapping: any
  currentComplexity: any
  rolesMapping: any
  usedRoles = []
  sourceRoles = []
  resultMapping = {}
  mappingRoles: any[] = []
  targetRoles: any[] = []
  targetRateCard: RatecardVersion
  complexityIndex: number

  isLoadingMapping = true
  isLoadingRateCard$ = this.store$.select(LibraryDeliverableSelectors.selectIsLoadingRateCard)

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LibraryDuplicateEntryModalConfig,
    private libraryComponentService: LibraryComponentService,
    private store$: Store
  ) {
    this.store$.dispatch(
      LibraryDeliverableActions.getRateCard({ id: data.rateCardIdentityId, version: data.rateCardVersion })
    )

    this.store$.select(LibraryDeliverableSelectors.selectRateCard).subscribe((rateCard) => {
      this.targetRateCard = rateCard
      if (this.targetRateCard) this.prepareMapping()
    })
  }

  prepareMapping() {
    this.mappingRoles = []
    this.usedRoles = []
    this.complexityIndex = 0

    this.libraryComponentService
      .getComponentRolesMapping(this.data.component.id, this.data.entry.id, this.data.rateCardId)
      .subscribe((mapping) => {
        this.isLoadingMapping = false
        this.mapping = mapping
        this.nextComplexity()
      })
  }

  nextComplexity() {
    var complexity = Object.keys(this.mapping)[this.complexityIndex]

    if (this.complexityIndex !== 0) {
      this.resultMapping[this.currentComplexity] = {}
      this.mappingRoles.forEach((roleMapping) => {
        if (roleMapping.targetMappingRole)
          this.resultMapping[this.currentComplexity][roleMapping.id] = roleMapping.targetMappingRole.id
      })
    }
    
    if (this.complexityIndex === size(this.mapping)) return this.data.onSubmit(this.resultMapping)

    this.mappingRoles = this.mapping[complexity].sourceRoles?.map((role) => ({
      ...role,
      name: role.rateCardRole?.name,
    }))
    this.usedRoles = this.mapping[complexity].usedRoles
    this.sourceRoles = this.mapping[complexity].sourceRoles
    this.targetRoles = this.mapping[complexity].targetRoles
    this.rolesMapping = this.mapping[complexity].rolesMapping
    this.complexityIndex = this.complexityIndex + 1
    this.currentComplexity = complexity
  }

  protected readonly size = size
}

<loader-modal *ngIf="isCleaningExternalData"></loader-modal>
<div class="scope-field-container">
  <div class="flex d-flex-justify-between items-center">
    <div class="title">
      <h1 class="product-heading-bold">Scope Fields</h1>
    </div>
    <div class="flex gap-2 items-center">
      <scope-ui-input
        icon="search"
        cancelIcon="cancel"
        inputLabel="Search"
        inputClass="search-field"
        (onCancel)="onCancelPressed()"
        (onInputChange)="onSearchKeyPressed($event)" />
      <mat-divider vertical />
      <button (click)="openCreateModal()" mat-flat-button>
        <mat-icon class="material-symbols-rounded">add</mat-icon>
        New Field
      </button>
    </div>
  </div>

  <scope-ui-table
    class="mt-8"
    tableClass="overview-table"
    [loggedInUser]="loggedInUser"
    [data]="mappedDynamicFieldDataSource$ | async"
    [displayedColumns]="dynamicFieldColumnsPrefsArray"
    [noPagination]="true"
    [rowGaps]="true"
    [condensed]="true"
    [menuOptions]="dynamicFieldMenuOptions"
    [showToggleMenu]="true"
    [addToggleListColumn]="true"
    [noPreferences]="true"
    [selectMode]="ScopeUiTableSelectMode.ROW"
    (onSelect)="onEdit($event.entity)"
  ></scope-ui-table>
</div>

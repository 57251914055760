import { Action, createReducer, on } from '@ngrx/store'
import {
  GetFormulasSuccessAction,
  GetGroupsSuccessAction,
  LibraryThirdPartyCostState,
} from '@app/features/library-management/store/models/third-party-cost'

import { LibraryThirdPartyCostActions } from '@app/features/library-management/store/actions'
import { libraryThirdPartyCostInitialState } from '@app/features/library-management/store'

const getFormulasHandler = (state: LibraryThirdPartyCostState) => ({ ...state, isLoading: !state.isFormulasLoaded })
const getGroupsHandler = (state: LibraryThirdPartyCostState) => ({ ...state, isLoading: !state.isGroupsLoaded })
const loadingTrueHandler = (state: LibraryThirdPartyCostState) => ({ ...state, isLoading: true })
const loadingFalseHandler = (state: LibraryThirdPartyCostState) => ({ ...state, isLoading: false })

const getThirdPartyCostHandler = (state: LibraryThirdPartyCostState) => ({
  ...state,
  isTpcLoading: true,
  tpc: undefined,
})

const getThirdPartyCostFailHandler = (state: LibraryThirdPartyCostState) => ({
  ...state,
  isTpcLoading: false,
  tpc: undefined,
})

const getThirdPartyCostSuccessHandler = (state: LibraryThirdPartyCostState, { tpc }) => ({
  ...state,
  isTpcLoading: false,
  tpc,
})

const getGroupsSuccessHandler = (state: LibraryThirdPartyCostState, action: GetGroupsSuccessAction) => ({
  ...state,
  isLoading: false,
  isGroupsLoaded: true,
  groups: action.groups,
})

const getFormulasSuccessHandler = (state: LibraryThirdPartyCostState, action: GetFormulasSuccessAction) => ({
  ...state,
  isLoading: false,
  isFormulasLoaded: true,
  formulas: action.formulas,
})

const updateThirdPartyCostSuccessHandler = (state: LibraryThirdPartyCostState, { tpc }) => ({
  ...state,
  isLoading: false,
  tpc,
})

export const libraryThirdPartyCostReducer = (
  state = libraryThirdPartyCostInitialState,
  action: Action
): LibraryThirdPartyCostState =>
  createReducer(
    libraryThirdPartyCostInitialState,
    on(
      LibraryThirdPartyCostActions.createThirdPartyCost,
      LibraryThirdPartyCostActions.updateThirdPartyCost,
      LibraryThirdPartyCostActions.duplicateThirdPartyCost,
      LibraryThirdPartyCostActions.deleteThirdPartyCost,
      loadingTrueHandler
    ),
    on(
      LibraryThirdPartyCostActions.getGroupsFail,
      LibraryThirdPartyCostActions.getFormulasFail,
      LibraryThirdPartyCostActions.createThirdPartyCostFail,
      LibraryThirdPartyCostActions.createThirdPartyCostSuccess,
      LibraryThirdPartyCostActions.getThirdPartyCostFail,
      LibraryThirdPartyCostActions.updateThirdPartyCostFail,
      LibraryThirdPartyCostActions.duplicateThirdPartyCostFail,
      LibraryThirdPartyCostActions.duplicateThirdPartyCostSuccess,
      LibraryThirdPartyCostActions.deleteThirdPartyCostFail,
      LibraryThirdPartyCostActions.deleteThirdPartyCostSuccess,
      loadingFalseHandler
    ),
    on(LibraryThirdPartyCostActions.getGroups, getGroupsHandler),
    on(LibraryThirdPartyCostActions.getFormulas, getFormulasHandler),
    on(LibraryThirdPartyCostActions.getGroupsSuccess, getGroupsSuccessHandler),
    on(LibraryThirdPartyCostActions.getFormulasSuccess, getFormulasSuccessHandler),
    on(LibraryThirdPartyCostActions.getThirdPartyCost, getThirdPartyCostHandler),
    on(LibraryThirdPartyCostActions.getThirdPartyCostFail, getThirdPartyCostFailHandler),
    on(LibraryThirdPartyCostActions.getThirdPartyCostSuccess, getThirdPartyCostSuccessHandler),
    on(LibraryThirdPartyCostActions.updateThirdPartyCostSuccess, updateThirdPartyCostSuccessHandler)
  )(state, action)

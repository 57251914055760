export enum DataTab {
  NONE = '',
  DELIVERABLES = 'DELIVERABLES',
  DISCIPLINES = 'DISCIPLINES',
  TASKS = 'TASKS',
  FEES = 'FEES',
  COST_PLUS = 'COST_PLUS',
  THIRDPARTYCOSTS = 'THIRDPARTYCOSTS',
  UNIT_TYPES = 'UNIT_TYPES',
  FORMULAS = 'FORMULAS',
  RATECARD_STRUCTURE = 'RATECARD_STRUCTURE',
  RATECARD_LOCATIONS = 'RATECARD_LOCATIONS',
  SCOPE_FIELDS = 'SCOPE_FIELDS',
}

import { ActionReducerMap } from '@ngrx/store'
import { LibraryManagementFeatureState } from '../models/state'
import { libraryComponentReducer } from './library-component.reducer'
import { libraryDeliverableReducer } from './library-deliverable.reducer'
import { libraryManagementFoldersReducer } from './library-management-folders.reducer'
import { libraryManagementItemsReducer } from './library-management-items.reducer'
import { libraryManagementReducer } from './library-management.reducer'
import { libraryThirdPartyCostReducer } from './library-third-party-cost.reducer'
import { deliverableEntryDetailsReducer } from './library-deliverable-entry-details.reducer';

export const libraryManagementFeatureReducer: ActionReducerMap<LibraryManagementFeatureState> = {
  libraryManagement: libraryManagementReducer,
  libraryItems: libraryManagementItemsReducer,
  folders: libraryManagementFoldersReducer,
  thirdPartyCost: libraryThirdPartyCostReducer,
  deliverable: libraryDeliverableReducer,
  component: libraryComponentReducer,
  deliverableEntryDetails: deliverableEntryDetailsReducer,
}

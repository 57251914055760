<div class="modal-container">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{title}}</h1>
      </div>
      <div class="modal-text mt-2">
        <span class="whitespace-pre-line">{{instructions}}</span>
      </div>
      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>
  </div>

  <mat-dialog-content aria-label="Add question modal">
    <div class="modal__body flex flex-col gap-8">
      <div class="flex flex-row gap-4 items-baseline">
        <scope-ui-input class="flex-1"
                        [inputLabel]="questionTextLabel"
                        [inputClass]="'icon-field show-errors'"
                        [required]="true"
                        [initialValue]="data.question?.displayText"
                        (onInputChange)="questionText = $event" />
        <mat-slide-toggle
          *ngIf="data.type !== QuestionType.FORMULA"
          class="scope-ui-toggle"
          [disableRipple]="true"
          [checked]="required"
          (change)="required = $event.checked">
          Required
        </mat-slide-toggle>
      </div>
      <mat-divider/>
      <h4 class="product-heading-bold">{{configureTitle}}</h4>
      <div class="flex flex-row gap-4 items-baseline">
        <scope-ui-input class="flex-1"
                        [inputLabel]="'Enter Field Label'"
                        [inputClass]="'icon-field show-errors'"
                        [initialValue]="data.question?.fieldLabel"
                        (onInputChange)="setFieldLabel($event)" />
        <scope-ui-input class="flex-1"
                        [inputLabel]="'Enter Field ID'"
                        [inputClass]="'icon-field show-errors'"
                        [required]="true"
                        [initialValue]="data.question?.fieldId"
                        [control]="fieldIDControl"
                        (onInputChange)="setFieldID($event)" />
      </div>
      <div *ngIf="data.type === QuestionType.TEXT" class="flex flex-row gap-4 items-baseline">
        <scope-ui-input class="flex-1"
                        [inputLabel]="'Maximum Length'"
                        [inputClass]="'icon-field show-errors'"
                        [initialValue]="data.question?.maxLength"
                        [type]="'number'"
                        [parseNumber]="true"
                        [isInteger]="true"
                        [minimum]="1"
                        [maximum]="9999"
                        [maximumLength]="4"
                        (onInputChange)="maxLength = $event" />
        <div class="flex-1"></div>
      </div>
      <div *ngIf="data.type === QuestionType.NUMBER" class="flex flex-row gap-4 items-baseline">
        <mat-slide-toggle
          class="scope-ui-toggle"
          [disableRipple]="true"
          [checked]="wholeNumber"
          (change)="wholeNumber = $event.checked">
          Whole numbers only
        </mat-slide-toggle>
      </div>
      <div *ngIf="data.type === QuestionType.NUMBER" class="flex flex-row gap-4 items-baseline">
        <scope-ui-input class="flex-1"
                        [inputLabel]="'Minimum Value'"
                        [inputClass]="'icon-field show-errors'"
                        [type]="'text'"
                        [decimalPlaces]="wholeNumber ? 0 : 2"
                        [allowNegativeNumbers]="true"
                        [maximum]="maximum"
                        [parseNumber]="true"
                        [control]="minimumControl"
                        [maximumLength]="19"
                        (onInputChange)="minimum = $event; cdr.detectChanges()" />
        <scope-ui-input class="flex-1"
                        [inputLabel]="'Maximum Value'"
                        [inputClass]="'icon-field show-errors'"
                        [type]="'text'"
                        [decimalPlaces]="wholeNumber ? 0 : 2"
                        [allowNegativeNumbers]="true"
                        [minimum]="minimum"
                        [parseNumber]="true"
                        [control]="maximumControl"
                        [maximumLength]="19"
                        (onInputChange)="maximum = $event; cdr.detectChanges()" />
      </div>
      <div *ngIf="data.type === QuestionType.DATE" class="flex flex-row gap-4 items-baseline">
        <scope-ui-datepicker
          class="flex-1"
          [singleDatePicker]="true"
          [inputLabel]="'Minimum Date'"
          [maxDate]="maxDate"
          [initialValue]="data.question?.minDate"
          [drops]="'up'"
          (onSelect)="minDate = $event">
        </scope-ui-datepicker>
        <scope-ui-datepicker
          class="flex-1"
          [singleDatePicker]="true"
          [inputLabel]="'Maximum Date'"
          [minDate]="minDate"
          [initialValue]="data.question?.maxDate"
          [drops]="'up'"
          (onSelect)="maxDate = $event">
        </scope-ui-datepicker>
      </div>
      <formula-builder *ngIf="data.type === QuestionType.FORMULA" [scenario]="scenario" [(value)]="formulaValue"
                       [required]="true" [control]="formulaControl" />
      <scope-ui-chips *ngIf="data.type === QuestionType.DROPDOWN" [inputLabel]="'Options list'"
                      [(values)]="options" (valuesChange)="cdr.detectChanges(); optionsChanged = true" [control]="optionsListControl"></scope-ui-chips>
      <mat-divider/>
      <div class="flex gap-2 flex-col">
        <h4 class="product-heading-bold">Set a rule for when this question appears<span class="orange"> *</span></h4>
        <scope-ui-checkbox [(ngModel)]="showAlways" (ngModelChange)="showAlways = true; hiddenAlways = false; showOnCondition = false"
                           [title]="data.type === QuestionType.FORMULA ? 'Always shown' : 'Always'" [disableUncheck]="true" />
        <scope-ui-checkbox *ngIf="data.type === QuestionType.FORMULA" [ngModel]="hiddenAlways" [disableUncheck]="true"
                           (ngModelChange)="hiddenAlways = true; showAlways = false; showOnCondition = false; checkDependencies()" [title]="'Always hidden'" />
        <scope-ui-checkbox [ngModel]="showOnCondition" (ngModelChange)="showOnCondition = true; showAlways = false; hiddenAlways = false; checkDependencies()"
                           [title]="data.type === QuestionType.FORMULA ? 'Shown on condition' : 'On condition'" [disableUncheck]="true" />
        <formula-builder *ngIf="showOnCondition" [scenario]="scenario" [(value)]="condition" [title]="'Set a condition'"
                         [required]="true" [showResult]="false" [control]="conditionControl" (focus)="checkDependencies()" />
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="flex gap-2 justify-end">
    <button mat-button mat-dialog-close>
      <span class='tracking-normal'>Cancel</span>
    </button>
    <button mat-flat-button class="ml-8" *ngIf="!(updateLoading$ | async)" (click)="submit()" [disabled]="disableSubmit()">
      <span class='tracking-normal'>Save</span>
    </button>
    <button mat-flat-button class="ml-8" *ngIf="(updateLoading$ | async)">
      <img src="/assets/icons/loading.svg" alt="loading" class="icon-spinner">
    </button>
  </mat-dialog-actions>
</div>

import { CompanyManagementState } from '@app/features/company-management/models/company-management.state.model'
import { createSelector } from '@ngrx/store';
import * as fromFeature from '@app/features/company-management/store/reducers/index'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { Discipline } from '@app/features/scope-overview/model/discipline.model';

export const getCompanyManagementFromState = createSelector(
  fromFeature.getCompanyManagementState,
  (state: fromFeature.State) => state.companyManagement
);

const getOutputTemplates = (state: CompanyManagementState): any => state.outputTemplates

const getScopeTemplates = (state: CompanyManagementState): any => {
  return state.outputTemplates?.filter(o => o.templateScopeType)
    .sort((a, b) => a.name.localeCompare(b.name))
}

const getFolderTemplates = (state: CompanyManagementState): any => {
  return state.outputTemplates?.filter(o => !o.templateScopeType)
    .sort((a, b) => a.name.localeCompare(b.name))
}

const selectOutputTemplates = createSelector(getCompanyManagementFromState, getOutputTemplates)
const selectScopeTemplates = createSelector(getCompanyManagementFromState, getScopeTemplates)
const selectFolderTemplates = createSelector(getCompanyManagementFromState, getFolderTemplates)
const selectGroupId = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.groupId)

const selectTasks = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.tasks)
const selectLoadingTasks = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.loadingTasks)
const selectTaskColumnUserPreferences = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.taskColumns)
const selectTaskColumns = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => Object.values(state.taskColumns))

const selectScenarios = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.scenarios)
const selectLoadingScenarios = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.loadingScenarios)

const selectScenario = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.scenario);
const selectLoadingScenario = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.loadingScenario);
const selectLoadedScenario = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.loadedScenario);

const selectModifiers = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.modifiers)
const selectModifiersIfLoaded = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => {
  return (state.loadedModifiers) ? state.modifiers : null;
})
const selectLoadingModifiers = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.loadingModifiers)
const selectLoadingUpdateModifiers = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.loadingUpdateModifiers)
const selectModifierColumnUserPreferences = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.modifierColumns)
const selectModifierColumns = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => Object.values(state.modifierColumns))
const selectNewModifier = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.newModifier)

const selectLoadingComponentEntries = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.loadingComponentEntries)
const selectSearchedComponentEntries = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => [...state.searchedComponentEntries.values()])

const selectRateCards = createSelector(
  getCompanyManagementFromState,
  (state: CompanyManagementState): RatecardVersion[] => state?.rateCards
)

const selectDisciplines = createSelector(
  getCompanyManagementFromState,
  (state: CompanyManagementState): Discipline[] => state?.disciplines
)

const selectIsRateCardsLoaded = createSelector(
  getCompanyManagementFromState,
  (state: CompanyManagementState): boolean => state?.isRateCardsLoaded
)

const selectIsDisciplinesLoaded = createSelector(
  getCompanyManagementFromState,
  (state: CompanyManagementState): boolean => state?.isDisciplinesLoaded
)

const lastComponentEntriesFetched = createSelector(
  getCompanyManagementFromState,
  (state: CompanyManagementState): boolean => state?.lastComponentEntriesFetched
)

const componentEntriesTotalCount = createSelector(
  getCompanyManagementFromState,
  (state: CompanyManagementState): number => state?.componentEntriesTotalCount
)

const selectHideModifierOverrideWarning = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.hideModifierOverrideWarning)

const selectLoadingAddUpdateScenarioQuestion = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.loadingAddUpdateScenarioQuestion)
const selectCreateQuestionError = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.createQuestionError)
const selectLoadingAddUpdateScenarioCategory = createSelector(getCompanyManagementFromState, (state: CompanyManagementState) => state.loadingAddUpdateScenarioCategory)

export const CompanyManagementSelectors = {
  selectOutputTemplates,
  selectScopeTemplates,
  selectFolderTemplates,
  selectGroupId,
  selectTasks,
  selectLoadingTasks,
  selectTaskColumnUserPreferences,
  selectTaskColumns,
  selectScenarios,
  selectLoadingScenarios,
  selectScenario: selectScenario,
  selectLoadingScenario: selectLoadingScenario,
  selectLoadedScenario: selectLoadedScenario,
  selectModifiers,
  selectModifiersIfLoaded,
  selectLoadingModifiers,
  selectLoadingUpdateModifiers,
  selectModifierColumnUserPreferences,
  selectModifierColumns,
  selectNewModifier,
  selectLoadingComponentEntries,
  selectSearchedComponentEntries,
  selectRateCards,
  selectDisciplines,
  selectIsRateCardsLoaded,
  selectIsDisciplinesLoaded,
  lastComponentEntriesFetched,
  componentEntriesTotalCount,
  selectHideModifierOverrideWarning,
  selectLoadingAddUpdateScenarioQuestion,
  selectCreateQuestionError,
  selectLoadingAddUpdateScenarioCategory
}

import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import {
  LibraryComponentSelectors,
  LibraryDiscipline,
  LibraryManagementActions,
  LibraryManagementSelectors,
  LibraryRateCard,
} from '@app/features/library-management/store'
import { Store, select } from '@ngrx/store'

import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ComplexitiesList } from '@app/core/model/enums/complexity.enum'
import { Privilege } from '@app/core/model/enums/privilege.enum'
import { AuthService } from '@app/core/service/auth.service'
import { LanguageService } from '@app/core/service/language.service'
import { LibraryTemplateType } from '@app/features/library-management/enums/library-template-type.enum'
import { LibraryAddComponentEntryModalConfig } from '@app/features/library-management/models'
import { Observable } from 'rxjs'

@Component({
  templateUrl: './library-create-component-modal.component.html',
  styleUrls: ['./library-create-component-modal.component.scss'],
})
export class LibraryCreateComponentModalComponent {
  rateCards$: Observable<LibraryRateCard[]>
  disciplines$: Observable<LibraryDiscipline[]>

  modalForm: FormGroup
  typeOptions = [
    { name: this.lang.get('component'), id: LibraryTemplateType.COMPONENT },
    { name: 'Fixed Fee', id: LibraryTemplateType.FIXED_COST },
  ]

  get isFixedCost() {
    return this.getControlValue('libraryItemType') === LibraryTemplateType.FIXED_COST
  }

  getGroup = (key: string, form?) => (form || this.modalForm)?.get(key) as FormGroup
  getControl = (key: string, form?) => (form || this.modalForm)?.get(key) as FormControl
  getControlValue = (key: string) => this.getControl(key)?.value
  setControlValue = (key: string, value: any, form?) => this.getControl(key, form)?.setValue(value)
  getComplexityControl = (complexity: string, key: string) =>
    this.getControl(key, this.getGroup(complexity, this.getGroup('complexities')))

  isFixedPricing = () => (this.data.isCreateEntry && this.data.isFixedPricing) || this.isFixedCost

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LibraryAddComponentEntryModalConfig,
    private store$: Store,
    private lang: LanguageService,
    protected authService: AuthService
  ) {
    this.rateCards$ = this.store$.pipe(select(LibraryManagementSelectors.selectRateCards))
    this.disciplines$ = this.store$.pipe(select(LibraryManagementSelectors.selectDisciplines))

    this.store$.dispatch(LibraryManagementActions.getRatecards())
    this.store$.dispatch(LibraryManagementActions.getDisciplines())

    this.setModalForm()
  }

  submit() {
    this.modalForm.markAllAsTouched()
    if (this.modalForm.valid)
      this.data.onSubmit({
        ...this.modalForm.value,
        fixedPricing: this.isFixedCost,
        fixedFeeEditable: this.isFixedCost ? this.modalForm.value.fixedFeeEditable : false,
        fixedFeeHasRoles: this.isFixedCost ? this.modalForm.value.fixedFeeHasRoles : false,
      })
  }

  onRateCardChange({ event }) {
    if (this.data.isCreateEntry) {
      this.getControl('discipline').reset()
      this.disciplines$ = this.store$.pipe(select(LibraryComponentSelectors.selectUnusedDisciplines(event)))
    }
  }

  updateMarkUp(complexity?: string) {
    let markup = 0
    let price, cost, group

    if (complexity) {
      group = this.getControl(complexity, this.getGroup('complexities'))
      price = group?.value?.price
      cost = group?.value?.cost
    } else {
      price = this.modalForm.value?.price
      cost = this.modalForm.value?.cost
    }

    if (price && price != 0 && (!cost || cost == 0)) markup = 100
    else if ((!price || price == 0) && cost && cost != 0) markup = -100
    else
      markup =
        markup == 0 ? parseFloat(markup.toFixed(2)) : parseFloat(Number(((price - cost) / cost) * 100).toFixed(2))

    this.setControlValue('markup', markup, group)
    this.onComplexityPriceChange()
  }

  setModalForm() {
    this.modalForm = new FormGroup({
      name: new FormControl('', this.data.isCreateEntry ? [] : [Validators.required]),
      rateCard: new FormControl(null, [Validators.required]),
      discipline: new FormControl(null, [Validators.required]),
      description: new FormControl('', this.data.entry ? [] : [Validators.required]),
      fixedFeeEditable: new FormControl(true, [Validators.required]),
      fixedFeeHasRoles: new FormControl(true, [Validators.required]),
      libraryItemType: new FormControl(
        this.data.isFixedPricing ? LibraryTemplateType.FIXED_COST : LibraryTemplateType.COMPONENT,
        [Validators.required]
      ),
      markup: new FormControl({ value: null, disabled: true }),
      cost: new FormControl(),
      price: new FormControl(null, this.priceValidator),
      complexities: new FormGroup(
        ComplexitiesList.reduce(
          (group, complexity) => ({
            ...group,
            [complexity]: new FormGroup({
              markup: new FormControl({ value: null, disabled: true }),
              cost: new FormControl(this.data.entry?.componentComplexities?.[complexity]?.costAmount || null),
              price: new FormControl(
                this.data.entry?.componentComplexities?.[complexity]?.fixedCost?.amount,
                this.complexityPriceValidator
              ),
            }),
          }),
          {}
        )
      ),
    })
  }

  onFixedFeeHasRolesChange() {
    this.getControl('price').updateValueAndValidity()
    this.onComplexityPriceChange()
  }

  onComplexityPriceChange() {
    ComplexitiesList.forEach((complexity) => this.getComplexityControl(complexity, 'price')?.updateValueAndValidity())
  }

  private priceValidator: ValidatorFn = (control: FormControl) =>
    this.isPriceInvalid(control) && !this.data.isCreateEntry && !this.getControlValue('fixedFeeHasRoles')
      ? { required: true }
      : null

  private complexityPriceValidator: ValidatorFn = (control: FormControl) =>
    this.isPriceInvalid(control) && this.data.isCreateEntry ? { required: true } : null

  private isPriceInvalid = (control: FormControl) => this.isFixedPricing() && !(control.value || control.value === 0)

  protected readonly Privilege = Privilege
  protected readonly ComplexitiesList = ComplexitiesList
}

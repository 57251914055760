import { Privileged } from '@app/core/class/privileged.class'
import { Company } from '@app/core/model/company.model'
import { Complexity } from '@app/core/model/enums/complexity.enum'
import { Rate } from '@app/core/model/rate.model'
import { User } from '@app/core/model/user.model'
import { Money } from '@app/features/scope-overview/model/money.model'
import { formatMonetaryValue } from '@app/shared/utils/utils'
import { isString } from 'lodash'
import { LibraryDeliverable } from '../deliverable/library-deliverable.model'
import { LibraryComplexity } from '../library-complexity.model'
import { LibraryDiscipline } from '../library-discipline.model'
import { LibraryRateCard } from '../library-rate-card.model'

export class LibraryManagementEntry extends Privileged {
  id: number
  libraryComponentTemplate: {
    itemType: string
    id: number
    name: string
    createdTs: string
    updatedTs: string
  }
  rateCardVersion: LibraryRateCard
  disciplineTypeId: number
  disciplineTypeName: string
  componentComplexities: LibraryItemComplexities
  libraryComponents: { agencyPrice: Rate }[]
  createdTs: string
  updatedTs: string
  createdBy: User
  updatedBy: User
  archived: boolean
  versionNumber: number
  fixedFeeEditable: boolean
  fixedFeeHasRoles: boolean
  privilegeCategories: string[]
  name: string
  language: string
  company: Company
  discipline: LibraryDiscipline
  deliverable: LibraryDeliverable
  fixedPricing: boolean

  getAvailableSizes() {
    var result = Object.values(Complexity)
      .filter(isString)
      .reduce((result, value) => {
        if (this.componentComplexities?.[value]?.roles?.length > 0) {
          if (result.length) result += ','

          result += value
        }

        return result
      }, '')

    return result.length ? result : '-'
  }

  getValue() {
    let currency
    let value = (this.libraryComponents || []).reduce((result, component) => {
      if (!currency) currency = component.agencyPrice.currency
      result += component.agencyPrice?.amount
      return result
    }, 0)

    return (value || value === 0) && currency
      ? formatMonetaryValue(
          new Money(
            (this.libraryComponents || []).reduce((result, component) => {
              if (!currency) currency = component.agencyPrice.currency
              result += component.agencyPrice?.amount
              return result
            }, 0),
            currency
          )
        )
      : '-'
  }
}

export type LibraryItemComplexities = {
  [K in keyof Complexity]: LibraryComplexity
}

import { Type } from 'class-transformer';
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model';
import { AuditMetadata } from '@core/model/auditMetadata.model';

export class Scenario {
  id!: number
  name!: string
  @Type(() => ScenarioCategory) categories: ScenarioCategory[] = []
  @Type(() => AuditMetadata) auditMetadata!: AuditMetadata
}

import { createAction, props } from '@ngrx/store';
import {
  LibraryDeliverableEntryDetails,
} from '@app/features/library-management/store/models/deliverable/library-deliverable-entry-details.model';

export const loadDeliverableEntryDetails = createAction(
  '[Library Deliverable Entry Details] Load Details',
  props<{ page: number; pageSize: number; filter: { libraryItemName?: string } }>(),
);

export const loadDeliverableEntryDetailsSuccess = createAction(
  '[Library Deliverable Entry Details] Load Details Success',
  props<{ data: LibraryDeliverableEntryDetails[]; total: number, last: boolean, number: number }>(),
);

export const loadDeliverableEntryDetailsFailure = createAction(
  '[Library Deliverable Entry Details] Load Details Failure',
  props<{ error: any }>(),
);

export const loadDeliverableEntryDetailsByIds = createAction(
  '[Library Deliverable Entry Details] Load Details By IDs',
  props<{ ids: number[] }>(),
);

export const loadDeliverableEntryDetailsByIdsSuccess = createAction(
  '[Library Deliverable Entry Details] Load Details By IDs Success',
  props<{ data: LibraryDeliverableEntryDetails[] }>(),
);

export const loadDeliverableEntryDetailsByIdsFailure = createAction(
  '[Library Deliverable Entry Details] Load Details By IDs Failure',
  props<{ error: any }>(),
);



export const LibraryDeliverableEntryDetailsActions = {
  loadDeliverableEntryDetails,
  loadDeliverableEntryDetailsSuccess,
  loadDeliverableEntryDetailsFailure,
  loadDeliverableEntryDetailsByIds,
  loadDeliverableEntryDetailsByIdsSuccess,
  loadDeliverableEntryDetailsByIdsFailure,
};

import {
  LibraryManagementEntry,
  LibraryManagementItem,
} from '@app/features/library-management/store/models/library-items'
import { createAction, props } from '@ngrx/store'

import { Sort } from '@angular/material/sort'
import { Preference } from '@app/core/model/user-preferences.interface'
import { LibraryManagementFolder } from '@app/features/library-management/store/models/library-folders'
import { TableColumnPreferences } from '@app/shared/components/ui-components/scope-ui-table/table-column-preferences.type'

export enum LibraryManagementItemsActionTypes {
  GET_LIBRARY_ITEMS = '[Library Management Items] Get Library Items',
  GET_LIBRARY_ITEMS_FAIL = '[Library Management Items] Get Library Items Fail',
  GET_LIBRARY_ITEMS_SUCCESS = '[Library Management Items] Get Library Items Success',

  GET_LIBRARY_ITEM = '[Library Management Items] Get Library Item',
  GET_LIBRARY_ITEM_FAIL = '[Library Management Items] Get Library Item Fail',
  GET_LIBRARY_ITEM_SUCCESS = '[Library Management Items] Get Library Item Success',

  GET_ARCHIVED_LIBRARY_ITEMS = '[Library Management Items] Get Archived Library Items',
  GET_ARCHIVED_LIBRARY_ITEMS_FAIL = '[Library Management Items] Get Archived Library Items Fail',
  GET_ARCHIVED_LIBRARY_ITEMS_SUCCESS = '[Library Management Items] Get Archived Library Items Success',

  SET_PAGE = '[Library Management Items] Set library items page',
  SET_SORT = '[Library Management Items] Set library items sort',
  SET_ARCHIVED_PAGE = '[Library Management Items] Set archived library items page',
  SET_ARCHIVED_SORT = '[Library Management Items] Set archived library items sort',
  SET_DIALOG_ID = '[Library Management] Set dialog id',

  GET_LIBRARY_ITEMS_COLUMNS = '[Library Management Items] Get Library Items Columns',
  GET_LIBRARY_ITEMS_COLUMNS_SUCCESS = '[Library Management Items] Get Library Items Columns Success',
  GET_LIBRARY_ITEMS_COLUMNS_FAIL = '[Library Management Items] Get Library Items Columns Fail',
  UPDATE_LIBRARY_ITEMS_COLUMN_PREFERENCE = '[Library Management Items] Update Library Items Column Preference',
  UPDATE_LIBRARY_ITEMS_COLUMN_PREFERENCE_SUCCESS = '[Library Management Items] Update Library Items Column Preference Success',
  UPDATE_LIBRARY_ITEMS_COLUMN_PREFERENCE_FAIL = '[Library Management Items] Update Library Items Column Preference Fail',

  GET_LIBRARY_ITEM_ENTRIES_COLUMNS = '[Library Management Items] Get Library Item Entries Columns',
  GET_LIBRARY_ITEM_ENTRIES_COLUMNS_SUCCESS = '[Library Management Items] Get Library Item Entries Columns Success',
  GET_LIBRARY_ITEM_ENTRIES_COLUMNS_FAIL = '[Library Management Items] Get Library Item Entries Columns Fail',
  UPDATE_LIBRARY_ITEM_ENTRIES_COLUMN_PREFERENCE = '[Library Management Items] Update Library Item Entries Column Preference',
  UPDATE_LIBRARY_ITEM_ENTRIES_COLUMN_PREFERENCE_SUCCESS = '[Library Management Items] Update Library Item Entries Column Preference Success',
  UPDATE_LIBRARY_ITEM_ENTRIES_COLUMN_PREFERENCE_FAIL = '[Library Management Items] Update Library Item Entries Column Preference Fail',

  UPDATE_LIBRARY_ITEM = '[Library Management Items] Update Library Item',
  UPDATE_LIBRARY_ITEM_FAIL = '[Library Management Items] Update Library Item Fail',
  UPDATE_LIBRARY_ITEM_SUCCESS = '[Library Management Items] Update Library Item Success',

  DUPLICATE_LIBRARY_ITEM = '[Library Management Items] Duplicate Library Item',
  DUPLICATE_LIBRARY_ITEM_FAIL = '[Library Management Items] Duplicate Library Item Fail',
  DUPLICATE_LIBRARY_ITEM_SUCCESS = '[Library Management Items] Duplicate Library Item Success',

  ARCHIVE_LIBRARY_ITEM = '[Library Management Items] Archive Library Item',
  ARCHIVE_LIBRARY_ITEM_FAIL = '[Library Management Items] Archive Library Item Fail',
  ARCHIVE_LIBRARY_ITEM_SUCCESS = '[Library Management Items] Archive Library Item Success',

  DELETE_LIBRARY_ITEM = '[Library Management Items] Delete Library Item',
  DELETE_LIBRARY_ITEM_FAIL = '[Library Management Items] Delete Library Item Fail',
  DELETE_LIBRARY_ITEM_SUCCESS = '[Library Management Items] Delete Library Item Success',

  MOVE_LIBRARY_ITEM = '[Library Management Items] Move Library Item',
  MOVE_LIBRARY_ITEM_FAIL = '[Library Management Items] Move Library Item Fail',
  MOVE_LIBRARY_ITEM_SUCCESS = '[Library Management Items] Move Library Item Success',

  CREATE_FOLDER_AND_MOVE_LIBRARY_ITEM = '[Library Management Items] Create Folder and Move Library Item',
  CREATE_FOLDER_AND_MOVE_LIBRARY_ITEM_FAIL = '[Library Management Items] Create Folder and Move Library Item Fail',
  CREATE_FOLDER_AND_MOVE_LIBRARY_ITEM_SUCCESS = '[Library Management Items] Create Folder and Move Library Item Success',

  DELETE_LIBRARY_ENTRY = '[Library Management Items] Delete Library Entry',
  DELETE_LIBRARY_ENTRY_FAIL = '[Library Management Items] Delete Library Entry Fail',
  DELETE_LIBRARY_ENTRY_SUCCESS = '[Library Management Items] Delete Library Entry Success',
}

const getLibraryItems = createAction(LibraryManagementItemsActionTypes.GET_LIBRARY_ITEMS)
const getLibraryItemsFail = createAction(
  LibraryManagementItemsActionTypes.GET_LIBRARY_ITEMS_FAIL,
  props<{ error: Error }>()
)
const getLibraryItemsSuccess = createAction(
  LibraryManagementItemsActionTypes.GET_LIBRARY_ITEMS_SUCCESS,
  props<{ list: LibraryManagementItem[]; totalElements: number }>()
)

const getLibraryItem = createAction(
  LibraryManagementItemsActionTypes.GET_LIBRARY_ITEM,
  props<{ entity: LibraryManagementItem }>()
)
const getLibraryItemFail = createAction(
  LibraryManagementItemsActionTypes.GET_LIBRARY_ITEM_FAIL,
  props<{ error: Error }>()
)
const getLibraryItemSuccess = createAction(
  LibraryManagementItemsActionTypes.GET_LIBRARY_ITEM_SUCCESS,
  props<{ item: LibraryManagementItem }>()
)

const getArchivedLibraryItems = createAction(LibraryManagementItemsActionTypes.GET_ARCHIVED_LIBRARY_ITEMS)
const getArchivedLibraryItemsFail = createAction(
  LibraryManagementItemsActionTypes.GET_ARCHIVED_LIBRARY_ITEMS_FAIL,
  props<{ error: Error }>()
)
const getArchivedLibraryItemsSuccess = createAction(
  LibraryManagementItemsActionTypes.GET_ARCHIVED_LIBRARY_ITEMS_SUCCESS,
  props<{ list: LibraryManagementItem[]; totalElements: number }>()
)

const setPage = createAction(LibraryManagementItemsActionTypes.SET_PAGE, props<{ page: number }>())
const setSort = createAction(LibraryManagementItemsActionTypes.SET_SORT, props<{ sort: Sort }>())
const setArchivedPage = createAction(LibraryManagementItemsActionTypes.SET_ARCHIVED_PAGE, props<{ page: number }>())
const setArchivedSort = createAction(LibraryManagementItemsActionTypes.SET_ARCHIVED_SORT, props<{ sort: Sort }>())
const setDialogId = createAction(LibraryManagementItemsActionTypes.SET_DIALOG_ID, props<{ id?: string }>())

const getLibraryItemsColumns = createAction(LibraryManagementItemsActionTypes.GET_LIBRARY_ITEMS_COLUMNS)
const getLibraryItemsColumnsSuccess = createAction(
  LibraryManagementItemsActionTypes.GET_LIBRARY_ITEMS_COLUMNS_SUCCESS,
  props<{ preferences: TableColumnPreferences }>()
)
const getLibraryItemsColumnsFail = createAction(
  LibraryManagementItemsActionTypes.GET_LIBRARY_ITEMS_COLUMNS_FAIL,
  props<{ error: Error }>()
)

const updateLibraryItemsColumnPreference = createAction(
  LibraryManagementItemsActionTypes.UPDATE_LIBRARY_ITEMS_COLUMN_PREFERENCE,
  props<{ preference: Preference }>()
)
const updateLibraryItemsColumnPreferenceSuccess = createAction(
  LibraryManagementItemsActionTypes.UPDATE_LIBRARY_ITEMS_COLUMN_PREFERENCE_SUCCESS,
  props<{ preferences: TableColumnPreferences }>()
)
const updateLibraryItemsColumnPreferenceFail = createAction(
  LibraryManagementItemsActionTypes.UPDATE_LIBRARY_ITEMS_COLUMN_PREFERENCE_FAIL,
  props<{ error: Error }>()
)

const getLibraryItemEntriesColumns = createAction(LibraryManagementItemsActionTypes.GET_LIBRARY_ITEM_ENTRIES_COLUMNS)
const getLibraryItemEntriesColumnsSuccess = createAction(
  LibraryManagementItemsActionTypes.GET_LIBRARY_ITEM_ENTRIES_COLUMNS_SUCCESS,
  props<{ preferences: TableColumnPreferences }>()
)
const getLibraryItemEntriesColumnsFail = createAction(
  LibraryManagementItemsActionTypes.GET_LIBRARY_ITEM_ENTRIES_COLUMNS_FAIL,
  props<{ error: Error }>()
)

const updateLibraryItemEntriesColumnPreference = createAction(
  LibraryManagementItemsActionTypes.UPDATE_LIBRARY_ITEM_ENTRIES_COLUMN_PREFERENCE,
  props<{ preference: Preference }>()
)
const updateLibraryItemEntriesColumnPreferenceSuccess = createAction(
  LibraryManagementItemsActionTypes.UPDATE_LIBRARY_ITEM_ENTRIES_COLUMN_PREFERENCE_SUCCESS,
  props<{ preferences: TableColumnPreferences }>()
)
const updateLibraryItemEntriesColumnPreferenceFail = createAction(
  LibraryManagementItemsActionTypes.UPDATE_LIBRARY_ITEM_ENTRIES_COLUMN_PREFERENCE_FAIL,
  props<{ error: Error }>()
)

const updateLibraryItem = createAction(
  LibraryManagementItemsActionTypes.UPDATE_LIBRARY_ITEM,
  props<{ item: LibraryManagementItem }>()
)
const updateLibraryItemFail = createAction(
  LibraryManagementItemsActionTypes.UPDATE_LIBRARY_ITEM_FAIL,
  props<{ error: Error }>()
)
const updateLibraryItemSuccess = createAction(
  LibraryManagementItemsActionTypes.UPDATE_LIBRARY_ITEM_SUCCESS,
  props<{ item: LibraryManagementItem }>()
)

const duplicateLibraryItem = createAction(
  LibraryManagementItemsActionTypes.DUPLICATE_LIBRARY_ITEM,
  props<{ item: LibraryManagementItem }>()
)
const duplicateLibraryItemFail = createAction(
  LibraryManagementItemsActionTypes.DUPLICATE_LIBRARY_ITEM_FAIL,
  props<{ error: Error }>()
)
const duplicateLibraryItemSuccess = createAction(
  LibraryManagementItemsActionTypes.DUPLICATE_LIBRARY_ITEM_SUCCESS,
  props<{ item: LibraryManagementItem }>()
)

const archiveLibraryItem = createAction(
  LibraryManagementItemsActionTypes.ARCHIVE_LIBRARY_ITEM,
  props<{ item: LibraryManagementItem }>()
)
const archiveLibraryItemFail = createAction(
  LibraryManagementItemsActionTypes.ARCHIVE_LIBRARY_ITEM_FAIL,
  props<{ error: Error }>()
)
const archiveLibraryItemSuccess = createAction(
  LibraryManagementItemsActionTypes.ARCHIVE_LIBRARY_ITEM_SUCCESS,
  props<{ item: LibraryManagementItem }>()
)

const deleteLibraryItem = createAction(
  LibraryManagementItemsActionTypes.DELETE_LIBRARY_ITEM,
  props<{ item: LibraryManagementItem }>()
)
const deleteLibraryItemFail = createAction(
  LibraryManagementItemsActionTypes.DELETE_LIBRARY_ITEM_FAIL,
  props<{ error: Error }>()
)
const deleteLibraryItemSuccess = createAction(
  LibraryManagementItemsActionTypes.DELETE_LIBRARY_ITEM_SUCCESS,
  props<{ item: LibraryManagementItem }>()
)

const moveLibraryItem = createAction(
  LibraryManagementItemsActionTypes.MOVE_LIBRARY_ITEM,
  props<{ id: number; folderId: number }>()
)
const moveLibraryItemFail = createAction(
  LibraryManagementItemsActionTypes.MOVE_LIBRARY_ITEM_FAIL,
  props<{ error: Error }>()
)
const moveLibraryItemSuccess = createAction(LibraryManagementItemsActionTypes.MOVE_LIBRARY_ITEM_SUCCESS)

const createFolderAndMoveLibraryItem = createAction(
  LibraryManagementItemsActionTypes.CREATE_FOLDER_AND_MOVE_LIBRARY_ITEM,
  props<{ id: number; folderId: number; name: string }>()
)
const createFolderAndMoveLibraryItemFail = createAction(
  LibraryManagementItemsActionTypes.CREATE_FOLDER_AND_MOVE_LIBRARY_ITEM_FAIL,
  props<{ error: Error }>()
)
const createFolderAndMoveLibraryItemSuccess = createAction(
  LibraryManagementItemsActionTypes.CREATE_FOLDER_AND_MOVE_LIBRARY_ITEM_SUCCESS,
  props<{ folder: LibraryManagementFolder }>()
)

const deleteLibraryEntry = createAction(
  LibraryManagementItemsActionTypes.DELETE_LIBRARY_ENTRY,
  props<{ item: LibraryManagementEntry }>()
)
const deleteLibraryEntryFail = createAction(
  LibraryManagementItemsActionTypes.DELETE_LIBRARY_ENTRY_FAIL,
  props<{ error: Error }>()
)
const deleteLibraryEntrySuccess = createAction(
  LibraryManagementItemsActionTypes.DELETE_LIBRARY_ENTRY_SUCCESS,
  props<{ item: LibraryManagementEntry }>()
)

export const LibraryManagementItemsActions = {
  getLibraryItems,
  getLibraryItemsFail,
  getLibraryItemsSuccess,
  getLibraryItem,
  getLibraryItemFail,
  getLibraryItemSuccess,
  getArchivedLibraryItems,
  getArchivedLibraryItemsFail,
  getArchivedLibraryItemsSuccess,
  setPage,
  setSort,
  setArchivedPage,
  setArchivedSort,
  getLibraryItemsColumns,
  getLibraryItemsColumnsSuccess,
  getLibraryItemsColumnsFail,
  updateLibraryItemsColumnPreference,
  updateLibraryItemsColumnPreferenceSuccess,
  updateLibraryItemsColumnPreferenceFail,
  getLibraryItemEntriesColumns,
  getLibraryItemEntriesColumnsSuccess,
  getLibraryItemEntriesColumnsFail,
  updateLibraryItemEntriesColumnPreference,
  updateLibraryItemEntriesColumnPreferenceSuccess,
  updateLibraryItemEntriesColumnPreferenceFail,
  updateLibraryItem,
  updateLibraryItemFail,
  updateLibraryItemSuccess,
  setDialogId,
  duplicateLibraryItem,
  duplicateLibraryItemFail,
  duplicateLibraryItemSuccess,
  archiveLibraryItem,
  archiveLibraryItemFail,
  archiveLibraryItemSuccess,
  deleteLibraryItem,
  deleteLibraryItemFail,
  deleteLibraryItemSuccess,
  moveLibraryItem,
  moveLibraryItemFail,
  moveLibraryItemSuccess,
  createFolderAndMoveLibraryItem,
  createFolderAndMoveLibraryItemFail,
  createFolderAndMoveLibraryItemSuccess,
  deleteLibraryEntry,
  deleteLibraryEntryFail,
  deleteLibraryEntrySuccess,
}

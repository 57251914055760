import { Actions, createEffect, ofType } from '@ngrx/effects'
import { LibraryFormError, LibraryItemName } from '@app/features/library-management/store/enums'
import { LibraryManagementActions, LibraryThirdPartyCostActions } from '@app/features/library-management/store/actions'
import {
  LibraryManagementApiMappingService,
  LibraryThirdPartyCostService,
} from '@app/features/library-management/services'
import {
  LibraryManagementSelectors,
  LibraryThirdPartyCostSelectors,
} from '@app/features/library-management/store/selectors'
import {
  SNACKBAR_LENGTH_LONG,
  SNACKBAR_LENGTH_SHORT,
  SnackbarEventType,
  SnackbarService,
} from '@app/shared/utils/snackbar.service'
import { Store, select } from '@ngrx/store'
import { catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs'

import { Injectable } from '@angular/core'
import { LanguageService } from '@app/core/service/language.service'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'
import { ThirdPartyCostFormula } from '@app/core/model/third-party-cost-formula.model'
import { ThirdPartyCostGroup } from '@app/features/library-management/store/models/third-party-cost'

@Injectable()
export class LibraryThirdPartyCostEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private router: Router,
    private snackbarService: SnackbarService,
    private libraryThirdPartyCostService: LibraryThirdPartyCostService,
    private libraryApiMappingService: LibraryManagementApiMappingService,
    private lang: LanguageService,
    public dialog: MatDialog
  ) {}

  getComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryThirdPartyCostActions.getThirdPartyCost),
      filter(({ id }) => !!id),
      switchMap(({ id }) =>
        this.libraryThirdPartyCostService.getThirdPartyCost(id).pipe(
          map((tpc) => LibraryThirdPartyCostActions.getThirdPartyCostSuccess({ tpc })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(error.error, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(LibraryThirdPartyCostActions.getThirdPartyCostFail({ error }))
          })
        )
      )
    )
  )

  getThirdPartyCostGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryThirdPartyCostActions.getGroups),
      withLatestFrom(this.store$.pipe(select(LibraryThirdPartyCostSelectors.selectIsGroupsLoaded))),
      filter(([, isLoaded]: [any, boolean]) => !isLoaded),
      switchMap(() =>
        this.libraryThirdPartyCostService.getThirdPartyCostGroups().pipe(
          map((groups: ThirdPartyCostGroup[]) => LibraryThirdPartyCostActions.getGroupsSuccess({ groups })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(error.error, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(LibraryThirdPartyCostActions.getGroupsFail({ error }))
          })
        )
      )
    )
  )

  getThirdPartyCostFormulas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryThirdPartyCostActions.getFormulas),
      withLatestFrom(this.store$.pipe(select(LibraryThirdPartyCostSelectors.selectIsFormulasLoaded))),
      filter(([, isLoaded]: [any, boolean]) => !isLoaded),
      switchMap(() =>
        this.libraryThirdPartyCostService.getThirdPartyCostFormulaDefinition().pipe(
          map((formulas: ThirdPartyCostFormula[]) => LibraryThirdPartyCostActions.getFormulasSuccess({ formulas })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(error.error, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(LibraryThirdPartyCostActions.getFormulasFail({ error }))
          })
        )
      )
    )
  )

  createThirdPartyCost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryThirdPartyCostActions.createThirdPartyCost),
      withLatestFrom(
        this.store$.select(LibraryManagementSelectors.selectDialogId),
        this.store$.select(LibraryManagementSelectors.selectFolder)
      ),
      switchMap(([tpc, dialogId, folder]) =>
        this.libraryThirdPartyCostService
          .createThirdPartyCost(this.libraryApiMappingService.mapThirdPartyCostToApi(tpc, folder))
          .pipe(
            switchMap((response: ThirdPartyCost) => [
              LibraryManagementActions.closeActiveModal(),
              LibraryThirdPartyCostActions.createThirdPartyCostSuccess({ tpc: response }),
              LibraryThirdPartyCostActions.navigateToThirdPartyCost({ id: response.id }),
            ]),
            catchError((error: any) => {
              if (error.status === 409) {
                this.setAlreadyExistsError(dialogId)
                return of(LibraryThirdPartyCostActions.createThirdPartyCostFail({ error }))
              }

              console.error(error)
              this.snackbarService.showSnackbar(error.error, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
              return of(LibraryThirdPartyCostActions.createThirdPartyCostFail({ error }))
            })
          )
      )
    )
  )

  updateThirdPartyCost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryThirdPartyCostActions.updateThirdPartyCost),
      switchMap(({ tpc }) =>
        this.libraryThirdPartyCostService
          .updateThirdPartyCost(tpc.id, this.libraryApiMappingService.mapThirdPartyCostToUpdateApi(tpc))
          .pipe(
            map((tpc) => {
              this.snackbarService.showSnackbar('Changes saved', SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
              return LibraryThirdPartyCostActions.updateThirdPartyCostSuccess({ tpc })
            }),
            catchError((error) => {
              console.error(error)
              this.snackbarService.showSnackbar(error.error, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
              return of(LibraryThirdPartyCostActions.updateThirdPartyCostFail(error))
            })
          )
      )
    )
  )

  duplicateThirdPartyCost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryThirdPartyCostActions.duplicateThirdPartyCost),
      switchMap(({ id, name }) =>
        this.libraryThirdPartyCostService
          .duplicateThirdPartyCost(id, name)
          .pipe(
            switchMap((tpc) => [
              LibraryThirdPartyCostActions.duplicateThirdPartyCostSuccess(),
              LibraryThirdPartyCostActions.navigateToThirdPartyCost({ id: tpc.id }),
            ]),
            catchError((error) => {
              console.error(error)
              this.snackbarService.showSnackbar(error.error, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
              return of(LibraryThirdPartyCostActions.duplicateThirdPartyCostFail(error))
            })
          )
      )
    )
  )

  deleteThirdPartyCost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryThirdPartyCostActions.deleteThirdPartyCost),
      switchMap(({ id }) =>
        this.libraryThirdPartyCostService.deleteThirdPartyCost(id).pipe(
          switchMap(() => {
            this.snackbarService.showSnackbar(
              `${this.lang.get('third_party_cost')} was successfully deleted`,
              SNACKBAR_LENGTH_SHORT,
              SnackbarEventType.SUCCESS
            )

            return [
              LibraryThirdPartyCostActions.deleteThirdPartyCostSuccess(),
              LibraryThirdPartyCostActions.navigateToLibrary(),
            ]
          }),
          catchError((error) => {
            console.error(error)
            this.snackbarService.showSnackbar(error.error, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(LibraryThirdPartyCostActions.deleteThirdPartyCostFail(error))
          })
        )
      )
    )
  )

  navigateToLibrary$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryThirdPartyCostActions.navigateToLibrary),
        map(() => {
          this.router.navigate(['library'])
        })
      ),
    { dispatch: false }
  )

  navigateToThirdPartyCost$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryThirdPartyCostActions.navigateToThirdPartyCost),
        map(({ id }) => {
          this.router.navigate([`library/third-party-cost/${id}`])
        })
      ),
    { dispatch: false }
  )

  setAlreadyExistsError(dialogId: string) {
    let modalForm = this.dialog.getDialogById(dialogId)?.componentInstance.modalForm
    modalForm.get('name').setErrors({
      [LibraryFormError.ALREADY_EXISTS]: `${this.lang.get(
        LibraryItemName.THIRD_PARTY_COST
      )} with this name already exists`,
    })
    modalForm.markAllAsTouched()
  }
}

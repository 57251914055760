<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>Share Folder</h1>
    </div>
    <span class="mt-2"> Please select groups below where '{{ data.folder.name }}' folder will be shared. </span>
    <button
      class="close-button"
      mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content
  aria-label="Generic modal"
  class="modal-container md-dialog-container library share-settings">
  <div class="modal__body">
    <h3 class="mt-0">Groups To Share</h3>

    <scope-ui-checkbox
      title="Select All"
      [ngModel]="isAllSelected"
      (ngModelChange)="selectAll()"></scope-ui-checkbox>

    <div *ngFor="let group of groups; trackBy: trackById">
      <scope-ui-checkbox
        [title]="group.companyGroupName"
        [(ngModel)]="group.isShared"></scope-ui-checkbox>
    </div>
  </div>

  <mat-dialog-actions class="flex justify-end gap-2 library-action-buttons">
    <button
      mat-button
      mat-dialog-close>
      Cancel
    </button>
    <button
      class="main-action-button ml-4"
      mat-button
      mat-flat-button
      (click)="submit()">
      Share Settings
    </button>
  </mat-dialog-actions>
</mat-dialog-content>

import { Action, createReducer, on } from '@ngrx/store'
import {
  GetArchivedLibraryItemsSuccessAction,
  GetLibraryItemAction,
  GetLibraryItemEntriesColumnsSuccessAction,
  GetLibraryItemSuccessAction,
  GetLibraryItemsColumnsSuccessAction,
  GetLibraryItemsSuccessAction,
  SetArchivedItemsPageAction,
  SetArchivedItemsSortAction,
  SetItemsDialogIdAction,
  SetItemsPageAction,
  SetItemsSortAction,
  UpdateLibraryItemSuccessAction,
} from '@app/features/library-management/store/models/library-items'
import {
  LibraryManagementActions,
  LibraryManagementItemsActions,
  libraryItemsAdapter,
  libraryManagementItemsInitialState,
  libraryManagementPaginationInitialState,
  libraryManagementSortInitialState,
} from '@app/features/library-management/store'

import { LibraryManagementItemsState } from '@app/features/library-management/store/models/library-items'

const loadingTrueHandler = (state: LibraryManagementItemsState) => ({ ...state, isLoading: true })
const loadingFalseHandler = (state: LibraryManagementItemsState) => ({ ...state, isLoading: false })

const getLibraryItemsSuccessHandler = (state: LibraryManagementItemsState, action: GetLibraryItemsSuccessAction) => ({
  ...state,
  isLoading: false,
  list: libraryItemsAdapter.setAll(action.list, state.list),
  pagination: {
    ...state.pagination,
    totalCount: action.totalElements,
  },
})

const getArchivedLibraryItemsHandler = (state: LibraryManagementItemsState) => ({
  ...state,
  isLoadingArchived: true,
})

const getArchivedLibraryItemsSuccessHandler = (
  state: LibraryManagementItemsState,
  action: GetArchivedLibraryItemsSuccessAction
) => ({
  ...state,
  isLoadingArchived: false,
  archivedList: libraryItemsAdapter.setAll(action.list, state.archivedList),
  archivedPagination: {
    ...state.archivedPagination,
    totalCount: action.totalElements,
  },
})

const getArchivedLibraryItemsFailHandler = (state: LibraryManagementItemsState) => ({
  ...state,
  isLoadingArchived: false,
})

const getLibraryItemHandler = (state: LibraryManagementItemsState, action: GetLibraryItemAction) => ({
  ...state,
  list: libraryItemsAdapter.updateOne({ id: action.entity.id, changes: { isLoading: true } }, state.list),
  archivedList: libraryItemsAdapter.updateOne({ id: action.entity.id, changes: { isLoading: true } }, state.archivedList),
})
const getLibraryItemSuccessHandler = (state: LibraryManagementItemsState, action: GetLibraryItemSuccessAction) => ({
  ...state,
  list: libraryItemsAdapter.updateOne({ id: action.item.id, changes: { ...action.item, isLoading: false } }, state.list),
  archivedList: libraryItemsAdapter.updateOne({ id: action.item.id, changes: { ...action.item, isLoading: false } }, state.archivedList),
})

const setPageHandler = (state: LibraryManagementItemsState, action: SetItemsPageAction) => ({
  ...state,
  isLoading: true,
  pagination: { ...state.pagination, page: action.page },
})

const setSortHandler = (state: LibraryManagementItemsState, action: SetItemsSortAction) => ({
  ...state,
  isLoading: true,
  sort: action.sort,
})

const setArchivedPageHandler = (state: LibraryManagementItemsState, action: SetArchivedItemsPageAction) => ({
  ...state,
  isLoadingArchived: true,
  archivedPagination: { ...state.archivedPagination, page: action.page },
})

const setArchivedSortHandler = (state: LibraryManagementItemsState, action: SetArchivedItemsSortAction) => ({
  ...state,
  isLoadingArchived: true,
  archivedSort: action.sort,
})

const getLibraryItemsColumnsHandler = (state: LibraryManagementItemsState) => ({ ...state, isLoadingColumns: true })
const getLibraryItemsColumnsFailHandler = (state: LibraryManagementItemsState) => ({
  ...state,
  isLoadingColumns: false,
})
const getLibraryItemsColumnsSuccessHandler = (
  state: LibraryManagementItemsState,
  action: GetLibraryItemsColumnsSuccessAction
) => ({
  ...state,
  columns: { ...state.columns, ...action.preferences },
  isLoadingColumns: false,
})
const getLibraryItemEntriesColumnsSuccessHandler = (
  state: LibraryManagementItemsState,
  action: GetLibraryItemEntriesColumnsSuccessAction
) => ({
  ...state,
  childColumns: { ...state.childColumns, ...action.preferences },
  isLoadingColumns: false,
})

const updateFiltersHandler = (state: LibraryManagementItemsState) => ({
  ...state,
  pagination: libraryManagementPaginationInitialState,
  sort: libraryManagementSortInitialState,
  archivedPagination: libraryManagementPaginationInitialState,
  archivedSort: libraryManagementSortInitialState,
})

const updateLibraryItemSuccessHandler = (
  state: LibraryManagementItemsState,
  action: UpdateLibraryItemSuccessAction
) => ({
  ...state,
  isLoading: false,
  list: libraryItemsAdapter.updateOne(
    { id: action.item.id, changes: { name: action.item.name, version: action.item.version } },
    state.list
  ),
  archivedList: libraryItemsAdapter.updateOne(
    { id: action.item.id, changes: { name: action.item.name, version: action.item.version } },
    state.archivedList
  ),
})

const setDialogIdHandler = (state: LibraryManagementItemsState, action: SetItemsDialogIdAction) => ({
  ...state,
  dialogId: action.id,
})

const setFolderIdHandler = (state: LibraryManagementItemsState) => ({
  ...state,
  list: libraryItemsAdapter.setAll([], state.list),
  pagination: libraryManagementPaginationInitialState,
  archivedList: libraryItemsAdapter.setAll([], state.archivedList),
  archivedPagination: libraryManagementPaginationInitialState,
})

export const libraryManagementItemsReducer = (
  state = libraryManagementItemsInitialState,
  action: Action
): LibraryManagementItemsState =>
  createReducer(
    libraryManagementItemsInitialState,
    on(
      LibraryManagementItemsActions.getLibraryItems,
      LibraryManagementItemsActions.archiveLibraryItem,
      LibraryManagementItemsActions.deleteLibraryItem,
      LibraryManagementActions.updateFilters,
      LibraryManagementActions.setFolderId,
      LibraryManagementActions.getFolderSuccess,
      loadingTrueHandler
    ),
    on(
      LibraryManagementItemsActions.getLibraryItemsFail,
      LibraryManagementItemsActions.archiveLibraryItemFail,
      LibraryManagementItemsActions.deleteLibraryItemFail,
      loadingFalseHandler
    ),
    on(LibraryManagementItemsActions.getLibraryItemsSuccess, getLibraryItemsSuccessHandler),
    on(LibraryManagementItemsActions.getLibraryItem, getLibraryItemHandler),
    on(LibraryManagementItemsActions.getLibraryItemSuccess, getLibraryItemSuccessHandler),
    on(
      LibraryManagementItemsActions.getArchivedLibraryItems,
      LibraryManagementActions.getFolderSuccess,
      getArchivedLibraryItemsHandler
    ),
    on(LibraryManagementItemsActions.getArchivedLibraryItemsSuccess, getArchivedLibraryItemsSuccessHandler),
    on(LibraryManagementItemsActions.getArchivedLibraryItemsFail, getArchivedLibraryItemsFailHandler),
    on(LibraryManagementItemsActions.setPage, setPageHandler),
    on(LibraryManagementItemsActions.setSort, setSortHandler),
    on(LibraryManagementItemsActions.setArchivedPage, setArchivedPageHandler),
    on(LibraryManagementItemsActions.setArchivedSort, setArchivedSortHandler),
    on(
      LibraryManagementItemsActions.getLibraryItemsColumns,
      LibraryManagementItemsActions.getLibraryItemEntriesColumns,
      LibraryManagementItemsActions.updateLibraryItemsColumnPreference,
      LibraryManagementItemsActions.updateLibraryItemEntriesColumnPreference,
      getLibraryItemsColumnsHandler
    ),
    on(
      LibraryManagementItemsActions.getLibraryItemsColumnsFail,
      LibraryManagementItemsActions.getLibraryItemEntriesColumnsFail,
      LibraryManagementItemsActions.updateLibraryItemsColumnPreferenceFail,
      LibraryManagementItemsActions.updateLibraryItemEntriesColumnPreferenceFail,
      getLibraryItemsColumnsFailHandler
    ),
    on(
      LibraryManagementItemsActions.getLibraryItemsColumnsSuccess,
      LibraryManagementItemsActions.updateLibraryItemsColumnPreferenceSuccess,
      getLibraryItemsColumnsSuccessHandler
    ),
    on(
      LibraryManagementItemsActions.getLibraryItemEntriesColumnsSuccess,
      LibraryManagementItemsActions.updateLibraryItemEntriesColumnPreferenceSuccess,
      getLibraryItemEntriesColumnsSuccessHandler
    ),
    on(LibraryManagementActions.updateFilters, updateFiltersHandler),
    on(LibraryManagementItemsActions.updateLibraryItemSuccess, updateLibraryItemSuccessHandler),
    on(LibraryManagementItemsActions.setDialogId, setDialogIdHandler),
    on(LibraryManagementActions.setFolderId, setFolderIdHandler)
  )(state, action)

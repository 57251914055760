import { createAction, props } from '@ngrx/store';
import { BootstrapConfig } from '../../model/bootstrap-config.model';
import { IdpDiscovery } from '@app/core/model/definitions/idp-discovery.interface';
import { CompanyType } from '@app/core/model/enums/company-type.enum';
import { Preference, UserPreferences } from '@core/model/user-preferences.interface';

export enum BootstrapActionTypes {
  LOAD_BOOTSTRAP_CONFIG = '[BOOTSTRAP CONFIG] Load',
  LOAD_BOOTSTRAP_CONFIG_SUCCESS = '[BOOTSTRAP CONFIG] Load Success',
  LOAD_BOOTSTRAP_CONFIG_FAILURE = '[BOOTSTRAP CONFIG] Load Failure',
  LOAD_USER_PREFERENCES = '[BOOTSTRAP CONFIG] Load User Preferences',
  LOAD_USER_PREFERENCES_FAILURE = '[BOOTSTRAP CONFIG] Load User Preferences Failure',
  SET_COMPANY_TYPE = '[BOOTSTRAP CONFIG] Set Company Type',
  IDP_DISCOVERY = '[BOOTSTRAP CONFIG] IDP Discovery (Identity Provider Discovery)',
  IDP_DISCOVERY_SUCCESS = '[BOOTSTRAP CONFIG] IDP Discovery Success (Identity Provider Discovery)',
  IDP_DISCOVERY_FAIL = '[BOOTSTRAP CONFIG] IDP Discover Fail (Identity Provider Discovery)',
  IDP_SAML_LOGIN = '[BOOTSTRAP CONFIG] IDP SAML Login',
  IDP_SAML_LOGIN_SUCCESS = '[BOOTSTRAP CONFIG] IDP SAML Login Success',
  IDP_SAML_LOGIN_FAIL = '[BOOTSTRAP CONFIG] IDP SAML Login Fail',
  SET_MY_SCOPES_COLUMNS_PREFERENCE_SUCCESS = '[BOOTSTRAP CONFIG] SET_MYSCOPESCOLUMNS_PREFERENCE_SUCCESS',
  SET_MY_SCOPES_COLUMNS_PREFERENCE_FAILURE = '[BOOTSTRAP CONFIG] SET_MYSCOPESCOLUMNS_PREFERENCE_FAILURE',

  SET_SOW_OVERVIEW_COLUMNS_PREFERENCE_SUCCESS = '[BOOTSTRAP CONFIG] SET_SOWOVERVIEWCOLUMNS_PREFERENCE_SUCCESS',
  SET_SOW_OVERVIEW_COLUMNS_PREFERENCE_FAILURE = '[BOOTSTRAP CONFIG] SET_SOWOVERVIEWCOLUMNS_PREFERENCE_FAILURE',

  SET_SCOPE_OVERVIEW_USERCOLUMNS_SUCCESS = '[BOOTSTRAP CONFIG] SCOPEOVERVIEW_USERCOLUMNS_SUCCESS',
  SET_SCOPE_OVERVIEW_USERCOLUMNS_FAILURE = '[BOOTSTRAP CONFIG] SCOPEOVERVIEW_USERCOLUMNS_FAILURE',
  UPDATE_COLUMN_PREFERENCES = '[BOOTSTRAP CONFIG] Update Scope Table with User Page Preferences',
  UPDATE_OVERVIEW_COLUMN_PREFERENCES = '[BOOTSTRAP CONFIG] Update Scope Deliverable Table with User Page Preferences',
  UPDATE_COLUMN_PREFERENCES_SUCCESS = '[BOOTSTRAP CONFIG] Update Scope Table with User Page Preferences Success',
  UPDATE_OVERVIEW_COLUMN_PREFERENCES_SUCCESS = '[BOOTSTRAP CONFIG] Update Scope Deliverable Table with User Page Preferences Success',
  UPDATE_COLUMN_PREFERENCES_FAIL = '[BOOTSTRAP CONFIG] Update Scope Table with User Page Preferences Fail',
  LOAD_TASK_PREFERENCES = '[BOOTSTRAP CONFIG] Load Task Table Page Preferences',
  LOAD_TASK_PREFERENCES_SUCCESS = '[BOOTSTRAP CONFIG] Load Task Table Page Preferences Success',
  LOAD_TASK_PREFERENCES_FAIL = '[BOOTSTRAP CONFIG] Load Task Table Page Preferences Fail',
  UPDATE_TASK_COLUMN_PREFERENCES = '[BOOTSTRAP CONFIG] Update Task Table Page Preferences',
  UPDATE_TASK_COLUMN_PREFERENCES_SUCCESS= '[BOOTSTRAP CONFIG] Update Task Table User Preferences Success',
  UPDATE_TASK_COLUMN_PREFERENCES_FAIL = '[BOOTSTRAP CONFIG] Update Task Table User Preferences Fail',
  LOAD_MODIFIER_PREFERENCES = '[BOOTSTRAP CONFIG] Load Modifier Table User Preferences',
  LOAD_MODIFIER_PREFERENCES_SUCCESS = '[BOOTSTRAP CONFIG] Load Modifier Table User Preferences Success',
  LOAD_MODIFIER_PREFERENCES_FAIL = '[BOOTSTRAP CONFIG] Load Modifier Table User Preferences Fail',
  UPDATE_MODIFIER_COLUMN_PREFERENCES = '[BOOTSTRAP CONFIG] Update Modifier Table User Preferences',
  UPDATE_MODIFIER_COLUMN_PREFERENCES_SUCCESS= '[BOOTSTRAP CONFIG] Update Modifier Table User Preferences Success',
  UPDATE_MODIFIER_COLUMN_PREFERENCES_FAIL = '[BOOTSTRAP CONFIG] Update Modifier Table User Preferences Fail',
  GET_MODIFIER_OVERRIDE_WARNING_PREFERENCE = '[BOOTSTRAP CONFIG] Get Modifier Override Warning Preference',
  GET_MODIFIER_OVERRIDE_WARNING_PREFERENCE_SUCCESS = '[BOOTSTRAP CONFIG] Get Modifier Override Warning Preference Success',
  GET_MODIFIER_OVERRIDE_WARNING_PREFERENCE_FAIL = '[BOOTSTRAP CONFIG] Get Modifier Override Warning Preference Fail',
  UPDATE_MODIFIER_OVERRIDE_WARNING_PREFERENCE = '[BOOTSTRAP CONFIG] Update Modifier Override Warning Preference',
  UPDATE_MODIFIER_OVERRIDE_WARNING_PREFERENCE_SUCCESS = '[BOOTSTRAP CONFIG] Update Modifier Override Warning Preference Success',
  UPDATE_MODIFIER_OVERRIDE_WARNING_PREFERENCE_FAIL = '[BOOTSTRAP CONFIG] Update Modifier Override Warning Preference Fail',
}

const loadBootstrapConfig = createAction(BootstrapActionTypes.LOAD_BOOTSTRAP_CONFIG);

const loadBootstrapConfigSuccess = createAction(
  BootstrapActionTypes.LOAD_BOOTSTRAP_CONFIG_SUCCESS,
  props<{ bsConfig?: BootstrapConfig }>()
);

const loadBootstrapConfigFail = createAction(
  BootstrapActionTypes.LOAD_BOOTSTRAP_CONFIG_FAILURE,
  props<{ error: Error }>()
);

const loadUserPreferences = createAction(BootstrapActionTypes.LOAD_USER_PREFERENCES);

const loadUserPreferencesFail = createAction(
  BootstrapActionTypes.LOAD_USER_PREFERENCES_FAILURE,
  props<{ error: Error }>()
);

const setCompanyType = createAction(
  BootstrapActionTypes.SET_COMPANY_TYPE,
  props<{ companyType: string | CompanyType }>()
);

// PAGE PREFERENCES
const setMyScopesColumnsFail = createAction(
  BootstrapActionTypes.SET_MY_SCOPES_COLUMNS_PREFERENCE_FAILURE,
  props<{ error: Error }>()
);

const setMyScopesColumnsSuccess = createAction(
  BootstrapActionTypes.SET_MY_SCOPES_COLUMNS_PREFERENCE_SUCCESS,
  props<{ preferences: UserPreferences }>()
);

const updateScopeColumnPreferences = createAction(
  BootstrapActionTypes.UPDATE_COLUMN_PREFERENCES,
  props<{ updatedPrefs: Preference }>()
);

const updateScopeOverviewColumnPreferences = createAction(
  BootstrapActionTypes.UPDATE_OVERVIEW_COLUMN_PREFERENCES,
  props<{ updatedPrefs: Preference }>()
);

const updateScopeColumnPreferencesSuccess = createAction(BootstrapActionTypes.UPDATE_COLUMN_PREFERENCES_SUCCESS);
const updateScopeOverviewColumnPreferencesSuccess = createAction(BootstrapActionTypes.UPDATE_OVERVIEW_COLUMN_PREFERENCES_SUCCESS);

const updateScopeColumnPreferencesFail = createAction(
  BootstrapActionTypes.UPDATE_COLUMN_PREFERENCES_FAIL,
  props<{ error: Error }>()
);

const setSowOverviewColumnsSuccess = createAction(
  BootstrapActionTypes.SET_SOW_OVERVIEW_COLUMNS_PREFERENCE_SUCCESS,
  props<{ preferences: UserPreferences }>()
);

const setSowOverviewColumnsFail = createAction(
  BootstrapActionTypes.SET_SOW_OVERVIEW_COLUMNS_PREFERENCE_FAILURE,
  props<{ error: Error }>()
);

const setScopeOverviewColumnsSuccess = createAction(
  BootstrapActionTypes.SET_SCOPE_OVERVIEW_USERCOLUMNS_SUCCESS,
  props<{ preferences: UserPreferences }>()
);

const setScopeOverviewColumnsFail = createAction(
  BootstrapActionTypes.SET_SCOPE_OVERVIEW_USERCOLUMNS_FAILURE,
  props<{ error: Error }>()
);

const loadTaskPreferences = createAction(BootstrapActionTypes.LOAD_TASK_PREFERENCES);
const loadTaskPreferencesSuccess = createAction(
  BootstrapActionTypes.LOAD_TASK_PREFERENCES_SUCCESS,
  props<{ preferences?: UserPreferences }>()
);
const loadTaskPreferencesFail = createAction(
  BootstrapActionTypes.LOAD_TASK_PREFERENCES_FAIL,
  props<{ error: Error }>()
);

const updateTaskColumnPreferences = createAction(
  BootstrapActionTypes.UPDATE_TASK_COLUMN_PREFERENCES,
  props<{ preference: Preference }>()
);
const updateTaskColumnPreferencesSuccess = createAction(BootstrapActionTypes.UPDATE_TASK_COLUMN_PREFERENCES_SUCCESS);
const updateTaskColumnPreferencesFail = createAction(
  BootstrapActionTypes.UPDATE_TASK_COLUMN_PREFERENCES_FAIL,
  props<{ error: Error }>()
);

const loadModifierPreferences = createAction(BootstrapActionTypes.LOAD_MODIFIER_PREFERENCES);
const loadModifierPreferencesSuccess = createAction(
  BootstrapActionTypes.LOAD_MODIFIER_PREFERENCES_SUCCESS,
  props<{ preferences?: UserPreferences }>()
);
const loadModifierPreferencesFail = createAction(
  BootstrapActionTypes.LOAD_MODIFIER_PREFERENCES_FAIL,
  props<{ error: Error }>()
);

const updateModifierColumnPreferences = createAction(
  BootstrapActionTypes.UPDATE_MODIFIER_COLUMN_PREFERENCES,
  props<{ preference: Preference }>()
);
const updateModifierColumnPreferencesSuccess = createAction(BootstrapActionTypes.UPDATE_MODIFIER_COLUMN_PREFERENCES_SUCCESS);
const updateModifierColumnPreferencesFail = createAction(
  BootstrapActionTypes.UPDATE_MODIFIER_COLUMN_PREFERENCES_FAIL,
  props<{ error: Error }>()
);

const getModifierOverrideWarningPreference = createAction(BootstrapActionTypes.GET_MODIFIER_OVERRIDE_WARNING_PREFERENCE);
const getModifierOverrideWarningPreferenceSuccess = createAction(
  BootstrapActionTypes.GET_MODIFIER_OVERRIDE_WARNING_PREFERENCE_SUCCESS,
  props<{ preferences?: { [key: string]: boolean } }>()
);
const getModifierOverrideWarningPreferenceFail = createAction(
  BootstrapActionTypes.GET_MODIFIER_OVERRIDE_WARNING_PREFERENCE_FAIL,
  props<{ error: Error }>()
);

const updateModifierOverrideWarningPreference = createAction(
  BootstrapActionTypes.UPDATE_MODIFIER_OVERRIDE_WARNING_PREFERENCE,
  props<{ preferences?: { [key: string]: boolean } }>()
);
const updateModifierOverrideWarningPreferenceSuccess = createAction(BootstrapActionTypes.UPDATE_MODIFIER_OVERRIDE_WARNING_PREFERENCE_SUCCESS);
const updateModifierOverrideWarningPreferenceFail = createAction(
  BootstrapActionTypes.UPDATE_MODIFIER_OVERRIDE_WARNING_PREFERENCE_FAIL,
  props<{ error: Error }>()
);

// IDP Discovery
const idpDiscovery = createAction(BootstrapActionTypes.IDP_DISCOVERY, props<{ username: string }>());

const idpDiscoverySuccess = createAction(
  BootstrapActionTypes.IDP_DISCOVERY_SUCCESS,
  props<{ idpDiscovery: IdpDiscovery }>()
);

const idpDiscoveryFail = createAction(BootstrapActionTypes.IDP_DISCOVERY_FAIL, props<{ error: Error }>());

// SAML Login
const idpSamlLogin = createAction(BootstrapActionTypes.IDP_SAML_LOGIN);

const idpSamlLoginSuccess = createAction(
  BootstrapActionTypes.IDP_SAML_LOGIN_SUCCESS,
  props<{ idpDiscovery: IdpDiscovery }>()
);

const idpSamlLoginFail = createAction(BootstrapActionTypes.IDP_SAML_LOGIN_FAIL, props<{ error: Error }>());

export const BootstrapActions = {
  loadBootstrapConfig,
  loadBootstrapConfigSuccess,
  loadBootstrapConfigFail,
  idpDiscovery,
  idpDiscoverySuccess,
  idpDiscoveryFail,
  idpSamlLogin,
  idpSamlLoginSuccess,
  idpSamlLoginFail,
  loadUserPreferences,
  loadUserPreferencesFail,
  setCompanyType,
  setMyScopesColumnsFail,
  setMyScopesColumnsSuccess,
  updateScopeColumnPreferences,
  updateScopeOverviewColumnPreferences,
  updateScopeColumnPreferencesSuccess,
  updateScopeOverviewColumnPreferencesSuccess,
  updateScopeColumnPreferencesFail,
  setSowOverviewColumnsFail,
  setSowOverviewColumnsSuccess,
  setScopeOverviewColumnsFail,
  setScopeOverviewColumnsSuccess,
  loadTaskPreferences,
  loadTaskPreferencesSuccess,
  loadTaskPreferencesFail,
  updateTaskColumnPreferences,
  updateTaskColumnPreferencesSuccess,
  updateTaskColumnPreferencesFail,
  loadModifierPreferences,
  loadModifierPreferencesSuccess,
  loadModifierPreferencesFail,
  updateModifierColumnPreferences,
  updateModifierColumnPreferencesSuccess,
  updateModifierColumnPreferencesFail,
  getModifierOverrideWarningPreference,
  getModifierOverrideWarningPreferenceSuccess,
  getModifierOverrideWarningPreferenceFail,
  updateModifierOverrideWarningPreference,
  updateModifierOverrideWarningPreferenceSuccess,
  updateModifierOverrideWarningPreferenceFail
};

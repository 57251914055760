import { TableColumnKey } from '@app/shared/components/ui-components/scope-ui-table/table-column-key.enum'

export const libraryFoldersColumnsBaseConfig = {
  [TableColumnKey.NAME]: {
    key: TableColumnKey.NAME,
    name: 'NAME',
    field: 'displayName',
    selected: true,
    isVisibleInList: () => false,
    valueIconFunction: (entity) => (entity.isSharedByCompanies ? 'folder_shared' : 'folder'),
  },
  [TableColumnKey.LIBRARY_ITEM_TYPE]: {
    key: TableColumnKey.LIBRARY_ITEM_TYPE,
    name: 'Item Type',
    field: 'libraryItemType',
    selected: true,
    noSort: true
  },
  [TableColumnKey.CREATED_BY]: {
    key: TableColumnKey.CREATED_BY,
    name: 'Created By',
    field: 'createdBy.company.name',
    selected: true,
  },
  [TableColumnKey.DATE_CREATED]: {
    key: TableColumnKey.DATE_CREATED,
    name: 'Date created',
    field: 'createdTs',
    selected: true,
  },
  [TableColumnKey.LAST_EDITED]: {
    key: TableColumnKey.LAST_EDITED,
    name: 'Last edited',
    field: 'updatedTs',
    selected: true,
  },
}

<div class="component-row items-center">
  <div class="row-content flex items-center" (click)="selectComponent(component.id)">
    <div class="column level-{{level}} {{ componentMainColumnClass }} flex items-center">
      <mat-icon class="material-symbols-rounded arrow orange" [ngClass]="{ active: scopeTabService.componentSelectedStates[component.id] }">expand_more</mat-icon>
      <div class="flex-col pl-1">
        <commentable style='--line-clamp: 1;' [c-root-entity]="deliverable" [c-entity]="component" [c-key]="'name'" [c-type]="'component'">
          {{component.name && component.name.length > 0 ? component.name : component.source.name}}
        </commentable>
        <span class="source-name">{{ component.source.name }}</span>
      </div>
    </div>
    <div class="column one--column" [hidden]='isTimeline || !scopeVersion.isScopeByScenario()'>
      <commentable [c-root-entity]="deliverable" [c-entity]="component" [c-key]="'quantityModifier'" [c-type]="'component'">
        {{ component.quantityModifierFormula ? component.quantityModifier : '-' }}
      </commentable>
    </div>
    <div class="column one--column" [hidden]="!userColumns.QUANTITY?.selected">
      <commentable [c-root-entity]="deliverable" [c-entity]="component" [c-key]="'quantity'" [c-type]="'component'"
                   [c-action]="overrideQuantity(deliverable, component)" [c-action-text]="'Change Quantity'"
                   [c-action-privilege]="scopeVersion.identity.isTemplate ? 'SCOPE_TEMPLATE__EDIT':'SCOPE_REVIEW__EDIT_COMPONENT_QUANTITY'">
        {{ component.quantity }}
      </commentable>
    </div>
    <div class="column one--column" [hidden]="!(userColumns.START_DATE?.selected && userColumns.START_DATE.isVisibleInList())">
      <span>{{component.startDate || deliverable.startDate | dateFormat: 'shortDate'}}</span>
    </div>
    <div class="column one--column" [hidden]="!(userColumns.END_DATE?.selected && userColumns.END_DATE.isVisibleInList())">
      <span>{{component.endDate || deliverable.endDate | dateFormat: 'shortDate'}}</span>
    </div>
    <div class='column one--column' [hidden]='!userColumns.MARK_UP?.selected'
         [has-privilege]="'SCOPE_MARKUP__VIEW' && 'SCOPE_MARGIN__VIEW'">
      <commentable [c-root-entity]='deliverable' [c-entity]='component'>
        <span class='tag-label' *ngIf='component.hasCostPlus() && currentUser.company.hasApplicationSetting("COST_PLUS")'>Cost Plus</span>
        {{ component.costPlusValue && currentUser.company.hasApplicationSetting('COST_PLUS') ? component.costPlusValue + '%' : component.getMarkup() ? component.getMarkup() + '%' : '--' }}
      </commentable>
    </div>
    <div class="column one--column" [hidden]='isTimeline'>
      <commentable [c-root-entity]="deliverable" [c-entity]="component" [c-key]="'complexity'" [c-type]="'component'">
        {{ component.complexity }}
      </commentable>
    </div>
    <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_HOURS?.selected">
      <commentable [c-root-entity]="deliverable" [c-entity]="component" [c-key]="'scopeMarkHours'" [c-type]="'component'">
        {{ component.scopeMarkTotalHours | formatHours }}
      </commentable>
    </div>
    <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_PRICE?.selected">
      <commentable [c-root-entity]="deliverable" [c-entity]="component" [c-key]="'scopeMarkSellingPrice'" [c-type]="'component'">
        {{ component.scopeMarkTotalSellingPrice ? (component.scopeMarkTotalSellingPrice.amount | currency: component.scopeMarkTotalSellingPrice.currency) : '--' }}
      </commentable>
    </div>
    <div class="column {{ isTimeline ? 'three--columns': 'one--column' }}" [hidden]="!isTimeline && !userColumns.AGENCY_HOURS?.selected">
      <commentable [c-root-entity]="deliverable" [c-entity]="component" [c-key]="'rateCardHours'" [c-type]="'component'">
        {{ component.rateCardTotalHours | formatHours }}
      </commentable>
    </div>
    <div class="column one--column" [hidden]='isTimeline || !scopeVersion.isScopeByScenario()'>
      <commentable [c-root-entity]="deliverable" [c-entity]="component" [c-key]="'priceModifier'" [c-type]="'component'">
        {{ component.priceModifierFormula ? component.priceModifier : '-' }}
      </commentable>
    </div>
    <div class="column {{ isTimeline ? 'three--columns': 'one--column' }}" [hidden]="!isTimeline && !userColumns.AGENCY_PRICE?.selected">
      <commentable [c-root-entity]="deliverable" [c-entity]="component" [c-key]="'agencySellingPrice'" [c-type]="'component'">
        {{ component.getTotalSellingPrice() ? (component.getTotalSellingPrice().amount | currency : component.getTotalSellingPrice().currency) : '--' }}
      </commentable>
    </div>
    <div class="column one--column">
      <div class="flex flex-row justify-end">
        <button class="toggle-button float-right {{isTimeline ? 'mr-1' : 'mr-2'}}" *ngIf="showToggleMenu()" (click)="$event.stopPropagation()" mat-mini-fab [matMenuTriggerFor]="componentMenu">
          <mat-icon class="material-symbols-rounded">more_horiz</mat-icon>
        </button>
      </div>
    </div>
    <mat-menu #componentMenu="matMenu" backdropClass="customize">
      <button mat-menu-item (click)="addComponentFee(component)">
        <mat-icon class="material-symbols-rounded orange">attach_money</mat-icon>
        <span>Add fee</span>
      </button>
    </mat-menu>
  </div>
  <div class="flex flex-col w-full description-container" *ngIf='!isTimeline'>
    <description-editor
      class="level-{{level}}"
      [(visible)]="scopeTabService.componentDescriptionStates[component.id]"
      [model]="component"
      [editable]='scopeApprovalFlowService.isScopeEditable(scopeVersion)'
      [(disableDragging)]="scopeTabService.disableDragging"
      (onSaveDescription)="saveComponentDescription(component, $event)"
      (onSaveNote)="saveComponentInternalNote(component, $event)">
    </description-editor>
  </div>
  <div
    class="department-header flex items-center row-content"
    [hidden]="!scopeTabService.componentSelectedStates[component.id]"
    *ngIf="component.departments.length > 0 && !isTimeline">
    <div class="column level-{{level+1}} {{ componentMainColumnClass }}">
      <span isEllipsisActive>Department name</span>
    </div>
    <div class="column one--column" [hidden]='!scopeVersion.isScopeByScenario()'></div>
    <div class="column one--column" [hidden]="!userColumns.QUANTITY?.selected"></div>
    <div class="column one--column" [hidden]="!(userColumns.START_DATE?.selected && userColumns.START_DATE.isVisibleInList())"></div>
    <div class="column one--column" [hidden]="!(userColumns.END_DATE?.selected && userColumns.END_DATE.isVisibleInList())"></div>
    <div class="column one--column" [hidden]='!userColumns.MARK_UP?.selected'></div>
    <div class="column one--column"></div>
    <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_HOURS?.selected">
      <span isEllipsisActive>{{ 'scope_mark' | lang }}<br />hours</span>
    </div>
    <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_PRICE?.selected">
      <span isEllipsisActive>{{ 'scope_mark' | lang }}<br />price</span>
    </div>
    <div class="column one--column" [hidden]="!userColumns.AGENCY_HOURS?.selected">
      <span isEllipsisActive>{{ 'agency|p' | lang }}<br />hours</span>
    </div>
    <div class="column one--column" [hidden]='!scopeVersion.isScopeByScenario()'></div>
    <div class="column one--column" [hidden]="!userColumns.AGENCY_PRICE?.selected" *ngIf="!scopeVersion.identity.costPlus">
      <span isEllipsisActive>{{ 'agency|p' | lang }}<br />price</span>
    </div>
    <div class="column one--column" [hidden]="!userColumns.AGENCY_PRICE?.selected" *ngIf="scopeVersion.identity.costPlus">
      <span isEllipsisActive>Cost plus<br />price</span>
    </div>
    <div class="column one--column"></div>
  </div>
  <div
    class="department-row flex items-center flex-col row-content"
    [hidden]="!scopeTabService.componentSelectedStates[component.id]"
    *ngFor="let department of component.departments; trackBy: trackById">
    <div class="row-content flex items-center" (click)="selectDepartment(department.id)">
      <div class="column level-{{level+1}} {{ componentMainColumnClass }} flex items-center">
        <mat-icon class="material-symbols-rounded arrow orange" [ngClass]="{ active: scopeTabService.departmentSelectedStates[department.id] }"
        >expand_more</mat-icon
        >
        <commentable [c-root-entity]="component" [c-entity]="department" [c-key]="'name'" [c-type]="'department'" class="pl-1">
          {{ department.name }}
        </commentable>
      </div>
      <div class="column one--column" [hidden]='isTimeline || !scopeVersion.isScopeByScenario()'></div>
      <div class="column one--column" [hidden]="!userColumns.QUANTITY?.selected"></div>
      <div class="column one--column" [hidden]="!(userColumns.START_DATE?.selected && userColumns.START_DATE.isVisibleInList())"></div>
      <div class="column one--column" [hidden]="!(userColumns.END_DATE?.selected && userColumns.END_DATE.isVisibleInList())"></div>
      <div class="column one--column" [hidden]='!userColumns.MARK_UP?.selected'></div>
      <div class="column one--column" [hidden]='isTimeline'></div>
      <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_HOURS?.selected">
        <commentable [c-root-entity]="component" [c-entity]="department" [c-key]="'scopeMarkHours'" [c-type]="'department'">
          {{ department.totalScopeMarkHours() | formatHours }}
        </commentable>
      </div>
      <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_PRICE?.selected">
        <commentable [c-root-entity]="component" [c-entity]="department" [c-key]="'scopeMarkSellingPrice'" [c-type]="'department'">
          {{ department.totalScopeMarkSellingPrice() ? (department.totalScopeMarkSellingPrice().amount | currency : department.currencyUnit) : '--' }}
        </commentable>
      </div>
      <div class="column {{ isTimeline ? 'three--columns': 'one--column' }}" [hidden]="!isTimeline && !userColumns.AGENCY_HOURS?.selected">
        <commentable [c-root-entity]="component" [c-entity]="department" [c-key]="'rateCardHours'" [c-type]="'department'">
          {{ department.getTotalRateCardHours() | formatHours }}
        </commentable>
      </div>
      <div class="column one--column" [hidden]='isTimeline || !scopeVersion.isScopeByScenario()'></div>
      <div class="column {{ isTimeline ? 'three--columns': 'one--column' }}" [hidden]="!isTimeline && !userColumns.AGENCY_PRICE?.selected">
        <commentable [c-root-entity]="component" [c-entity]="department" [c-key]="'agencySellingPrice'" [c-type]="'department'">
          {{ department.getTotalSellingPrice({ markup: component.costPlusValue }) ? (department.getTotalSellingPrice({ markup: component.costPlusValue }).amount | currency : department.getTotalSellingPrice({ markup: component.costPlusValue }).currency) : '--' }}
        </commentable>
      </div>
    </div>
    <div class="department-header flex items-center row-content" [hidden]="!scopeTabService.departmentSelectedStates[department.id] || isTimeline">
      <div class="column level-{{level+2}} {{ roleMainColumnClass }}">
        <span isEllipsisActive>Role name</span>
      </div>
      <div class="column one--column" [hidden]='!scopeVersion.isScopeByScenario()'></div>
      <div class="column one--column">
        <span isEllipsisActive>Weighted {{'scope_mark' | lang }}<br>rate</span>
      </div>
      <div class="column one--column" *ngIf="!scopeVersion.identity.costPlus">
        <span isEllipsisActive>{{'agency|p' | lang }}<br>rate</span>
      </div>
      <div class="column one--column" *ngIf="scopeVersion.identity.costPlus">
        <span isEllipsisActive>{{'agency|p' | lang }}<br>cost</span>
      </div>
      <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_HOURS?.selected">
        <span isEllipsisActive>{{ 'scope_mark' | lang }}<br />hours</span>
      </div>
      <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_PRICE?.selected">
        <span isEllipsisActive>{{ 'scope_mark' | lang }}<br />price</span>
      </div>
      <div class="column one--column" [hidden]="!userColumns.AGENCY_HOURS?.selected">
        <span isEllipsisActive>{{ 'agency|p' | lang }}<br />hours</span>
      </div>
      <div class="column one--column" [hidden]='!scopeVersion.isScopeByScenario()'></div>
      <div class="column one--column" [hidden]="!userColumns.AGENCY_PRICE?.selected" *ngIf="!scopeVersion.identity.costPlus">
        <span isEllipsisActive>{{ 'agency|p' | lang }}<br />price</span>
      </div>
      <div class="column one--column" [hidden]="!userColumns.AGENCY_PRICE?.selected" *ngIf="scopeVersion.identity.costPlus">
        <span isEllipsisActive>Cost plus<br />price</span>
      </div>
      <div class="column one--column"></div>
    </div>
    <div
      class="role-row flex items-center flex-col row-content"
      [hidden]="!scopeTabService.departmentSelectedStates[department.id]"
      *ngFor="let role of department.roles; trackBy: trackById">
      <div class="row-content flex items-center">
        <div class="column level-{{level+2}} {{ roleMainColumnClass }} flex items-center">
          <commentable [c-root-entity]="component" [c-entity]="role" [c-key]="'name'" [c-type]="'role'">
            {{ role.name }}
          </commentable>
        </div>
        <div class="column one--column" [hidden]='isTimeline || !scopeVersion.isScopeByScenario()'></div>
        <div class="column one--column" [hidden]="isTimeline">
          <commentable [c-root-entity]="component" [c-entity]="role" [c-key]="'scopeMarkRate'" [c-type]="'role'">
            {{ scopeTabService.roleColumns?.[TableColumnKey.WEIGHTED_RATE]?.isVisible() ? (role.getScopeMarkRate()?.amount | currency : scopeVersion.getRateCardCurrency()) : '--' }}
          </commentable>
        </div>
        <div class="column one--column" [hidden]="isTimeline" *ngIf="!scopeVersion.identity.costPlus">
          <commentable [c-root-entity]="component" [c-entity]="role" [c-key]="'rateCardRate'" [c-type]="'role'">
            {{ scopeTabService.roleColumns?.[TableColumnKey.AGENCY_RATE]?.isVisible() ? (role.rateCardRate?.amount | currency : scopeVersion.getRateCardCurrency()) : '--' }}
          </commentable>
        </div>
        <div class="column one--column" [hidden]="isTimeline" *ngIf="scopeVersion.identity.costPlus">
          <commentable [c-root-entity]="component" [c-entity]="role" [c-key]="'rateCardRate'" [c-type]="'role'">
            {{ userColumns.AGENCY_RATE?.isVisible() ? (role.rateCardCost?.amount | currency : scopeVersion.getRateCardCurrency()) : '--' }}
          </commentable>
        </div>
        <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_HOURS?.selected">
          <commentable [c-root-entity]="component" [c-entity]="role" [c-key]="'scopeMarkHours'" [c-type]="'role'">
            {{ role.getScopeMarkHours() | formatHours }}
          </commentable>
        </div>
        <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_PRICE?.selected">
          <commentable [c-root-entity]="component" [c-entity]="role" [c-key]="'scopeMarkSellingPrice'" [c-type]="'role'">
            {{ role.getScopeMarkSellingPrice()?.amount | currency : role.getScopeMarkSellingPrice()?.currency }}
          </commentable>
        </div>
        <div class="column {{ isTimeline ? 'three--columns': 'one--column' }}" [hidden]="!isTimeline && !userColumns.AGENCY_HOURS?.selected">
          <commentable [c-root-entity]="component" [c-entity]="role" [c-key]="'rateCardHours'" [c-type]="'role'"
                       [c-use-icon]="role.hasAttributeAlterationComment()" [c-action]="overrideRateCardRoleHours(deliverable, component, role)" [c-action-text]="'Change Hours'" [c-action-privilege]="'SCOPE_REVIEW__EDIT_ROLE_HOURS'">
            {{ role.getRateCardHours() | formatHours }}
          </commentable>
        </div>
        <div class="column one--column" [hidden]='isTimeline || !scopeVersion.isScopeByScenario()'></div>
        <div class="column {{ isTimeline ? 'three--columns': 'one--column' }}" [hidden]="!isTimeline && !userColumns.AGENCY_PRICE?.selected">
          <commentable [c-root-entity]="component" [c-entity]="role" [c-key]="'agencySellingPrice'" [c-type]="'role'">
            {{ role.getUserSellingPrice({markup: component.costPlusValue})?.amount | currency : role.getUserSellingPrice({markup: component.costPlusValue})?.currency }}
          </commentable>
        </div>
        <div class="column one--column" [hidden]="isTimeline"></div>
      </div>
    </div>
  </div>
  <scope-ui-table
    *ngIf="component.thirdPartyCosts?.length" [hidden]="!scopeTabService.componentSelectedStates[component.id] || isTimeline"
    [loggedInUser]="currentUser"
    [tableClass]="'tpc-table nested'"
    [headerExpandable]="false"
    [unmappedData]="component.thirdPartyCosts | orderBy: 'id'"
    [displayedColumns]="scopeTabService.thirdPartyCostColumns"
    [noSort]="true"
    [noPagination]="true"
    [noPreferences]="true"
    [showToggleMenu]="false"
    [expandable]="false"
    [showFooterRow]="false"
    [parentEntity]="component"
    [level]="level+1"
    [(disableDragging)]="scopeTabService.disableDragging">
  </scope-ui-table>
  <scope-ui-table
    *ngIf="component.feeItems?.length" [hidden]="!scopeTabService.componentSelectedStates[component.id] || isTimeline"
    [has-setting]="'COMPONENT__ENABLE_FEES'"
    [has-privilege]="'SCOPE__VIEW_FEES'"
    [loggedInUser]="currentUser"
    [tableClass]="'fees-table nested'"
    [headerExpandable]="true"
    [unmappedData]="component.feeItems | orderBy: 'id'"
    [displayedColumns]="componentFeeColumns"
    [noSort]="true"
    [noPagination]="true"
    [noPreferences]="true"
    [expandable]="false"
    [showToggleMenu]="feeMenuOptions.length > 0"
    [menuOptions]="feeMenuOptions"
    (updateFeeItem)="updateFeeItem($event, component.deliverable, component)"
    [showFooterRow]="true"
    [parentEntity]="component"
    [level]="level+1"
    [(tableExpanded)]="scopeTabService.componentFeeTableStates[component.id]"
    [(disableDragging)]="scopeTabService.disableDragging">
  </scope-ui-table>
  <div class="department-header flex items-center row-content rounded-b" [hidden]="!scopeTabService.componentSelectedStates[component.id] || isTimeline">
    <div class="column level-{{level}} {{ componentMainColumnClass }}">
      <span>Component total</span>
    </div>
    <div class="column one--column" [hidden]="!scopeVersion.isScopeByScenario()"></div>
    <div class="column one--column"></div>
    <div class="column one--column"></div>
    <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_HOURS?.selected"></div>
    <div class="column one--column" [hidden]="!userColumns.SCOPEMARK_PRICE?.selected"></div>
    <div class="column one--column" [hidden]="!userColumns.AGENCY_HOURS?.selected">
      <commentable [c-root-entity]="deliverable" [c-entity]="component" [c-key]="'totalComponentUnitRateCardHours'" [c-type]="'component'">
        <span [has-setting]="'SCOPE__AGENCY_HOURS'" [has-setting-disabled-mask]="'--'"> {{ component.getTotalRateCardHours() | formatHours }}</span>
      </commentable>
    </div>
    <div class="column one--column" [hidden]="!scopeVersion.isScopeByScenario()"></div>
    <div class="column one--column" [hidden]="!userColumns.AGENCY_PRICE?.selected">
      <commentable [c-root-entity]="deliverable" [c-entity]="component" [c-key]="'totalComponentUnitAgencySellingPrice'" [c-type]="'component'">
          <span [has-privilege]="'SCOPE__VIEW_RATES'" [has-privilege-mask]="'--'">{{
            component.getTotalSellingPrice() ? (component.getTotalSellingPrice().amount | currency : component.getTotalSellingPrice().currency) : '--'
            }}</span>
      </commentable>
    </div>
    <div class="column one--column"></div>
  </div>
</div>

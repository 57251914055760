import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, debounceTime, exhaustMap, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { ScopeOverviewService } from '../../service/scope-overview.service';
import { ScopeOverviewActionTypes, ScopeOverviewActions } from '../actions/scope-overview.action';
import { PostComment } from '../../model/activity.model'
import { ApprovalFlowService } from '../../service/approval-flow.service';
import { ApprovalsService } from '../../service/approvals.service';
import { ScopeTraffickingService } from '../../service/scope-trafficking.service';
import { MyScopesService } from '@app/features/scoping/service/scoping.service';
import { Router } from '@angular/router';
import {
  SnackbarEventType,
  SNACKBAR_LENGTH_LONG,
  SNACKBAR_LENGTH_SHORT,
  SnackbarService
} from '@shared/utils/snackbar.service'
import { LanguageService } from '@core/service/language.service'
import { ScopeTabService } from '@app/features/scope-overview/service/scope-tab.service'
import { Store } from '@ngrx/store';
import { ScopeOverviewSelectors } from '../selectors/scope-overview.selector';
import { MatDialog } from '@angular/material/dialog';
import { ScenarioService } from '@app/features/company-management/service/scenario.service';

@Injectable()
export class ScopeOverviewEffects {
  constructor(
    private store$: Store,
    private actions$: Actions,
    private scopeOverviewService: ScopeOverviewService,
    private scopingService: MyScopesService,
    private approvalsService: ApprovalsService,
    private traffickingService: ScopeTraffickingService,
    private snackbarService: SnackbarService,
    private router: Router,
    private lang: LanguageService,
    private scopeTabService: ScopeTabService,
    private approvalFlowService: ApprovalFlowService,
    private dialog: MatDialog,
    private scenarioService: ScenarioService
  ) {}

  updateCurrentScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.updateCurrentScope),
      switchMap((action) => {
        return this.scopeOverviewService.getScope(action.scopeId).pipe(
          map((currentScope) => ScopeOverviewActions.updateCurrentScopeSuccess({ currentScope })),
          catchError((error: Error) => of(ScopeOverviewActions.updateCurrentScopeFail({ error })))
        )
      })
    )
  });

  getActivities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.GET_ACTIVITY_COMMENTS),
      map((action: { scopeId: string }) => action.scopeId),
      switchMap((scopeId) => {
        return this.scopeOverviewService.getActivityComments(scopeId).pipe(
          map((activities) => ScopeOverviewActions.getActivitiesSuccess({ activities })),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(`An error occurred during loading activities`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getActivitiesFail({ error }));
          })
        );
      })
    );
  });

  submitComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.SUBMIT_COMMENT),
      map((action: { scopeId: string; comment: PostComment }) => action),
      switchMap((action) => {
        return this.scopeOverviewService.submitComment(action.scopeId, action.comment).pipe(
          map((activities) => ScopeOverviewActions.submitCommentSuccess({ updatedComments: activities })),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(`An error occurred during submitting the comment`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.submitCommentFail({ error }));
          })
        );
      })
    );
  });

  sendNotificationReminder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.SEND_NOTIFICATION_REMINDER),
      map((action: { scopeId: number; userId: number; comment: string }) => action),
      switchMap((action) => {
        console.log('action:', action);
        return this.approvalsService.sendApprovalNotification(action.scopeId, action.comment, action.userId).pipe(
          map(() => {
            return { type: '[Dummy] Action' };
          }),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(`An error occurred during sending approval notification`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.submitCommentFail({ error }));
          })
        );
      })
    );
  });

  exportToExternalSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.exportTrafficScope),
      tap(() => {
        this.traffickingService.abortedErrorMessage = null;
        this.traffickingService.exportStatus._confirmationPending = false;
      }),
      switchMap((action) =>
        this.scopeOverviewService.trafficScope(action.identityId).pipe(
          tap(() => {
            this.traffickingService.exportStatus.setPendingStart();
          }),
          map((success) => {
            console.log('Initiated Trafficking');
            return ScopeOverviewActions.exportTrafficScopeSuccess();
          }),
          catchError((error) => {
            console.error(error);
            this.snackbarService.showSnackbar(`An error occurred during trafficking the Scope`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            this.traffickingService.getServerErrorMessage(error);
            this.traffickingService.exportStatus.setExportAborted();
            return of(ScopeOverviewActions.exportTrafficScopeFail({ error }));
          })
        )
      )
    )
  );

  exportToWorkatoSystem$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ScopeOverviewActions.exportTrafficScopeWorkato),
    tap(() => {
      this.traffickingService.abortedErrorMessage = null;
      this.traffickingService.exportStatus._confirmationPending = false;
    }),
    switchMap((action) =>
      this.scopeOverviewService.trafficWorkatoScope(action.target, action.identityId).pipe(
        tap(() => {
          this.traffickingService.exportStatus.setPendingStart();
        }),
        map((success) => {
          console.log('Initiated Workato Trafficking');
          return ScopeOverviewActions.exportTrafficScopeSuccess();
        }),
        catchError((error) => {
          console.error(error);
          this.snackbarService.showSnackbar(`An error occurred during trafficking the Scope`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
          this.traffickingService.getServerErrorMessage(error);
          this.traffickingService.exportStatus.setExportAborted();
          return of(ScopeOverviewActions.exportTrafficScopeFail({ error }));
        })
      )
    )
  )
);

  trafficThroughWorkato$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.trafficScopeThroughWorkato),
      tap(() => {
        this.traffickingService.abortedErrorMessage = null;
        this.traffickingService.exportStatus._confirmationPending = false;
      }),
      switchMap((action) =>
        this.scopeOverviewService.trafficScopeThroughWorkato(action.identityId).pipe(
          map((success) => {
            console.log('Initiated trafficking through Workato');
            return ScopeOverviewActions.exportTrafficScopeSuccess();
          }),
          catchError((error) => {
            console.error(error);
            this.snackbarService.showSnackbar(`An error occurred during trafficking the Scope`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR);
            this.traffickingService.getServerErrorMessage(error);
            this.traffickingService.exportStatus.setExportAborted();
            return of(ScopeOverviewActions.exportTrafficScopeFail({ error }));
          }),
        ),
      ),
    ),
  );

  getCurrentScope$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getCurrentScope),
      switchMap((action) => {
        this.scopeTabService.resetDeliverablesState()
        return this.scopeOverviewService.getScope(action.scopeId).pipe(
          map((currentScope) => ScopeOverviewActions.getCurrentScopeSuccess({ currentScope })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading of current Scope`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getCurrentScopeFail({ error }))
          })
        )
      })
    )
  );

  getScopeRetainedHourStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getScopeRetainedStats),
      switchMap((action) => {
        return this.scopeOverviewService.getScopeRetainedHoursStats(action.sowId, action.scopeId).pipe(
          map((scopeRetainedHourStats) =>
            ScopeOverviewActions.getScopeRetainedStatsSuccess({ scopeRetainedHourStats })
          ),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading of retained hours statistics`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getScopeRetainedStatsFail({ error }))
          })
        );
      })
    )
  );

  updateScopeRetainedHoursStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.updateScopeRetainedHoursStats),
      switchMap((action) => {
        return this.scopeOverviewService.updateScopeRetainedHoursStats(action.sowId, action.scopeId, action.useRetainedHours).pipe(
          map((scopeRetainedHoursStats) =>
            ScopeOverviewActions.updateScopeRetainedHoursStatsSuccess({ scopeRetainedHoursStats }),
          ),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during updating of retained hours statistics`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR);
            return of(ScopeOverviewActions.updateScopeRetainedHoursStatsFail({ error }));
          }),
        );
      }),
    ),
  );

  getSowRetainedHourStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getSowRetainedStats),
      switchMap((action) => {
        return this.scopeOverviewService.getSowRetainedHoursStats(action.folderId).pipe(
          map((sowRetainedHourStats) => ScopeOverviewActions.getSowRetainedStatsSuccess({ sowRetainedHourStats })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading of retained hours statistics`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getSowRetainedStatsFail({ error }))
          })
        );
      })
    )
  );

  getThirdPartyBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getThirdPartyBalance),
      switchMap((action) => {
        return this.scopeOverviewService.getScopeThirdPartyCostBalanceById(action.scopeId).pipe(
          map((thirdPartyCostBalance) => ScopeOverviewActions.getThirdPartyBalanceSuccess({ thirdPartyCostBalance })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading TPC Balance`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getThirdPartyBalanceFail({ error }))
          })
        );
      })
    )
  );

  changeScopeVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.changeScopeVersion),
      switchMap((action) => {
        this.scopeTabService.resetDeliverablesState()
        return this.scopeOverviewService.getScopeVersionById(action.scopeId, action.versionId).pipe(
          map((currentScope) => {
            this.approvalFlowService.updateApprovalStateChecks(currentScope)
            return ScopeOverviewActions.changeScopeVersionSuccess({ currentScope })
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during changing the Scope version`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.changeScopeVersionFail({ error }))
          })
        );
      })
    )
  );

  addScopeSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.addScopeSection),
      switchMap((action) => {
        return this.scopeOverviewService.addScopeSection(action.scopeId, action.section).pipe(
          map((scopeSection) => ScopeOverviewActions.addScopeSectionSuccess({ scopeSection })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during saving the Scope section`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.addScopeSectionFail({ error }))
          })
        );
      })
    )
  );

  archiveScopeSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.archiveScope),
      switchMap((action) => {
        return this.scopingService.archiveScope(action.scopeId).pipe(
          map(() => ScopeOverviewActions.archiveScopeSuccess()),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during archiving the Scope`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.archiveScopeFail({ error }))
          })
        );
      })
    )
  );

  unarchiveScopeSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.unarchiveScope),
      switchMap((action) => {
        return this.scopingService.unarchiveScope(action.scopeId).pipe(
          map(() => ScopeOverviewActions.unarchiveScopeSuccess()),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during unarchiving the Scope`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.unarchiveScopeFail({ error }))
          })
        );
      })
    )
  );

  getScopeDeliverable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getScopeDeliverable),
      concatMap((action) => {
        return this.scopeOverviewService.getScopeDeliverable(action.scopeId, action.deliverableId).pipe(
          map((deliverable) => ScopeOverviewActions.getScopeDeliverableSuccess({ deliverable })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading the deliverable`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getScopeDeliverableFail({ error }))
          })
        );
      })
    )
  );

  getAllTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getAllTasks),
      concatMap((action) => {
        return this.scopeOverviewService.getAllTasks(action.scopeId, action.scopeVersion).pipe(
          map((tasks) => ScopeOverviewActions.getAllTasksSuccess({ tasks })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading the tasks`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getAllTasksFail({ error }))
          })
        );
      })
    )
  );

  deleteDeliverable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.deleteDeliverable),
      switchMap((action) => {
        return this.scopeOverviewService.deleteScopeDeliverable(action.scopeId, action.deliverableId).pipe(
          map((deliverable) => ScopeOverviewActions.deleteDeliverableSuccess({ deliverable, deliverableId: action.deliverableId })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during deletion of deliverable`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.deleteDeliverableFail({ error }))
          })
        );
      })
    )
  );

  getAvailableDeliverableTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getAvailableScopeDeliverableTypes),
      switchMap((action) => {
        return this.scopeOverviewService.getAvailableDeliverableTypes(action.mergeLibraryDeliverableEntryStrategy).pipe(
          map((availableDeliverableTypes) =>
            ScopeOverviewActions.getAvailableScopeDeliverableTypesSuccess({ availableDeliverableTypes })
          ),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading deliverable types`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getAvailableScopeDeliverableTypesFail({ error }))
          })
        );
      })
    )
  );

  addScopeDeliverable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.addScopeDeliverable),
      switchMap((action) => {
        return this.scopeOverviewService.addScopeDeliverable(action.scopeId, action.deliverable).pipe(
          map((deliverable) => {
            if (!action.quickAdd) {
              this.router.navigateByUrl(`/scopes/scope-overview/${action.scopeId}/deliverable/${deliverable.id}?editLibDelEntryId=${action.editLibDelEntryId}`)
            }
            return ScopeOverviewActions.addScopeDeliverableSuccess({ deliverable })
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during saving of the deliverable`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.addScopeDeliverableFail({ error }))
          })
        );
      })
    )
  );

  duplicateScopeDeliverable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.duplicateScopeDeliverable),
      switchMap((action) => {
        return this.scopeOverviewService.duplicateScopeDeliverable(action.scopeId, action.deliverableId).pipe(
          map((deliverable) => ScopeOverviewActions.duplicateScopeDeliverableSuccess({ deliverable })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during duplication of deliverable`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.duplicateScopeDeliverableFail({ error }))
          })
        );
      })
    )
  );

  moveComponentToDeliverable = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.moveComponentToDeliverable),
      switchMap((action) => {
        return this.scopeOverviewService.moveComponentToDeliverable(action.scopeId, action.componentId, action.deliverableId, action.name, action.order).pipe(
          map((deliverable) => ScopeOverviewActions.moveComponentToDeliverableSuccess({ deliverable })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during moving of component`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.moveComponentToDeliverableFail({ error }))
          })
        );
      })
    )
  );

  createLibraryDeliverableTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.createLibraryDeliverableTemplate),
      withLatestFrom(this.store$.select(ScopeOverviewSelectors.selectDialogId)),
      switchMap(([action, dialogId]) => {
        return this.scopeOverviewService.createLibraryDeliverableTemplate(action.scopeId, action.deliverable).pipe(
          switchMap((template) => {
            this.dialog.getDialogById(dialogId)?.close();
            return [
              ScopeOverviewActions.setDialogId({ id: undefined }),
              ScopeOverviewActions.createLibraryDeliverableTemplateSuccess({ template })
            ]
          }),
          catchError((error) => {
            if (error.status === 409) {
              let modalForm = this.dialog.getDialogById(dialogId)?.componentInstance.modalForm
              modalForm.get('name').setErrors({ conflict: true })
              modalForm.markAllAsTouched()
              return of(ScopeOverviewActions.createLibraryDeliverableTemplateFail({ error }));
            }

            console.log(JSON.stringify(error));
            this.snackbarService.showSnackbar(`An error occurred during saving of the library deliverable template`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.createLibraryDeliverableTemplateFail({ error }));
          })
        );
      })
    )
  );

  createLibraryDeliverableEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.createLibraryDeliverableEntry),
      switchMap((action) => {
        return this.scopeOverviewService.createLibraryDeliverableEntry(action.templateId, action.entry).pipe(
          map((updateEntry) => ScopeOverviewActions.createLibraryDeliverableEntrySuccess({ updateEntry })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during saving of the library deliverable entry`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.createLibraryDeliverableEntryFail({ error }))
          })
        );
      })
    )
  );

  getOutputTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getOutputTemplate),
      switchMap((action) => {
        return this.scopeOverviewService.getScopeTemplate(action.scopeId).pipe(
          map((template) => ScopeOverviewActions.getOutputTemplateSuccess({ template })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading output template`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getOutputTemplateFail({ error }))
          })
        );
      })
    )
  );

  updateCompanyCustomOutputTemplateFromScope$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.updateTemplate),
      switchMap((action) => {
        return this.scopeOverviewService.updateCompanyCustomOutputTemplateFromScope(action.template).pipe(
          map((template) => ScopeOverviewActions.updateTemplateSuccess({ template })),
          catchError((error) => of(ScopeOverviewActions.updateTemplateFail({ error })))
        );
      })
    )
  );

  previewScopeDocx$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.previewScopeDocx),
      switchMap((action) => {
        return this.scopeOverviewService.previewScopeDocx(action.scopeId).pipe(
          map((preview) => ScopeOverviewActions.previewScopeDocxSuccess({ preview })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during generation of DOCX preview`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.previewScopeDocxFail({ error }))
          })
        );
      })
    );
  });

  getSecondParty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getSecondParty),
      switchMap((action) => {
        return this.scopeOverviewService.getSecondPartyByIdForScope(action.secondPartyId).pipe(
          map((secondParty) => ScopeOverviewActions.getSecondPartySuccess({ secondParty })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading Client details`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getSecondPartyFail({ error }))
          })
        );
      })
    )
  );

  getOfficeLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getOfficeLocation),
      switchMap((action) => {
        return this.scopeOverviewService.getOfficeLocation(action.officeLocationId).pipe(
          map((officeLocation) => ScopeOverviewActions.getOfficeLocationSuccess({ officeLocation })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading locations`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getOfficeLocationFail({ error }))
          })
        );
      })
    )
  );

  getSowRetainedTeamStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getSowRetainedTeamStats),
      switchMap((action) => {
        return this.scopeOverviewService.getRetainedTeamStats(action.folderId).pipe(
          map((sowRetainedTeamStats) => ScopeOverviewActions.getSowRetainedTeamStatsSuccess({ sowRetainedTeamStats })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading folder retained team statistics`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getSowRetainedTeamStatsFail({ error }))
          })
        );
      })
    )
  );

  getScopeCustomFieldsDefinitionOutdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getScopeCustomFieldsDefinitionOutdated),
      switchMap((action) => {
        return this.scopeOverviewService.isCompanyScopeCustomFieldsDefinitionOutdated(action.scopeId).pipe(
          map((outdated) => ScopeOverviewActions.getScopeCustomFieldsDefinitionOutdatedSuccess({ outdated })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred while checking the custom fields definition`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.WARNING)
            return of(ScopeOverviewActions.getScopeCustomFieldsDefinitionOutdatedFail({ error }))
          })
        );
      })
    )
  );

  getAllRateCardsForScopeCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getAllRateCardsForScopeCreate),
      switchMap((action) => {
        return this.scopeOverviewService.getAllRateCardsForScopeCreate().pipe(
          map((rateCards) => ScopeOverviewActions.getAllRateCardsForScopeCreateSuccess({ rateCards })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading of available ratecards`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getAllRateCardsForScopeCreateFail({ error }))
          })
        );
      })
    )
  );

  getDefaultRateCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.getDefaultRateCard),
      switchMap((action) => {
        return this.scopeOverviewService.getDefaultRatecard().pipe(
          map((defaultRateCard) => {
            return ScopeOverviewActions.getDefaultRateCardSuccess({ defaultRateCard });
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading Scope default ratecard`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getDefaultRateCardFail({ error }))
          })
        );
      })
    )
  );

  deleteScopeSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.deleteScopeSection),
      switchMap((action) => {
        return this.scopeOverviewService.deleteScopeSection(action.scopeId, action.sectionId).pipe(
          map((section) => ScopeOverviewActions.deleteScopeSectionSuccess({ section, sectionId: action.sectionId })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during deleting of section`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.deleteScopeSectionFail({ error }))
          })
        );
      })
    )
  );

  updateScope$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.updateScope),
      switchMap((action) => {
        this.scopeTabService.resetDeliverablesState()
        return this.scopeOverviewService.updateScope(action.scope).pipe(
          map((scope) => {
            return ScopeOverviewActions.updateScopeSuccess({ scope })
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred while updating the ${this.lang.get('scope')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.updateScopeFail({ error }))
          })
        );
      })
    )
  );

  refreshOutdatedCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.refreshOutdatedCustomFields),
      switchMap((action) => {
        return this.scopeOverviewService.refreshOutdatedScopeVersionCustomFields(action.scopeId, action.customFieldValueStructure).pipe(
          map((updatedScopeVersionCustomFields) => {
            return ScopeOverviewActions.refreshOutdatedCustomFieldsSuccess({ updatedScopeVersionCustomFields })
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading deliverable types`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.refreshOutdatedCustomFieldsFail({ error }))
          })
        );
      })
    )
  );

  addScopeCollaborator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.addScopeCollaborator),
      switchMap((action) => {
        return this.scopeOverviewService.addScopeCollaborator(action.scopeId, action.memberIds).pipe(
          map((collaborators) => {
            return ScopeOverviewActions.addScopeCollaboratorSuccess({ collaborators })
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during adding new collaborator`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.scopeTeamFail({ error }))
          })
        );
      })
    )
  );

  addScopeReviewer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.addScopeReviewer),
      switchMap((action) => {
        return this.scopeOverviewService.addScopeReviewer(action.scopeId, action.memberIds).pipe(
          map((reviewers) => {
            return ScopeOverviewActions.addScopeReviewerSuccess({ reviewers })
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during adding new reviewer`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.scopeTeamFail({ error }))
          })
        );
      })
    )
  );

  addScopeApprover$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.addScopeApprover),
      switchMap((action) => {
        return this.scopeOverviewService.addScopeApprover(action.scopeId, action.memberIds).pipe(
          map((approvers) => {
            return ScopeOverviewActions.addScopeApproverSuccess({ approvers })
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during adding new approval`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.scopeTeamFail({ error }))
          })
        );
      })
    )
  );

  addScopeTrafficker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.addScopeTrafficker),
      switchMap((action) => {
        return this.scopeOverviewService.addScopeTrafficker(action.scopeId, action.memberIds).pipe(
          map((traffickers) => {
            return ScopeOverviewActions.addScopeTraffickerSuccess({ traffickers })
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during adding new trafficker`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.scopeTeamFail({ error }))
          })
        );
      })
    )
  );

  updateTeamMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.updateTeamMember),
      switchMap((action) => {
        return this.scopeOverviewService.updateTeamMember(action.scopeId, action.member).pipe(
          map(() => {
            this.snackbarService.showSnackbar(`Team member ${action.member.user.getFullname()} updated`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            return ScopeOverviewActions.updateTeamMemberSuccess({ member: action.member })
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during updating team member`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.scopeTeamFail({ error }))
          })
        );
      })
    )
  );

  removeScopeCollaborator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.removeScopeCollaborator),
      switchMap((action) => {
        return this.scopeOverviewService.removeScopeCollaborator(action.scopeId, action.member.id).pipe(
          map(() => {
            this.snackbarService.showSnackbar(`Team member ${action.member.user.getFullname()} was removed`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            return ScopeOverviewActions.removeScopeCollaboratorSuccess({ member: action.member })
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during removal of team member`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.scopeTeamFail({ error }))
          })
        );
      })
    )
  );

  removeScopeReviewer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.removeScopeReviewer),
      switchMap((action) => {
        return this.scopeOverviewService.removeScopeReviewer(action.scopeId, action.member.id).pipe(
          map(() => {
            this.snackbarService.showSnackbar(`Team member ${action.member.user.getFullname()} was removed as a reviewer`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            return ScopeOverviewActions.removeScopeReviewerSuccess({ member: action.member })
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during removal of reviewer member`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.scopeTeamFail({ error }))
          })
        );
      })
    )
  );

  removeScopeApprover$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.removeScopeApprover),
      switchMap((action) => {
        return this.scopeOverviewService.removeScopeApprover(action.scopeId, action.member.id).pipe(
          map(() => {
            this.snackbarService.showSnackbar(`Team member ${action.member.user.getFullname()} was removed as an approver`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            return ScopeOverviewActions.removeScopeApproverSuccess({ member: action.member })
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during removal of approver member`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.scopeTeamFail({ error }))
          })
        );
      })
    )
  );

  removeScopeTrafficker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.removeScopeTrafficker),
      switchMap((action) => {
        return this.scopeOverviewService.removeScopeTrafficker(action.scopeId, action.member.id).pipe(
          map(() => {
            this.snackbarService.showSnackbar(`Team member ${action.member.user.getFullname()} was removed as an trafficker`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            return ScopeOverviewActions.removeScopeTraffickerSuccess({ member: action.member })
          }),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during loading deliverable types`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.scopeTeamFail({ error }))
          })
        );
      }
      )
    )
  );

  deliverableTypeSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.DELIVERABLE_TYPE_SEARCH),
      map((action: { fixedOnly?: boolean, searchQuery?: string, rateCardVersionId: number, language: string }) => action),
      switchMap((action) => {
        return this.scopeOverviewService.deliverableTypePredictSearch(action.searchQuery, action.rateCardVersionId, action.language, action.fixedOnly).pipe(
          map((extendedDeliverables) => ScopeOverviewActions.deliverableTypeSearchSuccess({ extendedDeliverables })),
          catchError((error: Error) => {
            this.snackbarService.showSnackbar(`An error occurred during deliverable type search`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.deliverableTypeSearchFail({ error }))
          })
        )
      })
    )
  })

  initiateTrade$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.INITIATE_TRADE),
      map((action: { scopeId?: number; deliverableId: number; deliverables: any[] }) => action),
      switchMap((action) => {
        return this.scopeOverviewService.initiateTrade(action.scopeId, action.deliverableId, action.deliverables).pipe(
          concatMap((tradedDeliverables) => [
            ScopeOverviewActions.initiateTradeSuccess({ tradedDeliverables }),
            ScopeOverviewActions.updateCurrentScope({ scopeId: action.scopeId })
          ]),
          catchError((error: Error) => {
            this.snackbarService.showSnackbar(`An error occurred during trade`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.initiateTradeFail({ error }))
          })
        )
      })
    )
  })

  reopenTrade$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.REOPEN_TRADE),
      map((action: { scopeId?: number; deliverableId: number }) => action),
      switchMap((action) => {
        return this.scopeOverviewService.resumeTrade(action.scopeId, action.deliverableId).pipe(
          concatMap((tradedDeliverables) => [
            ScopeOverviewActions.reopenTradeSuccess({ tradedDeliverables }),
            ScopeOverviewActions.updateCurrentScope({ scopeId: action.scopeId })
          ]),
          catchError((error: Error) => {
            this.snackbarService.showSnackbar(`An error occurred while reopening trade`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.reopenTradeFail({ error }))
          })
        )
      })
    )
  })

  stopTrade$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.STOP_TRADE),
      map((action: { scopeId?: number; deliverableId: number }) => action),
      switchMap((action) => {
        return this.scopeOverviewService.stopTrade(action.scopeId, action.deliverableId).pipe(
          concatMap((tradedDeliverables) => [
            ScopeOverviewActions.stopTradeSuccess({ tradedDeliverables }),
            ScopeOverviewActions.updateCurrentScope({ scopeId: action.scopeId })
          ]),
          catchError((error: Error) => {
            this.snackbarService.showSnackbar(`An error occurred while stopping trade`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.stopTradeFail({ error }))
          })
        )
      })
    )
  })

  completeTrade$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.COMPLETE_TRADE),
      map((action: { scopeId?: number; deliverableId: number }) => action),
      switchMap((action) => {
        return this.scopeOverviewService.completeTrade(action.scopeId, action.deliverableId).pipe(
          concatMap((tradedDeliverables) => [
            ScopeOverviewActions.completeTradeSuccess({ tradedDeliverables }),
            ScopeOverviewActions.updateCurrentScope({ scopeId: action.scopeId })
          ]),
          catchError((error: Error) => {
            this.snackbarService.showSnackbar(`An error occurred while completing trade`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.completeTradeFail({ error }))
          })
        )
      })
    )
  })

  cancelTrade$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.CANCEL_TRADE),
      map((action: { scopeId?: number; deliverableId: number }) => action),
      exhaustMap((action) => {
        return this.scopeOverviewService.cancelTrade(action.scopeId, action.deliverableId).pipe(
          concatMap((tradedDeliverables) => [
            ScopeOverviewActions.cancelTradeSuccess({ tradedDeliverables }),
            ScopeOverviewActions.updateCurrentScope({ scopeId: action.scopeId })
          ]),
          catchError((error: Error) => {
            this.snackbarService.showSnackbar(`An error occurred during while cancelling trade`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.cancelTradeFail({ error }))
          })
        )
      })
    )
  })

  confirmTrade$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.CONFIRM_TRADE),
      map((action: { scopeId?: number; deliverableId: number }) => action),
      exhaustMap((action) => {
        return this.scopeOverviewService.confirmTrade(action.scopeId, action.deliverableId).pipe(
          concatMap((tradedDeliverables) => [
            ScopeOverviewActions.confirmTradeSuccess({ tradedDeliverables }),
            ScopeOverviewActions.updateCurrentScope({ scopeId: action.scopeId })
          ]),
          catchError((error: Error) => {
            this.snackbarService.showSnackbar(`An error occurred while confirming trade`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.confirmTradeFail({ error }))
          })
        )
      })
    )
  })

  applyLocationCardToDeliverable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.applyLocationCardToDeliverable),
      map((action: { scopeId?: number; deliverableId: number; locationId: number }) => action),
      switchMap((action) => {
        return this.scopeOverviewService.applyLocationCardToDeliverable(action.scopeId, action.deliverableId, action.locationId).pipe(
          map((deliverable) =>
              ScopeOverviewActions.applyLocationCardToDeliverableSuccess({ deliverable }),
          ),
          catchError((error: Error) => {
            this.snackbarService.showSnackbar(`An error occurred while applying the location card`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.applyLocationCardToDeliverableFail({ error }))
          })
        )
      })
    )
  })

  deleteScopeDiscount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.deleteScopeDiscount),
      map((action: { scopeId: number }) => action),
      switchMap((action) => {
        return this.scopeOverviewService.deleteScopeDiscount(action.scopeId).pipe(
          map(() =>
              ScopeOverviewActions.deleteScopeDiscountSuccess({ scopeId: action.scopeId }),
          ),
          catchError((error: Error) => {
            this.snackbarService.showSnackbar(`Unable to delete discount, please try again later`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.deleteScopeDiscountFail({ error }))
          })
        )
      })
    )
  })

  deleteScopeMsa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.deleteScopeMsa),
      map((action: { scopeId: number, reason: string }) => action),
      switchMap((action) => {
        return this.scopeOverviewService.deleteScopeMsa(action.scopeId, action.reason).pipe(
          map(() => {
            this.snackbarService.showSnackbar("MSA has been removed from scope", SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            return ScopeOverviewActions.deleteScopeMsaSuccess({ scopeId: action.scopeId, reason: action.reason })
          }),
          catchError((error: Error) => {
            this.snackbarService.showSnackbar(`Unable to remove MSA line item, please try again later`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.deleteScopeMsaFail({ error }))
          })
        )
      })
    )
  })

  deleteScopeFee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.deleteScopeFeeItem),
      switchMap((action) => {
        return this.scopeOverviewService.deleteScopeFeeItem(action.scopeId, action.feeItemId).pipe(
          map(() => ScopeOverviewActions.deleteScopeFeeItemSuccess({ feeItemId: action.feeItemId })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during deletion of ${this.lang.get('scope|l')} fee`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.deleteScopeFeeItemFail({ error }))
          })
        );
      })
    )
  );

  deleteSectionFee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOverviewActions.deleteSectionFeeItem),
      switchMap((action) => {
        return this.scopeOverviewService.deleteSectionFeeItem(action.scopeId, action.sectionId, action.feeItemId).pipe(
          map(() => ScopeOverviewActions.deleteSectionFeeItemSuccess({ sectionId: action.sectionId, feeItemId: action.feeItemId })),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during deletion of ${this.lang.get('stage|l')} fee`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.deleteSectionFeeItemFail({ error }))
          })
        );
      })
    )
  );

  filterDeliverables$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.filterDeliverables),
      switchMap((action: any) => {
        return this.scopeOverviewService.filterScopeDeliverables(action.scopeId, action.versionId, action.filters).pipe(
          map((filteredDeliverables) => ScopeOverviewActions.filterDeliverablesSuccess({filteredDeliverables})),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(`An error occurred during deliverable filtering`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.filterDeliverablesFail({error}))
          })
        );
      })
    );
  });

  updateScopeSectionsOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.updateScopeSectionsOrder),
      switchMap((action: any) => {
        let request: Object[] = action.sections.map((s, i) => {
          return {
            order: i,
            id: s.id,
            name: s.name,
          }
        })
        return this.scopeOverviewService.updateScopeSectionOrder(action.scopeId, request).pipe(
          map(() => ScopeOverviewActions.updateScopeSectionsOrderSuccess()),
          catchError((error: any) => {
            this.snackbarService.showSnackbar(`An error occurred during re-ordering of sections`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(error)
          })
        );
      })
    );
  });

  updateFinancialsOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.updateFinancialsOrder),
      switchMap((action: any) => {
        return this.scopeOverviewService.updateFinancialsOrder(action.scopeId, action.order).pipe(
          map(() => ScopeOverviewActions.updateFinancialsOrderSuccess()),
          catchError((error: any) => {
            this.snackbarService.showSnackbar(`An error occurred during re-ordering of financials`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(error)
          })
        );
      })
    );
  });

  updateDeliverablesOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.updateDeliverablesOrder),
      switchMap((action: any) => {
        let request: Object[] = action.deliverables.map((d, i) => {
          return {
            order: i,
            id: d.id,
            name: d.name,
            sourceType: d.sourceType,
          };
        });
        return this.scopeOverviewService.updateScopeDeliverableOrder(action.scopeId, request).pipe(
          map(() => ScopeOverviewActions.updateDeliverablesOrderSuccess()),
          catchError((error: any) => {
            this.snackbarService.showSnackbar(`An error occurred during re-ordering of deliverables`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(error)
          })
        );
      })
    );
  });

  updateDeliverable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.updateScopeDeliverable),
      debounceTime(300),
      concatMap((action: any) => {
        return this.scopeOverviewService.updateScopeDeliverable(action.scopeId, action.deliverable).pipe(
          map(() => ScopeOverviewActions.updateScopeDeliverableSuccess({ deliverable: action.deliverable })),
          catchError((error: any) => {
            this.snackbarService.showSnackbar(`An error occurred during ${this.lang.get('deliverable|l')} update`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.updateScopeDeliverableFail({error}))
          })
        );
      })
    );
  });

  clearTaskRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.clearScopeTaskRoles),
      debounceTime(300),
      concatMap((action: any) => {
        return this.scopeOverviewService.clearTaskHours(action.scopeId, action.taskId).pipe(
          map(() => ScopeOverviewActions.clearScopeTaskRolesSuccess({ taskId: action.taskId })),
          catchError((error: any) => {
            this.snackbarService.showSnackbar(`An error occurred during task update`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.clearScopeTaskRolesFail({error}))
          })
        );
      })
    );
  });

  updateSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.updateScopeSection),
      switchMap((action: any) => {
        return this.scopeOverviewService.updateScopeSection(action.scopeId, action.section).pipe(
          map(() => ScopeOverviewActions.updateScopeSectionSuccess({ section: action.section })),
          catchError((error: any) => {
            this.snackbarService.showSnackbar(`An error occurred during ${this.lang.get('stage|l')} update`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.updateScopeSectionFail({error}))
          })
        );
      })
    );
  });

  updateComponent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.updateScopeComponent),
      concatMap((action: any) => {
        return this.scopeOverviewService.updateScopeComponent(action.scopeId, action.component, false).pipe(
          map(() => ScopeOverviewActions.updateScopeComponentSuccess({ component: action.component })),
          catchError((error: any) => {
            this.snackbarService.showSnackbar(`An error occurred during ${this.lang.get('component|l')} update`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.updateScopeComponentFail({error}))
          })
        );
      })
    );
  });

  updateDeliverableSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.updateDeliverableSection),
      concatMap((action: any) => {
        return this.scopeOverviewService.updateDeliverableSection(action.scopeId, action.section).pipe(
          map(() => ScopeOverviewActions.updateDeliverableSectionSuccess({ section: action.section })),
          catchError((error: any) => {
            this.snackbarService.showSnackbar(`An error occurred during section update`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.updateDeliverableSectionFail({error}))
          })
        );
      })
    );
  });

  getDeliverableAndComponentFees$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.getDeliverableAndComponentFees),
      switchMap((action: any) => {
        return this.scopeOverviewService.getDeliverableAndComponentFees(action.scopeId, action.versionId).pipe(
          map((response) => ScopeOverviewActions.getDeliverableAndComponentFeesSuccess(response)),
          catchError((error: any) => {
            this.snackbarService.showSnackbar(`An error occurred while retrieving fees`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getDeliverableAndComponentFeesFail({error}))
          })
        );
      })
    );
  });

  getAllDeliverableTPCs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.getAllDeliverableTPCs),
      switchMap((action: any) => {
        return this.scopeOverviewService.getAllDeliverableThirdPartyCosts(action.scopeId, action.versionId).pipe(
          map((response) => ScopeOverviewActions.getAllDeliverableTPCsSuccess(response)),
          catchError((error: any) => {
            this.snackbarService.showSnackbar(`An error occurred while retrieving third party costs`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.getAllDeliverableTPCsFail({error}))
          })
        );
      })
    );
  });

  searchComponentsForScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.SEARCH_COMPONENTS_FOR_SCOPE),
      switchMap((action: any) => {
        return this.scopeOverviewService.searchComponentsForScope(action.scopeId, action.versionId, action.searchText).pipe(
          map((components) => ScopeOverviewActions.searchComponentsForScopeSuccess({components})),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(`An error occurred while searching ${this.lang.get('component.p|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.searchComponentsForScopeFail({error}))
          })
        );
      })
    );
  });

  searchNonDistinctComponentsForScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.SEARCH_NON_DISTINCT_COMPONENTS),
      switchMap((action: any) => {
        return this.scopeOverviewService.searchNonDistinctComponents(action.scopeId, action.versionId, action.searchText).pipe(
          map((components) => ScopeOverviewActions.searchNonDistinctComponentsSuccess({components})),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(`An error occurred while searching ${this.lang.get('component.p|l')}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.searchNonDistinctComponentsFail({error}))
          })
        );
      })
    );
  });

  searchDepartmentsForScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.SEARCH_DEPARTMENTS_FOR_SCOPE),
      switchMap((action: any) => {
        return this.scopeOverviewService.searchDepartmentsForScope(action.scopeId, action.versionId, action.searchText).pipe(
          map((departments) => ScopeOverviewActions.searchDepartmentsForScopeSuccess({departments})),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred while searching departments', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.searchDepartmentsForScopeFail({error}))
          })
        );
      })
    );
  });

  searchRolesForScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActionTypes.SEARCH_ROLES_FOR_SCOPE),
      switchMap((action: any) => {
        return this.scopeOverviewService.searchRolesForScope(action.scopeId, action.versionId, action.searchText).pipe(
          map((roles) => ScopeOverviewActions.searchRolesForScopeSuccess({roles})),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar('An error occurred while searching roles', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.searchRolesForScopeFail({error}))
          })
        );
      })
    );
  });

  updateRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.updateRole),
      debounceTime(300),
      concatMap((action: any) => {
        return this.scopeOverviewService.updateTaskRole(action.scopeId, action.taskId, action.role.source.id, action.updateValue).pipe(
          map((role) => ScopeOverviewActions.updateRoleSuccess({taskId: action.taskId, role: role})),
          catchError((error: any) => {
            this.snackbarService.showSnackbar(`An error occurred during role update`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.updateRoleFail({error}))
          })
        );
      })
    );
  });

  applyLocationToRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.applyLocationToRole),
      concatMap((action: any) => {
        return this.scopeOverviewService.applyLocationToRole(action.roleId, action.locationRoleId, action.scopeId).pipe(
          map(() => ScopeOverviewActions.applyLocationToRoleSuccess(
            {sourceDepartmentId: action.sourceDepartmentId, sourceRoleId: action.sourceRoleId,
              ratecardLocationRole: action.ratecardLocationRole, locationName: action.locationName,
              rateCardRate: action.rateCardRate, rateCardCost: action.rateCardCost}
          )),
          catchError((error: any) => {
            this.snackbarService.showSnackbar(`An error occurred during role update`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(ScopeOverviewActions.applyLocationToRoleFail({error}))
          })
        );
      })
    );
  });

  getScenario$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.getScenario),
      switchMap(({ scenarioId }) => {
        return this.scenarioService.get(scenarioId).pipe(
          map((scenario) =>
            ScopeOverviewActions.getScenarioSuccess({ scenario })
          ),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred during loading scenario',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR,
            );
            return of(ScopeOverviewActions.getScenarioFail({ error }));
          })
        );
      })
    );
  });

  saveScopeConfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.saveScopeConfiguration),
      switchMap(({ scopeId, configuration }) => {
        return this.scopeOverviewService.saveConfiguration(scopeId, configuration).pipe(
          map(() =>
            ScopeOverviewActions.saveScopeConfigurationSuccess()
          ),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred while saving the configuration form',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR,
            );
            return of(ScopeOverviewActions.saveScopeConfigurationFail({ error }));
          })
        );
      })
    );
  });

  submitScopeConfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScopeOverviewActions.submitScopeConfiguration),
      switchMap(({ scopeId, modifiers }) => {
        return this.scopeOverviewService.submitConfiguration(scopeId, modifiers).pipe(
          map((currentScope) => ScopeOverviewActions.submitScopeConfigurationSuccess({ currentScope })),
          catchError((error: any) => {
            console.error(error);
            this.snackbarService.showSnackbar(
              'An error occurred while submitting the configuration form',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR,
            );
            return of(ScopeOverviewActions.submitScopeConfigurationFail({ error }));
          })
        );
      })
    );
  });
}

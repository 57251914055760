import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

export interface LibraryCascadeChangesModalConfig {
  itemType: string
  onSubmit: (result: boolean) => any
}

@Component({
  templateUrl: './library-cascade-changes-modal.component.html',
  styleUrls: ['./library-cascade-changes-modal.component.scss'],
})
export class LibraryCascadeChangesModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LibraryCascadeChangesModalConfig,
    public dialogRef: MatDialogRef<LibraryCascadeChangesModalComponent>
  ) {}

  close = (value: boolean) => (this.data.onSubmit?.(value), this.dialogRef.close(value))
}

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ScopeVersion } from '@core/model/scope-version'
import { untilDestroyed } from '@shared/utils/utils'
import { User } from '@core/model/user.model'
import { MatDialogModule } from '@angular/material/dialog'
import { ScopeUiOptionsMenuComponent } from '@shared/components/scope-ui-options-menu/scope-ui-options-menu.component'
import { AsyncPipe, DecimalPipe, NgForOf, NgIf } from '@angular/common'
import { PipesModule } from '@shared/pipe/index.module'
import { MatDividerModule } from '@angular/material/divider'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { ScopeUiAutocompleteComponent } from '@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import { OfficeLocation } from '@core/model/office-location'
import { SecondParty } from '@core/model/second-party.model'
import { Brand } from '@app/features/scope-overview/model/brand.model'
import { DateFormatPipe } from '@shared/pipe/dateFormat.pipe'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { DirectivesModule } from '@shared/directives/index.module'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { ScopeUiDatepickerComponent } from '@shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatButtonModule } from '@angular/material/button'
import _, { cloneDeep } from 'lodash'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { CountryCodeService } from '@core/service/country-code.service'
import { ScopeMetadataService } from '@core/service/scope-metadata.service'
import { ScopeDetailsService } from '@app/features/scope-overview/service/scope-details.service'
import {
  TrafficSystemMetadataFieldValue
} from '@app/features/scope-overview/model/traffic-system-metadata-field-value.model'
import {
  ScopeCustomFieldValueStructure
} from '@app/features/scope-overview/model/scope-custom-field-value-structure.model'
import { Money } from '@app/features/scope-overview/model/money.model'

@Component({
  selector: 'app-scope-details',
  templateUrl: './scope-details.component.html',
  styleUrls: ['./scope-details.component.scss'],
  imports: [
    ScopeUiOptionsMenuComponent,
    MatDialogModule,
    AsyncPipe,
    PipesModule,
    NgIf,
    MatDividerModule,
    ScopeUiInputComponent,
    ScopeUiAutocompleteComponent,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    NgForOf,
    DirectivesModule,
    ScopeUiDatepickerComponent,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    DecimalPipe,
  ],
  providers: [DateFormatPipe],
  standalone: true,
})
export class ScopeDetailsComponent {
  private readonly destroy$

  @Input() loggedInUser!: User
  @Input() currentScope!: ScopeVersion
  @Input() officeLocations!: OfficeLocation[] | null
  @Input() secondParties!: SecondParty[] | null
  @Input() selectedSecondParty!: SecondParty | null
  @Input() selectedOfficeLocation!: any | null
  @Input() set customFieldValueStructure(value: ScopeCustomFieldValueStructure) {
    this.updateCustomFieldValueStructure = cloneDeep(value)
  }
  @Input() customFieldsDefinitionOutdated!: boolean | null
  @Input() showUseRetainedTeamToggle: boolean
  @Input() benchmarkRateCards!: RatecardVersion[]
  @Input() xlsxTemplates!: any[] | null
  @Input() contacts: any[]
  @Input() locations: OfficeLocation[]
  @Input() trafficMetadataLookupFields!: any
  @Output() onGetSecondParty: EventEmitter<number>
  @Output() onGetOfficeLocation: EventEmitter<number>
  @Output() onConfirmMetaStructureUpdate: EventEmitter<ScopeVersion>
  @Output() onSaveScope: EventEmitter<ScopeVersion>

  selectedBrand?: Brand | null
  selectedKeyContact?: any
  updateScopeRequest: ScopeVersion
  trafficMetaDataFieldTemp: any = {}
  trafficSystemMetadataSearch$: any = {}
  currencyCode?: string
  updateCustomFieldValueStructure?: ScopeCustomFieldValueStructure
  maximumStartDate: Date
  minimumEndDate: Date

  constructor(
    datePipe: DateFormatPipe,
    private countryCodeService: CountryCodeService,
    private scopeMetadataService: ScopeMetadataService,
    private scopeDetailsService: ScopeDetailsService
  ) {
    this.destroy$ = untilDestroyed()
    this.onGetSecondParty = new EventEmitter<number>()
    this.onGetOfficeLocation = new EventEmitter<number>()
    this.onConfirmMetaStructureUpdate = new EventEmitter<ScopeVersion>()
    this.onSaveScope = new EventEmitter<ScopeVersion>()
  }

  ngOnInit() {
    this.initScope()
    this.scopeDetailsService.getOfficeLocations()
    this.scopeDetailsService.getXlsxOutputTemplates()
    this.scopeDetailsService.getSecondParties()
    this.scopeDetailsService.getBenchmarkRateCards()
    this.scopeDetailsService.getDefaultRateCard()
    this.scopeDetailsService.getSecondParty(this.currentScope.identity.secondParty.id)
    if (
      this.loggedInUser?.company.hasApplicationSetting('SOW__RETAINED_HOURS') &&
      this.currentScope.scopeOfWorkVersion
    ) {
      this.scopeDetailsService.getRetainedTeamStats(this.currentScope.scopeOfWorkVersion.identity.id)
    }
    this.scopeDetailsService.getCustomFieldsOutdated(this.currentScope.identity.id)
    this.minimumEndDate = this.currentScope.getMinimumEndDate()
    this.maximumStartDate = this.currentScope.getMaximumStartDate()
  }

  getSecondPartyById(secondPartyId: number) {
    this.updateScopeRequest.identity.contact = null;
    this.updateScopeRequest.identity.location = null;
    this.updateScopeRequest.identity.brand = null;
    this.selectedSecondParty = null;
    this.selectedBrand = null;
    setTimeout(() => {
      if(secondPartyId && this.loggedInUser && this.loggedInUser.hasPrivilege('SECOND_PARTY__VIEW')) {
        this.onGetSecondParty.emit(secondPartyId);

      } else {
        this.contacts = [];
        this.locations = [];
      }

    }, 2000)

  };

  onSelectSecondParty($event: { event: SecondParty; componentId?: any }) {
    this.updateScopeRequest.identity.secondParty = $event.event
    this.getSecondPartyById($event.event?.id)
  }

  onSelectBrand($event: { event: Brand; componentId?: any }) {
    this.selectedBrand = $event.event
    this.updateScopeRequest.identity.brand = $event.event
    if ($event.event) {
      this.updateScopeRequest.identity.contact = $event.event.keyContact
      this.updateScopeRequest.identity.location = $event.event.location
    }
  }

  onSelectKeyContact($event: { event: any; componentId?: any }) {
    this.selectedKeyContact = $event.event
    this.updateScopeRequest.identity.contact = $event.event
  }

  onSelectLocation($event: { event: any; componentId?: any }) {
    this.updateScopeRequest.identity.location = $event.event
  }

  onSelectTemplate($event: { event: any; componentId?: any }) {
    this.updateScopeRequest.identity.xlsxTemplate = $event.event
  }

  findLocationByISO(iso2: any) {
    return this.countryCodeService.getCountryByISO2(iso2)
  }

  initScope() {
    this.updateScopeRequest = cloneDeep(this.currentScope)
    this.currencyCode = this.currentScope.identity.rateCard.currencyCode

    for (let i in this.updateScopeRequest.trafficSystemEntityMetadata) {
      if (this.updateScopeRequest.trafficSystemEntityMetadata[i]) {
        this.trafficMetaDataFieldTemp[i] = this.updateScopeRequest.trafficSystemEntityMetadata[i].value
      }
    }
  }

  updateTrafficSystemMetadataField(uuid: any) {
    let val = this.trafficMetaDataFieldTemp[uuid]
    if (this.updateScopeRequest.trafficSystemEntityMetadata == undefined) {
      this.updateScopeRequest.trafficSystemEntityMetadata = new Map<string, TrafficSystemMetadataFieldValue>()
    }
    this.updateScopeRequest.trafficSystemEntityMetadata[uuid] = {
      name: val,
      value: val,
    }
  }

  onSearchTrafficField(event: { searchString: string }, secondPartyId: number, rateCardId: number, uuid: string) {
    this.trafficSystemMetadataSearch$[uuid] = this.scopeMetadataService.callScopeMetadataLookupUrl(
      uuid,
      secondPartyId,
      rateCardId,
      event.searchString
    )
  }

  onSelectBenchmarkRateCard($event: { event: any; componentId?: any }) {
    this.updateScopeRequest.identity.benchmarkRateCard = $event.event
  }

  onSelectStartDate($event: Date) {
    this.updateScopeRequest.startDate = $event
  }

  onSelectEndDate($event: Date) {
    this.updateScopeRequest.endDate = $event
  }

  getSecondParties() {
    return (
      (this.selectedOfficeLocation ? this.selectedOfficeLocation.restrictedSecondParties : this.secondParties) || []
    )
  }

  getContacts() {
    let contacts: any[]
    if (this.selectedBrand) {
      let _this = this
      contacts = this.contacts.filter(function (contact) {
        return contact.location?.id === _this.selectedBrand!.location?.id
      })
    } else {
      contacts = this.contacts || []
    }
    return contacts
  }

  getLocations() {
    let locations: any[]
    if (this.selectedBrand) {
      let _this = this
      locations =
        this.selectedSecondParty?.locations?.filter(function (location: any) {
          return location.id === _this.selectedBrand!.location?.id
        }) || []
    } else {
      locations = this.locations || []
    }
    return locations
  }

  getBudgetLabel() {
    return `Budget (${this.currencyCode})`
  }

  getTPCBudgetLabel() {
    return `Third Party Cost Budget (${this.currencyCode})`
  }

  saveDetails() {
    if (this.isFormValid()) {
      this.updateScopeRequest.scopeVersionCustomFieldValueStructure = this.updateCustomFieldValueStructure
      this.updateScopeRequest.companyScopeCustomFieldsDefinition = this.currentScope.companyScopeCustomFieldsDefinition
      this.onSaveScope.emit(cloneDeep(this.updateScopeRequest))
    }
  }

  isFormValid() {
    if (
      this.updateScopeRequest &&
      this.updateScopeRequest.identity.secondParty &&
      this.updateScopeRequest.identity.secondParty.id !== null
    ) {
      return !_.isEmpty(this.updateScopeRequest.name) &&
        this.updateScopeRequest.budget?.amount >= 0 &&
        this.updateScopeRequest.thirdPartyCostBudget?.amount >= 0
    }
    return false
  }

  confirmMetaStructureUpdate() {
    this.onConfirmMetaStructureUpdate.emit(this.currentScope)
  }

  officeLocationDisplayName(item: any) {
    return item
      ? `${item.name} (${item.countryName}, ${
          item.region.charAt(0).toUpperCase() + item.region.slice(1).toLowerCase()
        })`
      : ''
  }

  locationDisplayName = (item?: OfficeLocation) => {
    if (item) {
      let country = this.findLocationByISO(item.country)
      return `${item.location} (${item.getAddress()} ${country.Country_Name}, ${country.Continent})`
    }
    return ''
  }

  contactDisplayName(item: any): string {
    return item?.contactName || ''
  }

  locationSimpleName(item?: OfficeLocation) {
    return item?.location || ''
  }

  trafficFieldOption(item: any) {
    return item ? `${item.name} (${item.description})` : ''
  }

  selectOfficeLocation($event: { event: any; componentId?: any }) {
    if ($event.event) {
      this.updateScopeRequest.identity.officeLocation = $event.event
      this.onGetOfficeLocation.emit($event.event.id)
    } else {
      this.updateScopeRequest.identity.officeLocation = null
      this.selectedOfficeLocation = null
      this.selectedSecondParty = null
      this.selectedBrand = null
      this.updateScopeRequest.identity.secondParty = null
      this.updateScopeRequest.identity.brand = null
      this.updateScopeRequest.identity.contact = null
      this.updateScopeRequest.identity.location = null
      this.updateScopeRequest.identity.template = null
    }
  }

  selectTrafficField($event: { event: any; componentId?: any }, uuid: string) {
    this.updateScopeRequest.trafficSystemEntityMetadata[uuid] = $event.event
  }

  updateBudget($event: any) {
    this.updateScopeRequest.budget = new Money($event, this.currencyCode)
  }

  updateTPCBudget($event: any) {
    this.updateScopeRequest.thirdPartyCostBudget = new Money($event, this.currencyCode)
  }
  updateUseRetainedHours($event: any) {
    this.updateScopeRequest.useRetainedHours = $event.checked
  }
  isUseRetainedHours(): boolean {
    return this.updateScopeRequest.useRetainedHours;
  }

  getMinEndDate() {
    return this.minimumEndDate ? new Date(
      Math.max(this.updateScopeRequest.startDate?.getTime(), this.currentScope.getMinimumEndDate()?.getTime())
    ): this.updateScopeRequest.startDate
  }
}

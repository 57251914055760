import { User } from '@app/core/model/user.model'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { LibraryComplexitiesMap } from './library-complexities-map.model'
import { LibraryComplexity } from './library-complexity.model'

export class LibraryComponentEntry {
  id?: number
  name?: string
  versionNumber?: number
  language?: string
  createdTs?: Date
  createdBy?: User
  rateCardVersion: RatecardVersion
  fixedPricing: boolean
  fixedFeeEditable: boolean
  fixedFeeHasRoles: boolean
  discipline: { id: number; name: string }
  libraryComponentTemplate?: { id: number; name: string }
  disciplineTypeName?: string
  componentComplexities?: LibraryComplexitiesMap
  complexities?: LibraryComplexity[]
}

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {SharedModule} from "@shared/shared.module";
import {ModalConfig} from "@core/model/modal-config.model";
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { map, Observable } from 'rxjs';
import { ScopeUiDuration } from '@shared/components/ui-components/scope-ui-duration/scope-ui-duration.component';
import { NewTask } from '@core/model/new-task.model';
import { FormControl } from '@angular/forms';
import { LanguageService } from '@core/service/language.service';
import {
  ScopeUiAutocompleteComponent
} from '@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component';
import { searchInText } from '@app/shared/utils/search-utils.const';

@Component({
  selector: 'add-task-modal',
  templateUrl: './add-task-modal.component.html',
  styleUrls: ['./add-task-modal.component.scss'],
  imports: [
    SharedModule,
    ScopeUiInputComponent,
    ScopeUiDuration,
    ScopeUiAutocompleteComponent,
  ],
  standalone: true
})
export class AddTaskModalComponent implements OnInit {
  @Input() taskTemplates$: Observable<any>
  @Input() rateCards$: Observable<any>
  @Input() newTaskTemplate = false
  @Output() onSubmit: EventEmitter<NewTask>
  @Output() onSubmitSavedTasks: EventEmitter<number[]>
  searchText: string
  taskTemplates: any[] = []
  title: string = "Create Task"
  subtitle: string = "Create a new or a series of new tasks, or add a saved task."
  page: string = 'new'
  editDescription: boolean = false
  newTask: NewTask
  nameFormControl = new FormControl()
  quantityFormControl = new FormControl()
  descriptionDirty: boolean = false
  selectedPeriod: { value: string, name: string } = { name: 'Custom', value: 'custom' };

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalConfig, private lang: LanguageService) {
    this.onSubmit = new EventEmitter<NewTask>()
    this.onSubmitSavedTasks = new EventEmitter<number[]>()
    this.newTask = new NewTask()
    this.newTask.identificationType = "TASK"
    this.newTask.sourceType = "NONE"
    this.newTask.startDate = new Date()
    this.newTask.endDate = new Date()
    this.newTask.endDate.setMonth(this.newTask.startDate.getMonth() + 3)
  }

  ngOnInit() {
    if (!this.newTaskTemplate) {
      this.taskTemplates$.pipe(
        map((taskTemplates: any[]) => {
          this.taskTemplates = taskTemplates;
        })
      ).subscribe()
    }
  }

  onSearch(event: string) {
    this.searchText = event
  }

  checkAll() {
    this.getFilteredTemplates().forEach((template) => {
      template.selected = true
    })
  }

  clear() {
    this.taskTemplates.forEach((template) => {
      template.selected = false
    })
  }

  getFilteredTemplates() {
    return this.searchText?.length
      ? this.taskTemplates.filter((item) => searchInText(item.name, this.searchText))
      : this.taskTemplates
  }

  createNewTasks() {
    this.page = 'new';
    this.title = "Create Task"
    this.subtitle = "Create a new or a series of new tasks, or add a saved task."
  }

  addSavedTasks() {
    this.page = 'existing'
    this.title = 'Add Saved Task(s)'
    this.subtitle = `Find task(s) and add them to the ${this.lang.get('scope|l')}.`
  }

  getTaskName(template: any) {
    if (this.searchText) {
      let search = new RegExp(this.searchText, 'gi')
      return template.name.replace(search, '<b>$&</b>')
    }
    return template.name
  }

  tasksCount() {
    return this.taskTemplates.filter((template) => template.selected).length
  }

  confirm() {
    this.onSubmit.emit(this.newTask)
  }

  confirmSavedTasks() {
    this.onSubmitSavedTasks.emit(this.taskTemplates.filter((template) => template.selected).map((template) => template.id))
  }

  isNewTasksFormValid() {
    return this.newTask.name?.length && this.newTask.description?.length &&
      (this.newTaskTemplate ? !!this.newTask.rateCardVersion : this.newTask.quantity > 0)
  }

  isSavedTasksFormValid() {
    return this.tasksCount() > 0
  }

  getSelectedTemplates() {
    return this.taskTemplates.filter((template) => template.selected)
  }
}

import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LibraryShareSettingsModalConfig } from '@app/features/library-management/models'
import {
  LibraryManagementFoldersActions,
  LibraryManagementFoldersSelectors,
} from '@app/features/library-management/store'
import { LibraryManagementFolderGroupSettingsModel } from '@app/features/library-management/store/models/library-folders'
import { untilDestroyed } from '@app/shared/utils/utils'
import { Store } from '@ngrx/store'
import { every } from 'lodash'

@Component({
  selector: 'library-share-settings-modal.component',
  templateUrl: 'library-share-settings-modal.component.html',
  styleUrls: ['library-share-settings-modal.component.scss'],
})
export class LibraryShareSettingsModalComponent {
  private readonly destroy$

  groups: LibraryManagementFolderGroupSettingsModel[]

  get isAllSelected() {
    return every(this.groups, { isShared: true })
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: LibraryShareSettingsModalConfig, private store$: Store) {
    this.destroy$ = untilDestroyed()

    this.store$.dispatch(LibraryManagementFoldersActions.getGroupSettings({ id: data.folder.id }))

    this.store$
      .select(LibraryManagementFoldersSelectors.selectGroupSettings)
      .pipe(this.destroy$())
      .subscribe((groups: LibraryManagementFolderGroupSettingsModel[]) => {
        this.groups = groups?.map((g) => ({ ...g }))
      })
  }

  selectAll = () => ((value) => this.groups?.map((g) => (g.isShared = !value)))(this.isAllSelected)

  submit = () => this.data.onSubmit(this.groups)

  protected readonly trackById = (index: number, item: any) => item.companyGroupId
}

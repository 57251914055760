import { LibraryDiscipline, LibraryManagementFilters, LibraryRateCard } from '../models'
import { createAction, props } from '@ngrx/store'

import { LibraryExportType } from '@app/features/library-management/store'
import { LibraryManagementFolder } from '@app/features/library-management/store/models/library-folders'

export enum LibraryManagementActionTypes {
  GET_HIGHLIGHTS = '[Library Management] Get Highlights',
  GET_HIGHLIGHTS_FAIL = '[Library Management] Get Highlights Fail',
  GET_HIGHLIGHTS_SUCCESS = '[Library Management] Get Highlights Success',

  GET_RATECARDS = '[Library Management] Get Ratecards',
  GET_RATECARDS_FAIL = '[Library Management] Get Ratecards Fail',
  GET_RATECARDS_SUCCESS = '[Library Management] Get Ratecards Success',

  GET_DISCIPLINES = '[Library Management] Get Disciplines',
  GET_DISCIPLINES_FAIL = '[Library Management] Get Disciplines Fail',
  GET_DISCIPLINES_SUCCESS = '[Library Management] Get Disciplines Success',

  UPDATE_FILTERS = '[Library Management] Update filters',
  SET_FOLDER_ID = '[Library Management] Set Folder Id',

  GET_FOLDER_FAIL = '[Library Management] Get Folder Fail',
  GET_FOLDER_SUCCESS = '[Library Management] Get Folder Success',

  SET_DIALOG_ID = '[Library Management] Set Active Dialog ID',
  CLOSE_ACTIVE_MODAL = '[Library Management] Close Active Modal',

  EXPORT_ITEMS = '[Library Management] Export items',
}

const getHighlights = createAction(LibraryManagementActionTypes.GET_HIGHLIGHTS)
const getHighlightsFail = createAction(LibraryManagementActionTypes.GET_HIGHLIGHTS_FAIL, props<{ error: Error }>())
const getHighlightsSuccess = createAction(
  LibraryManagementActionTypes.GET_HIGHLIGHTS_SUCCESS,
  props<{ highlights: any }>()
)

const getRatecards = createAction(LibraryManagementActionTypes.GET_RATECARDS)
const getRatecardsFail = createAction(LibraryManagementActionTypes.GET_RATECARDS_FAIL, props<{ error: Error }>())
const getRatecardsSuccess = createAction(
  LibraryManagementActionTypes.GET_RATECARDS_SUCCESS,
  props<{ rateCards: LibraryRateCard[] }>()
)

const getDisciplines = createAction(LibraryManagementActionTypes.GET_DISCIPLINES)
const getDisciplinesFail = createAction(LibraryManagementActionTypes.GET_DISCIPLINES_FAIL, props<{ error: Error }>())
const getDisciplinesSuccess = createAction(
  LibraryManagementActionTypes.GET_DISCIPLINES_SUCCESS,
  props<{ disciplines: LibraryDiscipline[] }>()
)

const updateFilters = createAction(
  LibraryManagementActionTypes.UPDATE_FILTERS,
  props<{ filters: LibraryManagementFilters }>()
)

const setFolderId = createAction(LibraryManagementActionTypes.SET_FOLDER_ID, props<{ folderId: number }>())

const getFolderFail = createAction(LibraryManagementActionTypes.GET_FOLDER_FAIL, props<{ error: Error }>())
const getFolderSuccess = createAction(
  LibraryManagementActionTypes.GET_FOLDER_SUCCESS,
  props<{ folder: LibraryManagementFolder }>()
)

const setDialogId = createAction(LibraryManagementActionTypes.SET_DIALOG_ID, props<{ id: string }>())
const closeActiveModal = createAction(LibraryManagementActionTypes.CLOSE_ACTIVE_MODAL)

const exportItems = createAction(
  LibraryManagementActionTypes.EXPORT_ITEMS,
  props<{ itemType: LibraryExportType; id: number }>()
)

export const LibraryManagementActions = {
  getHighlights,
  getHighlightsFail,
  getHighlightsSuccess,
  getRatecards,
  getRatecardsFail,
  getRatecardsSuccess,
  getDisciplines,
  getDisciplinesFail,
  getDisciplinesSuccess,
  updateFilters,
  setFolderId,
  getFolderFail,
  getFolderSuccess,
  setDialogId,
  closeActiveModal,
  exportItems,
}

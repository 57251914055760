import { LibraryDeliverable, LibraryDeliverableEntry } from '@app/features/library-management/store/models/deliverable'
import { createAction, props } from '@ngrx/store'

import { LibraryCreateDeliverableActionPayload } from '@app/features/library-management/store/models/modals'

export enum LibraryCreateDeliverableActionTypes {
  CREATE_DELIVERABLE = '[Library Management] Create Deliverable',
  CREATE_DELIVERABLE_FAIL = '[Library Management] Create Deliverable Fail',
  CREATE_DELIVERABLE_SUCCESS = '[Library Management] Create Deliverable Success',

  CREATE_DELIVERABLE_ENTRY = '[Library Management] Create Deliverable Entry',
  CREATE_DELIVERABLE_ENTRY_FAIL = '[Library Management] Create Deliverable Entry Fail',
  CREATE_DELIVERABLE_ENTRY_SUCCESS = '[Library Management] Create Deliverable Entry Success',

  NAVIGATE_TO_DELIVERABLE_OVERVIEW = '[Library Management] Navigate to Deliverable Overview',
}

const createDeliverable = createAction(
  LibraryCreateDeliverableActionTypes.CREATE_DELIVERABLE,
  props<LibraryCreateDeliverableActionPayload>()
)
const createDeliverableFail = createAction(
  LibraryCreateDeliverableActionTypes.CREATE_DELIVERABLE_FAIL,
  props<{ error: Error }>()
)
const createDeliverableSuccess = createAction(
  LibraryCreateDeliverableActionTypes.CREATE_DELIVERABLE_SUCCESS,
  props<{ deliverable: LibraryDeliverable }>()
)

const createDeliverableEntry = createAction(
  LibraryCreateDeliverableActionTypes.CREATE_DELIVERABLE_ENTRY,
  props<{ id: number; entry: LibraryDeliverableEntry }>()
)
const createDeliverableEntryFail = createAction(
  LibraryCreateDeliverableActionTypes.CREATE_DELIVERABLE_ENTRY_FAIL,
  props<{ error: Error }>()
)
const createDeliverableEntrySuccess = createAction(
  LibraryCreateDeliverableActionTypes.CREATE_DELIVERABLE_ENTRY_SUCCESS,
  props<{ entry: any }>()
)

const navigateToDeliverableOverview = createAction(
  LibraryCreateDeliverableActionTypes.NAVIGATE_TO_DELIVERABLE_OVERVIEW,
  props<{ id: number; entryId?: number }>()
)

export const LibraryCreateDeliverableActions = {
  createDeliverable,
  createDeliverableFail,
  createDeliverableSuccess,
  createDeliverableEntry,
  createDeliverableEntryFail,
  createDeliverableEntrySuccess,
  navigateToDeliverableOverview,
}

import { createAction, props } from '@ngrx/store'

import { LibraryCreateTPCActionPayload } from '@app/features/library-management/store/models/modals'
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'
import { ThirdPartyCostFormula } from '@app/core/model/third-party-cost-formula.model'
import { ThirdPartyCostGroup } from '@app/features/library-management/store/models/third-party-cost'

export enum LibraryThirdPartyCostActionTypes {
  GET_GROUPS = '[Library Third Party Cost] Get TPC Groups',
  GET_GROUPS_FAIL = '[Library Third Party Cost] Get TPC Groups Fail',
  GET_GROUPS_SUCCESS = '[Library Third Party Cost] Get TPC Groups Success',

  GET_FORMULAS = '[Library Third Party Cost] Get TPC Formulas',
  GET_FORMULAS_FAIL = '[Library Third Party Cost] Get TPC Formulas Fail',
  GET_FORMULAS_SUCCESS = '[Library Third Party Cost] Get TPC Formulas Success',

  CREATE_TPC = '[Library Third Party Cost] Create Third Party Cost',
  CREATE_TPC_FAIL = '[Library Third Party Cost] Create Third Party Cost Fail',
  CREATE_TPC_SUCCESS = '[Library Third Party Cost] Create Third Party Cost Success',

  NAVIGATE_TO_LIBRARY = '[Library Third Party Cost] Navigate to Library',
  NAVIGATE_TO_TPC = '[Library Third Party Cost] Navigate to Third Party Cost',

  GET_TPC = '[Library Third Party Cost] Get Third Party Cost',
  GET_TPC_FAIL = '[Library Third Party Cost] Get Third Party Cost Fail',
  GET_TPC_SUCCESS = '[Library Third Party Cost] Get Third Party Cost Success',

  UPDATE_TPC = '[Library Third Party Cost] Update Third Party Cost',
  UPDATE_TPC_FAIL = '[Library Third Party Cost] Update Third Party Cost Fail',
  UPDATE_TPC_SUCCESS = '[Library Third Party Cost] Update Third Party Cost Success',

  DELETE_TPC = '[Library Third Party Cost] Delete Third Party Cost',
  DELETE_TPC_FAIL = '[Library Third Party Cost] Delete Third Party Cost Fail',
  DELETE_TPC_SUCCESS = '[Library Third Party Cost] Delete Third Party Cost Success',

  DUPLICATE_TPC = '[Library Third Party Cost] Duplicate Third Party Cost',
  DUPLICATE_TPC_FAIL = '[Library Third Party Cost] Duplicate Third Party Cost Fail',
  DUPLICATE_TPC_SUCCESS = '[Library Third Party Cost] Duplicate Third Party Cost Success',
}

const getGroups = createAction(LibraryThirdPartyCostActionTypes.GET_GROUPS)
const getGroupsFail = createAction(LibraryThirdPartyCostActionTypes.GET_GROUPS_FAIL, props<{ error: Error }>())
const getGroupsSuccess = createAction(
  LibraryThirdPartyCostActionTypes.GET_GROUPS_SUCCESS,
  props<{ groups: ThirdPartyCostGroup[] }>()
)

const getFormulas = createAction(LibraryThirdPartyCostActionTypes.GET_FORMULAS)
const getFormulasFail = createAction(LibraryThirdPartyCostActionTypes.GET_FORMULAS_FAIL, props<{ error: Error }>())
const getFormulasSuccess = createAction(
  LibraryThirdPartyCostActionTypes.GET_FORMULAS_SUCCESS,
  props<{ formulas: ThirdPartyCostFormula[] }>()
)

const createThirdPartyCost = createAction(
  LibraryThirdPartyCostActionTypes.CREATE_TPC,
  props<LibraryCreateTPCActionPayload>()
)
const createThirdPartyCostFail = createAction(
  LibraryThirdPartyCostActionTypes.CREATE_TPC_FAIL,
  props<{ error: Error }>()
)
const createThirdPartyCostSuccess = createAction(
  LibraryThirdPartyCostActionTypes.CREATE_TPC_SUCCESS,
  props<{ tpc: ThirdPartyCost }>()
)

const navigateToLibrary = createAction(LibraryThirdPartyCostActionTypes.NAVIGATE_TO_LIBRARY)
const navigateToThirdPartyCost = createAction(LibraryThirdPartyCostActionTypes.NAVIGATE_TO_TPC, props<{ id: number }>())

const getThirdPartyCost = createAction(LibraryThirdPartyCostActionTypes.GET_TPC, props<{ id: number }>())
const getThirdPartyCostFail = createAction(LibraryThirdPartyCostActionTypes.GET_TPC_FAIL, props<{ error: Error }>())
const getThirdPartyCostSuccess = createAction(
  LibraryThirdPartyCostActionTypes.GET_TPC_SUCCESS,
  props<{ tpc: ThirdPartyCost }>()
)

const updateThirdPartyCost = createAction(LibraryThirdPartyCostActionTypes.UPDATE_TPC, props<{ tpc: ThirdPartyCost }>())
const updateThirdPartyCostFail = createAction(
  LibraryThirdPartyCostActionTypes.UPDATE_TPC_FAIL,
  props<{ error: Error }>()
)
const updateThirdPartyCostSuccess = createAction(
  LibraryThirdPartyCostActionTypes.UPDATE_TPC_SUCCESS,
  props<{ tpc: ThirdPartyCost }>()
)

const deleteThirdPartyCost = createAction(LibraryThirdPartyCostActionTypes.DELETE_TPC, props<{ id: number }>())
const deleteThirdPartyCostFail = createAction(
  LibraryThirdPartyCostActionTypes.DELETE_TPC_FAIL,
  props<{ error: Error }>()
)
const deleteThirdPartyCostSuccess = createAction(LibraryThirdPartyCostActionTypes.DELETE_TPC_SUCCESS)

const duplicateThirdPartyCost = createAction(
  LibraryThirdPartyCostActionTypes.DUPLICATE_TPC,
  props<{ id: number; name: string }>()
)
const duplicateThirdPartyCostFail = createAction(
  LibraryThirdPartyCostActionTypes.DUPLICATE_TPC_FAIL,
  props<{ error: Error }>()
)
const duplicateThirdPartyCostSuccess = createAction(LibraryThirdPartyCostActionTypes.DUPLICATE_TPC_SUCCESS)

export const LibraryThirdPartyCostActions = {
  getGroups,
  getGroupsFail,
  getGroupsSuccess,
  getFormulas,
  getFormulasFail,
  getFormulasSuccess,
  createThirdPartyCost,
  createThirdPartyCostFail,
  createThirdPartyCostSuccess,
  navigateToThirdPartyCost,
  navigateToLibrary,
  getThirdPartyCost,
  getThirdPartyCostFail,
  getThirdPartyCostSuccess,
  updateThirdPartyCost,
  updateThirdPartyCostFail,
  updateThirdPartyCostSuccess,
  deleteThirdPartyCost,
  deleteThirdPartyCostFail,
  deleteThirdPartyCostSuccess,
  duplicateThirdPartyCost,
  duplicateThirdPartyCostFail,
  duplicateThirdPartyCostSuccess,
}

<form class="auto-complete-form">
  <mat-form-field
    class="auto-complete-form-field"
    [class.has-icon]="hasIcon"
    subscriptSizing="dynamic">
    <mat-label *ngIf="label">
      {{ label }} <span *ngIf="showSelectedOptionsAmount">({{ selectedItems?.length || 0 }})</span>
    </mat-label>

    <span class="flex gap-2 items-center auto-complete-form-content">
      <i
        *ngIf="hasIcon"
        class="material-icons material-symbols-rounded text-base">
        {{ iconName }}
      </i>
      <input
        type="text"
        [id]="label"
        [placeholder]="placeholder"
        matInput
        [formControl]="!isCheckboxMultiselect || isFocused ? control : selectedItemsControl"
        [matAutocomplete]="auto"
        [required]="required"
        (input)="onInput()"
        (focusin)="isFocused = true"
        (focusout)="onFocusOut(false, $event)" />
      <span
        *ngIf="markerProperty && selectedValue && isMarkerPropertyAvailable(selectedValue)"
        class="option-marker selected">
        {{ markerText }}
      </span>
      <mat-icon
        *ngIf="control.value"
        class="material-symbols-rounded clear"
        aria-label="Clear"
        (click)="clear($event)"
        matSuffix>
        close
      </mat-icon>
      <mat-icon
        *ngIf="!control.value && !hideToggleIcon"
        class="material-symbols-rounded expand-icon"
        [ngClass]="{ 'panel-open': matAutocompleteTrigger?.panelOpen }"
        aria-label="Expand"
        (click)="toggle($event)"
        matSuffix>
        expand_more
      </mat-icon>
    </span>

    <mat-autocomplete
      (closed)="onFocusOut()"
      (optionSelected)="isCheckboxMultiselect ? toggleSelection($event.option.value) : onSelection($event)"
      #auto="matAutocomplete"
      class="{{ selectedValue ? 'selected' : '' }}"
      [displayWith]="displayFn">
      <ng-container *ngIf="isCheckboxMultiselect; then checkboxOptions; else standardOptions"></ng-container>

      <ng-template #checkboxOptions>
        <mat-option
          *ngFor="let option of filteredOptions | async | orderBy : orderBy"
          [value]="selectedItems"
          [class.auto-complete-form-checkbox]="isCheckboxMultiselect"
          [matTooltip]="option.name"
          [matTooltipShowDelay]="500"
          matTooltipPosition="right">
          <mat-checkbox
            *ngIf="option.id !== -1"
            [checked]="!!isSelected(option)"
            (change)="toggleSelection(option)"
            (click)="$event.stopPropagation()">
            <span [innerHTML]="getOptionView(option)"></span>
          </mat-checkbox>
          <span
            *ngIf="option.id == -1"
            class="ml-2 autocomplete-option">
            {{ noResultsMessage }}
          </span>
        </mat-option>
      </ng-template>

      <ng-template #standardOptions>
        <mat-option
          *ngFor="let option of filterByAction ? _options : (filteredOptions | async | orderBy : orderBy)"
          [class]="optionClass"
          [value]="option"
          [innerHTML]="getOptionView(option)"
          [disabled]="!enableDefaultOption && option.id === -1">
        </mat-option>
      </ng-template>
    </mat-autocomplete>
    <mat-error *ngIf="control.hasError('required')">This is required</mat-error>
  </mat-form-field>
</form>

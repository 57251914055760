import {
  LibraryCreateDeliverableEntryActionPayload,
  LibraryDuplicateDeliverableEntryActionPayload,
} from '@app/features/library-management/store/models/modals'
import { LibraryDeliverable, LibraryDeliverableEntry } from '@app/features/library-management/store/models/deliverable'
import { createAction, props } from '@ngrx/store'

import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'

export enum LibraryDeliverableActionTypes {
  SET_PAGE = '[Library Deliverable] Set page',

  GET_DELIVERABLE = '[Library Deliverable] Get Deliverable',
  GET_DELIVERABLE_FAIL = '[Library Deliverable] Get Deliverable Fail',
  GET_DELIVERABLE_SUCCESS = '[Library Deliverable] Get Deliverable Success',

  UPDATE_DELIVERABLE = '[Library Deliverable] Update Deliverable',
  UPDATE_DELIVERABLE_FAIL = '[Library Deliverable] Update Deliverable Fail',
  UPDATE_DELIVERABLE_SUCCESS = '[Library Deliverable] Update Deliverable Success',

  DELETE_DELIVERABLE = '[Library Deliverable] Delete Deliverable',
  DELETE_DELIVERABLE_FAIL = '[Library Deliverable] Delete Deliverable Fail',
  DELETE_DELIVERABLE_SUCCESS = '[Library Deliverable] Delete Deliverable Success',

  GET_DELIVERABLE_ENTRY = '[Library Deliverable] Get Deliverable Entry',
  GET_DELIVERABLE_ENTRY_FAIL = '[Library Deliverable] Get Deliverable Entry Fail',
  GET_DELIVERABLE_ENTRY_SUCCESS = '[Library Deliverable] Get Deliverable Entry Success',

  CREATE_DELIVERABLE_ENTRY = '[Library Deliverable] Create Deliverable Entry',
  CREATE_DELIVERABLE_ENTRY_FAIL = '[Library Deliverable] Create Deliverable Entry Fail',
  CREATE_DELIVERABLE_ENTRY_SUCCESS = '[Library Deliverable] Create Deliverable Entry Success',

  DELETE_DELIVERABLE_ENTRY = '[Library Deliverable] Delete Deliverable Entry',
  DELETE_DELIVERABLE_ENTRY_FAIL = '[Library Deliverable] Delete Deliverable Entry Fail',
  DELETE_DELIVERABLE_ENTRY_SUCCESS = '[Library Deliverable] Delete Deliverable Entry Success',

  DUPLICATE_DELIVERABLE_ENTRY = '[Library Deliverable] Duplicate Deliverable Entry',
  DUPLICATE_DELIVERABLE_ENTRY_FAIL = '[Library Deliverable] Duplicate Deliverable Entry Fail',
  DUPLICATE_DELIVERABLE_ENTRY_SUCCESS = '[Library Deliverable] Duplicate Deliverable Entry Success',

  GET_RATECARD = '[Library Deliverable] Get Rate Card',
  GET_RATECARD_FAIL = '[Library Deliverable] Get Rate Card Fail',
  GET_RATECARD_SUCCESS = '[Library Deliverable] Get Rate Card Success',

  TOGGLE_ENTRY_FIXED_COST_EDITABLE = '[Library Deliverable] Toggle Entry Fixed Cost Editable',
  TOGGLE_ENTRY_FIXED_COST_EDITABLE_FAIL = '[Library Deliverable] Toggle Entry Fixed Cost Editable Fail',
  TOGGLE_ENTRY_FIXED_COST_EDITABLE_SUCCESS = '[Library Deliverable] Toggle Entry Fixed Cost Editable Success',
}

const setPage = createAction(LibraryDeliverableActionTypes.SET_PAGE, props<{ page: number }>())

const getDeliverable = createAction(LibraryDeliverableActionTypes.GET_DELIVERABLE, props<{ id: number }>())
const getDeliverableFail = createAction(LibraryDeliverableActionTypes.GET_DELIVERABLE_FAIL, props<{ error: Error }>())
const getDeliverableSuccess = createAction(
  LibraryDeliverableActionTypes.GET_DELIVERABLE_SUCCESS,
  props<{ deliverable: LibraryDeliverable }>()
)

const updateDeliverable = createAction(
  LibraryDeliverableActionTypes.UPDATE_DELIVERABLE,
  props<{ deliverable: LibraryDeliverable }>()
)
const updateDeliverableFail = createAction(
  LibraryDeliverableActionTypes.UPDATE_DELIVERABLE_FAIL,
  props<{ error: Error }>()
)
const updateDeliverableSuccess = createAction(
  LibraryDeliverableActionTypes.UPDATE_DELIVERABLE_SUCCESS,
  props<{ deliverable: LibraryDeliverable }>()
)

const deleteDeliverable = createAction(
  LibraryDeliverableActionTypes.DELETE_DELIVERABLE,
  props<{ deliverable: LibraryDeliverable }>()
)
const deleteDeliverableFail = createAction(
  LibraryDeliverableActionTypes.DELETE_DELIVERABLE_FAIL,
  props<{ error: Error }>()
)
const deleteDeliverableSuccess = createAction(LibraryDeliverableActionTypes.DELETE_DELIVERABLE_SUCCESS)

const getDeliverableEntry = createAction(LibraryDeliverableActionTypes.GET_DELIVERABLE_ENTRY, props<{ id: number }>())
const getDeliverableEntryFail = createAction(
  LibraryDeliverableActionTypes.GET_DELIVERABLE_ENTRY_FAIL,
  props<{ error: Error }>()
)
const getDeliverableEntrySuccess = createAction(
  LibraryDeliverableActionTypes.GET_DELIVERABLE_ENTRY_SUCCESS,
  props<{ entry: LibraryDeliverableEntry }>()
)

const createDeliverableEntry = createAction(
  LibraryDeliverableActionTypes.CREATE_DELIVERABLE_ENTRY,
  props<{ payload: LibraryCreateDeliverableEntryActionPayload }>()
)
const createDeliverableEntryFail = createAction(
  LibraryDeliverableActionTypes.CREATE_DELIVERABLE_ENTRY_FAIL,
  props<{ error: Error }>()
)
const createDeliverableEntrySuccess = createAction(
  LibraryDeliverableActionTypes.CREATE_DELIVERABLE_ENTRY_SUCCESS,
  props<{ id: number }>()
)

const deleteDeliverableEntry = createAction(
  LibraryDeliverableActionTypes.DELETE_DELIVERABLE_ENTRY,
  props<{ deliverableId: number; entryId: number }>()
)
const deleteDeliverableEntryFail = createAction(
  LibraryDeliverableActionTypes.DELETE_DELIVERABLE_ENTRY_FAIL,
  props<{ error: Error }>()
)
const deleteDeliverableEntrySuccess = createAction(
  LibraryDeliverableActionTypes.DELETE_DELIVERABLE_ENTRY_SUCCESS,
  props<{ entryId: number }>()
)

const duplicateDeliverableEntry = createAction(
  LibraryDeliverableActionTypes.DUPLICATE_DELIVERABLE_ENTRY,
  props<{ payload: LibraryDuplicateDeliverableEntryActionPayload }>()
)
const duplicateDeliverableEntryFail = createAction(
  LibraryDeliverableActionTypes.DUPLICATE_DELIVERABLE_ENTRY_FAIL,
  props<{ error: Error }>()
)
const duplicateDeliverableEntrySuccess = createAction(
  LibraryDeliverableActionTypes.DUPLICATE_DELIVERABLE_ENTRY_SUCCESS,
  props<{ id: number }>()
)

const getRateCard = createAction(LibraryDeliverableActionTypes.GET_RATECARD, props<{ id: number; version: number }>())
const getRateCardFail = createAction(LibraryDeliverableActionTypes.GET_RATECARD_FAIL, props<{ error: Error }>())
const getRateCardSuccess = createAction(
  LibraryDeliverableActionTypes.GET_RATECARD_SUCCESS,
  props<{ rateCard: RatecardVersion }>()
)

const toggleEntryFixedCostEditable = createAction(
  LibraryDeliverableActionTypes.TOGGLE_ENTRY_FIXED_COST_EDITABLE,
  props<{ entry: LibraryDeliverableEntry }>()
)
const toggleEntryFixedCostEditableFail = createAction(
  LibraryDeliverableActionTypes.TOGGLE_ENTRY_FIXED_COST_EDITABLE_FAIL,
  props<{ error: Error }>()
)
const toggleEntryFixedCostEditableSuccess = createAction(
  LibraryDeliverableActionTypes.TOGGLE_ENTRY_FIXED_COST_EDITABLE_SUCCESS,
  props<{ id: number }>()
)

export const LibraryDeliverableActions = {
  setPage,
  getDeliverable,
  getDeliverableFail,
  getDeliverableSuccess,
  updateDeliverable,
  updateDeliverableFail,
  updateDeliverableSuccess,
  deleteDeliverable,
  deleteDeliverableFail,
  deleteDeliverableSuccess,
  getDeliverableEntry,
  getDeliverableEntryFail,
  getDeliverableEntrySuccess,
  createDeliverableEntry,
  createDeliverableEntryFail,
  createDeliverableEntrySuccess,
  deleteDeliverableEntry,
  deleteDeliverableEntryFail,
  deleteDeliverableEntrySuccess,
  duplicateDeliverableEntry,
  duplicateDeliverableEntryFail,
  duplicateDeliverableEntrySuccess,
  getRateCard,
  getRateCardFail,
  getRateCardSuccess,
  toggleEntryFixedCostEditable,
  toggleEntryFixedCostEditableFail,
  toggleEntryFixedCostEditableSuccess,
}

<div class="modal-container">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{data.category ? 'Edit' : 'Add'}} Category</h1>
      </div>
      <div class="modal-text mt-2" *ngIf="!data.category">
        <span class="whitespace-pre-line">Create a new Category</span>
      </div>
      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>
  </div>

  <mat-dialog-content aria-label="Add question modal">
    <div class="modal__body flex flex-col gap-8">
      <div class="flex flex-row gap-4 items-baseline">
        <scope-ui-input class="flex-1"
                        [inputLabel]="'Category name'"
                        [inputClass]="'icon-field show-errors'"
                        [required]="true"
                        [initialValue]="data.category?.name"
                        (onInputChange)="name = $event" />
      </div>
      <mat-divider/>
      <div class="flex gap-2 flex-col">
        <h4 class="product-heading-bold">Set a rule for when this question appears<span class="orange"> *</span></h4>
        <scope-ui-checkbox [(ngModel)]="showAlways" (ngModelChange)="showAlways = true; showOnCondition = false"
                           [title]="'Always'" [disableUncheck]="true" />
        <scope-ui-checkbox [ngModel]="showOnCondition" [title]="'On condition'" [disableUncheck]="true"
                           (ngModelChange)="showOnCondition = true; showAlways = false; checkDependencies();" />
        <formula-builder *ngIf="showOnCondition" [scenario]="scenario" [(value)]="condition" [title]="'Set a condition'"
                         [required]="true" [showResult]="false" [control]="conditionControl"
                         (focus)="checkDependencies()" />
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="flex gap-2 justify-end">
    <button mat-flat-button class="ml-8" *ngIf="!(updateLoading$ | async)" (click)="submit()" [disabled]="disableSubmit()">
      <span class='tracking-normal'>{{data.category ? 'Edit' : 'Add'}} Category</span>
    </button>
    <button mat-flat-button class="ml-8" *ngIf="(updateLoading$ | async)">
      <img src="/assets/icons/loading.svg" alt="loading" class="icon-spinner">
    </button>
  </mat-dialog-actions>
</div>

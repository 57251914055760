export enum LibraryItemTypes {
  DELIVERABLE = 'DELIVERABLE',
  COMPONENT = 'COMPONENT',
  THIRD_PARTY_COST = 'THIRD_PARTY_COST',
  FOLDER = 'FOLDER',
}

export const LibraryItemTypesList = [
  LibraryItemTypes.DELIVERABLE,
  LibraryItemTypes.COMPONENT,
  LibraryItemTypes.THIRD_PARTY_COST,
  LibraryItemTypes.FOLDER,
]

<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>Duplicating {{ 'component' | lang }} Entry of {{ 'component' | lang }} '{{ data.entry.name }}' Item</h1>
    </div>
  </div>
</div>

<mat-dialog-content
  aria-label="Generic modal"
  class="modal-container md-dialog-container library map-roles-modal">
  <div class="px-10 pb-7 w-full library-modal-content">
    <ng-container *ngIf="!(isLoadingMapping && isLoadingRateCard$ | async); else loader">
      <div class="mt-2 pb-4">
        <p>
          It seems that '{{ data.entry.name }}' {{ 'component' | lang }} using '{{
            data.entry.rateCardVersion.name
          }}
          (v{{ data.entry.rateCardVersion.version }})' rate-card which has different structure compare to the structure
          of '{{ targetRateCard.name }} (v{{ targetRateCard.version }})' rate-card.
        </p>
        <p class="pt-1">
          The system has already mapped the agency roles between rate-cards. Please check the mappings and remap if
          anything look different. To continue please click Duplicate button.
        </p>
      </div>

      <span class="orange">Please map the {{ 'agency|l' | lang }} roles for {{ 'complexity|l' | lang }}:</span>
      {{ currentComplexity }}

      <map-roles
        class="mt-4 d-block"
        [sourceRateCard]="data.entry.rateCardVersion"
        [ratecard]="targetRateCard"
        [targetRoles]="targetRoles"
        [mappingRoles]="mappingRoles">
      </map-roles>
    </ng-container>
  </div>

  <div class="d-flex d-flex-justify-end px-10 pt-8 pb-4">
    <div class="role-actions">
      <button
        mat-button
        mat-dialog-close>
        Cancel
      </button>
      <button
        class="main-action-button ml-4"
        mat-button
        [disabled]="isLoadingMapping || (isLoadingRateCard$ | async)"
        (click)="nextComplexity()">
        {{ complexityIndex === size(this.mapping) ? 'Duplicate' : 'Next Size' }}
      </button>
    </div>
  </div>
</mat-dialog-content>

<ng-template #loader>
  <div class="map-roles-loader">
    <img
      src="https://s3-eu-west-1.amazonaws.com/scopetrackermediastatic/main_load_spinner.gif"
      alt="" />
  </div>
</ng-template>

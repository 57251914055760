<ng-container *ngIf="showInput">
  <div class="formula-header">
    <div class="formula-header-top">
      <h4 class="product-heading-bold">{{title}}<span *ngIf="required" class="orange"> *</span></h4>
      <div class="formula-header-buttons">
        <button mat-icon-button matTooltip="Fields" [class.selected]="mode == 'Field'" (click)="selectFields()">
          <mat-icon class="material-symbols-rounded">data_table</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Functions" [class.selected]="mode == 'Function'" (click)="selectFunctions()">
          <mat-icon class="material-symbols-rounded">function</mat-icon>
        </button>
      </div>
    </div>
    <div class="flex w-full justify-end" *ngIf="mode == 'Function'">
      <div class="function-options" *ngIf="!functionMode">
        <button mat-flat-button (click)="functionMode = 'Arithmetic'">Arithmetic</button>
        <button mat-flat-button (click)="functionMode = 'Logic'">Logic</button>
        <button mat-flat-button (click)="functionMode = 'Comparison'">Comparison</button>
      </div>
      <div class="function-options mode-options" *ngIf="functionMode">
        <span>{{functionMode}}</span>
        <button mat-flat-button *ngFor="let fn of functions[functionMode]" matTooltip="{{fn.description}}" (click)="insert(fn.value, fn.brackets)">{{fn.value}}</button>
        <button mat-icon-button (click)="functionMode = null">
          <mat-icon class="material-symbols-rounded">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="flex w-full justify-end" *ngIf="mode == 'Field'">
      <div class="function-options overflow-x-auto overflow-y-hidden" *ngIf="!selectedCategory && !showFields">
        <ng-container *ngFor="let category of _scenario.categories">
          <button mat-flat-button *ngIf="category.questions.length" (click)="selectedCategory = category">{{category.name}}</button>
        </ng-container>
        <button mat-flat-button *ngIf="dynamicFields?.length" (click)="selectedCategory = undefined; showFields = true">{{'scope' | lang }} Fields</button>
      </div>
      <div class="function-options mode-options" *ngIf="selectedCategory">
        <span>{{selectedCategory.name}}</span>
        <div class="flex overflow-x-auto overflow-y-hidden gap-1">
          <ng-container *ngFor="let question of selectedCategory.questions">
            <button mat-flat-button *ngIf="question.type !== QuestionType.DELIVERABLE && question.type !== QuestionType.FEE"
                    matTooltip="{{question.fieldLabel}}: {{question.value != undefined ? question.value : 'unset'}}"
                    (click)="insert(question.fieldId)">{{question.fieldId}}</button>
          </ng-container>
        </div>
        <button mat-icon-button (click)="selectedCategory = null">
          <mat-icon class="material-symbols-rounded">close</mat-icon>
        </button>
      </div>
      <div class="function-options mode-options" *ngIf="showFields">
        <span>{{'scope' | lang }} Fields</span>
        <div class="flex overflow-x-auto overflow-y-hidden gap-1">
          <ng-container *ngFor="let field of dynamicFields">
            <button matTooltip="{{field.displayName}}: {{field.value != undefined ? field.value : 'unset'}}"
                    mat-flat-button (click)="insert(field.name)">{{field.name}}</button>
          </ng-container>
        </div>
        <button mat-icon-button (click)="showFields = false">
          <mat-icon class="material-symbols-rounded">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <code-input #formulaInput lang="excel-formula" template="default" rows="1" placeholder=""></code-input>
  <div class="flex w-full items-start justify-between">
    <span class="result" *ngIf="(result !== undefined && result !== null) && showResult">= {{result}}</span>
    <span class="mat-mdc-form-field-error" *ngIf="control.getError('invalid')">{{control.getError('invalid')}}</span>
    <span class="mat-mdc-form-field-error" *ngIf="control.touched && control.getError('required')">This is required</span>
    <div class="function-example" *ngIf="functionExample">
      <mat-icon class="material-symbols-rounded">lightbulb</mat-icon>
      <span>{{functionExample}}</span>
    </div>
  </div>
</ng-container>
<code-input #formulaReadOnly [hidden]="!_showFormula" lang="excel-formula" template="default" rows="1" placeholder=""></code-input>

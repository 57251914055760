import { EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import {
  LibraryManagementItem,
  LibraryManagementItemsState,
} from '@app/features/library-management/store/models/library-items'

import { LibraryManagementFeatureState } from '@app/features/library-management/store/models/state'
import { Pagination } from '@app/core/model/definitions/pagination.interface'
import { Preference } from '@app/core/model/user-preferences.interface'
import { Sort } from '@angular/material/sort'
import { createSelector } from '@ngrx/store'
import { getLibraryManagementFeatureState } from './library-management-feature.selectors'
import { TableColumnPreferences } from '@app/shared/components/ui-components/scope-ui-table/table-column-preferences.type'

export const libraryItemsAdapter: EntityAdapter<LibraryManagementItem> = createEntityAdapter<LibraryManagementItem>({
  selectId: (item) => item.id,
})

export const libraryItemsAdapterSelectors = libraryItemsAdapter.getSelectors()

const getLibraryManagementItemsState = createSelector(
  getLibraryManagementFeatureState,
  (state: LibraryManagementFeatureState): LibraryManagementItemsState => state?.libraryItems
)

const selectIsLoading = createSelector(
  getLibraryManagementItemsState,
  (state: LibraryManagementItemsState): boolean => state?.isLoading || state?.isLoadingColumns
)
const selectIsLoadingArchived = createSelector(
  getLibraryManagementItemsState,
  (state: LibraryManagementItemsState): boolean => state?.isLoadingArchived || state?.isLoadingColumns
)

const selectLibraryItems = createSelector(
  getLibraryManagementItemsState,
  (state: LibraryManagementItemsState): LibraryManagementItem[] => libraryItemsAdapterSelectors.selectAll(state?.list)
)

const selectPagination = createSelector(
  getLibraryManagementItemsState,
  (state: LibraryManagementItemsState): Pagination => state?.pagination
)

const selectSort = createSelector(
  getLibraryManagementItemsState,
  (state: LibraryManagementItemsState): Sort => state?.sort
)

const selectTotal = createSelector(selectPagination, (state: Pagination): number => state?.totalCount)

const selectColumns = createSelector(
  getLibraryManagementItemsState,
  (state: LibraryManagementItemsState): TableColumnPreferences => state?.columns
)
const selectDisplayedColumns = createSelector(
  getLibraryManagementItemsState,
  (state: LibraryManagementItemsState): Preference[] => Object.values(state?.columns)
)

const selectChildColumns = createSelector(
  getLibraryManagementItemsState,
  (state: LibraryManagementItemsState): TableColumnPreferences => state?.childColumns
)

const selectDisplayedChildColumns = createSelector(
  getLibraryManagementItemsState,
  (state: LibraryManagementItemsState): Preference[] => Object.values(state?.childColumns)
)

const selectDialogId = createSelector(
  getLibraryManagementItemsState,
  (state: LibraryManagementItemsState): string => state?.dialogId
)

const selectArchivedItems = createSelector(
  getLibraryManagementItemsState,
  (state: LibraryManagementItemsState): LibraryManagementItem[] =>
    libraryItemsAdapterSelectors.selectAll(state?.archivedList)
)

const selectArchivedPagination = createSelector(
  getLibraryManagementItemsState,
  (state: LibraryManagementItemsState): Pagination => state?.archivedPagination
)

const selectArchivedSort = createSelector(
  getLibraryManagementItemsState,
  (state: LibraryManagementItemsState): Sort => state?.archivedSort
)

const selectArchivedTotal = createSelector(selectArchivedPagination, (state: Pagination): number => state?.totalCount)

export const LibraryManagementItemsSelectors = {
  selectIsLoading,
  selectIsLoadingArchived,
  selectLibraryItems,
  selectPagination,
  selectSort,
  selectTotal,
  selectColumns,
  selectChildColumns,
  selectDisplayedColumns,
  selectDisplayedChildColumns,
  selectDialogId,
  selectArchivedItems,
  selectArchivedPagination,
  selectArchivedSort,
  selectArchivedTotal,
}

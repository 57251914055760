import { createAction, props } from '@ngrx/store';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { Tab } from '@shared/utils/tabs-util.service';
import { ScopeUiTabComponent } from '@shared/components/ui-components/scope-ui-tab/scope-ui-tab.component';
import { Page } from '@app/features/scoping/models/page.model';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { Sort } from '@angular/material/sort';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model';
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model';
import { ComponentModifier } from '@app/features/company-management/models/component-modifier.model';
import { ComponentEntryFilter } from '@core/model/component-entry-filter.model';
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { Discipline } from '@app/features/scope-overview/model/discipline.model';
import { Pagination } from '@core/model/definitions/pagination.interface';
import { ComponentEntryDetailsView } from '@app/features/company-management/models/component-entry-details-view';

export enum CompanyManagementActionTypes {
  GET_OUTPUT_TEMPLATES = '[COMPANY MANAGEMENT CONFIG] Get Output Templates',
  GET_OUTPUT_TEMPLATES_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Get Output Templates Success',
  GET_OUTPUT_TEMPLATES_FAILURE = '[COMPANY MANAGEMENT CONFIG] Get Output Templates Failure',

  SET_GROUP_ID = '[COMPANY MANAGEMENT CONFIG] Set Group Id',
  TAB_CHANGE_START = '[COMPANY MANAGEMENT CONFIG] Tab Change Start',

  GET_TASKS = '[COMPANY MANAGEMENT CONFIG] Get Tasks',
  GET_TASKS_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Get Tasks Success',
  GET_TASKS_FAIL = '[COMPANY MANAGEMENT CONFIG] Get Tasks Fail',

  GET_ALL_SCENARIOS = '[COMPANY MANAGEMENT CONFIG] Load Scenarios',
  GET_ALL_SCENARIOS_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Load Scenarios Success',
  GET_ALL_SCENARIOS_FAIL = '[COMPANY MANAGEMENT CONFIG] Load Scenarios Fail',
  CREATE_SCENARIO = '[COMPANY MANAGEMENT CONFIG] Create Scenario',
  CREATE_SCENARIO_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Create Scenario Success',
  CREATE_SCENARIO_FAIL = '[COMPANY MANAGEMENT CONFIG] Create Scenario Fail',
  DELETE_SCENARIO = '[COMPANY MANAGEMENT CONFIG] Delete Scenario',
  DELETE_SCENARIO_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Success',
  DELETE_SCENARIO_FAIL = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Fail',
  UPDATE_SCENARIO = '[COMPANY MANAGEMENT CONFIG] Update Scenario',
  UPDATE_SCENARIO_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Update Scenario Success',
  UPDATE_SCENARIO_FAIL = '[COMPANY MANAGEMENT CONFIG] Update Scenario Fail',
  GET_SCENARIO = '[COMPANY MANAGEMENT CONFIG] Load Scenario',
  GET_SCENARIO_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Load Scenario Success',
  GET_SCENARIO_FAIL = '[COMPANY MANAGEMENT CONFIG] Load Scenario Fail',

  CREATE_SCENARIO_CATEGORY = '[COMPANY MANAGEMENT CONFIG] Create Scenario Category',
  CREATE_SCENARIO_CATEGORY_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Create Scenario Category Success',
  CREATE_SCENARIO_CATEGORY_FAIL = '[COMPANY MANAGEMENT CONFIG] Create Scenario Category Fail',
  DELETE_SCENARIO_CATEGORY = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Category',
  DELETE_SCENARIO_CATEGORY_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Category Success',
  DELETE_SCENARIO_CATEGORY_FAIL = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Category Fail',
  REORDER_SCENARIO_CATEGORIES = '[COMPANY MANAGEMENT CONFIG] Reorder Scenario Categories',
  REORDER_SCENARIO_CATEGORIES_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Reorder Scenario Categories Success',
  REORDER_SCENARIO_CATEGORIES_FAIL = '[COMPANY MANAGEMENT CONFIG] Reorder Scenario Categories Fail',
  UPDATE_SCENARIO_CATEGORY = '[COMPANY MANAGEMENT CONFIG] Update Scenario Category',
  UPDATE_SCENARIO_CATEGORY_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Update Scenario Category Success',
  UPDATE_SCENARIO_CATEGORY_FAIL = '[COMPANY MANAGEMENT CONFIG] Update Scenario Category Fail',

  GET_ALL_SCENARIO_QUESTIONS = '[COMPANY MANAGEMENT CONFIG] Load Scenario Questions',
  GET_ALL_SCENARIO_QUESTIONS_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Load Scenario Questions Success',
  GET_ALL_SCENARIO_QUESTIONS_FAIL = '[COMPANY MANAGEMENT CONFIG] Load Scenario Questions Fail',
  CREATE_SCENARIO_QUESTION = '[COMPANY MANAGEMENT CONFIG] Create Scenario Question',
  CREATE_SCENARIO_QUESTION_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Create Scenario Question Success',
  CREATE_SCENARIO_QUESTION_FAIL = '[COMPANY MANAGEMENT CONFIG] Create Scenario Question Fail',
  DELETE_SCENARIO_QUESTION = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Question',
  DELETE_SCENARIO_QUESTION_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Question Success',
  DELETE_SCENARIO_QUESTION_FAIL = '[COMPANY MANAGEMENT CONFIG] Delete Scenario Question Fail',
  REORDER_SCENARIO_QUESTIONS = '[COMPANY MANAGEMENT CONFIG] Reorder Scenario Questions',
  REORDER_SCENARIO_QUESTIONS_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Reorder Scenario Questions Success',
  REORDER_SCENARIO_QUESTIONS_FAIL = '[COMPANY MANAGEMENT CONFIG] Reorder Scenario Questions Fail',
  UPDATE_SCENARIO_QUESTION = '[COMPANY MANAGEMENT CONFIG] Update Scenario Question',
  UPDATE_SCENARIO_QUESTION_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Update Scenario Question Success',
  UPDATE_SCENARIO_QUESTION_FAIL = '[COMPANY MANAGEMENT CONFIG] Update Scenario Question Fail',

  GET_COMPONENT_MODIFIERS = '[COMPANY MANAGEMENT CONFIG] Get Component Modifiers',
  GET_COMPONENT_MODIFIERS_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Get Component Modifiers Success',
  GET_COMPONENT_MODIFIERS_FAIL = '[COMPANY MANAGEMENT CONFIG] Get Component Modifiers Fail',

  GET_COMPONENT_MODIFIER = '[COMPANY MANAGEMENT CONFIG] Get Component Modifier',
  GET_COMPONENT_MODIFIER_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Get Component Modifier Success',
  GET_COMPONENT_MODIFIER_FAIL = '[COMPANY MANAGEMENT CONFIG] Get Component Modifier Fail',

  DELETE_COMPONENT_MODIFIER_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Delete Component Modifier Success',

  CREATE_COMPONENT_MODIFIER = '[COMPANY MANAGEMENT CONFIG] Create Component Modifier',
  CREATE_COMPONENT_MODIFIER_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Create Component Modifier Success',
  CREATE_COMPONENT_MODIFIER_FAIL = '[COMPANY MANAGEMENT CONFIG] Create Component Modifier Fail',

  UPDATE_COMPONENT_MODIFIER = '[COMPANY MANAGEMENT CONFIG] Update Component Modifier',
  UPDATE_COMPONENT_MODIFIER_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Update Component Modifier Success',
  UPDATE_COMPONENT_MODIFIER_FAIL = '[COMPANY MANAGEMENT CONFIG] Update Component Modifier Fail',

  ASSIGN_COMPONENT_MODIFIER = '[COMPANY MANAGEMENT CONFIG] Assign Component Modifier',
  ASSIGN_COMPONENT_MODIFIER_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Assign Component Modifier Success',
  ASSIGN_COMPONENT_MODIFIER_FAIL = '[COMPANY MANAGEMENT CONFIG] Assign Component Modifier Fail',

  SEARCH_COMPONENT_ENTRIES = '[COMPANY MANAGEMENT CONFIG] Search Component Entries',
  SEARCH_COMPONENT_ENTRIES_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Search Component Entries Success',
  SEARCH_COMPONENT_ENTRIES_FAIL = '[COMPANY MANAGEMENT CONFIG] Search Component Entries Fail',

  GET_RATECARDS = '[COMPANY MANAGEMENT CONFIG] Get Ratecards',
  GET_RATECARDS_FAIL = '[COMPANY MANAGEMENT CONFIG] Get Ratecards Fail',
  GET_RATECARDS_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Get Ratecards Success',

  GET_DISCIPLINES = '[COMPANY MANAGEMENT CONFIG] Get Disciplines',
  GET_DISCIPLINES_FAIL = '[COMPANY MANAGEMENT CONFIG] Get Disciplines Fail',
  GET_DISCIPLINES_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Get Disciplines Success',
}

const getOutputTemplates = createAction(CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES);
const getOutputTemplatesSuccess = createAction(
  CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES_SUCCESS,
  props<{ outputTemplates: OutputTemplate[] }>()
);
const getOutputTemplatesFail = createAction(CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES_FAILURE, props<{ error: Error }>());

const setGroupId = createAction(CompanyManagementActionTypes.SET_GROUP_ID, props<{ groupId: number }>());

const tabChangeStart = createAction(CompanyManagementActionTypes.TAB_CHANGE_START, props<{ additionalCtx: any, tabs: Tab | ScopeUiTabComponent }>());

const getTasks = createAction(CompanyManagementActionTypes.GET_TASKS, props<{ page: number, searchText?: string, sort: Sort }>());
const getTasksSuccess = createAction(CompanyManagementActionTypes.GET_TASKS_SUCCESS, props<{ tasks: Page<Deliverable> }>());
const getTasksFail = createAction(CompanyManagementActionTypes.GET_TASKS_FAIL, props<{ error: Error }>());

const getScenarios = createAction(CompanyManagementActionTypes.GET_ALL_SCENARIOS);
const getScenariosSuccess = createAction(CompanyManagementActionTypes.GET_ALL_SCENARIOS_SUCCESS, props<{ scenarios: Scenario[] }>());
const getScenariosFail = createAction(CompanyManagementActionTypes.GET_ALL_SCENARIOS_FAIL , props<{ error: any }>());
const createScenario = createAction(CompanyManagementActionTypes.CREATE_SCENARIO, props<{ scenario: Scenario }>());
const createScenarioSuccess = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_SUCCESS, props<{ scenario: Scenario }>());
const createScenarioFail = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_FAIL, props<{ error: any }>());
const deleteScenario = createAction(CompanyManagementActionTypes.DELETE_SCENARIO, props<{ id: number }>());
const deleteScenarioSuccess = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_SUCCESS, props<{ id: number }>());
const deleteScenarioFail = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_FAIL, props<{ error: any }>());
const updateScenario = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO, props<{ id: number , scenario: Scenario}>());
const updateScenarioSuccess = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_SUCCESS);
const updateScenarioFail = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_FAIL, props<{ error: any }>());
const getScenario = createAction(CompanyManagementActionTypes.GET_SCENARIO, props<{ scenarioId: number }>());
const getScenarioSuccess = createAction(CompanyManagementActionTypes.GET_SCENARIO_SUCCESS, props<{ scenario: Scenario }>());
const getScenarioFail = createAction(CompanyManagementActionTypes.GET_SCENARIO_FAIL, props<{ error: any }>());

const createScenarioCategory = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_CATEGORY, props<{ scenarioId: number, category: ScenarioCategory }>());
const createScenarioCategorySuccess = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_CATEGORY_SUCCESS, props<{ category: ScenarioCategory }>());
const createScenarioCategoryFail = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_CATEGORY_FAIL, props<{ error: any }>());
const deleteScenarioCategory = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_CATEGORY, props<{ scenarioId: number, categoryId: number }>());
const deleteScenarioCategorySuccess = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_CATEGORY_SUCCESS, props<{ categoryId: number }>());
const deleteScenarioCategoryFail = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_CATEGORY_FAIL, props<{ error: any }>());
const reorderScenarioCategories = createAction(CompanyManagementActionTypes.REORDER_SCENARIO_CATEGORIES, props<{ scenarioId: number, orderedCategoryIds: number[] }>());
const reorderScenarioCategoriesSuccess = createAction(CompanyManagementActionTypes.REORDER_SCENARIO_CATEGORIES_SUCCESS);
const reorderScenarioCategoriesFail = createAction(CompanyManagementActionTypes.REORDER_SCENARIO_CATEGORIES_FAIL, props<{ error: any }>());
const updateScenarioCategory = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_CATEGORY, props<{ scenarioId: number ,categoryId: number, category: ScenarioCategory}>());
const updateScenarioCategorySuccess = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_CATEGORY_SUCCESS, props<{ category: ScenarioCategory }>());
const updateScenarioCategoryFail = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_CATEGORY_FAIL, props<{ error: any }>());

const getScenarioQuestions = createAction(CompanyManagementActionTypes.GET_ALL_SCENARIO_QUESTIONS, props<{ scenarioId: number, categoryId: number }>());
const getScenarioQuestionsSuccess = createAction(CompanyManagementActionTypes.GET_ALL_SCENARIO_QUESTIONS_SUCCESS, props<{ categoryId: number, scenarioQuestions: ScenarioQuestion[] }>());
const getScenarioQuestionsFail = createAction(CompanyManagementActionTypes.GET_ALL_SCENARIO_QUESTIONS_FAIL, props<{ error: any }>());
const createScenarioQuestion = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_QUESTION, props<{ scenarioId: number, categoryId: number, question: ScenarioQuestion }>());
const createScenarioQuestionSuccess = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_QUESTION_SUCCESS, props<{ categoryId: number, question: ScenarioQuestion }>());
const createScenarioQuestionFail = createAction(CompanyManagementActionTypes.CREATE_SCENARIO_QUESTION_FAIL, props<{ error: any }>());
const deleteScenarioQuestion = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_QUESTION, props<{ scenarioId: number, categoryId: number, questionId: number }>());
const deleteScenarioQuestionSuccess = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_QUESTION_SUCCESS, props<{ categoryId: number, questionId: number }>());
const deleteScenarioQuestionFail = createAction(CompanyManagementActionTypes.DELETE_SCENARIO_QUESTION_FAIL, props<{ error: any }>());
const reorderScenarioQuestions = createAction(CompanyManagementActionTypes.REORDER_SCENARIO_QUESTIONS, props<{ scenarioId: number, categoryId: number, orderedQuestionIds: number[] }>());
const reorderScenarioQuestionsSuccess = createAction(CompanyManagementActionTypes.REORDER_SCENARIO_QUESTIONS_SUCCESS);
const reorderScenarioQuestionsFail = createAction(CompanyManagementActionTypes.REORDER_SCENARIO_QUESTIONS_FAIL, props<{ error: any }>());
const updateScenarioQuestion = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_QUESTION, props<{ scenarioId: number ,categoryId: number, questionId: number, question: ScenarioQuestion}>());
const updateScenarioQuestionSuccess = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_QUESTION_SUCCESS, props<{ categoryId: number, question: ScenarioQuestion }>());
const updateScenarioQuestionFail = createAction(CompanyManagementActionTypes.UPDATE_SCENARIO_QUESTION_FAIL, props<{ error: any }>());

const getComponentModifiers = createAction(CompanyManagementActionTypes.GET_COMPONENT_MODIFIERS, props<{ scenarioId: number }>());
const getComponentModifiersSuccess = createAction(CompanyManagementActionTypes.GET_COMPONENT_MODIFIERS_SUCCESS, props<{ componentModifiers: ComponentModifier[] }>());
const getComponentModifiersFail = createAction(CompanyManagementActionTypes.GET_COMPONENT_MODIFIERS_FAIL, props<{ error: Error }>());

const getComponentModifier = createAction(CompanyManagementActionTypes.GET_COMPONENT_MODIFIER, props<{ scenarioId: number, componentModifierId: number }>());
const getComponentModifierSuccess = createAction(CompanyManagementActionTypes.GET_COMPONENT_MODIFIER_SUCCESS, props<{ componentModifier: ComponentModifier }>());
const getComponentModifierFail = createAction(CompanyManagementActionTypes.GET_COMPONENT_MODIFIER_FAIL, props<{ error: Error }>());

const deleteComponentModifierSuccess = createAction(CompanyManagementActionTypes.DELETE_COMPONENT_MODIFIER_SUCCESS, props<{ componentModifierId: number }>());

const createComponentModifier = createAction(CompanyManagementActionTypes.CREATE_COMPONENT_MODIFIER, props<{ scenarioId: number, componentModifier: any }>());
const createComponentModifierSuccess = createAction(CompanyManagementActionTypes.CREATE_COMPONENT_MODIFIER_SUCCESS, props<{ componentModifier: ComponentModifier }>());
const createComponentModifierFail = createAction(CompanyManagementActionTypes.CREATE_COMPONENT_MODIFIER_FAIL, props<{ error: any }>());

const updateComponentModifier = createAction(CompanyManagementActionTypes.UPDATE_COMPONENT_MODIFIER, props<{ scenarioId: number, componentModifier: any }>());
const updateComponentModifierSuccess = createAction(CompanyManagementActionTypes.UPDATE_COMPONENT_MODIFIER_SUCCESS, props<{ componentModifier: ComponentModifier }>());
const updateComponentModifierFail = createAction(CompanyManagementActionTypes.UPDATE_COMPONENT_MODIFIER_FAIL, props<{ error: any }>());

const assignComponentModifier = createAction(CompanyManagementActionTypes.ASSIGN_COMPONENT_MODIFIER, props<{ scenarioId: number, componentModifierId: number, assignComplexities: any, unassignComplexities: number[] }>());
const assignComponentModifierSuccess = createAction(CompanyManagementActionTypes.ASSIGN_COMPONENT_MODIFIER_SUCCESS, props<{ scenarioId: number, componentModifierId: number }>());
const assignComponentModifierFail = createAction(CompanyManagementActionTypes.ASSIGN_COMPONENT_MODIFIER_FAIL, props<{ error: any }>());

const searchComponentEntries = createAction(CompanyManagementActionTypes.SEARCH_COMPONENT_ENTRIES, props<{ filter: ComponentEntryFilter, pageInfo: Pagination }>());
const searchComponentEntriesSuccess = createAction(CompanyManagementActionTypes.SEARCH_COMPONENT_ENTRIES_SUCCESS, props<{ componentEntries: Page<ComponentEntryDetailsView>, filter: ComponentEntryFilter }>());
const searchComponentEntriesFail = createAction(CompanyManagementActionTypes.SEARCH_COMPONENT_ENTRIES_FAIL, props<{ error: Error }>());

const getRateCards = createAction(CompanyManagementActionTypes.GET_RATECARDS)
const getRateCardsFail = createAction(CompanyManagementActionTypes.GET_RATECARDS_FAIL, props<{ error: Error }>())
const getRateCardsSuccess = createAction(
  CompanyManagementActionTypes.GET_RATECARDS_SUCCESS,
  props<{ rateCards: RatecardVersion[] }>()
)

const getDisciplines = createAction(CompanyManagementActionTypes.GET_DISCIPLINES)
const getDisciplinesFail = createAction(CompanyManagementActionTypes.GET_DISCIPLINES_FAIL, props<{ error: Error }>())
const getDisciplinesSuccess = createAction(
  CompanyManagementActionTypes.GET_DISCIPLINES_SUCCESS,
  props<{ disciplines: Discipline[] }>()
)

export const CompanyManagementActions = {
  getOutputTemplates,
  getOutputTemplatesSuccess,
  getOutputTemplatesFail,
  setGroupId,
  tabChangeStart,
  getTasks,
  getTasksSuccess,
  getTasksFail,
  getScenarios,
  getScenariosSuccess,
  getScenariosFail,
  createScenario,
  createScenarioSuccess,
  createScenarioFail,
  deleteScenario,
  deleteScenarioSuccess,
  deleteScenarioFail,
  updateScenario,
  updateScenarioSuccess,
  updateScenarioFail,
  getScenario: getScenario,
  getScenarioSuccess: getScenarioSuccess,
  getScenarioFail: getScenarioFail,
  createScenarioCategory,
  createScenarioCategorySuccess,
  createScenarioCategoryFail,
  deleteScenarioCategory,
  deleteScenarioCategorySuccess,
  deleteScenarioCategoryFail,
  reorderScenarioCategories,
  reorderScenarioCategoriesSuccess,
  reorderScenarioCategoriesFail,
  updateScenarioCategory,
  updateScenarioCategorySuccess,
  updateScenarioCategoryFail,
  getScenarioQuestions,
  getScenarioQuestionsSuccess,
  getScenarioQuestionsFail,
  createScenarioQuestion,
  createScenarioQuestionSuccess,
  createScenarioQuestionFail,
  deleteScenarioQuestion,
  deleteScenarioQuestionSuccess,
  deleteScenarioQuestionFail,
  reorderScenarioQuestions,
  reorderScenarioQuestionsSuccess,
  reorderScenarioQuestionsFail,
  updateScenarioQuestion,
  updateScenarioQuestionSuccess,
  updateScenarioQuestionFail,
  getComponentModifiers,
  getComponentModifiersSuccess,
  getComponentModifiersFail,
  getComponentModifier,
  getComponentModifierSuccess,
  getComponentModifierFail,
  deleteComponentModifierSuccess,
  createComponentModifier,
  createComponentModifierSuccess,
  createComponentModifierFail,
  updateComponentModifier,
  updateComponentModifierSuccess,
  updateComponentModifierFail,
  assignComponentModifier,
  assignComponentModifierSuccess,
  assignComponentModifierFail,
  searchComponentEntries,
  searchComponentEntriesSuccess,
  searchComponentEntriesFail,
  getRateCards,
  getRateCardsSuccess,
  getRateCardsFail,
  getDisciplines,
  getDisciplinesSuccess,
  getDisciplinesFail,
}

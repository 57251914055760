import { LibraryDiscipline } from './library-discipline.model'
import { Money } from '@app/features/scope-overview/model/money.model'

export class LibraryRateCardRole {
  id: number
  name: string
  ratecardRoleScopeMarkRoleMappings: LibraryRateCardRoleMapping[]
  disciplines: LibraryDiscipline[]
  rate: Money
  cost: Money
}

export interface LibraryRateCardRoleMapping {
  id: number
  scopeMarkRoleId: number
  disciplineId: number
  distributionPercentage: number
}

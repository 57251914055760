<form *ngIf="singleDatePicker && !inlineDatePicker">
  <mat-form-field subscriptSizing="dynamic" [floatLabel]="inputLabel ? 'auto':  'always'">
    <mat-label>{{ inputLabel }}</mat-label>
    <input
      type="text"
      ngxDaterangepickerMd
      matInput
      [formControl]="dateControl"
      [disabled]="isDisabled"
      [autoApply]="true"
      [lockStartDate]="false"
      [locale]="locale"
      [singleDatePicker]="true"
      [minDate]="_minDateValue"
      [maxDate]="_maxDateValue"
      [placeholder]="inputPlaceholder"
      (change)="onChange($event)"
      [opens]="opens"
      [drops]="drops"/>
    <mat-error *ngIf="dateControl.hasError('required')">This is required</mat-error>
  </mat-form-field>
</form>
<ngx-daterangepicker-material
  *ngIf="singleDatePicker && inlineDatePicker"
  [autoApply]="true"
  [locale]="locale"
  [minDate]="_minDateValue"
  [maxDate]="_maxDateValue"
  [singleDatePicker]="true"
  (choosedDate)="onChange($event)">
</ngx-daterangepicker-material>
<ngx-daterangepicker-material
  *ngIf="!singleDatePicker"
  [autoApply]="true"
  [locale]="locale"
  [minDate]="_minDateValue"
  [maxDate]="_maxDateValue"
  [isCustomDate]="isCustomDate"
  (choosedDate)="onSelectedChange($event)">
</ngx-daterangepicker-material>

import { LibraryManagementApiMappingService, LibraryManagementService } from '@app/features/library-management/services'
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@app/shared/utils/snackbar.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs'
import { LibraryCreateComponentActionTypes, LibraryCreateComponentActions, LibraryManagementActions } from '../actions'

import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { AuthService } from '@app/core/service/auth.service'
import { LanguageService } from '@app/core/service/language.service'
import { LibraryItemName } from '@app/features/library-management/store/enums'
import { LibraryComponent } from '@app/features/library-management/store/models/component'
import { Store } from '@ngrx/store'
import { LibraryFormError } from '../enums'
import { LibraryManagementSelectors } from '../selectors'

@Injectable()
export class LibraryCreateComponentEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private snackbarService: SnackbarService,
    private libraryManagementService: LibraryManagementService,
    private libraryApiMappingService: LibraryManagementApiMappingService,
    private authService: AuthService,
    private router: Router,
    private lang: LanguageService,
    public dialog: MatDialog
  ) {}

  navigateToComponentOverview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryCreateComponentActionTypes.NAVIGATE_TO_COMPONENT_OVERVIEW),
        map(({ id, entryId }) => {
          this.router.navigate([entryId ? `library/component/${id}/edit/${entryId}` : `library/component/${id}`])
        })
      ),
    { dispatch: false }
  )

  createComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryCreateComponentActionTypes.CREATE_COMPONENT),
      withLatestFrom(
        this.store$.select(LibraryManagementSelectors.selectDialogId),
        this.store$.select(LibraryManagementSelectors.selectFolder)
      ),
      switchMap(([component, dialogId, folder]) =>
        this.libraryManagementService
          .createComponent(
            this.libraryApiMappingService.mapComponentToCreateApi(
              component,
              this.authService.loggedInUser.company?.currency,
              folder
            )
          )
          .pipe(
            switchMap((response: LibraryComponent) => [
              LibraryManagementActions.closeActiveModal(),
              LibraryCreateComponentActions.createComponentSuccess({ component: response }),
              LibraryCreateComponentActions.navigateToComponentOverview({
                id: response.id,
                entryId: response.libraryComponentEntries?.[0]?.id,
              }),
            ]),
            catchError((error: any) => {
              if (error.status === 409) {
                this.setAlreadyExistsError(dialogId)
                return of(LibraryCreateComponentActions.createComponentFail({ error }))
              }

              console.error(error)
              this.snackbarService.showSnackbar(
                'An error occurred during creating Component Template',
                SNACKBAR_LENGTH_LONG,
                SnackbarEventType.ERROR
              )
              return of(LibraryCreateComponentActions.createComponentFail({ error }))
            })
          )
      )
    )
  )

  setAlreadyExistsError(dialogId: string) {
    let modalForm = this.dialog.getDialogById(dialogId)?.componentInstance.modalForm
    modalForm.get('name').setErrors({
      [LibraryFormError.ALREADY_EXISTS]: `${this.lang.get(LibraryItemName.COMPONENT)} with this name already exists`,
    })
    modalForm.markAllAsTouched()
  }
}

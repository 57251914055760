import {
  LibraryDeliverableService,
  LibraryManagementApiMappingService,
  LibraryManagementItemsService,
  LibraryManagementMappingService,
} from '@app/features/library-management/services'
import { LibraryDeliverableActions, LibraryDeliverableSelectors } from '@app/features/library-management/store'
import {
  DuplicateDeliverableEntryAction,
  LibraryDeliverable,
  LibraryDeliverableEntry,
} from '@app/features/library-management/store/models/deliverable'
import { SNACKBAR_LENGTH_SHORT, SnackbarEventType, SnackbarService } from '@app/shared/utils/snackbar.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs'

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { LanguageService } from '@app/core/service/language.service'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { Store } from '@ngrx/store'
import { plainToInstance } from 'class-transformer'

@Injectable()
export class LibraryDeliverableEffects {
  constructor(
    private store$: Store,
    private actions$: Actions,
    private router: Router,
    private snackbarService: SnackbarService,
    private lang: LanguageService,
    private libraryDeliverableService: LibraryDeliverableService,
    private libraryManagementItemsService: LibraryManagementItemsService,
    private libraryApiMappingService: LibraryManagementApiMappingService,
    private libraryMappingService: LibraryManagementMappingService
  ) {}

  getDeliverable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryDeliverableActions.getDeliverable),
      filter(({ id }) => !!id),
      switchMap(({ id }) =>
        this.libraryDeliverableService.getDeliverable(id).pipe(
          map((response) =>
            LibraryDeliverableActions.getDeliverableSuccess({
              deliverable: plainToInstance(LibraryDeliverable, {
                ...response,
                libraryDeliverableEntrySet: this.libraryMappingService.mapDeliverableEntries(
                  response.libraryDeliverableEntrySet
                ),
              }),
            })
          ),
          catchError((error: any) => of(LibraryDeliverableActions.getDeliverableFail({ error })))
        )
      )
    )
  )

  updateDeliverable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryDeliverableActions.updateDeliverable),
      switchMap(({ deliverable }) =>
        this.libraryDeliverableService
          .updateDeliverable(this.libraryApiMappingService.mapDeliverableToApi(deliverable))
          .pipe(
            map((response) =>
              LibraryDeliverableActions.updateDeliverableSuccess({
                deliverable: plainToInstance(LibraryDeliverable, response),
              })
            )
          )
      ),
      catchError((error) => of(LibraryDeliverableActions.updateDeliverableFail(error)))
    )
  })

  deleteDeliverable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryDeliverableActions.deleteDeliverable),
      switchMap(({ deliverable }) =>
        this.libraryManagementItemsService
          .deleteLibraryItem(deliverable.id)
          .pipe(map(() => LibraryDeliverableActions.deleteDeliverableSuccess()))
      ),
      catchError((error) => of(LibraryDeliverableActions.deleteDeliverableFail(error)))
    )
  )

  getDeliverableEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryDeliverableActions.getDeliverableEntry),
      withLatestFrom(this.store$.select(LibraryDeliverableSelectors.selectEntriesMap)),
      filter(([{ id }, map]) => id && !map[id]?.components),
      switchMap(([{ id }]) =>
        this.libraryDeliverableService.getDeliverableEntry(id).pipe(
          map((entry) =>
            LibraryDeliverableActions.getDeliverableEntrySuccess({
              entry: this.libraryMappingService.mapDeliverableEntry(entry),
            })
          ),
          catchError((error: any) => of(LibraryDeliverableActions.getDeliverableEntryFail({ error })))
        )
      )
    )
  )

  createDeliverableEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryDeliverableActions.createDeliverableEntry),
      withLatestFrom(this.store$.select(LibraryDeliverableSelectors.selectDeliverable)),
      switchMap(([{ payload }, deliverable]) =>
        this.libraryDeliverableService
          .createDeliverableEntry(deliverable.id, this.libraryApiMappingService.mapDeliverableEntryToApi(payload))
          .pipe(
            map(({ id }) => LibraryDeliverableActions.createDeliverableEntrySuccess({ id })),
            catchError((error: any) => of(LibraryDeliverableActions.createDeliverableEntryFail({ error })))
          )
      )
    )
  )

  deleteDeliverableEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryDeliverableActions.deleteDeliverableEntry),
      switchMap(({ deliverableId, entryId }) =>
        this.libraryDeliverableService.deleteDeliverableEntry(deliverableId, entryId).pipe(
          map(() => {
            this.snackbarService.showSnackbar(
              `${this.lang.get('deliverable')} Entry was successfully deleted`,
              SNACKBAR_LENGTH_SHORT,
              SnackbarEventType.SUCCESS
            )

            return LibraryDeliverableActions.deleteDeliverableEntrySuccess({ entryId })
          }),
          catchError((error: any) => of(LibraryDeliverableActions.deleteDeliverableEntryFail({ error })))
        )
      )
    )
  )

  duplicateDeliverableEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryDeliverableActions.duplicateDeliverableEntry),
      withLatestFrom(this.store$.select(LibraryDeliverableSelectors.selectDeliverable)),
      switchMap(([{ payload }, deliverable]: [DuplicateDeliverableEntryAction, LibraryDeliverable]) =>
        this.libraryDeliverableService
          .duplicateDeliverableEntry(
            deliverable.id,
            payload.entry.id,
            payload.rateCard.id,
            this.libraryApiMappingService.mapDeliverableEntryDuplicatePayloadApi(payload)
          )
          .pipe(
            map(({ id }) => LibraryDeliverableActions.duplicateDeliverableEntrySuccess({ id })),
            catchError((error: any) => of(LibraryDeliverableActions.duplicateDeliverableEntryFail({ error })))
          )
      )
    )
  )

  getRateCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryDeliverableActions.getRateCard),
      switchMap(({ id, version }) =>
        this.libraryDeliverableService.getRateCard(id, version).pipe(
          map((rateCard) =>
            LibraryDeliverableActions.getRateCardSuccess({ rateCard: plainToInstance(RatecardVersion, rateCard) })
          ),
          catchError((error: any) => of(LibraryDeliverableActions.getRateCardFail({ error })))
        )
      )
    )
  )

  toggleEntryFixedCostEditable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryDeliverableActions.toggleEntryFixedCostEditable),
      switchMap(({ entry }) =>
        this.libraryDeliverableService.toggleFixedCostEditableStatus(entry).pipe(
          map(({ id, name }: LibraryDeliverableEntry) => {
            this.snackbarService.showSnackbar(
              `Item ${name} successfully changed editable status`,
              SNACKBAR_LENGTH_SHORT,
              SnackbarEventType.SUCCESS
            )

            return LibraryDeliverableActions.toggleEntryFixedCostEditableSuccess({ id })
          }),
          catchError((error: any) => of(LibraryDeliverableActions.toggleEntryFixedCostEditableFail({ error })))
        )
      )
    )
  )

  navigateToLibrary$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryDeliverableActions.deleteDeliverableSuccess),
        map(() => this.router.navigate(['library']))
      ),
    { dispatch: false }
  )

  navigateToDeliverableEntry$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          LibraryDeliverableActions.createDeliverableEntrySuccess,
          LibraryDeliverableActions.duplicateDeliverableEntrySuccess
        ),
        withLatestFrom(this.store$.select(LibraryDeliverableSelectors.selectDeliverable)),
        map(([{ id }, deliverable]) => this.router.navigate([`library/deliverable/${deliverable.id}/edit/${id}`]))
      ),
    { dispatch: false }
  )
}

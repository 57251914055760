<div
  mat-dialog-title
  class="cascade-changes-header">
  <div class="modal__header">
    <div class="modal__title">
      <h1>Cascade Changes</h1>
    </div>
    <div class="mt-2">
      <span>
        Would you like to cascade your changes to all Library Deliverable entries using this
        {{ data.itemType | lang }}?
      </span>
    </div>
  </div>
</div>

<mat-dialog-content
  aria-label="Generic modal"
  class="modal-container md-dialog-container library">
  <div class="d-flex d-flex-justify-end px-10 pt-8 pb-4">
    <div class="cascade-changes-actions">
      <button
        class="main-action-button"
        mat-button
        (click)="close(true)"
        cdkFocusInitial>
        Yes, Cascade Changes
      </button>
      <button
        class="btn--outline"
        (click)="close(false)">
        No, keep Deliverables as they are
      </button>
    </div>
  </div>
</mat-dialog-content>

import {
  LibraryDuplicateEntryPayload,
  LibraryManagementFilters,
  LibraryRateCard,
} from '@app/features/library-management/store'
import {
  LibraryComplexitiesMap,
  LibraryComponent,
  LibraryComponentEntry,
  LibraryComponentEntryPayload,
  LibraryComponentEntryRequest,
  LibraryComponentEntryUpdateRequest,
  LibraryDuplicateEntryRequest,
} from '@app/features/library-management/store/models/component'
import {
  LibraryDeliverable,
  LibraryDeliverableEntryRequest,
} from '@app/features/library-management/store/models/deliverable'
import {
  LibraryCreateComponentActionPayload,
  LibraryCreateDeliverableEntryActionPayload,
  LibraryCreateTPCActionPayload,
  LibraryDuplicateDeliverableEntryActionPayload,
} from '@app/features/library-management/store/models/modals'

import { Injectable } from '@angular/core'
import { ComplexitiesList } from '@app/core/model/enums/complexity.enum'
import { TimePeriod } from '@app/core/model/enums/time-period.enum'
import { GenericFilterValue } from '@app/core/model/filter-option.interface'
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'
import { LibraryItemType } from '@app/features/library-management/store/enums'
import { LibraryManagementFolder } from '@app/features/library-management/store/models/library-folders'
import { LibraryManagementItem } from '@app/features/library-management/store/models/library-items'
import { getPlainHttpParams } from '@app/shared/utils/utils'
import { plainToInstance } from 'class-transformer'
import { LibraryManagementMappingService } from './library-management-mapping.service'

@Injectable({ providedIn: 'root' })
export class LibraryManagementApiMappingService {
  constructor(private libraryMappingService: LibraryManagementMappingService) {}

  mapLibraryItemToApi = (item: LibraryManagementItem) => ({
    id: item.id,
    name: item.name,
    itemType: item.itemType,
  })

  mapLibraryTcpToApi = (item: LibraryManagementItem) => ({
    id: item.id,
    name: item.name,
    itemType: item.itemType,
    createdBy: item.createdBy,
    unitCost: item.unitCost,
    version: item.version,
    cascadeOptions: { cascadeLibrary: item.cascadeLibrary, cascadeScope: false },
  })

  mapFiltersToApi = (filters: LibraryManagementFilters) => ({
    ...filters,
    ratecards: this.mapGenericFilterToJoinedString(filters.ratecards),
    members: this.mapGenericFilterToJoinedString(filters.members),
    createdDates: filters.createdDates?.length
      ? filters.createdDates.map((date) => this.getDateByTimeframe(date.id)).join(',')
      : undefined,
    disciplines: this.mapGenericFilterToJoinedString(filters.disciplines),
  })

  mapFolderToApi = (folder, parentFolder?) => ({
    ...folder,
    itemType: LibraryItemType.FOLDER,
    libraryFolder: parentFolder?.id
      ? {
          id: parentFolder.id,
          name: parentFolder.name,
          itemType: LibraryItemType.FOLDER,
        }
      : undefined,
  })

  mapDeliverableToApi = (deliverable: LibraryDeliverable, folder?: LibraryManagementFolder) =>
    getPlainHttpParams({
      id: deliverable.id,
      name: deliverable.name,
      description: deliverable.description,
      fixedPricing: deliverable.fixedPricing,
      itemType: deliverable.itemType,
      internalNote: deliverable.internalNote,
      libraryFolder: folder
        ? {
            id: folder.id,
            itemType: LibraryItemType.FOLDER,
            name: folder.name,
          }
        : undefined,
    }) as unknown as LibraryDeliverable

  mapDeliverableEntryToApi = (entry: LibraryCreateDeliverableEntryActionPayload): LibraryDeliverableEntryRequest => ({
    description: entry.description,
    internalNote: entry.internalNote,
    fixedCostEditable: entry.fixedCostEditable,
    thirdPartyCosts: [],
    sections: [],
    libraryComponents: [],
    feeItems: [],
    discipline: { id: entry.discipline.id, name: entry.discipline.name },
    rateCardVersion: { ...entry.rateCard },
  })

  mapDeliverableEntryDuplicatePayloadApi = ({
    discipline,
    rolesMapping = {},
  }: LibraryDuplicateDeliverableEntryActionPayload): LibraryDuplicateEntryPayload => ({
    newDiscipline: {
      active: discipline.active,
      id: discipline.id,
      name: discipline.name,
      nonTranslatedName: discipline.nonTranslatedName,
    },
    rolesMapping,
  })

  mapComponentToApi = (component: LibraryComponent): LibraryComponent => ({
    id: component.id,
    name: component.name,
    itemType: LibraryItemType.COMPONENT,
  })

  mapComponentToCreateApi = (
    component: LibraryCreateComponentActionPayload,
    currency: string,
    folder: LibraryManagementFolder
  ): LibraryComponent => ({
    name: component.name,
    fixedPricing: component.fixedPricing,
    itemType: LibraryItemType.COMPONENT,
    libraryFolder: folder
      ? {
          id: folder.id,
          itemType: LibraryItemType.FOLDER,
          name: folder.name,
        }
      : undefined,
    libraryComponentEntries: [
      {
        rateCardVersion: component.rateCard,
        componentComplexities: ComplexitiesList.reduce(
          (complexities, key) => ({
            ...complexities,
            [key]: {
              complexity: key,
              description: component.description,
              fixedCost: component.price ? { currency, amount: component.price } : null,
              costAmount: component.cost,
              markup: +component.markup,
            },
          }),
          {} as LibraryComplexitiesMap
        ),
        fixedPricing: component.fixedPricing,
        fixedFeeEditable: component.fixedPricing ? component.fixedFeeEditable : false,
        fixedFeeHasRoles: component.fixedPricing ? component.fixedFeeHasRoles : false,
        discipline: { id: component.discipline.id, name: component.discipline.name },
      },
    ],
  })

  mapComponentEntryToApi = (payload: LibraryComponentEntryPayload, currency: string): LibraryComponentEntryRequest => ({
    generalDescription: payload.description,
    fixedFeeEditable: payload.fixedFeeEditable,
    fixedFeeHasRoles: payload.fixedFeeHasRoles,
    discipline: { id: payload.discipline.id, name: payload.discipline.name },
    rateCardVersion: plainToInstance(LibraryRateCard, payload.rateCard),
    componentComplexities: ComplexitiesList.reduce(
      (complexities, key) => ({
        ...complexities,
        [key]: {
          complexity: key,
          description: payload.description,
          fixedCost: { currency, amount: +payload.complexities[key].price },
          costAmount: +payload.complexities[key].cost,
          markup: +payload.complexities[key].markup,
        },
      }),
      {} as LibraryComplexitiesMap
    ),
  })

  mapComponentEntryToUpdateApi = (payload: LibraryComponentEntry): LibraryComponentEntryUpdateRequest => ({
    id: payload.id,
    name: payload.name,
    fixedPricing: payload.fixedPricing,
    fixedFeeEditable: payload.fixedFeeEditable,
    fixedFeeHasRoles: payload.fixedFeeHasRoles,
    versionNumber: payload.versionNumber,
    rateCardVersion: plainToInstance(LibraryRateCard, payload.rateCardVersion),
    componentComplexities: ComplexitiesList.reduce((complexities, key) => {
      let complexity = payload.componentComplexities?.[key]

      return {
        ...complexities,
        [key]: {
          complexity: key,
          aliasName: complexity?.aliasName,
          description: complexity?.description,
          internalNote: complexity?.internalNote,
          fixedCost: complexity?.fixedCost,
          costAmount: complexity?.costAmount,
          roles: complexity?.roles
            ? complexity?.roles.map((role) => this.libraryMappingService.mapRoleToComplexityRole(role))
            : [],
          scenarioFormulas: complexity?.scenarioFormulas,
        },
      }
    }, {} as LibraryComplexitiesMap),
  })

  mapComponentEntryDuplicatePayloadApi = (payload: LibraryComponentEntryPayload): LibraryDuplicateEntryRequest => ({
    rolesMapping: payload.rolesMapping || null,
    newDiscipline: {
      id: payload.discipline.id,
      name: payload.discipline.name,
      nonTranslatedName: payload.discipline.nonTranslatedName,
      active: payload.discipline.active,
    },
    fixedCostsMapping: payload.fixedPricing
      ? ComplexitiesList.reduce(
          (complexities, key) => ({
            ...complexities,
            [key]: +payload.complexities[key].price,
          }),
          {} as LibraryComplexitiesMap<number>
        )
      : undefined,
  })

  mapThirdPartyCostToApi = (tpc: LibraryCreateTPCActionPayload, folder?: LibraryManagementFolder) => ({
    name: tpc.name,
    description: tpc.description,
    itemType: LibraryItemType.THIRD_PARTY_COST,
    unitCost: +tpc.unitCost,
    sellingPrice: +tpc.sellingPrice,
    markupPercentage: tpc.markup ? +tpc.markup / 100 : 0,
    group: tpc.group && { id: tpc.group.id, type: tpc.group.groupType },
    formula: tpc.formula && { id: tpc.formula.id, type: tpc.formula.getName },
    libraryFolder: folder
      ? {
          id: folder.id,
          itemType: LibraryItemType.FOLDER,
          name: folder.name,
        }
      : undefined,
  })

  mapThirdPartyCostToUpdateApi = (tpc: ThirdPartyCost) =>
    plainToInstance(ThirdPartyCost, {
      id: tpc.id,
      name: tpc.name,
      description: tpc.description,
      itemType: LibraryItemType.THIRD_PARTY_COST,
      unitCost: +tpc.unitCost,
      sellingPrice: +tpc.sellingPrice,
      group: tpc.group && { id: tpc.group.id, type: tpc.group.groupType },
      formula: tpc.formula && Object.keys(tpc.formula).length != 0 ? tpc.formula : null,
      unitType: tpc.unitType,
      markupPercentage: tpc.markupPercentage ? +tpc.markupPercentage / 100 : 0,
      createdBy: tpc.createdBy,
      version: tpc.version,
    })

  private mapGenericFilterToJoinedString = (arr: GenericFilterValue[]) =>
    arr?.length ? arr.map((item) => item.id).join(',') : undefined

  private getDateByTimeframe(timeframe: TimePeriod) {
    let date = new Date()

    switch (timeframe) {
      case TimePeriod.WEEK:
        date.setDate(date.getDate() - 7)
        break
      case TimePeriod.MONTH:
        date.setMonth(date.getMonth() - 1)
        break
      case TimePeriod.HALF_YEAR:
        date.setMonth(date.getMonth() - 6)
        break
      case TimePeriod.YEAR:
        date.setFullYear(date.getFullYear() - 1)
    }

    return date.toISOString()
  }
}

import { Action, createReducer, on } from '@ngrx/store'
import {
  DeleteDeliverableEntrySuccessAction,
  GetDeliverableEntryAction,
  GetDeliverableEntrySuccessAction,
  GetDeliverableSuccessAction,
  GetRateCardSuccessAction,
  LibraryDeliverableEntry,
  LibraryDeliverableState,
  SetDeliverableEntriesPageAction,
  ToggleEntryFixedCostEditableSuccessAction,
  UpdateDeliverableSuccessAction,
} from '@app/features/library-management/store/models/deliverable'
import { LibraryDeliverableActions, libraryDeliverableInitialState, libraryManagementPaginationInitialState } from '@app/features/library-management/store'

import { plainToInstance } from 'class-transformer'

const loadingTrueHandler = (state: LibraryDeliverableState) => ({ ...state, isLoading: true })
const loadingFalseHandler = (state: LibraryDeliverableState) => ({ ...state, isLoading: false })

const setPageHandler = (state: LibraryDeliverableState, action: SetDeliverableEntriesPageAction) => ({
  ...state,
  pagination: { ...state.pagination, page: action.page },
})

const getDeliverableSuccessHandler = (
  state: LibraryDeliverableState,
  { deliverable }: GetDeliverableSuccessAction
) => ({
  ...state,
  isLoading: false,
  deliverable,
  pagination: { ...state.pagination, page: 0, totalCount: deliverable?.libraryDeliverableEntrySet?.length | 0 },
})

const updateDeliverableSuccessHandler = (
  state: LibraryDeliverableState,
  { deliverable }: UpdateDeliverableSuccessAction
) => ({
  ...state,
  isLoading: false,
  deliverable: {
    ...deliverable,
    libraryDeliverableEntrySet: state.deliverable.libraryDeliverableEntrySet,
  },
})

const deleteDeliverableSuccessHandler = (state: LibraryDeliverableState) => ({
  ...state,
  isLoading: false,
  deliverable: undefined,
  pagination: libraryManagementPaginationInitialState,
  entriesMap: {},
})

const getDeliverableEntryHandler = (state: LibraryDeliverableState, { id }: GetDeliverableEntryAction) => ({
  ...state,
  entriesMap: {
    ...state.entriesMap,
    [id]: state.entriesMap[id]?.components ? state.entriesMap[id] : { isLoading: true },
  },
})

const getDeliverableEntrySuccessHandler = (
  state: LibraryDeliverableState,
  { entry }: GetDeliverableEntrySuccessAction
) => ({
  ...state,
  entriesMap: {
    ...state.entriesMap,
    [entry.id]: {
      isLoading: false,
      components: entry.libraryComponents,
      thirdPartyCosts: entry.thirdPartyCosts,
    },
  },
})

const deleteDeliverableEntrySuccessHandler = (
  state: LibraryDeliverableState,
  { entryId }: DeleteDeliverableEntrySuccessAction
) => ({
  ...state,
  isLoading: false,
  deliverable: {
    ...state.deliverable,
    libraryDeliverableEntrySet: state.deliverable.libraryDeliverableEntrySet?.filter((entry) => entry.id !== entryId),
  },
  pagination: { ...state.pagination, totalCount: state.pagination.totalCount - 1 },
})

const getRateCardHandler = (state: LibraryDeliverableState) => ({
  ...state,
  isLoadingRateCard: true,
  rateCard: undefined,
})

const getRateCardFailHandler = (state: LibraryDeliverableState) => ({ ...state, isLoadingRateCard: false })

const getRateCardSuccessHandler = (state: LibraryDeliverableState, { rateCard }: GetRateCardSuccessAction) => ({
  ...state,
  isLoadingRateCard: false,
  rateCard,
})

const toggleEntryFixedCostEditableSuccessHandler = (
  state: LibraryDeliverableState,
  { id }: ToggleEntryFixedCostEditableSuccessAction
) => ({
  ...state,
  isLoading: false,
  deliverable: {
    ...state.deliverable,
    libraryDeliverableEntrySet: state.deliverable.libraryDeliverableEntrySet.map((e) =>
      e.id === id ? plainToInstance(LibraryDeliverableEntry, { ...e, fixedCostEditable: !e.fixedCostEditable }) : e
    ),
  },
})

export function libraryDeliverableReducer(
  state = libraryDeliverableInitialState,
  action: Action
): LibraryDeliverableState {
  return createReducer(
    libraryDeliverableInitialState,
    on(
      LibraryDeliverableActions.getDeliverable,
      LibraryDeliverableActions.updateDeliverable,
      LibraryDeliverableActions.deleteDeliverable,
      LibraryDeliverableActions.createDeliverableEntry,
      LibraryDeliverableActions.deleteDeliverableEntry,
      LibraryDeliverableActions.duplicateDeliverableEntry,
      LibraryDeliverableActions.toggleEntryFixedCostEditable,
      loadingTrueHandler
    ),
    on(
      LibraryDeliverableActions.getDeliverableFail,
      LibraryDeliverableActions.updateDeliverableFail,
      LibraryDeliverableActions.deleteDeliverableFail,
      LibraryDeliverableActions.createDeliverableEntryFail,
      LibraryDeliverableActions.createDeliverableEntrySuccess,
      LibraryDeliverableActions.deleteDeliverableEntryFail,
      LibraryDeliverableActions.duplicateDeliverableEntryFail,
      LibraryDeliverableActions.duplicateDeliverableEntrySuccess,
      LibraryDeliverableActions.toggleEntryFixedCostEditableFail,
      loadingFalseHandler
    ),
    on(LibraryDeliverableActions.getDeliverableSuccess, getDeliverableSuccessHandler),
    on(LibraryDeliverableActions.updateDeliverableSuccess, updateDeliverableSuccessHandler),
    on(LibraryDeliverableActions.deleteDeliverableSuccess, deleteDeliverableSuccessHandler),
    on(LibraryDeliverableActions.setPage, setPageHandler),
    on(LibraryDeliverableActions.getDeliverableEntry, getDeliverableEntryHandler),
    on(LibraryDeliverableActions.getDeliverableEntrySuccess, getDeliverableEntrySuccessHandler),
    on(LibraryDeliverableActions.deleteDeliverableEntrySuccess, deleteDeliverableEntrySuccessHandler),
    on(LibraryDeliverableActions.getRateCard, getRateCardHandler),
    on(LibraryDeliverableActions.getRateCardFail, getRateCardFailHandler),
    on(LibraryDeliverableActions.getRateCardSuccess, getRateCardSuccessHandler),
    on(LibraryDeliverableActions.toggleEntryFixedCostEditableSuccess, toggleEntryFixedCostEditableSuccessHandler)
  )(state, action)
}

import {
  LibraryComponentEffects,
  LibraryCreateComponentEffects,
  LibraryCreateDeliverableEffects,
  LibraryDeliverableEffects,
  LibraryManagementEffects,
  LibraryManagementFoldersEffects,
  LibraryManagementItemsEffects,
  LibraryThirdPartyCostEffects,
  libraryManagementFeatureInitialState,
  libraryManagementFeatureKey,
  libraryManagementFeatureReducer,
} from '@app/features/library-management/store'

import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { NgModule } from '@angular/core'
import { StoreModule } from '@ngrx/store'
import {
  LibraryDeliverableEntryDetailsEffects
} from '@app/features/library-management/store/effects/library-deliverable-entry-details.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature(
      LibraryManagementEffects,
      LibraryManagementItemsEffects,
      LibraryManagementFoldersEffects,
      LibraryCreateDeliverableEffects,
      LibraryCreateComponentEffects,
      LibraryThirdPartyCostEffects,
      LibraryDeliverableEffects,
      LibraryComponentEffects,
      LibraryDeliverableEntryDetailsEffects
    ),
    StoreModule.forFeature(libraryManagementFeatureKey, libraryManagementFeatureReducer, {
      initialState: libraryManagementFeatureInitialState,
    }),
  ],
})
export class LibraryManagementNgrxModule {}

import {
  LibraryComponent,
  LibraryComponentEntry,
  LibraryComponentEntryUpdateRequest,
  LibraryDuplicateEntryRequest,
} from '@app/features/library-management/store/models/component'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { environment } from '@envs/environment'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class LibraryComponentService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl

  constructor(private http: HttpClient) {}

  getComponent(id: number): Observable<LibraryComponent> {
    return this.http.get<any>(`${this.BASE_API_URL}library/component/${id}`)
  }

  addComponentEntry(id: number, entry): Observable<LibraryComponentEntry> {
    return this.http.post<any>(`${this.BASE_API_URL}library/component/${id}/entry`, entry)
  }

  updateComponent(component: LibraryComponent): Observable<LibraryComponent> {
    return this.http.put<any>(`${this.BASE_API_URL}library/component`, component)
  }

  deleteComponentEntry(id: number, entryId: number): Observable<any> {
    return this.http.delete<any>(`${this.BASE_API_URL}library/component/${id}/entry/${entryId}`)
  }

  duplicateComponentEntry(
    id: number,
    entryId: number,
    rateCardId: number,
    entry: LibraryDuplicateEntryRequest
  ): Observable<LibraryComponentEntry> {
    return this.http.post<any>(
      `${this.BASE_API_URL}library/component/${id}/entry/${entryId}/ratecard/${rateCardId}/duplicate`,
      entry
    )
  }

  enableComponentEntryFixedFeeEditable(id: number, entryId: number): Observable<LibraryComponentEntry> {
    return this.http.put<any>(
      `${this.BASE_API_URL}library/component/${id}/component-entry/${entryId}/enable-fixed-fee-editable`,
      null
    )
  }

  disableComponentEntryFixedFeeEditable(id: number, entryId: number): Observable<LibraryComponentEntry> {
    return this.http.put<any>(
      `${this.BASE_API_URL}library/component/${id}/component-entry/${entryId}/disable-fixed-fee-editable`,
      null
    )
  }

  getComponentRolesMapping(id: number, entryId: number, rateCardId: number): Observable<any> {
    return this.http.get<any>(`${this.BASE_API_URL}library/component/${id}/entry/${entryId}/mapping/${rateCardId}`)
  }

  getEntryRateCard(id: number, entryId: number): Observable<RatecardVersion> {
    return this.http.get<any>(`${this.BASE_API_URL}library/component/${id}/entry/${entryId}/ratecard`)
  }

  saveEntry(entry: LibraryComponentEntryUpdateRequest): Observable<LibraryComponentEntry> {
    return this.http.put<any>(`${this.BASE_API_URL}library/component-entry/${entry.id}`, entry)
  }

  cascadeChanges(id: number): Observable<void> {
    return this.http.post<any>(
      `${this.BASE_API_URL}library/deliverable/force-update-latest-component-entry/${id}?incrementVersionOnly=false`,
      null
    )
  }
}

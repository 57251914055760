<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>
        {{ data.entry ? 'Duplicate' : 'Create' }} {{ 'component' | lang }}
        <ng-container *ngIf="data.isCreateEntry">Entry</ng-container>
      </h1>
    </div>

    <button
      class="close-button"
      mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content
  aria-label="Generic modal"
  class="modal-container md-dialog-container library">
  <div class="px-10 pb-2 w-full library-modal-content">
    <ng-container *ngIf="!data.isCreateEntry">
      <div class="flex-row">
        <scope-ui-input
          inputClass="scope-input--full-sized"
          inputLabel="Add {{ 'component' | lang }} Name*"
          [maximumLength]="255"
          [control]="getControl('name')" />
      </div>

      <div class="flex-row">
        <description-editor
          class="px-10"
          status="DRAFT"
          [hideNote]="true"
          [descriptionRequired]="true"
          [descriptionDirty]="getControl('description').touched"
          [model]="{ description: getControlValue('description') }"
          (onSaveDescription)="setControlValue('description', $event)" />
      </div>

      <div class="flex-row">
        <scope-ui-dropdown
          class="d-block scope-input--full-sized"
          controlLabel="Item Type"
          dropdownClass="scope-input--full-sized"
          [selectedValue]="typeOptions[0]"
          [dropdownOptions]="typeOptions"
          [isStandardDropdown]="true"
          (onSelectionChange)="setControlValue('libraryItemType', $event.id)" />
      </div>
    </ng-container>

    <ng-container [has-privilege]="Privilege.LIBRARY_DELIVERABLE__EDIT">
      <div
        class="flex-row"
        [class.pt-8]="!data.isCreateEntry">
        <scope-ui-autocomplete
          label="Select a Ratecard*"
          [control]="getControl('rateCard')"
          [options]="rateCards$ | async"
          (onSelectionChange)="onRateCardChange($event)" />
      </div>

      <div class="flex-row pt-8">
        <scope-ui-autocomplete
          label="Select Discipline*"
          [control]="getControl('discipline')"
          [options]="disciplines$ | async" />
      </div>
    </ng-container>

    <div
      *ngIf="data.isCreateEntry && !data.entry"
      class="flex-row">
      <description-editor
        class="px-10"
        status="DRAFT"
        [hideNote]="true"
        [descriptionRequired]="true"
        [descriptionDirty]="getControl('description').touched"
        [model]="{ description: getControlValue('description') }"
        (onSaveDescription)="setControlValue('description', $event)" />
    </div>

    <ng-container *ngIf="isFixedCost">
      <div
        *ngIf="!data.entry"
        class="flex justify-between pt-8">
        <scope-ui-toggle
          title="Fixed Fee has roles"
          [initialValue]="true"
          [control]="getControl('fixedFeeHasRoles')"
          (onToggleChange)="onFixedFeeHasRolesChange()">
        </scope-ui-toggle>
        <scope-ui-toggle
          title="Fixed Fee is editable"
          [initialValue]="true"
          [control]="getControl('fixedFeeEditable')">
        </scope-ui-toggle>
      </div>

      <div
        *ngIf="!data.isCreateEntry && !getControlValue('fixedFeeHasRoles')"
        class="complexities pt-8">
        <scope-ui-input
          type="number"
          inputLabel="Mark-up (%)"
          inputClass="complexities-input"
          [isDisabled]="true"
          [control]="getControl('markup')" />
        <scope-ui-input
          type="text"
          inputLabel="Cost"
          inputClass="complexities-input"
          [isCurrency]="true"
          [currency]="authService.loggedInUser.company?.currency"
          [maximumLength]="17"
          [control]="getControl('cost')"
          (onInputChange)="updateMarkUp()" />
        <scope-ui-input
          type="text"
          inputLabel="Price"
          [isInteger]="true"
          [maximumLength]="17"
          inputClass="complexities-input"
          [isCurrency]="true"
          [currency]="authService.loggedInUser.company?.currency"
          [maximumLength]="17"
          [control]="getControl('price')"
          (onInputChange)="updateMarkUp()" />
      </div>

      <div
        *ngIf="data.isCreateEntry && data.isFixedPricing"
        class="complexities pt-8"
        [class.mt-4]="data.entry">
        <div
          *ngFor="let complexity of ComplexitiesList"
          class="complexities-block">
          <p class="complexities-title">Size {{ complexity }}</p>
          <ng-container *ngIf="!getControlValue('fixedFeeHasRoles')">
            <scope-ui-input
              type="number"
              inputLabel="Mark-up (%)"
              inputClass="complexities-input"
              [isDisabled]="true"
              [control]="getComplexityControl(complexity, 'markup')" />
            <scope-ui-input
              type="text"
              inputLabel="Cost"
              inputClass="complexities-input"
              [isCurrency]="true"
              [currency]="authService.loggedInUser.company?.currency"
              [maximumLength]="17"
              [control]="getComplexityControl(complexity, 'cost')"
              (onInputChange)="updateMarkUp(complexity)" />
          </ng-container>
          <scope-ui-input
            type="text"
            inputLabel="Price"
            inputClass="complexities-input"
            [isCurrency]="true"
            [currency]="authService.loggedInUser.company?.currency"
            [maximumLength]="17"
            [control]="getComplexityControl(complexity, 'price')"
            (onInputChange)="updateMarkUp(complexity)" />
        </div>
      </div>
    </ng-container>
  </div>

  <div class="d-flex d-flex-justify-end px-10 pt-8 pb-4">
    <div class="library-action-buttons">
      <button
        mat-button
        mat-dialog-close>
        Cancel
      </button>
      <button
        class="main-action-button ml-4"
        [class.disabled]="!modalForm.valid"
        mat-button
        (click)="submit()">
        {{ data.entry ? 'Duplicate' : 'Create' }}
      </button>
    </div>
  </div>
</mat-dialog-content>

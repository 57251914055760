import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  LibraryDeliverableEntryDetailsAdapter,
} from '@app/features/library-management/store/reducers/library-deliverable-entry-details.reducer';
import { LibraryManagementFeatureState } from '@app/features/library-management/store/models/state';

const { selectAll } = LibraryDeliverableEntryDetailsAdapter.getSelectors();

export const selectDeliverableEntryDetailsState = createSelector(
  createFeatureSelector<LibraryManagementFeatureState>('libraryManagement'),
  (state: LibraryManagementFeatureState) => {
    return state?.deliverableEntryDetails;
  },
);

export const selectAllDeliverableEntryDetails = createSelector(
  selectDeliverableEntryDetailsState,
  selectAll,
);

export const selectDeliverableEntryDetailsLoading = createSelector(
  selectDeliverableEntryDetailsState,
  (state) => state.isLoading,
);

export const selectDeliverableEntryDetailsLastPage = createSelector(
  selectDeliverableEntryDetailsState,
  (state) => state.last,
);

import {
  LibraryManagementFoldersService,
  LibraryManagementMappingService,
  LibraryManagementService,
} from '@app/features/library-management/services'
import {
  LibraryDiscipline,
  LibraryManagementActionTypes,
  LibraryManagementActions,
  LibraryManagementSelectors,
  LibraryRateCard,
} from '@app/features/library-management/store'
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@app/shared/utils/snackbar.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { EMPTY, catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs'

import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Privilege } from '@app/core/model/enums/privilege.enum'
import { User } from '@app/core/model/user.model'
import { AuthSelectors } from '@app/core/store/selectors/auth.selector'
import { orderBy } from 'lodash'

@Injectable()
export class LibraryManagementEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private snackbarService: SnackbarService,
    private libraryManagementService: LibraryManagementService,
    private libraryMappingService: LibraryManagementMappingService,
    private libraryFoldersService: LibraryManagementFoldersService,
    private dialog: MatDialog
  ) {}

  getHighlights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementActionTypes.GET_HIGHLIGHTS),
      withLatestFrom(this.store.pipe(select(AuthSelectors.selectUser))),
      map(([, user]: [any, User]) => ({
        enableDeliverable: user.hasPrivilege(Privilege.LIBRARY_DELIVERABLE__VIEW),
        enableComponent: user.hasPrivilege(Privilege.LIBRARY_COMPONENT__VIEW),
      })),
      switchMap((params) =>
        this.libraryManagementService.getHighlights(params).pipe(
          map((highlights) => ({
            ...highlights,
            creators: this.libraryMappingService.mapHighlightsEntry(highlights.creators),
            ratecards: this.libraryMappingService.mapHighlightsEntry(highlights.ratecards),
            disciplines: orderBy(this.libraryMappingService.mapHighlightsEntry(highlights.disciplines), 'name'),
          })),
          map((highlights) => LibraryManagementActions.getHighlightsSuccess({ highlights })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during loading Highlights',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryManagementActions.getHighlightsFail({ error }))
          })
        )
      )
    )
  )

  getRatecards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementActionTypes.GET_RATECARDS),
      withLatestFrom(this.store.pipe(select(LibraryManagementSelectors.selectIsRateCardsLoaded))),
      filter(([, isLoaded]: [any, boolean]) => !isLoaded),
      switchMap(() =>
        this.libraryManagementService.getRateCards().pipe(
          map((rateCards: LibraryRateCard[]) => LibraryManagementActions.getRatecardsSuccess({ rateCards })),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during loading Rate Cards',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryManagementActions.getRatecardsFail({ error }))
          })
        )
      )
    )
  )

  getDisciplines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementActionTypes.GET_DISCIPLINES),
      withLatestFrom(this.store.pipe(select(LibraryManagementSelectors.selectIsDisciplinesLoaded))),
      filter(([, isLoaded]: [any, boolean]) => !isLoaded),
      switchMap(() =>
        this.libraryManagementService.getDisciplines().pipe(
          map((disciplines: LibraryDiscipline[]) =>
            LibraryManagementActions.getDisciplinesSuccess({
              disciplines: disciplines.filter((discipline) => discipline.active),
            })
          ),
          catchError((error: any) => {
            console.error(error)
            this.snackbarService.showSnackbar(
              'An error occurred during loading Disciplines',
              SNACKBAR_LENGTH_LONG,
              SnackbarEventType.ERROR
            )
            return of(LibraryManagementActions.getDisciplinesFail({ error }))
          })
        )
      )
    )
  )

  getFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementActionTypes.SET_FOLDER_ID),
      switchMap(({ folderId }) =>
        folderId
          ? this.libraryFoldersService.getFolder(folderId).pipe(
              map((folder) => LibraryManagementActions.getFolderSuccess({ folder })),
              catchError((error: any) => {
                console.error(error)
                this.snackbarService.showSnackbar(
                  'An error occurred during loading Folder',
                  SNACKBAR_LENGTH_LONG,
                  SnackbarEventType.ERROR
                )
                return of(LibraryManagementActions.getFolderFail({ error }))
              })
            )
          : [LibraryManagementActions.getFolderSuccess({ folder: undefined })]
      )
    )
  )

  exportItems$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryManagementActionTypes.EXPORT_ITEMS),
        switchMap(({ itemType, id }) =>
          this.libraryManagementService.export(itemType, id).pipe(
            map(() => {
              this.snackbarService.showSnackbar(
                'Export started and when complete file will automatically download',
                SNACKBAR_LENGTH_LONG,
                SnackbarEventType.INFO
              )
            }),
            catchError(() => {
              this.snackbarService.showSnackbar(
                'Failed to download export',
                SNACKBAR_LENGTH_LONG,
                SnackbarEventType.ERROR
              )
              return EMPTY
            })
          )
        )
      ),
    { dispatch: false }
  )

  closeActiveModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryManagementActionTypes.CLOSE_ACTIVE_MODAL),
      withLatestFrom(this.store.select(LibraryManagementSelectors.selectDialogId)),
      map(([, dialogId]) => {
        if (dialogId) this.dialog.getDialogById(dialogId)?.close()
        return LibraryManagementActions.setDialogId({ id: undefined })
      })
    )
  )
}

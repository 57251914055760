<div class="mx-3.5">
  <div *ngIf='!isTimeline' class="tr table-header flex px-2">
    <div class="column {{ mainColumnClass }}">
      <span class="info-row-title">Item name</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.DELIVERY_STATUS.isVisible()">
      <span>Delivery status</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.CREATED_BY.selected">
      <span>Created by</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.OWNER.selected">
      <span>Owner</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.DATE_CREATED.selected">
      <span>Date created</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.QUANTITY.selected">
      <span>Quantity</span>
    </div>
    <div
      class="column one--column"
      *ngIf="
              scopeTabService.userColumns.START_DATE.selected &&
              scopeTabService.userColumns.START_DATE.isVisibleInList()
            ">
      <span class="info-row-title">Start date</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.LAST_EDITED.selected">
      <span>Last edited</span>
    </div>
    <div
      class="column one--column"
      *ngIf="
              scopeTabService.userColumns.END_DATE.selected && scopeTabService.userColumns.END_DATE.isVisibleInList()
            ">
      <span class="info-row-title">End date</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.MARK_UP.selected">
      <span>Markup</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.PROFIT.selected">
      <span>Profit</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.SCOPEMARK_HOURS.selected">
      <span isEllipsisActive>{{ 'scope_mark' | lang }} hours</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.SCOPEMARK_PRICE.selected">
      <span isEllipsisActive>{{ 'scope_mark' | lang }} price</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.AGENCY_HOURS.selected">
      <span isEllipsisActive>{{ 'agency' | lang }} hours</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.BUDGET.selected">
      <span>{{ 'budget' | lang }}</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.BALANCE.selected">
      <span>Balance</span>
    </div>
    <div
      class="column one--column"
      *ngIf="scopeTabService.userColumns.AGENCY_PRICE.selected">
      <span isEllipsisActive *ngIf="!scopeVersion.identity.costPlus">{{ 'agency' | lang }} price</span>
      <span isEllipsisActive *ngIf="scopeVersion.identity.costPlus">Cost plus price</span>
    </div>
    <div class="column one--column toggle-list-content">
      <scope-ui-toggle-list
        (onTogglePref)="onToggleScopePreference($event)"
        [preferences]="scopeTabService.userColumnsPrefsArray"
        [maxSelected]="9"
      >
      </scope-ui-toggle-list>
    </div>
  </div>

  <div *ngIf='isTimeline' class="tr table-header flex px-1">
    <div class="column {{ mainColumnClass }}">
      <span class="info-row-title">Item name</span>
    </div>
    <div
      class="column three--columns">
      <span>{{ 'agency' | lang }} hours</span>
    </div>
    <div
      class="column three--columns">
      <span>{{ 'agency' | lang }} price</span>
    </div>
  </div>
</div>
<div
  class="tbody table-content mt-2 {{ isTimeline ? 'timeline-view' : '' }}"
  [ngClass]="{ 'drag-target': sectionDragging }"
  *ngIf='sortedSections.length'
  cdkDropList
  [cdkDropListData]="sortedSections"
  [cdkDropListDisabled]="!scopeApprovalFlowService.isScopeEditable(scopeVersion)"
  (cdkDropListDropped)="dropSection($event)">
  <div
    *ngFor="let section of sortedSections; trackBy: trackById"
    cdkDrag
    [cdkDragData]="section"
    [cdkDragDisabled]="scopeTabService.disableDragging"
    (cdkDragStarted)="sectionDragging = true"
    (cdkDragEnded)="sectionDragging = false">
    <mat-icon class="material-symbols-rounded drag-indicator">drag_indicator</mat-icon>
    <scope-section-row
      class="tr section-row flex flex-col mat-elevation-z0"
      [ngClass]="{ selected: scopeTabService.sectionSelectedStates[section.id] || section.id == scopeTabService.dragTargetId }"
      (mouseenter)="onDragEnter(section)"
      [isTimeline]='isTimeline'
      [section]='section'
      [currentUser]="currentUser"
      [userColumns]='isTimeline ? scopeTabService.timelineColumns : scopeTabService.userColumns'
      [scopeVersion]="scopeVersion"
      [feeMenuOptions]="feeMenuOptions"
      [currentRatecard]="currentRatecard"
      [sectionDrops]='sectionDrops'
      [mainColumnClass]='mainColumnClass'
      [componentMainColumnClass]='componentMainColumnClass'
      [roleMainColumnClass]='roleMainColumnClass'
      [sectionSelectedStates]="scopeTabService.sectionSelectedStates$ | async"
      [cdnConfig]="cdnConfig"
      (onDropDeliverable)='dropDeliverable($event.event, $event.section)'
      (onAddSectionFee)="addSectionFee($event)"
      (onDeleteSection)="deleteSection($event)"
      (onFetchDeliverable)="fetchDeliverable($event)"
      (onUpdateDeliverable)="updateDeliverable($event)"
      (onUpdateComponent)="updateComponent($event)"
      (onDuplicateDeliverable)="duplicateDeliverable($event)"
      (onDeleteDeliverable)="deleteDeliverable($event)"
      (onAcceptDeliverable)="acceptDeliverable($event)"
      (onRejectDeliverable)="rejectDeliverable($event)"
      (onAddDeliverableFee)="addDeliverableFee($event)"
      (onAddComponentFee)="addComponentFee($event)"
      (onEditDeliverable)="editDeliverable($event)"
      (onCompleteTrade)="completeTrade($event)"
      (onTradeDeliverable)="tradeDeliverable($event)"
      (onStopProgressTrade)="stopProgressTrade($event)"
      (onReopenTrade)="reopenTrade($event)"
      (onCancelTrade)="cancelTrade($event)"
      (onConfirmTrade)="confirmTrade($event)"
      (onAddDeliverableToTrade)="addDeliverableToTrade($event)"
      (onShowDeliverableApplyLocationCardDialog)="showDeliverableApplyLocationCardDialog($event)"
      (onUpdateFeeItem)="updateFeeItem($event.feeItem, $event.section, $event.deliverable, $event.component)"
      (onOverrideQuantity)="overrideQuantity($event)"
      (onOverrideRateCardRoleHours)="overrideRateCardRoleHours($event)" >
    </scope-section-row>
  </div>
</div>

<div
  class="tbody table-content mt-2 {{ isTimeline ? 'timeline-view' : '' }}"
  [hidden]="isTimeline && (!sortedDefaultSectionDeliverables.length && !scopeVersion.sections.length)"
  [ngClass]="{ 'drag-target': scopeTabService.deliverableDragging }"
  style="min-height: 40px"
  id="list-one"
  [cdkDropListConnectedTo]="sectionDrops"
  cdkDropList
  [cdkDropListData]="sortedDefaultSectionDeliverables"
  [cdkDropListDisabled]="!scopeApprovalFlowService.isScopeEditable(scopeVersion)"
  (cdkDropListDropped)="dropDeliverable($event, null)">
  <div
    cdkDrag
    *ngFor="let deliverable of sortedDefaultSectionDeliverables; trackBy: trackById"
    [cdkDragData]="deliverable"
    [cdkDragDisabled]="scopeTabService.disableDragging"
    (cdkDragStarted)="scopeTabService.deliverableDragging = true"
    (cdkDragEnded)="scopeTabService.deliverableDragging = false; scopeTabService.dragTargetId = null;">
    <mat-icon class="material-symbols-rounded drag-indicator">drag_indicator</mat-icon>
    <scope-deliverable-row
      class="tr deliverable-row flex-col"
      style="min-height: 40px"
      [isTimeline]='isTimeline'
      [currentUser]="currentUser"
      [scopeVersion]="scopeVersion"
      [deliverable]="deliverable"
      [userColumns]="isTimeline ? scopeTabService.timelineColumns : scopeTabService.userColumns"
      [mainColumnClass]='mainColumnClass'
      [componentMainColumnClass]='componentMainColumnClass'
      [roleMainColumnClass]='roleMainColumnClass'
      [deliverableColumns]="scopeTabService.deliverableColumns"
      [deliverableFeeColumns]="scopeTabService.deliverableFeeColumns"
      [componentFeeColumns]="scopeTabService.componentFeeColumns"
      [feeMenuOptions]="feeMenuOptions"
      [currentRatecard]="currentRatecard"
      [level]="1"
      [deliverableSelectedStates]="scopeTabService.deliverableSelectedStates$ | async"
      [cdnConfig]="cdnConfig"
      (onFetchDeliverable)="fetchDeliverable($event)"
      (onUpdateDeliverable)="updateDeliverable($event)"
      (onUpdateComponent)="updateComponent($event)"
      (onDuplicateDeliverable)="duplicateDeliverable($event)"
      (onDeleteDeliverable)="deleteDeliverable($event)"
      (onAcceptDeliverable)="acceptDeliverable(deliverable)"
      (onRejectDeliverable)="rejectDeliverable(deliverable)"
      (onAddDeliverableFee)="addDeliverableFee($event)"
      (onAddComponentFee)="addComponentFee($event)"
      (onEditDeliverable)="editDeliverable($event)"
      (onCompleteTrade)="completeTrade(deliverable)"
      (onTradeDeliverable)="tradeDeliverable(deliverable)"
      (onStopProgressTrade)="stopProgressTrade(deliverable)"
      (onReopenTrade)="reopenTrade(deliverable)"
      (onCancelTrade)="cancelTrade(deliverable)"
      (onConfirmTrade)="confirmTrade(deliverable)"
      (onAddDeliverableToTrade)="addDeliverableToTrade(deliverable)"
      (onShowDeliverableApplyLocationCardDialog)="showDeliverableApplyLocationCardDialog(deliverable)"
      (onUpdateFeeItem)="updateFeeItem($event.feeItem, undefined, $event.deliverable, $event.component)"
      (onOverrideQuantity)="overrideQuantity($event)"
      (onMoveComponent)="moveComponent($event)"
      (onMoveComponentOutsideDeliverable)="moveComponentOutsideDeliverable($event)"
      [deliverableDrops] = "deliverableDrops"
      (onOverrideRateCardRoleHours)="overrideRateCardRoleHours($event)">
    </scope-deliverable-row>
  </div>

  <div
    class="text-for-empty"
    [hidden]="sortedDefaultSectionDeliverables.length > 0">
    <p class="text">Drag &amp; Drop {{ 'deliverable.p' | lang }}</p>
    <p class="undertext">between sections or from this default section</p>
  </div>
</div>

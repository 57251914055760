import {
  LibraryManagementFolder,
  LibraryManagementFolderGroupSettingsModel,
} from '@app/features/library-management/store/models/library-folders'
import { createAction, props } from '@ngrx/store'

import { Preference } from '@app/core/model/user-preferences.interface'
import { Sort } from '@angular/material/sort'
import { TableColumnPreferences } from '@app/shared/components/ui-components/scope-ui-table/table-column-preferences.type'

export enum LibraryManagementFoldersActionTypes {
  GET_FOLDERS = '[Library Management Folders] Get Folders',
  GET_FOLDERS_FAIL = '[Library Management Folders] Get Folders Fail',
  GET_FOLDERS_SUCCESS = '[Library Management Folders] Get Folders Success',

  GET_ARCHIVED_FOLDERS = '[Library Management Folders] Get Archived Folders',
  GET_ARCHIVED_FOLDERS_FAIL = '[Library Management Folders] Get Archived Folders Fail',
  GET_ARCHIVED_FOLDERS_SUCCESS = '[Library Management Folders] Get Archived Folders Success',

  GET_ALL_FOLDERS = '[Library Management Folders] Get All Folders',
  GET_ALL_FOLDERS_TRIGGER = '[Library Management Folders] Get All Folders Triggered',
  GET_ALL_FOLDERS_FAIL = '[Library Management Folders] Get All Folders Fail',
  GET_ALL_FOLDERS_SUCCESS = '[Library Management Folders] Get All Folders Success',

  SET_PAGE = '[Library Management Folders] Set page',
  SET_SORT = '[Library Management Folders] Set sort',
  SET_ARCHIVED_PAGE = '[Library Management Folders] Set archived page',
  SET_ARCHIVED_SORT = '[Library Management Folders] Set archived sort',
  SET_DIALOG_ID = '[Library Management Folders] Set dialog id',

  GET_COLUMNS = '[Library Management Folders] Get Columns',
  GET_COLUMNS_SUCCESS = '[Library Management Folders] Get Columns Success',
  GET_COLUMNS_FAIL = '[Library Management Folders] Get Columns Fail',
  UPDATE_COLUMN_PREFERENCE = '[Library Management Folders] Update Column Preference',
  UPDATE_COLUMN_PREFERENCE_SUCCESS = '[Library Management Folders] Update Column Preference Success',
  UPDATE_COLUMN_PREFERENCE_FAIL = '[Library Management Folders] Update Column Preference Fail',

  CREATE_FOLDER = '[Library Management Folders] Create Folder',
  CREATE_FOLDER_FAIL = '[Library Management Folders] Create Folder Fail',
  CREATE_FOLDER_SUCCESS = '[Library Management Folders] Create Folder Success',

  UPDATE_FOLDER = '[Library Management Folders] Update Folder',
  UPDATE_FOLDER_SUCCESS = '[Library Management Folders] Update Folder Success',
  UPDATE_FOLDER_FAIL = '[Library Management Folders] Update Folder Fail',

  ARCHIVE_FOLDER = '[Library Management Folders] Archive Folder',
  ARCHIVE_FOLDER_FAIL = '[Library Management Folders] Archive Folder Fail',
  ARCHIVE_FOLDER_SUCCESS = '[Library Management Folders] Archive Folder Success',

  DELETE_FOLDER = '[Library Management Folders] Delete Folder',
  DELETE_FOLDER_FAIL = '[Library Management Folders] Delete Folder Fail',
  DELETE_FOLDER_SUCCESS = '[Library Management Folders] Delete Folder Success',

  OPEN_MOVE_FOLDER_MODAL = '[Library Management Folders] Open Move Folder Modal',
  CLOSE_MOVE_FOLDER_MODAL = '[Library Management Folders] Close Move Folder Modal',

  MOVE_FOLDER = '[Library Management Folders] Move Folder',
  MOVE_FOLDER_FAIL = '[Library Management Folders] Move Folder Fail',
  MOVE_FOLDER_SUCCESS = '[Library Management Folders] Move Folder Success',

  CREATE_AND_MOVE_FOLDER = '[Library Management Folders] Create and Move Folder',
  CREATE_AND_MOVE_FOLDER_FAIL = '[Library Management Folders] Create and Move Folder Fail',

  GET_GROUP_SETTINGS = '[Library Management Folders] Get Folder group settings',
  GET_GROUP_SETTINGS_FAIL = '[Library Management Folders] Get Folder group settings Fail',
  GET_GROUP_SETTINGS_SUCCESS = '[Library Management Folders] Get Folder group settings Success',
  SET_GROUP_SETTINGS = '[Library Management Folders] Set Folder group settings',
  SET_GROUP_SETTINGS_FAIL = '[Library Management Folders] Set Folder group settings Fail',
  SET_GROUP_SETTINGS_SUCCESS = '[Library Management Folders] Set Folder group settings Success',
}

const getFolders = createAction(LibraryManagementFoldersActionTypes.GET_FOLDERS)
const getFoldersFail = createAction(LibraryManagementFoldersActionTypes.GET_FOLDERS_FAIL, props<{ error: Error }>())
const getFoldersSuccess = createAction(
  LibraryManagementFoldersActionTypes.GET_FOLDERS_SUCCESS,
  props<{ list: LibraryManagementFolder[]; totalElements: number }>()
)

const getArchivedFolders = createAction(LibraryManagementFoldersActionTypes.GET_ARCHIVED_FOLDERS)
const getArchivedFoldersFail = createAction(
  LibraryManagementFoldersActionTypes.GET_ARCHIVED_FOLDERS_FAIL,
  props<{ error: Error }>()
)
const getArchivedFoldersSuccess = createAction(
  LibraryManagementFoldersActionTypes.GET_ARCHIVED_FOLDERS_SUCCESS,
  props<{ list: LibraryManagementFolder[]; totalElements: number }>()
)

const getAllFolders = createAction(LibraryManagementFoldersActionTypes.GET_ALL_FOLDERS)
const getAllFoldersTrigger = createAction(LibraryManagementFoldersActionTypes.GET_ALL_FOLDERS_TRIGGER)
const getAllFoldersFail = createAction(
  LibraryManagementFoldersActionTypes.GET_ALL_FOLDERS_FAIL,
  props<{ error: Error }>()
)
const getAllFoldersSuccess = createAction(
  LibraryManagementFoldersActionTypes.GET_ALL_FOLDERS_SUCCESS,
  props<{ list: LibraryManagementFolder[] }>()
)

const setPage = createAction(LibraryManagementFoldersActionTypes.SET_PAGE, props<{ page: number }>())
const setSort = createAction(LibraryManagementFoldersActionTypes.SET_SORT, props<{ sort: Sort }>())
const setArchivedPage = createAction(LibraryManagementFoldersActionTypes.SET_ARCHIVED_PAGE, props<{ page: number }>())
const setArchivedSort = createAction(LibraryManagementFoldersActionTypes.SET_ARCHIVED_SORT, props<{ sort: Sort }>())
const setDialogId = createAction(LibraryManagementFoldersActionTypes.SET_DIALOG_ID, props<{ id?: string }>())

const getColumns = createAction(LibraryManagementFoldersActionTypes.GET_COLUMNS)
const getColumnsSuccess = createAction(
  LibraryManagementFoldersActionTypes.GET_COLUMNS_SUCCESS,
  props<{ preferences: TableColumnPreferences }>()
)
const getColumnsFail = createAction(LibraryManagementFoldersActionTypes.GET_COLUMNS_FAIL, props<{ error: Error }>())

const updateColumnPreference = createAction(
  LibraryManagementFoldersActionTypes.UPDATE_COLUMN_PREFERENCE,
  props<{ preference: Preference }>()
)
const updateColumnPreferenceSuccess = createAction(
  LibraryManagementFoldersActionTypes.UPDATE_COLUMN_PREFERENCE_SUCCESS,
  props<{ preferences: TableColumnPreferences }>()
)
const updateColumnPreferenceFail = createAction(
  LibraryManagementFoldersActionTypes.UPDATE_COLUMN_PREFERENCE_FAIL,
  props<{ error: Error }>()
)

const createFolder = createAction(LibraryManagementFoldersActionTypes.CREATE_FOLDER, props<{ name: string }>())
const createFolderSuccess = createAction(
  LibraryManagementFoldersActionTypes.CREATE_FOLDER_SUCCESS,
  props<{ folder: LibraryManagementFolder }>()
)
const createFolderFail = createAction(LibraryManagementFoldersActionTypes.CREATE_FOLDER_FAIL, props<{ error: Error }>())

const updateFolder = createAction(
  LibraryManagementFoldersActionTypes.UPDATE_FOLDER,
  props<{ folder: LibraryManagementFolder }>()
)
const updateFolderSuccess = createAction(
  LibraryManagementFoldersActionTypes.UPDATE_FOLDER_SUCCESS,
  props<{ folder: LibraryManagementFolder }>()
)
const updateFolderFail = createAction(LibraryManagementFoldersActionTypes.UPDATE_FOLDER_FAIL, props<{ error: Error }>())

const archiveFolder = createAction(
  LibraryManagementFoldersActionTypes.ARCHIVE_FOLDER,
  props<{ folder: LibraryManagementFolder }>()
)
const archiveFolderFail = createAction(
  LibraryManagementFoldersActionTypes.ARCHIVE_FOLDER_FAIL,
  props<{ error: Error }>()
)
const archiveFolderSuccess = createAction(
  LibraryManagementFoldersActionTypes.ARCHIVE_FOLDER_SUCCESS,
  props<{ folder: LibraryManagementFolder }>()
)

const deleteFolder = createAction(
  LibraryManagementFoldersActionTypes.DELETE_FOLDER,
  props<{ folder: LibraryManagementFolder }>()
)
const deleteFolderFail = createAction(LibraryManagementFoldersActionTypes.DELETE_FOLDER_FAIL, props<{ error: Error }>())
const deleteFolderSuccess = createAction(
  LibraryManagementFoldersActionTypes.DELETE_FOLDER_SUCCESS,
  props<{ folder: LibraryManagementFolder }>()
)

const openMoveFolderModal = createAction(
  LibraryManagementFoldersActionTypes.OPEN_MOVE_FOLDER_MODAL,
  props<{ folder: LibraryManagementFolder }>()
)
const closeMoveFolderModal = createAction(LibraryManagementFoldersActionTypes.CLOSE_MOVE_FOLDER_MODAL)

const moveFolder = createAction(
  LibraryManagementFoldersActionTypes.MOVE_FOLDER,
  props<{ id: number; folderId: number }>()
)
const moveFolderFail = createAction(LibraryManagementFoldersActionTypes.MOVE_FOLDER_FAIL, props<{ error: Error }>())
const moveFolderSuccess = createAction(LibraryManagementFoldersActionTypes.MOVE_FOLDER_SUCCESS)

const createAndMoveFolder = createAction(
  LibraryManagementFoldersActionTypes.CREATE_AND_MOVE_FOLDER,
  props<{ id: number; folderId: number; name: string }>()
)
const createAndMoveFolderFail = createAction(
  LibraryManagementFoldersActionTypes.CREATE_AND_MOVE_FOLDER_FAIL,
  props<{ error: Error }>()
)

const getGroupSettings = createAction(LibraryManagementFoldersActionTypes.GET_GROUP_SETTINGS, props<{ id: number }>())
const getGroupSettingsFail = createAction(
  LibraryManagementFoldersActionTypes.GET_GROUP_SETTINGS_FAIL,
  props<{ error: Error }>()
)
const getGroupSettingsSuccess = createAction(
  LibraryManagementFoldersActionTypes.GET_GROUP_SETTINGS_SUCCESS,
  props<{ groupSettings: LibraryManagementFolderGroupSettingsModel[] }>()
)

const setGroupSettings = createAction(
  LibraryManagementFoldersActionTypes.SET_GROUP_SETTINGS,
  props<{ id: number; groupSettings: LibraryManagementFolderGroupSettingsModel[] }>()
)
const setGroupSettingsFail = createAction(
  LibraryManagementFoldersActionTypes.SET_GROUP_SETTINGS_FAIL,
  props<{ error: Error }>()
)
const setGroupSettingsSuccess = createAction(
  LibraryManagementFoldersActionTypes.SET_GROUP_SETTINGS_SUCCESS,
  props<{ id: number; groupSettings: LibraryManagementFolderGroupSettingsModel[] }>()
)

export const LibraryManagementFoldersActions = {
  getAllFolders,
  getAllFoldersTrigger,
  getAllFoldersFail,
  getAllFoldersSuccess,
  getFolders,
  getFoldersFail,
  getFoldersSuccess,
  getArchivedFolders,
  getArchivedFoldersFail,
  getArchivedFoldersSuccess,
  setPage,
  setSort,
  setArchivedPage,
  setArchivedSort,
  getColumns,
  getColumnsSuccess,
  getColumnsFail,
  updateColumnPreference,
  updateColumnPreferenceSuccess,
  updateColumnPreferenceFail,
  createFolder,
  createFolderSuccess,
  createFolderFail,
  updateFolder,
  updateFolderSuccess,
  updateFolderFail,
  setDialogId,
  archiveFolder,
  archiveFolderFail,
  archiveFolderSuccess,
  deleteFolder,
  deleteFolderFail,
  deleteFolderSuccess,
  openMoveFolderModal,
  closeMoveFolderModal,
  moveFolder,
  moveFolderFail,
  moveFolderSuccess,
  createAndMoveFolder,
  createAndMoveFolderFail,
  getGroupSettings,
  getGroupSettingsFail,
  getGroupSettingsSuccess,
  setGroupSettings,
  setGroupSettingsFail,
  setGroupSettingsSuccess,
}

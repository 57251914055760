import {
  LibraryDeliverable,
  LibraryDeliverableEntry,
  LibraryEntriesMap,
} from '@app/features/library-management/store/models/deliverable'

import { LibraryDeliverableState } from '@app/features/library-management/store/models/deliverable'
import { LibraryManagementFeatureState } from '@app/features/library-management/store/models/state'
import { Pagination } from '@app/core/model/definitions/pagination.interface'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { chunk } from 'lodash'
import { createSelector } from '@ngrx/store'
import { getLibraryManagementFeatureState } from './library-management-feature.selectors'

const getLibraryDeliverableState = createSelector(
  getLibraryManagementFeatureState,
  (state: LibraryManagementFeatureState): LibraryDeliverableState => state?.deliverable
)

const selectIsLoading = createSelector(
  getLibraryDeliverableState,
  (state: LibraryDeliverableState): boolean => state?.isLoading
)

const selectDeliverable = createSelector(
  getLibraryDeliverableState,
  (state: LibraryDeliverableState): LibraryDeliverable => state?.deliverable
)

const selectDeliverableEntries = createSelector(
  selectDeliverable,
  (deliverable): LibraryDeliverableEntry[] => deliverable?.libraryDeliverableEntrySet
)

const selectPagination = createSelector(
  getLibraryDeliverableState,
  (state: LibraryDeliverableState): Pagination => state?.pagination
)

const selectPaginatedDeliverableEntries = createSelector(
  selectDeliverableEntries,
  selectPagination,
  (entries: LibraryDeliverableEntry[], pagination: Pagination): LibraryDeliverableEntry[] =>
    chunk(entries, pagination.pageSize)[pagination.page]
)

const selectEntriesMap = createSelector(getLibraryDeliverableState, (state): LibraryEntriesMap => state?.entriesMap)

const selectRateCard = createSelector(getLibraryDeliverableState, (state): RatecardVersion => state?.rateCard)

const selectIsLoadingRateCard = createSelector(getLibraryDeliverableState, (state): boolean => state?.isLoadingRateCard)

export const LibraryDeliverableSelectors = {
  selectIsLoading,
  selectDeliverable,
  selectDeliverableEntries,
  selectPaginatedDeliverableEntries,
  selectPagination,
  selectEntriesMap,
  selectRateCard,
  selectIsLoadingRateCard,
}

<div class="modal-container">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>Override originally assigned modifiers.</h1>
      </div>
      <div class="modal-text mt-2">
        <span class="whitespace-pre-line">The following {{'component.p|l' | lang}} have a modifier. Please review.</span>
      </div>
      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>
  </div>

  <mat-dialog-content aria-label="Add question modal">
    <div class="modal__body flex flex-1 flex-col gap-2 overflow-auto">
      <div class="flex justify-between w-full">
        <div class="flex gap-2 items-center">
          <button mat-button (click)="selectAll()">
            <span>Select all</span>
          </button>
          <mat-divider vertical />
          <span class="modal-text">Selected {{data.overrideComplexities.length - excludeComplexities.size}} of {{data.overrideComplexities.length}}</span>
        </div>
        <button mat-button (click)="clear()">
          <span>Clear</span>
        </button>
      </div>
      <div class="flex flex-col flex-1 overflow-auto components-container">
        <table mat-table [dataSource]="dataSource" (matSortChange)="onSortChange($event)" matSort multiTemplateDataRows>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'component|u' | lang}} NAME
              <sort-icon [column]="'name'" [sortedColumn]="sortedColumn" [sortDirection]="sortDirection"></sort-icon>
            </th>
            <td mat-cell *matCellDef="let element" [class.selected]="selected(element)">
              <div class="flex flex-row items-center gap-2">
                <mat-checkbox [checked]='selected(element)' (change)='select(element)' aria-label='Selected'>
                </mat-checkbox>
                <div class="flex flex-col">
                  <span isEllipsisActive>{{element.name}}</span>
                  <span isEllipsisActive>{{element.rateCardNameAndVersion}} | {{element.disciplineTypeName}}</span>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="complexity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Complexity
              <sort-icon [column]="'complexity'" [sortedColumn]="sortedColumn" [sortDirection]="sortDirection"></sort-icon>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.complexity}} </td>
          </ng-container>
          <ng-container matColumnDef="viewFormula">
            <th mat-header-cell *matHeaderCellDef>Formula</th>
            <td mat-cell *matCellDef="let element">
              <button mat-button (click)="showFormula[element.id] = !showFormula[element.id]">
                <mat-icon class="material-symbols-rounded orange">{{showFormula[element.id] ? 'visibility_off' : 'visibility'}}</mat-icon>
                <span class='tracking-normal'>{{showFormula[element.id] ? 'Hide' : 'View'}}</span>
              </button></td>
          </ng-container>
          <ng-container matColumnDef="formula">
            <td
              mat-cell
              *matCellDef="let element"
              [attr.colspan]="3">
              <formula-builder [(value)]="element.formula" [showInput]="false" [showFormula]="true" ></formula-builder>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['name', 'complexity', 'viewFormula']; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: ['name', 'complexity', 'viewFormula'];"></tr>
          <tr mat-row [hidden]="!showFormula[element.id]" *matRowDef="let element; columns: ['formula']"></tr>
        </table>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="flex gap-2 justify-end">
    <div class="flex-1">
      <button mat-button mat-dialog-close>
        <mat-icon class="material-symbols-rounded">chevron_left</mat-icon>Back
      </button>
    </div>
    <button mat-flat-button class="ml-8" (click)="submit()">
      Override selected
    </button>
  </mat-dialog-actions>
</div>

import { createAction, emptyProps, props } from '@ngrx/store';
import { Activities, PostComment } from '../../model/activity.model';
import { Deliverable, TradedDeliverable } from '@app/features/scoping/models/deliverable.model';
import { ScopeConfiguration, ScopeVersion } from '@app/core/model/scope-version';
import { ScopeRetainedHoursStats } from '../../model/scope-retained-hours-stats.model';
import { SowRetainedHoursStats } from '../../model/sow-retained-hours-stats.model';
import { Money } from '../../model/money.model';
import { ScopeSection } from '../../model/scope-section';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model'
import { SowRetainedTeamStats } from '@app/features/scope-overview/model/sow-retained-team-stats.model'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import {
  RefreshCustomFieldsResponse
} from '@app/features/scope-overview/model/refresh-custom-fields-response.interface'
import {
  ScopeCustomFieldValueStructure
} from '@app/features/scope-overview/model/scope-custom-field-value-structure.model'
import { ScopeTeamMember } from '@core/model/scope-team.model'
import { NotificationScopeUpdate } from '@app/core/model/user-notification.model'
import { ScopeOverviewFilter } from '@core/model/scope-overview-filter.model'
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model'
import { DeliverableTradePayload } from '../../components/track-trade-modal/track-trade-modal.component'
import { ScopeComponent } from '@app/features/scoping/models/component.model'
import { Discount } from '@core/model/discount.model'
import { ScopeMsaLineItem } from '@core/model/scope-msa-line-item.model'
import { Role } from '@app/features/scoping/models/role.model'
import { DeliverableType } from '@app/features/scope-overview/model/deliverable-type'
import { DeliverableSection } from '@app/features/scope-overview/model/deliverable-section'
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'
import { Scenario } from '@app/features/scope-overview/model/scenario.model';

export enum ScopeOverviewActionTypes {
  GET_ACTIVITY_COMMENTS = '[Scope Overview] Get Activity Comments',
  GET_ACTIVITY_COMMENTS_SUCCESS = '[Scope Overview] Get Activity Comments Success',
  GET_ACTIVITY_COMMENTS_FAIL = '[Scope Overview] Get Activity Comments Fail',
  SUBMIT_COMMENT = '[Scope Overview] Submit Activity Comment',
  SUBMIT_COMMENT_SUCCESS = '[Scope Overview] Submit Activity Comment Success',
  SUBMIT_COMMENT_FAIL = '[Scope Overview] Submit Activity Comment Fail',
  SEND_NOTIFICATION_REMINDER = '[Scope Overview] Send Notification Reminder',
  EXPORT_TRAFFIC_SCOPE = '[Scope Overview] Export Traffic Data',
  EXPORT_TRAFFIC_SCOPE_WORKATO = '[Scope Overview] Export Traffic Data Through Workato',
  TRAFFIC_SCOPE_THROUGH_WORKATO = '[Scope Overview] Traffic Data Through Workato',
  EXPORT_TRAFFIC_SCOPE_SUCCESS = '[Scope Overview] Export Traffic Data Success',
  EXPORT_TRAFFIC_SCOPE_FAIL = '[Scope Overview] Export Traffic Data Fail',
  GET_CURRENT_SCOPE = '[Scope Overview] Get Current Scope',
  GET_CURRENT_SCOPE_SUCCESS = '[Scope Overview] Get Current Scope Success',
  GET_CURRENT_SCOPE_FAIL = '[Scope Overview] Get Current Scope Data Fail',
  GET_COMPANY_INTEGRATIONS = '[Scope Overview] Get Company Integrations',
  GET_COMPANY_INTEGRATIONS_SUCCESS = '[Scope Overview] Get Company Integrations Success',
  GET_COMPANY_INTEGRATIONS_FAIL = '[Scope Overview] Get Company Integrations Fail',
  GET_SCOPE_RETAINED_STATS = '[Scope Overview] Get Scope Retained Stats',
  GET_SCOPE_RETAINED_STATS_SUCCESS = '[Scope Overview] Get Scope Retained Stats Success',
  GET_SCOPE_RETAINED_STATS_FAIL = '[Scope Overview] Get Scope Retained Stats Fail',
  UPDATE_SCOPE_RETAINED_STATS = '[Scope Overview] Update Scope Retained Stats',
  UPDATE_SCOPE_RETAINED_STATS_SUCCESS = '[Scope Overview] Update Scope Retained Stats Success',
  UPDATE_SCOPE_RETAINED_STATS_FAIL = '[Scope Overview] Update Scope Retained Stats Fail',
  GET_SOW_RETAINED_STATS = '[Scope Overview] Get Sow Retained Stats',
  GET_SOW_RETAINED_STATS_SUCCESS = '[Scope Overview] Get Sow Retained Stats Success',
  GET_SOW_RETAINED_STATS_FAIL = '[Scope Overview] Get Sow Retained Stats Fail',
  GET_SOW_RETAINED_TEAM_STATS = '[Scope Overview] Get Sow Retained Stats',
  GET_SOW_RETAINED_TEAM_STATS_SUCCESS = '[Scope Overview] Get Sow Retained Stats Success',
  GET_SOW_RETAINED_TEAM_STATS_FAIL = '[Scope Overview] Get Sow Retained Stats Fail',
  GET_SCOPE_CUSTOM_FIELDS_DEFINITION_OUTDATED = '[Scope Overview] Get Scope Custom Fields Outdated',
  GET_SCOPE_CUSTOM_FIELDS_DEFINITION_OUTDATED_SUCCESS = '[Scope Overview] Get Scope Custom Fields Outdated Success',
  GET_SCOPE_CUSTOM_FIELDS_DEFINITION_OUTDATED_FAIL = '[Scope Overview] Get Scope Custom Fields Outdated Fail',
  GET_THIRD_PARTY_BALANCE = '[Scope Overview] Get Third Party Balance',
  GET_THIRD_PARTY_BALANCE_SUCCESS = '[Scope Overview] Get Third Party Balance Success',
  GET_THIRD_PARTY_BALANCE_FAIL = '[Scope Overview] Get Third Party Balance Fail',
  CHANGE_SCOPE_VERSION = '[Scope Overview] Change Scope Version',
  CHANGE_SCOPE_VERSION_SUCCESS = '[Scope Overview] Change Scope Version Success',
  CHANGE_SCOPE_VERSION_FAIL = '[Scope Overview] Change Scope Version Fail',
  ADD_SCOPE_SECTION = '[Scope Overview] Add Scope Section',
  ADD_SCOPE_SECTION_SUCCESS = '[Scope Overview] Add Scope Section Success',
  ADD_SCOPE_SECTION_FAIL = '[Scope Overview] Add Scope Section Fail',
  ADD_NEW_ACTION_ITEM = '[Scope Overview] Add New Action Item',
  ADD_NEW_ACTION_ITEM_SUCCESS = '[Scope Overview] Add New Action Item Success',
  ADD_NEW_ACTION_ITEM_FAIL = '[Scope Overview] Add New Action Item Fail',
  ARCHIVE_SCOPE = '[Scope Overview] Archive Scope Section',
  ARCHIVE_SCOPE_SUCCESS = '[Scope Overview] Archive Scope Section Success',
  ARCHIVE_SCOPE_FAIL = '[Scope Overview] Archive Scope Section Fail',
  UNARCHIVE_SCOPE = '[Scope Overview] Unarchive Scope Section',
  UNARCHIVE_SCOPE_SUCCESS = '[Scope Overview] Unarchive Scope Section Success',
  UNARCHIVE_SCOPE_FAIL = '[Scope Overview] Unarchive Scope Section Fail',
  GET_SCOPE_DELIVERABLE = '[Scope Overview] Get Scope Deliverable',
  GET_SCOPE_DELIVERABLE_SUCCESS = '[Scope Overview] Get Scope Deliverable Success',
  GET_SCOPE_DELIVERABLE_FAIL = '[Scope Overview] Get Scope Deliverable Fail',
  GET_ALL_TASKS = '[Scope Overview] Get Scope By Role Deliverables',
  GET_ALL_TASKS_SUCCESS = '[Scope Overview] Get Scope By Role Deliverables Success',
  GET_ALL_TASKS_FAIL = '[Scope Overview] Get Scope By Role Deliverables Fail',
  GET_AVAILABLE_DELIVERABLE_TYPES = '[Scope Overview] Get Available Deliverable Types',
  GET_AVAILABLE_DELIVERABLE_TYPES_SUCCESS = '[Scope Overview] Get Available Deliverable Types Success',
  GET_AVAILABLE_DELIVERABLE_TYPES_FAIL = '[Scope Overview] Get Available Deliverable Types Fail',
  ADD_SCOPE_DELIVERABLE = '[Scope Overview] Add Scope Deliverable',
  ADD_SCOPE_DELIVERABLE_SUCCESS = '[Scope Overview] Add Scope Deliverable Success',
  ADD_SCOPE_DELIVERABLE_FAIL = '[Scope Overview] Add Scope Deliverable Fail',
  ADD_SCOPE_DELIVERABLES_SUCCESS = '[Scope Overview] Add Scope Deliverables Success',
  DUPLICATE_SCOPE_DELIVERABLE = '[Scope Overview] Duplicate Scope Deliverable',
  DUPLICATE_SCOPE_DELIVERABLE_SUCCESS = '[Scope Overview] Duplicate Scope Deliverable Success',
  DUPLICATE_SCOPE_DELIVERABLE_FAIL = '[Scope Overview] Duplicate Scope Deliverable Fail',
  MOVE_COMPONENT_TO_DELIVERABLE = '[Scope Overview] Move component to deliverable',
  MOVE_COMPONENT_TO_DELIVERABLE_SUCCESS = '[Scope Overview] Move component to deliverable Success',
  MOVE_COMPONENT_TO_DELIVERABLE_FAIL = '[Scope Overview] Move component to deliverable Fail',
  UPDATE_SCOPE_DELIVERABLE = '[Scope Overview] Update Scope Deliverable',
  UPDATE_SCOPE_DELIVERABLE_SUCCESS = '[Scope Overview] Update Scope Deliverable Success',
  UPDATE_SCOPE_DELIVERABLE_FAIL = '[Scope Overview] Update Scope Deliverable Fail',
  CLEAR_SCOPE_TASK_ROLES = '[Scope Overview] Clear Scope Task Roles',
  CLEAR_SCOPE_TASK_ROLES_SUCCESS = '[Scope Overview] Clear Scope Task Roles Success',
  CLEAR_SCOPE_TASK_ROLES_FAIL = '[Scope Overview] Clear Scope Task Roles Fail',
  UPDATE_SCOPE_SECTION = '[Scope Overview] Update Scope Section',
  UPDATE_SCOPE_SECTION_SUCCESS = '[Scope Overview] Update Scope Section Success',
  UPDATE_SCOPE_SECTION_FAIL = '[Scope Overview] Update Scope Section Fail',
  UPDATE_SCOPE_COMPONENT = '[Scope Overview] Update Scope Component',
  UPDATE_SCOPE_COMPONENT_SUCCESS = '[Scope Overview] Update Scope Component Success',
  UPDATE_SCOPE_COMPONENT_FAIL = '[Scope Overview] Update Scope Component Fail',
  UPDATE_DELIVERABLE_SECTION = '[Scope Overview] Update Deliverable Section',
  UPDATE_DELIVERABLE_SECTION_SUCCESS = '[Scope Overview] Update Deliverable Section Success',
  UPDATE_DELIVERABLE_SECTION_FAIL = '[Scope Overview] Update Deliverable Section Fail',
  OVERRIDE_SCOPE_COMPONENT_SUCCESS = '[Scope Overview] Override Scope Component Success',
  OVERRIDE_ROLE_SUCCESS = '[Scope Overview] Override Role Success',
  UPDATE_ROLE = '[Scope Overview] Update Role',
  UPDATE_ROLE_SUCCESS = '[Scope Overview] Update Role Success',
  CREATE_SCOPE_TPC_SUCCESS = '[Scope Overview] Create TPC Success',
  UPDATE_SCOPE_TPC_SUCCESS = '[Scope Overview] Update TPC Success',
  DELETE_SCOPE_TPC_SUCCESS = '[Scope Overview] Delete TPC Success',
  UPDATE_ROLE_FAIL = '[Scope Overview] Update Role Faile',
  CREATE_LIBRARY_DELIVERABLE_TEMPLATE = '[Scope Overview] Create Library Delivery Template',
  CREATE_LIBRARY_DELIVERABLE_TEMPLATE_SUCCESS = '[Scope Overview] Create Library Delivery Template Success',
  CREATE_LIBRARY_DELIVERABLE_TEMPLATE_FAIL = '[Scope Overview] Create Library Delivery Template Fail',
  CREATE_LIBARY_DELIVERABLE_ENTRY = '[Scope Overview] Create Library Delivery Entry',
  CREATE_LIBARY_DELIVERABLE_ENTRY_SUCCESS = '[Scope Overview] Create Library Delivery Entry Success',
  CREATE_LIBARY_DELIVERABLE_ENTRY_FAIL = '[Scope Overview] Create Library Delivery Entry Fail',
  GET_OUTPUT_TEMPLATE = '[Scope Overview] Get Output Template',
  GET_OUTPUT_TEMPLATE_SUCCESS = '[Scope Overview] Get Output Template Success',
  GET_OUTPUT_TEMPLATE_FAIL = '[Scope Overview] Get Output Template Fail',
  UPDATE_TEMPLATE = '[Scope Overview] Update Template',
  UPDATE_TEMPLATE_SUCCESS = '[Scope Overview] Update Template Success',
  UPDATE_TEMPLATE_FAIL = '[Scope Overview] Update Template Fail',
  PREVIEW_SCOPE_DOCX = '[Scope Overview] Preview Scope Docx',
  PREVIEW_SCOPE_DOCX_SUCCESS = '[Scope Overview] Preview Scope Docx Success',
  PREVIEW_SCOPE_DOCX_FAIL = '[Scope Overview] Preview Scope Docx Fail',
  GET_SECOND_PARTY = '[Scope Overview] Get Second Party',
  GET_SECOND_PARTY_SUCCESS = '[Scope Overview] Get Second Party Success',
  GET_SECOND_PARTY_FAIL = '[Scope Overview] Get Second Party Fail',
  GET_OFFICE_LOCATION = '[Scope Overview] Get Office Location',
  GET_OFFICE_LOCATION_SUCCESS = '[Scope Overview] Get Office Location Success',
  GET_OFFICE_LOCATION_FAIL = '[Scope Overview] Get Office Location Fail',
  GET_ALL_RATE_CARDS_FOR_SCOPE = '[Scope Overview] Get All Rate Cards For Scope',
  GET_ALL_RATE_CARDS_FOR_SCOPE_SUCCESS = '[Scope Overview] Get All Rate Cards For Scope Success',
  GET_ALL_RATE_CARDS_FOR_SCOPE_FAIL = '[Scope Overview] Get All Rate Cards For Scope Fail',
  GET_DEFAULT_RATE_CARD = '[Scope Overview] Get Default Rate Card',
  GET_DEFAULT_RATE_CARD_SUCCESS = '[Scope Overview] Get Default Rate Card Success',
  GET_DEFAULT_RATE_CARD_FAIL = '[Scope Overview] Get Default Rate Card Fail',
  DELETE_SCOPE_SECTION = '[Scope Overview] Delete Scope Section',
  DELETE_SCOPE_SECTION_SUCCESS = '[Scope Overview] Delete Scope Section Success',
  DELETE_SCOPE_SECTION_FAIL = '[Scope Overview] Delete Scope Section Fail',
  UPDATE_SCOPE = '[Scope Overview] Update Scope Details',
  UPDATE_SCOPE_SUCCESS = '[Scope Overview] Update Scope Details Success',
  UPDATE_SCOPE_FAIL = '[Scope Overview] Update Scope Details Fail',
  REFRESH_OUTDATED_CUSTOM_FIELDS = '[Scope Overview] Refresh Outdated Custom Fields',
  REFRESH_OUTDATED_CUSTOM_FIELDS_SUCCESS = '[Scope Overview] Refresh Outdated Custom Fields Success',
  REFRESH_OUTDATED_CUSTOM_FIELDS_FAIL = '[Scope Overview] Refresh Outdated Custom Fields Fail',
  ADD_SCOPE_COLLABORATOR = '[Scope Overview] ADD_SCOPE_COLLABORATOR',
  ADD_SCOPE_COLLABORATOR_SUCCESS = '[Scope Overview] ADD_SCOPE_COLLABORATOR_SUCCESS',
  ADD_SCOPE_REVIEWER = '[Scope Overview] ADD_SCOPE_REVIEWER',
  ADD_SCOPE_REVIEWER_SUCCESS = '[Scope Overview] ADD_SCOPE_REVIEWER_SUCCESS',
  ADD_SCOPE_APPROVER = '[Scope Overview] ADD_SCOPE_APPROVER',
  ADD_SCOPE_APPROVER_SUCCESS = '[Scope Overview] ADD_SCOPE_APPROVER_SUCCESS',
  ADD_SCOPE_TRAFFICKER = '[Scope Overview] ADD_SCOPE_TRAFFICKER',
  ADD_SCOPE_TRAFFICKER_SUCCESS = '[Scope Overview] ADD_SCOPE_TRAFFICKER_SUCCESS',
  UPDATE_TEAM_MEMBER = '[Scope Overview] UPDATE_TEAM_MEMBER',
  UPDATE_TEAM_MEMBER_SUCCESS = '[Scope Overview] UPDATE_TEAM_MEMBER_SUCCESS',
  REMOVE_SCOPE_COLLABORATOR = '[Scope Overview] REMOVE_SCOPE_COLLABORATOR',
  REMOVE_SCOPE_COLLABORATOR_SUCCESS = '[Scope Overview] REMOVE_SCOPE_COLLABORATOR_SUCCESS',
  REMOVE_SCOPE_REVIEWER = '[Scope Overview] REMOVE_SCOPE_REVIEWER',
  REMOVE_SCOPE_REVIEWER_SUCCESS = '[Scope Overview] REMOVE_SCOPE_REVIEWER_SUCCESS',
  REMOVE_SCOPE_APPROVER = '[Scope Overview] REMOVE_SCOPE_APPROVER',
  REMOVE_SCOPE_APPROVER_SUCCESS = '[Scope Overview] REMOVE_SCOPE_APPROVER_SUCCESS',
  REMOVE_SCOPE_TRAFFICKER = '[Scope Overview] REMOVE_SCOPE_TRAFFICKER',
  REMOVE_SCOPE_TRAFFICKER_SUCCESS = '[Scope Overview] REMOVE_SCOPE_TRAFFICKER_SUCCESS',
  SCOPE_TEAM_ERROR = '[Scope Overview] SCOPE_TEAM_ERROR',
  DELETE_DELIVERABLE = '[Scope Overview] Delete Deliverable',
  DELETE_DELIVERABLE_SUCCESS = '[Scope Overview] Delete Deliverable Success',
  DELETE_DELIVERABLE_FAIL = '[Scope Overview] Delete Deliverable Fail',
  DELETE_COMPONENT_FEE_SUCCESS = '[Scope Overview] Delete Component Fee Success',
  DELETE_COMPONENT_FEE_FAIL = '[Scope Overview] Delete Component Fee Fail',
  DELETE_DELIVERABLE_FEE_ITEM_SUCCESS = '[Scope Overview] Delete Deliverable Fee Item Success',
  DELETE_DELIVERABLE_FEE_ITEM_FAIL = '[Scope Overview] Delete Deliverable Fee Item Fail',
  DELETE_SCOPE_FEE_ITEM = '[Scope Overview] Delete Scope Fee Item',
  DELETE_SCOPE_FEE_ITEM_SUCCESS = '[Scope Overview] Delete Scope Fee Item Success',
  DELETE_SCOPE_FEE_ITEM_FAIL = '[Scope Overview] Delete Scope Fee Item Fail',
  DELETE_SECTION_FEE_ITEM = '[Scope Overview] Delete Section Fee Item',
  DELETE_SECTION_FEE_ITEM_SUCCESS = '[Scope Overview] Delete Section Fee Item Success',
  DELETE_SECTION_FEE_ITEM_FAIL = '[Scope Overview] Delete Section Fee Item Fail',
  SET_SCOPE_NOTIFICATION_EVENT = '[Scope Overview] Set Notification Archived Scope',
  FILTER_DELIVERABLES = '[Scope Overview] Filter Deliverables',
  FILTER_DELIVERABLES_FAIL = '[Scope Overview] Filter Deliverables Fail',
  FILTER_DELIVERABLES_SUCCESS = '[Scope Overview] Filter Deliverables Success',
  CREATE_SCOPE_FEE_ITEM_SUCCESS = '[Scope Overview] Create Scope Fee Item Success',
  CREATE_SECTION_FEE_ITEM_SUCCESS = '[Scope Overview] Create Section Fee Item Success',
  CREATE_DELIVERABLE_FEE_ITEM_SUCCESS = '[Scope Overview] Create Deliverable Fee Item Success',
  CREATE_COMPONENT_FEE_ITEM_SUCCESS = '[Scope Overview] Create Component Fee Item Success',
  EDIT_SCOPE_FEE_ITEM_SUCCESS = '[Scope Overview] Edit Scope Fee Item Success',
  EDIT_SECTION_FEE_ITEM_SUCCESS = '[Scope Overview] Edit Section Fee Item Success',
  EDIT_DELIVERABLE_FEE_ITEM_SUCCESS = '[Scope Overview] Edit Deliverable Fee Item Success',
  EDIT_COMPONENT_FEE_ITEM_SUCCESS = '[Scope Overview] Edit Component Fee Item Success',
  UPDATE_SCOPE_DISCOUNT_SUCCESS = '[Scope Overview] Update Scope Discount Success',
  UPDATE_SCOPE_MSA_SUCCESS = '[Scope Overview] Update Scope Msa Line Item Success',
  UPDATE_SCOPE_SECTIONS_ORDER = '[Scope Overview] Update Scope Sections Order',
  UPDATE_FINANCIALS_ORDER = '[Scope Overview] Update Financials Order',
  UPDATE_SCOPE_SECTIONS_ORDER_SUCCESS = '[Scope Overview] Update Scope Sections Order Success',
  UPDATE_FINANCIALS_ORDER_SUCCESS = '[Scope Overview] Update Scope Sections Order Success',
  UPDATE_DELIVERABLES_ORDER = '[Scope Overview] Update Deliverables Order',
  UPDATE_DELIVERABLES_ORDER_SUCCESS = '[Scope Overview] Update Deliverables Order Success',
  DELIVERABLE_TYPE_SEARCH = '[Scope Overview] Deliverable Type Search (Predict)',
  DELIVERABLE_TYPE_SEARCH_FAIL = '[Scope Overview] Deliverable Type Search (Predict) Fail',
  DELIVERABLE_TYPE_SEARCH_SUCCESS = '[Scope Overview] Deliverable Type Search (Predict) Success',
  INITIATE_TRADE = '[Scope Overview] Initiate Deliverable Trade',
  INITIATE_TRADE_FAIL = '[Scope Overview] Initiate Deliverable Trade Fail',
  INITIATE_TRADE_SUCCESS = '[Scope Overview] Initiate Deliverable Trade Success',
  REOPEN_TRADE = '[Scope Overview] Reopen Deliverable Trade',
  REOPEN_FAIL = '[Scope Overview] Reopen Deliverable Trade Fail',
  REOPEN_SUCCESS = '[Scope Overview] Reopen Deliverable Trade Success',
  UPDATE_CURRENT_SCOPE = '[Scope Overview] Update Current Scope',
  UPDATE_CURRENT_SCOPE_FAIL = '[Scope Overview] Update Current Scope Fail',
  UPDATE_CURRENT_SCOPE_SUCCESS = '[Scope Overview] Update Current Scope Success',
  STOP_TRADE = '[Scope Overview] Stop Deliverable Trade',
  STOP_TRADE_FAIL = '[Scope Overview] Stop Deliverable Trade Fail',
  STOP_TRADE_SUCCESS = '[Scope Overview] Stop Deliverable Trade Success',
  COMPLETE_TRADE = '[Scope Overview] Complete Deliverable Trade',
  COMPLETE_TRADE_FAIL = '[Scope Overview] Complete Deliverable Trade Fail',
  COMPLETE_TRADE_SUCCESS = '[Scope Overview] Complete Deliverable Trade Success',
  CANCEL_TRADE = '[Scope Overview] Cancel Deliverable Trade',
  CANCEL_TRADE_FAIL = '[Scope Overview] Cancel Deliverable Trade Fail',
  CANCEL_TRADE_SUCCESS = '[Scope Overview] Cancel Deliverable Trade Success',
  CONFIRM_TRADE = '[Scope Overview] Confirm Deliverable Trade',
  CONFIRM_TRADE_FAIL = '[Scope Overview] Confirm Deliverable Trade Fail',
  CONFIRM_TRADE_SUCCESS = '[Scope Overview] Confirm Deliverable Trade Success',
  APPLY_DELIVERABLE_LOCATION_CARD = '[Scope Overview] Apply Deliverable Location Card',
  APPLY_DELIVERABLE_LOCATION_CARD_FAIL = '[Scope Overview] Apply Deliverable Location Card Fail',
  APPLY_DELIVERABLE_LOCATION_CARD_SUCCESS = '[Scope Overview] Apply Deliverable Location Card Success',
  DELETE_SCOPE_DISCOUNT = '[Scope Overview] Delete Scope Discount',
  DELETE_SCOPE_DISCOUNT_FAIL = '[Scope Overview] Delete Scope Discount Fail',
  DELETE_SCOPE_DISCOUNT_SUCCESS = '[Scope Overview] Delete Scope Discount Success',
  DELETE_SCOPE_MSA = '[Scope Overview] Delete Scope Msa',
  DELETE_SCOPE_MSA_FAIL = '[Scope Overview] Delete Scope Msa Fail',
  DELETE_SCOPE_MSA_SUCCESS = '[Scope Overview] Delete Scope Msa Success',
  ADD_DELIVERABLE_COMMENT_SUCCESS = '[Scope Overview] Add Deliverable Comment Success',
  ADD_COMPONENT_COMMENT_SUCCESS = '[Scope Overview] Add Component Comment Success',
  ADD_DEPARTMENT_COMMENT_SUCCESS = '[Scope Overview] Add Department Comment Success',
  ADD_ROLE_COMMENT_SUCCESS = '[Scope Overview] Add Role Comment Success',
  ADD_DELIVERABLE_SECTION_COMMENT_SUCCESS = '[Scope Overview] Add Deliverable Section Comment Success',
  ADD_SCOPE_SECTION_COMMENT_SUCCESS = '[Scope Overview] Add Scope Section Comment Success',
  ADD_DELIVERABLE_TPC_COMMENT_SUCCESS = '[Scope Overview] Add Deliverable TPC Comment Success',
  ADD_SCOPE_TPC_COMMENT_SUCCESS = '[Scope Overview] Add Scope TPC Comment Success',
  ADD_COMPONENT_TPC_SUCCESS = '[Scope Overview] Add Component TPC Comment Success',
  ADD_SCOPE_COMMENT_SUCCESS = '[Scope Overview] Add Scope Comment Success',
  GET_DELIVERABLE_COMPONENT_FEES = '[Scope Overview] Get Deliverable and Component Fees',
  GET_DELIVERABLE_COMPONENT_FEES_SUCCESS = '[Scope Overview] Get Deliverable and Component Fees Success',
  GET_DELIVERABLE_COMPONENT_FEES_FAIL = '[Scope Overview] Get Deliverable and Component Fees Fail',
  GET_ALL_DELIVERABLE_TPCS = '[Scope Overview] Get All Deliverable Third Party Costs',
  GET_ALL_DELIVERABLE_TPCS_SUCCESS = '[Scope Overview] Get All Deliverable Third Party Costs Success',
  GET_ALL_DELIVERABLE_TPCS_FAIL = '[Scope Overview] Get All Deliverable Third Party Costs Fail',
  SEARCH_COMPONENTS_FOR_SCOPE = '[Scope Overview] Search Components for Scope',
  SEARCH_COMPONENTS_FOR_SCOPE_FAIL = '[Scope Overview] Search Components for Scope Fail',
  SEARCH_COMPONENTS_FOR_SCOPE_SUCCESS = '[Scope Overview] Search Components for Scope Success',
  SEARCH_NON_DISTINCT_COMPONENTS = '[Scope Overview] Search Non-distinct Components for Scope',
  SEARCH_NON_DISTINCT_COMPONENTS_FAIL = '[Scope Overview] Search Non-distinct Components for Scope Fail',
  SEARCH_NON_DISTINCT_COMPONENTS_SUCCESS = '[Scope Overview] Search Non-distinct Components for Scope Success',
  SEARCH_DEPARTMENTS_FOR_SCOPE = '[Scope Overview] Search Departments for Scope',
  SEARCH_DEPARTMENTS_FOR_SCOPE_FAIL = '[Scope Overview] Search Departments for Scope Fail',
  SEARCH_DEPARTMENTS_FOR_SCOPE_SUCCESS = '[Scope Overview] Search Departments for Scope Success',
  SEARCH_ROLES_FOR_SCOPE = '[Scope Overview] Search Roles for Scope',
  SEARCH_ROLES_FOR_SCOPE_FAIL = '[Scope Overview] Search Roles for Scope Fail',
  SEARCH_ROLES_FOR_SCOPE_SUCCESS = '[Scope Overview] Search Roles for Scope Success',
  APPLY_LOCATION_TO_ROLE = '[Scope Overview] Apply Location to Role',
  APPLY_LOCATION_TO_ROLE_FAIL = '[Scope Overview] Apply Location to Role Fail',
  APPLY_LOCATION_TO_ROLE_SUCCESS = '[Scope Overview] Apply Location to Role Success',
  SET_DIALOG_ID = '[Scope Overview] Set Dialog ID',
  GET_SCENARIO = '[Scope Overview] Load Scenario',
  GET_SCENARIO_SUCCESS = '[Scope Overview] Load Scenario Success',
  GET_SCENARIO_FAIL = '[Scope Overview] Load Scenario Fail',
  SAVE_CONFIGURATION = '[Scope Overview] Save Configuration',
  SAVE_CONFIGURATION_SUCCESS = '[Scope Overview] Save Configuration Success',
  SAVE_CONFIGURATION_FAIL = '[Scope Overview] Save Configuration Fail',
  SUBMIT_CONFIGURATION = '[Scope Overview] Submit Configuration',
  SUBMIT_CONFIGURATION_SUCCESS = '[Scope Overview] Submit Configuration Success',
  SUBMIT_CONFIGURATION_FAIL = '[Scope Overview] Submit Configuration Fail',
  UPDATE_SCENARIO = '[Scope Overview] Update Scenario',
  RESET_SCENARIO = '[Scope Overview] Reset Scenario',
}

const setScopeNotificationEvent = createAction(ScopeOverviewActionTypes.SET_SCOPE_NOTIFICATION_EVENT, props<{ scopeNotificationProps: NotificationScopeUpdate }>())

const addNewAction = createAction(ScopeOverviewActionTypes.ADD_NEW_ACTION_ITEM, props<{ scopeId?: number }>());

const addNewActionSuccess = createAction(
  ScopeOverviewActionTypes.ADD_NEW_ACTION_ITEM_SUCCESS,
  props<{ scopeId?: number }>()
);

const addNewActionFail = createAction(ScopeOverviewActionTypes.ADD_NEW_ACTION_ITEM_FAIL, props<{ error?: string }>());

const createLibraryDeliverableTemplate = createAction(
  ScopeOverviewActionTypes.CREATE_LIBRARY_DELIVERABLE_TEMPLATE,
  props<{ scopeId?: number; deliverable: Deliverable }>()
);

const createLibraryDeliverableTemplateSuccess = createAction(
  ScopeOverviewActionTypes.CREATE_LIBRARY_DELIVERABLE_TEMPLATE_SUCCESS,
  props<{ template?: any }>()
);

const createLibraryDeliverableTemplateFail = createAction(
  ScopeOverviewActionTypes.CREATE_LIBRARY_DELIVERABLE_TEMPLATE_FAIL,
  props<{ error?: string }>()
);

const createLibraryDeliverableEntry = createAction(
  ScopeOverviewActionTypes.CREATE_LIBARY_DELIVERABLE_ENTRY,
  props<{ templateId?: any; entry: any }>()
);

const createLibraryDeliverableEntrySuccess = createAction(
  ScopeOverviewActionTypes.CREATE_LIBARY_DELIVERABLE_ENTRY_SUCCESS,
  props<{ updateEntry?: string }>()
);

const createLibraryDeliverableEntryFail = createAction(
  ScopeOverviewActionTypes.CREATE_LIBARY_DELIVERABLE_ENTRY_FAIL,
  props<{ error?: string }>()
);

const getOutputTemplate = createAction(ScopeOverviewActionTypes.GET_OUTPUT_TEMPLATE, props<{ scopeId: number }>());

const getOutputTemplateSuccess = createAction(
  ScopeOverviewActionTypes.GET_OUTPUT_TEMPLATE_SUCCESS,
  props<{ template: OutputTemplate }>()
);

const getOutputTemplateFail = createAction(
  ScopeOverviewActionTypes.GET_OUTPUT_TEMPLATE_FAIL,
  props<{ error?: string }>()
);

const updateTemplate = createAction(ScopeOverviewActionTypes.UPDATE_TEMPLATE, props<{ template: OutputTemplate }>());

const updateTemplateSuccess = createAction(
  ScopeOverviewActionTypes.UPDATE_TEMPLATE_SUCCESS,
  props<{ template: OutputTemplate }>()
);

const updateTemplateFail = createAction(ScopeOverviewActionTypes.UPDATE_TEMPLATE_FAIL, props<{ error?: string }>());

const previewScopeDocx = createAction(
  ScopeOverviewActionTypes.PREVIEW_SCOPE_DOCX,
  props<{ scopeId: number }>()
);

const previewScopeDocxSuccess = createAction(
  ScopeOverviewActionTypes.PREVIEW_SCOPE_DOCX_SUCCESS,
  props<{ preview: any }>()
);

const previewScopeDocxFail = createAction(
  ScopeOverviewActionTypes.PREVIEW_SCOPE_DOCX_FAIL,
  props<{ error?: string }>()
);

const getSecondParty = createAction(ScopeOverviewActionTypes.GET_SECOND_PARTY, props<{ secondPartyId: number }>());

const getSecondPartySuccess = createAction(
  ScopeOverviewActionTypes.GET_SECOND_PARTY_SUCCESS,
  props<{ secondParty: any }>()
);

const getSecondPartyFail = createAction(ScopeOverviewActionTypes.GET_SECOND_PARTY_FAIL, props<{ error?: string }>());

const getOfficeLocation = createAction(
  ScopeOverviewActionTypes.GET_OFFICE_LOCATION,
  props<{ officeLocationId: number }>()
);

const getOfficeLocationSuccess = createAction(
  ScopeOverviewActionTypes.GET_OFFICE_LOCATION_SUCCESS,
  props<{ officeLocation: any }>()
);

const getOfficeLocationFail = createAction(
  ScopeOverviewActionTypes.GET_OFFICE_LOCATION_FAIL,
  props<{ error?: string }>()
);

// FEE CRUD
const deleteComponentFeeSuccess = createAction(
  ScopeOverviewActionTypes.DELETE_COMPONENT_FEE_SUCCESS,
  props<{ deliverableId: number; componentId: number; feeItemId: number }>()
);

const deleteComponentFeeFail = createAction(
  ScopeOverviewActionTypes.DELETE_COMPONENT_FEE_FAIL,
  props<{ error?: string }>()
);

const deleteDeliverableFeeItemSuccess = createAction(
  ScopeOverviewActionTypes.DELETE_DELIVERABLE_FEE_ITEM_SUCCESS,
  props<{ deliverableId: number; feeItemId: number }>()
);

const deleteDeliverableFeeItemFail = createAction(
  ScopeOverviewActionTypes.DELETE_DELIVERABLE_FEE_ITEM_FAIL,
  props<{ error?: string }>()
);

// (Scope Fee)
const deleteScopeFeeItem = createAction(
  ScopeOverviewActionTypes.DELETE_SCOPE_FEE_ITEM,
  props<{ scopeId: number; feeItemId: number }>()
);

const deleteScopeFeeItemSuccess = createAction(
  ScopeOverviewActionTypes.DELETE_SCOPE_FEE_ITEM_SUCCESS,
  props<{ feeItemId: number }>()
);

const deleteScopeFeeItemFail = createAction(
  ScopeOverviewActionTypes.DELETE_SCOPE_FEE_ITEM_FAIL,
  props<{ error?: string }>()
);

// (Section Fee)
const deleteSectionFeeItem = createAction(
  ScopeOverviewActionTypes.DELETE_SECTION_FEE_ITEM,
  props<{ scopeId: number; sectionId: number; feeItemId: number }>()
);

const deleteSectionFeeItemSuccess = createAction(
  ScopeOverviewActionTypes.DELETE_SECTION_FEE_ITEM_SUCCESS,
  props<{ sectionId: number; feeItemId: number }>()
);

const deleteSectionFeeItemFail = createAction(
  ScopeOverviewActionTypes.DELETE_SECTION_FEE_ITEM_FAIL,
  props<{ error?: string }>()
);

// DELIVRABLES CRUD
const addScopeDeliverable = createAction(
  ScopeOverviewActionTypes.ADD_SCOPE_DELIVERABLE,
  props<{ scopeId?: number; deliverable: Deliverable; editLibDelEntryId? : number; quickAdd: boolean }>()
);

const addScopeDeliverableSuccess = createAction(
  ScopeOverviewActionTypes.ADD_SCOPE_DELIVERABLE_SUCCESS,
  props<{ deliverable?: Deliverable }>()
);

const addScopeDeliverableFail = createAction(
  ScopeOverviewActionTypes.ADD_SCOPE_DELIVERABLE_FAIL,
  props<{ error?: string }>()
);

const addScopeDeliverablesSuccess = createAction(
  ScopeOverviewActionTypes.ADD_SCOPE_DELIVERABLES_SUCCESS,
  props<{ deliverables: Deliverable[] }>()
);

const duplicateScopeDeliverable = createAction(
  ScopeOverviewActionTypes.DUPLICATE_SCOPE_DELIVERABLE,
  props<{ scopeId: number; deliverableId: number }>()
);

const duplicateScopeDeliverableSuccess = createAction(
  ScopeOverviewActionTypes.DUPLICATE_SCOPE_DELIVERABLE_SUCCESS,
  props<{ deliverable?: Deliverable }>()
);

const duplicateScopeDeliverableFail = createAction(
  ScopeOverviewActionTypes.DUPLICATE_SCOPE_DELIVERABLE_FAIL,
  props<{ error?: string }>()
);

const moveComponentToDeliverable = createAction(
  ScopeOverviewActionTypes.MOVE_COMPONENT_TO_DELIVERABLE,
  props<{ scopeId: number, componentId: number; deliverableId: number, name: string, order: number }>()
);

const moveComponentToDeliverableSuccess = createAction(
  ScopeOverviewActionTypes.MOVE_COMPONENT_TO_DELIVERABLE_SUCCESS,
  props<{ deliverable?: Deliverable }>()
);

const moveComponentToDeliverableFail = createAction(
  ScopeOverviewActionTypes.MOVE_COMPONENT_TO_DELIVERABLE_FAIL,
  props<{ error?: string }>()
);

const updateScopeDeliverable = createAction(
  ScopeOverviewActionTypes.UPDATE_SCOPE_DELIVERABLE,
  props<{ scopeId: number; deliverable: Deliverable }>()
);

const updateScopeDeliverableSuccess = createAction(
  ScopeOverviewActionTypes.UPDATE_SCOPE_DELIVERABLE_SUCCESS,
  props<{ deliverable: Deliverable }>()
);

const updateScopeDeliverableFail = createAction(
  ScopeOverviewActionTypes.UPDATE_SCOPE_DELIVERABLE_FAIL,
  props<{ error?: string }>()
);

const clearScopeTaskRoles = createAction(
  ScopeOverviewActionTypes.CLEAR_SCOPE_TASK_ROLES,
  props<{ scopeId: number; taskId: number }>()
);

const clearScopeTaskRolesSuccess = createAction(
  ScopeOverviewActionTypes.CLEAR_SCOPE_TASK_ROLES_SUCCESS,
  props<{ taskId: number }>()
);

const clearScopeTaskRolesFail = createAction(
  ScopeOverviewActionTypes.CLEAR_SCOPE_TASK_ROLES_FAIL,
  props<{ error?: string }>()
);

const updateScopeSection = createAction(
  ScopeOverviewActionTypes.UPDATE_SCOPE_SECTION,
  props<{ scopeId: number; section: ScopeSection }>()
);

const updateScopeSectionSuccess = createAction(
  ScopeOverviewActionTypes.UPDATE_SCOPE_SECTION_SUCCESS,
  props<{ section: ScopeSection }>()
);

const updateScopeSectionFail = createAction(
  ScopeOverviewActionTypes.UPDATE_SCOPE_SECTION_FAIL,
  props<{ error?: string }>()
);

const updateScopeComponent = createAction(
  ScopeOverviewActionTypes.UPDATE_SCOPE_COMPONENT,
  props<{ scopeId: number; deliverableId: number; component: ScopeComponent; deliverable: Deliverable }>()
);

const updateScopeComponentSuccess = createAction(
  ScopeOverviewActionTypes.UPDATE_SCOPE_COMPONENT_SUCCESS,
  props<{ component: ScopeComponent }>()
);

const updateScopeComponentFail = createAction(
  ScopeOverviewActionTypes.UPDATE_SCOPE_COMPONENT_FAIL,
  props<{ error?: string }>()
);

const updateDeliverableSection = createAction(
  ScopeOverviewActionTypes.UPDATE_DELIVERABLE_SECTION,
  props<{ scopeId: number; section: DeliverableSection }>()
);

const updateDeliverableSectionSuccess = createAction(
  ScopeOverviewActionTypes.UPDATE_DELIVERABLE_SECTION_SUCCESS,
  props<{ section: DeliverableSection }>()
);

const updateDeliverableSectionFail = createAction(
  ScopeOverviewActionTypes.UPDATE_DELIVERABLE_SECTION_FAIL,
  props<{ error?: string }>()
);

const overrideScopeComponentSuccess = createAction(
  ScopeOverviewActionTypes.OVERRIDE_SCOPE_COMPONENT_SUCCESS,
  props<{ component: ScopeComponent }>()
);

const overrideRoleSuccess = createAction(
  ScopeOverviewActionTypes.OVERRIDE_ROLE_SUCCESS,
  props<{ component: ScopeComponent, role: Role }>()
);

const updateRole = createAction(
  ScopeOverviewActionTypes.UPDATE_ROLE,
  props<{ scopeId: number, taskId: number, role: Role, updateValue: any }>()
);

const updateRoleSuccess = createAction(
  ScopeOverviewActionTypes.UPDATE_ROLE_SUCCESS,
  props<{ taskId: number, role: Role }>()
);

const updateRoleFail = createAction(
  ScopeOverviewActionTypes.UPDATE_ROLE_FAIL,
  props<{ error?: string }>()
);

const createScopeTpcSuccess = createAction(
  ScopeOverviewActionTypes.CREATE_SCOPE_TPC_SUCCESS,
  props<{ thirdPartyCost: ThirdPartyCost }>()
);

const updateScopeTpcSuccess = createAction(
  ScopeOverviewActionTypes.UPDATE_SCOPE_TPC_SUCCESS,
  props<{ thirdPartyCost: ThirdPartyCost }>()
);

const deleteScopeTpcSuccess = createAction(
  ScopeOverviewActionTypes.DELETE_SCOPE_TPC_SUCCESS,
  props<{ thirdPartyCost: ThirdPartyCost }>()
);

const getAvailableScopeDeliverableTypes = createAction(
  ScopeOverviewActionTypes.GET_AVAILABLE_DELIVERABLE_TYPES,
  props<{ mergeLibraryDeliverableEntryStrategy?: string }>()
);

const getAvailableScopeDeliverableTypesSuccess = createAction(
  ScopeOverviewActionTypes.GET_AVAILABLE_DELIVERABLE_TYPES_SUCCESS,
  props<{ availableDeliverableTypes?: DeliverableType[] }>()
);

const getAvailableScopeDeliverableTypesFail = createAction(
  ScopeOverviewActionTypes.GET_AVAILABLE_DELIVERABLE_TYPES_FAIL,
  props<{ error?: string }>()
);

const getScopeDeliverable = createAction(
  ScopeOverviewActionTypes.GET_SCOPE_DELIVERABLE,
  props<{ scopeId?: number; deliverableId: number }>()
);

const getScopeDeliverableSuccess = createAction(
  ScopeOverviewActionTypes.GET_SCOPE_DELIVERABLE_SUCCESS,
  props<{ deliverable?: Deliverable }>()
);

const getScopeDeliverableFail = createAction(
  ScopeOverviewActionTypes.GET_SCOPE_DELIVERABLE_FAIL,
  props<{ error?: string }>()
);

const getAllTasks = createAction(
  ScopeOverviewActionTypes.GET_ALL_TASKS,
  props<{ scopeId: number, scopeVersion?: number }>()
);

const getAllTasksSuccess = createAction(
  ScopeOverviewActionTypes.GET_ALL_TASKS_SUCCESS,
  props<{ tasks?: Deliverable[] }>()
);

const getAllTasksFail = createAction(
  ScopeOverviewActionTypes.GET_ALL_TASKS_FAIL,
  props<{ error?: string }>()
);

/* Update Scope Version and Scope */
const getCurrentScope = createAction(ScopeOverviewActionTypes.GET_CURRENT_SCOPE, props<{ scopeId?: number }>());

const getCurrentScopeSuccess = createAction(
  ScopeOverviewActionTypes.GET_CURRENT_SCOPE_SUCCESS,
  props<{ currentScope?: ScopeVersion }>()
);

const getCurrentScopeFail = createAction(ScopeOverviewActionTypes.GET_CURRENT_SCOPE_FAIL, props<{ error?: string }>());

const getCompanyIntegrations = createAction(ScopeOverviewActionTypes.GET_COMPANY_INTEGRATIONS);

const getCompanyIntegrationsSuccess = createAction(
  ScopeOverviewActionTypes.GET_COMPANY_INTEGRATIONS_SUCCESS,
  props<{ currentScope?: ScopeVersion }>()
);

const getCompanyIntegrationsFail = createAction(ScopeOverviewActionTypes.GET_COMPANY_INTEGRATIONS_FAIL, props<{ error?: string }>());


/* Activities/Comments */
const getActivities = createAction(ScopeOverviewActionTypes.GET_ACTIVITY_COMMENTS, props<{ scopeId?: number }>());

const getActivitiesSuccess = createAction(
  ScopeOverviewActionTypes.GET_ACTIVITY_COMMENTS_SUCCESS,
  props<{ activities?: Activities }>()
);
const getActivitiesFail = createAction(
  ScopeOverviewActionTypes.GET_ACTIVITY_COMMENTS_FAIL,
  props<{ error?: string }>()
);

const submitComment = createAction(
  ScopeOverviewActionTypes.SUBMIT_COMMENT,
  props<{ scopeId?: number; comment: PostComment }>()
);

const submitCommentSuccess = createAction(
  ScopeOverviewActionTypes.SUBMIT_COMMENT_SUCCESS,
  props<{ updatedComments: Activities }>()
);
const submitCommentFail = createAction(ScopeOverviewActionTypes.SUBMIT_COMMENT_FAIL, props<{ error?: string }>());

const sendNotificationReminder = createAction(
  ScopeOverviewActionTypes.SEND_NOTIFICATION_REMINDER,
  props<{ scopeId: number; userId?: number; comment?: string }>()
);

/* Export Scope */
const exportTrafficScope = createAction(ScopeOverviewActionTypes.EXPORT_TRAFFIC_SCOPE, props<{ identityId: number }>());

const exportTrafficScopeWorkato = createAction(ScopeOverviewActionTypes.EXPORT_TRAFFIC_SCOPE_WORKATO, props<{ target: string, identityId: number }>());

const trafficScopeThroughWorkato = createAction(ScopeOverviewActionTypes.TRAFFIC_SCOPE_THROUGH_WORKATO, props<{ identityId: number }>());

const exportTrafficScopeSuccess = createAction(ScopeOverviewActionTypes.EXPORT_TRAFFIC_SCOPE_SUCCESS)
;
const exportTrafficScopeFail = createAction(
  ScopeOverviewActionTypes.EXPORT_TRAFFIC_SCOPE_FAIL,
  props<{ error: Error }>()
);

/* Retained Hours Stats */

const getScopeRetainedStats = createAction(
  ScopeOverviewActionTypes.GET_SCOPE_RETAINED_STATS,
  props<{ sowId?: string | number; scopeId: number }>()
);

const getScopeRetainedStatsSuccess = createAction(
  ScopeOverviewActionTypes.GET_SCOPE_RETAINED_STATS_SUCCESS,
  props<{ scopeRetainedHourStats: ScopeRetainedHoursStats }>()
);

const getScopeRetainedStatsFail = createAction(
  ScopeOverviewActionTypes.GET_SCOPE_RETAINED_STATS_FAIL,
  props<{ error?: string }>()
);

const updateScopeRetainedHoursStats = createAction(
  ScopeOverviewActionTypes.UPDATE_SCOPE_RETAINED_STATS,
  props<{ sowId?: string | number; scopeId: number; useRetainedHours: boolean }>(),
);

const updateScopeRetainedHoursStatsSuccess = createAction(
  ScopeOverviewActionTypes.UPDATE_SCOPE_RETAINED_STATS_SUCCESS,
  props<{ scopeRetainedHoursStats: ScopeRetainedHoursStats }>(),
);

const updateScopeRetainedHoursStatsFail = createAction(
  ScopeOverviewActionTypes.UPDATE_SCOPE_RETAINED_STATS_FAIL,
  props<{ error?: string }>(),
);

const getSowRetainedStats = createAction(
  ScopeOverviewActionTypes.GET_SOW_RETAINED_STATS,
  props<{ folderId?: string | number }>()
);

const getSowRetainedStatsSuccess = createAction(
  ScopeOverviewActionTypes.GET_SOW_RETAINED_STATS_SUCCESS,
  props<{ sowRetainedHourStats: SowRetainedHoursStats }>()
);

const getSowRetainedStatsFail = createAction(
  ScopeOverviewActionTypes.GET_SOW_RETAINED_STATS_FAIL,
  props<{ error?: string }>()
);

/* Third Party Balance */
const getThirdPartyBalance = createAction(
  ScopeOverviewActionTypes.GET_THIRD_PARTY_BALANCE,
  props<{ scopeId?: number }>()
);

const getThirdPartyBalanceSuccess = createAction(
  ScopeOverviewActionTypes.GET_THIRD_PARTY_BALANCE_SUCCESS,
  props<{ thirdPartyCostBalance: Money }>()
);

const getThirdPartyBalanceFail = createAction(
  ScopeOverviewActionTypes.GET_THIRD_PARTY_BALANCE_FAIL,
  props<{ error?: string }>()
);

/* Change Version */
const changeScopeVersion = createAction(
  ScopeOverviewActionTypes.CHANGE_SCOPE_VERSION,
  props<{ scopeId?: number; versionId: number | string }>()
);

const changeScopeVersionSuccess = createAction(
  ScopeOverviewActionTypes.CHANGE_SCOPE_VERSION_SUCCESS,
  props<{ currentScope?: ScopeVersion }>()
);

const changeScopeVersionFail = createAction(
  ScopeOverviewActionTypes.CHANGE_SCOPE_VERSION_FAIL,
  props<{ error?: string }>()
);

// Scope Section
const addScopeSection = createAction(
  ScopeOverviewActionTypes.ADD_SCOPE_SECTION,
  props<{ scopeId?: number; section: ScopeSection }>()
);

const addScopeSectionSuccess = createAction(
  ScopeOverviewActionTypes.ADD_SCOPE_SECTION_SUCCESS,
  props<{ scopeSection?: ScopeSection }>()
);

const deleteScopeSection = createAction(
  ScopeOverviewActionTypes.DELETE_SCOPE_SECTION,
  props<{ scopeId?: number; sectionId: number }>()
);

const deleteScopeSectionSuccess = createAction(
  ScopeOverviewActionTypes.DELETE_SCOPE_SECTION_SUCCESS,
  props<{ section?: ScopeSection; sectionId: number }>()
);

const deleteScopeSectionFail = createAction(
  ScopeOverviewActionTypes.DELETE_SCOPE_SECTION_FAIL,
  props<{ error?: string }>()
);

const deleteDeliverable = createAction(
  ScopeOverviewActionTypes.DELETE_DELIVERABLE,
  props<{ scopeId?: number; deliverableId: number }>()
);

const deleteDeliverableSuccess = createAction(
  ScopeOverviewActionTypes.DELETE_DELIVERABLE_SUCCESS,
  props<{ deliverable: Deliverable; deliverableId: number }>()
);

const deleteDeliverableFail = createAction(
  ScopeOverviewActionTypes.DELETE_DELIVERABLE_FAIL,
  props<{ error?: string }>()
);

const addScopeSectionFail = createAction(ScopeOverviewActionTypes.ADD_SCOPE_SECTION_FAIL, props<{ error?: string }>());

const archiveScope = createAction(ScopeOverviewActionTypes.ARCHIVE_SCOPE, props<{ scopeId?: number }>());

const archiveScopeSuccess = createAction(ScopeOverviewActionTypes.ARCHIVE_SCOPE_SUCCESS);

const archiveScopeFail = createAction(ScopeOverviewActionTypes.ARCHIVE_SCOPE_FAIL, props<{ error?: string }>());

const unarchiveScope = createAction(ScopeOverviewActionTypes.UNARCHIVE_SCOPE, props<{ scopeId?: number }>());

const unarchiveScopeSuccess = createAction(ScopeOverviewActionTypes.UNARCHIVE_SCOPE_SUCCESS);

const unarchiveScopeFail = createAction(ScopeOverviewActionTypes.UNARCHIVE_SCOPE_FAIL, props<{ error?: string }>());

const getSowRetainedTeamStats = createAction(
  ScopeOverviewActionTypes.GET_SOW_RETAINED_TEAM_STATS,
  props<{ folderId: number }>()
);

const getSowRetainedTeamStatsSuccess = createAction(
  ScopeOverviewActionTypes.GET_SOW_RETAINED_TEAM_STATS_SUCCESS,
  props<{ sowRetainedTeamStats: SowRetainedTeamStats }>()
);

const getSowRetainedTeamStatsFail = createAction(
  ScopeOverviewActionTypes.GET_SOW_RETAINED_TEAM_STATS_FAIL,
  props<{ error?: string }>()
);

const getScopeCustomFieldsDefinitionOutdated = createAction(
  ScopeOverviewActionTypes.GET_SCOPE_CUSTOM_FIELDS_DEFINITION_OUTDATED,
  props<{ scopeId: number }>()
);

const getScopeCustomFieldsDefinitionOutdatedSuccess = createAction(
  ScopeOverviewActionTypes.GET_SCOPE_CUSTOM_FIELDS_DEFINITION_OUTDATED_SUCCESS,
  props<{ outdated: boolean }>()
);

const getScopeCustomFieldsDefinitionOutdatedFail = createAction(
  ScopeOverviewActionTypes.GET_SCOPE_CUSTOM_FIELDS_DEFINITION_OUTDATED_FAIL,
  props<{ error?: string }>()
);

const getAllRateCardsForScopeCreate = createAction(ScopeOverviewActionTypes.GET_ALL_RATE_CARDS_FOR_SCOPE, emptyProps);
const getAllRateCardsForScopeCreateSuccess = createAction(
  ScopeOverviewActionTypes.GET_ALL_RATE_CARDS_FOR_SCOPE_SUCCESS,
  props<{ rateCards: any[] }>()
);
const getAllRateCardsForScopeCreateFail = createAction(
  ScopeOverviewActionTypes.GET_ALL_RATE_CARDS_FOR_SCOPE_FAIL,
  props<{ error: Error }>()
);

const getDefaultRateCard = createAction(ScopeOverviewActionTypes.GET_DEFAULT_RATE_CARD, emptyProps);
const getDefaultRateCardSuccess = createAction(
  ScopeOverviewActionTypes.GET_DEFAULT_RATE_CARD_SUCCESS,
  props<{ defaultRateCard: RatecardVersion }>()
);
const getDefaultRateCardFail = createAction(
  ScopeOverviewActionTypes.GET_DEFAULT_RATE_CARD_FAIL,
  props<{ error: Error }>()
);

const updateScope = createAction(ScopeOverviewActionTypes.UPDATE_SCOPE, props<{ scope: ScopeVersion }>())
const updateScopeSuccess = createAction(ScopeOverviewActionTypes.UPDATE_SCOPE_SUCCESS, props<{ scope: ScopeVersion }>())
const updateScopeFail = createAction(ScopeOverviewActionTypes.UPDATE_SCOPE_FAIL, props<{ error: Error }>())

const refreshOutdatedCustomFields = createAction(ScopeOverviewActionTypes.REFRESH_OUTDATED_CUSTOM_FIELDS, props<{ scopeId: number, customFieldValueStructure: ScopeCustomFieldValueStructure }>())
const refreshOutdatedCustomFieldsSuccess = createAction(ScopeOverviewActionTypes.REFRESH_OUTDATED_CUSTOM_FIELDS_SUCCESS, props<{ updatedScopeVersionCustomFields: RefreshCustomFieldsResponse }>())
const refreshOutdatedCustomFieldsFail = createAction(ScopeOverviewActionTypes.REFRESH_OUTDATED_CUSTOM_FIELDS_FAIL, props<{ error: Error }>())

const addScopeCollaborator = createAction(ScopeOverviewActionTypes.ADD_SCOPE_COLLABORATOR, props<{ scopeId: number; memberIds: number[] }>())
const addScopeCollaboratorSuccess = createAction(ScopeOverviewActionTypes.ADD_SCOPE_COLLABORATOR_SUCCESS, props<{ collaborators: ScopeTeamMember[] }>())

const addScopeReviewer = createAction(ScopeOverviewActionTypes.ADD_SCOPE_REVIEWER, props<{ scopeId: number; memberIds: number[] }>())
const addScopeReviewerSuccess = createAction(ScopeOverviewActionTypes.ADD_SCOPE_REVIEWER_SUCCESS, props<{ reviewers: ScopeTeamMember[] }>())

const addScopeApprover = createAction(ScopeOverviewActionTypes.ADD_SCOPE_APPROVER, props<{ scopeId: number; memberIds: number[] }>())
const addScopeApproverSuccess = createAction(ScopeOverviewActionTypes.ADD_SCOPE_APPROVER_SUCCESS, props<{ approvers: ScopeTeamMember[] }>())

const addScopeTrafficker = createAction(ScopeOverviewActionTypes.ADD_SCOPE_TRAFFICKER, props<{ scopeId: number; memberIds: number[] }>())
const addScopeTraffickerSuccess = createAction(ScopeOverviewActionTypes.ADD_SCOPE_TRAFFICKER_SUCCESS, props<{ traffickers: ScopeTeamMember[] }>())

const updateTeamMember = createAction(ScopeOverviewActionTypes.UPDATE_TEAM_MEMBER, props<{ scopeId: number; member: ScopeTeamMember }>())
const updateTeamMemberSuccess = createAction(ScopeOverviewActionTypes.UPDATE_TEAM_MEMBER_SUCCESS, props<{ member: ScopeTeamMember }>())

const removeScopeCollaborator = createAction(ScopeOverviewActionTypes.REMOVE_SCOPE_COLLABORATOR, props<{ scopeId: number; member: ScopeTeamMember }>())
const removeScopeCollaboratorSuccess = createAction(ScopeOverviewActionTypes.REMOVE_SCOPE_COLLABORATOR_SUCCESS, props<{ member: ScopeTeamMember }>())

const removeScopeReviewer = createAction(ScopeOverviewActionTypes.REMOVE_SCOPE_REVIEWER, props<{ scopeId: number; member: ScopeTeamMember }>())
const removeScopeReviewerSuccess = createAction(ScopeOverviewActionTypes.REMOVE_SCOPE_REVIEWER_SUCCESS, props<{ member: ScopeTeamMember }>())

const removeScopeApprover = createAction(ScopeOverviewActionTypes.REMOVE_SCOPE_APPROVER, props<{ scopeId: number; member: ScopeTeamMember }>())
const removeScopeApproverSuccess = createAction(ScopeOverviewActionTypes.REMOVE_SCOPE_APPROVER_SUCCESS, props<{ member: ScopeTeamMember }>())

const removeScopeTrafficker = createAction(ScopeOverviewActionTypes.REMOVE_SCOPE_TRAFFICKER, props<{ scopeId: number; member: ScopeTeamMember }>())
const removeScopeTraffickerSuccess = createAction(ScopeOverviewActionTypes.REMOVE_SCOPE_TRAFFICKER_SUCCESS, props<{ member: ScopeTeamMember }>())

const scopeTeamFail = createAction(ScopeOverviewActionTypes.SCOPE_TEAM_ERROR, props<{ error: Error }>())

const filterDeliverables = createAction(ScopeOverviewActionTypes.FILTER_DELIVERABLES, props<{ scopeId: number, versionId: number, filters: ScopeOverviewFilter }>());
const filterDeliverablesFail = createAction(ScopeOverviewActionTypes.FILTER_DELIVERABLES_FAIL, props<{ error: Error }>());
const filterDeliverablesSuccess = createAction(ScopeOverviewActionTypes.FILTER_DELIVERABLES_SUCCESS, props<{ filteredDeliverables: Deliverable[] }>());

const createScopeFeeItemSuccess = createAction(ScopeOverviewActionTypes.CREATE_SCOPE_FEE_ITEM_SUCCESS, props<{ feeItem: FeeItemInstance }>());
const createSectionFeeItemSuccess = createAction(ScopeOverviewActionTypes.CREATE_SECTION_FEE_ITEM_SUCCESS, props<{ sectionId: number, feeItem: FeeItemInstance }>());
const createDeliverableFeeItemSuccess = createAction(ScopeOverviewActionTypes.CREATE_DELIVERABLE_FEE_ITEM_SUCCESS, props<{ deliverableId: number, feeItem: FeeItemInstance }>());
const createComponentFeeItemSuccess = createAction(ScopeOverviewActionTypes.CREATE_COMPONENT_FEE_ITEM_SUCCESS, props<{ deliverableId: number, componentId: number, feeItem: FeeItemInstance }>());

const editScopeFeeItemSuccess = createAction(ScopeOverviewActionTypes.EDIT_SCOPE_FEE_ITEM_SUCCESS, props<{ feeItemId: number, feeItem: FeeItemInstance }>());
const editSectionFeeItemSuccess = createAction(ScopeOverviewActionTypes.EDIT_SECTION_FEE_ITEM_SUCCESS, props<{ feeItemId: number, sectionId: number, feeItem: FeeItemInstance }>());
const editDeliverableFeeItemSuccess = createAction(ScopeOverviewActionTypes.EDIT_DELIVERABLE_FEE_ITEM_SUCCESS, props<{ feeItemId: number, deliverableId: number, feeItem: FeeItemInstance }>());
const editComponentFeeItemSuccess = createAction(ScopeOverviewActionTypes.EDIT_COMPONENT_FEE_ITEM_SUCCESS, props<{ feeItemId: number, deliverableId: number, componentId: number, feeItem: FeeItemInstance }>());

const updateScopeDiscountSuccess = createAction(ScopeOverviewActionTypes.UPDATE_SCOPE_DISCOUNT_SUCCESS, props<{ discount: Discount }>());

const updateScopeMsaSuccess = createAction(ScopeOverviewActionTypes.UPDATE_SCOPE_MSA_SUCCESS, props<{ msaLineItem: ScopeMsaLineItem }>());

const updateScopeSectionsOrder = createAction(ScopeOverviewActionTypes.UPDATE_SCOPE_SECTIONS_ORDER, props<{ scopeId: number, sections: ScopeSection[] }>());
const updateFinancialsOrder = createAction(ScopeOverviewActionTypes.UPDATE_FINANCIALS_ORDER, props<{ scopeId: number, order: Object }>());
const updateScopeSectionsOrderSuccess = createAction(ScopeOverviewActionTypes.UPDATE_SCOPE_SECTIONS_ORDER_SUCCESS, emptyProps);
const updateFinancialsOrderSuccess = createAction(ScopeOverviewActionTypes.UPDATE_FINANCIALS_ORDER_SUCCESS, emptyProps);

const updateDeliverablesOrder = createAction(ScopeOverviewActionTypes.UPDATE_DELIVERABLES_ORDER, props<{ scopeId: number, sectionId?: number, deliverables: Deliverable[] }>());
const updateDeliverablesOrderSuccess = createAction(ScopeOverviewActionTypes.UPDATE_DELIVERABLES_ORDER_SUCCESS, emptyProps);

const deliverableTypeSearch = createAction(
  ScopeOverviewActionTypes.DELIVERABLE_TYPE_SEARCH,
  props<{ fixedOnly?: boolean, searchQuery?: string, rateCardVersionId: number, language: string }>()
)

const deliverableTypeSearchSuccess = createAction(
  ScopeOverviewActionTypes.DELIVERABLE_TYPE_SEARCH_SUCCESS,
  props<{ extendedDeliverables: DeliverableType[] }>()
)

const deliverableTypeSearchFail = createAction(
  ScopeOverviewActionTypes.DELIVERABLE_TYPE_SEARCH_FAIL,
  props<{ error: Error }>()
)

const updateCurrentScope = createAction(
  ScopeOverviewActionTypes.UPDATE_CURRENT_SCOPE,
  props<{ scopeId: number }>()
)

const updateCurrentScopeSuccess = createAction(
  ScopeOverviewActionTypes.UPDATE_CURRENT_SCOPE_SUCCESS,
  props<{ currentScope: ScopeVersion }>()
)

const updateCurrentScopeFail = createAction(
  ScopeOverviewActionTypes.UPDATE_CURRENT_SCOPE_FAIL,
  props<{ error: Error }>()
)

const initiateTrade = createAction(
  ScopeOverviewActionTypes.INITIATE_TRADE,
  props<{ scopeId?: number, deliverableId: number, deliverables: DeliverableTradePayload[] }>()
)

const initiateTradeSuccess = createAction(
  ScopeOverviewActionTypes.INITIATE_TRADE_SUCCESS,
  props<{ tradedDeliverables: TradedDeliverable[] }>()
)

const initiateTradeFail = createAction(
  ScopeOverviewActionTypes.INITIATE_TRADE_FAIL,
  props<{ error: Error }>()
)


const reopenTrade = createAction(
  ScopeOverviewActionTypes.REOPEN_TRADE,
  props<{ scopeId?: number, deliverableId: number }>()
)

const reopenTradeSuccess = createAction(
  ScopeOverviewActionTypes.REOPEN_SUCCESS,
  props<{ tradedDeliverables: TradedDeliverable[] }>()
)

const reopenTradeFail = createAction(
  ScopeOverviewActionTypes.REOPEN_FAIL,
  props<{ error: Error }>()
)

const stopTrade = createAction(
  ScopeOverviewActionTypes.STOP_TRADE,
  props<{ scopeId?: number, deliverableId: number }>()
)

const stopTradeSuccess = createAction(
  ScopeOverviewActionTypes.STOP_TRADE_SUCCESS,
  props<{ tradedDeliverables: TradedDeliverable[] }>()
)

const stopTradeFail = createAction(
  ScopeOverviewActionTypes.STOP_TRADE_FAIL,
  props<{ error: Error }>()
)

const completeTrade = createAction(
  ScopeOverviewActionTypes.COMPLETE_TRADE,
  props<{ scopeId?: number, deliverableId: number }>()
)

const completeTradeSuccess = createAction(
  ScopeOverviewActionTypes.COMPLETE_TRADE_SUCCESS,
  props<{ tradedDeliverables: TradedDeliverable[] }>()
)

const completeTradeFail = createAction(
  ScopeOverviewActionTypes.COMPLETE_TRADE_FAIL,
  props<{ error: Error }>()
)

const cancelTrade = createAction(
  ScopeOverviewActionTypes.CANCEL_TRADE,
  props<{ scopeId?: number, deliverableId: number }>()
)

const cancelTradeSuccess = createAction(
  ScopeOverviewActionTypes.CANCEL_TRADE_SUCCESS,
  props<{ tradedDeliverables: TradedDeliverable[] }>()
)

const cancelTradeFail = createAction(
  ScopeOverviewActionTypes.CANCEL_TRADE_FAIL,
  props<{ error: Error }>()
)

const confirmTrade = createAction(
  ScopeOverviewActionTypes.CONFIRM_TRADE,
  props<{ scopeId: number, deliverableId: number }>()
)

const confirmTradeSuccess = createAction(
  ScopeOverviewActionTypes.CONFIRM_TRADE_SUCCESS,
  props<{ tradedDeliverables: TradedDeliverable[] }>()
)

const confirmTradeFail = createAction(
  ScopeOverviewActionTypes.CONFIRM_TRADE_FAIL,
  props<{ error: Error }>()
)

const applyLocationCardToDeliverable = createAction(
  ScopeOverviewActionTypes.APPLY_DELIVERABLE_LOCATION_CARD,
  props<{ scopeId: number, deliverableId: number, locationId: number }>()
)

const applyLocationCardToDeliverableSuccess = createAction(
  ScopeOverviewActionTypes.APPLY_DELIVERABLE_LOCATION_CARD_SUCCESS,
  props<{ deliverable: Deliverable }>()
)

const applyLocationCardToDeliverableFail = createAction(
  ScopeOverviewActionTypes.APPLY_DELIVERABLE_LOCATION_CARD_FAIL,
  props<{ error: Error }>()
)

const deleteScopeDiscount = createAction(
  ScopeOverviewActionTypes.DELETE_SCOPE_DISCOUNT,
  props<{ scopeId: number }>()
)

const deleteScopeDiscountSuccess = createAction(
  ScopeOverviewActionTypes.DELETE_SCOPE_DISCOUNT_SUCCESS,
  props<{ scopeId: number }>()
)

const deleteScopeDiscountFail = createAction(
  ScopeOverviewActionTypes.DELETE_SCOPE_DISCOUNT_FAIL,
  props<{ error: Error }>()
)

const deleteScopeMsa = createAction(
  ScopeOverviewActionTypes.DELETE_SCOPE_MSA,
  props<{ scopeId: number, reason: string }>()
)

const deleteScopeMsaSuccess = createAction(
  ScopeOverviewActionTypes.DELETE_SCOPE_MSA_SUCCESS,
  props<{ scopeId: number, reason: string }>()
)

const deleteScopeMsaFail = createAction(
  ScopeOverviewActionTypes.DELETE_SCOPE_MSA_FAIL,
  props<{ error: Error }>()
)

const addDeliverableCommentSuccess = createAction(
  ScopeOverviewActionTypes.ADD_DELIVERABLE_COMMENT_SUCCESS,
  props<{ deliverableId: number, comment: any }>()
)

const addComponentCommentSuccess = createAction(
  ScopeOverviewActionTypes.ADD_COMPONENT_COMMENT_SUCCESS,
  props<{ deliverableId: number, componentId: number, comment: any }>()
)

const addDepartmentCommentSuccess = createAction(
  ScopeOverviewActionTypes.ADD_DEPARTMENT_COMMENT_SUCCESS,
  props<{ deliverableId: number, componentId: number, departmentId: number, comment: any }>()
)

const addRoleCommentSuccess = createAction(
  ScopeOverviewActionTypes.ADD_ROLE_COMMENT_SUCCESS,
  props<{ deliverableId: number, componentId: number, roleId: number, comment: any }>()
)

const addDeliverableSectionCommentSuccess = createAction(
  ScopeOverviewActionTypes.ADD_DELIVERABLE_SECTION_COMMENT_SUCCESS,
  props<{ deliverableId: number, sectionId: number, comment: any }>()
)

const addScopeSectionCommentSuccess = createAction(
  ScopeOverviewActionTypes.ADD_SCOPE_SECTION_COMMENT_SUCCESS,
  props<{ sectionId: number, comment: any }>()
)

const addDeliverableTPCCommentSuccess = createAction(
  ScopeOverviewActionTypes.ADD_DELIVERABLE_TPC_COMMENT_SUCCESS,
  props<{ deliverableId: number, tpcId: number, comment: any }>()
)

const addScopeTPCCommentSuccess = createAction(
  ScopeOverviewActionTypes.ADD_SCOPE_TPC_COMMENT_SUCCESS,
  props<{ tpcId: number, comment: any }>()
)

const addComponentTPCCommentSuccess = createAction(
  ScopeOverviewActionTypes.ADD_COMPONENT_TPC_SUCCESS,
  props<{ deliverableId: number, componentId: number, tpcId: number, comment: any }>()
)

const addScopeCommentSuccess = createAction(
  ScopeOverviewActionTypes.ADD_SCOPE_COMMENT_SUCCESS,
  props<{ comment: any }>()
)

const getDeliverableAndComponentFees = createAction(
  ScopeOverviewActionTypes.GET_DELIVERABLE_COMPONENT_FEES,
  props<{ scopeId: number, versionId: number }>()
)

const getDeliverableAndComponentFeesSuccess = createAction(
  ScopeOverviewActionTypes.GET_DELIVERABLE_COMPONENT_FEES_SUCCESS,
  props<{ deliverableFeeItems: FeeItemInstance, componentFeeItems: FeeItemInstance }>()
)

const getDeliverableAndComponentFeesFail = createAction(
  ScopeOverviewActionTypes.GET_DELIVERABLE_COMPONENT_FEES_FAIL,
  props<{ error: Error }>()
)

const getAllDeliverableTPCs = createAction(
  ScopeOverviewActionTypes.GET_ALL_DELIVERABLE_TPCS,
  props<{ scopeId: number, versionId: number }>()
)

const getAllDeliverableTPCsSuccess = createAction(
  ScopeOverviewActionTypes.GET_ALL_DELIVERABLE_TPCS_SUCCESS,
  props<{ deliverableThirdPartyCosts: ThirdPartyCost, componentThirdPartyCosts: ThirdPartyCost, sectionThirdPartyCosts: ThirdPartyCost }>()
)

const getAllDeliverableTPCsFail = createAction(
  ScopeOverviewActionTypes.GET_ALL_DELIVERABLE_TPCS_FAIL,
  props<{ error: Error }>()
)

const searchComponentsForScope = createAction(ScopeOverviewActionTypes.SEARCH_COMPONENTS_FOR_SCOPE, props<{ scopeId: number, versionId: number, searchText: string }>());
const searchComponentsForScopeFail = createAction(ScopeOverviewActionTypes.SEARCH_COMPONENTS_FOR_SCOPE_FAIL, props<{ error: Error }>());
const searchComponentsForScopeSuccess = createAction(ScopeOverviewActionTypes.SEARCH_COMPONENTS_FOR_SCOPE_SUCCESS, props<{ components: any }>());

const searchNonDistinctComponents = createAction(ScopeOverviewActionTypes.SEARCH_NON_DISTINCT_COMPONENTS, props<{ scopeId: number, versionId: number, searchText: string }>());
const searchNonDistinctComponentsFail = createAction(ScopeOverviewActionTypes.SEARCH_NON_DISTINCT_COMPONENTS_FAIL, props<{ error: Error }>());
const searchNonDistinctComponentsSuccess = createAction(ScopeOverviewActionTypes.SEARCH_NON_DISTINCT_COMPONENTS_SUCCESS, props<{ components: any }>());

const searchDepartmentsForScope = createAction(ScopeOverviewActionTypes.SEARCH_DEPARTMENTS_FOR_SCOPE, props<{ scopeId: number, versionId: number, searchText: string }>());
const searchDepartmentsForScopeFail = createAction(ScopeOverviewActionTypes.SEARCH_DEPARTMENTS_FOR_SCOPE_FAIL, props<{ error: Error }>());
const searchDepartmentsForScopeSuccess = createAction(ScopeOverviewActionTypes.SEARCH_DEPARTMENTS_FOR_SCOPE_SUCCESS, props<{ departments: any }>());

const searchRolesForScope = createAction(ScopeOverviewActionTypes.SEARCH_ROLES_FOR_SCOPE, props<{ scopeId: number, versionId: number, searchText: string }>());
const searchRolesForScopeFail = createAction(ScopeOverviewActionTypes.SEARCH_ROLES_FOR_SCOPE_FAIL, props<{ error: Error }>());
const searchRolesForScopeSuccess = createAction(ScopeOverviewActionTypes.SEARCH_ROLES_FOR_SCOPE_SUCCESS, props<{ roles: any }>());

const applyLocationToRole = createAction(ScopeOverviewActionTypes.APPLY_LOCATION_TO_ROLE,
  props<{ roleId: number, locationRoleId: number, scopeId: number, sourceDepartmentId: number, sourceRoleId: number,
    ratecardLocationRole: any, locationName: string,
    rateCardRate: Money, rateCardCost: Money }>());
const applyLocationToRoleFail = createAction(ScopeOverviewActionTypes.APPLY_LOCATION_TO_ROLE_FAIL, props<{ error: Error }>());
const applyLocationToRoleSuccess = createAction(ScopeOverviewActionTypes.APPLY_LOCATION_TO_ROLE_SUCCESS,
  props<{ sourceDepartmentId: number, sourceRoleId: number, ratecardLocationRole: any,
    locationName: string, rateCardRate: Money, rateCardCost: Money }>());

const setDialogId = createAction(ScopeOverviewActionTypes.SET_DIALOG_ID, props<{ id: string }>())

const getScenario = createAction(ScopeOverviewActionTypes.GET_SCENARIO, props<{ scenarioId: number }>());
const getScenarioSuccess = createAction(ScopeOverviewActionTypes.GET_SCENARIO_SUCCESS, props<{ scenario: Scenario }>());
const getScenarioFail = createAction(ScopeOverviewActionTypes.GET_SCENARIO_FAIL, props<{ error: any }>());

const updateScenario = createAction(ScopeOverviewActionTypes.UPDATE_SCENARIO, props<{ scenario: Scenario, hasUpdates: boolean }>());
const resetScenario = createAction(ScopeOverviewActionTypes.RESET_SCENARIO, emptyProps);

const saveScopeConfiguration = createAction(ScopeOverviewActionTypes.SAVE_CONFIGURATION, props<{ scopeId: number, configuration: ScopeConfiguration }>());
const saveScopeConfigurationSuccess = createAction(ScopeOverviewActionTypes.SAVE_CONFIGURATION_SUCCESS, emptyProps);
const saveScopeConfigurationFail = createAction(ScopeOverviewActionTypes.SAVE_CONFIGURATION_FAIL, props<{ error: any }>());

const submitScopeConfiguration = createAction(ScopeOverviewActionTypes.SUBMIT_CONFIGURATION, props<{ scopeId: number, modifiers: { [key: number]: number } }>());
const submitScopeConfigurationSuccess = createAction(ScopeOverviewActionTypes.SUBMIT_CONFIGURATION_SUCCESS, props<{ currentScope: ScopeVersion }>());
const submitScopeConfigurationFail = createAction(ScopeOverviewActionTypes.SUBMIT_CONFIGURATION_FAIL, props<{ error: any }>());

export const ScopeOverviewActions = {
  getActivities,
  getActivitiesSuccess,
  getActivitiesFail,
  submitComment,
  submitCommentSuccess,
  submitCommentFail,
  sendNotificationReminder,
  exportTrafficScope,
  exportTrafficScopeWorkato,
  trafficScopeThroughWorkato,
  exportTrafficScopeSuccess,
  exportTrafficScopeFail,
  getCurrentScope,
  getCurrentScopeSuccess,
  getCurrentScopeFail,
  getCompanyIntegrations,
  getCompanyIntegrationsSuccess,
  getCompanyIntegrationsFail,
  getScopeRetainedStats,
  getScopeRetainedStatsSuccess,
  getScopeRetainedStatsFail,
  updateScopeRetainedHoursStats,
  updateScopeRetainedHoursStatsSuccess,
  updateScopeRetainedHoursStatsFail,
  getSowRetainedStats,
  getSowRetainedStatsSuccess,
  getSowRetainedStatsFail,
  getThirdPartyBalance,
  getThirdPartyBalanceSuccess,
  getThirdPartyBalanceFail,
  changeScopeVersion,
  changeScopeVersionSuccess,
  changeScopeVersionFail,
  addScopeSection,
  addScopeSectionSuccess,
  addScopeSectionFail,
  addNewAction,
  addNewActionSuccess,
  addNewActionFail,
  archiveScope,
  archiveScopeSuccess,
  archiveScopeFail,
  unarchiveScope,
  unarchiveScopeSuccess,
  unarchiveScopeFail,
  getScopeDeliverable,
  getScopeDeliverableSuccess,
  getScopeDeliverableFail,
  getAllTasks,
  getAllTasksSuccess,
  getAllTasksFail,
  getAvailableScopeDeliverableTypes,
  getAvailableScopeDeliverableTypesSuccess,
  getAvailableScopeDeliverableTypesFail,
  addScopeDeliverable,
  addScopeDeliverableSuccess,
  addScopeDeliverableFail,
  addScopeDeliverablesSuccess,
  duplicateScopeDeliverable,
  duplicateScopeDeliverableSuccess,
  duplicateScopeDeliverableFail,
  moveComponentToDeliverable,
  moveComponentToDeliverableSuccess,
  moveComponentToDeliverableFail,
  updateScopeDeliverable,
  updateScopeDeliverableSuccess,
  updateScopeDeliverableFail,
  clearScopeTaskRoles,
  clearScopeTaskRolesSuccess,
  clearScopeTaskRolesFail,
  updateScopeSection,
  updateScopeSectionSuccess,
  updateScopeSectionFail,
  updateScopeComponent,
  updateScopeComponentSuccess,
  updateScopeComponentFail,
  updateDeliverableSection,
  updateDeliverableSectionSuccess,
  updateDeliverableSectionFail,
  overrideScopeComponentSuccess,
  overrideRoleSuccess,
  updateRole,
  updateRoleSuccess,
  updateRoleFail,
  createScopeTpcSuccess,
  updateScopeTpcSuccess,
  deleteScopeTpcSuccess,
  createLibraryDeliverableTemplate,
  createLibraryDeliverableTemplateSuccess,
  createLibraryDeliverableTemplateFail,
  createLibraryDeliverableEntry,
  createLibraryDeliverableEntrySuccess,
  createLibraryDeliverableEntryFail,
  getOutputTemplate,
  getOutputTemplateSuccess,
  getOutputTemplateFail,
  updateTemplate,
  updateTemplateSuccess,
  updateTemplateFail,
  previewScopeDocx,
  previewScopeDocxSuccess,
  previewScopeDocxFail,
  getSecondParty,
  getSecondPartySuccess,
  getSecondPartyFail,
  getOfficeLocation,
  getOfficeLocationSuccess,
  getOfficeLocationFail,
  getSowRetainedTeamStats,
  getSowRetainedTeamStatsSuccess,
  getSowRetainedTeamStatsFail,
  getScopeCustomFieldsDefinitionOutdated,
  getScopeCustomFieldsDefinitionOutdatedSuccess,
  getScopeCustomFieldsDefinitionOutdatedFail,
  getAllRateCardsForScopeCreate,
  getAllRateCardsForScopeCreateSuccess,
  getAllRateCardsForScopeCreateFail,
  getDefaultRateCard,
  getDefaultRateCardSuccess,
  getDefaultRateCardFail,
  deleteScopeSection,
  deleteScopeSectionSuccess,
  deleteScopeSectionFail,
  updateScope,
  updateScopeSuccess,
  updateScopeFail,
  refreshOutdatedCustomFields,
  refreshOutdatedCustomFieldsSuccess,
  refreshOutdatedCustomFieldsFail,
  addScopeCollaborator,
  addScopeCollaboratorSuccess,
  addScopeReviewer,
  addScopeReviewerSuccess,
  addScopeApprover,
  addScopeApproverSuccess,
  addScopeTrafficker,
  addScopeTraffickerSuccess,
  updateTeamMember,
  updateTeamMemberSuccess,
  removeScopeCollaborator,
  removeScopeCollaboratorSuccess,
  removeScopeReviewer,
  removeScopeReviewerSuccess,
  removeScopeApprover,
  removeScopeApproverSuccess,
  removeScopeTrafficker,
  removeScopeTraffickerSuccess,
  scopeTeamFail,
  deleteDeliverable,
  deleteDeliverableSuccess,
  deleteDeliverableFail,
  deleteComponentFeeSuccess,
  deleteComponentFeeFail,
  deleteDeliverableFeeItemSuccess,
  deleteDeliverableFeeItemFail,
  deleteScopeFeeItem,
  deleteScopeFeeItemSuccess,
  deleteScopeFeeItemFail,
  deleteSectionFeeItem,
  deleteSectionFeeItemSuccess,
  deleteSectionFeeItemFail,
  setScopeNotificationEvent,
  filterDeliverables,
  filterDeliverablesSuccess,
  filterDeliverablesFail,
  createScopeFeeItemSuccess,
  createSectionFeeItemSuccess,
  createDeliverableFeeItemSuccess,
  createComponentFeeItemSuccess,
  editScopeFeeItemSuccess,
  editSectionFeeItemSuccess,
  editDeliverableFeeItemSuccess,
  editComponentFeeItemSuccess,
  updateScopeDiscountSuccess,
  updateScopeMsaSuccess,
  updateScopeSectionsOrder,
  updateFinancialsOrder,
  updateScopeSectionsOrderSuccess,
  updateFinancialsOrderSuccess,
  updateDeliverablesOrder,
  updateDeliverablesOrderSuccess,
  deliverableTypeSearch,
  deliverableTypeSearchSuccess,
  deliverableTypeSearchFail,
  initiateTrade,
  initiateTradeSuccess,
  initiateTradeFail,
  updateCurrentScope,
  updateCurrentScopeSuccess,
  updateCurrentScopeFail,
  reopenTrade,
  reopenTradeSuccess,
  reopenTradeFail,
  stopTrade,
  stopTradeSuccess,
  stopTradeFail,
  completeTrade,
  completeTradeSuccess,
  completeTradeFail,
  cancelTrade,
  cancelTradeSuccess,
  cancelTradeFail,
  confirmTrade,
  confirmTradeSuccess,
  confirmTradeFail,
  applyLocationCardToDeliverable,
  applyLocationCardToDeliverableSuccess,
  applyLocationCardToDeliverableFail,
  deleteScopeDiscount,
  deleteScopeDiscountSuccess,
  deleteScopeDiscountFail,
  deleteScopeMsa,
  deleteScopeMsaSuccess,
  deleteScopeMsaFail,
  addDeliverableCommentSuccess,
  addComponentCommentSuccess,
  addDepartmentCommentSuccess,
  addRoleCommentSuccess,
  addDeliverableSectionCommentSuccess,
  addScopeSectionCommentSuccess,
  addDeliverableTPCCommentSuccess,
  addScopeTPCCommentSuccess,
  addComponentTPCCommentSuccess,
  addScopeCommentSuccess,
  getDeliverableAndComponentFees,
  getDeliverableAndComponentFeesSuccess,
  getDeliverableAndComponentFeesFail,
  getAllDeliverableTPCs,
  getAllDeliverableTPCsSuccess,
  getAllDeliverableTPCsFail,
  searchComponentsForScope,
  searchComponentsForScopeFail,
  searchComponentsForScopeSuccess,
  searchNonDistinctComponents,
  searchNonDistinctComponentsFail,
  searchNonDistinctComponentsSuccess,
  searchDepartmentsForScope,
  searchDepartmentsForScopeFail,
  searchDepartmentsForScopeSuccess,
  searchRolesForScope,
  searchRolesForScopeFail,
  searchRolesForScopeSuccess,
  applyLocationToRole,
  applyLocationToRoleFail,
  applyLocationToRoleSuccess,
  setDialogId,
  getScenario,
  getScenarioSuccess,
  getScenarioFail,
  updateScenario,
  resetScenario,
  saveScopeConfiguration,
  saveScopeConfigurationSuccess,
  saveScopeConfigurationFail,
  submitScopeConfiguration,
  submitScopeConfigurationSuccess,
  submitScopeConfigurationFail,
}

import { LibraryComponentActions, libraryComponentInitialState } from '@app/features/library-management/store'
import {
  DeleteComponentEntrySuccessAction,
  GetComponentSuccessAction,
  LibraryComponentEntry,
  LibraryComponentState,
  SetComponentEntriesPageAction,
  ToggleEntryFixedCostEditableSuccessAction,
  UpdateComponentSuccessAction,
} from '@app/features/library-management/store/models/component'
import { Action, createReducer, on } from '@ngrx/store'

import { libraryManagementPaginationInitialState } from '@app/features/library-management/store'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { plainToInstance } from 'class-transformer'

const loadingTrueHandler = (state: LibraryComponentState) => ({ ...state, isLoading: true })
const loadingFalseHandler = (state: LibraryComponentState) => ({ ...state, isLoading: false })

const setPageHandler = (state: LibraryComponentState, action: SetComponentEntriesPageAction) => ({
  ...state,
  pagination: { ...state.pagination, page: action.page },
})
const destroyPageHandler = (state: LibraryComponentState) => ({
  ...state,
  isLoading: false,
  isEntryChanged: false,
  rateCard: undefined,
})

const getComponentSuccessHandler = (state: LibraryComponentState, { component }: GetComponentSuccessAction) => ({
  ...state,
  isLoading: false,
  component,
  pagination: { ...state.pagination, page: 0, totalCount: component?.libraryComponentEntries?.length | 0 },
})

const updateComponentSuccessHandler = (state: LibraryComponentState, { component }: UpdateComponentSuccessAction) => ({
  ...state,
  isLoading: false,
  component: {
    ...component,
    libraryComponentEntries: state.component.libraryComponentEntries,
  },
})

const deleteComponentSuccessHandler = (state: LibraryComponentState) => ({
  ...state,
  isLoading: false,
  component: undefined,
  pagination: libraryManagementPaginationInitialState,
})

const deleteComponentEntryHandler = (state: LibraryComponentState) => ({
  ...state,
  isLoading: true,
  isEntryChanged: false,
})

const deleteComponentEntrySuccessHandler = (
  state: LibraryComponentState,
  { entryId }: DeleteComponentEntrySuccessAction
) => ({
  ...state,
  isLoading: false,
  component: {
    ...state.component,
    libraryComponentEntries: state.component.libraryComponentEntries?.filter((entry) => entry.id !== entryId),
  },
  pagination: { ...state.pagination, totalCount: state.pagination.totalCount - 1 },
})

const toggleEntryFixedCostEditableSuccessHandler = (
  state: LibraryComponentState,
  { id }: ToggleEntryFixedCostEditableSuccessAction
) => ({
  ...state,
  isLoading: false,
  component: {
    ...state.component,
    libraryComponentEntries: state.component.libraryComponentEntries.map((e) =>
      e.id === id ? plainToInstance(LibraryComponentEntry, { ...e, fixedFeeEditable: !e.fixedFeeEditable }) : e
    ),
  },
})

const getEntryRateCardHandler = (state: LibraryComponentState) => ({
  ...state,
  isLoadingRateCard: !state.rateCard,
})

const getEntryRateCardFailHandler = (state: LibraryComponentState) => ({
  ...state,
  isLoadingRateCard: false,
})

const getEntryRateCardSuccessHandler = (state: LibraryComponentState, { rateCard }) => ({
  ...state,
  isLoadingRateCard: false,
  rateCard: plainToInstance(RatecardVersion, rateCard),
})

const updateComponentEntryHandler = (state: LibraryComponentState, { entry }) => ({
  ...state,
  isEntryChanged: true,
  component: {
    ...state.component,
    libraryComponentEntries: state.component.libraryComponentEntries.map((e) =>
      e.id === entry.id ? plainToInstance(LibraryComponentEntry, entry) : e
    ),
  },
})

const saveComponentEntrySuccessHandler = (state: LibraryComponentState, { entry }) => ({
  ...state,
  isEntryChanged: false,
  component: {
    ...state.component,
    libraryComponentEntries: state.component.libraryComponentEntries.map((e) =>
      e.id === entry.id ? plainToInstance(LibraryComponentEntry, { ...e, versionNumber: entry.versionNumber }) : e
    ),
  },
})

export function libraryComponentReducer(state = libraryComponentInitialState, action: Action): LibraryComponentState {
  return createReducer(
    libraryComponentInitialState,
    on(
      LibraryComponentActions.getComponent,
      LibraryComponentActions.updateComponent,
      LibraryComponentActions.deleteComponent,
      LibraryComponentActions.addComponentEntry,
      LibraryComponentActions.duplicateComponentEntry,
      LibraryComponentActions.toggleEntryFixedCostEditable,
      loadingTrueHandler
    ),
    on(
      LibraryComponentActions.getComponentFail,
      LibraryComponentActions.updateComponentFail,
      LibraryComponentActions.deleteComponentFail,
      LibraryComponentActions.addComponentEntryFail,
      LibraryComponentActions.addComponentEntrySuccess,
      LibraryComponentActions.deleteComponentEntryFail,
      LibraryComponentActions.duplicateComponentEntryFail,
      LibraryComponentActions.duplicateComponentEntrySuccess,
      LibraryComponentActions.toggleEntryFixedCostEditableFail,
      loadingFalseHandler
    ),
    on(LibraryComponentActions.getComponentSuccess, getComponentSuccessHandler),
    on(LibraryComponentActions.updateComponentSuccess, updateComponentSuccessHandler),
    on(LibraryComponentActions.deleteComponentEntrySuccess, deleteComponentEntrySuccessHandler),
    on(LibraryComponentActions.toggleEntryFixedCostEditableSuccess, toggleEntryFixedCostEditableSuccessHandler),
    on(LibraryComponentActions.getEntryRateCard, getEntryRateCardHandler),
    on(LibraryComponentActions.getEntryRateCardFail, getEntryRateCardFailHandler),
    on(LibraryComponentActions.getEntryRateCardSuccess, getEntryRateCardSuccessHandler),
    on(LibraryComponentActions.updateComponentEntry, updateComponentEntryHandler),
    on(LibraryComponentActions.saveComponentEntrySuccess, saveComponentEntrySuccessHandler),
    on(LibraryComponentActions.setPage, setPageHandler),
    on(LibraryComponentActions.destroyPage, destroyPageHandler),
    on(LibraryComponentActions.deleteComponentSuccess, deleteComponentSuccessHandler),
    on(LibraryComponentActions.deleteComponentEntry, deleteComponentEntryHandler),
    on(LibraryComponentActions.deleteComponentEntrySuccess, deleteComponentEntrySuccessHandler),
    on(LibraryComponentActions.toggleEntryFixedCostEditableSuccess, toggleEntryFixedCostEditableSuccessHandler)
  )(state, action)
}

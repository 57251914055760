import { PrivilegeAware } from '../model/definitions/privileged-aware.interface'
import { Privilege } from '../model/enums/privilege.enum'
import { User } from '../model/user.model'

export class Privileged implements PrivilegeAware {
  userPrivilegeRestrictions?: any[]
  userAdditionalPrivileges?: any[]

  isPrivilegeRestricted(privilege: Privilege) {
    return this.userPrivilegeRestrictions?.indexOf(privilege) != -1
  }

  hasPrivilege(privilege: Privilege, user: User) {
    if (this.hasAdditionalPrivilege(privilege)) return true

    if (user.hasPrivilege(privilege)) return !this.isPrivilegeRestricted(privilege)

    return false
  }

  hasAdditionalPrivilege(privilege: Privilege) {
    return this.userAdditionalPrivileges?.indexOf(privilege) != -1
  }
}


export enum EventType {
    DELIVERABLE_LOCKED,
    DELIVERABLE_UNLOCKED,
    SCOPE_EXTERNAL_TRANSFER_STARTED,
    SCOPE_EXTERNAL_TRANSFER_ABORTED,
    SCOPE_EXTERNAL_TRANSFER_COMPLETE,
    SCOPE_EXTERNAL_TRANSFER_DELIVERABLE_TRANSFERRED,
    DELIVERABLE_UPDATED,
    DELIVERABLE_ACTUALS_UPDATED,
    FOLDER_DELETED,
    COMPONENT_UPDATED,
    COMPONENT_ADDED,
    COMPONENT_DELETED,
    COMPONENT_MOVED,
    COMPONENT_TPC_DELETED,
    COMPONENT_TPC_UPDATED,
    DELIVERABLE_SECTION_DELETED,
    DELIVERABLE_SECTION_ADDED,
    DELIVERABLE_SECTION_UPDATED,
    DELIVERABLE_TPC_ADDED,
    DELIVERABLE_TPC_DELETED,
    DELIVERABLE_TPC_UPDATED,
    DELIVERABLE_TPC_COMPONENT_LINKED,
    DELIVERABLE_TPC_COMPONENT_UNLINKED,
    SCOPE_DELIVERABLE_UPDATED,
    SCOPE_DELIVERABLE_ACTUALS_UPDATED,
    SCOPE_DELIVERABLE_ADDED,
    SCOPE_DELIVERABLE_DELETED,
    SCOPE_STAGE_ADDED,
    SCOPE_STAGE_DELETED,
    SCOPE_UPDATED,
    SCOPE_VERSIONED,
    SCOPE_COMMENT,
    SCOPE_MENTION,
    SCOPE_COLLABORATOR_ADDED,
    SCOPE_REVIEWER_ADDED,
    SCOPE_TEMPLATE_UPDATED,
    SCOPE_APPROVER_ADDED,
    SCOPE_TRAFFICKER_ADDED,
    SCOPE_COLLABORATOR_REMOVED,
    SCOPE_REVIEWER_REMOVED,
    SCOPE_APPROVER_REMOVED,
    SCOPE_TRAFFICKER_REMOVED,
    SCOPE_DELETED,
    SCOPE_ARCHIVED,
    SCOPE_UNARCHIVED,
    SCOPE_ACTIVITY_COMMENT,
    COMPANY_UPDATED,
    SCOPE_ADDED,
    SCOPE_SUBMISSION_WITHDREW,
    SCOPE_SUBMITTED,
    SCOPE_SUBMITTED_FOR_REVIEW,
    SCOPE_REVIEW_SUBMITTED,
    SCOPE_REVIEW_ACCEPTED,
    SCOPE_REVIEW_REJECTED,
    SCOPE_DELIVERABLE_REVIEW_REJECTED,
    SCOPE_DELIVERABLE_REVIEW_ACCEPTED,
    SCOPE_REVIEWED,
    SCOPE_APPROVAL_REJECTED,
    SCOPE_APPROVAL_ACCEPTED,
    SCOPE_APPROVAL_WEBHOOK_INFO_UPDATED,
    SCOPE_CLIENT_APPROVAL_ACCEPTED,
    SCOPE_CLIENT_APPROVAL_REJECTED,
    SCOPE_READY_FOR_TRAFFICKING,
    SCOPE_TRAFFICKED,
    SCOPE_CLOSED,
    SOW_UPDATED,
    SOW_ARCHIVED,
    SOW_UNARCHIVED,
    SOW_IN_REVIEW,
    SOW_SCOPE_ADDED,
    SOW_SCOPE_REMOVED,
    SOW_REVIEW_ACCEPTED,
    SOW_REVIEW_REJECTED,
    USER_NOTIFICATION,
    GROUP_MEMBER_ADDED,
    GROUP_MEMBER_REMOVED,
    SCOPE_APPROVAL_REMINDER,
    ALREADY_CONNECTED,
    SCOPE_TEAM_MEMBER_REMOVED,
    SCOPE_TEAM_MEMBER_ADDED,
    SOW_COMMENT,
    SOW_DELETED,
    SOW_SCOPE_UPDATED,
    USER_TRANSACTION,
    REPORT_DOWNLOAD_AVAILABLE,
    REPORT_GENERATION_FAILED,
    LIBRARY_DATA_EXPORT_AVAILABLE,
    LIBRARY_DATA_EXPORT_FAILED,
    LIBRARY_ENTRY_MIGRATION_SUCCESS,
    DATA_EXPORT_SUCCESS,
    DATA_EXPORT_FAILED
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import { CommonModule } from '@angular/common'

@Component({
  selector: 'table-loader',
  templateUrl: 'table-loader.component.html',
  styleUrls: ['./table-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true,
})
export class TableLoaderComponent {
  @Input() loading: boolean
  @Input() tableClass: string
  @Input() condensed: boolean
  @Input() amountOfRows = 10
}

import { Privileged } from '@app/core/class/privileged.class'
import { Complexity } from '@app/core/model/enums/complexity.enum'
import { Money } from '@app/features/scope-overview/model/money.model'
import { Role } from '@app/features/scoping/models/role.model'
import { ComponentModifier } from '@app/features/company-management/models/component-modifier.model';

export class LibraryComplexity extends Privileged {
  complexity: Complexity
  description: string
  internalNote?: string
  fixedCost: Money
  costAmount: number
  markup?: number
  name?: string
  aliasName?: string
  roles: LibraryComplexityRole[]
  departments?: LibraryComplexityDepartment[]
  agencyHours?: number
  agencyPrice?: Money
  agencyCost?: Money
  fixedPricing?: boolean
  price?: number
  scenarioFormulas?: ComponentModifier
}

export interface LibraryComplexityDepartment {
  id: number
  name: string
  agencyHours?: number
  agencyPrice?: Money
  roles: LibraryComplexityRole[]
  expanded?: boolean
  selected?: boolean
}

export class LibraryComplexityRole extends Role {
  agencyHours?: number
  agencyPrice?: Money
  agencyCost?: Money
  override rateCardRole: LibraryComplexityRole
  latestMergedRole?: LibraryComplexityRole
  locationCard?: any
  hours?: number
  minutes?: number
  hoursFormatted?: string
  fixedCost: Money
  costAmount: number
  markup?: number
}

import { Action, createReducer, on } from '@ngrx/store'
import {
  GetDisciplinesSuccessAction,
  GetFolderSuccessAction,
  GetHighlightsSuccessAction,
  GetRatecardsSuccessAction,
  LibraryManagementState,
  SetDialogIdAction,
  SetFolderIdAction,
  UpdateFiltersAction,
} from '@app/features/library-management/store/models/state'
import {
  LibraryCreateComponentActions,
  LibraryCreateDeliverableActions,
  LibraryItemTypes,
  LibraryManagementActions,
  LibraryManagementFoldersActions,
  LibraryManagementItemsActions,
  libraryManagementInitialState,
} from '@app/features/library-management/store'
import {
  LibraryManagementFolder,
  UpdateFolderSuccessAction,
} from '@app/features/library-management/store/models/library-folders'

import { DuplicateLibraryItemSuccessAction } from '@app/features/library-management/store/models/library-items'
import { plainToInstance } from 'class-transformer'

const loadingTrueHandler = (state: LibraryManagementState) => ({ ...state, isLoading: true })
const loadingFalseHandler = (state: LibraryManagementState) => ({ ...state, isLoading: false })

const setDialogIdHandler = (state: LibraryManagementState, action: SetDialogIdAction) => ({
  ...state,
  dialogId: action.id,
})

const getHighlightsSuccessHandler = (state: LibraryManagementState, action: GetHighlightsSuccessAction) => ({
  ...state,
  highlights: action.highlights,
})

const getRatecardsSuccessHandler = (state: LibraryManagementState, action: GetRatecardsSuccessAction) => ({
  ...state,
  rateCards: action.rateCards,
  isRateCardsLoaded: true,
})

const getDisciplinesSuccessHandler = (state: LibraryManagementState, action: GetDisciplinesSuccessAction) => ({
  ...state,
  disciplines: action.disciplines,
  isDisciplinesLoaded: true,
})

const updateFiltersHandler = (state: LibraryManagementState, action: UpdateFiltersAction) => ({
  ...state,
  filters: { ...state.filters, ...action.filters },
})

const setFolderIdHandler = (state: LibraryManagementState, action: SetFolderIdAction) => ({
  ...state,
  filters: { ...state.filters, folderId: action.folderId },
})

const getFolderSuccessHandler = (state: LibraryManagementState, action: GetFolderSuccessAction) => ({
  ...state,
  folder: action.folder,
})

const updateFolderSuccessHandler = (state: LibraryManagementState, action: UpdateFolderSuccessAction) => ({
  ...state,
  folder:
    state.folder?.id === action.folder?.id
      ? plainToInstance(LibraryManagementFolder, { ...state.folder, ...action.folder })
      : state.folder,
})

const duplicateLibraryItemSuccessHandler = (
  state: LibraryManagementState,
  action: DuplicateLibraryItemSuccessAction
) => {
  let counts = { ...state.highlights.counts }

  switch (action.item.libraryItemType) {
    case LibraryItemTypes.COMPONENT:
      counts.LibraryComponentTemplate = ++counts.LibraryComponentTemplate
      break
    case LibraryItemTypes.DELIVERABLE:
      counts.LibraryDeliverableTemplate = ++counts.LibraryDeliverableTemplate
      break
    case LibraryItemTypes.THIRD_PARTY_COST:
      counts.LibraryThirdPartyCost = ++counts.LibraryThirdPartyCost
      break
  }

  return {
    ...state,
    highlights: {
      ...state.highlights,
      counts: { ...state.highlights.counts, ...counts },
    },
  }
}

const removeLibraryItemHandler = (state: LibraryManagementState, action: any) => {
  let counts = { ...state.highlights.counts }

  switch (action.item.libraryItemType) {
    case LibraryItemTypes.COMPONENT:
      counts.LibraryComponentTemplate = --counts.LibraryComponentTemplate
      break
    case LibraryItemTypes.DELIVERABLE:
      counts.LibraryDeliverableTemplate = --counts.LibraryDeliverableTemplate
      break
    case LibraryItemTypes.THIRD_PARTY_COST:
      counts.LibraryThirdPartyCost = --counts.LibraryThirdPartyCost
      break
  }

  return {
    ...state,
    highlights: {
      ...state.highlights,
      counts: { ...state.highlights.counts, ...counts },
    },
  }
}

export function libraryManagementReducer(
  state = libraryManagementInitialState,
  action: Action
): LibraryManagementState {
  return createReducer(
    libraryManagementInitialState,
    on(
      LibraryManagementActions.getHighlights,
      LibraryManagementActions.getFolderFail,
      LibraryCreateDeliverableActions.createDeliverable,
      LibraryCreateDeliverableActions.createDeliverableEntry,
      LibraryCreateComponentActions.createComponent,
      LibraryManagementFoldersActions.createFolder,
      LibraryManagementItemsActions.moveLibraryItem,
      LibraryManagementItemsActions.createFolderAndMoveLibraryItem,
      LibraryManagementFoldersActions.moveFolder,
      LibraryManagementFoldersActions.createAndMoveFolder,
      LibraryManagementItemsActions.updateLibraryItem,
      LibraryManagementItemsActions.duplicateLibraryItem,
      LibraryManagementFoldersActions.updateFolder,
      LibraryManagementFoldersActions.getGroupSettings,
      LibraryManagementFoldersActions.setGroupSettings,
      loadingTrueHandler
    ),
    on(
      LibraryManagementActions.getHighlightsFail,
      LibraryManagementActions.getHighlightsSuccess,
      LibraryManagementActions.getFolderSuccess,
      LibraryCreateDeliverableActions.createDeliverableFail,
      LibraryCreateDeliverableActions.createDeliverableSuccess,
      LibraryCreateDeliverableActions.createDeliverableEntryFail,
      LibraryCreateDeliverableActions.createDeliverableEntrySuccess,
      LibraryCreateComponentActions.createComponentFail,
      LibraryCreateComponentActions.createComponentSuccess,
      LibraryManagementFoldersActions.createFolderFail,
      LibraryManagementFoldersActions.createFolderSuccess,
      LibraryManagementFoldersActions.moveFolderFail,
      LibraryManagementFoldersActions.moveFolderSuccess,
      LibraryManagementFoldersActions.createAndMoveFolderFail,
      LibraryManagementItemsActions.moveLibraryItemFail,
      LibraryManagementItemsActions.moveLibraryItemSuccess,
      LibraryManagementItemsActions.createFolderAndMoveLibraryItemFail,
      LibraryManagementItemsActions.createFolderAndMoveLibraryItemSuccess,
      LibraryManagementItemsActions.updateLibraryItemFail,
      LibraryManagementItemsActions.updateLibraryItemSuccess,
      LibraryManagementItemsActions.duplicateLibraryItemFail,
      LibraryManagementItemsActions.duplicateLibraryItemSuccess,
      LibraryManagementFoldersActions.updateFolderFail,
      LibraryManagementFoldersActions.updateFolderSuccess,
      LibraryManagementFoldersActions.getGroupSettingsFail,
      LibraryManagementFoldersActions.getGroupSettingsSuccess,
      LibraryManagementFoldersActions.setGroupSettingsSuccess,
      loadingFalseHandler
    ),
    on(LibraryManagementActions.setDialogId, setDialogIdHandler),
    on(LibraryManagementActions.getHighlightsSuccess, getHighlightsSuccessHandler),
    on(LibraryManagementActions.getRatecardsSuccess, getRatecardsSuccessHandler),
    on(LibraryManagementActions.getDisciplinesSuccess, getDisciplinesSuccessHandler),
    on(LibraryManagementActions.updateFilters, updateFiltersHandler),
    on(LibraryManagementItemsActions.duplicateLibraryItemSuccess, duplicateLibraryItemSuccessHandler),
    on(
      LibraryManagementItemsActions.archiveLibraryItemSuccess,
      LibraryManagementItemsActions.deleteLibraryItemSuccess,
      removeLibraryItemHandler
    ),
    on(LibraryManagementActions.setFolderId, setFolderIdHandler),
    on(LibraryManagementActions.getFolderSuccess, getFolderSuccessHandler),
    on(LibraryManagementFoldersActions.updateFolderSuccess, updateFolderSuccessHandler)
  )(state, action)
}

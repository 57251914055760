import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedModule } from '@app/shared/shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import {
  ScopeUiDropdownComponent,
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HyperFormula } from 'hyperformula';
import { FormulaBuilderComponent } from '@shared/components/formula-builder/formula-builder.component';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { Observable, take } from 'rxjs';
import {
  ComponentModifier,
  ScenarioModifierType,
} from '@app/features/company-management/models/component-modifier.model';
import { ScopeDynamicFieldSettingModel } from '@app/features/scoping/models/scope-dynamic-field-setting.model';
import { Store } from '@ngrx/store';
import {
  CompanyManagementSelectors
} from '@app/features/company-management/store/selectors/company-management.selectors';
import { filter } from 'rxjs/operators';
import { CompanyManagementActions } from '@app/features/company-management/store/actions/company-management.actions';
import { LanguageService } from '@core/service/language.service';
import { cloneDeep } from 'lodash';
import { ScopeUiTableComponent } from '@shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { Preference } from '@core/model/user-preferences.interface';
import { TableColumnKey } from '@shared/components/ui-components/scope-ui-table/table-column-key.enum';
import { User } from '@core/model/user.model';
import { untilDestroyed } from '@shared/utils/utils';

export interface AddEditModifierModalConfig {
  modifier?: ComponentModifier,
  scenario: Scenario,
  dynamicFields$: Observable<ScopeDynamicFieldSettingModel[]>,
  modifiers$: Observable<ComponentModifier[]>,
  callback: (modifier?: ComponentModifier) => void
  assign?: (modifier?: ComponentModifier) => void
  loggedInUser: User
}

@Component({
  selector: 'add-edit-modifier-modal',
  standalone: true,
  imports: [CommonModule, SharedModule, MatDatepickerModule, ScopeUiInputComponent, ScopeUiDropdownComponent, FormulaBuilderComponent, ScopeUiTableComponent],
  templateUrl: './add-edit-modifier-modal.component.html',
  styleUrls: ['./add-edit-modifier-modal.component.scss'],
})
export class AddEditModifierModalComponent {
  title: string
  body: string
  modifier: ComponentModifier | {
    name: string
    type: ScenarioModifierType
    formula: string
  }
  dynamicFields: ScopeDynamicFieldSettingModel[]
  modifiers: ComponentModifier[]
  updateLoading$: Observable<boolean>
  assign: boolean
  private newModifier: ComponentModifier

  hf: HyperFormula

  modalForm: FormGroup

  typeOptions = [ ScenarioModifierType.QUANTITY_MODIFIER , ScenarioModifierType.PRICE_MODIFIER ]
  typeDisplayNames = {
    [ScenarioModifierType.QUANTITY_MODIFIER]: "Quantity",
    [ScenarioModifierType.PRICE_MODIFIER]: "Price"
  }
  componentColumns: Preference[]
  updatingModifier = false
  isUpdate: boolean

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddEditModifierModalConfig,
    private store: Store,
    private lang: LanguageService
  ) {
    let destroy$ = untilDestroyed()
    // Create a HyperFormula instance for field ID validation
    this.hf = HyperFormula.buildEmpty({ licenseKey: 'gpl-v3' })
    this.updateLoading$ = this.store.select(CompanyManagementSelectors.selectLoadingUpdateModifiers)
    this.data.dynamicFields$.subscribe((dynamicFields) => this.dynamicFields = dynamicFields)
    this.data.modifiers$.subscribe((modifiers) => this.modifiers = modifiers)
    this.isUpdate = !!data.modifier

    if (this.isUpdate) {
      this.title = 'Edit modifier'
      this.body = `${this.lang.get('component')} sizes already assigned would be updated with the new changes.`
      this.modifier = cloneDeep(data.modifier)
      this.store.select(CompanyManagementSelectors.selectModifiers).pipe(destroy$())
        .subscribe((modifiers) => {
          if (!this.updatingModifier) {
            data.modifier = modifiers.find((m) => m.id === data.modifier.id);
            (this.modifier as ComponentModifier).complexityRoles = data.modifier.complexityRoles;
          }
        })
    } else {
      this.title = 'Create modifier'
      this.body = `Create a modifier and if needed assign it to available ${this.lang.get('component|l')} sizes.`
      this.modifier = {
        name: '',
        type: null,
        formula: ''
      }
    }
    this.modalForm = new FormGroup({
      nameControl: new FormControl(this.modifier.name, [Validators.required]),
      typeControl: new FormControl(this.modifier.type, [Validators.required]),
      formulaControl: new FormControl(this.modifier.formula, [Validators.required])
    })
    this.componentColumns = [
      {
        key: TableColumnKey.NAME,
        name: `${this.lang.get('component|u')} NAME`,
        field: 'name',
        selected: true
      },
      {
        key: TableColumnKey.RATE_CARD,
        name: `Rate card`,
        field: 'rateCardNameAndVersion',
        selected: true
      },
      {
        key: TableColumnKey.DISCIPLINE,
        name: `${this.lang.get('discipline')}`,
        field: 'disciplineTypeName',
        selected: true
      },
      {
        key: TableColumnKey.COMPLEXITY,
        name: `${this.lang.get('complexity')}`,
        field: 'complexity',
        selected: true
      },
    ]
  }

  submit() {
    this.modalForm.markAllAsTouched()

    if (this.modalForm.valid && this.modifier.formula) {
      if (this.isUpdate) {
        this.updatingModifier = true
        this.store.dispatch(CompanyManagementActions.updateComponentModifier({
          scenarioId: this.data.scenario.id,
          componentModifier: this.modifier
        }))

        this.updateLoading$.pipe(
          filter((loading) => !loading),
          take(1)
        ).subscribe(() => {
          this.updatingModifier = false
          this.data.callback()
        })
      } else {
        this.store.dispatch(CompanyManagementActions.createComponentModifier({
          scenarioId: this.data.scenario.id,
          componentModifier: this.modifier
        }))

        this.updateLoading$.pipe(
          filter((loading) => !loading),
          take(1)
        ).subscribe(() => {
          this.assign = true
        })

        this.store.select(CompanyManagementSelectors.selectNewModifier).pipe(
          filter((modifier) => !!modifier),
          take(1)
        ).subscribe((result: ComponentModifier) => {
          this.newModifier = result
        })
      }
    }
  }

  assignModifier() {
    this.data.callback(this.newModifier)
  }

  getControl = (key: string) => this.modalForm.get(key) as FormControl
  typeDisplayFn = (type: ScenarioModifierType) => this.typeDisplayNames[type]

  updateAssignments() {
    this.data.assign(this.data.modifier)
  }

  setName($event: any) {
    if (this.modifiers.find((m) => m.name === $event && (!this.isUpdate || this.data.modifier.id !== m.id))) {
      this.getControl('nameControl').setErrors({conflict: true})
    } else {
      this.modifier.name = $event
    }
    this.getControl('nameControl').markAsTouched()
  }
}

<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>Move {{ data.item.name }} to a folder</h1>
    </div>

    <button
      class="close-button"
      mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content
  aria-label="Generic modal"
  class="modal-container md-dialog-container move-item">
  <loader-modal *ngIf="isLoading$ | async"></loader-modal>
  <mat-tree
    *ngIf="!(isLoading$ | async)"
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    class="move-item__tree pt-4">
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodePadding>
      <div
        *ngIf="node.id; else addTemplate"
        matTreeNodeToggle
        class="move-item__tree-element">
        <div class="d-flex d-flex-align-center move-item__content">
          <button
            mat-icon-button
            disabled></button>
          <span class="move-item__name-container">
            <mat-icon class="material-symbols-rounded orange mr-2">folder</mat-icon>
            <span class="move-item__name">{{ node.name }}</span>
          </span>
        </div>
        <button
          *ngIf="data.item.libraryFolder?.id !== node.id"
          class="move-item__button"
          (click)="onMoveClick($event, node)">
          move
        </button>
      </div>
      <ng-template #addTemplate>
        <ng-container *ngTemplateOutlet="addFolderTemplate; context: { $implicit: node }"></ng-container>
      </ng-template>
    </mat-tree-node>
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding>
      <div
        *ngIf="node.id; else addTemplate"
        matTreeNodeToggle
        class="move-item__tree-element">
        <div class="d-flex d-flex-align-center move-item__content">
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name"
            class="move-item__arrow">
            <mat-icon class="material-symbols-rounded orange">{{
              treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'
            }}</mat-icon>
          </button>
          <span class="move-item__name-container">
            <mat-icon class="material-symbols-rounded orange mr-2">folder</mat-icon>
            <span class="move-item__name">{{ node.name }}</span>
          </span>
        </div>
        <button
          *ngIf="data.item.libraryFolder?.id !== node.id"
          class="move-item__button"
          (click)="onMoveClick($event, node)">
          move
        </button>
      </div>
      <ng-template #addTemplate>
        <ng-container *ngTemplateOutlet="addFolderTemplate; context: { $implicit: node }"></ng-container>
      </ng-template>
    </mat-tree-node>
  </mat-tree>
</mat-dialog-content>

<ng-template
  #addFolderTemplate
  let-node>
  <div
    *ngIf="addUnderFolderId !== node?.parent"
    class="move-item__tree-element move-item__add-button">
    <div class="d-flex d-flex-align-center w-full">
      <button
        mat-icon-button
        class="move-item__arrow move-item__arrow--hidden"></button>
      <a
        class="move-item__add"
        (click)="onAddFolderClick(node)">
        <mat-icon class="material-symbols-rounded orange mr-2">add</mat-icon> Add Folder</a
      >
    </div>
  </div>
  <div
    *ngIf="addUnderFolderId === node?.parent"
    class="move-item__tree-element move-item__form-container">
    <button
      mat-icon-button
      class="move-item__arrow move-item__arrow--hidden"></button>
    <form
      [formGroup]="modalForm"
      class="move-item__form">
      <div class="d-flex d-flex-align-center">
        <mat-icon class="material-symbols-rounded orange mr-2">folder</mat-icon>

        <div class="move-item__input">
          <scope-ui-input
            inputLabel="Name"
            [autofocus]="true"
            [maximumLength]="255"
            [control]="nameControl" />
        </div>
      </div>

      <button
        class="move-item__button mt-3"
        (click)="onCreateAndMoveClick($event, node)">
        create & move
      </button>
    </form>
  </div>
</ng-template>

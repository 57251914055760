import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { LibraryManagementActions, LibraryManagementSelectors } from '@app/features/library-management/store'
import { Store, select } from '@ngrx/store'

import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LanguageService } from '@app/core/service/language.service'
import { LibraryDuplicateEntryModalConfig } from '@app/features/library-management/models'
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@app/shared/utils/snackbar.service'

@Component({
  templateUrl: './library-duplicate-deliverable-entry-modal.component.html',
})
export class LibraryDuplicateDeliverableEntryModalComponent {
  rateCards$ = this.store$.pipe(select(LibraryManagementSelectors.selectRateCards))
  disciplines$ = this.store$.pipe(select(LibraryManagementSelectors.selectDisciplines))

  modalForm: FormGroup = new FormGroup({
    rateCard: new FormControl(null, [Validators.required]),
    discipline: new FormControl(null, [Validators.required]),
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LibraryDuplicateEntryModalConfig,
    private store$: Store,
    private lang: LanguageService,
    private snackbarService: SnackbarService
  ) {
    this.store$.dispatch(LibraryManagementActions.getRatecards())
    this.store$.dispatch(LibraryManagementActions.getDisciplines())
  }

  getControl = (key: string) => this.modalForm.get(key) as FormControl
  getControlValue = (key: string) => this.getControl(key).value
  setControlValue = (key: string, value: any) => this.getControl(key).setValue(value)

  submit() {
    this.modalForm.markAllAsTouched()
    if (this.modalForm.valid) {
      const payload = this.modalForm.value
      const disciplineAndRateCardExists = this.data.entries?.findIndex(
        (entry) => payload.discipline.id === entry.discipline.id && payload.rateCard.id === entry.rateCardVersion.id
      )

      if (disciplineAndRateCardExists === -1) this.data.onSubmit(payload)
      else
        this.snackbarService.showSnackbar(
          `Entry with these ${this.lang.get('discipline')} and Rate Card already exists.`,
          SNACKBAR_LENGTH_LONG,
          SnackbarEventType.ERROR
        )
    }
  }
}

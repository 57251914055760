import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs';
import {
  DynamicFieldType,
  ScopeDynamicFieldSettingModel,
} from '@app/features/scoping/models/scope-dynamic-field-setting.model';
import { ScopingSelectors } from '@app/features/scoping/store/selectors/scoping.selector';
import { ScopeActions } from '@app/features/scoping/store/actions/scoping.actions';
import { MappedEntity } from '@app/features/scoping/models/mapped-entity.model';
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service';
import { Preference } from '@core/model/user-preferences.interface';
import { User } from '@core/model/user.model';
import { AuthService } from '@core/service/auth.service';
import { MenuOptions } from '@core/model/definitions/menu-options.interface';
import { RegisteredSubscription, WebsocketService } from '@shared/services/websocket.service';
import { ModalConfig } from '@core/model/modal-config.model';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { MatDialog } from '@angular/material/dialog';
import {
  CreateScopeDynamicFieldSettingModalComponent, ScopeDynamicFieldSettingModalConfig,
} from '@app/features/company-management/components/data/create-scope-dynamic-field-setting-modal/create-scope-dynamic-field-setting-modal.component';
import { Router } from '@angular/router';
import _ from 'lodash';
import { Title } from '@angular/platform-browser';
import { HeaderStyleService } from '@shared/services/header-style.service';
import { ScopeDynamicFieldSettingService } from '@app/features/scoping/service/scope-dynamic-field-setting.service';
import {
  SNACKBAR_LENGTH_LONG,
  SNACKBAR_LENGTH_SHORT,
  SnackbarEventType,
  SnackbarService,
} from '@shared/utils/snackbar.service';
import { TableColumnKey } from '@app/shared/components/ui-components/scope-ui-table/table-column-key.enum';
import { ScopeUiTableSelectMode } from '@app/shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { searchInText } from '@app/shared/utils/search-utils.const';

@Component({
  selector: 'app-scope-dynamic-field-setting',
  templateUrl: './scope-dynamic-field-setting.component.html',
  styleUrls: ['./scope-dynamic-field-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScopeDynamicFieldSetting implements OnInit, AfterViewInit, OnDestroy {

  loggedInUser!: User;
  dynamicFieldColumns!: {
    [key: string]: Preference;
  };
  dynamicFieldMenuOptions: MenuOptions[];
  registeredSubs: RegisteredSubscription[] = [];
  dynamicFieldColumnsPrefsArray!: Preference[];
  settings$: Observable<ScopeDynamicFieldSettingModel[]>;
  mappedDynamicFieldDataSource$!: Observable<MappedEntity<ScopeDynamicFieldSettingModel>[]>;
  isCleaningExternalData:boolean = false;

  constructor(private store: Store,
              private mappingService: DataMappingService,
              private authService: AuthService,
              private cdr: ChangeDetectorRef,
              private webSocketService: WebsocketService,
              private dialog: MatDialog,
              private router: Router,
              private titleService: Title,
              private headerStyleService: HeaderStyleService,
              private snackbarService: SnackbarService,
              private scopeDynamicFieldSettingService: ScopeDynamicFieldSettingService
  ) {
    this.loggedInUser = this.authService.loggedInUser;
    this.settings$ = this.store.select(ScopingSelectors.selectDynamicFieldSettings);
  }

  ngOnInit(): void {
    this.store.dispatch(ScopeActions.getDynamicFieldSettings());
    this.mapDynamicFieldConfigToDataSource();
    this.setDynamicFieldColumns();
    this.setMenuOptions();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.registeredSubs.forEach(s => this.webSocketService.unregisterReceiver(s));
    this.registeredSubs = [];
  }

  backToManage() {
    this.router.navigate([`/manage`]);
  }

  setMenuOptions() {
    this.dynamicFieldMenuOptions = [
      {
        callback: (element) => this.onEdit(element.entity),
        icon: () => 'edit',
        name: () => 'Edit',
      },
      {
        callback: (element) => this.onDelete(element.entity),
        icon: () => 'delete',
        name: () => 'Delete',
      },
      {
        callback: (element) => this.onCleanCache(element.entity),
        icon: () => 'mop',
        name: () => 'Clean cache',
        isHidden: (element) => element.entity.type !== DynamicFieldType.DROPDOWN_EXTERNAL,
      }
    ];
  }

  mapDynamicFieldConfigToDataSource() {
    this.mappedDynamicFieldDataSource$ = combineLatest([this.settings$]).pipe(
      map(([dynamicFields]) => {
        return this.mappingService.transformArray(dynamicFields, this.dynamicFieldColumnsPrefsArray);
      }),
    );
  }

  setDynamicFieldColumns() {
    this.dynamicFieldColumns = {
    [TableColumnKey.FIELD_NAME]: {
        key: TableColumnKey.FIELD_NAME,
        name: 'Field Name',
        selected: true,
        field: 'displayName',
      },
    [TableColumnKey.FIELD_JSON_KEY]: {
        key: TableColumnKey.FIELD_JSON_KEY,
        name: 'Field Json Key',
        selected: true,
        field: 'name',
      },
    [TableColumnKey.FIELD_TYPE]: {
        key: TableColumnKey.FIELD_TYPE,
        name: 'Field Type',
        selected: true,
        field: 'type',
      },
    [TableColumnKey.MANDATORY]: {
        key: TableColumnKey.MANDATORY,
        name: 'Mandatory',
        selected: true,
        noSort: true,
      },
    };
    this.setDynamicFieldColumnsArray();
  }

  setDynamicFieldColumnsArray() {
    this.dynamicFieldColumnsPrefsArray = Object.values(this.dynamicFieldColumns);
  }

  onCancelPressed() {
    this.mapDynamicFieldConfigToDataSource();
  }

  onSearchKeyPressed(value: string): void {
    const filteredSettings$ = this.settings$.pipe(
      map(settings => settings.filter(setting => searchInText(setting.displayName, value))),
    );
    this.mappedDynamicFieldDataSource$ = filteredSettings$.pipe(
      map(filteredSettings =>
        this.mappingService.transformArray(filteredSettings, this.dynamicFieldColumnsPrefsArray),
      ),
    );
  }

  openCreateModal() {
    const scopeDynamicFieldCreateModalConfig: ScopeDynamicFieldSettingModalConfig = {
      title: `Create Scope Dynamic Field`,
      submitButtonName: 'Create',
      scopeDynamicFieldSetting: null,
      isEditModal: false,
    };
    this.dialog.open(CreateScopeDynamicFieldSettingModalComponent, {
      data: scopeDynamicFieldCreateModalConfig,
    });
  }

  onEdit(setting: ScopeDynamicFieldSettingModel) {
    const scopeDynamicFieldEditModalConfig: ScopeDynamicFieldSettingModalConfig = {
      title: `Edit Scope Dynamic Field: ${setting.displayName}`,
      submitButtonName: 'Save',
      scopeDynamicFieldSetting: setting,
      isEditModal: true,
    };
    this.dialog.open(CreateScopeDynamicFieldSettingModalComponent, {
      data: scopeDynamicFieldEditModalConfig,
    });
  }

  onDelete(setting: ScopeDynamicFieldSettingModel) {
    const scopeDynamicFieldDeleteModalConfig: ModalConfig = {
      title: `Delete Scope Dynamic Field ${setting.displayName}`,
      body: `Are you sure you want to delete ${setting.displayName} Scope Dynamic Field?`,
      confirmText: 'Delete',
      confirmCallback: () => {
        this.store.dispatch(ScopeActions.deleteDynamicFieldSetting({ id: setting.id }));
        dialog.close();
      },
      cancelCallback: undefined,
      inputs: [],
      isMemberModal: undefined,
      limitBodyWidth: true,
    };
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: scopeDynamicFieldDeleteModalConfig,
    });
  }

  onCleanCache(setting: ScopeDynamicFieldSettingModel) {
    this.isCleaningExternalData = true;
    this.scopeDynamicFieldSettingService.evictExternalSystemResource({
      integrationName: setting.apiIntegrationName,
      resourceUri: setting.externalSystemResourceUri,
    }).subscribe({
      next: () => {
        this.isCleaningExternalData = false;
        this.snackbarService.showSnackbar(`${setting.displayName} cache is cleared successfully`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
      }, error: () => {
        this.isCleaningExternalData = false;
        this.snackbarService.showSnackbar('Error during cleaning External Data', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR);
      }
    });
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.data.action === 'adminNavigate') {
      this.headerStyleService.setHeaderClass(null)
      let location = event.data.location === 'scope-fields' ? 'scope fields' : event.data.location
      this.titleService.setTitle(_.startCase(_.camelCase(location)) + ' | Admin | Scope')
      this.router.navigate(
        [`/manage`],
        {
          queryParams: { 'tab': location }
        }
      )
    }
  }
  
  protected readonly ScopeUiTableSelectMode = ScopeUiTableSelectMode;
}

<div class='modal-container'>
  <div mat-dialog-title>
    <div class='modal__header'>
      <div class='modal__title'>
        <h1>{{title}}</h1>
      </div>
      <div class='modal-text mt-2'>
        <span class='whitespace-pre-line'>{{instructions}}</span>
      </div>
      <button class='close-button' mat-dialog-close>
        <i class='material-symbols-rounded'>close</i>
      </button>
    </div>
  </div>
  <mat-dialog-content aria-label='Add Deliverable Prompt' *ngIf='step===1'>
    <div class='modal__body flex flex-col gap-8'>
      <div class='flex flex-row gap-4 items-baseline'>
        <scope-ui-input
          class='flex-1'
          [inputLabel]='questionTextLabel'
          [inputClass]="'icon-field show-errors'"
          [required]='true'
          [initialValue]='questionText'
          (onInputChange)='questionText = $event'
        ></scope-ui-input>
      </div>
      <mat-divider></mat-divider>
      <div class='flex gap-2 flex-col'>
        <h4 class='product-heading-bold'>
          {{configureTitle}}<span class='orange'> *</span>
        </h4>
        <scope-ui-checkbox
          [ngModel]='showAlways'
          (ngModelChange)='showAlways = $event; showOnCondition = !$event'
          [title]="'Always'"
        ></scope-ui-checkbox>
        <scope-ui-checkbox
          [ngModel]='showOnCondition'
          (ngModelChange)='showOnCondition = $event; showAlways = !$event'
          [title]="'On condition'"
        ></scope-ui-checkbox>
        <formula-builder
          *ngIf='showOnCondition'
          [scenario]='scenario'
          [(value)]='condition'
          [title]="'Set a condition'"
          [required]='true'
          [showResult]='false'
          [control]='conditionControl'
        ></formula-builder>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-content aria-label='Add Deliverable Prompt' *ngIf='step===2'>
    <div class='modal__body flex flex-col'>
      <h4 class='product-heading-bold'>Select {{'deliverable.p' | lang}}<span class='orange'> *</span></h4>
      <div *ngIf='selectedDeliverables.length > 0' class='table-container'>
        <table mat-table [dataSource]='dataSource' class='mat-elevation-z8'>
          <ng-container matColumnDef='pin'>
            <th mat-header-cell *matHeaderCellDef>
              Pin
            </th>
            <td mat-cell *matCellDef='let row'>
              <mat-icon
                class='material-symbols-rounded item-icon pointer'
                [ngClass]="{ 'orange fill': !row.required }"
                (click)='pinDeliverable(row)'
                matTooltip="Pin a {{ 'deliverable|l' | lang }} so it will always be included"
              >
                {{ row.required ? 'keep_off' : 'keep' }}
              </mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef='deliverable_name'>
            <th mat-header-cell *matHeaderCellDef>
              <div class='flex items-center pointer' (click)="onSort('deliverable_name')">
                {{'deliverable' | lang}} Name
                <sort-icon
                  [column]="'deliverable_name'"
                  [sortedColumn]='sortedColumn'
                  [sortDirection]='sortDirection'
                ></sort-icon>
              </div>
            </th>
            <td mat-cell *matCellDef='let element'>
              {{ element.libraryItemName }}
            </td>
          </ng-container>
          <ng-container matColumnDef='discipline_name'>
            <th mat-header-cell *matHeaderCellDef>
              <div class='flex items-center pointer' (click)="onSort('discipline_name')">
                Discipline
                <sort-icon
                  [column]="'discipline_name'"
                  [sortedColumn]='sortedColumn'
                  [sortDirection]='sortDirection'
                ></sort-icon>
              </div>
            </th>
            <td mat-cell *matCellDef='let element'>
              {{ element.disciplineName }}
            </td>
          </ng-container>
          <ng-container matColumnDef='delete'>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef='let element'>
              <mat-icon
                class='material-symbols-rounded right orange pointer'
                (click)='deleteDeliverable(element)'>delete
              </mat-icon>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
          <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
        </table>
        <mat-slide-toggle
          class='scope-ui-toggle'
          [disableRipple]='true'
          [checked]='required'
          (change)='required = $event.checked'>
          Required
        </mat-slide-toggle>
      </div>
      <div class='flex-row items-baseline'>
        <prompt-autocomplete
          [options]='deliverableEntryDetails$ | async'
          [optionFn]='optionFunction'
          [isFetching]="isLoading$ | async"
          [lastPage]="lastPage$ | async"
          [title]="'Find ' + ('deliverable|l' | lang)"
          [isSelected]="isSelected"
          (onFetch)='fetchDeliverables($event)'
          (selectedItemsChange)='updateSelectedDeliverables($event)'>
        </prompt-autocomplete>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class='flex gap-3 justify-between'>
    <span class='step'>{{ step }}/2</span>
    <div class='actions-right'>
      <button mat-button mat-dialog-close>
        Cancel
      </button>
      <button mat-button *ngIf="step === 2" (click)="step = 1">
        <mat-icon class="material-symbols-rounded">chevron_left</mat-icon>
        Back
      </button>
      <button mat-flat-button class='ml-8' *ngIf="!(updateLoading$ | async)" (click)='goToStep(step)' [disabled]='disableNextStep()'>
        {{ step === 1 ? 'Go To Next Step' : 'Save' }}
      </button>
      <button mat-flat-button class="ml-8" *ngIf="(updateLoading$ | async)">
        <img src="/assets/icons/loading.svg" alt="loading" class="icon-spinner">
      </button>
    </div>
  </mat-dialog-actions>
</div>

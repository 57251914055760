import {
  LibraryComponentService,
  LibraryManagementApiMappingService,
  LibraryManagementItemsService,
  LibraryManagementMappingService,
} from '@app/features/library-management/services'
import {
  LibraryComponentActions,
  LibraryComponentSelectors,
  LibraryItemName,
} from '@app/features/library-management/store'
import {
  LibraryComponent,
  LibraryComponentEntry,
  ToggleComponentEntryFixedFeeEditableAction,
} from '@app/features/library-management/store/models/component'
import { SNACKBAR_LENGTH_SHORT, SnackbarEventType, SnackbarService } from '@app/shared/utils/snackbar.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs'
import { Injectable } from '@angular/core'
import { LanguageService } from '@app/core/service/language.service'
import { LibraryCascadeChangesModalComponent } from '../../components/modals'
import { MatDialog } from '@angular/material/dialog'
import { Privilege } from '@app/core/model/enums/privilege.enum'
import { Router } from '@angular/router'
import { AuthService } from '@app/core/service/auth.service'
import { Store } from '@ngrx/store'
import { plainToInstance } from 'class-transformer'

@Injectable()
export class LibraryComponentEffects {
  constructor(
    private store$: Store,
    private actions$: Actions,
    private router: Router,
    private snackbarService: SnackbarService,
    private lang: LanguageService,
    private authService: AuthService,
    private dialog: MatDialog,
    private libraryComponentService: LibraryComponentService,
    private libraryManagementItemsService: LibraryManagementItemsService,
    private libraryMappingService: LibraryManagementMappingService,
    private libraryApiMappingService: LibraryManagementApiMappingService
  ) {}

  getComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryComponentActions.getComponent),
      filter(({ id }) => !!id),
      switchMap(({ id }) =>
        this.libraryComponentService.getComponent(id).pipe(
          map((response) =>
            LibraryComponentActions.getComponentSuccess({
              component: this.libraryMappingService.mapComponent(response),
            })
          ),
          catchError((error: any) => of(LibraryComponentActions.getComponentFail({ error })))
        )
      )
    )
  )

  addComponentEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryComponentActions.addComponentEntry),
      withLatestFrom(this.store$.select(LibraryComponentSelectors.selectComponent)),
      switchMap(([{ payload }, component]) =>
        this.libraryComponentService
          .addComponentEntry(
            component.id,
            this.libraryApiMappingService.mapComponentEntryToApi(
              payload,
              this.authService.loggedInUser.company?.currency
            )
          )
          .pipe(
            map(({ id }) => LibraryComponentActions.addComponentEntrySuccess({ id })),
            catchError((error: any) => of(LibraryComponentActions.addComponentEntryFail({ error })))
          )
      )
    )
  )

  duplicateComponentEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryComponentActions.duplicateComponentEntry),
      withLatestFrom(this.store$.select(LibraryComponentSelectors.selectComponent)),
      switchMap(([{ payload }, component]) =>
        this.libraryComponentService
          .duplicateComponentEntry(
            component.id,
            payload.id,
            payload.rateCard.id,
            this.libraryApiMappingService.mapComponentEntryDuplicatePayloadApi(payload)
          )
          .pipe(
            map(({ id }) => LibraryComponentActions.duplicateComponentEntrySuccess({ id })),
            catchError((error: any) => of(LibraryComponentActions.duplicateComponentEntryFail({ error })))
          )
      )
    )
  )

  updateComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryComponentActions.updateComponent),
      switchMap(({ component }) =>
        this.libraryComponentService
          .updateComponent(this.libraryApiMappingService.mapComponentToApi(component))
          .pipe(
            map((response) =>
              LibraryComponentActions.updateComponentSuccess({ component: plainToInstance(LibraryComponent, response) })
            )
          )
      ),
      catchError((error) => of(LibraryComponentActions.updateComponentFail(error)))
    )
  )

  deleteComponentEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryComponentActions.deleteComponentEntry),
      switchMap(({ id, entryId }) =>
        this.libraryComponentService.deleteComponentEntry(id, entryId).pipe(
          map(() => {
            this.snackbarService.showSnackbar(
              `${this.lang.get('deliverable')} Entry was successfully deleted`,
              SNACKBAR_LENGTH_SHORT,
              SnackbarEventType.SUCCESS
            )

            return LibraryComponentActions.deleteComponentEntrySuccess({ entryId })
          }),
          catchError((error: any) => of(LibraryComponentActions.deleteComponentEntryFail({ error })))
        )
      )
    )
  )

  toggleEntryFixedCostEditable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryComponentActions.toggleEntryFixedCostEditable),
      switchMap(({ entry }: ToggleComponentEntryFixedFeeEditableAction) =>
        (entry.fixedFeeEditable
          ? this.libraryComponentService.enableComponentEntryFixedFeeEditable(
              entry.libraryComponentTemplate.id,
              entry.id
            )
          : this.libraryComponentService.disableComponentEntryFixedFeeEditable(
              entry.libraryComponentTemplate.id,
              entry.id
            )
        ).pipe(
          map(({ id, name }: LibraryComponentEntry) => {
            this.snackbarService.showSnackbar(
              `Item ${name} successfully changed editable status`,
              SNACKBAR_LENGTH_SHORT,
              SnackbarEventType.SUCCESS
            )

            return LibraryComponentActions.toggleEntryFixedCostEditableSuccess({ id })
          }),
          catchError((error: any) => of(LibraryComponentActions.toggleEntryFixedCostEditableFail({ error })))
        )
      )
    )
  )

  getComponentRateCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryComponentActions.getEntryRateCard),
      withLatestFrom(this.store$.select(LibraryComponentSelectors.selectRateCard)),
      filter(([{ id, entryId }, rateCard]) => id && entryId && !rateCard),
      switchMap(([{ id, entryId }]) =>
        this.libraryComponentService.getEntryRateCard(id, entryId).pipe(
          map((rateCard) => LibraryComponentActions.getEntryRateCardSuccess({ rateCard, entryId })),
          catchError((error: any) => of(LibraryComponentActions.getEntryRateCardFail({ error })))
        )
      )
    )
  )

  saveEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryComponentActions.saveComponentEntry),
      withLatestFrom(this.store$.select(LibraryComponentSelectors.selectComponentEntries)),
      map(([{ entry, entryId }, entries]) => entry || entries?.find((entry) => entry.id == entryId)),
      filter((entry) => !!entry),
      switchMap((entry) =>
        this.libraryComponentService.saveEntry(this.libraryApiMappingService.mapComponentEntryToUpdateApi(entry)).pipe(
          map((entry: LibraryComponentEntry) => LibraryComponentActions.saveComponentEntrySuccess({ entry })),
          catchError((error: any) => of(LibraryComponentActions.saveComponentEntryFail({ error })))
        )
      )
    )
  )

  saveComponentEntrySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryComponentActions.saveComponentEntrySuccess),
        tap(() => this.snackbarService.showSnackbar('Changes saved', SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)),
        filter(() => this.authService.loggedInUser?.hasPrivilege(Privilege.LIBRARY_COMPONENT__EDIT_CASCADE)),
        map(({ entry }) => {
          this.dialog.open(LibraryCascadeChangesModalComponent, {
            data: {
              itemType: `${LibraryItemName.COMPONENT}|l`,
              onSubmit: (result: boolean) =>
                result && this.libraryComponentService.cascadeChanges(entry.id).subscribe(),
            },
          })
        })
      ),
    { dispatch: false }
  )

  navigateToLibrary$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryComponentActions.deleteComponentSuccess),
        map(() => this.router.navigate(['library']))
      ),
    { dispatch: false }
  )

  navigateToComponent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryComponentActions.deleteComponentEntrySuccess),
        withLatestFrom(this.store$.select(LibraryComponentSelectors.selectComponent)),
        map(([_, component]) => this.router.navigate([`library/component/${component.id}`]))
      ),
    { dispatch: false }
  )

  navigateToComponentEntry$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          LibraryComponentActions.addComponentEntrySuccess,
          LibraryComponentActions.duplicateComponentEntrySuccess
        ),
        withLatestFrom(this.store$.select(LibraryComponentSelectors.selectComponent)),
        map(([{ id }, component]) => this.router.navigate([`library/component/${component.id}/edit/${id}`]))
      ),
    { dispatch: false }
  )
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { filter, map, startWith, debounceTime } from 'rxjs';

import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { untilDestroyed } from '@app/shared/utils/utils';
import { SharedModule } from '@shared/shared.module';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@Component({
  selector: 'prompt-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    SharedModule,
    MatPseudoCheckboxModule,
    InfiniteScrollModule,
  ],
  templateUrl: './prompt-autocomplete.component.html',
  styleUrls: ['./prompt-autocomplete.component.scss'],
})
export class PromptAutocompleteComponent implements OnInit {
  private readonly destroy$;

  _options!: any[];

  @Input() set options(value: any[]) {
    this._options = value;
  }

  @Input() orderBy!: string;

  @Input() optionFn!: (value: any) => string;

  @Input() noResultsMessage?: string = 'No results found';

  @Input() set disabled(value: boolean) {
    if (value) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  @Input() required = false;

  @Input() control = new FormControl<string | any>('');

  @Input() isFetching = false;

  @Input() lastPage = false;

  @Input() noPagination = false;

  @Input() title: string

  @Input() isSelected: (option: any) => boolean

  @Output() onFetch!: EventEmitter<{ page: number, searchString: string }>;

  @Output() selectedItemsChange = new EventEmitter<any>();

  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;

  searchText: string;

  page: number = 0;

  constructor(private cdr: ChangeDetectorRef) {
    this.onFetch = new EventEmitter<{ page: number, searchString: string }>();
    this.destroy$ = untilDestroyed();
  }

  ngOnInit() {
    if (!this.optionFn) {
      this.optionFn = (item: any) => {
        return item && item.name ? item.name : '';
      };
    }
    this.cdr.detectChanges();
    this.autoComplete();
  }

  onScroll() {
    this.page++;
    this.onFetch.emit({ page: this.page, searchString: this.searchText });
  }

  runSearch = (value) => {
    this.searchText = value;
    this.page = 0;
    this.onFetch.emit({ page: this.page, searchString: this.searchText });
  };

  autoComplete() {
    this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      filter((value) => typeof value === 'string'),
      map((value) => {
        this.runSearch(value);
        return this._filterOptions(value);
      }),
      this.destroy$()
    ).subscribe();
  }

  private _filterOptions(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this._options?.filter((option) => {
      const optionValue = this.optionFn ? this.optionFn(option) : option.name;
      return optionValue.toLowerCase().includes(filterValue);
    }) || [];
  }

  clear($event?: MouseEvent) {
    $event.stopPropagation();
    this.control.setValue('');
    this.page = 0;
    this.onFetch.emit({ page: this.page, searchString: '' });
  }

  toggle($event: MouseEvent) {
    $event.stopPropagation();
    if (this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.closePanel();
    } else {
      this.matAutocompleteTrigger.openPanel();
    }
  }

  onAdd(option: any, event: MouseEvent): void {
    event.stopPropagation();
    this.matAutocompleteTrigger.closePanel()
    if (!this.isSelected(option)) {
      this.selectedItemsChange.emit(option);
    }
  }
}

<div class="manage-tab-wrapper">
  <div class="flex d-flex-justify-between pt-10 px-10 items-center">
    <h3 class="product-heading-bold">Scenario Pricing Configurator</h3>
    <div class="flex gap-2 items-center">
      <scope-ui-input
        [icon]="'search'"
        [cancelIcon]="'cancel'"
        [inputLabel]="'Search'"
        [inputClass]="'search-field'"
        (onCancel)="searchText = ''; filterScenarios()"
        (onInputChange)="searchText = $event; filterScenarios()" />
      <mat-divider vertical />
      <button (click)="openCreateModal()" mat-flat-button>
        <mat-icon class="material-symbols-rounded">add</mat-icon> Create Form
      </button>
    </div>
  </div>
  <div class='p-10'>
    <scope-ui-table
      [loggedInUser]='loggedInUser'
      [data]='mappedScenarioDataSource$ | async'
      [displayedColumns]='scenarioPreferenceColumns'
      [noPagination]='true'
      [rowGaps]='true'
      [selectMode]='ScopeUiTableSelectMode.ROW'
      [menuOptions]='scenarioMenuOptions'
      [showToggleMenu]='true'
      [addToggleListColumn]='true'
      [noPreferences]='true'
      [loading]="loading$ | async"
      (onSelect)="onSelect($event)"/>
  </div>
</div>
